// import { API_HOST } from "./index";
// import {directionalDropdownUniqueId} from '../Editor';

export const styleManager = {
  appendTo: "#styles-container",
  sectors: [
    {
      name: "General",
      properties: [
        {
          extend: "float",
          type: "radio",
          default: "none",
          options: [
            { value: "none", className: "fa fa-times" },
            { value: "left", className: "fa fa-align-left" },
            { value: "right", className: "fa fa-align-right" },
          ],
        },
        {
          extend: "vertical-align",
          type: "radio",
          // default: 'baseline',
          options: [
            { value: "top", className: "fa fa-arrow-up" },
            { value: "middle", className: "fa fa-arrow-right" },
            { value: "bottom", className: "fa fa-arrow-down" },
          ],
        },
        "display",
        { extend: "position", type: "select" },
        { extend: "overflow", type: "select" },
        { extend: "overflow-x", type: "select" },
        { extend: "overflow-y", type: "select" },
        "top",
        "right",
        "left",
        "bottom",
        "z-index",
        {
          name: "Cursor",
          property: "cursor",
          type: "select",
          default: "auto",
          options: [
            { value: "alias", name: "Alias" },
            { value: "all-scroll", name: "All-Scroll" },
            { value: "auto", name: "Auto" },
            { value: "cell", name: "Cell" },
            { value: "col-resize", name: "Col-Resize" },
            { value: "context-menu", name: "Context-Menu" },
            { value: "copy", name: "Copy" },
            { value: "crosshair", name: "Crosshair" },
            { value: "default", name: "Default" },
            { value: "e-resize", name: "E-Resize" },
            { value: "ew-resize", name: "EW-Resize" },
            { value: "grab", name: "Grab" },
            { value: "grabbing", name: "Grabbing" },
            { value: "help", name: "Help" },
            { value: "move", name: "Move" },
            { value: "n-resize", name: "N-Resize" },
            { value: "ne-resize", name: "NE-Resize" },
            { value: "nesw-resize", name: "NESW-Resize" },
            { value: "ns-resize", name: "NS-Resize" },
            { value: "nw-resize", name: "NW-Resize" },
            { value: "nwse-resize", name: "NWSE-Resize" },
            { value: "no-drop", name: "No-Drop" },
            { value: "none", name: "None" },
            { value: "not-allowed", name: "Not-Allowed" },
            { value: "not-allowed", name: "Not-Allowed" },
            { value: "pointer", name: "Pointer" },
            { value: "progress", name: "Progress" },
            { value: "row-resize", name: "Row-Resize" },
            { value: "s-resize", name: "S-Resize" },
            { value: "se-resize", name: "SE-Resize" },
            { value: "sw-resize", name: "SW-Resize" },
            { value: "text", name: "Text" },
            { value: "vertical-text", name: "Vertical-Text" },
            { value: "w-resize", name: "W-Resize" },
            { value: "wait", name: "Wait" },
            { value: "zoom-in", name: "Zoom-In" },
            { value: "zoom-out", name: "Zoom-Out" },
            { value: "initial", name: "Initial" },
            { value: "inherit", name: "Inherit" },
          ],
        },
      ],
    },
    {
      name: "Dimension",
      open: false,
      properties: [
        "width",
        // {
        //     id: 'flex-width',
        //     type: 'integer',
        //     name: 'Width',
        //     units: ['px', '%'],
        //     property: 'flex-basis',
        //     toRequire: 1,
        // },
        "height",
        "min-width",
        "max-width",
        "min-height",
        "max-height",
        "margin",
        "padding",
        "transform",
        // 'border-spacing',
      ],
    },
    {
      name: "Typography",
      open: false,
      properties: [
        "font-family",
        {
          extend: "font-family",
          property: "font-family",
          // name: 'Google Fonts',
          type: "select",
          options: [
            { name: "Roboto", value: "Roboto, sans-serif" },
            { name: "Poppins", value: "Poppins, sans-serif" },
            { name: "Open Sans", value: "Open Sans, sans-serif" },
            { name: "Montserrat", value: "Montserrat, sans-serif" },
            { name: "Noto Sans", value: "Noto Sans, sans-serif" },
            { name: "Noto Sans Korean", value: "Noto Sans KR, sans-serif" },
            { name: "Lato", value: "Lato, sans-serif" },
            { name: "Nunito Sans", value: "Nunito Sans, sans-serif" },
            { name: "Raleway", value: "Raleway, sans-serif" },
            { name: "Bacasime Antique", value: "Bacasime Antique, serif" },
            { name: "League Spartan", value: "League Spartan, sans-serif" },
            { name: "Manrope", value: "Manrope, sans-serif" },
            { name: "Bebas Neue", value: "Bebas Neue, sans-serif" },
            { name: "Marcellus", value: "Marcellus, serif" },
            { name: "Ubuntu", value: "Ubuntu, sans-serif" },
          ],
        },
        "font-size",
        "font-weight",
        "letter-spacing",
        "color",
        "line-height",
        {
          extend: "text-align",
          options: [
            { id: "left", label: "Left", className: "fa fa-align-left" },
            { id: "center", label: "Center", className: "fa fa-align-center" },
            { id: "right", label: "Right", className: "fa fa-align-right" },
            {
              id: "justify",
              label: "Justify",
              className: "fa fa-align-justify",
            },
          ],
        },
        {
          property: "text-decoration",
          type: "radio",
          default: "none",
          options: [
            { id: "none", label: "None", className: "fa fa-times" },
            {
              id: "underline",
              label: "underline",
              className: "fa fa-underline",
            },
            {
              id: "line-through",
              label: "Line-through",
              className: "fa fa-strikethrough",
            },
          ],
        },
        {
          property: "font-weight",
          type: "radio",
          default: "none",
          options: [
            { id: "none", label: "None", className: "fa fa-times" },
            { id: "normal", label: "Normal", className: "fa fa-font" },
            { id: "bold", label: "Bold", className: "fa fa-bold" },
          ],
        },
        {
          property: "font-style",
          type: "radio",
          default: "none",
          options: [
            { id: "none", label: "None", className: "fa fa-times" },
            { id: "normal", label: "Normal", className: "fa fa-font" },
            { id: "italic", label: "Bold", className: "fa fa-italic" },
          ],
        },
        {
          name: "Writing Mode",
          property: "writing-mode",
          type: "radio",
          defaults: "horizontal-tb",
          list: [
            { value: "horizontal-tb", name: "Horizontal-TB" },
            { value: "vertical-rl", name: "Vertical-RL" },
            { value: "vertical-lr", name: "Vertical-LR" },
          ],
        },
        "text-shadow",
      ],
    },
    {
      name: "Decorations",
      open: false,
      properties: [
        "opacity",
        {
          name: "Outline",
          type: "composite",
          properties: [
            {
              name: "outline-width",
              type: "integer",
              units: ["px", "rem", "em", "vw", "vh", "%", "cm", "mm", "in"],
            },
            {
              name: "outline-style",
              type: "select",
              options: [
                { value: "none", name: "None" },
                { value: "dashed", name: "Dashed" },
                { value: "dotted", name: "Dotted" },
                { value: "double", name: "Double" },
                { value: "groove", name: "Groove" },
                { value: "hidden", name: "Hidden" },
                { value: "inherit", name: "Inherit" },
                { value: "initial", name: "Initial" },
                { value: "inset", name: "Inset" },
                { value: "outset", name: "Outset" },
                { value: "ridge", name: "Ridge" },
                { value: "solid", name: "Solid" },
              ],
            },
            {
              name: "outline-color",
              type: "color",
              placeholder: "black",
            },
          ],
        },
        {
          name: "List Style",
          property: "list-style",
          type: "select",
          defaults: "none",
          list: [
            { value: "none", name: "None" },
            { value: "disc", name: "Disc" },
            { value: "circle", name: "Circle" },
            { value: "square", name: "Square" },
            { value: "decimal", name: "Decimal" },
            { value: "decimal-leading-zero", name: "Decimal Leading Zero" },
            { value: "lower-roman", name: "Lower Roman" },
            { value: "upper-roman", name: "Upper Roman" },
            { value: "lower-alpha", name: "Lower Alpha" },
            { value: "upper-alpha", name: "Upper Alpha" },
            { value: "lower-greek", name: "Lower Greek" },
            // Add more values as needed
          ],
        },
        "border-radius",
        "border",
        {
          name: "Border Top",
          type: "composite",
          properties: [
            {
              name: "border-top-width",
              type: "integer",
              units: ["px", "rem", "em", "vw", "vh", "%", "cm", "mm", "in"],
            },
            {
              name: "border-top-style",
              type: "select",
              options: [
                { value: "none", name: "None" },
                { value: "dashed", name: "Dashed" },
                { value: "dotted", name: "Dotted" },
                { value: "double", name: "Double" },
                { value: "groove", name: "Groove" },
                { value: "hidden", name: "Hidden" },
                { value: "inherit", name: "Inherit" },
                { value: "initial", name: "Initial" },
                { value: "inset", name: "Inset" },
                { value: "outset", name: "Outset" },
                { value: "ridge", name: "Ridge" },
                { value: "solid", name: "Solid" },
              ],
            },
            {
              name: "border-top-color",
              type: "color",
              placeholder: "black",
            },
          ],
        },
        {
          name: "Border Right",
          type: "composite",
          properties: [
            {
              name: "border-right-width",
              type: "integer",
              units: ["px", "rem", "em", "vw", "vh", "%", "cm", "mm", "in"],
            },
            {
              name: "border-right-style",
              type: "select",
              options: [
                { value: "none", name: "None" },
                { value: "dashed", name: "Dashed" },
                { value: "dotted", name: "Dotted" },
                { value: "double", name: "Double" },
                { value: "groove", name: "Groove" },
                { value: "hidden", name: "Hidden" },
                { value: "inherit", name: "Inherit" },
                { value: "initial", name: "Initial" },
                { value: "inset", name: "Inset" },
                { value: "outset", name: "Outset" },
                { value: "ridge", name: "Ridge" },
                { value: "solid", name: "Solid" },
              ],
            },
            {
              name: "border-right-color",
              type: "color",
              placeholder: "black",
            },
          ],
        },
        {
          name: "Border Bottom",
          type: "composite",
          properties: [
            {
              name: "border-bottom-width",
              type: "integer",
              units: ["px", "rem", "em", "vw", "vh", "%", "cm", "mm", "in"],
            },
            {
              name: "border-bottom-style",
              type: "select",
              options: [
                { value: "none", name: "None" },
                { value: "dashed", name: "Dashed" },
                { value: "dotted", name: "Dotted" },
                { value: "double", name: "Double" },
                { value: "groove", name: "Groove" },
                { value: "hidden", name: "Hidden" },
                { value: "inherit", name: "Inherit" },
                { value: "initial", name: "Initial" },
                { value: "inset", name: "Inset" },
                { value: "outset", name: "Outset" },
                { value: "ridge", name: "Ridge" },
                { value: "solid", name: "Solid" },
              ],
            },
            {
              name: "border-bottom-color",
              type: "color",
              placeholder: "black",
            },
          ],
        },
        {
          name: "Border Left",
          type: "composite",
          properties: [
            {
              name: "border-left-width",
              type: "integer",
              units: ["px", "rem", "em"],
            },
            {
              name: "border-left-style",
              type: "select",
              options: [
                { value: "none", name: "None" },
                { value: "dashed", name: "Dashed" },
                { value: "dotted", name: "Dotted" },
                { value: "double", name: "Double" },
                { value: "groove", name: "Groove" },
                { value: "hidden", name: "Hidden" },
                { value: "inherit", name: "Inherit" },
                { value: "initial", name: "Initial" },
                { value: "inset", name: "Inset" },
                { value: "outset", name: "Outset" },
                { value: "ridge", name: "Ridge" },
                { value: "solid", name: "Solid" },
              ],
            },
            {
              name: "border-left-color",
              type: "color",
              placeholder: "black",
            },
          ],
        },
        // for html slider/range element
        {
          name: "Accent Color",
          property: "accent-color",
          type: "color",
        },
        // "border-collapse",
        // "border-spacing",
        "border-color",
        "box-shadow",
        "background-color",
        "background", // { id: 'background-bg', property: 'background', type: 'bg' }
        // {
        //   label: "Background Gradient",
        //   property: "background-image",
        // },
        "background-image",
      ],
    },
    {
      name: "Extra",
      open: false,
      buildProps: ["transition", "perspective", "transform"],
    },
    {
      name: "Flex",
      open: false,
      properties: [
        {
          name: "Flex Container",
          property: "display",
          type: "select",
          defaults: "block",
          list: [
            { value: "block", name: "Disable" },
            { value: "flex", name: "Enable" },
          ],
        },
        {
          name: "Flex Parent",
          property: "label-parent-flex",
          type: "integer",
        },
        {
          name: "Flex Wrap",
          property: "flex-wrap",
          type: "radio",
          defaults: "nowrap",
          list: [
            {
              value: "nowrap",
              name: "No wrap",
              title: "No wrap",
              className: "icons-flex icon-wrap-no",
            },
            {
              value: "wrap",
              name: "Wrap",
              title: "Wrap",
              className: "icons-flex icon-wrap-yes",
            },
            {
              value: "wrap-reverse",
              name: "Wrap reverse",
              title: "Wrap reverse",
              className: "icons-flex icon-wrap-yes-rev",
            },
          ],
        },

        {
          name: "Direction",
          property: "flex-direction",
          type: "radio",
          defaults: "row",
          list: [
            {
              value: "row",
              name: "Row",
              className: "icons-flex icon-dir-row",
              title: "Row",
            },
            {
              value: "row-reverse",
              name: "Row reverse",
              className: "icons-flex icon-dir-row-rev",
              title: "Row reverse",
            },
            {
              value: "column",
              name: "Column",
              title: "Column",
              className: "icons-flex icon-dir-col",
            },
            {
              value: "column-reverse",
              name: "Column reverse",
              title: "Column reverse",
              className: "icons-flex icon-dir-col-rev",
            },
          ],
        },
        {
          name: "Justify",
          property: "justify-content",
          type: "radio",
          defaults: "flex-start",
          list: [
            {
              value: "flex-start",
              className: "icons-flex icon-just-start",
              title: "Start",
            },
            {
              value: "flex-end",
              title: "End",
              className: "icons-flex icon-just-end",
            },
            {
              value: "space-between",
              title: "Space between",
              className: "icons-flex icon-just-sp-bet",
            },
            {
              value: "space-around",
              title: "Space around",
              className: "icons-flex icon-just-sp-ar",
            },
            {
              value: "center",
              title: "Center",
              className: "icons-flex icon-just-sp-cent",
            },
          ],
        },
        {
          name: "Align",
          property: "align-items",
          type: "radio",
          defaults: "center",
          list: [
            {
              value: "flex-start",
              title: "Start",
              className: "icons-flex icon-al-start",
            },
            {
              value: "flex-end",
              title: "End",
              className: "icons-flex icon-al-end",
            },
            {
              value: "stretch",
              title: "Stretch",
              className: "icons-flex icon-al-str",
            },
            {
              value: "center",
              title: "Center",
              className: "icons-flex icon-al-center",
            },
          ],
        },
        {
          name: "Row Gap",
          property: "row-gap",
          type: "integer",
          units: ["px", "em", "%"],
          defaults: 0, // Set your default value
        },
        {
          name: "Column Gap",
          property: "column-gap",
          type: "integer",
          units: ["px", "em", "%"],
          defaults: 0, // Set your default value
        },
        {
          name: "Flex Children",
          property: "label-parent-flex",
          type: "integer",
        },
        {
          name: "Order",
          property: "order",
          type: "integer",
          defaults: 0,
          min: 0,
        },
        {
          name: "Flex",
          property: "flex",
          type: "composite",
          properties: [
            {
              name: "Grow",
              property: "flex-grow",
              type: "integer",
              defaults: 0,
              min: 0,
            },
            {
              name: "Shrink",
              property: "flex-shrink",
              type: "integer",
              defaults: 0,
              min: 0,
            },
            {
              name: "Basis",
              property: "flex-basis",
              type: "integer",
              units: ["px", "%", ""],
              unit: "",
              defaults: "auto",
            },
          ],
        },
        {
          name: "Align",
          property: "align-self",
          type: "radio",
          defaults: "auto",
          list: [
            {
              value: "auto",
              name: "Auto",
            },
            {
              value: "flex-start",
              title: "Start",
              className: "icons-flex icon-al-start",
            },
            {
              value: "flex-end",
              title: "End",
              className: "icons-flex icon-al-end",
            },
            {
              value: "stretch",
              title: "Stretch",
              className: "icons-flex icon-al-str",
            },
            {
              value: "center",
              title: "Center",
              className: "icons-flex icon-al-center",
            },
          ],
        },
      ],
    },
    // {
    //   name: "Animation", // New Animation sector
    //   open: false,
    //   buildProps: ["animation-name", "animation-duration", "animation-timing-function", "animation-iteration-count"],
    //   properties: [
    //     {
    //       name: "Animation Name",
    //       property: "animation-name",
    //       type: "select",
    //       defaults: "none",
    //       options: [
    //         { value: "none", name: "None" },
    //         { value: "rotateClockwise", name: "Rotate Clockwise" },
    //         { value: "bounce", name: "Bounce" },
    //         { value: "flash", name: "Flash" },
    //         { value: "pulse", name: "Pulse" },
    //         { value: "rubberBand", name: "Rubber Band" },
    //         { value: "shake", name: "Shake" },
    //         { value: "headShake", name: "Head Shake" },
    //         { value: "swing", name: "Swing" },
    //         { value: "tada", name: "Tada" },
    //         { value: "wobble", name: "Wobble" },
    //         { value: "jello", name: "Jello" },
    //         { value: "bounceIn", name: "Bounce In" },
    //         { value: "bounceInDown", name: "Bounce In Down" },
    //         { value: "bounceInLeft", name: "Bounce In Left" },
    //         { value: "bounceInRight", name: "Bounce In Right" },
    //         { value: "bounceInUp", name: "Bounce In Up" },
    //         { value: "bounceOut", name: "Bounce Out" },
    //         { value: "bounceOutDown", name: "Bounce Out Down" },
    //         { value: "bounceOutLeft", name: "Bounce Out Left" },
    //         { value: "bounceOutRight", name: "Bounce Out Right" },
    //         { value: "bounceOutUp", name: "Bounce Out Up" },
    //         { value: "fadeIn", name: "Fade In" },
    //         { value: "fadeInDown", name: "Fade In Down" },
    //         { value: "fadeInDownBig", name: "Fade In Down Big" },
    //         { value: "fadeInLeft", name: "Fade In Left" },
    //         { value: "fadeInLeftBig", name: "Fade In Left Big" },
    //         { value: "fadeInRight", name: "Fade In Right" },
    //         { value: "fadeInRightBig", name: "Fade In Right Big" },
    //         { value: "fadeInUp", name: "Fade In Up" },
    //         { value: "fadeInUpBig", name: "Fade In Up Big" },
    //         { value: "fadeOut", name: "Fade Out" },
    //         { value: "fadeOutDown", name: "Fade Out Down" },
    //         { value: "fadeOutDownBig", name: "Fade Out Down Big" },
    //         { value: "fadeOutLeft", name: "Fade Out Left" },
    //         { value: "fadeOutLeftBig", name: "Fade Out Left Big" },
    //         { value: "fadeOutRight", name: "Fade Out Right" },
    //         { value: "fadeOutRightBig", name: "Fade Out Right Big" },
    //         { value: "fadeOutUp", name: "Fade Out Up" },
    //         { value: "fadeOutUpBig", name: "Fade Out Up Big" },
    //         { value: "flip", name: "Flip" },
    //         { value: "flipInX", name: "Flip In X" },
    //         { value: "flipInY", name: "Flip In Y" },
    //         { value: "flipOutX", name: "Flip Out X" },
    //         { value: "flipOutY", name: "Flip Out Y" },
    //         { value: "lightSpeedIn", name: "Light Speed In" },
    //         { value: "lightSpeedOut", name: "Light Speed Out" },
    //         { value: "rotateIn", name: "Rotate In" },
    //         { value: "rotateInDownLeft", name: "Rotate In Down Left" },
    //         { value: "rotateInDownRight", name: "Rotate In Down Right" },
    //         { value: "rotateInUpLeft", name: "Rotate In Up Left" },
    //         { value: "rotateInUpRight", name: "Rotate In Up Right" },
    //         { value: "rotateOut", name: "Rotate Out" },
    //         { value: "rotateOutDownLeft", name: "Rotate Out Down Left" },
    //         { value: "rotateOutDownRight", name: "Rotate Out Down Right" },
    //         { value: "rotateOutUpLeft", name: "Rotate Out Up Left" },
    //         { value: "rotateOutUpRight", name: "Rotate Out Up Right" },
    //         { value: "hinge", name: "Hinge" },
    //         { value: "jackInTheBox", name: "Jack In The Box" },
    //         { value: "rollIn", name: "Roll In" },
    //         { value: "rollOut", name: "Roll Out" },
    //         { value: "zoomIn", name: "Zoom In" },
    //         { value: "zoomInDown", name: "Zoom In Down" },
    //         { value: "zoomInLeft", name: "Zoom In Left" },
    //         { value: "zoomInRight", name: "Zoom In Right" },
    //         { value: "zoomInUp", name: "Zoom In Up" },
    //         { value: "zoomOut", name: "Zoom Out" },
    //         { value: "zoomOutDown", name: "Zoom Out Down" },
    //         { value: "zoomOutLeft", name: "Zoom Out Left" },
    //         { value: "zoomOutRight", name: "Zoom Out Right" },
    //         { value: "zoomOutUp", name: "Zoom Out Up" },
    //         { value: "slideInDown", name: "Slide In Down" },
    //         { value: "slideInLeft", name: "Slide In Left" },
    //         { value: "slideInRight", name: "Slide In Right" },
    //         { value: "slideInUp", name: "Slide In Up" },
    //         { value: "slideOutDown", name: "Slide Out Down" },
    //         { value: "slideOutLeft", name: "Slide Out Left" },
    //         { value: "slideOutRight", name: "Slide Out Right" },
    //         { value: "slideOutUp", name: "Slide Out Up" },
    //       ],
    //     },
    //     {
    //       name: "Duration",
    //       property: "animation-duration",
    //       type: "integer",
    //       units: ["s", "ms"],
    //       defaults: "1s",
    //     },
    //     {
    //       name: "Timing Function",
    //       property: "animation-timing-function",
    //       type: "select",
    //       defaults: "ease",
    //       options: [
    //         { value: "ease", name: "Ease" },
    //         { value: "linear", name: "Linear" },
    //         { value: "ease-in", name: "Ease In" },
    //         { value: "ease-out", name: "Ease Out" },
    //         { value: "ease-in-out", name: "Ease In Out" },
    //       ],
    //     },
    //     {
    //       name: "Iteration Count",
    //       property: "animation-iteration-count",
    //       type: "select",
    //       defaults: "infinite",
    //       options: [
    //         { value: "infinite", name: "Infinite" },
    //         { value: "1", name: "1" },
    //         { value: "2", name: "2" },
    //         { value: "3", name: "3" },
    //       ],
    //     },
    //   ],
    // },
    {
      name: "Component Animations",
      open: false,
      properties: [
        {
          name: "animation",
          type: "composite",
          properties: [
            {
              name: "Animation Name",
              property: "animation-name",
              type: "select",
              default: "none",
              options: [
                { value: "none", name: "None" },
                { value: "rotateClockwise", name: "Rotate Clockwise" },
                { value: "bounce", name: "Bounce" },
                { value: "flash", name: "Flash" },
                { value: "pulse", name: "Pulse" },
                { value: "rubberBand", name: "Rubber Band" },
                { value: "shake", name: "Shake" },
                { value: "headShake", name: "Head Shake" },
                { value: "swing", name: "Swing" },
                { value: "tada", name: "Tada" },
                { value: "wobble", name: "Wobble" },
                { value: "jello", name: "Jello" },
                { value: "bounceIn", name: "Bounce In" },
                { value: "bounceInDown", name: "Bounce In Down" },
                { value: "bounceInLeft", name: "Bounce In Left" },
                { value: "bounceInRight", name: "Bounce In Right" },
                { value: "bounceInUp", name: "Bounce In Up" },
                { value: "bounceOut", name: "Bounce Out" },
                { value: "bounceOutDown", name: "Bounce Out Down" },
                { value: "bounceOutLeft", name: "Bounce Out Left" },
                { value: "bounceOutRight", name: "Bounce Out Right" },
                { value: "bounceOutUp", name: "Bounce Out Up" },
                { value: "fadeIn", name: "Fade In" },
                { value: "fadeInDown", name: "Fade In Down" },
                { value: "fadeInDownBig", name: "Fade In Down Big" },
                { value: "fadeInLeft", name: "Fade In Left" },
                { value: "fadeInLeftBig", name: "Fade In Left Big" },
                { value: "fadeInRight", name: "Fade In Right" },
                { value: "fadeInRightBig", name: "Fade In Right Big" },
                { value: "fadeInUp", name: "Fade In Up" },
                { value: "fadeInUpBig", name: "Fade In Up Big" },
                { value: "fadeOut", name: "Fade Out" },
                { value: "fadeOutDown", name: "Fade Out Down" },
                { value: "fadeOutDownBig", name: "Fade Out Down Big" },
                { value: "fadeOutLeft", name: "Fade Out Left" },
                { value: "fadeOutLeftBig", name: "Fade Out Left Big" },
                { value: "fadeOutRight", name: "Fade Out Right" },
                { value: "fadeOutRightBig", name: "Fade Out Right Big" },
                { value: "fadeOutUp", name: "Fade Out Up" },
                { value: "fadeOutUpBig", name: "Fade Out Up Big" },
                { value: "flip", name: "Flip" },
                { value: "flipInX", name: "Flip In X" },
                { value: "flipInY", name: "Flip In Y" },
                { value: "flipOutX", name: "Flip Out X" },
                { value: "flipOutY", name: "Flip Out Y" },
                { value: "lightSpeedIn", name: "Light Speed In" },
                { value: "lightSpeedOut", name: "Light Speed Out" },
                { value: "rotateIn", name: "Rotate In" },
                { value: "rotateInDownLeft", name: "Rotate In Down Left" },
                { value: "rotateInDownRight", name: "Rotate In Down Right" },
                { value: "rotateInUpLeft", name: "Rotate In Up Left" },
                { value: "rotateInUpRight", name: "Rotate In Up Right" },
                { value: "rotateOut", name: "Rotate Out" },
                { value: "rotateOutDownLeft", name: "Rotate Out Down Left" },
                { value: "rotateOutDownRight", name: "Rotate Out Down Right" },
                { value: "rotateOutUpLeft", name: "Rotate Out Up Left" },
                { value: "rotateOutUpRight", name: "Rotate Out Up Right" },
                { value: "hinge", name: "Hinge" },
                { value: "jackInTheBox", name: "Jack In The Box" },
                { value: "rollIn", name: "Roll In" },
                { value: "rollOut", name: "Roll Out" },
                { value: "zoomIn", name: "Zoom In" },
                { value: "zoomInDown", name: "Zoom In Down" },
                { value: "zoomInLeft", name: "Zoom In Left" },
                { value: "zoomInRight", name: "Zoom In Right" },
                { value: "zoomInUp", name: "Zoom In Up" },
                { value: "zoomOut", name: "Zoom Out" },
                { value: "zoomOutDown", name: "Zoom Out Down" },
                { value: "zoomOutLeft", name: "Zoom Out Left" },
                { value: "zoomOutRight", name: "Zoom Out Right" },
                { value: "zoomOutUp", name: "Zoom Out Up" },
                { value: "slideInDown", name: "Slide In Down" },
                { value: "slideInLeft", name: "Slide In Left" },
                { value: "slideInRight", name: "Slide In Right" },
                { value: "slideInUp", name: "Slide In Up" },
                { value: "slideOutDown", name: "Slide Out Down" },
                { value: "slideOutLeft", name: "Slide Out Left" },
                { value: "slideOutRight", name: "Slide Out Right" },
                { value: "slideOutUp", name: "Slide Out Up" },
              ],
            },
            {
              name: "Duration",
              property: "animation-duration",
              type: "integer",
              units: ["s", "ms"],
              default: "1s",
            },
            {
              name: "Timing Function",
              property: "animation-timing-function",
              type: "select",
              default: "ease",
              options: [
                { value: "ease", name: "Ease" },
                { value: "linear", name: "Linear" },
                { value: "ease-in", name: "Ease In" },
                { value: "ease-out", name: "Ease Out" },
                { value: "ease-in-out", name: "Ease In Out" },
              ],
            },
            // {
            //   name: "Delay",
            //   property: "animation-delay",
            //   type: "integer",
            //   units: ["s", "ms"],
            //   default: "0s",
            // },
            {
              name: "Iteration Count",
              property: "animation-iteration-count",
              type: "select",
              default: "infinite",
              options: [
                { value: "infinite", name: "Infinite" },
                { value: "1", name: "1" },
                { value: "2", name: "2" },
                { value: "3", name: "3" },
                { value: "4", name: "4" },
                { value: "5", name: "5" },
              ],
            },
            {
              name: "Direction",
              property: "animation-direction",
              type: "select",
              default: "normal",
              options: [
                { value: "normal", name: "Normal" },
                { value: "reverse", name: "Reverse" },
                { value: "alternate", name: "Alternate" },
                { value: "alternate-reverse", name: "Alternate Reverse" },
              ],
            },
            {
              name: "Fill Mode",
              property: "animation-fill-mode",
              type: "select",
              default: "none",
              options: [
                { value: "none", name: "None" },
                { value: "forwards", name: "Forwards" },
                { value: "backwards", name: "Backwards" },
                { value: "both", name: "Both" },
              ],
            },
            {
              name: "Play State",
              property: "animation-play-state",
              type: "select",
              default: "running",
              options: [
                { value: "running", name: "Running" },
                { value: "paused", name: "Paused" },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "Text Animations",
      open: false,
      properties: [
        {
          name: "animation",
          type: "composite",
          properties: [
            {
              name: "Animation Name",
              property: "animation-name",
              type: "select",
              defaults: "none",
              options: [
                { value: "none", name: "None" },
                { value: "shadow-dance", name: "Shadow Dance" },
                { value: "diagonal-rise", name: "Diagonal Rise" },
                { value: "glitch", name: "Glitch" },
                { value: "glow", name: "Glow" },
                { value: "slide-in-left", name: "Slide In Left" },
                { value: "slide-in-right", name: "Slide In Right" },
                { value: "wobble", name: "Wobble" },
                { value: "glow-2", name: "Glow-2" },
                { value: "ghostMove", name: "Ghost Move" },
                { value: "sparkle", name: "Sparkle" },
                { value: "shimmer", name: "Shimmer" },
                { value: "frameMove", name: "Frame Move" },
                { value: "aurora-border", name: "Aurora Border" },
                { value: "lights", name: "Lights" },
                { value: "spotlight", name: "Spotlight" },
              ],
            },
            {
              name: "Duration",
              property: "animation-duration",
              type: "integer",
              units: ["s", "ms"],
              defaults: "1s",
            },
            {
              name: "Timing Function",
              property: "animation-timing-function",
              type: "select",
              defaults: "ease",
              options: [
                { value: "ease", name: "Ease" },
                { value: "linear", name: "Linear" },
                { value: "ease-in", name: "Ease In" },
                { value: "ease-out", name: "Ease Out" },
                { value: "ease-in-out", name: "Ease In Out" },
              ],
            },
            // {
            //   name: "Delay",
            //   property: "animation-delay",
            //   type: "integer",
            //   units: ["s", "ms"],
            //   defaults: "0s",
            // },
            {
              name: "Iteration Count",
              property: "animation-iteration-count",
              type: "select",
              defaults: "infinite",
              options: [
                { value: "infinite", name: "Infinite" },
                { value: "1", name: "1" },
                { value: "2", name: "2" },
                { value: "3", name: "3" },
                { value: "4", name: "4" },
                { value: "5", name: "5" },
              ],
            },
            {
              name: "Direction",
              property: "animation-direction",
              type: "select",
              defaults: "normal",
              options: [
                { value: "normal", name: "Normal" },
                { value: "reverse", name: "Reverse" },
                { value: "alternate", name: "Alternate" },
                { value: "alternate-reverse", name: "Alternate Reverse" },
              ],
            },
            {
              name: "Fill Mode",
              property: "animation-fill-mode",
              type: "select",
              defaults: "none",
              options: [
                { value: "none", name: "None" },
                { value: "forwards", name: "Forwards" },
                { value: "backwards", name: "Backwards" },
                { value: "both", name: "Both" },
              ],
            },
            {
              name: "Play State",
              property: "animation-play-state",
              type: "select",
              defaults: "running",
              options: [
                { value: "running", name: "Running" },
                { value: "paused", name: "Paused" },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export const layerManager = {
  appendTo: "#layer-container",
};

export const traitManager = {
  appendTo: "#trait-container",
};

export const selectorManager = {
  appendTo: "#styles-container",
  // this is done so that compoenents with same class names also don't affect each other on getting styled
  componentFirst: true,
};

export let panels = {
  defaults: [
    {
      id: "basic-actions",
      el: ".panel__basic-actions",
      buttons: [
        {
          id: "visibility",
          active: true,
          className: "btn-toggle-borders",
          label: '<i class="fa fa-clone"></i>',
          command: "sw-visibility",
        },
      ],
    },
    {
      id: "editor-actions",
      el: ".panel__editor",
      buttons: [
        // 'fa' icon library for reference - https://fontawesome.com/v4/icons/
        {
          id: "saveDb",
          className: "fa fa-paper-plane",
          attributes: { title: "Save progress" },
          command: "saveDb",
        },
        {
          id: "cmd-clear",
          className: "fa fa-trash",
          attributes: { title: "Clear editor" },
          command: "cmd-clear",
        },
        {
          id: "undo",
          className: "fa fa-undo",
          attributes: { title: "Undo" },
          command: "undo",
        },
        {
          id: "redo",
          className: "fa fa-repeat",
          attributes: { title: "Redo" },
          command: "redo",
        },
        {
          id: "export",
          className: "fa fa-download",
          attributes: { title: "Download project" },
          command: "export",
        },
        {
          id: "import",
          className: "fa fa-upload",
          attributes: { title: "Upload an excel/csv file" },
          command: "import",
        },
        {
          id: "view-import",
          className: "fa fa-file-excel-o",
          attributes: { title: "View any imported excel/CSV file" },
          command: "view-import",
        },
        // Preview
        // {
        //   id: "preview",
        //   className: "fa fa-eye",
        //   attributes: { title: "Preview" },
        //   command: "preview",
        //   run: (editor, sender) => {
        //     editor.runCommand("preview");
        //   },
        // }
      ],
    },
    {
      id: "panel-devices",
      el: ".panel__devices",
      buttons: [
        {
          id: "device-desktop",
          label: '<i class="fa fa-television"></i>',
          command: "set-device-desktop",
          active: true,
          togglable: false,
          attributes: { title: "Desktop view" },
        },
        {
          id: "device-mobile",
          label: '<i class="fa fa-mobile"></i>',
          command: "set-device-mobile",
          togglable: false,
          attributes: { title: "Mobile view" },
        },
      ],
    },
  ],
};

export const deviceManager = {
  devices: [
    {
      name: "Desktop",
      width: "",
    }, {
      name: "Laptop1024px",
      width: "1024px",
    }, {
      name: "MacBookAir",
      width: "1280px",
      // height: "832px",
    }, {
      name: "Laptop1440px",
      width: "1440px",
    }, {
      name: "MacBookPro14",
      width: "1512px",
      // height: "982px",
    }, {
      name: "MacBookPro16",
      width: "1728px",
      // height: "1117px",
    }, {
      name: "Desktop2560px",
      width: "2560px",
    }, {
      name: "Mobile",
      width: "480px",
      // widthMedia: "480px",
    }, {
      name: "Android",
      width: "360px",
      // height: "800px",
      // widthMedia: "480px",
    }, {
      name: "iPhoneSE",
      width: "375px",
      height: "667px",
      // widthMedia: "480px",
    }, {
      name: "iPhoneXR",
      width: "414px",
      height: "896px",
      // widthMedia: "480px",
    }, {
      name: "iPhone12Pro",
      width: "390px",
      height: "844px",
      // widthMedia: "480px",
    }, {
      name: "iPhone13Mini",
      width: "375px",
      height: "812px",
      // widthMedia: "480px",
    }, {
      name: "iPhone14Plus",
      width: "428px",
      height: "926px",
      // widthMedia: "480px",
    }, {
      name: "iPhone14ProMax",
      width: "430px",
      height: "932px",
      // widthMedia: "480px",
    }, {
      name: "Pixel7",
      width: "412px",
      height: "915px",
      // widthMedia: "480px",
    }, {
      name: "SamsungGalaxyS8",
      width: "360px",
      height: "740px",
      // widthMedia: "480px",
    }, {
      name: "SamsungGalaxyS20Ultra",
      width: "412px",
      height: "915px",
      // widthMedia: "480px",
    }, {
      name: "GalaxyFold",
      width: "280px",
      height: "653px",
      // widthMedia: "480px",
    }, {
      name: "SamsungGalaxyA5171",
      width: "412px",
      height: "914px",
      // widthMedia: "480px",
    },
  ],
};

// function dropdownCss(uniqueId) {
//   // directional dropdown
//   result.push({
//     selectors: `.d-dropdown.${uniqueId}`,
//     style: {
//       display: "inline-block",
//       position: "relative",
//       padding: "0.5em !important",
//       transition: "all 150ms linear",
//     },
//   });

//   result.push({
//     selectors: `.d-dropdown.${uniqueId} button.btn-${uniqueId}`,
//     style: {
//       border: "none",
//       "border-radius": "5px",
//       padding: "0.5em",
//       "font-size": "16px",
//       cursor: "pointer",
//       display: "flex",
//       "align-items": "center",
//     },
//   });

//   result.push({
//     selectors: `.d-dropdown.${uniqueId} button.btn-${uniqueId}:hover`,
//     style: {
//       "background-color": "#ddd",
//     },
//   });

//   result.push({
//     selectors: `.d-dropdown.${uniqueId} button.btn-${uniqueId} span.pointer-${uniqueId}`,
//     style: {
//       "margin-left": "0.5em",
//       width: "0.5em",
//       height: "0.5em",
//       "background-color": "#ddd",
//       "clip-path": "polygon(50% 100%, 0 0, 100% 0)",
//     },
//   });

//   result.push({
//     selectors: `.d-dropdown.${uniqueId} button.btn-${uniqueId}:hover span.pointer-${uniqueId}`,
//     style: {
//       "background-color": "white",
//     },
//   });

//   result.push({
//     selectors: `.d-dropdown.${uniqueId} .directional-dropdown-options.opt-${uniqueId}`,
//     style: {
//       display: "none",
//       position: "absolute",
//       "background-color": "#fff",
//       "border-radius": "5px",
//       "box-shadow": "0px 5px 5px 0px rgba(0,0,0,0.4)",
//     },
//   });

//   result.push({
//     selectors: `.d-dropdown.${uniqueId}:hover .directional-dropdown-options.opt-${uniqueId}`,
//     style: {
//       display: "block",
//     },
//   });

//   result.push({
//     selectors: `.d-dropdown.${uniqueId}[data-hovering="true"] .directional-dropdown-options.opt-${uniqueId}`,
//     style: {
//       display: "block",
//     },
//   });

//   result.push({
//     selectors: `.d-dropdown.${uniqueId}[data-direction="under"] .directional-dropdown-options.opt-${uniqueId}`,
//     style: {
//       top: "100%",
//       position: "static",
//     },
//   });

//   result.push({
//     selectors: `d-dropdown.${uniqueId}[data-direction="under"] button.btn-${uniqueId} span.pointer-${uniqueId}`,
//     style: {
//       "clip-path": "polygon(50% 100%, 0 0, 100% 0)",
//     },
//   });

//   result.push({
//     selectors: `d-dropdown.${uniqueId}[data-direction="over"] button.btn-${uniqueId} span.pointer-${uniqueId}`,
//     style: {
//       "clip-path": "polygon(50% 0%, 0% 100%, 100% 100%) !important",
//     },
//   });

//   result.push({
//     selectors: `.d-dropdown.${uniqueId}[data-direction="over"] .directional-dropdown-options.opt-${uniqueId}`,
//     style: {
//       bottom: "100%",
//     },
//   });

//   result.push({
//     selectors: `.d-dropdown.${uniqueId}[data-direction="right"] .directional-dropdown-options.opt-${uniqueId}`,
//     style: {
//       left: "100%",
//       top: "-10%",
//     },
//   });

//   result.push({
//     selectors: `d-dropdown.${uniqueId}[data-direction="right"] button.btn-${uniqueId} span.pointer-${uniqueId}`,
//     style: {
//       "clip-path": "polygon(100% 50%, 0 0, 0 100%)",
//     },
//   });

//   result.push({
//     selectors: `.d-dropdown.${uniqueId}[data-direction="left"] .directional-dropdown-options.opt-${uniqueId}`,
//     style: {
//       right: "100%",
//       top: "-10%",
//     },
//   });

//   result.push({
//     selectors: `d-dropdown.${uniqueId}[data-direction="left"] button.btn-${uniqueId} span.pointer-${uniqueId}`,
//     style: {
//       "clip-path": "polygon(0 50%, 100% 0, 100% 100%)",
//     },
//   });

//   result.push({
//     selectors: `.d-dropdown.${uniqueId} .directional-dropdown-options.opt-${uniqueId} a`,
//     style: {
//       display: "block",
//       color: "#000000",
//       padding: "5px",
//       "text-decoration": "none",
//       padding: "20px 40px",
//     },
//   });

//   result.push({
//     selectors: `.d-dropdown.${uniqueId} .directional-dropdown-options.opt-${uniqueId} a:hover`,
//     style: {
//       color: "#0a0a23",
//       "background-color": "#ddd",
//       "border-radius": "5px",
//     },
//   });
// }

function cssResult(editor, uniqueId) {
  // FONT FACES....................................

  // REGULAR CSS....................................
  result.push({
    selectors: "html",
    style: {
      "scroll-behavior": "smooth",
    },
  });

  result.push({
    selectors: "body",
    style: {
      padding: "0",
      margin: "0",
      "box-sizing": "border-box",
      "overflow-x": "hidden !important",
      "overflow-y": "scroll !important",
      "scroll-behavior": "smooth",
      position: "relative",
      height: "100%",
      "-ms-overflow-style": "none",
      "scrollbar-width": "none",
    },
  });

  result.push({
    selectors: "body::-webkit-scrollbar",
    style: {
      display: "none",
    },
  });

  result.push({
    selectors: "a",
    style: {
      "text-decoration": "none !important",
      color: "inherit",
    },
  });

  result.push({
    selectors: "img",
    style: {
      position: "relative",
    },
  });

  // result.push({
  //     // params: '(max-width: 480px)',
  //     selectors: '[data-gjs-type=wrapper]',
  //     // atRule: 'media',
  //     style: {
  //         'overflow-y': 'scroll !important',
  //         height: '100% !important',
  //         'padding-bottom': '2rem  !important',
  //         'min-height': '100% !important'
  //     },
  // });

  // so that the body of the editor/webpage is filled completely and remains constant while editor reloads
  result.push({
    selectors: "#grapes-iframe-body",
    style: {
      "padding-bottom": "1.5rem",
      margin: "0",
      "box-sizing": "border-box",
      height: "100% !important",
      "overflow-y": "scroll !important",
      "overflow-x": "hidden !important",
    },
  });

  // result.push({
  //     params: '(max-width: 480px)',
  //     selectors: 'body',
  //     atRule: 'media',
  //     style: {
  //         'background-color': 'green !important',
  //     },
  // });

  result.push({
    params: "(max-width: 480px)",
    selectors: "video",
    atRule: "media",
    style: {
      "max-width": "100% !important",
    },
  });

  // stellar-btn
  result.push({
    selectors: ".stellar-btn",
    style: {
      width: "5em",
      padding: "0.5em",
      "border-radius": "10px",
      "background-color": "blue",
      border: "none",
      color: "white",
      "text-align": "center",
      cursor: "pointer",
    },
  });

  result.push({
    selectors: ".stellar-btn > p",
    style: {
      margin: "auto",
      "text-align": "center",
    },
  });

  // predefined-email-dispatch
  result.push({
    selectors: ".predefined-email-dispatch",
    style: {
      width: "max-content",
      padding: "0.5em",
      "border-radius": "10px",
      "text-align": "center",
      "background-color": "blue",
      color: "white",
      border: "none",
      cursor: "pointer",
    },
  });

  result.push({
    selectors: ".predefined-email-dispatch > p",
    style: {
      margin: "auto",
      "text-align": "center",
    },
  });

  result.push({
    selectors: ".customize-receiver-email-dispatch",
    style: {
      width: "max-content",
      padding: "0.5em",
      "border-radius": "10px",
      "text-align": "center",
      cursor: "pointer",
      "background-color": "blue",
      color: "white",
      border: "none",
    },
  });

  result.push({
    selectors: ".customize-receiver-email-dispatch > p",
    style: {
      margin: "auto",
      "text-align": "center",
    },
  });

  result.push({
    selectors: ".contain-customize-receiver-email-dispatch",
    style: {
      width: "max-content",
      padding: "0.5em",
    },
  });

  // popup-button
  result.push({
    selectors: ".contain-popup-button",
    style: {
      width: "max-content",
      padding: "0.5em",
    },
  });

  result.push({
    selectors: ".popup-button",
    style: {
      "background-color": "blue",
      color: "white",
      border: "none",
    },
  });

  result.push({
    selectors: ".popup-button > p",
    style: {
      margin: "auto",
      "text-align": "center",
    },
  });

  result.push({
    selectors: ".popup-button-modal",
    style: {
      position: "absolute !important",
      width: "100% !important",
      height: "100% !important",
      border: "none !important",
      padding: "0.5em !important",
      top: "0 !important",
      bottom: "0 !important",
      left: "0 !important",
      right: "0 !important",
    },
  });

  result.push({
    selectors: ".popup-button-modal .modal-dialog .modal-content",
    style: {
      padding: "0.5em !important",
    },
  });

  // page-popup
  result.push({
    selectors: ".contain-page-popup",
    style: {
      width: "max-content",
      padding: "0.5em",
    },
  });

  result.push({
    selectors: ".page-popup-modal",
    style: {
      position: "absolute !important",
      width: "100% !important",
      height: "100% !important",
      border: "none !important",
      padding: "0.5em !important",
      top: "0 !important",
      bottom: "0 !important",
      left: "0 !important",
      right: "0 !important",
    },
  });

  result.push({
    selectors: ".page-popup",
    style: {
      "background-color": "blue",
      color: "white",
      border: "none",
    },
  });

  result.push({
    selectors: ".page-popup > p",
    style: {
      margin: "auto",
      "text-align": "center",
    },
  });

  result.push({
    selectors: ".page-popup-modal .modal-dialog",
    style: {
      padding: "1em",
    },
  });

  result.push({
    selectors: ".page-popup-modal .modal-dialog .modal-content",
    style: {
      padding: "0.5em",
      // width: "90%",
      // height: "20em",

      // position: "absolute !important",
      // left: "-80%",
    },
  });

  // result.push({
  //   selectors: ".page-popup-modal .modal-dialog .modal-content .modal-body",
  //   style: {
  //     maxWidth: "90%",
  //   },
  // });

  // Text box with auto search and api
  result.push({
    selectors: "#text-box-auto-search-container",
    style: {
      width: "100%",
      padding: "0.5em",
    },
  });

  result.push({
    selectors: ".text-box-auto-search-input",
    style: {
      width: "100%",
    },
  });

  result.push({
    selectors: ".g-map-container",
    style: {
      width: "100%",
      height: "40%",
    },
  });

  // empty-form-container
  result.push({
    selectors: "#empty-form-container",
    style: {
      width: "100%",
      height: "50%",
      padding: "1em",
    },
  });

  result.push({
    selectors: "#label-regular",
    style: {
      width: "100%",
      padding: "0.5em",
      display: "block",
    },
  });

  result.push({
    selectors: "#text-regular",
    style: {
      display: "flex",
      "flex-direction": "column",
      "row-gap": "0.5em",
      width: "100%",
    },
  });

  result.push({
    selectors: "#email-regular",
    style: {
      display: "flex",
      "flex-direction": "column",
      "row-gap": "0.5em",
      width: "100%",
    },
  });

  result.push({
    selectors: "#pwd-regular",
    style: {
      display: "flex",
      "flex-direction": "column",
      "row-gap": "0.5em",
      width: "100%",
    },
  });

  result.push({
    selectors: "#tele-regular",
    style: {
      display: "flex",
      "flex-direction": "column",
      "row-gap": "0.5em",
      width: "100%",
    },
  });

  result.push({
    selectors: "#url-regular",
    style: {
      display: "flex",
      "flex-direction": "column",
      "row-gap": "0.5em",
      width: "100%",
    },
  });

  result.push({
    selectors: "#radio-regular",
    style: {
      display: "flex",
      "column-gap": "0.5em",
    },
  });

  result.push({
    selectors: "#colorPalette-regular-form",
    style: {
      display: "flex",
      "column-gap": "0.5em",
      "align-items": "center",
    },
  });

  result.push({
    selectors: "#week-regular",
    style: {
      display: "flex",
      "column-gap": "0.5em",
      "align-items": "center",
    },
  });

  result.push({
    selectors: "#monthYear-regular",
    style: {
      display: "flex",
      "column-gap": "0.5em",
      "align-items": "center",
    },
  });

  result.push({
    selectors: "#time-regular",
    style: {
      display: "flex",
      "column-gap": "0.5em",
      "align-items": "center",
    },
  });

  result.push({
    selectors: "#file-regular",
    style: {
      display: "flex",
      "column-gap": "0.5em",
      "align-items": "center",
    },
  });

  result.push({
    selectors: "#number-regular",
    style: {
      display: "flex",
      "column-gap": "0.5em",
      "align-items": "center",
    },
  });

  result.push({
    selectors: "#range-regular",
    style: {
      display: "flex",
      "column-gap": "0.5em",
      "align-items": "center",
    },
  });

  result.push({
    selectors: "#reset-regular",
    style: {
      display: "flex",
      "column-gap": "0.5em",
      "align-items": "center",
    },
  });

  result.push({
    selectors: "#submit-regular",
    style: {
      display: "flex",
      "column-gap": "0.5em",
      "align-items": "center",
    },
  });

  result.push({
    selectors: "#upload",
    style: {
      "min-width": "100%",
      "min-height": "5px",
      padding: "0.5em",
    },
  });

  // table
  result.push({
    selectors: "table",
    style: {
      "max-width": "100%",
      margin: "auto",
      "border-spacing": "100px",
    },
  });

  result.push({
    selectors: "table tr",
    style: {
      "max-width": "100%",
    },
  });

  result.push({
    selectors: "table tr td",
    style: {
      "max-width": "5em",
      "word-wrap": "break-word",
    },
  });

  // C-SWIPER component slide height
  result.push({
    selectors: ".swiper-slide",
    style: {
      height: "80%",
    },
  });

  // file upload button
  result.push({
    selectors: ".file-btn-regular-form",
    style: {
      padding: "0.5em",
    },
  });

  result.push({
    selectors: "#upload-file",
    style: {
      "max-width": "100%",
      "min-height": "5px",
      padding: "0.5em",
    },
  });

  result.push({
    selectors: ".FileUploadBtnDiv",
    style: {
      width: "max-content",
    },
  });

  result.push({
    selectors: ".file-upload-btn-label",
    style: {
      width: "max-content",
      display: "inline-block",
      padding: "0.5em",
      border: "1px solid black",
      "border-radius": "10px",
      cursor: "pointer",
    },
  });

  result.push({
    selectors: ".file-upload-btn-label p",
    style: {
      margin: "auto",
      "text-align": "center",
    },
  });

  result.push({
    selectors: ".file-upload-btn-input",
    style: {
      display: "none",
      // 'z-index': '10',
    },
  });

  // directional-dd
  // result.push({
  //   selectors: ".dropdown-dd",
  //   style: {
  //     width: "max-content",
  //     padding: "0.5em",
  //     position: "relative",
  //   },
  // });

  // result.push({
  //   selectors: ".dropdown-dd-btn",
  //   style: {
  //     width: "15vw",
  //     padding: "0.5em",
  //     cursor: "pointer",
  //   },
  // });

  // result.push({
  //   selectors: ".dropdown-item-container",
  //   style: {
  //     // display: "none",
  //     padding: "0.5em",
  //     width: "15vw",
  //     position: "absolute !important",
  //     top: "105%",
  //     left: "0",
  //     borderRadius: "10px",
  //     backgroundColor: "white !important",
  //     borderRadius: "10px",
  //     border: "1px solid gray",
  //   },
  // });

  // result.push({
  //   selectors: ".dropdown-item-container .dropdown-item",
  //   style: {
  //     padding: "0.5em",
  //     cursor: "pointer",
  //   },
  // });

  result.push({
    selectors: ".dropdown-item-container",
    style: {
      display: 'none',
    },
  });

  // dropdown componenet in block manager
  // result.push({
  //   selectors: ".dropdown",
  //   style: {
  //     display: "inline-block",
  //     position: "relative",
  //     padding: "0.5em !important",
  //     transition: "all 150ms linear",
  //   },
  // });

  // result.push({
  //   selectors: ".dropdown button",
  //   style: {
  //     border: "none",
  //     "border-radius": "5px",
  //     padding: "0.5em",
  //     "margin-left": "auto !important",
  //     "margin-right": "auto !important",
  //     "font-size": "16px",
  //     cursor: "pointer",
  //   },
  // });

  // result.push({
  //   selectors: ".dropdown button:hover",
  //   style: {
  //     "background-color": "#ddd",
  //   },
  // });

  // result.push({
  //   selectors: ".dropdown .dropdown-options",
  //   style: {
  //     display: "none",
  //     position: "absolute",
  //     "background-color": "#fff",
  //     "border-radius": "5px",
  //     "box-shadow": "0px 5px 5px 0px rgba(0,0,0,0.4)",
  //   },
  // });

  // result.push({
  //   selectors: ".dropdown:hover .dropdown-options",
  //   style: {
  //     display: "block",
  //   },
  // });

  // result.push({
  //   selectors: '[data-hovering="true"] .dropdown-options',
  //   style: {
  //     display: "block",
  //   },
  // });

  // result.push({
  //   selectors: ".dropdown .dropdown-options a",
  //   style: {
  //     display: "block",
  //     color: "#000000",
  //     padding: "5px",
  //     "text-decoration": "none",
  //     padding: "20px 40px",
  //   },
  // });

  // result.push({
  //   selectors: ".dropdown .dropdown-options a:hover",
  //   style: {
  //     color: "#0a0a23",
  //     "background-color": "#ddd",
  //     "border-radius": "5px",
  //   },
  // });

  // directional dropdown
  if (uniqueId !== 1) {
    console.log("ID not 1,", uniqueId);

    // result.push({
    //   selectors: `.d-dropdown.${uniqueId}`,
    //   style: {
    //     display: "inline-block",
    //     position: "relative",
    //     padding: "0.5em !important",
    //     transition: "all 150ms linear",
    //   },
    // });

    // result.push({
    //   selectors: `.d-dropdown.${uniqueId} button.btn-${uniqueId}`,
    //   style: {
    //     border: "none",
    //     "border-radius": "5px",
    //     padding: "0.5em",
    //     "font-size": "16px",
    //     cursor: "pointer",
    //     display: "flex",
    //     "align-items": "center",
    //   },
    // });

    // result.push({
    //   selectors: `.d-dropdown.${uniqueId} button.btn-${uniqueId}:hover`,
    //   style: {
    //     "background-color": "#ddd",
    //   },
    // });

    // result.push({
    //   selectors: `.d-dropdown.${uniqueId} button.btn-${uniqueId} span.pointer-${uniqueId}`,
    //   style: {
    //     "margin-left": "0.5em",
    //     width: "0.5em",
    //     height: "0.5em",
    //     "background-color": "#ddd",
    //     "clip-path": "polygon(50% 100%, 0 0, 100% 0)",
    //   },
    // });

    // result.push({
    //   selectors: `.d-dropdown.${uniqueId} button.btn-${uniqueId}:hover span.pointer-${uniqueId}`,
    //   style: {
    //     "background-color": "white",
    //   },
    // });

    // result.push({
    //   selectors: `.d-dropdown.${uniqueId} .directional-dropdown-options.opt-${uniqueId}`,
    //   style: {
    //     display: "none",
    //     position: "absolute",
    //     "background-color": "#fff",
    //     "border-radius": "5px",
    //     "box-shadow": "0px 5px 5px 0px rgba(0,0,0,0.4)",
    //   },
    // });

    // result.push({
    //   selectors: `.d-dropdown.${uniqueId}:hover .directional-dropdown-options.opt-${uniqueId}`,
    //   style: {
    //     display: "block",
    //   },
    // });

    // result.push({
    //   selectors: `.d-dropdown.${uniqueId}[data-hovering="true"] .directional-dropdown-options.opt-${uniqueId}`,
    //   style: {
    //     display: "block",
    //   },
    // });

    // result.push({
    //   selectors: `.d-dropdown.${uniqueId}[data-direction="under"] .directional-dropdown-options.opt-${uniqueId}`,
    //   style: {
    //     top: "100%",
    //     position: "static",
    //   },
    // });

    // result.push({
    //   selectors: `d-dropdown.${uniqueId}[data-direction="under"] button.btn-${uniqueId} span.pointer-${uniqueId}`,
    //   style: {
    //     "clip-path": "polygon(50% 100%, 0 0, 100% 0)",
    //   },
    // });

    // result.push({
    //   selectors: `d-dropdown.${uniqueId}[data-direction="over"] button.btn-${uniqueId} span.pointer-${uniqueId}`,
    //   style: {
    //     "clip-path": "polygon(50% 0%, 0% 100%, 100% 100%) !important",
    //   },
    // });

    // result.push({
    //   selectors: `.d-dropdown.${uniqueId}[data-direction="over"] .directional-dropdown-options.opt-${uniqueId}`,
    //   style: {
    //     bottom: "100%",
    //   },
    // });

    // result.push({
    //   selectors: `.d-dropdown.${uniqueId}[data-direction="right"] .directional-dropdown-options.opt-${uniqueId}`,
    //   style: {
    //     left: "100%",
    //     top: "-10%",
    //   },
    // });

    // result.push({
    //   selectors: `d-dropdown.${uniqueId}[data-direction="right"] button.btn-${uniqueId} span.pointer-${uniqueId}`,
    //   style: {
    //     "clip-path": "polygon(100% 50%, 0 0, 0 100%)",
    //   },
    // });

    // result.push({
    //   selectors: `.d-dropdown.${uniqueId}[data-direction="left"] .directional-dropdown-options.opt-${uniqueId}`,
    //   style: {
    //     right: "100%",
    //     top: "-10%",
    //   },
    // });

    // result.push({
    //   selectors: `d-dropdown.${uniqueId}[data-direction="left"] button.btn-${uniqueId} span.pointer-${uniqueId}`,
    //   style: {
    //     "clip-path": "polygon(0 50%, 100% 0, 100% 100%)",
    //   },
    // });

    // result.push({
    //   selectors: `.d-dropdown.${uniqueId} .directional-dropdown-options.opt-${uniqueId} a`,
    //   style: {
    //     display: "block",
    //     color: "#000000",
    //     padding: "5px",
    //     "text-decoration": "none",
    //     padding: "20px 40px",
    //   },
    // });

    // result.push({
    //   selectors: `.d-dropdown.${uniqueId} .directional-dropdown-options.opt-${uniqueId} a:hover`,
    //   style: {
    //     color: "#0a0a23",
    //     "background-color": "#ddd",
    //     "border-radius": "5px",
    //   },
    // });
  }

  // mob-segment-button component in block manager
  result.push({
    selectors: ".tab",
    style: {
      "background-color": "#f1f1f1",
      overflow: "hidden",
      border: "1px solid #ccc",
    },
  });

  result.push({
    selectors: ".tab button",
    style: {
      "background-color": "inherit",
      float: "left",
      border: "none",
      outline: "none",
      cursor: "pointer",
      padding: "14px 16px",
      transition: "0.3s",
    },
  });

  result.push({
    selectors: ".tab button:hover",
    style: {
      "background-color": "#ddd",
    },
  });

  result.push({
    selectors: ".tab button.active",
    style: {
      "background-color": "#ccc",
    },
  });

  result.push({
    selectors: ".tabcontent",
    style: {
      display: "none",
      padding: "6px 12px",
      border: "1px solid #ccc",
      "border-top": "none",
    },
  });

  // navbar component in block manager
  result.push({
    selectors: ".nav-comp-div",
    style: {
      width: "100%",
    },
  });

  result.push({
    selectors: ".nav-comp-div ul",
    style: {
      display: "flex",
      "flex-direction": "row",
      "flex-wrap": "wrap",
      "justify-content": "space-around",
      "align-items": "center",
      "max-width": "90%",
      "margin-left": "auto",
      "margin-right": "auto",
      "margin-top": "auto",
      "margin-bottom": "auto",
    },
  });
  result.push({
    selectors: ".nav-comp-div ul li",
    style: {
      "list-style": "none",
      margin: "0.5em",
    },
  });
  result.push({
    params: "(max-width: 480px)",
    selectors: ".nav-comp-div",
    atRule: "media",
    style: {
      display: "none",
    },
  });
  result.push({
    selectors: ".hamburger-nav",
    style: {
      display: "block",
      position: "relative",
    },
  });
  result.push({
    selectors: ".hamburger-nav-img",
    style: {
      "margin-right": "3%",
      "margin-top": "3%",
      width: "1.5em",
      float: "right",
    },
  });
  result.push({
    selectors: "#toggle-nav",
    style: {
      position: "absolute",
      right: "0",
      top: "0",
      width: "2.5em",
      height: "2.5em",
      float: "right",
      opacity: "0",
      "z-index": "2",
    },
  });
  result.push({
    selectors: "#toggle-nav+ul",
    style: {
      display: "none",
      transition: "all 100ms",
    },
  });
  result.push({
    selectors: "#toggle-nav+ul li",
    style: {
      "list-style": "none",
      "margin-bottom": "5%",
    },
  });
  result.push({
    selectors: "#toggle-nav:checked+ul",
    style: {
      display: "flex",
      "flex-direction": "column",
      "justify-content": "space-around",
      "align-items": "flex-end",
      "max-width": "max-content",
      position: "absolute",
      "margin-top": "105%",
      right: "5%",
    },
  });

  result.push({
    params: "(min-width: 500px)",
    selectors: ".hamburger-nav",
    atRule: "media",
    style: {
      display: "none",
    },
  });

  // form component in block manager
  result.push({
    selectors: "#text-regular-form, #email-regular-form, #pwd-regular-form",
    style: {
      display: "flex",
      "flex-direction": "column",
      "row-gap": "0.5em",
    },
  });

  result.push({
    selectors: "#colorPalette-regular-form",
    style: {
      display: "flex",
      "column-gap": "0.5em",
      "align-items": "center",
    },
  });

  result.push({
    selectors: "#checkbox-regular-form div, #radio-regular-form div",
    style: {
      display: "flex",
      "column-gap": "0.5em",
    },
  });

  // BASIC BLOCK columns
  result.push({
    selectors: ".gjs-row",
    style: {
      display: "table",
      padding: "10px",
      width: "100%",
    },
  });

  result.push({
    selectors: ".gjs-cell",
    style: {
      width: "8%",
      display: "table-cell",
      height: "75px",
    },
  });

  // TEMPLATE REDSLING
  result.push({
    selectors: ".wrapper",
    style: {
      "z-index": "100",
      "background-color": "#FFFFFF",
      position: "fixed",
      top: "0",
    },
  });

  result.push({
    selectors: ".navigation",
    style: {
      color: "#5D6A75",
      width: "100vw",
      height: "auto",
      "padding-top": "1em",
      position: "relative",
    },
  });

  result.push({
    selectors: ".navigation ul",
    style: {
      "margin-left": "auto",
      "margin-right": "auto",
      width: "30rem",
      display: "flex",
      "flex-direction": "row",
      "justify-content": "space-between",
      "align-items": "center",
    },
  });

  result.push({
    selectors: ".navigation ul img",
    style: {
      width: "6rem",
      cursor: "pointer",
    },
  });

  result.push({
    selectors: ".navigation ul li",
    style: {
      "list-style": "none",
      "text-align": "center",
      "font-style": "normal",
      cursor: "pointer",
      "border-bottom": "0 solid red",
      transition: "all 150ms",
    },
  });

  result.push({
    selectors: ".navigation ul li:hover",
    style: {
      "padding-bottom": "0.5em",
      "border-bottom": "4px solid red",
    },
  });

  result.push({
    selectors: ".mobile, #id-ww-23, #id-ww-24",
    style: {
      display: "none",
    },
  });

  result.push({
    selectors: ".login-signup",
    style: {
      position: "absolute",
      top: "40%",
      right: "5%",
      padding: "1em",
      border: "2px solid #FF0000",
      "border-radius": "8px",
      "background-color": "transparent",
      cursor: "pointer",
      transition: "all 150ms",
    },
  });

  result.push({
    selectors: ".login-signup:hover",
    style: {
      "background-color": "rgba(255, 0, 0, 0.1)",
    },
  });

  result.push({
    selectors: ".login-signup a",
    style: {
      "text-transform": "capitalize",
      "font-style": "normal",
      color: "#5D6A75",
    },
  });

  result.push({
    selectors: ".wrapper+a",
    style: {
      display: "none",
    },
  });

  result.push({
    selectors: ".hamburger-menu",
    style: {
      display: "none",
    },
  });

  result.push({
    selectors: ".d-bb",
    style: {
      position: "absolute",
      top: "-7%",
      left: "-35%",
      width: "60vw",
      "z-index": "-1",
    },
  });

  result.push({
    selectors: ".floaters",
    style: {
      position: "absolute",
      width: "auto",
      height: "auto",
      top: "-2%",
    },
  });

  result.push({
    selectors: ".floaters.left-position",
    style: {
      left: "5%",
    },
  });

  result.push({
    selectors: ".floaters.right-position",
    style: {
      right: "5%",
    },
  });

  result.push({
    selectors: "#stats",
    style: {
      display: "block",
      width: "20vw",
      opacity: "0",
      animation: `fade-in 500ms 1s ease-in forwards,
                float 2s 1s ease-out infinite`,
    },
  });

  result.push({
    selectors: "#contacts",
    style: {
      display: "block",
      width: "5vw",
      "margin-top": "20%",
      "margin-left": "20%",
      opacity: "0",
      animation: `fade-in 500ms 1.5s ease-in forwards,
                float 2s 1.5s ease-out infinite`,
    },
  });

  result.push({
    selectors: "#message",
    style: {
      display: "block",
      width: "5vw",
      "margin-top": "-70%",
      "margin-left": "100%",
      opacity: "0",
      animation: `fade-in 500ms 2s ease-in forwards,
                float 2s 2s ease-out infinite`,
    },
  });

  result.push({
    selectors: "#graphs",
    style: {
      display: "block",
      width: "5vw",
      "margin-top": "30%",
      "margin-left": "70%",
      opacity: "0",
      animation: `fade-in 500ms 2.5s ease-in forwards,
                float 2s 2.5s ease-out infinite`,
    },
  });

  result.push({
    selectors: "#text-box",
    style: {
      display: "block",
      width: "20vw",
      "margin-top": "-5%",
      opacity: "0",
      animation: `fade-in 500ms 3s ease-in forwards,
                float 2s 3s ease-out infinite`,
    },
  });

  result.push({
    selectors: "#settings",
    style: {
      display: "block",
      width: "5vw",
      "margin-top": "-5%",
      "margin-left": "10%",
      opacity: "0",
      animation: `fade-in 500ms 3.5s ease-in forwards,
                float 2s 3.5s ease-out infinite`,
    },
  });

  result.push({
    selectors: "#domain",
    style: {
      display: "block",
      width: "15vw",
      "margin-left": "20%",
      opacity: "0",
      animation: `fade-in 500ms 4s ease-in forwards,
                float 2s 4s ease-out infinite`,
    },
  });

  result.push({
    selectors: ".floaters+h1",
    style: {
      "text-transform": "uppercase",
      "text-align": "center",
      "font-style": "normal",
      "margin-top": "30vh",
    },
  });

  result.push({
    selectors: ".floaters+h1+h2",
    style: {
      "text-align": "center",
      color: "#5D6A75",
      "font-style": "normal",
    },
  });

  result.push({
    selectors: ".context-img",
    style: {
      display: "none",
    },
  });

  result.push({
    selectors: ".watch-tutorial",
    style: {
      "margin-left": "auto",
      "margin-right": "auto",
      "text-transform": "capitalize",
      "font-style": "normal",
      "font-size": "1.2em",
      display: "flex",
      "flex-direction": "row",
      "justify-content": "space-around",
      "align-items": "center",
      width: "12em",
    },
  });

  result.push({
    selectors: ".play-btn",
    style: {
      "background-color": "#ED1C24",
      width: "4em",
      height: "4em",
      "border-radius": "50%",
      border: "0",
      cursor: "pointer",
      position: "relative",
    },
  });

  result.push({
    selectors: ".play-btn::before",
    style: {
      content: "",
      "background-color": "#ED1C24",
      width: "4em",
      height: "4em",
      "border-radius": "50%",
      border: "0",
      position: "absolute",
      top: "0",
      left: "0",
      animation: "pulsate 5s ease-out infinite",
    },
  });

  result.push({
    selectors: ".play-btn div",
    style: {
      margin: "auto",
      width: "45%",
      height: "45%",
      "background-color": "white",
      "clip-path": "polygon(100% 50%, 0 0, 0 100%)",
      transition: "all 150ms",
    },
  });

  result.push({
    selectors: ".play-btn:hover::before",
    style: {
      "-webkit-animation-play-state": "paused",
      "-moz-animation-play-state": "paused",
      "-o-animation-play-state": "paused",
      "animation-play-state": "paused",
    },
  });

  result.push({
    selectors: ".play-btn:hover div",
    style: {
      "background-color": "black",
    },
  });

  result.push({
    selectors: ".l-bb",
    style: {
      position: "absolute",
      top: "4%",
      right: "-50%",
      width: "70vw",
      "z-index": "-1",
    },
  });

  result.push({
    selectors: ".l-bb+h1",
    style: {
      "text-align": "center",
      "font-style": "normal",
      "margin-top": "30%",
    },
  });

  result.push({
    selectors: ".gray-area",
    style: {
      width: "100vw",
      height: "auto",
      "background-color": "#ECECEC",
      display: "flex",
      "flex-direction": "row",
      "flex-wrap": "wrap",
      "justify-content": "space-around",
      "align-items": "center",
      "padding-top": "1.3em",
      "padding-bottom": "1.3em",
      position: "relative",
    },
  });

  // result.push({
  //     selectors: '.white-area',
  //     style: {
  //         position: 'absolute',
  //     },
  // });

  result.push({
    selectors: ".ww-21",
    style: {
      top: "-45%",
      left: "0",
      width: "90vw",
      position: "absolute",
    },
  });

  result.push({
    selectors: ".ww-22",
    style: {
      top: "-40%",
      right: "3%",
      width: "50vw",
      position: "absolute",
    },
  });

  result.push({
    selectors: ".ww-23",
    style: {
      top: "-30%",
      left: "-5%",
      width: "50vw",
      position: "absolute",
    },
  });

  result.push({
    selectors: ".gray-area .white-waves+img",
    style: {
      "z-index": "1",
      width: "40vw",
    },
  });

  result.push({
    selectors: ".description",
    style: {
      "z-index": "1",
      width: "45vw",
    },
  });

  result.push({
    selectors: ".description h2",
    style: {
      "font-style": "normal",
      "text-align": "left",
      "line-height": "1.5",
    },
  });

  result.push({
    selectors: ".description p",
    style: {
      "font-style": "normal",
      "text-align": "left",
      color: "#5D6A75",
      "line-height": "1.5",
    },
  });

  result.push({
    selectors: ".gray-area #ssl",
    style: {
      "z-index": "1",
      width: "20vw",
    },
  });

  result.push({
    selectors: ".white-area",
    style: {
      width: "100vw",
      height: "auto",
      "background-color": "#FFFFFF",
      display: "flex",
      "flex-direction": "row",
      "flex-wrap": "wrap",
      "justify-content": "space-around",
      "align-items": "center",
      "padding-top": "1.3em",
      "padding-bottom": "1.3em",
    },
  });

  result.push({
    selectors: ".white-area img",
    style: {
      "z-index": "1",
      width: "40vw",
    },
  });

  result.push({
    selectors: ".kf",
    style: {
      "flex-direction": "column",
      "justify-content": "space-around",
      "align-items": "center",
    },
  });

  result.push({
    selectors: ".kf h1",
    style: {
      color: "#ED1C24",
      "text-align": "center",
      "text-transform": "uppercase",
      "font-style": "normal",
    },
  });

  result.push({
    selectors: ".kf .key-features ul",
    style: {
      padding: "0",
      width: "90vw",
      display: "flex",
      "flex-direction": "row",
      "justify-content": "space-between",
      "align-items": "stretch",
    },
  });

  result.push({
    selectors: ".kf .key-features ul li",
    style: {
      "min-width": "20%",
      margin: "0.5em",
      display: "flex",
      "flex-direction": "column",
      "justify-content": "baseline",
      "align-items": "center",
    },
  });

  result.push({
    selectors: ".kf .key-features ul li img",
    style: {
      width: "8em",
      transform: "scale(0.8)",
    },
  });

  result.push({
    selectors: ".kf .key-features ul li img#no-code",
    style: {
      width: "20em",
    },
  });

  result.push({
    selectors: ".kf .key-features ul li img#downloadable",
    style: {
      width: "10em",
    },
  });

  result.push({
    selectors: ".kf .key-features ul li h3",
    style: {
      "font-style": "normal",
      "text-align": "center",
    },
  });

  result.push({
    selectors: ".kf .key-features ul li p",
    style: {
      "font-style": "normal",
      "text-align": "center",
      color: "#5D6A75",
      "line-height": "1.5",
      "text-align": "center",
    },
  });

  result.push({
    selectors: ".kf+br+h1",
    style: {
      "text-align": "center",
      "text-transform": "uppercase",
      "font-style": "normal",
    },
  });

  result.push({
    selectors: ".kf+br+h1+h3",
    style: {
      "text-align": "center",
      "font-style": "normal",
    },
  });

  result.push({
    selectors: ".modal-btn",
    style: {
      display: "block",
      "margin-left": "auto",
      "margin-right": "auto",
      width: "10em",
      padding: "1em",
      border: "0",
      "border-radius": "8px",
      "background-color": "#FF0000",
      color: "white",
      "font-style": "normal",
      "font-size": "1.2em",
      cursor: "pointer",
      transition: "all 100ms",
    },
  });

  result.push({
    selectors: ".modal-btn:hover",
    style: {
      transform: "scale(1.05)",
      "box-shadow": "0 0 15px -5px black",
    },
  });

  result.push({
    selectors: ".modal",
    style: {
      width: "30em",
      height: "50em",
      border: "5px solid red",
      "border-radius": "10px",
      "-ms-overflow-style": "none",
      "scrollbar-width": "none",
    },
  });

  result.push({
    selectors: ".modal::-webkit-scrollbar",
    style: {
      display: "none",
    },
  });

  result.push({
    selectors: ".close-modal",
    style: {
      float: "right",
      width: "1.5em",
      height: "1.5em",
      "background-color": "#FF0000",
      color: "#FFFFFF",
      "font-size": "2em",
      margin: "auto",
      border: "0",
      "border-radius": "50%",
      cursor: "pointer",
      transition: "all 100ms",
    },
  });

  result.push({
    selectors: ".close-modal:hover",
    style: {
      transform: "scale(1.05)",
      "box-shadow": "0 0 15px -5px black",
    },
  });

  result.push({
    selectors: ".modal h2",
    style: {
      "text-align": "left",
      "text-transform": "uppercase",
      "font-style": "normal",
      color: "#FF0000",
    },
  });

  result.push({
    selectors: ".modal p",
    style: {
      "text-align": "left",
      "font-style": "normal",
      "line-height": "1.5",
    },
  });

  result.push({
    selectors: ".modal .form",
    style: {
      width: "90%",
      height: "80%",
      display: "flex",
      "flex-direction": "column",
      "justify-content": "space-evenly",
      "align-items": "flex-start",
    },
  });

  result.push({
    selectors: ".modal .form label",
    style: {
      display: "block",
      "text-align": "left",
      "font-style": "normal",
    },
  });

  result.push({
    selectors: ".modal .form input",
    style: {
      display: "block",
      border: "2px solid #FF0000",
      "border-radius": "50px",
      padding: "1em",
      width: "80%",
    },
  });

  result.push({
    selectors: ".modal .form textarea",
    style: {
      display: "block",
      border: "2px solid #FF0000",
      "border-radius": "10px",
      padding: "1em",
      width: "80%",
      height: "10%",
    },
  });

  result.push({
    selectors: ".send-btn",
    style: {
      display: "block",
      width: "5em",
      padding: "1em",
      border: "0",
      "border-radius": "8px",
      "background-color": "#FF0000",
      color: "white",
      "font-style": "normal",
      "font-size": "1em",
      "text-align": "center",
      cursor: "pointer",
      transition: "all 100ms",
    },
  });

  result.push({
    selectors: ".send-btn:hover",
    style: {
      transform: "scale(1.05)",
      "box-shadow": "0 0 15px -5px black",
    },
  });

  result.push({
    selectors: ".rS-arc",
    style: {
      display: "block",
      "margin-left": "auto",
      "margin-right": "auto",
      "margin-top": "5vh",
      width: "40vw",
      "z-index": "1",
      filter: "drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.25))",
    },
  });

  result.push({
    selectors: "footer",
    style: {
      "margin-top": "-5%",
      display: "block",
      width: "100vw",
      "padding-top": "1.3em",
      "padding-bottom": "1.3em",
      background:
        "linear-gradient(140.86deg, #DEDEDF 22.43%, rgba(239, 241, 243, 0) 92.48%)",
    },
  });

  result.push({
    selectors: ".subscription-form",
    style: {
      width: "50em",
      display: "block",
      "margin-left": "auto",
      "margin-right": "auto",
      "margin-top": "5%",
      padding: "1.5em",
      background:
        "linear-gradient(127.74deg, rgba(255, 255, 255, 0.4) 14.43%, rgba(255, 255, 255, 0.1) 76.55%)",
      "box-shadow": "0 0 20px -5px rgba(0, 0, 0, 0.2)",
      "backdrop-filter": "blur(20px)",
      "border-radius": "15px",
    },
  });

  result.push({
    selectors: ".subscription-form label",
    style: {
      "font-style": "normal",
      "font-size": "1.2em",
    },
  });

  result.push({
    selectors: ".subscription-form #subscribe",
    style: {
      "margin-left": "5%",
      border: 0,
      "border-radius": "50px",
      padding: "1em",
    },
  });

  result.push({
    selectors: ".subs-btn",
    style: {
      padding: "1em",
      border: "0",
      "border-radius": "50px",
      "background-color": "#FF0000",
      color: "white",
      "font-style": "normal",
      "text-transform": "capitalize",
      "margin-left": "-5%",
      cursor: "pointer",
      transition: "all 100ms",
    },
  });

  result.push({
    selectors: ".subs-btn:hover",
    style: {
      transform: "scale(1.05)",
      "box-shadow": "0 0 15px -5px black",
    },
  });

  result.push({
    selectors: ".footer-content",
    style: {
      width: "50em",
      "margin-left": "auto",
      "margin-right": "auto",
      display: "flex",
      "flex-direction": "row",
      "justify-content": "space-between",
      "align-items": "center",
    },
  });

  result.push({
    selectors: ".footer-content div:nth-child(1) img",
    style: {
      width: "15vw",
    },
  });

  result.push({
    selectors: ".footer-content div:nth-child(1) p",
    style: {
      "font-style": "normal",
      "text-align": "left",
    },
  });

  result.push({
    selectors: ".footer-content div:nth-child(2)",
    style: {
      display: "flex",
      "flex-direction": "row",
      "justify-content": "space-between",
      "align-items": "flex-start",
    },
  });

  result.push({
    selectors: ".footer-content div:nth-child(2) #col",
    style: {
      "margin-right": "1em",
      display: "flex",
      "flex-direction": "column",
      "justify-content": "space-around",
      "align-items": "flex-start",
    },
  });

  result.push({
    selectors: ".footer-content div:nth-child(2) #col p",
    style: {
      "font-style": "normal",
      "text-align": "left",
    },
  });

  result.push({
    selectors: ".footer-content div:nth-child(2) #col p:nth-child(1)",
    style: {},
  });

  result.push({
    selectors: ".social-medias",
    style: {
      width: "20em",
      display: "flex",
      "flex-direction": "row",
      "justify-content": "space-between",
      "align-items": "center",
      "margin-left": "auto",
      "margin-right": "auto",
    },
  });

  result.push({
    selectors: ".social-medias .bi",
    style: {
      "font-size": "1.5em",
      color: "#ED1C24",
      transition: "all 150ms",
    },
  });

  result.push({
    selectors: ".social-medias .bi:hover",
    style: {
      "font-size": "2em",
      "box-shadow": "0 0 15px -5px black",
    },
  });

  // TEMPLATE REDSLING MEDIA QUERIES.................................
  result.push({
    params: "(max-width: 500px)",
    selectors: ".wrapper",
    atRule: "media",
    style: {
      display: "none",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: "#id-ww-24",
    atRule: "media",
    style: {
      "z-index": "-1",
      display: "block",
      top: "-13em",
      right: "0",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: "#id-ww-24",
    atRule: "media",
    style: {
      width: "auto",
      height: "auto",
      display: "inline",
      position: "absolute",
      top: "-4em",
      right: "5%",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".bi",
    atRule: "media",
    style: {
      color: "black",
      "font-size": "2em",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".hamburger-menu ul",
    atRule: "media",
    style: {
      display: "none",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".hamburger-menu:hover ul",
    atRule: "media",
    style: {
      position: "absolute",
      "background-color": "white",
      display: "flex",
      "flex-direction": "column",
      "justify-content": "space-evenly",
      "align-items": "flex-end",
      top: "40%",
      right: "0",
      width: "auto",
      "font-style": "normal",
      "box-shadow": "0 0 10px -5px black",
      "border-radius": "10px",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".hamburger-menu:hover ul li",
    atRule: "media",
    style: {
      color: "black",
      "font-size": "0.5em",
      margin: "0.5em",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".wrapper+a",
    atRule: "media",
    style: {
      display: "inline",
      position: "absolute",
      top: "-9em",
      left: "2%",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".d-bb,.floaters,.l-bb",
    atRule: "media",
    style: {
      display: "none",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".floaters+h1",
    atRule: "media",
    style: {
      "margin-top": "20vh",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".floaters+h1 span",
    atRule: "media",
    style: {
      color: "#ED1C24",
      "font-size": "1.3em",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".floaters+h1+h2,.login-signup a",
    atRule: "media",
    style: {
      color: "#FFFFFF",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: "#id-ww-23",
    atRule: "media",
    style: {
      "z-index": "-2",
      display: "block",
      width: "60em",
      top: "10em",
      right: "22em",
      transform: "rotate(-161.14deg)",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".context-img",
    atRule: "media",
    style: {
      display: "block",
      width: "60vw",
      "margin-top": "-15%",
      "margin-bottom": "10%",
      "margin-left": "auto",
      "margin-right": "auto",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".mobile",
    atRule: "media",
    style: {
      display: "block",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".login-signup",
    atRule: "media",
    style: {
      position: "unset",
      "margin-left": "50%",
      "margin-top": "10%",
      transform: "translate(-50%)",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".login-signup a",
    atRule: "media",
    style: {
      color: "black",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".l-bb+h1 span",
    atRule: "media",
    style: {
      color: "#ED1C24",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".gray-area",
    atRule: "media",
    style: {
      "flex-direction": "column",
      "justify-content": "space-between",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".gray-area .white-waves+img",
    atRule: "media",
    style: {
      width: "80vw",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".ww-21",
    atRule: "media",
    style: {
      top: "35%",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".description",
    atRule: "media",
    style: {
      width: "80vw",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".description h2",
    atRule: "media",
    style: {
      "text-align": "center",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".description p",
    atRule: "media",
    style: {
      "text-align": "center",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".gray-area #ssl",
    atRule: "media",
    style: {
      width: "30vw",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".ww-23",
    atRule: "media",
    style: {
      width: "90vw",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".white-area",
    atRule: "media",
    style: {
      "flex-direction": "column-reverse",
      "justify-content": "space-between",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".white-area img",
    atRule: "media",
    style: {
      width: "80vw",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".kf",
    atRule: "media",
    style: {
      "flex-direction": "column",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".kf .key-features ul",
    atRule: "media",
    style: {
      "max-width": "100%",
      "flex-wrap": "wrap",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".kf .key-features ul li",
    atRule: "media",
    style: {
      width: "47%",
      margin: "0",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".modal",
    atRule: "media",
    style: {
      width: "80%",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".subscription-form",
    atRule: "media",
    style: {
      width: "80%",
      display: "flex",
      "flex-direction": "column",
      "justify-content": "space-between",
      "align-items": "center",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".subscription-form #subscribe,.subs-btn",
    atRule: "media",
    style: {
      "margin-bottom": "1em",
      "margin-left": "auto",
      "margin-right": "auto",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".subscription-form #subscribe",
    atRule: "media",
    style: {
      width: "80%",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".footer-content",
    atRule: "media",
    style: {
      width: "90%",
      "flex-direction": "column",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".footer-content div:nth-child(1) img",
    atRule: "media",
    style: {
      width: "30vw",
      "margin-left": "50%",
      transform: "translate(-50%)",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".footer-content div:nth-child(1) p",
    atRule: "media",
    style: {
      "text-align": "center",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".footer-content div:nth-child(2) #col",
    atRule: "media",
    style: {
      "align-items": "center",
      margin: "2em",
    },
  });
  result.push({
    params: "(max-width: 500px)",
    selectors: ".social-medias",
    atRule: "media",
    style: {
      "max-width": "70%",
      "flex-wrap": "wrap",
    },
  });

  // TEMPLATE ECOMMPRODUCTPAGE
  result.push({
    selectors: ".header",
    style: {
      display: "flex",
      "justify-content": "space-between",
      "align-items": "flex-end",
      padding: "5vh 4.5vw",
      position: "relative",
    },
  });
  result.push({
    selectors: ".header-right-section",
    style: {
      display: "flex",
      "justify-content": "center",
      "align-items": "center",
      width: "8vw",
      "z-index": "10",
    },
  });
  result.push({
    selectors: ".header-right-section img",
    style: {
      width: "10vw",
      height: "9vh",
    },
  });
  result.push({
    selectors: ".header-right-section p",
    style: {
      color: " #5d6a75",
      "font-size": "18px",
      "margin-right": "1vw",
    },
  });
  result.push({
    selectors: ".whiteWaveOverlay",
    style: {
      position: "absolute",
      top: "0",
      right: "0",
    },
  });
  result.push({
    selectors: ".whiteWaveOverlay img",
    style: {
      height: "120vh",
      width: "95vw",
    },
  });
  result.push({
    selectors: ".mainDiv",
    style: {
      position: "relative",
      "overflow-y": "hidden",
      padding: "7vh 0",
    },
  });
  result.push({
    selectors: ".addProject-d-bb",
    style: {
      position: "absolute",
      width: "55vw",
      height: "170vh",
      left: "-37vw",
      top: "-20%",
    },
  });
  result.push({
    selectors: ".mainDiv-head",
    style: {
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center",
      padding: "0vh 4.5vw",
      position: "relative",
    },
  });
  result.push({
    selectors: ".mainDiv-head p:first-child",
    style: {
      "font-style": "normal",
      "font-weight": "800",
      "font-size": "3.2rem",
      "padding-left": "12%",
    },
  });
  result.push({
    selectors: ".mainDiv-head div",
    style: {
      display: "flex",
      width: "26vw",
      "justify-content": "space-between",
      "align-items": "center",
    },
  });
  result.push({
    selectors: ".mainDiv-head div p:first-child",
    style: {
      "font-size": "18px",
      padding: "2.5% 5%",
      "background-color": "red",
      color: "white",
      "box-shadow": "4px 4px 10px rgba(0, 0, 0, 0.35)",
      "border-radius": "27.5px",
    },
  });
  result.push({
    selectors: ".mainDiv-head div p",
    style: {
      "font-size": "18px",
      padding: "2.5% 5%",
      border: "2px solid red",
      color: "red",
      "box-shadow": "4px 4px 10px rgba(0, 0, 0, 0.35)",
      "border-radius": "27.5px",
    },
  });
  result.push({
    selectors: "table",
    style: {
      width: "65vw",
      "margin-top": "8vh",
      "margin-left": "20vw",
    },
  });
  result.push({
    selectors: "th",
    style: {
      "font-size": "1.2rem",
    },
  });
  result.push({
    selectors: "td",
    style: {
      "text-align": "center",
      "font-size": "1.2rem",
      padding: "2.2vh 0",
    },
  });
  result.push({
    selectors: ".box1",
    style: {
      border: "2px solid red",
      width: "1vw",
      height: "2vh",
      "border-radius": "6px",
      "margin-left": "1vw",
    },
  });
  result.push({
    selectors: ".box",
    style: {
      border: "2px solid red",
      width: "1.5vw",
      height: "3vh",
      "border-radius": "6px",
      "margin-left": "0.7vw",
    },
  });
  result.push({
    selectors: ".paginationTable",
    style: {
      display: "flex",
      "justify-content": "flex-end",
      "align-items": "center",
      "padding-right": "4%",
      "margin-top": "8vh",
    },
  });
  result.push({
    selectors: ".paginationTable div",
    style: {
      display: "flex",
      "justify-content": "center",
      "align-items": "center",
    },
  });
  result.push({
    selectors: ".paginationTable div p",
    style: {
      "font-size": "1.2rem",
      margin: "0.2em",
      cursor: "pointer",
    },
  });
  result.push({
    selectors: ".pageParaTwo",
    style: {
      width: "2.5vw",
      "margin-left": "8%",
      border: "2px solid red",
      "border-radius": "50% 50%",
      "text-align": "center",
      cursor: "pointer",
    },
  });
  result.push({
    selectors: ".pageParaOne",
    style: {
      width: "2vw",
      border: "2px solid red",
      "text-align": "center",
      "border-radius": "50% 50%",
      color: "white",
      "background-color": "red",
      "font-size": "1rem",
      "font-weight": "800",
      "margin-right": "1vw",
      "margin-left": "1vw",
      "margin-top": "auto",
      "margin-bottom": "auto",
      cursor: "pointer",
    },
  });
  // TEMPLATE ECOMMPRODUCTPAGE MEDIA QUERIES.................................
  result.push({
    params: "(max-width: 768px)",
    selectors: ".header",
    atRule: "media",
    style: {
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center",
      position: "relative",
      padding: "2vh 2.5vw",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".header-right-section img",
    atRule: "media",
    style: {
      width: "5vw",
      height: "3vh",
      "margin-left": "18%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".header-right-section p",
    atRule: "media",
    style: {
      "font-size": "1rem",
      "margin-right": "9%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".whiteWaveOverlay",
    atRule: "media",
    style: {
      position: "absolute",
      top: "0",
      right: "-8%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".whiteWaveOverlay img",
    atRule: "media",
    style: {
      height: "80vh",
      width: "100vw",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".mainDiv",
    atRule: "media",
    style: {
      "overflow-x": "hidden",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".addProject-d-bb",
    atRule: "media",
    style: {
      display: "none",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".mainDiv-head",
    atRule: "media",
    style: {
      display: "block",
      "justify-content": "space-between",
      "align-items": "center",
      padding: "0vh 0 0 8vw",
      position: "relative",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".mainDiv-head p:first-child",
    atRule: "media",
    style: {
      "font-style": "normal",
      "font-weight": "800",
      "font-size": "2.8rem",
      "padding-left": "5%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".mainDiv-head div",
    atRule: "media",
    style: {
      display: "flex",
      width: "initial",
      "margin-top": "2vh",
      "justify-content": "space-between",
      "align-items": "center",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: "table",
    atRule: "media",
    style: {
      width: "100vw",
      "margin-top": "8vh",
      "margin-left": "8%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: "th",
    atRule: "media",
    style: {
      "font-size": "1.2rem",
      "padding-left": "8vw",
      "padding-right": "8vw",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: "th:first-child",
    atRule: "media",
    style: {
      "padding-left": "0",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: "td",
    atRule: "media",
    style: {
      "text-align": "center",
      "font-size": "1rem",
      padding: "1vh 0 1vh 0",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".box1",
    atRule: "media",
    style: {
      border: "2px solid red",
      width: "3.5vw",
      height: "1.5vh",
      "border-radius": "6px",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".box",
    atRule: "media",
    style: {
      border: "2px solid red",
      width: "4vw",
      height: "2vh",
      "border-radius": "6px",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".paginationTable",
    atRule: "media",
    style: {
      display: "flex",
      "justify-content": "flex-end",
      "align-items": "center",
      "margin-top": "5vh",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".paginationTable div",
    atRule: "media",
    style: {
      display: "flex",
      "justify-content": "center",
      "align-items": "center",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".paginationTable div p",
    atRule: "media",
    style: {
      "font-size": "1.2rem",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".pageParaTwo",
    atRule: "media",
    style: {
      width: "2em",
      "margin-left": "8%",
      border: "2px solid red",
      "border-radius": "50% 50%",
      "text-align": "center",
      padding: "2%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".pageParaOne",
    atRule: "media",
    style: {
      width: "1.8em",
      border: "2px solid red",
      "text-align": "center",
      "border-radius": "50% 50%",
      color: "white",
      "background-color": "red",
      "font-size": "1rem",
      "font-weight": "800",
      "margin-right": "3vw",
      "margin-left": "2vw",
    },
  });

  // TEMPLATE DETAILSPAGE
  result.push({
    selectors: ".header-right-section1",
    style: {
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center",
      width: "8vw",
      "z-index": "10",
    },
  });
  result.push({
    selectors: ".header-right-section1 img",
    style: {
      width: "3vw",
      height: "6vh",
    },
  });
  result.push({
    selectors: ".header-right-section1 p",
    style: {
      "font-size": "18px",
    },
  });
  result.push({
    selectors: ".whiteWaveOverlay1",
    style: {
      position: "absolute",
      top: "0",
      right: "0",
    },
  });
  result.push({
    selectors: ".whiteWave2",
    style: {
      position: "absolute",
      top: "0",
      right: "0",
      height: "160%",
    },
  });
  result.push({
    selectors: ".checkoutDiv",
    style: {
      position: "relative",
      "overflow-y": "hidden",
      height: "inherit",
      "padding-left": "22vw",
      "padding-right": "4vw",
    },
  });
  result.push({
    selectors: ".checkout-d-bb",
    style: {
      position: "absolute",
      width: "55vw",
      height: "140vh",
      left: "-35vw",
      top: "-15%",
      "z-index": "10",
    },
  });
  result.push({
    selectors: ".checkout-bread",
    style: {
      display: "flex",
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center",
      "margin-top": "8vh",
      "margin-bottom": "4vh",
    },
  });
  result.push({
    selectors: ".checkout-bread div",
    style: {
      display: "flex",
      "justify-content": "center",
      "align-items": "center",
    },
  });
  result.push({
    selectors: ".checkout-bread div:last-child",
    style: {
      display: "flex",
      "justify-content": "space-between",
      width: "100%",
      "font-size": "1.5rem",
      "margin-top": "2vh",
    },
  });
  result.push({
    selectors: ".checkout-bread div:last-child p",
    style: {
      "padding-left": "2.3vw",
      "text-align": "end",
    },
  });
  result.push({
    selectors: ".circle1",
    style: {
      width: "2em",
      height: "2em",
      "background-color": "red",
      "border-radius": "50% 50%",
    },
  });
  result.push({
    selectors: ".circle2",
    style: {
      width: "2em",
      height: "2em",
      "border-radius": "50% 50%",
      border: "8px solid rgba(237, 28, 36, 0.4)",
    },
  });
  result.push({
    selectors: ".h-line",
    style: {
      border: "4px solid rgba(237, 28, 36, 0.4)",
      width: "30vw",
    },
  });
  result.push({
    selectors: ".detail-Main",
    style: {
      "padding-left": "2rem",
      "padding-bottom": "4vh",
    },
  });
  result.push({
    selectors: ".shippingAdd",
    style: {
      "font-size": "1.5rem",
    },
  });
  result.push({
    selectors: ".detailName",
    style: {
      "margin-top": "2.5vh",
    },
  });
  result.push({
    selectors: ".detailName label",
    style: {
      "font-size": "1.2rem",
    },
  });
  result.push({
    selectors: ".detailName input",
    style: {
      "margin-top": "1vh",
      padding: "1.5vh 0.5vw",
      "font-size": "1rem",
      border: "1px solid #ff0000",
      "border-radius": "6px",
      width: "50%",
      outline: "none",
    },
  });
  result.push({
    selectors: ".detailMob",
    style: {
      "margin-top": "3vh",
    },
  });
  result.push({
    selectors: ".detailMob label",
    style: {
      "font-size": "1.2rem",
    },
  });
  result.push({
    selectors: ".detailMob input",
    style: {
      "margin-top": "1vh",
      padding: "1.5vh 0.5vw",
      "font-size": "1rem",
      border: "1px solid #ff0000",
      "border-radius": "6px",
      width: "25%",
      outline: "none",
    },
  });
  result.push({
    selectors: ".detailAdd",
    style: {
      "margin-top": "3vh",
    },
  });
  result.push({
    selectors: ".detailAdd label",
    style: {
      "font-size": "1.2rem",
    },
  });
  result.push({
    selectors: ".detailAdd input",
    style: {
      "margin-top": "1vh",
      padding: "1.5vh 0.5vw",
      "font-size": "1rem",
      border: "1px solid #ff0000",
      "border-radius": "6px",
      width: "70%",
      outline: "none",
    },
  });
  result.push({
    selectors: ".detailCityState",
    style: {
      display: "flex",
      "justify-content": "flex-start",
      "align-items": "center",
      "margin-top": "3vh",
    },
  });
  result.push({
    selectors: ".detailCity label",
    style: {
      "font-size": "1.2rem",
    },
  });
  result.push({
    selectors: ".detailCity input",
    style: {
      "margin-top": "1vh",
      padding: "1.5vh 0.5vw",
      "font-size": "1rem",
      border: "1px solid #ff0000",
      "border-radius": "6px",
      width: "80%",
      outline: "none",
      "margin-right": "8vw",
    },
  });
  result.push({
    selectors: ".detailState label",
    style: {
      "font-size": "1.2rem",
    },
  });
  result.push({
    selectors: ".detailState input",
    style: {
      "margin-top": "1vh",
      padding: "1.5vh 0.5vw",
      "font-size": "1rem",
      border: "1px solid #ff0000",
      "border-radius": "6px",
      width: "18vw",
      outline: "none",
    },
  });
  result.push({
    selectors: ".saveDetail",
    style: {
      "margin-top": "4vh",
      "font-size": "1.2rem",
    },
  });
  result.push({
    selectors: ".detailSaveDiv",
    style: {
      display: "flex",
      "justify-content": "start",
      "align-items": "center",
      "margin-top": "1vh",
    },
  });
  result.push({
    selectors: ".detailSaveInner",
    style: {
      display: "flex",
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center",
      "margin-left": "2vw",
    },
  });
  result.push({
    selectors: ".detailSaveInner1",
    style: {
      display: "flex",
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center",
    },
  });
  result.push({
    selectors: ".detailSaveInner p:first-child",
    style: {
      height: "2vh",
      width: "1vw",
      border: "1px solid black",
      "border-radius": "50% 50%",
    },
  });
  result.push({
    selectors: ".detailSaveInner p",
    style: {
      "font-size": "1rem",
      "margin-top": "1vh",
    },
  });
  result.push({
    selectors: ".detailSaveInner1 p",
    style: {
      "font-size": "1rem",
      "margin-top": "1vh",
    },
  });
  result.push({
    selectors: ".detailSaveInner1 p:first-child",
    style: {
      height: "2vh",
      width: "1vw",
      "background-color": "red",
      border: "1px solid red",
      "border-radius": "50% 50%",
    },
  });
  result.push({
    selectors: ".detailFooter",
    style: {
      "margin-top": "3vh",
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center",
    },
  });
  result.push({
    selectors: ".detailFooter div",
    style: {
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center",
      "font-size": "1rem",
    },
  });
  result.push({
    selectors: ".detailFooter div p:first-child",
    style: {
      border: "1px solid red",
      padding: "0.8vh 0.4vw",
      "border-radius": "6px",
      "margin-right": "0.5vw",
    },
  });
  result.push({
    selectors: ".detailFooter button",
    style: {
      padding: "1vh 1.5vw",
      "font-size": "1rem",
      color: "white",
      outline: "none",
      "background-color": "red",
      border: "none",
      "border-radius": "20px",
      "box-shadow": "4px 4px 10px rgba(0, 0, 0, 0.35)",
    },
  });
  // TEMPLATE DETAILSPAGE MEDIA QUERIES.................................
  result.push({
    params: "(max-width: 768px)",
    selectors: ".header",
    atRule: "media",
    style: {
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center",
      position: "relative",
      padding: "2vh 2.5vw",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".header-right-section1",
    atRule: "media",
    style: {
      display: "flex",
      "justify-content": "space-evenly",
      "align-items": "center",
      width: "initial",
      "z-index": "10",
    },
  });

  result.push({
    params: "(max-width: 768px)",
    selectors: ".header-right-section1 img",
    atRule: "media",
    style: {
      width: "4vw",
      height: "3vh",
      "margin-left": "5%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".header-right-section1 p",
    atRule: "media",
    style: {
      "font-size": "1rem",
      "margin-right": "5%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".whiteWaveOverlay1",
    atRule: "media",
    style: {
      position: "absolute",
      top: "0",
      right: "0",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".whiteWaveOverlay1 img:first-child",
    atRule: "media",
    style: {
      width: "50vw",
      position: "absolute",
      top: "0",
      right: "0",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".whiteWave2",
    atRule: "media",
    style: {
      width: "90vw",
      position: "absolute",
      top: "0",
      right: "0",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".checkoutDiv",
    atRule: "media",
    style: {
      "padding-left": "4vw",
      "padding-right": "4vw",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".checkout-d-bb",
    atRule: "media",
    style: {
      display: "none",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".checkout-bread",
    atRule: "media",
    style: {
      "margin-top": "4vh",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".checkout-bread div:last-child",
    atRule: "media",
    style: {
      "font-size": "1.2rem",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".checkout-bread div:last-child p",
    atRule: "media",
    style: {
      "padding-left": "5vw",
      "text-align": "end",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".circle1",
    atRule: "media",
    style: {
      width: "2em",
      height: "2em",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".circle2",
    atRule: "media",
    style: {
      width: "2em",
      height: "2em",
      border: "4px solid rgba(237, 28, 36, 0.4)",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".detail-Main",
    atRule: "media",
    style: {
      "padding-left": "4.6vw",
      "padding-bottom": "4vh",
      "margin-top": "12%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".shippingAdd",
    atRule: "media",
    style: {
      "font-size": "1.2rem",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".detailName",
    atRule: "media",
    style: {
      "margin-top": "4vh",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".detailName input",
    atRule: "media",
    style: {
      "margin-top": "1vh",
      padding: "1.5vh 2vw",
      width: "85%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".detailMob input",
    atRule: "media",
    style: {
      padding: "1.5vh 2vw",
      width: "85%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".detailAdd input",
    atRule: "media",
    style: {
      padding: "1.5vh 2vw",
      width: "85%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".detailCityState",
    atRule: "media",
    style: {
      display: "flex",
      "flex-direction": "column",
      "justify-content": "space-between",
      "align-items": "flex-start",
      "margin-top": "3vh",
      height: "19vh",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".detailCity input",
    atRule: "media",
    style: {
      padding: "1.5vh 2vw",
      width: "74vw",
      "margin-right": "0",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".detailState input",
    atRule: "media",
    style: {
      padding: "1.5vh 2vw",
      width: "74vw",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".saveDetail",
    atRule: "media",
    style: {
      "margin-top": "6em",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".detailSaveDiv",
    atRule: "media",
    style: {
      "justify-content": "space-between",
      "padding-right": "2vw",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".detailSaveInner p:first-child",
    atRule: "media",
    style: {
      height: "2vh",
      width: "4.5vw",
      border: "1px solid black",
      "border-radius": "50% 50%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".detailSaveInner p",
    atRule: "media",
    style: {
      "font-size": "1rem",
      "margin-top": "1.5vh",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".detailSaveInner1 p",
    atRule: "media",
    style: {
      "font-size": "1rem",
      "margin-top": "1.5vh",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".detailSaveInner1 p:first-child",
    atRule: "media",
    style: {
      height: "2vh",
      width: "4vw",
      "background-color": "red",
      border: "1px solid red",
      "border-radius": "50% 50%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".detailFooter",
    atRule: "media",
    style: {
      "margin-top": "3vh",
      display: "flex",
      "flex-direction": "column",
      "justify-content": "space-between",
      "align-items": "center",
      height: "12vh",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".detailFooter div",
    atRule: "media",
    style: {
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center",
      "font-size": "1rem",
      width: "100%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".detailFooter div p:first-child",
    atRule: "media",
    style: {
      border: "1px solid red",
      padding: "0.8vh 2vw",
      "border-radius": "6px",
      "margin-right": "3vw",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".detailFooter button",
    atRule: "media",
    style: {
      padding: "1.5vh 1.5vw",
      width: "100%",
    },
  });

  // TEMPLATE PRODUCTPAGE
  result.push({
    selectors: ".mainDiv1",
    style: {
      position: "relative",
      "overflow-y": "hidden",
      height: "initial",
      "padding-bottom": "5vh",
    },
  });
  result.push({
    selectors: ".addProject-d-bb1",
    style: {
      position: "absolute",
      width: "55vw",
      height: "158vh",
      left: "-35vw",
      top: "-15%",
      "z-index": "10",
    },
  });
  result.push({
    selectors: ".mainDiv-head2",
    style: {
      display: "flex",
      "justify-content": "space-between",
      "align-items": "flex-end",
      padding: "0vh 4.8vw",
      position: "relative",
    },
  });
  result.push({
    selectors: ".mainDiv-head2 div:first-child p:first-child",
    style: {
      "font-style": "normal",
      "font-size": "2.8rem",
    },
  });
  result.push({
    selectors: ".mainDiv-head2 div:first-child",
    style: {
      "margin-left": "9%",
    },
  });
  result.push({
    selectors: ".mainDiv-head2 div:first-child p",
    style: {
      "font-style": "normal",
      "font-size": "0.8rem",
      "margin-top": "2.5%",
      width: "30vw",
    },
  });
  result.push({
    selectors: ".mainDiv-head2 div:first-child p a",
    style: {
      color: "red",
    },
  });
  result.push({
    selectors: ".mainDiv-head2 div:last-child",
    style: {
      display: "flex",
      "align-items": "center",
      "justify-content": "flex-start",
      width: "37vw",
      "margin-top": "4%",
    },
  });
  result.push({
    selectors: ".mainDiv-head2 div:last-child p:first-child",
    style: {
      width: "inherit",
      height: "inherit",
      "font-style": "normal",
      "font-weight": "700",
      "font-size": "1.2rem",
      color: "#5d6a75",
      "margin-right": "15%",
    },
  });
  result.push({
    selectors: ".mainDiv-head2 div:last-child p:last-child",
    style: {
      width: "inherit",
      height: "inherit",
      "font-style": "normal",
      "font-weight": "700",
      "font-size": "1.2rem",
      "margin-right": "15%",
    },
  });
  result.push({
    selectors: ".overlayDiv2",
    style: {
      position: "relative",
      "margin-top": "3.8vh",
      height: "388px",
      background: "white",
      "z-index": "20",
      padding: "auto 2vw",
      display: "flex",
      "align-items": "center",
      "justify-content": "center",
    },
  });
  result.push({
    selectors: ".overlayDiv2 div:first-child",
    style: {
      display: "flex",
      "justify-content": "space-between",
      width: "100%",
      "align-items": "center",
      padding: "0 4vw",
    },
  });
  result.push({
    selectors: ".overlay2-innerDiv",
    style: {
      display: "flex",
      "align-items": "center",
      "justify-content": "center",
    },
  });
  result.push({
    selectors: ".overlay2-1",
    style: {
      width: "260px",
      height: "176px",
    },
  });
  result.push({
    selectors: ".overlay2-0",
    style: {
      width: "358px",
      height: "243px",
      "margin-left": "2%",
      "margin-right": "2%",
    },
  });
  result.push({
    selectors: ".bottomDivWrapper",
    style: {
      "padding-left": "18vw",
      "padding-right": "2%",
      "margin-top": "5vh",
    },
  });
  result.push({
    selectors: ".bottomDivWrapper a",
    style: {
      color: "red",
    },
  });
  result.push({
    selectors: ".b-head",
    style: {
      "font-size": "1.2rem",
    },
  });
  result.push({
    selectors: ".b-para",
    style: {
      color: "#5d6a75",
      "line-height": "145%",
      margin: "2vh 0",
    },
  });
  result.push({
    selectors: ".b-head1",
    style: {
      "font-size": "1.2rem",
      "border-top": "1px solid #c4c4c4",
      padding: "2vh 0",
    },
  });
  result.push({
    selectors: ".b-button-div",
    style: {
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center",
      margin: "2vh 0",
    },
  });
  result.push({
    selectors: ".b-button-div button",
    style: {
      "font-size": "1.2rem",
      padding: "1.5vh 1vw",
      "border-radius": "20px",
      "background-color": "red",
      color: "white",
      border: "none",
      "box-shadow": "4px 4px 10px rgba(0, 0, 0, 0.35)",
    },
  });
  result.push({
    selectors: ".b-button-div button:first-child",
    style: {
      "background-color": "black",
      color: "white",
      border: "none",
      "box-shadow": "4px 4px 10px rgba(0, 0, 0, 0.35)",
    },
  });

  // TEMPLATE PRODUCTPAGE MEDIA QUERIES.................................
  result.push({
    params: "(max-width: 768px)",
    selectors: ".addProject-d-bb1",
    atRule: "media",
    style: {
      display: "none",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".mainDiv-head2",
    atRule: "media",
    style: {
      "align-items": "flex-start",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".mainDiv-head2 div:first-child p:first-child",
    atRule: "media",
    style: {
      "font-size": "1.8rem",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".mainDiv-head2 div:first-child",
    atRule: "media",
    style: {
      "margin-left": "4%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".mainDiv-head2 div:first-child p",
    atRule: "media",
    style: {
      "margin-top": "4.5%",
      width: "50vw",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".mainDiv-head2 div:first-child p a",
    atRule: "media",
    style: {
      color: "red",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".mainDiv-head2 div:last-child",
    atRule: "media",
    style: {
      display: "flex",
      "flex-direction": "column",
      "align-items": "center",
      "justify-content": "space-around",
      width: "37vw",
      "margin-top": "4%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".mainDiv-head2 div:last-child p:first-child",
    atRule: "media",
    style: {
      width: "inherit",
      height: "inherit",
      "font-style": "normal",
      "font-weight": "700",
      "font-size": "1.2rem",
      color: "#5d6a75",
      "text-align": "right",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".mainDiv-head2 div:last-child p:last-child",
    atRule: "media",
    style: {
      width: "inherit",
      height: "inherit",
      "font-style": "normal",
      "font-weight": "700",
      "font-size": "1.2rem",
      "text-align": "right",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".overlayDiv2",
    atRule: "media",
    style: {
      "margin-top": "3.8vh",
      background: "white",
      "z-index": "20",
      "padding-left": "5%",
      "padding-top": "0.7%",
      height: "initial",
      "padding-bottom": "3vh",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".arrowProduct1",
    atRule: "media",
    style: {
      display: "none",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".overlay2-innerDiv",
    atRule: "media",
    style: {
      "flex-direction": "column",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".overlay2-1",
    atRule: "media",
    style: {
      width: "40vw",
      height: "15vh",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".overlay2-0",
    atRule: "media",
    style: {
      width: "70vw",
      height: "25vh",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".bottomDivWrapper",
    atRule: "media",
    style: {
      "padding-left": "5vw",
      "padding-right": "5vw",
      "padding-bottom": "4vh",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".b-button-div",
    atRule: "media",
    style: {
      "flex-direction": "column",
      margin: "2vh 0 0 0",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".b-button-div button",
    atRule: "media",
    style: {
      width: "90%",
      "margin-bottom": "4%",
    },
  });

  // TEMPLATE CHECKOUTPAGE
  result.push({
    selectors: ".checkout-content",
    style: {
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center",
      "border-bottom": "1px solid #c4c4c4",
    },
  });
  result.push({
    selectors: ".checkout-content img",
    style: {
      height: "15vh",
    },
  });
  result.push({
    selectors: ".checkout-content div:first-child",
    style: {
      display: "flex",
      "align-items": "start",
      "justify-content": "center",
    },
  });
  result.push({
    selectors: ".checkout-product",
    style: {
      display: "flex",
      "flex-direction": "column",
      height: "12vh",
      "justify-content": "space-between",
      "align-items": "center",
      padding: "0.8vh 0",
    },
  });
  result.push({
    selectors: ".checkout-button",
    style: {
      display: "flex",
      "align-items": "center",
    },
  });
  result.push({
    selectors: ".checkout-button p:first-child",
    style: {
      "font-size": "2rem",
      border: "none",
    },
  });
  result.push({
    selectors: ".checkout-button p:last-child",
    style: {
      "font-size": "2rem",
      border: "none",
    },
  });
  result.push({
    selectors: ".checkout-button p",
    style: {
      border: "1px solid #ed1c24",
      "border-radius": "8px",
      padding: "2vh 1.5vw",
      color: "#657581",
    },
  });
  result.push({
    selectors: ".checkout-coupon",
    style: {
      display: "flex",
      "justify-content": "center",
      "align-items": "center",
      "margin-top": "5vh",
      color: "#bcbcbc",
    },
  });
  result.push({
    selectors: ".checkout-coupon p",
    style: {
      border: "2px solid red",
      padding: "1.5vh 2.6vw",
      "border-radius": "10px",
    },
  });
  result.push({
    selectors: ".checkout-coupon p a",
    style: {
      color: "red",
      "text-decoration": "none",
      "margin-left": "0.5vw",
    },
  });
  result.push({
    selectors: ".priceDesc",
    style: {
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center",
      color: "#657581",
      "font-size": "1rem",
      "margin-top": "1vh",
    },
  });
  result.push({
    selectors: ".checkoutButton",
    style: {
      display: "flex",
      "justify-content": "flex-end",
      "margin-top": "8vh",
    },
  });
  result.push({
    selectors: ".checkoutButton button",
    style: {
      padding: "2vh 2vw",
      "font-size": "1rem",
      "background-color": "red",
      color: "white",
      border: "none",
      "border-radius": "20px",
    },
  });
  // TEMPLATE CHECKOUTPAGE MEDIA QUERIES.................................
  result.push({
    params: "(max-width: 768px)",
    selectors: ".checkout-content img",
    atRule: "media",
    style: {
      height: "8vh",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".checkout-product",
    atRule: "media",
    style: {
      display: "flex",
      "flex-direction": "column",
      height: "inherit",
      "justify-content": "space-between",
      "align-items": "center",
      padding: "1vh 0 2vh 0",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".checkout-button",
    atRule: "media",
    style: {
      display: "flex",
      "flex-direction": "column",
      "align-items": "center",
      "margin-top": "8%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".checkout-button p:first-child",
    atRule: "media",
    style: {
      "font-size": "1.5rem",
      border: "none",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".checkout-button p:last-child",
    atRule: "media",
    style: {
      "font-size": "1.5rem",
      border: "none",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".checkout-button p",
    atRule: "media",
    style: {
      border: "1px solid #ed1c24",
      "border-radius": "8px",
      padding: "0.8vh 3vw",
      color: "#657581",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".checkout-button button",
    atRule: "media",
    style: {
      padding: "2vh 2vw",
      "font-size": "1rem",
      "background-color": "red",
      color: "white",
      border: "none",
      "border-radius": "20px",
      width: "100%",
    },
  });

  // TEMPLATE RECEIPTPAGE
  result.push({
    selectors: ".recieptwhiteWaveOverlay1",
    style: {
      position: "absolute",
      top: "0",
      right: "0",
    },
  });
  result.push({
    selectors: ".recieptwhiteWaveOverlay1 img:first-child",
    style: {
      width: "35rem",
    },
  });
  result.push({
    selectors: ".recieptwhiteWave2",
    style: {
      position: "absolute",
      top: "0",
      right: "0",
      height: "120%",
    },
  });
  result.push({
    selectors: ".checkoutDivR",
    style: {
      position: "relative",
      overflow: "hidden",
      height: "inherit",
      "padding-left": "22vw",
      "padding-right": "4vw",
    },
  });
  result.push({
    selectors: ".thankText",
    style: {
      "font-size": "1.8rem",
    },
  });
  result.push({
    selectors: ".purchaseDesc",
    style: {
      "margin-top": "5vh",
      width: "52%",
      "font-size": "1.2rem",
      "line-height": "150%",
    },
  });
  result.push({
    selectors: ".vectorBackground",
    style: {
      display: "flex",
      "justify-content": "start",
      "align-items": "center",
      "margin-left": "0",
      "overflow-x": "hidden",
    },
  });
  result.push({
    selectors: ".recieptBottom",
    style: {
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center",
      "padding-bottom": "6vh",
      "margin-top": "8%",
    },
  });
  result.push({
    selectors: ".recieptBottom button:first-child",
    style: {
      padding: "1.5vh 4vw",
      "border-radius": "27.5px",
      border: "none",
      color: "white",
      "font-size": "1.2rem",
      "background-color": "black",
    },
  });
  result.push({
    selectors: ".recieptBottom button:last-child",
    style: {
      padding: "1.5vh 4vw",
      "border-radius": "27.5px",
      border: "none",
      color: "white",
      "font-size": "1.2rem",
      "background-color": "red",
    },
  });
  // TEMPLATE RECEIPTPAGE MEDIA QUERIES.................................
  result.push({
    params: "(max-width: 768px)",
    selectors: ".recieptwhiteWaveOverlay1",
    atRule: "media",
    style: {
      position: "absolute",
      top: "0",
      right: "0",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".recieptwhiteWaveOverlay1 img:first-child",
    atRule: "media",
    style: {
      width: "11rem",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".checkoutDivR",
    atRule: "media",
    style: {
      "padding-left": "4vw",
      "padding-right": "4vw",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".thankText",
    atRule: "media",
    style: {
      "font-size": "1.5rem",
      "margin-top": "4vh",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".purchaseDesc",
    atRule: "media",
    style: {
      "margin-top": "3vh",
      width: "100%",
      "font-size": "1rem",
      "line-height": "150%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".vectorBackground",
    atRule: "media",
    style: {
      "margin-left": "0%",
      width: "90vw",
      "margin-top": "8%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".vectorBackground img",
    atRule: "media",
    style: {
      width: "100%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".recieptBottom",
    atRule: "media",
    style: {
      "margin-top": "8%",
      display: "flex",
      "flex-direction": "column",
      "justify-content": "space-between",
      "align-items": "center",
      "padding-bottom": "4vh",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".recieptBottom button:first-child",
    atRule: "media",
    style: {
      width: "100%",
      "margin-bottom": "3%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".recieptBottom button:last-child",
    atRule: "media",
    style: {
      width: "100%",
    },
  });

  // TEMPLATE PAYMENTPAGE
  result.push({
    selectors: ".detail-Main",
    style: {
      "padding-left": "2rem",
      "padding-bottom": "4vh",
    },
  });
  result.push({
    selectors: ".shippingAdd",
    style: {
      "font-size": "1.5rem",
    },
  });
  result.push({
    selectors: ".savedDebitCard",
    style: {
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center",
      border: "2px solid #e0e0e0",
      width: "80%",
      "background-color": "#fff",
      "padding-right": "1vw",
      "margin-top": "1.5vh",
      "margin-bottom": "5vh",
    },
  });
  result.push({
    selectors: ".savedDebitCard div",
    style: {
      display: "flex",
      "justify-content": "space-around",
      "align-items": "center",
      width: "80%",
    },
  });
  result.push({
    selectors: ".savedDebitCard input",
    style: {
      "padding-top": "1vh",
      "padding-bottom": "1vh",
      width: "90%",
      outline: "none",
      border: "none",
      "font-size": "1rem",
    },
  });
  result.push({
    selectors: ".savedDebitCard p",
    style: {
      "background-color": "red",
      height: "2vh",
      width: "1vw",
      "border-radius": "50% 50%",
    },
  });
  result.push({
    selectors: ".savedDebitCard2",
    style: {
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center",
      "border-top": "2px solid #e0e0e0",
      "border-right": "2px solid #e0e0e0",
      "border-left": "2px solid #e0e0e0",
      width: "80%",
      "background-color": "#fff",
      "padding-right": "1vw",
      "margin-top": "2%",
    },
  });
  result.push({
    selectors: ".savedDebitCard2 div",
    style: {
      display: "flex",
      "justify-content": "space-around",
      "align-items": "center",
      width: "80%",
    },
  });
  result.push({
    selectors: ".savedDebitCard2 input",
    style: {
      "padding-top": "1vh",
      "padding-bottom": "1vh",
      width: "90%",
      outline: "none",
      border: "none",
      "font-size": "1rem",
    },
  });
  result.push({
    selectors: ".savedDebitCard2 p",
    style: {
      "background-color": "white",
      height: "2vh",
      width: "1vw",
      "border-radius": "50% 50%",
      border: "1px solid gray",
    },
  });
  result.push({
    selectors: ".savedDebitCard1",
    style: {
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center",
      "border-top": "2px solid #e0e0e0",
      "border-right": "2px solid #e0e0e0",
      "border-left": "2px solid #e0e0e0",
      width: "80%",
      "background-color": "#fff",
      "padding-right": "1vw",
    },
  });
  result.push({
    selectors: ".savedDebitCard1 div",
    style: {
      display: "flex",
      "justify-content": "space-around",
      "align-items": "center",
      width: "80%",
    },
  });
  result.push({
    selectors: ".savedDebitCard1 input",
    style: {
      "padding-top": "1vh",
      "padding-bottom": "1vh",
      width: "90%",
      outline: "none",
      border: "none",
      "font-size": "1rem",
    },
  });
  result.push({
    selectors: ".savedDebitCard1 p",
    style: {
      "background-color": "white",
      height: "2vh",
      width: "1vw",
      "border-radius": "50% 50%",
      border: "1px solid gray",
    },
  });
  result.push({
    selectors: ".savedDebitCard3",
    style: {
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center",
      border: "2px solid #e0e0e0",
      width: "80%",
      "background-color": "#fff",
      "padding-right": "1vw",
    },
  });
  result.push({
    selectors: ".savedDebitCard3 div",
    style: {
      display: "flex",
      "justify-content": "space-around",
      "align-items": "center",
      width: "80%",
    },
  });
  result.push({
    selectors: ".savedDebitCard3 input",
    style: {
      "padding-top": "1vh",
      "padding-bottom": "1vh",
      width: "90%",
      outline: "none",
      border: "none",
      "font-size": "1rem",
    },
  });
  result.push({
    selectors: ".savedDebitCard3 p",
    style: {
      "background-color": "white",
      height: "2vh",
      width: "1vw",
      "border-radius": "50% 50%",
      border: "1px solid gray",
    },
  });
  result.push({
    selectors: ".paymentNote",
    style: {
      "margin-top": "2%",
      "font-size": "0.8rem",
      color: "red",
    },
  });
  result.push({
    selectors: ".paymentBottomButtom",
    style: {
      display: "flex",
      "justify-content": "flex-end",
      "align-items": "center",
      "margin-top": "6%",
    },
  });
  result.push({
    selectors: ".paymentBottomButtom button",
    style: {
      padding: "1.5vh 3vw",
      "border-radius": "27.5px",
      border: "none",
      "background-color": "red",
      color: "white",
      "box-shadow": "4px 4px 10px rgba(0, 0, 0, 0.35)",
    },
  });
  // TEMPLATE PAYMENTPAGE MEDIA QUERIES.................................
  result.push({
    params: "(max-width: 768px)",
    selectors: ".savedDebitCard",
    atRule: "media",
    style: {
      width: "95%",
      "padding-right": "2vw",
      "margin-bottom": "4vh",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".savedDebitCard div",
    atRule: "media",
    style: {
      width: "95%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".savedDebitCard input",
    atRule: "media",
    style: {
      width: "80%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".savedDebitCard p",
    atRule: "media",
    style: {
      height: "1em",
      width: "1em",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".savedDebitCard2",
    atRule: "media",
    style: {
      width: "95%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".savedDebitCard2 div",
    atRule: "media",
    style: {
      width: "95%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".savedDebitCard2 input",
    atRule: "media",
    style: {
      width: "80%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".savedDebitCard2 p",
    atRule: "media",
    style: {
      height: "1em",
      width: "1em",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".savedDebitCard1",
    atRule: "media",
    style: {
      width: "95%",
      "padding-right": "2vw",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".savedDebitCard1 div",
    atRule: "media",
    style: {
      width: "95%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".savedDebitCard1 input",
    atRule: "media",
    style: {
      width: "80%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".savedDebitCard1 p",
    atRule: "media",
    style: {
      width: "1em",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".savedDebitCard3",
    atRule: "media",
    style: {
      width: "95%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".savedDebitCard3 div",
    atRule: "media",
    style: {
      width: "95%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".savedDebitCard3 input",
    atRule: "media",
    style: {
      width: "80%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".savedDebitCard3 p",
    atRule: "media",
    style: {
      width: "1em",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".paymentNote",
    atRule: "media",
    style: {
      "margin-top": "6%",
      "line-height": "150%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".paymentBottomButtom",
    atRule: "media",
    style: {
      "margin-top": "12%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".paymentBottomButtom button",
    atRule: "media",
    style: {
      width: "100%",
    },
  });

  // TEMPLATE ADDNEWPRODUCTPAGE
  result.push({
    selectors: ".mainDiv-head1",
    style: {
      display: "flex",
      "justify-content": "space-between",
      "align-items": "start",
      padding: "0vh 2.5vw",
      position: "relative",
      "margin-top": "1.7%",
    },
  });
  result.push({
    selectors: ".mainDiv-head1 p:first-child",
    style: {
      "font-style": "normal",
      "font-size": "2.8rem",
      "padding-left": "16.2%",
    },
  });
  result.push({
    selectors: ".mainDiv-head1 p:last-child",
    style: {
      "font-size": "1rem",
      padding: "0.7% 2%",
      "margin-right": "2%",
      "background-color": "red",
      color: "white",
      "box-shadow": "4px 4px 10px rgba(0, 0, 0, 0.35)",
      "border-radius": "27.5px",
    },
  });
  result.push({
    selectors: ".overlayDiv",
    style: {
      position: "relative",
      "margin-top": "3.8vh",
      height: "388px",
      background: "white",
      "z-index": "20",
      "padding-left": "18vw",
      "padding-right": "1vw",
      "padding-top": "0.7%",
    },
  });
  result.push({
    selectors: ".overlayHead",
    style: {
      "font-size": "2rem",
    },
  });
  result.push({
    selectors: ".overlayInnerDiv",
    style: {
      "margin-top": "3vh",
    },
  });
  result.push({
    selectors: ".overlayInnerPara",
    style: {
      "font-size": "1.2rem",
      "margin-bottom": "1vh",
    },
  });
  result.push({
    selectors: ".input1",
    style: {
      width: "434px",
      height: "35px",
      border: "1px solid #ff0000",
      "border-radius": "6px",
      "padding-left": "1vw",
    },
  });
  result.push({
    selectors: ".input2",
    style: {
      width: "272px",
      height: "35px",
      border: "1px solid #ff0000",
      "border-radius": "6px",
      "padding-left": "4%",
    },
  });
  result.push({
    selectors: ".productGallery1",
    style: {
      display: "flex",
      "justify-content": "flex-start",
      "align-items": "center",
      "margin-top": "5vh",
    },
  });
  result.push({
    selectors: ".productGallery2",
    style: {
      display: "flex",
      "justify-content": "center",
      "align-items": "center",
    },
  });
  result.push({
    selectors: ".galleryWidth",
    style: {
      "margin-left": "4vw",
    },
  });
  result.push({
    selectors: ".wrapperGridImage",
    style: {
      display: "grid",
      "grid-template-columns": "1fr 1fr 1fr",
    },
  });
  result.push({
    selectors: ".wrapperGridImage img",
    style: {
      width: "5vw",
    },
  });
  result.push({
    selectors: ".galleryLastDiv",
    style: {
      "margin-left": "1vw",
    },
  });
  result.push({
    selectors: ".bottomDivWrapper",
    style: {
      "padding-left": "18vw",
      "padding-right": "2%",
      "margin-top": "5vh",
    },
  });
  result.push({
    selectors: ".bottomDivWrapper a",
    style: {
      color: "red",
    },
  });
  result.push({
    selectors: ".overlayInnerDiv1",
    style: {
      display: "flex",
      "align-items": "center",
      "justify-content": "space-between",
      "margin-top": "2vh",
    },
  });
  // TEMPLATE ADDNEWPRODUCTPAGE MEDIA QUERIES.................................
  result.push({
    params: "(max-width: 768px)",
    selectors: ".overlayDiv",
    atRule: "media",
    style: {
      "margin-top": "3.8vh",
      background: "white",
      "z-index": "20",
      "padding-left": "5%",
      "padding-top": "0.7%",
      height: "initial",
      "padding-bottom": "3vh",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".overlayHead",
    atRule: "media",
    style: {
      "font-size": "1.8rem",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".overlayInnerPara",
    atRule: "media",
    style: {
      "margin-top": "5%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".input1",
    atRule: "media",
    style: {
      width: "80vw",
      height: "35px",
      "border-radius": "6px",
      "padding-left": "1vw",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".productGallery1",
    atRule: "media",
    style: {
      display: "block",
      "margin-top": "5vh",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".productGallery2",
    atRule: "media",
    style: {
      display: "block",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".galleryWidth",
    atRule: "media",
    style: {
      "margin-left": "0vw",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".wrapperGridImage",
    atRule: "media",
    style: {
      "margin-top": "6%",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".wrapperGridImage img",
    atRule: "media",
    style: {
      width: "15vw",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".bottomDivWrapper",
    atRule: "media",
    style: {
      "padding-left": "5vw",
      "padding-right": "5vw",
      "padding-bottom": "4vh",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".bottomDivWrapper",
    atRule: "media",
    style: {
      display: "block",
    },
  });
  result.push({
    params: "(max-width: 768px)",
    selectors: ".overlayInnerDiv1",
    atRule: "media",
    style: {
      "flex-direction": "column",
    },
  });

  // TEMPLATE DASHBOARD PAGE REGULAR CSS
  result.push({
    selectors: ".dashBoardTable",
    style: {
      width: "100%",
      "overflow-x": "scroll",
      "margin-left": "0",
      "margin-top": "0",
    },
  });

  result.push({
    selectors: ".dashBoardTh",
    style: {
      "font-size": "1.12rem",
    },
  });

  result.push({
    selectors: ".dashboardTd",
    style: {
      "text-align": "center",
      "font-size": "1rem",
      padding: "2.2vh 0",
    },
  });

  result.push({
    selectors: ".dashboardTd button",
    style: {
      background: "linear-gradient(90.18deg, #ef4770 40.16%, #977df4 99.89%)",
      border: "none",
      width: "6vw",
      height: "4vh",
      "border-radius": "20px",
      color: "white",
      "font-size": "1rem",
    },
  });

  result.push({
    selectors: ".DashHead ",
    style: {
      display: "flex",
      "justify-content": "space-between",
      padding: "2.5vh 2vw",
      "align-items": "center",
    },
  });

  result.push({
    selectors: ".DashHead div ",
    style: {
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center",
      " font-size": "1rem",
      color: "#5d6a75",
    },
  });

  result.push({
    selectors: ".DashHead div img ",
    style: {
      width: "1.8rem",
      " margin-left": "0.2vw",
    },
  });

  result.push({
    selectors: ".dashHero ",
    style: {
      display: "flex",
      padding: " 0 2vw",
      "justify-content": "flex-start",
      "align-items": "flex-start",
    },
  });

  result.push({
    selectors: ".heroLeft ",
    style: {
      width: "10vw",
      "box-shadow": "4px 4px 4px rgba(0, 0, 0, 0.25)",
      height: "140vh",
      "padding-top": "8vh",
      "font-size": "1.2rem",
      color: "#5d6a75",
    },
  });

  result.push({
    selectors: ".heroLeft p ",
    style: {
      width: "7vw",
    },
  });

  result.push({
    selectors: ".heroLeft p:first-child ",
    style: {
      "margin-bottom": "2.5vh",
      "border-image":
        "linear-gradient(90deg, #ef4770 25.7%, #977df4 105.88%) 0 0 30 0",
      "border-width": "4px",
      "border-style": "solid",
    },
  });

  result.push({
    selectors: ".heroLeft p:last-child  ",
    style: {
      "margin-top": "2.5vh",
    },
  });

  result.push({
    selectors: ".heroRight  ",
    style: {
      "padding-left": "2vw",
      height: "fit-content",
    },
  });

  result.push({
    selectors: ".heroRightTopText  ",
    style: {
      "font-size": "3rem",
    },
  });

  result.push({
    selectors: ".dashMainContent  ",
    style: {
      display: "flex",
      "justify-content": "flex-start",
      "align-items": "flex-start",
    },
  });

  result.push({
    selectors: ".dashContentLeft  ",
    style: {
      "margin-top": "1.2vh",
      "margin-right": "8vw",
      width: "40vw",
    },
  });

  result.push({
    selectors: ".dashContentLeftHead button:first-child   ",
    style: {
      "border-radius": "27.5px 27.5px",
      padding: "1vh 1vw",
      border: "1px solid black",
      "font-size": "1rem",
      "margin-right": "1.2vw",
    },
  });

  result.push({
    selectors: ".dashContentLeftHead button:last-child  ",
    style: {
      border: "none",
      "background-color": "white",
      color: "#5d6a75",
      "font-size": "1rem",
    },
  });

  result.push({
    selectors: ".dashContentLeftText  ",
    style: {
      color: "#5d6a75",
      "font-size": "1rem",
      "margin-top": "2vh",
    },
  });

  result.push({
    selectors: ".leftContentBlue  ",
    style: {
      width: "fit-content",
      height: "fit-content",
      background:
        "linear-gradient(to bottom right, #d4e0ff 2.96%, #ffffff 69.72%)",
      border: "1px solid #c7d7ff",
      "box-shadow": "6px 6px 25px 6px rgba(0, 0, 0, 0.12)",
      "border-radius": "20px",
      padding: "3.5vh 2vw",
      "margin-top": "4vh",
    },
  });

  result.push({
    selectors: ".leftContentBlueHead  ",
    style: {
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center",
      "flex-wrap": "wrap",
    },
  });

  result.push({
    selectors: ".leftContentBlueHead p:first-child  ",
    style: {
      "font-size": "1rem",
      color: "#7c93b4",
    },
  });

  result.push({
    selectors: ".leftContentBlueHead p:last-child  ",
    style: {
      border: "2px solid #c7d7ff",
      padding: "1vh 1vw",
      "border-radius": "6px",
      "background-color": "white",
    },
  });

  result.push({
    selectors: ".leftContentBlueText ",
    style: {
      "font-size": "2.3rem",
      "margin-top": "2%",
    },
  });

  result.push({
    selectors: ".leftContentBlueBottom ",
    style: {
      display: "flex",
      "justify-content": "start",
      "align-items": "flex-end",
      "margin-top": "4vh",
      "flex-wrap": "wrap",
    },
  });

  result.push({
    selectors: ".leftContentBottomLeft ",
    style: {
      color: "#29b62f",
      "font-size": "1.1rem",
      "border-radius": "17px",
      background: "#e9ffea",
      padding: "0.5vh 0.5vw",
      "margin-right": "12.5vw",
    },
  });

  result.push({
    selectors: ".leftContentBottomMonth ",
    style: {
      display: "flex",
      "flex-direction": "column",
      "align-items": "flex-end",
      "font-size": "1rem",
      "margin-right": "2.5vw",
    },
  });

  result.push({
    selectors: ".leftContentBottomMonth p:first-child ",
    style: {
      color: "#7c93b4",
    },
  });

  result.push({
    selectors: ".leftContentBottomMonth p:last-child ",
    style: {
      "font-size": "1.3rem",
      "margin-top": "2vh",
    },
  });

  result.push({
    selectors: ".leftContentBottomMonth p:last-child span ",
    style: {
      color: "red",
    },
  });

  result.push({
    selectors: ".leftContentBottomMonth1",
    style: {
      display: "flex",
      "flex-direction": "column",
      "align-items": "flex-end",
      "font-size": "1rem",
    },
  });

  result.push({
    selectors: ".leftContentBottomMonth1 p:first-child",
    style: {
      color: "#7c93b4",
    },
  });

  result.push({
    selectors: ".leftContentBottomMonth1 p:last-child",
    style: {
      "font-size": "1.3rem",
      "margin-top": "2vh",
      color: "#29b62f",
    },
  });

  result.push({
    selectors: ".leftContentPink",
    style: {
      background: "#f16e8c",
      "border-radius": "11px",
      position: "relative",
      width: "40vw",
      height: "13.8vh",
      "margin-top": "4.5vh",
    },
  });

  result.push({
    selectors: ".leftContentPinkHead",
    style: {
      display: "flex",
      "justify-content": "flex-end",
      "align-items": "center",
      "font-size": "0.9rem",
      padding: "3.5vh 1vw 1vh 1vw",
      "z-index": "10",
      position: "relative",
      color: "white",
    },
  });

  result.push({
    selectors: ".pinkDivAbs",
    style: {
      position: "absolute",
      top: "10%",
    },
  });

  result.push({
    selectors: ".pinkHead1",
    style: {
      "margin-left": "2vw",
    },
  });

  result.push({
    selectors: ".leftContentPinkText",
    style: {
      "z-index": "10",
      position: "relative",
      display: "flex",
      "align-items": "center",
      "padding-left": "1vw",
      "font-size": "0.9rem",
      color: "white",
    },
  });

  result.push({
    selectors: ".leftContentPinkText img",
    style: {
      width: "0.8vw",
      height: "1.3vh",
      "margin-left": "0.5vw",
    },
  });

  result.push({
    selectors: ".leftContentStats",
    style: {
      "margin-top": "6%",
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center",
    },
  });

  result.push({
    selectors: ".leftContentStats p",
    style: {
      "font-size": "1.3rem",
    },
  });

  result.push({
    selectors: ".leftContentStats button",
    style: {
      width: "6.5vw",
      height: "4.5vh",
      display: "flex",
      "justify-content": "center",
      "align-items": "center",
      "border-radius": "27.5px 27.5px",
      "background-color": "white",
    },
  });

  result.push({
    selectors: ".leftContentPurple",
    style: {
      display: "flex",
      position: "relative",
      "justify-content": "space-between",
      "align-items": "center",
      padding: "0vh 2vw",
      background: "#bfadf1",
      height: "15vh",
      "border-radius": " 11px",
      "margin-top": "5%",
    },
  });

  result.push({
    selectors: ".leftContentPurpleAbsolute",
    style: {
      position: "absolute",
      top: "0",
      right: "0",
    },
  });

  result.push({
    selectors: ".leftContentPurpleInner",
    style: {
      "z-index": "10",
      display: "flex",
      "flex-direction": "column",
      "align-items": "center",
    },
  });

  result.push({
    selectors: ".leftContentPurpleInner p:first-child",
    style: {
      "font-size": "2rem",
      "margin-bottom": "6%",
    },
  });

  result.push({
    selectors: ".leftContentPurpleInner p:last-child",
    style: {
      "font-size": "1.1rem",
    },
  });

  result.push({
    selectors: ".leftMainContentInvoice",
    style: {
      "font-size": " 1.5rem",
      "margin-top": "6%",
    },
  });

  result.push({
    selectors: ".leftMainContentInvoiceWrapper",
    style: {
      display: "flex",
      "padding-bottom": "8vh",
      "justify-content": "space-between",
      "align-items": "start",
      "margin-top": "2.5%",
    },
  });

  result.push({
    selectors: ".contentP1",
    style: {
      color: "#0e6b06",
      "text-align": "center",
    },
  });

  result.push({
    selectors: ".contentP2",
    style: {
      color: "#ff0000",
    },
  });

  result.push({
    selectors: ".dashContentRightHead",
    style: {
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center",
      width: "100%",
    },
  });

  result.push({
    selectors: ".dashContentRightHead p",
    style: {
      color: "#424242",
    },
  });

  result.push({
    selectors: ".dashContentRightHead div",
    style: {
      background: "linear-gradient(90.18deg, #ef4770 40.16%, #977df4 99.89%)",
      width: "22%",
      height: "5vh",
      "border-radius": "20px",
      display: "flex",
      "justify-content": "center",
      "align-items": "center",
    },
  });

  result.push({
    selectors: ".dashContentRightHead div p",
    style: {
      color: "white",
      "margin-right": "8%",
    },
  });

  result.push({
    selectors: ".dashContentRight",
    style: {
      width: "35vw",
    },
  });

  result.push({
    selectors: ".dashContentRightImg",
    style: {
      display: "flex",
      "justify-content": "start",
      "align-items": "center",
      "margin-top": "2%",
    },
  });

  result.push({
    selectors: ".dashContentRightImg img",
    style: {
      width: "15%",
      "margin-right": "3%",
    },
  });

  result.push({
    selectors: ".dashContentRightImgWrapper",
    style: {
      "margin-top": "5%",
    },
  });

  result.push({
    selectors: ".dashContentRightBottom",
    style: {
      background: "linear-gradient(90.18deg, #ef4770 40.16%, #977df4 99.89%)",
      "border-radius": "20px",
      "margin-top": "15%",
      padding: "3vh 2vw 0 2vw",
      position: "relative",
    },
  });

  result.push({
    selectors: ".dashContentRightBottomHead",
    style: {
      "text-align": "end",
      "font-size": "0.9rem",
      color: "white",
      "letter-spacing": "0.005em",
    },
  });

  result.push({
    selectors: ".dashContentRightBottomHead1",
    style: {
      "margin-top": "1%",
      color: "white",
    },
  });

  result.push({
    selectors: ".dashContentRightBottomSmiley",
    style: {
      display: "flex",
      "margin-top": "3%",
      "align-items": "center",
      "justify-content": "start",
      "font-size": "2rem",
      color: "white",
    },
  });

  result.push({
    selectors: ".dashContentRightBottomSmiley img",
    style: {
      " margin-right": "2%",
    },
  });

  result.push({
    selectors: ".dashContentRightBottomWrapper",
    style: {
      display: "flex",
      "align-items": "start",
      "justify-content": "start",
      "margin-top": "4%",
      "font-size": "0.8rem",
    },
  });

  result.push({
    selectors: ".dashContentRightBottomLeft",
    style: {
      width: "45%",
      " margin-right": "3%",
    },
  });

  result.push({
    selectors: ".dashContentRightBottomLeft1",
    style: {
      display: "flex",
      "justify-content": "space-around",
      "align-items": "center",
      "background-color": "white",
      padding: "1vh 0",
      "border-radius": "6px",
    },
  });

  result.push({
    selectors: ".dashContentRightBottomLeft2",
    style: {
      "background-color": "white",
      padding: "2vh 0.7vw 1vh 1.5vw",
      "border-radius": "6px",
      margin: "2vh 0",
      height: "10vh",
    },
  });

  result.push({
    selectors: ".dashContentRightBottomLeft2 p:last-child",
    style: {
      display: "flex",
      "justify-content": "end",
      "margin-top": "12%",
    },
  });

  result.push({
    selectors: ".dashContentRightBottomLeft3",
    style: {
      "background-color": "white",
      padding: "1vh 1vw 1vh 1.5vw",
      "border-top-right-radius": "6px",
      "border-top-left-radius": "6px",
    },
  });

  result.push({
    selectors: ".dashContentRightBottomRight1",
    style: {
      display: "flex",
      "justify-content": "space-around",
      "align-items": "center",
      "background-color": "white",
      padding: "1vh 1vw",
      "border-radius": "6px",
      "line-height": "150%",
    },
  });

  result.push({
    selectors: ".dashContentRightBottomRight2",
    style: {
      " background-color": "white",
      padding: "2vh 1vw 1vh 1.5vw",
      "border-radius": "6px",
      margin: "2vh 0",
      height: "13vh",
      "line-height": "180%",
    },
  });

  result.push({
    selectors: ".dashContentRightBottomRight2 p:last-child",
    style: {
      display: "flex",
      "justify-content": "end",
      "margin-top": "8%",
    },
  });

  result.push({
    selectors: ".dashBottomRect",
    style: {
      position: "absolute",
      top: "12%",
      right: "2%",
    },
  });

  // MEDIA CLASSES FOR DASHBOARD PAGE

  result.push({
    params: "(max-width: 768px)",
    selectors: ".heroLeft",
    atRule: "media",
    style: {
      display: "none",
    },
  });

  result.push({
    params: "(max-width: 768px)",
    selectors: ".heroRight",
    atRule: "media",
    style: {
      "overflow-x": "hidden",
    },
  });

  result.push({
    params: "(max-width: 768px)",
    selectors: ".dashMainContent",
    atRule: "media",
    style: {
      display: "block",
    },
  });

  result.push({
    params: "(max-width: 768px)",
    selectors: ".dashContentLeft  ",
    atRule: "media",
    style: {
      width: "92vw",
    },
  });

  result.push({
    params: "(max-width: 768px)",
    selectors: ".leftContentBlue",
    atRule: "media",
    style: {
      width: "90%",
      "border-radius": "20px",
      padding: "3.5vh 4vw",
    },
  });

  result.push({
    params: "(max-width: 768px)",
    selectors: ".leftContentBottomLeft",
    atRule: "media",
    style: {
      "margin-right": "30vw",
      padding: "1vh 1vw",
    },
  });

  result.push({
    params: "(max-width: 768px)",
    selectors: ".leftContentBottomMonth",
    atRule: "media",
    style: {
      "margin-right": "4vw",
    },
  });

  result.push({
    params: "(max-width: 768px)",
    selectors: ".leftContentBlueBottom",
    atRule: "media",
    style: {
      "margin-top": "7vh",
    },
  });

  result.push({
    params: "(max-width: 768px)",
    selectors: ".leftContentPink",
    atRule: "media",
    style: {
      width: "92vw",
      overflow: "hidden",
    },
  });

  result.push({
    params: "(max-width: 768px)",
    selectors: ".pinkDivAbs",
    atRule: "media",
    style: {
      top: "24%",
    },
  });

  result.push({
    params: "(max-width: 768px)",
    selectors: ".leftContentPinkHead",
    atRule: "media",
    style: {
      display: "flex",
      "justify-content": "center",
      "align-items": "center",
      "font-size": "0.75rem",
      padding: "2.5vh 0.5vw 1vh 0.5vw",
      "z-index": "10",
      position: "relative",
      color: "white",
    },
  });

  result.push({
    params: "(max-width: 768px)",
    selectors: ".pinkHead1",
    atRule: "media",
    style: {
      "margin-left": "2.5vw",
    },
  });

  result.push({
    params: "(max-width: 768px)",
    selectors: ".leftContentPinkText",
    atRule: "media",
    style: {
      "padding-left": "2vw",
      " margin-top": "8%",
    },
  });

  result.push({
    params: "(max-width: 768px)",
    selectors: ".leftContentPinkText img",
    atRule: "media",
    style: {
      width: "3vw",
      height: "1vh",
      "margin-left": "2vw",
    },
  });

  result.push({
    params: "(max-width: 768px)",
    selectors: ".leftContentStats button",
    atRule: "media",
    style: {
      width: "24vw",
    },
  });

  result.push({
    params: "(max-width: 768px)",
    selectors: ".leftContentPurple",
    atRule: "media",
    style: {
      overflow: "hidden",
    },
  });

  result.push({
    params: "(max-width: 768px)",
    selectors: ".leftMainContentInvoiceWrapper",
    atRule: "media",
    style: {
      "padding-bottom": "4vh",
      "margin-top": "4%",
    },
  });

  result.push({
    params: "(max-width: 768px)",
    selectors: ".leftContentStats",
    atRule: "media",
    style: {
      width: "92vw",
    },
  });

  result.push({
    params: "(max-width: 768px)",
    selectors: ".leftContentPurple",
    atRule: "media",
    style: {
      width: "88vw",
    },
  });

  result.push({
    params: "(max-width: 768px)",
    selectors: ".dashBoardTable",
    atRule: "media",
    style: {
      width: "180vw",
    },
  });

  result.push({
    params: "(max-width: 768px)",
    selectors: ".dashBoardTable p",
    atRule: "media",
    style: {
      width: "28vw",
    },
  });

  result.push({
    params: "(max-width: 768px)",
    selectors: ".dashoardTd button",
    atRule: "media",
    style: {
      width: "20vw",
    },
  });

  result.push({
    params: "(max-width: 768px)",
    selectors: ".dashContentRight",
    atRule: "media",
    style: {
      width: "92vw",
      "margin-top": "4%",
    },
  });

  result.push({
    params: "(max-width: 768px)",
    selectors: ".dashContentRightHead div",
    atRule: "media",
    style: {
      width: "30%",
    },
  });

  result.push({
    params: "(max-width: 768px)",
    selectors: ".dashContentRightBottomWrapper",
    atRule: "media",
    style: {
      display: "block",
      "margin-top": "8%",
      "font-size": "1rem",
    },
  });

  result.push({
    params: "(max-width: 768px)",
    selectors: ".dashContentRightBottomLeft",
    atRule: "media",
    style: {
      width: "100%",
      "margin-right": "3%",
    },
  });

  result.push({
    params: "(max-width: 768px)",
    selectors: ".dashContentRightBottomLeft1",
    atRule: "media",
    style: {
      display: "flex",
      "justify-content": "space-between",
      "align-items": "center",
      "background-color": "white",
      padding: "3vh 6vw 3vh 4vw",
      "border-radius": "6px",
    },
  });

  result.push({
    params: "(max-width: 768px)",
    selectors: ".dashContentRightBottomLeft2",
    atRule: "media",
    style: {
      "background-color": "white",
      padding: "2vh 4vw 1vh 4vw",
      "border-radius": "6px",
      margin: "2vh 0",
      height: "10vh",
    },
  });

  result.push({
    params: "(max-width: 768px)",
    selectors: ".dashContentRightBottomLeft3",
    atRule: "media",
    style: {
      padding: "3vh 4vw",
      "margin-bottom": "5%",
    },
  });

  result.push({
    params: "(max-width: 768px)",
    selectors: ".dashContentRightBottom",
    atRule: "media",
    style: {
      padding: "3vh 5vw",
      "margin-bottom": "5%",
    },
  });

  result.push({
    params: "(max-width: 768px)",
    selectors: ".dashContentRightBottomRight1",
    atRule: "media",
    style: {
      padding: "3vh 4vw",
      "border-radius": "6px",
      "line-height": "150%",
    },
  });

  result.push({
    params: "(max-width: 768px)",
    selectors: ".dashContentRightBottomRight2",
    atRule: "media",
    style: {
      "background-color": "white",
      padding: "3vh 4vw 3vh 4vw",
      "border-radius": "6px",
      margin: "2vh 0",
      height: "13vh",
      "line-height": "180%",
    },
  });

  result.push({
    params: "(max-width: 768px)",
    selectors: ".dashContentRightBottomRight2 p:last-child",
    atRule: "media",
    style: {
      display: "flex",
      "justify-content": "end",
      "margin-top": "8%",
    },
  });

  // CAROUSEL
  result.push({
    selectors: ".slider",
    style: {
      width: "300px",
      "text-align": "center",
      overflow: "hidden",
    },
  });
  result.push({
    selectors: ".slides",
    style: {
      display: "flex",
      "overflow-x": "auto",
      "scroll-snap-type": "x mandatory",
      "scroll-behavior": "smooth",
      "-webkit-overflow-scrolling": "touch",
    },
  });
  result.push({
    selectors: ".slides::-webkit-scrollbar",
    style: {
      width: "10px",
      height: "10px",
    },
  });
  result.push({
    selectors: ".slides::-webkit-scrollbar-thumb",
    style: {
      background: "black",
      "border-radius": "10px",
    },
  });
  result.push({
    selectors: ".slides::-webkit-scrollbar-track",
    style: {
      background: "transparent",
    },
  });
  result.push({
    selectors: ".slides > div",
    style: {
      "scroll-snap-align": "start",
      "flex-shrink": "0",
      width: "300px",
      height: "300px",
      "margin-right": "1px",
      "border-radius": "10px",
      background: "#eee",
      "transform-origin": "center center",
      transform: "scale(1)",
      transition: "transform 0.5s",
      position: "relative",

      display: "flex",
      "justify-content": "center",
      "align-items": "center",
      "font-size": "100px",
    },
  });

  result.push({
    selectors: ".author-info",
    style: {
      background: "rgba(0, 0, 0, 0.75)",
      color: "white",
      padding: "0.75rem",
      "text-align": "center",
      position: "absolute",
      bottom: "0",
      left: "0",
      width: "100%",
      margin: "0",
    },
  });
  result.push({
    selectors: ".author-info a",
    style: {
      color: "white",
    },
  });
  // result.push({
  //     selectors: 'img',
  //     style: {
  //         'object-fit': 'cover',
  //         position: 'absolute',
  //         top: '0',
  //         left: '0',
  //         width: '100%',
  //         height: '100%',
  //     },
  // });
  result.push({
    selectors: "slider > a",
    style: {
      display: "inline-flex",
      width: "1.5rem",
      height: "1.5rem",
      background: "white",
      "text-decoration": "none",
      "align-items": "center",
      "justify-content": "center",
      "border-radius": "50%",
      margin: "0 0 0.5rem 0",
      position: "relative",
    },
  });
  result.push({
    selectors: "slider > a:active",
    style: {
      top: "1px",
    },
  });
  result.push({
    selectors: "slider > a:focus",
    style: {
      background: "#000",
    },
  });
  result.push({
    params: "(scroll-snap-type)",
    selectors: ".slider > a",
    atRule: "supports",
    style: {
      display: "none",
    },
  });

  result.push({
    selectors: ".headerWrapper",

    style: {
      padding: "2vh 1vw",

      display: "flex",

      "align-items": "center",

      "justify-content": "space-between",
    },
  });

  result.push({
    selectors: ".newIcon",

    style: {
      display: "flex",

      "align-items": "center",

      "justify-content": "center",
    },
  });

  result.push({
    selectors: ".icon2 span",

    style: {
      "font-size": "1.5rem",

      "margin-left": "0.5rem",

      "font-weight": "600",

      "letter-spacing": "3px",
    },
  });

  result.push({
    selectors: ".rightSection",

    style: {
      width: "50%",
    },
  });

  // IONIC TABS

    result.push({
        selectors: '.example-content',
        style: {
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            height: '100%',
            border: '2px solid red',
        },
    });


    result.push({
        selectors: '.ion-item::part(native)',
        style: {
            'background-color': '#19422d',
            color: '#fff',
            'border-color': '#fff',
            'border-style': 'dashed',
            'border-width': '2px',
            'border-radius': '20px',
        },
    });

    result.push({
        selectors: '.div1Tab',
        style: {
            display: 'flex',
            'flex-direction': 'column',
            'align-items': 'center',
            'justify-content': 'center',
            width: '33%'
        },
    });

    result.push({
        selectors: '.div2Tab',
        style: {
            display: 'flex',
            'flex-direction': 'column',
            'align-items': 'center',
            'justify-content': 'center',
            width: '33%'
        },
    });

    result.push({
        selectors: '.div3Tab',
        style: {
            display: 'flex',
            'flex-direction': 'column',
            'align-items': 'center',
            'justify-content': 'center',
            width: '33%'
        },
    });



    result.push({
        selectors: '.searchBarInput',
        style: {
            outline: 'none',
            'flex-grow': '1',
            'background-color': 'white',
            'padding': '1rem 0.5rem'
        },
    });


    result.push({
        selectors: '.MenuActionComp',
        style: {
            display: 'none',
            height: '100%',
            width: '100%',
        },
    });

    result.push({
        selectors: '.MenuActionCloseWrapper',
        style: {
            display: 'flex',
            'justify-content': 'space-between',
            'align-items': 'center'
        },
    });

    result.push({
        selectors: '.MenuHeadComp',
        style: {
            display: 'flex',
            'justify-content': 'start',
            'align-items': 'center'
        },
    });

    result.push({
        selectors: '.MenuHeadCompP',
        style: {
            'margin-left': '10rem'
        },
    });



    result.push({
        selectors: '.confirmWrapper',
        style: {
            display: 'none',
        },
    });

    result.push({
        selectors: '.confirmAlertWrapper',
        style: {
            display: 'none',
        },
    });

    result.push({
        selectors: '.buttonWrapper',
        style: {
            display: 'flex',
            'border-radius': '6px',
            'justify-content': 'start',
            'align-items': 'center'
        },
    });

    result.push({
        selectors: '.AlertbuttonWrapper',
        style: {
            display: 'flex',
            'border-radius': '6px',
            'justify-content': 'end',
            'align-items': 'center',
            'margin-top': '4rem',
        },
    });

    result.push({
        selectors: '.actionBtn1',
        style: {
            padding: '0.5rem',
            width: '45%',
            'border-radius': '6px',
            color: 'white',
            'background-color': 'red',
        },
    });

    result.push({
        selectors: '.closeAlert',
        style: {
            padding: '0.5rem 1rem',
            'border-radius': '6px',
            color: 'white',
            'background-color': 'blue',
        },
    });

    result.push({
        selectors: '.actionBtn2',
        style: {
            padding: '0.5rem',
            width: '45%',
            'border-radius': '6px',
            color: 'white',
            'background-color': 'green',
            'margin-left': '10px'
        },
    });

    result.push({
        selectors: '.deleteBtn',
        style: {
            padding: '0.5rem 1rem',
            'border-radius': '6px',
            color: 'white',
            'background-color': 'red',
        },
    });

    result.push({
        selectors: '.mobAlertBtn',
        style: {
            padding: '0.5rem 1rem',
            'border-radius': '6px',
            color: 'white',
            'background-color': 'green',
        },
    });

    result.push({
        selectors: '.floatingBtnTrigger',
        style: {
            position: 'absolute',
            bottom: '10px',
            right: '10px',
        },
    });

    result.push({
        selectors: '.floatingIconWrapper',
        style: {
            display: 'none',
        },
    });

    result.push({
        selectors: '.floatingIcon2',
        style: {
            'margin-top': '10px',
            'margin-bottom': '10px',
        },
    });


    result.push({

        selectors: '.nested-dropdowns',

        style: {

            display: 'flex',

            'list-style-type': 'none',

            margin: 0,

            padding: 0,

        },

    });


    result.push({

        selectors: '.nested-dropdowns li',

        style: {

            cursor: 'pointer',

            width: '12vw',

            'font-size': '1.2rem',

            padding: '0.5rem',

            position: 'relative',

        },

    });


    result.push({

        selectors: '.nested-dropdowns__item',

        style: {

            display: 'flex',

            'align-items': 'center',

            'justify-content': 'space-between',

        },

    });


    result.push({

        selectors: '.nested-dropdowns__arrow',

        style: {

            'margin-left': '1rem'

        },

    });



    result.push({
        selectors: '#wrapGrid',
        style: {
            display: 'flex',
            'justify-content': 'space-evenly',
            'align-items': 'center',
        },
    });

    result.push({
        selectors: '#wrapGrid p',
        style: {
            width: '50%',
        },
    });


    result.push({

        selectors: '.nested-dropdowns ul',

        style: {

            display: 'none',

            left: '0',

            position: 'absolute',

            top: '100%',

            'background-color': 'white',

            'list-style-type': 'none',

            margin: '0',

            padding: '0',

        },

    });


    result.push({

        selectors: '.nested-dropdowns ul ul',

        style: {

            left: '100%',

            position: 'absolute',

            top: '0',

        },

    });


    result.push({

        selectors: '.nested-dropdowns li:hover',

        style: {

            'background-color': 'rgba(0, 0, 0, 0.1)'

        },

    });


    result.push({

        selectors: '.nested-dropdowns li:hover > ul',

        style: {

            'display': 'block'

        },

    });


    result.push({
        selectors: `ul-${uniqueId} li`,
        style: {
            display: 'flex',
            'align-items': 'center',
        },
    });

    result.push({
        selectors: `.example-content`,
        style: {
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            height: '100%',
        },
    });


    // FORM (NEW COMP DEF)
    result.push({
        selectors: `#empty-form`,
        style: {
            width: '100%',
            padding: '0.5em',
            display: 'block',
            'min-height': '20%'
        },
    });




    // login-form-template 1
    result.push({
        selectors: '.login-form-temp1',
        style: {
            width: '50%',
            padding: '0.5em'
        },
    });

    result.push({
        selectors: '.login-form-temp1-container',
        style: {
            'max-width': '100%',
            'background-color': 'white',
            'border-radius': '20px',
            padding: '0.5em 0.5em 3em 0.5em',
            margin: 'auto',
            border: '1px solid black',
            position: 'relative',
        },
    });

    result.push({
        selectors: '.login-form-temp1-header',
        style: {
            'text-align': 'center',
        },
    });

    result.push({
        selectors: '.login-form-temp1-email-input, .login-form-temp1-username-input, .login-form-temp1-password-input',
        style: {
            display: 'block',
            width: '90%',
            'border-radius': '25px',
            boder: 'none',
            padding: '0.5em',
            'background-color': '#d9d6ce',
            'margin-left': 'auto',
            'margin-right': 'auto',
            'margin-top': '5%',
            color: 'black',
        },
    });

    result.push({
        selectors: '.login-form-temp1-submit-button',
        style: {
            display: 'block',
            width: '90%',
            'border-radius': '25px',
            boder: 'none',
            padding: '0.6em',
            'background-color': '#2979FF',
            'margin-left': 'auto',
            'margin-right': 'auto',
            'margin-top': '5%',
            color: 'white',
        },
    });

    result.push({
        selectors: '.social-media-container',
        style: {
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'space-between',
            width: '40%',
            position: 'absolute',
            left: '30%',
            right: 'auto',
            bottom: '-5%',
        },
    });

    result.push({
        selectors: '.form-facebook-icon',
        style: {
            padding: '0.5em',
            'border-radius': '50%',
            'background-color': '#2979FF',
            color: 'white',
        },
    });

    result.push({
        selectors: '.form-google-icon',
        style: {
            padding: '0.5em',
            'border-radius': '50%',
            'background-color': 'red',
            color: 'white',
        },
    });

    result.push({
        selectors: '.form-twitter-icon',
        style: {
            padding: '0.5em',
            'border-radius': '50%',
            'background-color': 'green',
            color: 'white',
        },
    });

    result.push({
        selectors: '.login-form-temp2',
        style: {
            width: '60%',
            padding: '0.5em',
        },
    });

    result.push({
        selectors: '.login-form-temp2-container',
        style: {
            display: 'flex',
            'align-items': 'center',
            'max-width': '100%',
            height: '30em',
            'background-color': 'white',
            'border-radius': '20px',
            margin: 'auto',
            border: '1px solid black',
        },
    });

    result.push({
        selectors: '.login-form-temp2-left-container',
        style: {
            width: '50%',
            height: '100%',
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center',
        },
    });

    result.push({
        selectors: '.login-form-temp2-left-container-image-holder',
        style: {
            padding: '0.5em',
            width: '50%',
            height: '40%',
            'border-radius': '50%',
            'background-color': '#f0eee9',
            display: 'flex',
            'justify-content': 'center',
            'align-items': 'center',
        },
    });

    result.push({
        selectors: '.login-form-temp2-left-container-image-holder-image',
        style: {
            width: '80%',
        },
    });

    result.push({
        selectors: '.login-form-temp2-right-container',
        style: {
            width: '50%',
            height: '100%',

            display: 'flex',
            'flex-direction': 'column',
            'justify-content': 'center',
            'align-items': 'center',
            'row-gap': '2em',
        },
    });

    result.push({
        selectors: '.login-form-temp2-right-container-header',
        style: {
            'text-align': 'center',
        },
    });

    result.push({
        selectors: '.login-form-temp2-right-container-email-input-container, .login-form-temp2-right-container-username-input-container, .login-form-temp2-right-container-password-input-container',
        style: {
            position: 'relative',
            'margin-left': 'auto',
            'margin-right': 'auto',
            width: '90%',
        },
    });

    result.push({
        selectors: '.login-form-temp2-right-container-email-input-icon, .login-form-temp2-right-container-username-input-icon, .login-form-temp2-right-container-password-input-icon',
        style: {
            color: 'gray',
            'font-size': '90%',
            'z-index': '1',

            position: 'absolute',
            top: '35%',
            left: '4%',
        },
    });

    result.push({
        selectors: '.login-form-temp2-right-container-email-input, .login-form-temp2-right-container-username-input, .login-form-temp2-right-container-password-input',
        style: {
            width: '100%',
            'border-radius': '25px',
            boder: 'none',
            padding: '0.5em 0.5em 0.5em 3em',
            'background-color': '#d9d6ce',
            color: 'black',
        },
    });

    result.push({
        selectors: '.login-form-temp2-right-container-login-button',
        style: {
            width: '90%',
            'background-color': 'green',
            'border-radius': '25px',
            padding: '0.8em',
            cursor: 'pointer',
            color: 'white',
        },
    });

    result.push({
        selectors: '.login-form-temp2-right-container-forget-credentials-link, .login-form-temp2-right-container-create-credentials-link',
        style: {
            color: 'green',
            'text-decoration': 'none',
            cursor: 'pointer',
        },
    });

    result.push({
        selectors: '.login-form-temp2-right-container-create-credentials-arrow',
        style: {
            color: 'green',
        },
    });


    // BUSINESS DASHBOARD TEMPLATE
    result.push({
        selectors: '.card',
        style: {
            'margin-bottom': '30px',
            border: 'none',
            '-webkit-transition': 'all 0.3s ease',
            transition: 'all 0.3s ease',
            'letter-spacing': '0.5px',
            'border-radius': '10px',
            '-webkit-box-shadow': '0 0 41px 18px #f9fafb',
            'box-shadow': '0 0 41px 18px #f9fafb',
            padding: '0.5em',
        },
    });

    result.push({
        selectors: '.card .card-header',
        style: {
            'background-color': '#fff',
            padding: '30px',
            'border-bottom': '1px solid #dfe5f5',
            'border-top-left-radius': '10px',
            'border-top-right-radius': '10px',
            position: 'relative',
        },
    });

    result.push({
        selectors: '.card .card-header.card-no-border',
        style: {
            'border-bottom': 'none !important',
        },
    });


    result.push({
        selectors: '.card .card-header h5',
        style: {
            'font-size': '18px',
        },
    });

    result.push({
        selectors: '.card .card-header h5:not(.mb-0)',
        style: {
            'margin-bottom': '0',
            'text-transform': 'capitalize',
            'font-weight': '600',
            'line-height': '1',
        },
    });

    result.push({
        selectors: '.card .card-header>span',
        style: {
            'font-size': '12px',
            'font-family': 'Roboto, sans-serif',
            color: '#8392a5',
            'margin-top': '5px',
            display: 'block',
            'letter-spacing': '1px',
        },
    });

    result.push({
        selectors: '.card .card-body',
        style: {
            padding: '30px',
            'background-color': 'rgba(0, 0, 0, 0)',
        },
    });

    result.push({
        selectors: '.card .card-body p:last-child',
        style: {
            'margin-bottom': '0',
        },
    });

    result.push({
        selectors: '.card .sub-title',
        style: {
            'border-bottom': '1px solid rgba(0, 0, 0, 0.1)',
            'padding-bottom': '5px',
            'margin-bottom': '8px',
            'font-size': '18px',
        },
    });

    result.push({
        selectors: '.card .card-footer',
        style: {
            'background-color': '#fff',
            'border-top': '1px solid #dfe5f5',
            padding: '30px',
            'border-bottom-left-radius': '10px',
            'border-bottom-right-radius': '10px'
        },
    });

    result.push({
        selectors: '.card.card-with-border',
        style: {
            border: '1px solid #dfe5f5',
            '-webkit-box-shadow': 'none',
            'box-shadow': 'none',
        },
    });

    result.push({
        selectors: '.card.card-with-border .card-header',
        style: {
            'border-bottom': '1px solid #dfe5f5',
        },
    });

    result.push({
        selectors: '.card .card-header .card-header-right',
        style: {
            'border-radius': '0 0 0 7px',
            right: '20px',
            top: '22px',
            display: 'inline-block',
            float: 'right',
            padding: '8px 0',
            position: 'absolute',
            'background-color': '#fff',
            'z-index': '1'
        },
    });

    result.push({
        selectors: '.card .card-header .card-header-right .card-option',
        style: {
            'text-align': 'right',
            width: '35px',
            height: '20px',
            overflow: 'hidden',
            '-webkit-transition': '0.3s ease-in-out',
            transition: '0.3s ease-in-out',
        },
    });
    
    result.push({
        selectors: '.card .card-header .card-header-right .card-option li',
        style: {
            display: 'inline-block',
        },
    });
    
    result.push({
        selectors: '.card .card-header .card-header-right .card-option li:first-child i',
        style: {
            'font-size': '18px',
        },
    });
    
    result.push({
        selectors: '.card .card-header .card-header-right i',
        style: {
            margin: '0 5px',
            cursor: 'pointer',
            color: '#2c323f',
            'line-height': '20px',
        },
    });
    
    result.push({
        selectors: '.card .card-header .card-header-right i.icofont-refresh',
        style: {
            'font-size': '13px',
        },
    });
    
    result.push({
        selectors: '.total-sale-widget .media-left',
        style: {
            width: '40%',
        },
    });
    
    result.push({
        selectors: '.total-sale-widget .media-right',
        style: {
            width: 'calc(60% - 20px)',
            overflow: 'hidden',
        },
    });
    
    result.push({
        selectors: '.total-sale-widget .media .media-body p',
        style: {
            color: '#59667a',
            'font-weight': '500',
        },
    });
    
    result.push({
        selectors: '.total-sale-widget .media .media-body p span',
        style: {
            'margin-left': '10px',
        },
    });
    
    result.push({
        selectors: '.total-sale-widget .media .media-body p span i',
        style: {
            'margin-left': '6px',
        },
    });
    
    result.push({
        selectors: '.total-sale-widget .media .media-body .sales-widgets',
        style: {
            '-webkit-box-align': 'center',
            '-ms-flex-align': 'center',
            'align-items': 'center',
        },
    });
    
    result.push({
        selectors: '.total-sale-widget .media .media-body .sales-widgets h3',
        style: {
            display: '-webkit-box',
            display: -'ms-flexbox',
            display: 'flex',
            '-webkit-box-align': 'center',
            '-ms-flex-align': 'center',
            'align-items': 'center',
        },
    });
    
    result.push({
        selectors: '.total-sale-widget .media .media-body .sales-widgets h3 svg',
        style: {
            width: '22px',
            height: '22px',
            'stroke-width': '3',
        },
    });
    
    result.push({
        selectors: '.total-sale-widget .media .media-body .sales-widgets p',
        style: {
            'margin-left': '20px',
        },
    });
    
    result.push({
        selectors: '.txt-primary',
        style: {
            color: '#655af3 !important',
        },
    });
    
    result.push({
        selectors: '.o-hidden',
        style: {
            overflow: 'hidden',
        },
    });
    
    result.push({
        selectors: '.media-widgets .media-body',
        style: {
            'margin-bottom': '30px',
        },
    });
    
    result.push({
        selectors: '.f-w-400',
        style: {
            'font-weight': '400',
        },
    });
    
    result.push({
        selectors: '.f-w-600',
        style: {
            'font-weight': '600',
        },
    });
    
    result.push({
        selectors: '.f-w-700',
        style: {
            'font-weight': '700',
        },
    });
    
    result.push({
        selectors: '.f-w-900',
        style: {
            'font-weight': '900',
        },
    });
    
    result.push({
        selectors: '.f-12',
        style: {
            'font-size': '12px',
        },
    });
    
    result.push({
        selectors: '.font-warning',
        style: {
            color: '#ff5f24 !important',
        },
    });
    
    result.push({
        selectors: '.chart-value-box span',
        style: {
            'font-size': '12px',
            'font-family': 'Roboto, sans-serif',
            color: '#8392a5',
        },
    });
    
    result.push({
        selectors: '.card .card-header h6 .pull-right i',
        style: {
            position: 'absolute',
            top: '4px',
            left: '0',
            'font-size': '10px',
            width: '100%',
            height: '10px',
            'text-align': 'right',
            cursor: 'pointer',
        },
    });
    
    result.push({
        selectors: '.value-square-box-primary',
        style: {
            'background-color': '#655af3',
            width: '8px',
            display: 'inline-block',
            height: '8px',
            'margin-right': '8px',
            'border-radius': '100%',
        },
    });
    
    result.push({
        selectors: '.value-square-box-secondary',
        style: {
            'background-color': '#148df6',
            width: '8px',
            display: 'inline-block',
            height: '8px',
            'margin-right': '8px',
            'border-radius': '100%',
        },
    });
    
    result.push({
        selectors: '.value-square-box-light',
        style: {
            'background-color': '#e8ebf2',
            width: '8px',
            display: 'inline-block',
            height: '8px',
            'margin-right': '8px',
            'border-radius': '100%',
        },
    });
    
    result.push({
        selectors: '.dashboard-rounded-chart svg',
        style: {
            height: '260px !important',
        },
    });
    
    result.push({
        selectors: '.flot-chart-container',
        style: {
            '-webkit-box-sizing': 'border-box',
            'box-sizing': 'border-box',
            width: '100%',
            height: '400px',
        },
    });
    
    result.push({
        selectors: '.dashboard-rounded-chart.flot-chart-container',
        style: {
            height: '245px',
        },
    });
    
    result.push({
        selectors: '.monthly-growth .card-footer .growth-revenue-footer',
        style: {
            margin: '0',
        },
    });
    
    result.push({
        selectors: '.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget',
        style: {
            border: 'none',
        },
    });
    
    result.push({
        selectors: '.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget .progress-showcase',
        style: {
            'margin-top': '20px',
        },
    });
    
    result.push({
        selectors: '.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget .progress-showcase .sm-progress-bar',
        style: {
            height: '3px',
        },
    });
    
    result.push({
        selectors: '.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget .support-ticket-font>div',
        style: {
            '-webkit-box-pack': 'justify',
            '-ms-flex-pack': 'justify',
            'justify-content': 'space-between',
        },
    });
    
    result.push({
        selectors: '.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget ul li:last-child',
        style: {
            'margin-top': '15px',
        },
    });
    
    result.push({
        selectors: '.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget .total-num',
        style: {
            'font-weight': 'normal',
            'margin-top': '5px',
        },
    });
    
    result.push({
        selectors: '.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget .total-num span',
        style: {
            color: '#1b3155',
            'font-weight': '600',
        },
    });
    
    result.push({
        selectors: '.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget svg',
        style: {
            '-webkit-filter': 'none',
            filter: 'none',
            'stroke-width': '3',
            width: '22px',
            height: '22px',
        },
    });
    
    result.push({
        selectors: '.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget span, .monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget h6',
        style: {
            color: '#59667a',
            'font-weight': '500',
        },
    });
    
    result.push({
        selectors: '.monthly-growth .card-footer .growth-revenue-footer .col-6',
        style: {
            padding: '0',
        },
    });
    
    result.push({
        selectors: '.monthly-growth .card-footer .growth-revenue-footer .col-6:nth-child(odd)',
        style: {
            'order-right': '1px solid #e8ebf2',
        },
    });
    
    result.push({
        selectors: '.monthly-growth .card-footer .growth-revenue-footer .col-6',
        style: {
            padding: '0',
        },
    });
    
    result.push({
        selectors: '.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget',
        style: {
            border: 'none',
        },
    });
    
    result.push({
        selectors: '.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget .progress-showcase',
        style: {
            'margin-top': '20px',
        },
    });
    
    result.push({
        selectors: '.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget .progress-showcase .sm-progress-bar',
        style: {
            height: '3px',
        },
    });
    
    result.push({
        selectors: '.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget .support-ticket-font>div',
        style: {
            '-webkit-box-pack': 'justify',
            '-ms-flex-pack': 'justify',
            'justify-content': 'space-between',
        },
    });
    
    result.push({
        selectors: '.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget ul li:last-child',
        style: {
            'margin-top': '15px',
        },
    });
    
    result.push({
        selectors: '.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget .total-num',
        style: {
            'font-weight': 'normal',
            'margin-top': '5px',
        },
    });
    
    result.push({
        selectors: '.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget .total-num span',
        style: {
            color: '#1b3155',
            'font-weight': '600',
        },
    });
    
    result.push({
        selectors: '.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget svg',
        style: {
            '-webkit-filter': 'none',
            filter: 'none',
            'stroke-width': '3',
            width: '22px',
            height: '22px',
        },
    });
    
    result.push({
        selectors: '.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget span,.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget h6',
        style: {
            color: '#59667a',
            'font-weight': '500',
        },
    });
    
    result.push({
        selectors: '.ecommerce-widget',
        style: {
            '-webkit-box-shadow': 'none',
            'box-shadow': 'none',
            border: '1px solid #f1f3ff',
            'border-radius': '5px',
        },
    });
    
    result.push({
        selectors: '.ecommerce-widget .total-num',
        style: {
            'font-family': 'Montserrat, sans-serif',
            'font-weight': '600',
            color: '#1b3155',
            margin: '0',
            'letter-spacing': '1px',
        },
    });
    
    result.push({
        selectors: '.ecommerce-widget .total-num span',
        style: {
            color: '#000',
        },
    });
    
    result.push({
        selectors: '.ecommerce-widget .row',
        style: {
            '-webkit-box-align': 'center',
            '-ms-flex-align': 'center',
            'align-items': 'center',
        },
    });
    
    result.push({
        selectors: '.ecommerce-widget .progress-showcase',
        style: {
            'margin-top': '30px',
        },
    });
    
    result.push({
        selectors: '.ecommerce-widget span',
        style: {
            color: '#8392a5',
            margin: '0',
        },
    });
    
    result.push({
        selectors: '.ecommerce-widget .icon',
        style: {
            color: '#efefef',
            'font-size': '40px',
        },
    });
    
    result.push({
        selectors: '.ecommerce-widget .product-stts',
        style: {
            'font-family': 'Montserrat, sans-serif',
        },
    });
    
    result.push({
        selectors: '.ecommerce-widget .flot-chart-container',
        style: {
            height: '100px',
            padding: '0',
            margin: '0',
            border: 'transparent',
        },
    });
    
    result.push({
        selectors: '.ecommerce-widget .morris-default-style',
        style: {
            display: 'none !important',
        },
    });
    
    result.push({
        selectors: '.ecommerce-widget svg',
        style: {
            '-webkit-filter': 'drop-shadow(12px 12px 7px rgba(0, 0, 0, 0.3))',
            filter: 'drop-shadow(12px 12px 7px rgba(0, 0, 0, 0.3))',
        },
    });
    
    result.push({
        selectors: '.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget .support-ticket-font>div',
        style: {
            '-webkit-box-pack': 'justify',
            '-ms-flex-pack': 'justify',
            'justify-content': 'space-between',
        },
    });
    
    result.push({
        selectors: '.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget .total-num',
        style: {
            'font-weight': 'normal',
            'margin-top': '5px',
        },
    });
    
    result.push({
        selectors: '.monthly-growth .card-footer .growth-revenue-footer .ecommerce-widget .total-num span',
        style: {
            color: '#1b3155',
            'font-weight': '600',
        },
    });
    
    result.push({
        selectors: '.table-bordernone td',
        style: {
            border: '0',
        },
    });
    
    result.push({
        selectors: '.table-bordernone .bd-t-none',
        style: {
            'border-top': 'none',
        },
    });
    
    result.push({
        selectors: '.table-bordernone .u-s-tb',
        style: {
            padding: '11px',
        },
    });
    
    result.push({
        selectors: '.table',
        style: {
            'margin-bottom': '0px',
        },
    });
    
    result.push({
        selectors: '.table th,.table td',
        style: {
            padding: '.75rem',
        },
    });
    
    result.push({
        selectors: '.table th',
        style: {
            color: '#1b3155',
        },
    });
    
    result.push({
        selectors: '.table td',
        style: {
            color: '#1b3155',
        },
    });
    
    result.push({
        selectors: '.users-total table thead tr th',
        style: {
            'font-size': '15px',
            padding: '20px 30px',
            'font-weight': '500',
            'border-top': 'none',
            'border-bottom': 'none',
            'background-color': '#f8faff',
            'font-family': 'Roboto, sans-serif',
        },
    });
    
    result.push({
        selectors: '.users-total table tbody tr td',
        style: {
            'font-weight': '500',
            'vertical-align': 'middle',
            padding: '15px 30px',
            'border-bottom': '1px solid #f2f4ff',
        },
    });
    
    result.push({
        selectors: '.users-total table tbody tr td .badge-secondary',
        style: {
            '-webkit-box-shadow': '-0.5px 4.33px 16px 0px rgba(20, 141, 246, 0.25)',
            'box-shadow': '-0.5px 4.33px 16px 0px rgba(20, 141, 246, 0.25)',
        },
    });
    
    result.push({
        selectors: '.users-total table tbody tr td .d-flex',
        style: {
            '-webkit-box-align': 'center',
            '-ms-flex-align': 'center',
            'align-items': 'center',
        },
    });
    
    result.push({
        selectors: '.users-total table tbody tr td .round-product',
        style: {
            width: '40px',
            height: '40px',
            'border-radius': '100%',
            'background-color': 'rgba(255, 95, 36, 0.08)',
            'text-align': 'center',
            'line-height': '3.5',
        },
    });
    
    result.push({
        selectors: '.users-total table tbody tr td i.icofont',
        style: {
            'font-size:': '22px',
        },
    });
    
    result.push({
        selectors: '.users-total table tbody tr td .round-product-dark',
        style: {
            'background-color': '#f0f1f3',
        },
    });
    
    result.push({
        selectors: '.users-total table tbody tr td:last-child,.users-total table tbody tr td:nth-child(3)',
        style: {
            'font-family': 'Roboto, sans-serif',
            'font-weight': '500',
        },
    });
    
    result.push({
        selectors: '.users-total table tbody tr td .align-middle img',
        style: {
            '-webkit-filter': 'none',
            filter: 'none',
        },
    });
    
    result.push({
        selectors: '.users-total table tbody tr td .align-middle span',
        style: {
            'margin-top': '10px',
            display: 'inline-block',
        },
    });
    
    result.push({
        selectors: '.users-total table tbody tr:last-child td',
        style: {
            'border-bottom': 'none !important',
        },
    });
    
    result.push({
        selectors: '.img-40',
        style: {
            width: '40px !important',
        },
    });
    
    result.push({
        selectors: '.product-sales .table tr td .badge',
        style: {
            'font-weight': '500',
        },
    });
    
    result.push({
        selectors: '.product-sales .table tr td+td',
        style: {
            'font-family': 'Roboto, sans-serif',
        },
    });
    
    result.push({
        selectors: '.txt-success',
        style: {
            color: '#51bb25 !important',
        },
    });
    
    result.push({
        selectors: '.txt-danger',
        style: {
            color: '#fd2e64 !important',
        },
    });


    // ------
    result.push({
        selectors: '.offcanvas-menu-btn',
        style: {
            padding: '0.5em',
            width: 'max-content',
            'border-radius': '10px',
        },
    });

    result.push({
        selectors: 'ion-item',
        style: {
            '--padding-start': '0',
        },
    });


    // QUESTIONNAIRE TEMPLATE
    result.push({
        selectors: '.questionnaire-template-wrapper',
        style: {
            width: '100vw',
            height: '80vh',
            padding: '1em',
        },
    });

    result.push({
        selectors: '.progress',
        style: {
            'margin-top': '1%',
            'margin-bottom': '3%',
        },
    });

    result.push({
        selectors: '.qna-section',
        style: {
            width: '98%',
            padding: '1em',
            'margin-left': 'auto',
            'margin-right': 'auto',
            'margin-bottom': '3%',
        },
    });

    result.push({
        selectors: '.qna-answer-section',
        style: {
            padding: '0.5em',
        },
    });


    // ANIMATIONS....................................
    // result.push({
    //     params: 'pulsate',
    //     selectors: '0%',
    //     atRule: 'keyframes',
    //     style: {
    //         transform: 'scale(1.0)',
    //     },
    // });
    // result.push({
    //     params: 'pulsate',
    //     selectors: '50%',
    //     atRule: 'keyframes',
    //     style: {
    //         'transform-origin': 'center',
    //         transform: 'scale(1.25)',
    //     },
    // });

    // result.push({
    //     params: 'pulsate',
    //     selectors: '100%',
    //     atRule: 'keyframes',
    //     style: {
    //         opacity: '0',
    //     },
    // });

    // // fade-in animation
    // result.push({
    //     params: 'fade-in',
    //     selectors: 'from',
    //     atRule: 'keyframes',
    //     style: {
    //         transform: 'translateY(5%)',
    //     },
    // });

    // result.push({
    //     params: 'fade-in',
    //     selectors: 'to',
    //     atRule: 'keyframes',
    //     style: {
    //         opacity: '1',
    //         transform: 'translateY(0)',
    //     },
    // });

    // // float animation
    // result.push({
    //     params: 'float',
    //     selectors: '0%',
    //     atRule: 'keyframes',
    //     style: {
    //         transform: 'translateY(0)',
    //     },
    // });

    // result.push({
    //     params: 'float',
    //     selectors: '50%',
    //     atRule: 'keyframes',
    //     style: {
    //         transform: 'translateY(2%)',
    //     },
    // });

    // result.push({
    //     params: 'float',
    //     selectors: '100%',
    //     atRule: 'keyframes',
    //     style: {
    //         transform: 'translateY(0)',
    //     },
    // });
}

const result = [];
const lengthOfResult = result.length;

export const parserCss = (css, editor, uniqueId = 1) => {
  // ... parse the CSS string
  // console.log('CSS:', css);
  // console.log("RESULT:", result);
  // console.log("DD ID in geditor:", uniqueId);

  // if the constant result is empty then push all the css, then next time if the ID is dropdown's unique ID, only then push more code for dropdown
  if (result.length === 0) {
    // if (result.length < lengthOfResult) {
    cssResult(editor, uniqueId);
  } else if (uniqueId !== 1) {
    // cssResult(uniqueId);
    // dropdownCss(uniqueId);
  }

  // console.log("result.length (at the end):", result.length);
  // ...
  return result; // Result should be ALWAYS an array
};
