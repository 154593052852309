import React, { useEffect, useRef, useState } from "react";
import ReactDom from "react-dom/client";
import ReactDOM from "react-dom";
import grapesjs from "grapesjs";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
// mockup images:
import redSlingLogo from "../Screens/component_assets/images/redSlingLogo.png";
import laptopFrameVector1 from "../assets/lappyPrev.png";
import laptopFrameVectorNew from "../assets/laptopPreview1440.png";
import phoneFrameVectorNew from "../assets/mobilePreview910.png";
// import redSlingLogo from '../Screens/component_assets/images/details_close.png'
import { Button, Divider, Grid, Modal, Typography } from "@mui/material";
import { API_HOST } from "../api_utils";
import uniqid from "uniqid";
import {
  deviceManager,
  layerManager,
  panels,
  selectorManager,
  storageManager,
  styleManager,
  traitManager,
  parserCss,
} from "../api_utils/geditor_utils";
import $ from "jquery";
import gjsBlockBasic from "grapesjs-blocks-basic";
import grapesjsBlockBootstrap from "grapesjs-blocks-bootstrap4";
// import grapesjsTailwind from "grapesjs-tailwind";

import grapesjsPluginExport from "grapesjs-plugin-export";
import grapesjsStyleBg from "grapesjs-style-bg";
import grapesjsGradient from "grapesjs-style-gradient";
// import grapesjsCkEditor from "grapesjs-plugin-ckeditor";
import grapesjsTuiImageEditor from "grapesjs-tui-image-editor";
import CustomTablePlugin from "../Screens/CustomPlugins/CustomTablePlugin";
import CustomTablePluginNew from "../Screens/CustomPlugins/CustomTablePluginNew";
import CustomEmailPlugin from "../plugins/CustomEmailPlugin";
import CustomEmailTemplatePlugin from "../plugins/CustomEmailTemplatePlugin";
import CustomPDFPlugin from "../plugins/CustomPDFPlugin";
import CustomPopupPlugin from "../plugins/CustomPopupPlugin";
import CustomPopupPlugin_PreviousVersion from "../plugins/CustomPopupPlugin_PreviousVersion";
import CustomExcelPlugin from "../plugins/CustomExcelPlugin";
import CustomCalendarPlugin from "../plugins/CustomCalendarPlugin";
import CustomAccordionPlugin from "../plugins/CustomAccordionPlugin";
import CustomDynamicCarouselPlugin from "../plugins/CustomDynamicCarouselPlugin";
import MobileTabPlugin from "../Screens/CustomPlugins/MobileTabPlugin";
import CustomCardPlugin from "../Screens/CustomPlugins/CustomCardPlugin";
import customCardListPlugin from "../Screens/CustomPlugins/customCardListPlugin";
import customFormElements from "../Screens/CustomPlugins/customFormElements";
import customCardFH from "../Screens/CustomPlugins/customCardFH";
import customCardFH_new from "../Screens/CustomPlugins/HorizontalCarousel";
import plugin from "../Screens/CustomPlugins/grapesjs-blockly";
import { pdfPreviewButton } from "../plugins/PDFPlugin";
import DrillDownBarChart from "../plugins/DataVisualizationPlugin/DrillDownBarChart";

import BusinessDashboardTemplate from "../templates/templatePlugins/BusinessDashboardTemplate";

import PatchupProviderPlugin from "../plugins/PatchupProviderPlugin";

import ThreeDCharts from "../plugins/DataVisualizationPlugin/ThreeDCharts_copy";
import TwoDCharts from "../plugins/DataVisualizationPlugin/TwoDCharts";

import PageInModal from "../PopupContainer/PageInModal";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  BugReportTwoTone,
  ConstructionOutlined,
  EditRoad,
} from "@mui/icons-material";

import {
  Avatar,
  Box,
  FormControlLabel,
  Paper,
  Switch,
  Tooltip,
} from "@mui/material";

import SaveAsIcon from "@mui/icons-material/SaveAs";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreIcon from "@mui/icons-material/Restore";
import UpdateIcon from "@mui/icons-material/Update";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import CottageIcon from "@mui/icons-material/Cottage";
import WidgetsIcon from "@mui/icons-material/Widgets";
import ConstructionIcon from "@mui/icons-material/Construction";
import RedeemIcon from "@mui/icons-material/Redeem";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ThunderstormIcon from "@mui/icons-material/Thunderstorm";
import AddReactionIcon from "@mui/icons-material/AddReaction";
import SmartButtonIcon from "@mui/icons-material/SmartButton";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DnsIcon from "@mui/icons-material/Dns";
import AddchartIcon from "@mui/icons-material/Addchart";
import WindowIcon from "@mui/icons-material/Window";
import Groups2Icon from "@mui/icons-material/Groups2";
import AnchorIcon from "@mui/icons-material/Anchor";
import LayersIcon from "@mui/icons-material/Layers";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import LogoutIcon from "@mui/icons-material/Logout";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import TabletIcon from "@mui/icons-material/Tablet";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import Scrollbar from "react-scrollbars-custom";
import SettingsIcon from "@mui/icons-material/Settings";
import LinearProgress from "@mui/material/LinearProgress";
import ButtonGroup from "@mui/material/ButtonGroup";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PreviewIcon from "@mui/icons-material/Preview";

import { connect } from "react-redux";
import { getAllFunctionsOfProject } from "../Redux/Actions/functionActions";

import {
  createTable,
  createGrid,
  createNavBar,
  createNavBarMob,
  createBasicForm,
  createMobBasicForm,
  appendIcon,
  createCarousel,
  createArrNavCarousel,
  createCaptionCarousel,
  createAccordion,
  createMobBasicAccordion,
  createMobInsetXBasicAccordion,
  createMobMultipleAccordion,
  createDropDown,
  createDirectionalDropDown,
  createFullCard1,
  createFullCard2,
  createTitleCard,
  createMobTitleCard,
  createProjectTable,
  createFormTable,
  createPageDataGrid,
  createProjectDataGrid,
  createPageDataForm,
  createProjectDataForm,
  createButtonCard,
  createListCard,
  createBoundedRippleButton,
  storeConnectionForm,
  createDynamicApiCallResponseTable,
  createMobSelect,
  createMobToggle,
  createSegmentButton,
  createIndividualSegmentButton,
  //createMobTabs,
  createIonicTabs,
  createIonicSearchBar,
  createIonicList,
  createIonicItem,
  createIonicMenu,
  createIonicAction,
  createIonicDateTime,
  createIonicFloatingBtn,
  createIonicModal,
  createIonicPopover,
  createIonicCheckbox,
  createIonicHeader,
  createIonicFooter,
  createIonicContent,
  createIonicToggle,
  createIonicToast,
  createIonicAlert,
  // createIonicRadio,
  createIonicSegment,
  createIonicSelect,
  createBasicButton,
  authenticateUser,
  storeConnectionForm2,
  dynamicApiCall2,
  createFileUploadButton,
  createImageUpload,
} from "../creatorFunctions";

// import CustomEmailPlugin from "../plugins/CustomEmailPlugin";

var calendyClientSecret,
  calendyTokenUrl,
  calendyClientID,
  calendyAuthCode,
  calendyToken,
  calendyRefreshToken,
  calendyInviteURL,
  calendyUserUrl;

function readTextFile(file) {
  var rawFile = new XMLHttpRequest();
  rawFile.open("GET", file, false);
  rawFile.onreadystatechange = function () {
    if (rawFile.readyState === 4) {
      if (rawFile.status === 200 || rawFile.status == 0) {
        var allText = rawFile.responseText;
        // alert(allText);
        // return allText;
      }
    }
  };
  rawFile.send(null);
  console.log("rawfile.responseText:", rawFile.responseText);
  return rawFile.responseText;
}

const notifyTheme = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

const successTheme = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

// toast notification for empty tables
const infoTheme = {
  position: "top-right",
  autoClose: 10000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

var baseUrlData,
  baseUrl1,
  baseUrl2,
  baseUrl3,
  baseUrl4,
  baseUrl5,
  baseUrl1BearerToken,
  baseUrl2BearerToken,
  baseUrl3BearerToken,
  baseUrl4BearerToken,
  baseUrl5BearerToken;

// getting baseUrl data from local storage for run-time access
if (JSON.parse(localStorage.getItem("baseUrlData"))) {
  baseUrlData = JSON.parse(localStorage.getItem("baseUrlData"));
}

const Quill = require("quill");

function Laptop() {
  const { id } = useParams();
  const { userInfo } = useSelector((state) => state.userLogin);
  const [content, setContent] = useState({});
  const { pageByProjectData } = useSelector((state) => state.pageByProject_id);
  const { allProjectData, loading, error, emptyProject } = useSelector(
    (state) => state.allProjectData
  );
  let project_id = localStorage.getItem("project_id");

  if (!project_id) {
    project_id = pageByProjectData.filter((page) => page["_id"] === id)[0]["project"];
  }

  const currentProject = allProjectData?.filter((prj) => prj["_id"] === project_id)[0];
  console.log("currentProject, allProjectData, project_id, pageByProjectData", currentProject, allProjectData, project_id, pageByProjectData);

  // grabbing Base URLs of a project to use in component traits
  if (currentProject != undefined) {
    baseUrl1 = currentProject.baseUrl1;
    baseUrl2 = currentProject.baseUrl2;
    baseUrl3 = currentProject.baseUrl3;
    baseUrl4 = currentProject.baseUrl4;
    baseUrl5 = currentProject.baseUrl5;
  }

  // extracting token values from base URLs from baseUrlData
  if (baseUrlData) {
    try {
      if (`baseUrl1_${project_id}` in baseUrlData) {
        baseUrl1BearerToken = baseUrlData[`baseUrl1_${project_id}`].token;
      }
      if (`baseUrl2_${project_id}` in baseUrlData) {
        baseUrl2BearerToken = baseUrlData[`baseUrl2_${project_id}`].token;
      }
      if (`baseUrl3_${project_id}` in baseUrlData) {
        baseUrl3BearerToken = baseUrlData[`baseUrl3_${project_id}`].token;
      }
      if (`baseUrl4_${project_id}` in baseUrlData) {
        baseUrl4BearerToken = baseUrlData[`baseUrl4_${project_id}`].token;
      }
      if (`baseUrl5_${project_id}` in baseUrlData) {
        baseUrl5BearerToken = baseUrlData[`baseUrl5_${project_id}`].token;
      }
    } catch (e) {
      // statements
      console.log(e);
    }
  }

  console.log(
    "baseUrl1, baseUrl2, baseUrl3, baseUrl4, baseUrl5",
    baseUrl1,
    baseUrl2,
    baseUrl3,
    baseUrl4,
    baseUrl5
  );
  console.log(
    "baseUrl1BearerToken, baseUrl2BearerToken, baseUrl3BearerToken, baseUrl4BearerToken, baseUrl5BearerToken",
    baseUrl1BearerToken,
    baseUrl2BearerToken,
    baseUrl3BearerToken,
    baseUrl4BearerToken,
    baseUrl5BearerToken
  );

  // preview size dimensions
  const previewDims = {
    width: "100%",
    height: "100%",
  };
  const [previewDimsUnits, setPreviewDimsUnits] = useState(previewDims);
  // const [previewWidth, setPreviewWidth] = useState("100%");
  // const [previewHeight, setPreviewHeight] = useState("100%");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [disableScreensBtn, setDisableScreensBtn] = useState(false);

  const editorRef = useRef(null);
  //const functionList = useRef([]);
  //const tempVarList = useRef([]);

  const Chart = require("chart.js");

  const calenderId = uniqid("calender-");
  const calenderInputId = uniqid("calender1-");
  const calenderLabelId = uniqid("calender2-");
  const calenderItemId = uniqid("calender3-");
  const calenderDateId = uniqid("calender4-");

  // to check for the preview dimensions only the first time the preview editor is loaded; then after that the dimensions will get updated from the SCREENS dropdown
  useEffect(() => {
    try {
      let device = JSON.parse(localStorage.device);
      if (device) {
        // console.log('Initial dimensions', device);
        setPreviewDimsUnits((prevState) => ({
          ...prevState,
          width: device.width,
          height: device.height,
          name: device.name,
        }));
      }
    } catch (e) {
      // statements
      console.log(e);
    }
  }, []);

  const resizePreview = function (width, height, name) {
    console.log("width, height", width, height);
    // setPreviewWidth(width);
    // setPreviewHeight(height);

    setPreviewDimsUnits((prevState) => ({
      ...prevState,
      width: width,
      height: height,
      name: name,
    }));
    localStorage.setItem(
      "device",
      JSON.stringify({ width: width, height: height, name: name })
    );

    console.log("previewDimsUnits", previewDimsUnits);
  };

  // const getPageContent = async () => {
  //   const { data } = await axios.get(`${API_HOST}pages/${id}/content`);
  //   console.log("data", data);
  //   if (data.message) {
  //     alert(`${data.message}`);
  //   } else {
  //     console.log("data", data);
  //     setContent(data);
  //   }
  // };

  // useEffect(() => {
  //   getPageContent();
  // }, []);

  grapesjs.plugins.add("customRemote", (editor) => {
    editor.Storage.add("customRemote", {
      async load() {
        console.log("pageId during loading:", id);
        // return await axios.get(`${API_HOST}pages/${pageId}/content`);
        const response = await fetch(`${API_HOST}pages/${id}/content`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userInfo.token}`,
          },
        });
        const data = await response.json();
        console.log("load data ->", data);
        // Process the data if needed

        if (!data.message) {
          return data;
        }

        // else {
        //   const content = {};
        //   content["my-html"] = editor.getHtml();
        //   content["my-components"] = JSON.stringify(editor.getComponents());
        //   content["my-css"] = editor.getCss();
        //   return await axios.post(`${API_HOST}pages/${id}/content`, content);
        // }
      },

      // async store(data) {
      //   console.log("pageId during storing:", id);
      //   console.log("data:", data);

      //   // Create the dynamic content object
      //   const content = {};

      //   // Check if "my-html" exists in the original data
      //   // if (data.hasOwnProperty("my-html")) {
      //   content["my-html"] = editor.getHtml();
      //   // }

      //   // Check if "my-components" exists in the original data
      //   // if (data.hasOwnProperty("my-components")) {
      //   content["my-components"] = JSON.stringify(editor.getComponents());
      //   // }

      //   // Check if "assets" exists in the original data
      //   if (data.hasOwnProperty("assets")) {
      //     content["my-assets"] = JSON.stringify(data["assets"]);
      //   }

      //   // Check if "my-css" exists in the original data
      //   // if (data.hasOwnProperty("my-css")) {
      //   content["my-css"] = editor.getCss();
      //   // }

      //   // Check if "styles" exists in the original data
      //   if (data.hasOwnProperty("styles")) {
      //     content["my-styles"] = JSON.stringify(data["styles"]);
      //   }

      //   console.log("content:", content);

      //   return await axios.post(`${API_HOST}pages/${id}/content`, content);
      // },
    });

    editor.Storage.getStorageOptions(
      "remote"
    ).urlStore = `${API_HOST}pages/${id}/content`;
    editor.Storage.getStorageOptions(
      "remote"
    ).urlLoad = `${API_HOST}pages/${id}/content`;

    console.log(
      'editor.Storage.getStorageOptions("remote"):',
      editor.Storage.getStorageOptions("remote")
    );
  });

  useEffect(() => {
    try {
      console.log("content", content);
      setDisableScreensBtn(true);

      const editor = grapesjs.init({
        container: editorRef.current,

        width: previewDimsUnits.width,

        height: previewDimsUnits.height,

        overflowY: "scroll",

        showToolbar: 0,

        defaultCommand: "",

        multipleSelection: 0,

        wrapperIsBody: 0,

        allowScripts: 1,

        // modal: {
        //   title: "Initialize",
        //   content: "",
        //   backdrop: false,
        // },

        assetManager: {
          // Upload endpoint, set `false` to disable upload, default `false`
          upload: false,
          openAssetsOnDrop: false,

          uploadFile: function (e) {
            console.log(e);
            var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
            // ...send somewhere
            console.log(files);

            var formData = new FormData();

            for (var i in files) {
              console.log("iiii", i);
              formData.append("file", files[i]);
            }

            $.ajax({
              url: `${API_HOST}assets/uploadphoto/${id}`,
              type: "POST",
              data: formData,
              contentType: false,
              crossDomain: true,
              mimeType: "multipart/form-data",
              processData: false,
              success: function (result) {
                console.log(result);
                if (result === "Please upload an Image less than 5 mb") {
                  return;
                } else {
                  var images = result;
                  editor.AssetManager.add({
                    src: images,
                    name: "Uploaded Image",
                  });
                  //
                  // made a change
                  e.target.value = `${images}`;
                }
              },
            });
          },

          // The name used in POST to pass uploaded files, default: `'files'`
          uploadName: "file",
          // Text on upload input
          uploadText: "Drop files here or click to upload",
          // Label for the add button
          addBtnText: "Add image",
          assets: [
            "http://placehold.it/350x250/78c5d6/fff/image1.jpg",
            // Pass an object with your properties
            {
              type: "image",
              src: "http://placehold.it/350x250/459ba8/fff/image2.jpg",
              height: 350,
              width: 250,
              name: "displayName",
            },
            {
              // As the 'image' is the base type of assets, omitting it will
              // be set as `image` by default
              src: "http://placehold.it/350x250/79c267/fff/image3.jpg",
              height: 350,
              width: 250,
              name: "displayName",
            },
          ],
          multiUpload: true,
        },

        storageManager: {
          type: "customRemote",
          autosave: false,
          autoload: true,
          // stepsBeforeSave: 1,
          contentTypeJson: true,
          storeComponents: false,
          storeStyles: false,
          storeHtml: false,
          storeCss: false,
          id: "my-",
          // urlStore: `${API_HOST}pages/${pageId}/content`,
          // urlLoad: `${API_HOST}pages/${pageId}/content`,
          // headers: {
          //     'Content-Type': 'application/json'
          // },
        },

        parser: {
          parserCss,
        },

        styleManager: styleManager,

        layerManager: layerManager,

        traitManager: traitManager,

        selectorManager: selectorManager,

        panels: panels,

        deviceManager: deviceManager,

        plugins: [
          "customRemote",
          gjsBlockBasic,
          // tailwindComponent,
          grapesjsBlockBootstrap,
          // grapesjsPluginExport,
          // grapesjsStyleBg,
          grapesjsGradient,
          // grapesjsCkEditor,
          // grapesjsTuiImageEditor,
          CustomTablePlugin,
          CustomTablePluginNew,
          CustomEmailPlugin,
          CustomEmailTemplatePlugin,
          CustomPDFPlugin,
          CustomPopupPlugin,
          CustomPopupPlugin_PreviousVersion,
          CustomExcelPlugin,
          CustomCalendarPlugin,
          CustomAccordionPlugin,
          CustomDynamicCarouselPlugin,
          // grapesjsTailwind,
          MobileTabPlugin,
          // 'grapesjs-blockly'
          plugin,
          BusinessDashboardTemplate,
          // QuestionnaireTemplate,
          PatchupProviderPlugin,
          CustomCardPlugin,
          customCardListPlugin,
          customFormElements,
          customCardFH,
          customCardFH_new,
          pdfPreviewButton,
          DrillDownBarChart,
          ThreeDCharts,
          TwoDCharts,
        ],

        pluginsOpts: {
          [gjsBlockBasic]: {
            labelImage: "Add Image",
            labelVideo: "Add Video",
            showBasic: false,
            labelColumn1: "Column 1",
            labelColumn2: "Column 2",
            labelColumn37: "Column 1:3",
            labelText: "Add Text",
            labelLink: "Add Link",
            labelMap: "Add Maps",
            labelColumn3: "Column 3",
          },
          // tailwindComponent: {},
          // plugin: [plugin],
          swiperComponent: {},
          [grapesjsBlockBootstrap]: {
            blockCategories: {
              forms: false,
              layout: false,
            },

            blocks: {
              alert: false,
              card: false,
              card_container: true,
              collapse: false,
              tabs: false,
              dropdown: false,
            },

            labels: {
              image: "Group Image Component",
              video: "Group Video Component",
            },
          },
          // [grapesjsPluginExport]: {
          //   root: {
          //     css: {
          //       "main.css": (editor) => editor.getCss(),
          //     },

          //     js: {
          //       "main.js": (editor) => editor.getJs(),
          //     },
          //     bootstrap: {
          //       "bootstrap.min.css": (editor) =>
          //         readTextFile("/bootstrap/css/bootstrap.min.css"),
          //       "bootstrap.bundle.min.js": (editor) =>
          //         readTextFile("/bootstrap/js/bootstrap.bundle.min.js"),
          //       "jquery-3.5.1.slim.min.js": (editor) =>
          //         readTextFile("/bootstrap/jquery/jquery-3.5.1.slim.min.js"),
          //     },
          //     swiper: {
          //       "swiper-bundle.min.css": (editor) =>
          //         readTextFile("/swiper/css/swiper-bundle.min.css"),
          //       "swiper-bundle.min.js": (editor) =>
          //         readTextFile("/swiper/js/swiper-bundle.min.js"),
          //     },
          //     "index.html": (editor: Editor) =>
          //       `<!doctype html>
          //                   <html lang="en">
          //                     <head>
          //                       <meta charset="utf-8">
          //                       <meta name="viewport" content="width=device-width, initial-scale=1.0">

          //                       <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css" integrity="sha512-MV7K8+y+gLIBoVD59lQIYicR65iaqukzvf/nwasF0nqhPay5w/9lJmVM2hMDcnK1OnMGCdVK+iQrJ7lzPJQd1w==" crossorigin="anonymous" referrerpolicy="no-referrer" />
          //                       <script src="./bootstrap/jquery-3.5.1.slim.min.js"></script>
          //                       <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous">
          //                       <link rel="stylesheet" href="./bootstrap/bootstrap.min.css">
          //                       <script src="./bootstrap/bootstrap.bundle.min.js"></script>
          //                       <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.min.js" integrity="sha384-cuYeSxntonz0PPNlHhBs68uyIAVpIIOZZ5JqeqvYYIcEL727kskC66kF92t6Xl2V" crossorigin="anonymous"></script>
          //                       <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.6/dist/umd/popper.min.js" integrity="sha384-oBqDVmMz9ATKxIep9tiCxS/Z9fNfEXiDAYTujMAeBAsjFuCZSmKbSSUnQlmh/jp3" crossorigin="anonymous"></script>
          //                       <link rel="stylesheet" href="./swiper/swiper-bundle.min.css">
          //                       <script src="./swiper/swiper-bundle.min.js"></script>
          //                       <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@ionic/core/css/ionic.bundle.css" />
          //                       <script nomodule src="https://cdn.jsdelivr.net/npm/@ionic/core/dist/ionic/ionic.js"></script>
          //                       <script type="module" src="https://cdn.jsdelivr.net/npm/@ionic/core/dist/ionic/ionic.esm.js"></script>
          //                       <link rel="stylesheet" href="https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css">
          //                       <script src="https://cdnjs.cloudflare.com/ajax/libs/Chart.js/4.2.1/chart.min.js" integrity="sha512-v3ygConQmvH0QehvQa6gSvTE2VdBZ6wkLOlmK7Mcy2mZ0ZF9saNbbk19QeaoTHdWIEiTlWmrwAL4hS8ElnGFbA==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>

          //                       <!-- <link rel="stylesheet" href="path/to/grapick.min.css"> -->
          //                       <!-- <script src="path/to/grapick.min.js"></script> -->

          //                       <link rel="stylesheet" href="./css/main.css">
          //                       <script type="text/javascript" src="./js/main.js" defer></script>
          //                     </head>
          //                     <body>
          //                           ${editor.getHtml()}
          //                     </body>
          //                   </html>`,
          //   },
          //   filenamePfx: `redSling_bundle_${pageId}`,
          // },

          // grapesjsStyleBg: {},
          // [grapesjsCkEditor]: {
          //   position: {
          //     top: "50%", // Adjust the vertical position as a percentage
          //     left: "50%", // Adjust the horizontal position as a percentage
          //   },
          //   options: {
          //     language: "en",
          //     toolbarGroups: [
          //       { name: "document", groups: ["mode", "document", "doctools"] },
          //       { name: "clipboard", groups: ["clipboard", "undo"] },
          //       {
          //         name: "editing",
          //         groups: ["find", "selection", "spellchecker", "editing"],
          //       },
          //       { name: "forms", groups: ["forms"] },
          //       "/",
          //       { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
          //       {
          //         name: "paragraph",
          //         groups: [
          //           "list",
          //           "indent",
          //           "blocks",
          //           "align",
          //           "bidi",
          //           "paragraph",
          //         ],
          //       },
          //       { name: "links", groups: ["links"] },
          //       { name: "insert", groups: ["insert"] },
          //       "/",
          //       { name: "styles", groups: ["styles"] },
          //       { name: "colors", groups: ["colors"] },
          //       { name: "tools", groups: ["tools"] },
          //       { name: "others", groups: ["others"] },
          //       { name: "about", groups: ["about"] },
          //     ],
          //     removeButtons: "NewPage",
          //   },
          // },
          // [grapesjsTuiImageEditor]: {
          //   config: {
          //     includeUI: {
          //       initMenu: "filter",
          //     },
          //   },
          //   width: "80vw !important",
          //   script: [
          //     "https://uicdn.toast.com/tui.code-snippet/v1.5.2/tui-code-snippet.min.js",
          //     "https://uicdn.toast.com/tui-color-picker/v2.2.7/tui-color-picker.min.js",
          //     "https://uicdn.toast.com/tui-image-editor/v3.15.2/tui-image-editor.min.js",
          //   ],
          //   style: [
          //     "https://uicdn.toast.com/tui-color-picker/v2.2.7/tui-color-picker.min.css",
          //     "https://uicdn.toast.com/tui-image-editor/v3.15.2/tui-image-editor.min.css",
          //   ],
          // },
          // [plugin]: {
          //   toolbox: {
          //     // Add your custom blocks here
          //   },

          //   // The workspace specifies the size and position of the Blockly editor.
          //   workspace: {
          //     width: 600,
          //     height: 400,
          //     position: 'bottom-right',
          //   },

          //   // The theme specifies the theme that will be used for the Blockly editor.
          //   theme: 'classic',
          // },
          // The theme specifies the theme that will be used for the Blockly editor.
          theme: "classic",
          // },

          BusinessDashboardTemplate: {},
          // QuestionnaireTemplate: {},
          PatchupProviderPlugin: {},
          PatchupProviderPlugin: {},
          DrillDownBarChart: {},
          [customFormElements]: {
            baseUrl1,
            baseUrl2,
            baseUrl3,
            baseUrl4,
            baseUrl5,
            baseUrl1BearerToken,
            baseUrl2BearerToken,
            baseUrl3BearerToken,
            baseUrl4BearerToken,
            baseUrl5BearerToken,
          },
          
          [ThreeDCharts]: {
            baseUrl1,
            baseUrl2,
            baseUrl3,
            baseUrl4,
            baseUrl5,
            baseUrl1BearerToken,
            baseUrl2BearerToken,
            baseUrl3BearerToken,
            baseUrl4BearerToken,
            baseUrl5BearerToken,
          },

          [TwoDCharts]: {
            baseUrl1,
            baseUrl2,
            baseUrl3,
            baseUrl4,
            baseUrl5,
            baseUrl1BearerToken,
            baseUrl2BearerToken,
            baseUrl3BearerToken,
            baseUrl4BearerToken,
            baseUrl5BearerToken,
          },
          [CustomTablePlugin]: {
            baseUrl1,
            baseUrl2,
            baseUrl3,
            baseUrl4,
            baseUrl5,
            baseUrl1BearerToken,
            baseUrl2BearerToken,
            baseUrl3BearerToken,
            baseUrl4BearerToken,
            baseUrl5BearerToken,
          },

          [CustomTablePluginNew]: {
            project_id,
            baseUrl1,
            baseUrl2,
            baseUrl3,
            baseUrl4,
            baseUrl5,
            baseUrl1BearerToken,
            baseUrl2BearerToken,
            baseUrl3BearerToken,
            baseUrl4BearerToken,
            baseUrl5BearerToken,
          },

          [CustomEmailPlugin]: {
            baseUrl1,
            baseUrl2,
            baseUrl3,
            baseUrl4,
            baseUrl5,
            baseUrl1BearerToken,
            baseUrl2BearerToken,
            baseUrl3BearerToken,
            baseUrl4BearerToken,
            baseUrl5BearerToken,
          },

          [CustomEmailTemplatePlugin]: {
            baseUrl1,
            baseUrl2,
            baseUrl3,
            baseUrl4,
            baseUrl5,
            baseUrl1BearerToken,
            baseUrl2BearerToken,
            baseUrl3BearerToken,
            baseUrl4BearerToken,
            baseUrl5BearerToken,
            // allFragmentData: allFragmentData.current,
          },

          [CustomPDFPlugin]: {
            baseUrl1,
            baseUrl2,
            baseUrl3,
            baseUrl4,
            baseUrl5,
            baseUrl1BearerToken,
            baseUrl2BearerToken,
            baseUrl3BearerToken,
            baseUrl4BearerToken,
            baseUrl5BearerToken,
            // allFragmentData: allFragmentData.current,
          },

          [CustomPopupPlugin]: {
            baseUrl1,
            baseUrl2,
            baseUrl3,
            baseUrl4,
            baseUrl5,
            baseUrl1BearerToken,
            baseUrl2BearerToken,
            baseUrl3BearerToken,
            baseUrl4BearerToken,
            baseUrl5BearerToken,
          },

          [CustomPopupPlugin_PreviousVersion]: {
            baseUrl1,
            baseUrl2,
            baseUrl3,
            baseUrl4,
            baseUrl5,
            baseUrl1BearerToken,
            baseUrl2BearerToken,
            baseUrl3BearerToken,
            baseUrl4BearerToken,
            baseUrl5BearerToken,
          },

          [customCardFH_new]: {
            baseUrl1,
            baseUrl2,
            baseUrl3,
            baseUrl4,
            baseUrl5,
            baseUrl1BearerToken,
            baseUrl2BearerToken,
            baseUrl3BearerToken,
            baseUrl4BearerToken,
            baseUrl5BearerToken,
          },

          [CustomExcelPlugin]: {
            baseUrl1,
            baseUrl2,
            baseUrl3,
            baseUrl4,
            baseUrl5,
            baseUrl1BearerToken,
            baseUrl2BearerToken,
            baseUrl3BearerToken,
            baseUrl4BearerToken,
            baseUrl5BearerToken,
          },

          [CustomCalendarPlugin]: {
            baseUrl1,
            baseUrl2,
            baseUrl3,
            baseUrl4,
            baseUrl5,
            baseUrl1BearerToken,
            baseUrl2BearerToken,
            baseUrl3BearerToken,
            baseUrl4BearerToken,
            baseUrl5BearerToken,
          },

          CustomAccordionPlugin: {},
          [CustomDynamicCarouselPlugin]: {
            baseUrl1,
            baseUrl2,
            baseUrl3,
            baseUrl4,
            baseUrl5,
            baseUrl1BearerToken,
            baseUrl2BearerToken,
            baseUrl3BearerToken,
            baseUrl4BearerToken,
            baseUrl5BearerToken,
          },
        },

        canvas: {
          styles: [
            "https://unpkg.com/swiper@8/swiper-bundle.min.css",
            "https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css",
            "https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css",
            "https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.2/font/bootstrap-icons.css",
            "https://cdn.jsdelivr.net/npm/@ionic/core/css/ionic.bundle.css",
            "/styles/main.scss",

            "https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap",
            "https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap",
            "https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap",
            "https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap",
            "https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300&display=swap",
            "https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300&display=swap",
            "https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap",
            "https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300&display=swap",
            "https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap",
            "https://fonts.googleapis.com/css2?family=Bacasime+Antique&display=swap",
            "https://fonts.googleapis.com/css2?family=League+Spartan:wght@300&display=swap",
            "https://fonts.googleapis.com/css2?family=Manrope:wght@300&display=swap",
            "https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap",
            "https://fonts.googleapis.com/css2?family=Marcellus&display=swap",
            "https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap",

            "https://cdn.jsdelivr.net/npm/gridjs/dist/theme/mermaid.min.css",

            "https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css",

            "https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700,700i&amp;display=swap",
            "https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700&amp;display=swap",
            "/assets/css/datatables.css",
            "/assets/css/datatable-extension.css",
            "/assets/css/bootstrap.css",
            "/assets/css/bootstrap.css.map",

            "/assets/css/fontawesome.css",
            "/assets/css/fontawesome.css.map",
            "/assets/css/ionic-icon.css",
            "/assets/css/ionic-icon.css.map",
            "/assets/css/themify.css",
            "/assets/css/themify.css.map",
            "/assets/css/icofont.css",
            "/assets/css/icofont.css.map",
            "/assets/css/simple-line-icon.css",
            "/assets/css/simple-line-icon.css.map",
            "/assets/css/material-design-icon.css",
            "/assets/css/material-design-icon.css.map",
            "/assets/css/pe7-icon.css",
            "/assets/css/pe7-icon.css.map",
            "/assets/css/typicon-icon.css",
            "/assets/css/typicon-icon.css.map",

            // NOTE --> style.css and style.css.map causes issues/conflict with redSling builder's typography functionality
            // "/assets/css/style.css",
            // "/assets/css/style.css.map",
            "/assets/css/chartist.css",
            "/assets/css/prism.css",
            "/assets/css/color-1.css",
            "/assets/css/responsive.css",

            "/korebotComponents/sdk/UI/libs/jquery-ui.min.css",
            "/korebotComponents/sdk/UI/chatWindow.css",
            "/korebotComponents/sdk/libs/emojione.sprites.css",
            "/korebotComponents/sdk/libs/purejscarousel.css",
            "/korebotComponents/sdk/UI/custom/customTemplate.css",

            // for pagination - uikit framework
            "https://cdnjs.cloudflare.com/ajax/libs/uikit/2.27.5/css/uikit.min.css",
            "https://cdnjs.cloudflare.com/ajax/libs/uikit/2.27.5/css/uikit.almost-flat.min.css",
            "https://cdnjs.cloudflare.com/ajax/libs/uikit/2.27.5/css/uikit.gradient.min.css",

            //  quill.js for rte
            "https://cdn.quilljs.com/1.3.6/quill.snow.css",

            // tui calendar
            "https://uicdn.toast.com/tui.date-picker/latest/tui-date-picker.css",
            "https://uicdn.toast.com/tui.time-picker/latest/tui-time-picker.css",
            "https://uicdn.toast.com/calendar/latest/toastui-calendar.min.css",

            // select-dropdown with search (select picker), (NOTE - only works with bootstrap-4),
            "https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css",
            "https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.18/dist/css/bootstrap-select.min.css",

            // custom css animations
            "/animations.css",
          ],
          scripts: [
            "https://cdn.jsdelivr.net/npm/@popperjs/core@2.10.2/dist/umd/popper.min.js",
            "https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.bundle.min.js",
            "https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.min.js",
            "https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js",
            "https://code.jquery.com/jquery-3.6.0.min.js",
            "https://code.jquery.com/jquery-3.7.1.min.js",
            "https://cdn.jsdelivr.net/npm/@ionic/core/dist/ionic/ionic.js",
            "https://cdn.jsdelivr.net/npm/@ionic/core/dist/ionic/ionic.esm.js",
            "https://unpkg.com/swiper@8/swiper-bundle.min.js",
            "https://www.jsdelivr.com/package/npm/chart.js?path=dist",
            // "https://unpkg.com/blockly",
            // "https://unpkg.com/@blockly/block-plus-minus",

            // "plotly-2.20.0.min.js",
            "http://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit",
            "https://cdn.jsdelivr.net/npm/gridjs/dist/gridjs.umd.js",

            "https://smtpjs.com/v3/smtp.js",

            // "/bootstrap/js/bootstrap.bundle.min.js",
            // "/assets/js/jquery-3.5.1.min.js",
            "/assets/js/datatable/datatables/jquery.dataTables.min.js",
            // "/assets/js/bootstrap/popper.min.js",
            // "/assets/js/bootstrap/bootstrap.js",

            "/korebotComponents/sdk/UI/libs/jquery.js",
            "/korebotComponents/sdk/UI/libs/jquery.tmpl.min.js",
            "/korebotComponents/sdk/UI/libs/jquery-ui.min.js",
            "/korebotComponents/sdk/UI/libs/moment.js",
            "/korebotComponents/sdk/libs/lodash.min.js",
            "/korebotComponents/sdk/libs/d3.v4.min.js",
            "/korebotComponents/sdk/libs/KoreGraphAdapter.js",
            "/korebotComponents/sdk/libs/anonymousassertion.js",
            "/korebotComponents/sdk/kore-bot-sdk-client.js",
            "/korebotComponents/sdk/UI/chatWindow.js",
            "/korebotComponents/sdk/libs/emoji.js",
            "/korebotComponents/sdk/libs/recorder.js",
            "/korebotComponents/sdk/libs/recorderWorker.js",
            "/korebotComponents/sdk/libs/purejscarousel.js",
            "/korebotComponents/sdk/UI/custom/customTemplate.js",
            "/korebotComponents/sdk/libs/perfect-scrollbar.js",
            //"/korebotComponents/sdk/UI/kore-config.js",
            "/korebotComponents/sdk/UI/kore-main.js",
            "../src/api_utils/index.js",

            "/assets/js/icons/feather-icon/feather.min.js",
            "/assets/js/icons/feather-icon/feather-icon.js",
            "/assets/js/sidebar-menu.js",
            "/assets/js/config.js",
            "/assets/js/chart/chartist/chartist.js",
            "/assets/js/chart/chartist/chartist-plugin-tooltip.js",
            "/assets/js/chart/apex-chart/apex-chart.js",
            "/assets/js/chart/apex-chart/stock-prices.js",
            "/assets/js/prism/prism.min.js",
            "/assets/js/clipboard/clipboard.min.js",
            "/assets/js/counter/jquery.waypoints.min.js",
            "/assets/js/counter/jquery.counterup.min.js",
            "/assets/js/counter/counter-custom.js",
            "/assets/js/custom-card/custom-card.js",

            // .load inner data bug solution
            // "/assets/js/notify/bootstrap-notify.min.js",

            "/assets/js/dashboard/default.js",
            "/assets/js/notify/index.js",
            "/assets/js/tooltip-init.js",
            "/assets/js/script.js",

            "https://unpkg.com/vis-graph3d@latest/dist/vis-graph3d.min.js",
            "https://cdn.jsdelivr.net/npm/chart.js",

            // .load inner data bug solution
            // "/assets/js/theme-customizer/customizer.js",

            // for pagination - uikit framework
            "https://cdnjs.cloudflare.com/ajax/libs/uikit/2.27.5/js/uikit.min.js",
            "https://cdnjs.cloudflare.com/ajax/libs/uikit/2.27.5/js/components/pagination.min.js",

            // quill.js for rte
            "https://cdn.quilljs.com/1.3.6/quill.js",

            // excel file downloading
            "https://unpkg.com/xlsx/dist/xlsx.full.min.js",

            // tui calendar
            "https://uicdn.toast.com/tui.code-snippet/latest/tui-code-snippet.min.js",
            "https://uicdn.toast.com/tui.dom/latest/tui-dom.min.js",
            "https://uicdn.toast.com/tui.time-picker/latest/tui-time-picker.min.js",
            "https://uicdn.toast.com/tui.date-picker/latest/tui-date-picker.min.js",
            "https://uicdn.toast.com/calendar/latest/toastui-calendar.min.js",

            // select-dropdown with search (select picker), (NOTE - only works with bootstrap-4),
            "https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.bundle.min.js",
            "https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.18/dist/js/bootstrap-select.min.js",

            // md5 hashing
            "https://cdnjs.cloudflare.com/ajax/libs/blueimp-md5/2.18.0/js/md5.min.js",
          ],
        },
      });
      editor.setStyle(`.cv-canvas {display:block; }`);

      // editor.setComponents(content);

      editor.on("load", () => {
        setDisableScreensBtn(false);
        editor.stopCommand("sw-visibility");
        // const components = editor.DomComponents.getComponents();
        // components.forEach((component) => {
        //   const el = component.view.el;
        //   el.style.pointerEvents='none';
        //   el.style.resize='none'
        // });

        const all = getAllComponents(editor.DomComponents.getWrapper());

        console.log("all", all);

        // to block interactivity from the user on components in preview
        all.forEach((comp) => {
          console.log("childComp", comp);
          comp.attributes.editable = false;
          comp.attributes.locked = false;

          // comp.setAttributes({
          //   locked: false,
          //   editable: false,
          //   hoverable: false,
          //   selectable: false,
          //   highlightable: false,
          //   draggable: false,
          //   removable: false,
          //   stylable: false,
          // });
        });
      });

      editor.on("component:mount", async (component) => {
        console.log("comp attributes1::::::", component);
        if (component && component.attributes.type === "template-fragment") {
          console.log("comp attributes2::::::", component);
          console.log("comp attributes3::::::", component);

          if (component.attributes.attributes.template_id) {
            let fragmentId = component.attributes.attributes.template_id;
            const config = {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
              },
            };
            const { data } = await axios.get(
              `${API_HOST}template_fragment/${fragmentId}`,
              config
            );

            if (!data.message) {
              component.components().reset();

              const components = JSON.parse(data["my-components"]);

              const styles = data["my-css"];

              function makeChildNonEditable(comp) {
                // Set properties for the current component
                console.log("comp::::::", comp);
                comp.set({
                  droppable: false,
                  editable: false,
                  resizable: false,
                });

                // Recursively traverse child components of the current component
                const childComps = comp.components();
                console.log("childComps::::::", childComps);
                childComps.forEach((childComp) => {
                  makeChildNonEditable(childComp);
                });
              }

              components.forEach((componentChild, index) => {
                console.log(
                  "componentChild fragment123::::::::::",
                  componentChild
                );

                // const componentNew = editor.addComponents(component);
                const componentNew =
                  editor.DomComponents.getWrapper().append(componentChild);

                componentNew[0].set({
                  droppable: false,
                  resizable: false,
                  editable: false,
                });
                console.log("componentNew componentChild:::::::", componentNew);

                component.components(componentNew[0]);

                makeChildNonEditable(componentNew[0]);
              });

              const styleComponent = editor.DomComponents.addComponent({
                tagName: "style",
                content: styles,
              });

              component.append(styleComponent);
            }
          }
        }

        if (component && component.attributes.type === "autocomplete_custom") {
          let comp = editor.DomComponents.getType("autocomplete_custom");
          if (comp) {
            const optionDiv = component.find("div.autocomplete_custom_div")[0];
            optionDiv.components("");
          }
        }
      });

      const defaultType = editor.DomComponents.getType("default");
      const defaultModel = defaultType.model;
      const defaultView = defaultType.view;

      // ALL COMPONENT TYPES
      // define image component definition to solve the image error
      editor.DomComponents.addType("image", {
        isComponent: (el) => el.tagName === "div" && el.type === "image",

        model: {
          defaults: {
            tagName: "div",
            type: "image",
          },
        },

        view: {
          onRender({ el, model }) {
            // el.tagName = 'image';
            model.attributes.tagName = "img";
            // model.attributes.attributes.hoverable = false;
            console.log("el >>", el, model);
          },
        },
      });

      // define video component definition to solve the video error
      editor.DomComponents.addType("video", {
        isComponent: (el) => el.tagName === "div" && el.type === "video",

        model: {
          defaults: {
            tagName: "div",
            type: "image",
          },
        },

        view: {
          onRender({ el, model }) {
            // el.tagName = 'image';
            model.attributes.tagName = "video";
            // model.attributes.attributes.hoverable = false;
            console.log("el >>", el, model);
          },
        },
      });

      editor.DomComponents.addType("empty-form-container", {
        // console.log("HEREEEE 22")
        model: {
          defaults: {
            tagName: "form",
            attributes: { id: "empty-form-container" },
            styles: `
                          #empty-form-container {
                              width: 100%;
                              height: 50%;
                              padding: 1em;
                          }
                          `,
            Functions: "",
            traits: [
              "name",
              // {
              //   type: "select",
              //   name: "Functions",
              //   label: "Functions",
              //   changeProp: true,
              //   options:
              //     functionList && functionList.current !== null
              //       ? [
              //           ...functionList.current.map((x) => ({
              //             value: x._id,
              //             name: x.name,
              //           })),
              //           { value: "none", name: "None" },
              //         ]
              //       : [{ value: "none", name: "None" }],
              // },
            ],
            //"script-props": ["Functions"],
          },
        },

        view: {
          // init() {
          //   this.listenTo(
          //     this.model,
          //     "change:Functions",
          //     this.handleFunctionChange
          //   );
          //   if (functionList.current !== null) {
          //     console.log("REDNER NOT NULL");
          //     this.on("render", this.handleFunctionChange);
          //     this.listenTo(this.model, "updated", this.handleFunctionChange);
          //   }
          // },
          // // onRender() {
          // //     this.handleFunctionChange();
          // // },
          // handleFunctionChange(e) {
          //   // e.preventDefault();
          //   var selectedFunction = this.model.get("Functions");
          //   const components = this.model.components();
          //   let allSelectedVariables = [];
          //   let allSelectedVariablesValue = [];
          //   let functionToBeExecuted = functionList.current.find(
          //     (a) => a._id === selectedFunction
          //   );
          //   console.log("components FORM", components);
          //   console.log("IN HANDLE CHANGE ");
          //   if (functionToBeExecuted) {
          //     components.forEach((component) => {
          //       console.log("inside COMP", component);
          //       if (
          //         component.attributes.attributes.id !== "submit-regular" &&
          //         component.attributes.TempVar &&
          //         component.attributes.TempVar !== "none"
          //       ) {
          //         let findTempVarName = tempVarList.current.find(
          //           (a) => a._id === component.attributes.TempVar
          //         );
          //         let userInputValue = component.view.$el.find("input").val();
          //         if (findTempVarName && userInputValue) {
          //           let renderedName = {
          //             name: findTempVarName.name,
          //             value: userInputValue,
          //           };
          //           return allSelectedVariablesValue.push(renderedName.value);
          //         }
          //       }
          //       if (component.attributes.attributes.id === "submit-regular") {
          //         component.view.$el.on("click", function () {
          //           console.log("allSelectedVariables", allSelectedVariables);
          //           console.log("functionToBeExecuted", functionToBeExecuted);
          //           // executeFunction(functionToBeExecuted, tempVarList.current, userInfo, allSelectedVariablesValue)
          //         });
          //       }
          //     });
          //   }
          // },
        },
      });

      editor.DomComponents.addType("login_universal", {
        model: {
          defaults: {
            tagName: "div",
            type: "default",
            style: {
              display: "flex",
              "justify-content": "start",
              "align-items": "center",
              width: "100%",
            },
            components: [
              {
                type: "image",
                attributes: {
                  src: "https://images.unsplash.com/photo-1510074377623-8cf13fb86c08?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1172&q=80",
                },
                style: {
                  height: "500px",
                  width: "50%",
                },
              },
              {
                type: "complete_form_wrapper",
                attributes: {
                  class: "login_container",
                },
                style: {
                  width: "50%",
                  "padding-left": "2rem",
                  "padding-right": "2rem",
                  display: "block",
                  // "align-items": "center",
                },
                components: [
                  {
                    type: "form_title",
                  },
                  {
                    type: "form_email_field",
                    style: {
                      "margin-top": "3rem",
                      display: "flex",
                      "align-items": "center",
                    },
                  },
                  {
                    type: "form_password_field",
                    style: {
                      "margin-top": "1rem",
                      display: "flex",
                      "align-items": "center",
                    },
                  },
                  {
                    type: "form_submit_field",
                    style: {
                      "margin-top": "3rem",
                    },
                  },
                  {
                    type: "default",
                    tagName: "div",
                    style: {
                      display: "flex",
                      "justify-content": "center",
                      "align-items": "center",
                      "margin-top": "1rem",
                    },
                    components: [
                      {
                        type: "text",
                        content: "New User Registration ? ",
                      },
                      {
                        type: "link",
                        content: "Click Here",
                        style: {
                          "margin-left": "8px",
                          color: "#22d3ee",
                        },
                        attributes: {
                          class: "register_now_link",
                        },
                      },
                    ],
                  },
                ],
              },
              {
                type: "complete_form_wrapper",
                attributes: {
                  class: "signup_container",
                },
                style: {
                  width: "50%",
                  "padding-left": "2rem",
                  "padding-right": "2rem",
                  display: "none",
                },
                components: [
                  {
                    type: "form_title",
                  },
                  {
                    type: "form_email_field",
                    style: {
                      "margin-top": "3rem",
                    },
                  },
                  {
                    type: "form_password_field",
                    style: {
                      "margin-top": "1rem",
                    },
                  },
                  {
                    type: "form_submit_field",
                    style: {
                      "margin-top": "3rem",
                    },
                  },
                  {
                    type: "default",
                    tagName: "div",
                    style: {
                      display: "flex",
                      "justify-content": "center",
                      "align-items": "center",
                      "margin-top": "1rem",
                    },
                    components: [
                      {
                        type: "text",
                        content: "Already registered ? ",
                      },
                      {
                        type: "link",
                        content: "Login Here",
                        style: {
                          "margin-left": "8px",
                          color: "#22d3ee",
                        },
                        attributes: {
                          class: "login_now_link",
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        },
        view: {
          init({ model }) {},
          onRender() {},
        },
      });

      editor.DomComponents.addType("login_fraunhofer", {
        model: {
          defaults: {
            script: function (props) {
              function handleSignIn() {
                // Get the values of the username and password inputs
                const usernameInput = document.getElementById("username");
                const passwordInput = document.getElementById("password");
                const username = usernameInput.value;
                const password = passwordInput.value;

                // Check if both fields are non-empty
                if (username.trim() === "" || password.trim() === "") {
                  alert("Please enter both username and password");
                  return;
                }

                // Make an API call with the username and password
                // Replace the URL and API call with your actual implementation
                fetch("https://your-api-url.com/login", {
                  method: "POST",
                  body: JSON.stringify({ username, password }),
                  headers: {
                    "Content-Type": "application/json",
                  },
                })
                  .then((response) => response.json())
                  .then((data) => {
                    // Handle the API response
                    console.log(data);
                  })
                  .catch((error) => {
                    // Handle any errors
                    console.error(error);
                  });
              }

              // Add event listeners to the username and password inputs
              const usernameInput = document.getElementById("username");
              usernameInput.addEventListener("input", () => {
                console.log("Username:", usernameInput.value);
              });

              const passwordInput = document.getElementById("password");
              passwordInput.addEventListener("input", () => {
                console.log("Password:", passwordInput.value);
              });

              // Add click event listener to the submit button
              const signInButton = document.querySelector(".text-teal-100");
              signInButton.addEventListener("click", handleSignIn);
            },

            components: `
                      <div class="grid grid-cols-5 gap-x-4 items-center h-full">
                        <div class="col-span-3">
                          <div class="w-full h-full relative">

                          <img src="https://images.unsplash.com/photo-1510074377623-8cf13fb86c08?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1172&q=80" class="h-full"/>


                          <div class=" z-20 p-8 heroHeadCircle text-white bg-gradient-to-b from-gray-800 via-teal-500 to-teal-800 flex justify-center items-center absolute top-20 right-20 w-2/3 h-2/3 rounded-full" >
                          <p class="tracking-wider text-center">
                          <strong class="text-lg">Bin Picking</strong> is a robot vision solution that guides your robot to pick and place a wide range of products for various service
                          </p>
                          </div>

                          </div>
                         
                        </div>
                        <div class="col-span-2">
                         <div class="w-full flex justify-end mb-8">
                          <p class="w-10 h-10 rounded-full bg-green-500"></p>
                         </div>

                         <div class="flex space-x-4 items-center justify-start mb-4">

                         <div class="flex items-end space-x-2 justify-start">
                         <p class="w-6 h-8 bg-blue-500"></p>
                         <p class="font-bold text-xl tracking-wider">Fraunhofer</p>
                         </div>

                         <p class="w-1 h-8 rounded-lg bg-gray-400"></p>

                         <div class="relative">
                         <p class="w-8 h-8 bg-yellow-400 rounded-full absolute top-0 left-4 " style="z-index:-1"></p>
                         <p class="font-bold text-xl tracking-wider" style="z-index:1">Bin Picking</p>
                         </div>
                         
                         </div>

                         <p class="text-gray-500 tracking-wider text-md mb-4">Welcome back! Please login to your account.</p>

                         <div class="space-y-2 mb-4">
                         <label for="username">User Name</label>
                         <input id="username" type="text" placeholder="Enter Your Username" class="w-full px-2 py-1 border boder-gray-400 rounded-md text-gray-500 outline-none"/>
                         </div>

                         <div class="space-y-2 mb-4">
                         <label for="password">Password</label>
                         <input id="password" type="password" placeholder="Enter Your Password" class="w-full px-2 py-1 border boder-gray-400 rounded-md text-gray-500 outline-none"/>
                         </div>

                         <div class="flex justify-between items-center ">

                         
                           <input type="checkbox" >
                         
                         


                         <button id="submitButton" class="text-teal-100 bg-teal-500 px-6 py-2 rounded-md hover:text-teal-500 hover:bg-teal-100 transition duration-300 hover:shadow-xl">
                         Sign In
                         </button>


                         </div>

                        </div>


                      </div>

                      <script type="text/javascript">

                      const usernameInput = document.getElementById('username');
                      usernameInput.addEventListener('input', () => {
                        console.log('Username:', usernameInput.value);
                      });
              
                      const passwordInput = document.getElementById('password');
                      passwordInput.addEventListener('input', () => {
                        console.log('Password:', passwordInput.value);
                      });
              
              
                      const signInButton = document.querySelector('#submitButton');
                      signInButton.addEventListener('click', () => {
                        const username = usernameInput.value;
                        const password = passwordInput.value;
              
              
                        if (username.trim() === '' || password.trim() === '') {
                          alert('Please enter both username and password');
                          return;
                        }
              
                        fetch('http://localhost:8085/customroutes/login', {
                          method: 'POST',
                          body: JSON.stringify({ username, password }),
                          headers: {
                            'Content-Type': 'application/json',
                          },
                        })
                          .then((response) => response.json())
                          .then((data) => {
                            // Handle the API response
                            console.log(data);
                          })
                          .catch((error) => {
                            // Handle any errors
                            console.error(error);
                          });
                      });
                    </script>

                   
                      `,
          },
        },
      });

      // editor.DomComponents.addType("lineChart", {
      //   model: {
      //     defaults: {
      //       script: function (props) {
      //         const initLib = async function () {
      //           // this helper function recursively scans an object and its nests until it finds the key and returns its value
      //           function findKeyInNestedObject(response, key) {
      //             let values = [];

      //             function traverseObject(obj) {
      //               for (let prop in obj) {
      //                 if (typeof obj[prop] === "object") {
      //                   traverseObject(obj[prop]);
      //                 } else if (Array.isArray(obj[prop])) {
      //                   for (let item of obj[prop]) {
      //                     if (typeof item === "object") {
      //                       traverseObject(item);
      //                     }
      //                   }
      //                 } else if (prop === key) {
      //                   values.push(Number(obj[prop]));
      //                 }
      //               }
      //             }

      //             traverseObject(response);

      //             return values;
      //           }

      //           // this helper function checks if an object has multiple levels of nesting
      //           function isNestedObject(response) {
      //             if (typeof response !== "object") {
      //               return false; // Not an object
      //             }

      //             for (let prop in response) {
      //               if (typeof response[prop] === "object") {
      //                 return true; // Found nested object
      //               }
      //             }

      //             return false; // No nested object found
      //           }

      //           // this helper function checks if there is an array of objects in a response object
      //           function hasArrayofObjects(obj) {
      //             for (let prop in obj) {
      //               if (
      //                 Array.isArray(obj[prop]) &&
      //                 obj[prop].length > 0 &&
      //                 typeof obj[prop][0] === "object"
      //               ) {
      //                 return true; // Array of objects found
      //               }
      //             }
      //             return false; // Array of objects not found
      //           }

      //           let username = props.Username;
      //           let password = props.Password;
      //           let apiKey = props.API_key;
      //           let moreHeaders = props.More_Headers;

      //           let xAxis = [];
      //           let yAxis = [];

      //           console.log(
      //             "Graph props:",
      //             props,
      //             "\n\nusername:",
      //             username,
      //             "\npassword:",
      //             password,
      //             "\napiKey:",
      //             apiKey,
      //             "\nmoreHeaders:",
      //             moreHeaders
      //           );

      //           if (props.Graph_API) {
      //             try {
      //               // By Invoice URL
      //               // let response = await fetch('http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&INVOICE=AR25634D', {

      //               // By Date URL
      //               // let response = await fetch('http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&DATE=20230102', {

      //               // By Customer URL
      //               // let response = await fetch('http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&CUSTOMER=DENCO%20EQUIPMENTS%20LIMITED', {

      //               // By Coin URL
      //               // let response = await fetch('https://api.coinranking.com/v2/coins', {

      //               // By Month URL
      //               // http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&MONTH=MAR

      //               // when api, username and password is provided but not api key
      //               if (username && password && apiKey === undefined) {
      //                 let apiData;
      //                 let config = {
      //                   headers: {
      //                     Authorization:
      //                       "Basic " + btoa(username + ":" + password),
      //                   },
      //                 };
      //                 // ['a:b', "c:d"]
      //                 // extracting headers from More_Headers trait, if provided
      //                 if (moreHeaders) {
      //                   moreHeaders.split(",").forEach((header) => {
      //                     let key, val;
      //                     [key, val] = header.split(":");
      //                     console.log("[key, val]:\n", key, val);

      //                     config.headers[key] = val;
      //                   });
      //                 }

      //                 console.log("config:", config);

      //                 const response = await fetch(
      //                   `${props.Graph_API}`,
      //                   config
      //                 );

      //                 const responseData = await response.json();
      //                 apiData = responseData;
      //                 console.log(
      //                   "responseData:",
      //                   responseData,
      //                   "\napiData:",
      //                   apiData
      //                 );

      //                 // variables needed later confirming whether the value of the required keys are nested further with objects or not
      //                 let hasNestedArrayOfObjects =
      //                   hasArrayofObjects(apiData);
      //                 let abscissaIsNested = isNestedObject(
      //                   responseData[props.Abscissa]
      //                 );
      //                 let ordinateIsNested = isNestedObject(
      //                   responseData[props.Ordinate]
      //                 );

      //                 // MAKING IT WORK FOR UNIVERSAL API RESPONSES, below;
      //                 // not for response structures - array of arrays, object of array of objects, object of array of arrays

      //                 // checking if the reponse is an ARRAY of OBNECTS; and then creating the coordinates array xAxis, yAxis for plotting
      //                 if (
      //                   Array.isArray(apiData) &&
      //                   apiData.length > 0 &&
      //                   typeof apiData[0] === "object"
      //                 ) {
      //                   for (let objX of apiData) {
      //                     xAxis.push(
      //                       objX[!props.Abscissa ? "" : props.Abscissa]
      //                     );
      //                   }

      //                   for (let objY of apiData) {
      //                     yAxis.push(
      //                       objY[!props.Ordinate ? "" : props.Ordinate]
      //                     );
      //                   }
      //                 }

      //                 // checking if the reponse is an OBJECT but not an array; and then creating the coordinates array xAxis, yAxis for plotting
      //                 else if (
      //                   typeof apiData === "object" &&
      //                   !Array.isArray(apiData)
      //                 ) {
      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are an ARRAY also while those traits/values are not 'undefined'
      //                   if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     Array.isArray(apiData[props.Abscissa]) &&
      //                     props.Ordinate &&
      //                     Array.isArray(apiData[props.Ordinate])
      //                   ) {
      //                     // if the array contains a string amongst numbers, then don't assign it to the coordinate arrays
      //                     let xContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     xAxis = !xContainsString
      //                       ? apiData[props.Abscissa]
      //                       : [];

      //                     let yContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     yAxis = !yContainsString
      //                       ? apiData[props.Ordinate]
      //                       : [];
      //                   }

      //                   // now checking if the value of the required keys are PRIMITIVE values, neither arrays nor objects also while those traits/values are not 'undefined'
      //                   else if (
      //                     (!hasNestedArrayOfObjects &&
      //                       props.Abscissa &&
      //                       typeof apiData[props.Abscissa] !== "object") ||
      //                     (apiData[props.Abscissa] === null &&
      //                       props.Ordinate &&
      //                       typeof responseData[props.Ordinate] !==
      //                         "object") ||
      //                     responseData[props.Ordinate] === null
      //                   ) {
      //                     xAxis.push(apiData[props.Abscissa]);
      //                     yAxis.push(apiData[props.Ordinate]);
      //                   }

      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are again a NESTED OBJECT (using recursive helper function) also while those traits/values are not 'undefined'
      //                   else if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     abscissaIsNested &&
      //                     props.Ordinate &&
      //                     ordinateIsNested
      //                   ) {
      //                     console.log("inside 1");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData[props.Abscissa],
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData[props.Ordinate],
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     else if (
      //                       (xValue &&
      //                         yValue &&
      //                         !Array.isArray(xValue) &&
      //                         !Array.isArray(yValue) &&
      //                         typeof xValue !== "object") ||
      //                       (xValue === null && typeof yValue !== "object") ||
      //                       yValue === null
      //                     ) {
      //                       xAxis.push(xValue);
      //                       yAxis.push(yValue);
      //                     }
      //                   }

      //                   // now checking if the value of the response is nested also while those traits/values are not 'undefined'
      //                   else if (
      //                     props.Abscissa &&
      //                     props.Ordinate &&
      //                     hasNestedArrayOfObjects
      //                   ) {
      //                     console.log("inside 2");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     // else if (xValue && yValue && !Array.isArray(xValue) && !Array.isArray(yValue) && typeof xValue !== 'object' || xValue === null && typeof yValue !== 'object' || yValue === null && xValue !== 'NaN' && yValue !== 'NaN') {
      //                     else if (
      //                       typeof Number(xValue) === "number" &&
      //                       typeof Number(yValue) === "number"
      //                     ) {
      //                       xAxis.push(Number(xValue));
      //                       yAxis.push(Number(yValue));
      //                     }
      //                   }
      //                 }
      //               }

      //               // when api and api key is provided but not username and password
      //               else if (!username && !password && apiKey !== undefined) {
      //                 let apiData;
      //                 let config = {
      //                   headers: {
      //                     "X-API-KEY": `${apiKey}`,
      //                   },
      //                 };
      //                 // ['a:b', "c:d"]
      //                 // extracting headers from More_Headers trait
      //                 if (moreHeaders) {
      //                   moreHeaders.split(",").forEach((header) => {
      //                     let key, val;
      //                     [key, val] = header.split(":");
      //                     console.log("[key, val]:\n", key, val);

      //                     config.headers[key] = val;
      //                   });
      //                 }

      //                 console.log("config:", config);

      //                 let response = await fetch(
      //                   `${props.Graph_API}`,
      //                   config
      //                 );

      //                 let responseData = await response.json();
      //                 apiData = responseData;
      //                 console.log(
      //                   "responseData:",
      //                   responseData,
      //                   "\napiData:",
      //                   apiData
      //                 );

      //                 // variables needed later confirming whether the value of the required keys are nested further with objects or not
      //                 let hasNestedArrayOfObjects =
      //                   hasArrayofObjects(apiData);
      //                 let abscissaIsNested = isNestedObject(
      //                   responseData[props.Abscissa]
      //                 );
      //                 let ordinateIsNested = isNestedObject(
      //                   responseData[props.Ordinate]
      //                 );

      //                 // MAKING IT WORK FOR UNIVERSAL API RESPONSES, below;
      //                 // not for response structures - array of arrays, object of array of objects, object of array of arrays

      //                 // checking if the reponse is an ARRAY of OBNECTS; and then creating the coordinates array xAxis, yAxis for plotting
      //                 if (
      //                   Array.isArray(apiData) &&
      //                   apiData.length > 0 &&
      //                   typeof apiData[0] === "object"
      //                 ) {
      //                   for (let objX of apiData) {
      //                     xAxis.push(
      //                       objX[!props.Abscissa ? "" : props.Abscissa]
      //                     );
      //                   }

      //                   for (let objY of apiData) {
      //                     yAxis.push(
      //                       objY[!props.Ordinate ? "" : props.Ordinate]
      //                     );
      //                   }
      //                 }

      //                 // checking if the reponse is an OBJECT but not an array; and then creating the coordinates array xAxis, yAxis for plotting
      //                 else if (
      //                   typeof apiData === "object" &&
      //                   !Array.isArray(apiData)
      //                 ) {
      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are an ARRAY also while those traits/values are not 'undefined'
      //                   if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     Array.isArray(apiData[props.Abscissa]) &&
      //                     props.Ordinate &&
      //                     Array.isArray(apiData[props.Ordinate])
      //                   ) {
      //                     // if the array contains a string amongst numbers, then don't assign it to the coordinate arrays
      //                     let xContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     xAxis = !xContainsString
      //                       ? apiData[props.Abscissa]
      //                       : [];

      //                     let yContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     yAxis = !yContainsString
      //                       ? apiData[props.Ordinate]
      //                       : [];
      //                   }

      //                   // now checking if the value of the required keys are PRIMITIVE values, neither arrays nor objects also while those traits/values are not 'undefined'
      //                   else if (
      //                     (!hasNestedArrayOfObjects &&
      //                       props.Abscissa &&
      //                       typeof apiData[props.Abscissa] !== "object") ||
      //                     (apiData[props.Abscissa] === null &&
      //                       props.Ordinate &&
      //                       typeof responseData[props.Ordinate] !==
      //                         "object") ||
      //                     responseData[props.Ordinate] === null
      //                   ) {
      //                     xAxis.push(apiData[props.Abscissa]);
      //                     yAxis.push(apiData[props.Ordinate]);
      //                   }

      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are again a NESTED OBJECT (using recursive helper function) also while those traits/values are not 'undefined'
      //                   else if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     abscissaIsNested &&
      //                     props.Ordinate &&
      //                     ordinateIsNested
      //                   ) {
      //                     console.log("inside 1");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData[props.Abscissa],
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData[props.Ordinate],
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     else if (
      //                       (xValue &&
      //                         yValue &&
      //                         !Array.isArray(xValue) &&
      //                         !Array.isArray(yValue) &&
      //                         typeof xValue !== "object") ||
      //                       (xValue === null && typeof yValue !== "object") ||
      //                       yValue === null
      //                     ) {
      //                       xAxis.push(xValue);
      //                       yAxis.push(yValue);
      //                     }
      //                   }

      //                   // now checking if the value of the response is nested also while those traits/values are not 'undefined'
      //                   else if (
      //                     props.Abscissa &&
      //                     props.Ordinate &&
      //                     hasNestedArrayOfObjects
      //                   ) {
      //                     console.log("inside 2");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     // else if (xValue && yValue && !Array.isArray(xValue) && !Array.isArray(yValue) && typeof xValue !== 'object' || xValue === null && typeof yValue !== 'object' || yValue === null && xValue !== 'NaN' && yValue !== 'NaN') {
      //                     else if (
      //                       typeof Number(xValue) === "number" &&
      //                       typeof Number(yValue) === "number"
      //                     ) {
      //                       xAxis.push(Number(xValue));
      //                       yAxis.push(Number(yValue));
      //                     }
      //                   }
      //                 }
      //               }

      //               // when everything is provided
      //               else if (username && password && apiKey !== undefined) {
      //                 let apiData;
      //                 let config = {
      //                   headers: {
      //                     Authorization:
      //                       "Basic " + btoa(username + ":" + password),
      //                     "X-API-KEY": `${apiKey}`,
      //                   },
      //                 };
      //                 // ['a:b', "c:d"]
      //                 // extracting headers from More_Headers trait
      //                 if (moreHeaders) {
      //                   moreHeaders.split(",").forEach((header) => {
      //                     let key, val;
      //                     [key, val] = header.split(":");
      //                     console.log("[key, val]:\n", key, val);

      //                     config.headers[key] = val;
      //                   });
      //                 }

      //                 console.log("config:", config);

      //                 let response = await fetch(
      //                   `${props.Graph_API}`,
      //                   config
      //                 );

      //                 let responseData = await response.json();
      //                 apiData = responseData;
      //                 console.log(
      //                   "responseData:",
      //                   responseData,
      //                   "\napiData:",
      //                   apiData
      //                 );

      //                 // variables needed later confirming whether the value of the required keys are nested further with objects or not
      //                 let hasNestedArrayOfObjects =
      //                   hasArrayofObjects(apiData);
      //                 let abscissaIsNested = isNestedObject(
      //                   responseData[props.Abscissa]
      //                 );
      //                 let ordinateIsNested = isNestedObject(
      //                   responseData[props.Ordinate]
      //                 );

      //                 // MAKING IT WORK FOR UNIVERSAL API RESPONSES, below;
      //                 // not for response structures - array of arrays, object of array of objects, object of array of arrays

      //                 // checking if the reponse is an ARRAY of OBNECTS; and then creating the coordinates array xAxis, yAxis for plotting
      //                 if (
      //                   Array.isArray(apiData) &&
      //                   apiData.length > 0 &&
      //                   typeof apiData[0] === "object"
      //                 ) {
      //                   for (let objX of apiData) {
      //                     xAxis.push(
      //                       objX[!props.Abscissa ? "" : props.Abscissa]
      //                     );
      //                   }

      //                   for (let objY of apiData) {
      //                     yAxis.push(
      //                       objY[!props.Ordinate ? "" : props.Ordinate]
      //                     );
      //                   }
      //                 }

      //                 // checking if the reponse is an OBJECT but not an array; and then creating the coordinates array xAxis, yAxis for plotting
      //                 else if (
      //                   typeof apiData === "object" &&
      //                   !Array.isArray(apiData)
      //                 ) {
      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are an ARRAY also while those traits/values are not 'undefined'
      //                   if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     Array.isArray(apiData[props.Abscissa]) &&
      //                     props.Ordinate &&
      //                     Array.isArray(apiData[props.Ordinate])
      //                   ) {
      //                     // if the array contains a string amongst numbers, then don't assign it to the coordinate arrays
      //                     let xContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     xAxis = !xContainsString
      //                       ? apiData[props.Abscissa]
      //                       : [];

      //                     let yContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     yAxis = !yContainsString
      //                       ? apiData[props.Ordinate]
      //                       : [];
      //                   }

      //                   // now checking if the value of the required keys are PRIMITIVE values, neither arrays nor objects also while those traits/values are not 'undefined'
      //                   else if (
      //                     (!hasNestedArrayOfObjects &&
      //                       props.Abscissa &&
      //                       typeof apiData[props.Abscissa] !== "object") ||
      //                     (apiData[props.Abscissa] === null &&
      //                       props.Ordinate &&
      //                       typeof responseData[props.Ordinate] !==
      //                         "object") ||
      //                     responseData[props.Ordinate] === null
      //                   ) {
      //                     xAxis.push(apiData[props.Abscissa]);
      //                     yAxis.push(apiData[props.Ordinate]);
      //                   }

      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are again a NESTED OBJECT (using recursive helper function) also while those traits/values are not 'undefined'
      //                   else if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     abscissaIsNested &&
      //                     props.Ordinate &&
      //                     ordinateIsNested
      //                   ) {
      //                     console.log("inside 1");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData[props.Abscissa],
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData[props.Ordinate],
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     else if (
      //                       (xValue &&
      //                         yValue &&
      //                         !Array.isArray(xValue) &&
      //                         !Array.isArray(yValue) &&
      //                         typeof xValue !== "object") ||
      //                       (xValue === null && typeof yValue !== "object") ||
      //                       yValue === null
      //                     ) {
      //                       xAxis.push(xValue);
      //                       yAxis.push(yValue);
      //                     }
      //                   }

      //                   // now checking if the value of the response is nested also while those traits/values are not 'undefined'
      //                   else if (
      //                     props.Abscissa &&
      //                     props.Ordinate &&
      //                     hasNestedArrayOfObjects
      //                   ) {
      //                     console.log("inside 2");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     // else if (xValue && yValue && !Array.isArray(xValue) && !Array.isArray(yValue) && typeof xValue !== 'object' || xValue === null && typeof yValue !== 'object' || yValue === null && xValue !== 'NaN' && yValue !== 'NaN') {
      //                     else if (
      //                       typeof Number(xValue) === "number" &&
      //                       typeof Number(yValue) === "number"
      //                     ) {
      //                       xAxis.push(Number(xValue));
      //                       yAxis.push(Number(yValue));
      //                     }
      //                   }
      //                 }
      //               }

      //               // when api is provided but nothing else is provided
      //               else if (!username && !password && apiKey === undefined) {
      //                 console.log(
      //                   "inside else if block - when nothing is provided except api"
      //                 );
      //                 let apiData;
      //                 // to hadd multiple header attributes
      //                 let config = {
      //                   headers: {},
      //                 };
      //                 // ['a:b', "c:d"]
      //                 // extracting headers from More_Headers trait
      //                 if (moreHeaders) {
      //                   moreHeaders.split(",").forEach((header) => {
      //                     let key, val;
      //                     [key, val] = header.split(":");
      //                     config.headers[key] = val;

      //                     console.log("[key, val]:\n", key, val);
      //                   });
      //                 }

      //                 console.log("config:", config);

      //                 let response = await fetch(
      //                   `${props.Graph_API}`,
      //                   config
      //                 );

      //                 let responseData = await response.json();
      //                 apiData = responseData;
      //                 console.log(
      //                   "responseData:",
      //                   responseData,
      //                   "\napiData:",
      //                   apiData
      //                 );

      //                 // variables needed later confirming whether the value of the required keys are nested further with objects or not
      //                 let hasNestedArrayOfObjects =
      //                   hasArrayofObjects(apiData);
      //                 let abscissaIsNested = isNestedObject(
      //                   apiData[props.Abscissa]
      //                 );
      //                 let ordinateIsNested = isNestedObject(
      //                   apiData[props.Ordinate]
      //                 );

      //                 // MAKING IT WORK FOR UNIVERSAL API RESPONSES, below;
      //                 // not for response structures - array of arrays, object of array of objects, object of array of arrays

      //                 // checking if the reponse is an ARRAY of OBNECTS; and then creating the coordinates array xAxis, yAxis for plotting
      //                 if (
      //                   Array.isArray(apiData) &&
      //                   apiData.length > 0 &&
      //                   typeof apiData[0] === "object"
      //                 ) {
      //                   for (let objX of apiData) {
      //                     xAxis.push(
      //                       objX[!props.Abscissa ? "" : props.Abscissa]
      //                     );
      //                   }

      //                   for (let objY of apiData) {
      //                     yAxis.push(
      //                       objY[!props.Ordinate ? "" : props.Ordinate]
      //                     );
      //                   }
      //                 }

      //                 // checking if the reponse is an OBJECT but not an array; and then creating the coordinates array xAxis, yAxis for plotting
      //                 else if (
      //                   typeof apiData === "object" &&
      //                   !Array.isArray(apiData)
      //                 ) {
      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are an ARRAY also while those traits/values are not 'undefined'
      //                   if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     Array.isArray(apiData[props.Abscissa]) &&
      //                     props.Ordinate &&
      //                     Array.isArray(apiData[props.Ordinate])
      //                   ) {
      //                     // if the array contains a string amongst numbers, then don't assign it to the coordinate arrays
      //                     let xContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     xAxis = !xContainsString
      //                       ? apiData[props.Abscissa]
      //                       : [];

      //                     let yContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     yAxis = !yContainsString
      //                       ? apiData[props.Ordinate]
      //                       : [];
      //                   }

      //                   // now checking if the value of the required keys are PRIMITIVE values, neither arrays nor objects also while those traits/values are not 'undefined'
      //                   else if (
      //                     (!hasNestedArrayOfObjects &&
      //                       props.Abscissa &&
      //                       typeof apiData[props.Abscissa] !== "object") ||
      //                     (apiData[props.Abscissa] === null &&
      //                       props.Ordinate &&
      //                       typeof responseData[props.Ordinate] !==
      //                         "object") ||
      //                     responseData[props.Ordinate] === null
      //                   ) {
      //                     xAxis.push(apiData[props.Abscissa]);
      //                     yAxis.push(apiData[props.Ordinate]);
      //                   }

      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are again a NESTED OBJECT (using recursive helper function) also while those traits/values are not 'undefined'
      //                   else if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     abscissaIsNested &&
      //                     props.Ordinate &&
      //                     ordinateIsNested
      //                   ) {
      //                     console.log("inside 1");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData[props.Abscissa],
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData[props.Ordinate],
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     else if (
      //                       (xValue &&
      //                         yValue &&
      //                         !Array.isArray(xValue) &&
      //                         !Array.isArray(yValue) &&
      //                         typeof xValue !== "object") ||
      //                       (xValue === null && typeof yValue !== "object") ||
      //                       yValue === null
      //                     ) {
      //                       xAxis.push(xValue);
      //                       yAxis.push(yValue);
      //                     }
      //                   }

      //                   // now checking if the value of the response is nested also while those traits/values are not 'undefined'
      //                   else if (
      //                     props.Abscissa &&
      //                     props.Ordinate &&
      //                     hasNestedArrayOfObjects
      //                   ) {
      //                     console.log("inside 2");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     // else if (xValue && yValue && !Array.isArray(xValue) && !Array.isArray(yValue) && typeof xValue !== 'object' || xValue === null && typeof yValue !== 'object' || yValue === null && xValue !== 'NaN' && yValue !== 'NaN') {
      //                     else if (
      //                       typeof Number(xValue) === "number" &&
      //                       typeof Number(yValue) === "number"
      //                     ) {
      //                       xAxis.push(Number(xValue));
      //                       yAxis.push(Number(yValue));
      //                     }
      //                   }
      //                 }
      //               }

      //               console.log("xAxis:", xAxis, "\nyAxis:", yAxis);

      //               // const labels = [
      //               //   "January",
      //               //   "February",
      //               //   "March",
      //               //   "April",
      //               //   "May",
      //               //   "June",
      //               // ];

      //               // animations
      //               let animate;
      //               let transition;
      //               let delayed;

      //               if (props.Animation === "delay") {
      //                 animate = {
      //                   onComplete: () => {
      //                     delayed = true;
      //                   },

      //                   delay: (context) => {
      //                     let delay = 0;
      //                     if (
      //                       context.type === "data" &&
      //                       context.mode === "default" &&
      //                       !delayed
      //                     ) {
      //                       delay =
      //                         context.dataIndex * 300 +
      //                         context.datasetIndex * 100;
      //                     }
      //                     return delay;
      //                   },
      //                 };
      //               } else if (props.Animation === "hideAndShow") {
      //                 animate = {};
      //                 transition = {
      //                   show: {
      //                     animations: {
      //                       x: {
      //                         from: 0,
      //                       },
      //                       y: {
      //                         from: 0,
      //                       },
      //                     },
      //                   },

      //                   hide: {
      //                     animations: {
      //                       x: {
      //                         to: 0,
      //                       },
      //                       y: {
      //                         to: 0,
      //                       },
      //                     },
      //                   },
      //                 };
      //               }

      //               const data = {
      //                 // x-axis
      //                 labels: xAxis,
      //                 datasets: [
      //                   {
      //                     label: props.Abscissa,
      //                     // backgroundColor: ['red', 'green', 'blue'],
      //                     backgroundColor: !props.Bar_Colors
      //                       ? ["red", "green", "blue"]
      //                       : props.Bar_Colors.split(","),
      //                     borderColor: !props.Border_Color
      //                       ? "rgb(255, 99, 132)"
      //                       : props.Border_Color,
      //                     borderWidth: !props.Border_Width
      //                       ? 2
      //                       : props.Border_Width,
      //                     // y-axis
      //                     data: yAxis,
      //                     // color: ['red', 'green', 'blue'],
      //                   },
      //                 ],
      //               };

      //               const config = {
      //                 type: "line",
      //                 data: data,
      //                 options: {
      //                   animation: animate,
      //                   transitions: transition,
      //                   stacked: false,
      //                   responsive: true,
      //                 },
      //               };

      //               const ctx = document
      //                 .getElementById("lineChart")
      //                 .getContext("2d");
      //               const myChart = new Chart(ctx, config);
      //             } catch (e) {
      //               console.log(e);
      //             }
      //           }
      //         };

      //         if (typeof Chart == "undefined") {
      //           const script = document.createElement("script");
      //           script.onload = initLib;
      //           script.src = "https://cdn.jsdelivr.net/npm/chart.js";
      //           document.body.appendChild(script);
      //         } else {
      //           console.log("InitLib");
      //           initLib();
      //         }
      //       },

      //       Graph_API: "",
      //       Username: "",
      //       Password: "",
      //       API_Key: "",
      //       More_Headers: "", // input should be like: a:b,c:d,e:f

      //       Animation: "",
      //       Abscissa: "",
      //       Ordinate: "",
      //       Bar_Colors: "",
      //       Border_Width: "",
      //       Border_Color: "",
      //       // Fill: "",

      //       traits: [
      //         {
      //           type: "text",
      //           name: "Graph_API",
      //           label: "Graph API",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Username",
      //           label: "Username",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Password",
      //           label: "Password",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "API_Key",
      //           label: "API Key",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "More_Headers",
      //           label: "More Headers",
      //           changeProp: true,
      //         },
      //         {
      //           type: "select",
      //           name: "Animation",
      //           label: "Pre-defined Graph Animations",
      //           options: [
      //             { id: "delay", name: "Delay" },
      //             { id: "hideAndShow", name: "Hide And Show" },
      //           ],
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Abscissa",
      //           label: "Abscissa",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Ordinate",
      //           label: "Ordinates",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Bar_Colors",
      //           label: "Datapoint Colors",
      //           changeProp: true,
      //         },
      //         {
      //           type: "range",
      //           min: "0",
      //           max: "20",
      //           name: "Border_Width",
      //           label: "Border Width [0px to 20px]",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Border_Color",
      //           label: "Border Color",
      //           changeProp: true,
      //         },
      //         // {
      //         //     type: 'checkbox',
      //         //     name: 'Fill',
      //         //     label: 'Fill Area',
      //         //     changeProp: true,
      //         // },
      //       ],

      //       changeProp: true,

      //       "script-props": [
      //         "Graph_API",
      //         "Username",
      //         "Password",
      //         "API_Key",
      //         "More_Headers",
      //         "Animation",
      //         "Abscissa",
      //         "Ordinate",
      //         "Bar_Colors",
      //         "Border_Width",
      //         "Border_Color",
      //       ],
      //     },
      //   },
      //   isComponent: (el) => {
      //     if (
      //       el.getAttribute &&
      //       el.getAttribute("data-gjs-type") == "lineChart"
      //     ) {
      //       return "lineChart";
      //     }
      //   },
      //   view: defaultView.extend({
      //     init({ model }) {},
      //   }),
      // });

      // editor.DomComponents.addType("bar-chart-container", {
      //   model: {
      //     defaults: {
      //       tagName: "div",
      //       attributes: {
      //         class: "bar-chart-container",
      //         style: "padding: 0.5em",
      //       },

      //       components: [
      //         {
      //           type: "bar-chart",
      //         },
      //       ],

      //       traits: ["id"],
      //     },
      //   },
      // });

      // editor.DomComponents.addType("bar-chart", {
      //   model: {
      //     defaults: {
      //       tagName: "div",
      //       attributes: {
      //         // id: "3d-chart",
      //         class: "bar-chart",
      //         // style: "width: 500px; height: 500px;",
      //         "data-gjs-type": "bar-chart",
      //       },

      //       components: [
      //         {
      //           tagName: "canvas",
      //           attributes: {
      //             // id: "3d-chart",
      //             class: "bar-chart-canvas",
      //             // style: "width: 500px; height: 500px;",
      //             "data-gjs-type": "bar-chart-canvas",
      //           },

      //           "style-default": {
      //             width: "500px",
      //             height: "500px",
      //           },

      //           style: {
      //             width: "500px",
      //             height: "500px",
      //           },
      //         },
      //       ],

      //       script: function (props) {
      //         console.log("this", this);
      //         let container = this;

      //         const initLib = async function () {
      //           // this helper function recursively scans an object and its nests until it finds the key and returns its value
      //           function findKeyInNestedObject(response, key) {
      //             let values = [];

      //             function traverseObject(obj) {
      //               for (let prop in obj) {
      //                 if (typeof obj[prop] === "object") {
      //                   traverseObject(obj[prop]);
      //                 } else if (Array.isArray(obj[prop])) {
      //                   for (let item of obj[prop]) {
      //                     if (typeof item === "object") {
      //                       traverseObject(item);
      //                     }
      //                   }
      //                 } else if (prop === key) {
      //                   values.push(Number(obj[prop]));
      //                 }
      //               }
      //             }

      //             traverseObject(response);

      //             return values;
      //           }

      //           // this helper function checks if an object has multiple levels of nesting
      //           function isNestedObject(response) {
      //             if (typeof response !== "object") {
      //               return false; // Not an object
      //             }

      //             for (let prop in response) {
      //               if (typeof response[prop] === "object") {
      //                 return true; // Found nested object
      //               }
      //             }

      //             return false; // No nested object found
      //           }

      //           // this helper function checks if there is an array of objects in a response object
      //           function hasArrayofObjects(obj) {
      //             for (let prop in obj) {
      //               if (
      //                 Array.isArray(obj[prop]) &&
      //                 obj[prop].length > 0 &&
      //                 typeof obj[prop][0] === "object"
      //               ) {
      //                 return true; // Array of objects found
      //               }
      //             }
      //             return false; // Array of objects not found
      //           }

      //           let username = props.Username;
      //           let password = props.Password;
      //           let apiKey = props.API_key;
      //           let moreHeaders = props.More_Headers;

      //           let xAxis = [];
      //           let yAxis = [];

      //           console.log(
      //             "Graph props:",
      //             props,
      //             "\n\nusername:",
      //             username,
      //             "\npassword:",
      //             password,
      //             "\napiKey:",
      //             apiKey,
      //             "\nmoreHeaders:",
      //             moreHeaders
      //           );

      //           if (props.Graph_API) {
      //             try {
      //               // By Invoice URL
      //               // let response = await fetch('http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&INVOICE=AR25634D', {

      //               // By Date URL
      //               // let response = await fetch('http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&DATE=20230102', {

      //               // By Customer URL
      //               // let response = await fetch('http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&CUSTOMER=DENCO%20EQUIPMENTS%20LIMITED', {

      //               // By Coin URL
      //               // let response = await fetch('https://api.coinranking.com/v2/coins', {

      //               // By Month URL
      //               // http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&MONTH=MAR

      //               // when api, username and password is provided but not api key
      //               if (username && password && apiKey === undefined) {
      //                 let apiData;
      //                 let config = {
      //                   headers: {
      //                     Authorization:
      //                       "Basic " + btoa(username + ":" + password),
      //                   },
      //                 };
      //                 // ['a:b', "c:d"]
      //                 // extracting headers from More_Headers trait, if provided
      //                 if (moreHeaders) {
      //                   moreHeaders.split(",").forEach((header) => {
      //                     let key, val;
      //                     [key, val] = header.split(":");
      //                     console.log("[key, val]:\n", key, val);

      //                     config.headers[key] = val;
      //                   });
      //                 }

      //                 console.log("config:", config);

      //                 const response = await fetch(
      //                   `${props.Graph_API}`,
      //                   config
      //                 );

      //                 const responseData = await response.json();
      //                 apiData = responseData;
      //                 console.log(
      //                   "responseData:",
      //                   responseData,
      //                   "\napiData:",
      //                   apiData
      //                 );

      //                 // variables needed later confirming whether the value of the required keys are nested further with objects or not
      //                 let hasNestedArrayOfObjects =
      //                   hasArrayofObjects(apiData);
      //                 let abscissaIsNested = isNestedObject(
      //                   responseData[props.Abscissa]
      //                 );
      //                 let ordinateIsNested = isNestedObject(
      //                   responseData[props.Ordinate]
      //                 );

      //                 // MAKING IT WORK FOR UNIVERSAL API RESPONSES, below;
      //                 // not for response structures - array of arrays, object of array of objects, object of array of arrays

      //                 // checking if the reponse is an ARRAY of OBNECTS; and then creating the coordinates array xAxis, yAxis for plotting
      //                 if (
      //                   Array.isArray(apiData) &&
      //                   apiData.length > 0 &&
      //                   typeof apiData[0] === "object"
      //                 ) {
      //                   for (let objX of apiData) {
      //                     xAxis.push(
      //                       objX[!props.Abscissa ? "" : props.Abscissa]
      //                     );
      //                   }

      //                   for (let objY of apiData) {
      //                     yAxis.push(
      //                       objY[!props.Ordinate ? "" : props.Ordinate]
      //                     );
      //                   }
      //                 }

      //                 // checking if the reponse is an OBJECT but not an array; and then creating the coordinates array xAxis, yAxis for plotting
      //                 else if (
      //                   typeof apiData === "object" &&
      //                   !Array.isArray(apiData)
      //                 ) {
      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are an ARRAY also while those traits/values are not 'undefined'
      //                   if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     Array.isArray(apiData[props.Abscissa]) &&
      //                     props.Ordinate &&
      //                     Array.isArray(apiData[props.Ordinate])
      //                   ) {
      //                     // if the array contains a string amongst numbers, then don't assign it to the coordinate arrays
      //                     let xContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     xAxis = !xContainsString
      //                       ? apiData[props.Abscissa]
      //                       : [];

      //                     let yContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     yAxis = !yContainsString
      //                       ? apiData[props.Ordinate]
      //                       : [];
      //                   }

      //                   // now checking if the value of the required keys are PRIMITIVE values, neither arrays nor objects also while those traits/values are not 'undefined'
      //                   else if (
      //                     (!hasNestedArrayOfObjects &&
      //                       props.Abscissa &&
      //                       typeof apiData[props.Abscissa] !== "object") ||
      //                     (apiData[props.Abscissa] === null &&
      //                       props.Ordinate &&
      //                       typeof responseData[props.Ordinate] !==
      //                         "object") ||
      //                     responseData[props.Ordinate] === null
      //                   ) {
      //                     xAxis.push(apiData[props.Abscissa]);
      //                     yAxis.push(apiData[props.Ordinate]);
      //                   }

      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are again a NESTED OBJECT (using recursive helper function) also while those traits/values are not 'undefined'
      //                   else if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     abscissaIsNested &&
      //                     props.Ordinate &&
      //                     ordinateIsNested
      //                   ) {
      //                     console.log("inside 1");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData[props.Abscissa],
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData[props.Ordinate],
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     else if (
      //                       (xValue &&
      //                         yValue &&
      //                         !Array.isArray(xValue) &&
      //                         !Array.isArray(yValue) &&
      //                         typeof xValue !== "object") ||
      //                       (xValue === null && typeof yValue !== "object") ||
      //                       yValue === null
      //                     ) {
      //                       xAxis.push(xValue);
      //                       yAxis.push(yValue);
      //                     }
      //                   }

      //                   // now checking if the value of the response is nested also while those traits/values are not 'undefined'
      //                   else if (
      //                     props.Abscissa &&
      //                     props.Ordinate &&
      //                     hasNestedArrayOfObjects
      //                   ) {
      //                     console.log("inside 2");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     // else if (xValue && yValue && !Array.isArray(xValue) && !Array.isArray(yValue) && typeof xValue !== 'object' || xValue === null && typeof yValue !== 'object' || yValue === null && xValue !== 'NaN' && yValue !== 'NaN') {
      //                     else if (
      //                       typeof Number(xValue) === "number" &&
      //                       typeof Number(yValue) === "number"
      //                     ) {
      //                       xAxis.push(Number(xValue));
      //                       yAxis.push(Number(yValue));
      //                     }
      //                   }
      //                 }
      //               }

      //               // when api and api key is provided but not username and password
      //               else if (!username && !password && apiKey !== undefined) {
      //                 let apiData;
      //                 let config = {
      //                   headers: {
      //                     "X-API-KEY": `${apiKey}`,
      //                   },
      //                 };
      //                 // ['a:b', "c:d"]
      //                 // extracting headers from More_Headers trait
      //                 if (moreHeaders) {
      //                   moreHeaders.split(",").forEach((header) => {
      //                     let key, val;
      //                     [key, val] = header.split(":");
      //                     console.log("[key, val]:\n", key, val);

      //                     config.headers[key] = val;
      //                   });
      //                 }

      //                 console.log("config:", config);

      //                 let response = await fetch(
      //                   `${props.Graph_API}`,
      //                   config
      //                 );

      //                 let responseData = await response.json();
      //                 apiData = responseData;
      //                 console.log(
      //                   "responseData:",
      //                   responseData,
      //                   "\napiData:",
      //                   apiData
      //                 );

      //                 // variables needed later confirming whether the value of the required keys are nested further with objects or not
      //                 let hasNestedArrayOfObjects =
      //                   hasArrayofObjects(apiData);
      //                 let abscissaIsNested = isNestedObject(
      //                   responseData[props.Abscissa]
      //                 );
      //                 let ordinateIsNested = isNestedObject(
      //                   responseData[props.Ordinate]
      //                 );

      //                 // MAKING IT WORK FOR UNIVERSAL API RESPONSES, below;
      //                 // not for response structures - array of arrays, object of array of objects, object of array of arrays

      //                 // checking if the reponse is an ARRAY of OBNECTS; and then creating the coordinates array xAxis, yAxis for plotting
      //                 if (
      //                   Array.isArray(apiData) &&
      //                   apiData.length > 0 &&
      //                   typeof apiData[0] === "object"
      //                 ) {
      //                   for (let objX of apiData) {
      //                     xAxis.push(
      //                       objX[!props.Abscissa ? "" : props.Abscissa]
      //                     );
      //                   }

      //                   for (let objY of apiData) {
      //                     yAxis.push(
      //                       objY[!props.Ordinate ? "" : props.Ordinate]
      //                     );
      //                   }
      //                 }

      //                 // checking if the reponse is an OBJECT but not an array; and then creating the coordinates array xAxis, yAxis for plotting
      //                 else if (
      //                   typeof apiData === "object" &&
      //                   !Array.isArray(apiData)
      //                 ) {
      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are an ARRAY also while those traits/values are not 'undefined'
      //                   if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     Array.isArray(apiData[props.Abscissa]) &&
      //                     props.Ordinate &&
      //                     Array.isArray(apiData[props.Ordinate])
      //                   ) {
      //                     // if the array contains a string amongst numbers, then don't assign it to the coordinate arrays
      //                     let xContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     xAxis = !xContainsString
      //                       ? apiData[props.Abscissa]
      //                       : [];

      //                     let yContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     yAxis = !yContainsString
      //                       ? apiData[props.Ordinate]
      //                       : [];
      //                   }

      //                   // now checking if the value of the required keys are PRIMITIVE values, neither arrays nor objects also while those traits/values are not 'undefined'
      //                   else if (
      //                     (!hasNestedArrayOfObjects &&
      //                       props.Abscissa &&
      //                       typeof apiData[props.Abscissa] !== "object") ||
      //                     (apiData[props.Abscissa] === null &&
      //                       props.Ordinate &&
      //                       typeof responseData[props.Ordinate] !==
      //                         "object") ||
      //                     responseData[props.Ordinate] === null
      //                   ) {
      //                     xAxis.push(apiData[props.Abscissa]);
      //                     yAxis.push(apiData[props.Ordinate]);
      //                   }

      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are again a NESTED OBJECT (using recursive helper function) also while those traits/values are not 'undefined'
      //                   else if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     abscissaIsNested &&
      //                     props.Ordinate &&
      //                     ordinateIsNested
      //                   ) {
      //                     console.log("inside 1");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData[props.Abscissa],
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData[props.Ordinate],
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     else if (
      //                       (xValue &&
      //                         yValue &&
      //                         !Array.isArray(xValue) &&
      //                         !Array.isArray(yValue) &&
      //                         typeof xValue !== "object") ||
      //                       (xValue === null && typeof yValue !== "object") ||
      //                       yValue === null
      //                     ) {
      //                       xAxis.push(xValue);
      //                       yAxis.push(yValue);
      //                     }
      //                   }

      //                   // now checking if the value of the response is nested also while those traits/values are not 'undefined'
      //                   else if (
      //                     props.Abscissa &&
      //                     props.Ordinate &&
      //                     hasNestedArrayOfObjects
      //                   ) {
      //                     console.log("inside 2");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     // else if (xValue && yValue && !Array.isArray(xValue) && !Array.isArray(yValue) && typeof xValue !== 'object' || xValue === null && typeof yValue !== 'object' || yValue === null && xValue !== 'NaN' && yValue !== 'NaN') {
      //                     else if (
      //                       typeof Number(xValue) === "number" &&
      //                       typeof Number(yValue) === "number"
      //                     ) {
      //                       xAxis.push(Number(xValue));
      //                       yAxis.push(Number(yValue));
      //                     }
      //                   }
      //                 }
      //               }

      //               // when everything is provided
      //               else if (username && password && apiKey !== undefined) {
      //                 let apiData;
      //                 let config = {
      //                   headers: {
      //                     Authorization:
      //                       "Basic " + btoa(username + ":" + password),
      //                     "X-API-KEY": `${apiKey}`,
      //                   },
      //                 };
      //                 // ['a:b', "c:d"]
      //                 // extracting headers from More_Headers trait
      //                 if (moreHeaders) {
      //                   moreHeaders.split(",").forEach((header) => {
      //                     let key, val;
      //                     [key, val] = header.split(":");
      //                     console.log("[key, val]:\n", key, val);

      //                     config.headers[key] = val;
      //                   });
      //                 }

      //                 console.log("config:", config);

      //                 let response = await fetch(
      //                   `${props.Graph_API}`,
      //                   config
      //                 );

      //                 let responseData = await response.json();
      //                 apiData = responseData;
      //                 console.log(
      //                   "responseData:",
      //                   responseData,
      //                   "\napiData:",
      //                   apiData
      //                 );

      //                 // variables needed later confirming whether the value of the required keys are nested further with objects or not
      //                 let hasNestedArrayOfObjects =
      //                   hasArrayofObjects(apiData);
      //                 let abscissaIsNested = isNestedObject(
      //                   responseData[props.Abscissa]
      //                 );
      //                 let ordinateIsNested = isNestedObject(
      //                   responseData[props.Ordinate]
      //                 );

      //                 // MAKING IT WORK FOR UNIVERSAL API RESPONSES, below;
      //                 // not for response structures - array of arrays, object of array of objects, object of array of arrays

      //                 // checking if the reponse is an ARRAY of OBNECTS; and then creating the coordinates array xAxis, yAxis for plotting
      //                 if (
      //                   Array.isArray(apiData) &&
      //                   apiData.length > 0 &&
      //                   typeof apiData[0] === "object"
      //                 ) {
      //                   for (let objX of apiData) {
      //                     xAxis.push(
      //                       objX[!props.Abscissa ? "" : props.Abscissa]
      //                     );
      //                   }

      //                   for (let objY of apiData) {
      //                     yAxis.push(
      //                       objY[!props.Ordinate ? "" : props.Ordinate]
      //                     );
      //                   }
      //                 }

      //                 // checking if the reponse is an OBJECT but not an array; and then creating the coordinates array xAxis, yAxis for plotting
      //                 else if (
      //                   typeof apiData === "object" &&
      //                   !Array.isArray(apiData)
      //                 ) {
      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are an ARRAY also while those traits/values are not 'undefined'
      //                   if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     Array.isArray(apiData[props.Abscissa]) &&
      //                     props.Ordinate &&
      //                     Array.isArray(apiData[props.Ordinate])
      //                   ) {
      //                     // if the array contains a string amongst numbers, then don't assign it to the coordinate arrays
      //                     let xContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     xAxis = !xContainsString
      //                       ? apiData[props.Abscissa]
      //                       : [];

      //                     let yContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     yAxis = !yContainsString
      //                       ? apiData[props.Ordinate]
      //                       : [];
      //                   }

      //                   // now checking if the value of the required keys are PRIMITIVE values, neither arrays nor objects also while those traits/values are not 'undefined'
      //                   else if (
      //                     (!hasNestedArrayOfObjects &&
      //                       props.Abscissa &&
      //                       typeof apiData[props.Abscissa] !== "object") ||
      //                     (apiData[props.Abscissa] === null &&
      //                       props.Ordinate &&
      //                       typeof responseData[props.Ordinate] !==
      //                         "object") ||
      //                     responseData[props.Ordinate] === null
      //                   ) {
      //                     xAxis.push(apiData[props.Abscissa]);
      //                     yAxis.push(apiData[props.Ordinate]);
      //                   }

      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are again a NESTED OBJECT (using recursive helper function) also while those traits/values are not 'undefined'
      //                   else if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     abscissaIsNested &&
      //                     props.Ordinate &&
      //                     ordinateIsNested
      //                   ) {
      //                     console.log("inside 1");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData[props.Abscissa],
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData[props.Ordinate],
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     else if (
      //                       (xValue &&
      //                         yValue &&
      //                         !Array.isArray(xValue) &&
      //                         !Array.isArray(yValue) &&
      //                         typeof xValue !== "object") ||
      //                       (xValue === null && typeof yValue !== "object") ||
      //                       yValue === null
      //                     ) {
      //                       xAxis.push(xValue);
      //                       yAxis.push(yValue);
      //                     }
      //                   }

      //                   // now checking if the value of the response is nested also while those traits/values are not 'undefined'
      //                   else if (
      //                     props.Abscissa &&
      //                     props.Ordinate &&
      //                     hasNestedArrayOfObjects
      //                   ) {
      //                     console.log("inside 2");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     // else if (xValue && yValue && !Array.isArray(xValue) && !Array.isArray(yValue) && typeof xValue !== 'object' || xValue === null && typeof yValue !== 'object' || yValue === null && xValue !== 'NaN' && yValue !== 'NaN') {
      //                     else if (
      //                       typeof Number(xValue) === "number" &&
      //                       typeof Number(yValue) === "number"
      //                     ) {
      //                       xAxis.push(Number(xValue));
      //                       yAxis.push(Number(yValue));
      //                     }
      //                   }
      //                 }
      //               }

      //               // when api is provided but nothing else is provided
      //               else if (!username && !password && apiKey === undefined) {
      //                 let apiData;
      //                 let config = {
      //                   headers: {},
      //                 };
      //                 // ['a:b', "c:d"]
      //                 // extracting headers from More_Headers trait
      //                 if (moreHeaders) {
      //                   moreHeaders.split(",").forEach((header) => {
      //                     let key, val;
      //                     [key, val] = header.split(":");
      //                     console.log("[key, val]:\n", key, val);

      //                     config.headers[key] = val;
      //                   });
      //                 }

      //                 console.log("config:", config);

      //                 let response = await fetch(
      //                   `${props.Graph_API}`,
      //                   config
      //                 );

      //                 let responseData = await response.json();
      //                 apiData = responseData;
      //                 console.log(
      //                   "responseData:",
      //                   responseData,
      //                   "\napiData:",
      //                   apiData
      //                 );

      //                 // variables needed later confirming whether the value of the required keys are nested further with objects or not
      //                 let hasNestedArrayOfObjects =
      //                   hasArrayofObjects(apiData);
      //                 let abscissaIsNested = isNestedObject(
      //                   apiData[props.Abscissa]
      //                 );
      //                 let ordinateIsNested = isNestedObject(
      //                   apiData[props.Ordinate]
      //                 );

      //                 // MAKING IT WORK FOR UNIVERSAL API RESPONSES, below;
      //                 // not for response structures - array of arrays, object of array of objects, object of array of arrays

      //                 // checking if the reponse is an ARRAY of OBNECTS; and then creating the coordinates array xAxis, yAxis for plotting
      //                 if (
      //                   Array.isArray(apiData) &&
      //                   apiData.length > 0 &&
      //                   typeof apiData[0] === "object"
      //                 ) {
      //                   for (let objX of apiData) {
      //                     xAxis.push(
      //                       objX[!props.Abscissa ? "" : props.Abscissa]
      //                     );
      //                   }

      //                   for (let objY of apiData) {
      //                     yAxis.push(
      //                       objY[!props.Ordinate ? "" : props.Ordinate]
      //                     );
      //                   }
      //                 }

      //                 // checking if the reponse is an OBJECT but not an array; and then creating the coordinates array xAxis, yAxis for plotting
      //                 else if (
      //                   typeof apiData === "object" &&
      //                   !Array.isArray(apiData)
      //                 ) {
      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are an ARRAY also while those traits/values are not 'undefined'
      //                   if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     Array.isArray(apiData[props.Abscissa]) &&
      //                     props.Ordinate &&
      //                     Array.isArray(apiData[props.Ordinate])
      //                   ) {
      //                     // if the array contains a string amongst numbers, then don't assign it to the coordinate arrays
      //                     let xContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     xAxis = !xContainsString
      //                       ? apiData[props.Abscissa]
      //                       : [];

      //                     let yContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     yAxis = !yContainsString
      //                       ? apiData[props.Ordinate]
      //                       : [];
      //                   }

      //                   // now checking if the value of the required keys are PRIMITIVE values, neither arrays nor objects also while those traits/values are not 'undefined'
      //                   else if (
      //                     (!hasNestedArrayOfObjects &&
      //                       props.Abscissa &&
      //                       typeof apiData[props.Abscissa] !== "object") ||
      //                     (apiData[props.Abscissa] === null &&
      //                       props.Ordinate &&
      //                       typeof responseData[props.Ordinate] !==
      //                         "object") ||
      //                     responseData[props.Ordinate] === null
      //                   ) {
      //                     xAxis.push(apiData[props.Abscissa]);
      //                     yAxis.push(apiData[props.Ordinate]);
      //                   }

      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are again a NESTED OBJECT (using recursive helper function) also while those traits/values are not 'undefined'
      //                   else if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     abscissaIsNested &&
      //                     props.Ordinate &&
      //                     ordinateIsNested
      //                   ) {
      //                     console.log("inside 1");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData[props.Abscissa],
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData[props.Ordinate],
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     else if (
      //                       (xValue &&
      //                         yValue &&
      //                         !Array.isArray(xValue) &&
      //                         !Array.isArray(yValue) &&
      //                         typeof xValue !== "object") ||
      //                       (xValue === null && typeof yValue !== "object") ||
      //                       yValue === null
      //                     ) {
      //                       xAxis.push(xValue);
      //                       yAxis.push(yValue);
      //                     }
      //                   }

      //                   // now checking if the value of the response is nested also while those traits/values are not 'undefined'
      //                   else if (
      //                     props.Abscissa &&
      //                     props.Ordinate &&
      //                     hasNestedArrayOfObjects
      //                   ) {
      //                     console.log("inside 2");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     // else if (xValue && yValue && !Array.isArray(xValue) && !Array.isArray(yValue) && typeof xValue !== 'object' || xValue === null && typeof yValue !== 'object' || yValue === null && xValue !== 'NaN' && yValue !== 'NaN') {
      //                     else if (
      //                       typeof Number(xValue) === "number" &&
      //                       typeof Number(yValue) === "number"
      //                     ) {
      //                       xAxis.push(Number(xValue));
      //                       yAxis.push(Number(yValue));
      //                     }
      //                   }
      //                 }
      //               }

      //               console.log("xAxis:", xAxis, "\nyAxis:", yAxis);

      //               // const labels = [
      //               //   "January",
      //               //   "February",
      //               //   "March",
      //               //   "April",
      //               //   "May",
      //               //   "June",
      //               // ];

      //               // animations
      //               let animate;
      //               let transition;
      //               let delayed;

      //               if (props.Animation === "delay") {
      //                 animate = {
      //                   onComplete: () => {
      //                     delayed = true;
      //                   },

      //                   delay: (context) => {
      //                     let delay = 0;
      //                     if (
      //                       context.type === "data" &&
      //                       context.mode === "default" &&
      //                       !delayed
      //                     ) {
      //                       delay =
      //                         context.dataIndex * 300 +
      //                         context.datasetIndex * 100;
      //                     }
      //                     return delay;
      //                   },
      //                 };
      //               } else if (props.Animation === "hideAndShow") {
      //                 animate = {};
      //                 transition = {
      //                   show: {
      //                     animations: {
      //                       x: {
      //                         from: 0,
      //                       },
      //                       y: {
      //                         from: 0,
      //                       },
      //                     },
      //                   },

      //                   hide: {
      //                     animations: {
      //                       x: {
      //                         to: 0,
      //                       },
      //                       y: {
      //                         to: 0,
      //                       },
      //                     },
      //                   },
      //                 };
      //               }

      //               const data = {
      //                 // x-axis
      //                 labels: xAxis,
      //                 datasets: [
      //                   {
      //                     label: props.Abscissa,
      //                     // backgroundColor: ['red', 'green', 'blue'],
      //                     backgroundColor: !props.Bar_Colors
      //                       ? ["red", "green", "blue"]
      //                       : props.Bar_Colors.split(","),
      //                     borderColor: !props.Border_Color
      //                       ? "rgb(255, 99, 132)"
      //                       : props.Border_Color,
      //                     borderWidth: !props.Border_Width
      //                       ? 2
      //                       : props.Border_Width,
      //                     // y-axis
      //                     data: yAxis,
      //                     // color: ['red', 'green', 'blue'],
      //                   },
      //                 ],
      //               };

      //               const config = {
      //                 type: "bar",
      //                 data: data,
      //                 options: {
      //                   animation: animate,
      //                   transitions: transition,
      //                   stacked: false,
      //                   responsive: true,
      //                 },
      //               };

      //               const ctx = container
      //                 .getElementsByClassName("bar-chart-canvas")[0]
      //                 .getContext("2d");
      //               const myChart = new Chart(ctx, config);
      //             } catch (e) {
      //               console.log(e);
      //             }
      //           }
      //         };

      //         if (typeof Chart == "undefined") {
      //           const script = document.createElement("script");
      //           script.onload = initLib;
      //           script.src = "https://cdn.jsdelivr.net/npm/chart.js";
      //           document.body.appendChild(script);
      //         } else {
      //           console.log("InitLib");
      //           initLib();
      //         }
      //       },

      //       Graph_API: "",
      //       Username: "",
      //       Password: "",
      //       API_Key: "",
      //       More_Headers: "", // input should be like: a:b,c:d,e:f

      //       Animation: "",
      //       Abscissa: "",
      //       Ordinate: "",
      //       Bar_Colors: "",
      //       Border_Width: "",
      //       Border_Color: "",
      //       // Fill: "",

      //       traits: [
      //         {
      //           type: "text",
      //           name: "Graph_API",
      //           label: "Graph API",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Username",
      //           label: "Username",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Password",
      //           label: "Password",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "API_Key",
      //           label: "API Key",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "More_Headers",
      //           label: "More Headers",
      //           changeProp: true,
      //         },
      //         {
      //           type: "select",
      //           name: "Animation",
      //           label: "Pre-defined Graph Animations",
      //           options: [
      //             { id: "delay", name: "Delay" },
      //             { id: "hideAndShow", name: "Hide And Show" },
      //           ],
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Abscissa",
      //           label: "Abscissa",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Ordinate",
      //           label: "Ordinates",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Bar_Colors",
      //           label: "Datapoint Colors",
      //           changeProp: true,
      //         },
      //         {
      //           type: "range",
      //           min: "0",
      //           max: "20",
      //           name: "Border_Width",
      //           label: "Border Width [0px to 20px]",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Border_Color",
      //           label: "Border Color",
      //           changeProp: true,
      //         },
      //         // {
      //         //     type: 'checkbox',
      //         //     name: 'Fill',
      //         //     label: 'Fill Area',
      //         //     changeProp: true,
      //         // },
      //       ],

      //       changeProp: true,

      //       "script-props": [
      //         "Graph_API",
      //         "Username",
      //         "Password",
      //         "API_Key",
      //         "More_Headers",
      //         "Animation",
      //         "Abscissa",
      //         "Ordinate",
      //         "Bar_Colors",
      //         "Border_Width",
      //         "Border_Color",
      //       ],
      //     },
      //   },

      //   isComponent: (el) => {
      //     if (
      //       el.getAttribute &&
      //       el.getAttribute("data-gjs-type") == "bar-chart"
      //     ) {
      //       return "bar-chart";
      //     }
      //   },
      //   view: defaultView.extend({
      //     init({ model }) {},
      //   }),
      // });

      // editor.DomComponents.addType("barChart", {
      //   model: {
      //     defaults: {
      //       script: function (props) {
      //         const initLib = async function () {
      //           // this helper function recursively scans an object and its nests until it finds the key and returns its value
      //           function findKeyInNestedObject(response, key) {
      //             let values = [];

      //             function traverseObject(obj) {
      //               for (let prop in obj) {
      //                 if (typeof obj[prop] === "object") {
      //                   traverseObject(obj[prop]);
      //                 } else if (Array.isArray(obj[prop])) {
      //                   for (let item of obj[prop]) {
      //                     if (typeof item === "object") {
      //                       traverseObject(item);
      //                     }
      //                   }
      //                 } else if (prop === key) {
      //                   values.push(Number(obj[prop]));
      //                 }
      //               }
      //             }

      //             traverseObject(response);

      //             return values;
      //           }

      //           // this helper function checks if an object has multiple levels of nesting
      //           function isNestedObject(response) {
      //             if (typeof response !== "object") {
      //               return false; // Not an object
      //             }

      //             for (let prop in response) {
      //               if (typeof response[prop] === "object") {
      //                 return true; // Found nested object
      //               }
      //             }

      //             return false; // No nested object found
      //           }

      //           // this helper function checks if there is an array of objects in a response object
      //           function hasArrayofObjects(obj) {
      //             for (let prop in obj) {
      //               if (
      //                 Array.isArray(obj[prop]) &&
      //                 obj[prop].length > 0 &&
      //                 typeof obj[prop][0] === "object"
      //               ) {
      //                 return true; // Array of objects found
      //               }
      //             }
      //             return false; // Array of objects not found
      //           }

      //           let username = props.Username;
      //           let password = props.Password;
      //           let apiKey = props.API_key;
      //           let moreHeaders = props.More_Headers;

      //           let xAxis = [];
      //           let yAxis = [];

      //           console.log(
      //             "Graph props:",
      //             props,
      //             "\n\nusername:",
      //             username,
      //             "\npassword:",
      //             password,
      //             "\napiKey:",
      //             apiKey,
      //             "\nmoreHeaders:",
      //             moreHeaders
      //           );

      //           if (props.Graph_API) {
      //             try {
      //               // By Invoice URL
      //               // let response = await fetch('http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&INVOICE=AR25634D', {

      //               // By Date URL
      //               // let response = await fetch('http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&DATE=20230102', {

      //               // By Customer URL
      //               // let response = await fetch('http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&CUSTOMER=DENCO%20EQUIPMENTS%20LIMITED', {

      //               // By Coin URL
      //               // let response = await fetch('https://api.coinranking.com/v2/coins', {

      //               // By Month URL
      //               // http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&MONTH=MAR

      //               // when api, username and password is provided but not api key
      //               if (username && password && apiKey === undefined) {
      //                 let apiData;
      //                 let config = {
      //                   headers: {
      //                     Authorization:
      //                       "Basic " + btoa(username + ":" + password),
      //                   },
      //                 };
      //                 // ['a:b', "c:d"]
      //                 // extracting headers from More_Headers trait, if provided
      //                 if (moreHeaders) {
      //                   moreHeaders.split(",").forEach((header) => {
      //                     let key, val;
      //                     [key, val] = header.split(":");
      //                     console.log("[key, val]:\n", key, val);

      //                     config.headers[key] = val;
      //                   });
      //                 }

      //                 console.log("config:", config);

      //                 const response = await fetch(
      //                   `${props.Graph_API}`,
      //                   config
      //                 );

      //                 const responseData = await response.json();
      //                 apiData = responseData;
      //                 console.log(
      //                   "responseData:",
      //                   responseData,
      //                   "\napiData:",
      //                   apiData
      //                 );

      //                 // variables needed later confirming whether the value of the required keys are nested further with objects or not
      //                 let hasNestedArrayOfObjects =
      //                   hasArrayofObjects(apiData);
      //                 let abscissaIsNested = isNestedObject(
      //                   responseData[props.Abscissa]
      //                 );
      //                 let ordinateIsNested = isNestedObject(
      //                   responseData[props.Ordinate]
      //                 );

      //                 // MAKING IT WORK FOR UNIVERSAL API RESPONSES, below;
      //                 // not for response structures - array of arrays, object of array of objects, object of array of arrays

      //                 // checking if the reponse is an ARRAY of OBNECTS; and then creating the coordinates array xAxis, yAxis for plotting
      //                 if (
      //                   Array.isArray(apiData) &&
      //                   apiData.length > 0 &&
      //                   typeof apiData[0] === "object"
      //                 ) {
      //                   for (let objX of apiData) {
      //                     xAxis.push(
      //                       objX[!props.Abscissa ? "" : props.Abscissa]
      //                     );
      //                   }

      //                   for (let objY of apiData) {
      //                     yAxis.push(
      //                       objY[!props.Ordinate ? "" : props.Ordinate]
      //                     );
      //                   }
      //                 }

      //                 // checking if the reponse is an OBJECT but not an array; and then creating the coordinates array xAxis, yAxis for plotting
      //                 else if (
      //                   typeof apiData === "object" &&
      //                   !Array.isArray(apiData)
      //                 ) {
      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are an ARRAY also while those traits/values are not 'undefined'
      //                   if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     Array.isArray(apiData[props.Abscissa]) &&
      //                     props.Ordinate &&
      //                     Array.isArray(apiData[props.Ordinate])
      //                   ) {
      //                     // if the array contains a string amongst numbers, then don't assign it to the coordinate arrays
      //                     let xContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     xAxis = !xContainsString
      //                       ? apiData[props.Abscissa]
      //                       : [];

      //                     let yContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     yAxis = !yContainsString
      //                       ? apiData[props.Ordinate]
      //                       : [];
      //                   }

      //                   // now checking if the value of the required keys are PRIMITIVE values, neither arrays nor objects also while those traits/values are not 'undefined'
      //                   else if (
      //                     (!hasNestedArrayOfObjects &&
      //                       props.Abscissa &&
      //                       typeof apiData[props.Abscissa] !== "object") ||
      //                     (apiData[props.Abscissa] === null &&
      //                       props.Ordinate &&
      //                       typeof responseData[props.Ordinate] !==
      //                         "object") ||
      //                     responseData[props.Ordinate] === null
      //                   ) {
      //                     xAxis.push(apiData[props.Abscissa]);
      //                     yAxis.push(apiData[props.Ordinate]);
      //                   }

      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are again a NESTED OBJECT (using recursive helper function) also while those traits/values are not 'undefined'
      //                   else if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     abscissaIsNested &&
      //                     props.Ordinate &&
      //                     ordinateIsNested
      //                   ) {
      //                     console.log("inside 1");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData[props.Abscissa],
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData[props.Ordinate],
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     else if (
      //                       (xValue &&
      //                         yValue &&
      //                         !Array.isArray(xValue) &&
      //                         !Array.isArray(yValue) &&
      //                         typeof xValue !== "object") ||
      //                       (xValue === null && typeof yValue !== "object") ||
      //                       yValue === null
      //                     ) {
      //                       xAxis.push(xValue);
      //                       yAxis.push(yValue);
      //                     }
      //                   }

      //                   // now checking if the value of the response is nested also while those traits/values are not 'undefined'
      //                   else if (
      //                     props.Abscissa &&
      //                     props.Ordinate &&
      //                     hasNestedArrayOfObjects
      //                   ) {
      //                     console.log("inside 2");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     // else if (xValue && yValue && !Array.isArray(xValue) && !Array.isArray(yValue) && typeof xValue !== 'object' || xValue === null && typeof yValue !== 'object' || yValue === null && xValue !== 'NaN' && yValue !== 'NaN') {
      //                     else if (
      //                       typeof Number(xValue) === "number" &&
      //                       typeof Number(yValue) === "number"
      //                     ) {
      //                       xAxis.push(Number(xValue));
      //                       yAxis.push(Number(yValue));
      //                     }
      //                   }
      //                 }
      //               }

      //               // when api and api key is provided but not username and password
      //               else if (!username && !password && apiKey !== undefined) {
      //                 let apiData;
      //                 let config = {
      //                   headers: {
      //                     "X-API-KEY": `${apiKey}`,
      //                   },
      //                 };
      //                 // ['a:b', "c:d"]
      //                 // extracting headers from More_Headers trait
      //                 if (moreHeaders) {
      //                   moreHeaders.split(",").forEach((header) => {
      //                     let key, val;
      //                     [key, val] = header.split(":");
      //                     console.log("[key, val]:\n", key, val);

      //                     config.headers[key] = val;
      //                   });
      //                 }

      //                 console.log("config:", config);

      //                 let response = await fetch(
      //                   `${props.Graph_API}`,
      //                   config
      //                 );

      //                 let responseData = await response.json();
      //                 apiData = responseData;
      //                 console.log(
      //                   "responseData:",
      //                   responseData,
      //                   "\napiData:",
      //                   apiData
      //                 );

      //                 // variables needed later confirming whether the value of the required keys are nested further with objects or not
      //                 let hasNestedArrayOfObjects =
      //                   hasArrayofObjects(apiData);
      //                 let abscissaIsNested = isNestedObject(
      //                   responseData[props.Abscissa]
      //                 );
      //                 let ordinateIsNested = isNestedObject(
      //                   responseData[props.Ordinate]
      //                 );

      //                 // MAKING IT WORK FOR UNIVERSAL API RESPONSES, below;
      //                 // not for response structures - array of arrays, object of array of objects, object of array of arrays

      //                 // checking if the reponse is an ARRAY of OBNECTS; and then creating the coordinates array xAxis, yAxis for plotting
      //                 if (
      //                   Array.isArray(apiData) &&
      //                   apiData.length > 0 &&
      //                   typeof apiData[0] === "object"
      //                 ) {
      //                   for (let objX of apiData) {
      //                     xAxis.push(
      //                       objX[!props.Abscissa ? "" : props.Abscissa]
      //                     );
      //                   }

      //                   for (let objY of apiData) {
      //                     yAxis.push(
      //                       objY[!props.Ordinate ? "" : props.Ordinate]
      //                     );
      //                   }
      //                 }

      //                 // checking if the reponse is an OBJECT but not an array; and then creating the coordinates array xAxis, yAxis for plotting
      //                 else if (
      //                   typeof apiData === "object" &&
      //                   !Array.isArray(apiData)
      //                 ) {
      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are an ARRAY also while those traits/values are not 'undefined'
      //                   if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     Array.isArray(apiData[props.Abscissa]) &&
      //                     props.Ordinate &&
      //                     Array.isArray(apiData[props.Ordinate])
      //                   ) {
      //                     // if the array contains a string amongst numbers, then don't assign it to the coordinate arrays
      //                     let xContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     xAxis = !xContainsString
      //                       ? apiData[props.Abscissa]
      //                       : [];

      //                     let yContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     yAxis = !yContainsString
      //                       ? apiData[props.Ordinate]
      //                       : [];
      //                   }

      //                   // now checking if the value of the required keys are PRIMITIVE values, neither arrays nor objects also while those traits/values are not 'undefined'
      //                   else if (
      //                     (!hasNestedArrayOfObjects &&
      //                       props.Abscissa &&
      //                       typeof apiData[props.Abscissa] !== "object") ||
      //                     (apiData[props.Abscissa] === null &&
      //                       props.Ordinate &&
      //                       typeof responseData[props.Ordinate] !==
      //                         "object") ||
      //                     responseData[props.Ordinate] === null
      //                   ) {
      //                     xAxis.push(apiData[props.Abscissa]);
      //                     yAxis.push(apiData[props.Ordinate]);
      //                   }

      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are again a NESTED OBJECT (using recursive helper function) also while those traits/values are not 'undefined'
      //                   else if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     abscissaIsNested &&
      //                     props.Ordinate &&
      //                     ordinateIsNested
      //                   ) {
      //                     console.log("inside 1");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData[props.Abscissa],
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData[props.Ordinate],
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     else if (
      //                       (xValue &&
      //                         yValue &&
      //                         !Array.isArray(xValue) &&
      //                         !Array.isArray(yValue) &&
      //                         typeof xValue !== "object") ||
      //                       (xValue === null && typeof yValue !== "object") ||
      //                       yValue === null
      //                     ) {
      //                       xAxis.push(xValue);
      //                       yAxis.push(yValue);
      //                     }
      //                   }

      //                   // now checking if the value of the response is nested also while those traits/values are not 'undefined'
      //                   else if (
      //                     props.Abscissa &&
      //                     props.Ordinate &&
      //                     hasNestedArrayOfObjects
      //                   ) {
      //                     console.log("inside 2");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     // else if (xValue && yValue && !Array.isArray(xValue) && !Array.isArray(yValue) && typeof xValue !== 'object' || xValue === null && typeof yValue !== 'object' || yValue === null && xValue !== 'NaN' && yValue !== 'NaN') {
      //                     else if (
      //                       typeof Number(xValue) === "number" &&
      //                       typeof Number(yValue) === "number"
      //                     ) {
      //                       xAxis.push(Number(xValue));
      //                       yAxis.push(Number(yValue));
      //                     }
      //                   }
      //                 }
      //               }

      //               // when everything is provided
      //               else if (username && password && apiKey !== undefined) {
      //                 let apiData;
      //                 let config = {
      //                   headers: {
      //                     Authorization:
      //                       "Basic " + btoa(username + ":" + password),
      //                     "X-API-KEY": `${apiKey}`,
      //                   },
      //                 };
      //                 // ['a:b', "c:d"]
      //                 // extracting headers from More_Headers trait
      //                 if (moreHeaders) {
      //                   moreHeaders.split(",").forEach((header) => {
      //                     let key, val;
      //                     [key, val] = header.split(":");
      //                     console.log("[key, val]:\n", key, val);

      //                     config.headers[key] = val;
      //                   });
      //                 }

      //                 console.log("config:", config);

      //                 let response = await fetch(
      //                   `${props.Graph_API}`,
      //                   config
      //                 );

      //                 let responseData = await response.json();
      //                 apiData = responseData;
      //                 console.log(
      //                   "responseData:",
      //                   responseData,
      //                   "\napiData:",
      //                   apiData
      //                 );

      //                 // variables needed later confirming whether the value of the required keys are nested further with objects or not
      //                 let hasNestedArrayOfObjects =
      //                   hasArrayofObjects(apiData);
      //                 let abscissaIsNested = isNestedObject(
      //                   responseData[props.Abscissa]
      //                 );
      //                 let ordinateIsNested = isNestedObject(
      //                   responseData[props.Ordinate]
      //                 );

      //                 // MAKING IT WORK FOR UNIVERSAL API RESPONSES, below;
      //                 // not for response structures - array of arrays, object of array of objects, object of array of arrays

      //                 // checking if the reponse is an ARRAY of OBNECTS; and then creating the coordinates array xAxis, yAxis for plotting
      //                 if (
      //                   Array.isArray(apiData) &&
      //                   apiData.length > 0 &&
      //                   typeof apiData[0] === "object"
      //                 ) {
      //                   for (let objX of apiData) {
      //                     xAxis.push(
      //                       objX[!props.Abscissa ? "" : props.Abscissa]
      //                     );
      //                   }

      //                   for (let objY of apiData) {
      //                     yAxis.push(
      //                       objY[!props.Ordinate ? "" : props.Ordinate]
      //                     );
      //                   }
      //                 }

      //                 // checking if the reponse is an OBJECT but not an array; and then creating the coordinates array xAxis, yAxis for plotting
      //                 else if (
      //                   typeof apiData === "object" &&
      //                   !Array.isArray(apiData)
      //                 ) {
      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are an ARRAY also while those traits/values are not 'undefined'
      //                   if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     Array.isArray(apiData[props.Abscissa]) &&
      //                     props.Ordinate &&
      //                     Array.isArray(apiData[props.Ordinate])
      //                   ) {
      //                     // if the array contains a string amongst numbers, then don't assign it to the coordinate arrays
      //                     let xContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     xAxis = !xContainsString
      //                       ? apiData[props.Abscissa]
      //                       : [];

      //                     let yContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     yAxis = !yContainsString
      //                       ? apiData[props.Ordinate]
      //                       : [];
      //                   }

      //                   // now checking if the value of the required keys are PRIMITIVE values, neither arrays nor objects also while those traits/values are not 'undefined'
      //                   else if (
      //                     (!hasNestedArrayOfObjects &&
      //                       props.Abscissa &&
      //                       typeof apiData[props.Abscissa] !== "object") ||
      //                     (apiData[props.Abscissa] === null &&
      //                       props.Ordinate &&
      //                       typeof responseData[props.Ordinate] !==
      //                         "object") ||
      //                     responseData[props.Ordinate] === null
      //                   ) {
      //                     xAxis.push(apiData[props.Abscissa]);
      //                     yAxis.push(apiData[props.Ordinate]);
      //                   }

      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are again a NESTED OBJECT (using recursive helper function) also while those traits/values are not 'undefined'
      //                   else if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     abscissaIsNested &&
      //                     props.Ordinate &&
      //                     ordinateIsNested
      //                   ) {
      //                     console.log("inside 1");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData[props.Abscissa],
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData[props.Ordinate],
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     else if (
      //                       (xValue &&
      //                         yValue &&
      //                         !Array.isArray(xValue) &&
      //                         !Array.isArray(yValue) &&
      //                         typeof xValue !== "object") ||
      //                       (xValue === null && typeof yValue !== "object") ||
      //                       yValue === null
      //                     ) {
      //                       xAxis.push(xValue);
      //                       yAxis.push(yValue);
      //                     }
      //                   }

      //                   // now checking if the value of the response is nested also while those traits/values are not 'undefined'
      //                   else if (
      //                     props.Abscissa &&
      //                     props.Ordinate &&
      //                     hasNestedArrayOfObjects
      //                   ) {
      //                     console.log("inside 2");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     // else if (xValue && yValue && !Array.isArray(xValue) && !Array.isArray(yValue) && typeof xValue !== 'object' || xValue === null && typeof yValue !== 'object' || yValue === null && xValue !== 'NaN' && yValue !== 'NaN') {
      //                     else if (
      //                       typeof Number(xValue) === "number" &&
      //                       typeof Number(yValue) === "number"
      //                     ) {
      //                       xAxis.push(Number(xValue));
      //                       yAxis.push(Number(yValue));
      //                     }
      //                   }
      //                 }
      //               }

      //               // when api is provided but nothing else is provided
      //               else if (!username && !password && apiKey === undefined) {
      //                 let apiData;
      //                 let config = {
      //                   headers: {},
      //                 };
      //                 // ['a:b', "c:d"]
      //                 // extracting headers from More_Headers trait
      //                 if (moreHeaders) {
      //                   moreHeaders.split(",").forEach((header) => {
      //                     let key, val;
      //                     [key, val] = header.split(":");
      //                     console.log("[key, val]:\n", key, val);

      //                     config.headers[key] = val;
      //                   });
      //                 }

      //                 console.log("config:", config);

      //                 let response = await fetch(
      //                   `${props.Graph_API}`,
      //                   config
      //                 );

      //                 let responseData = await response.json();
      //                 apiData = responseData;
      //                 console.log(
      //                   "responseData:",
      //                   responseData,
      //                   "\napiData:",
      //                   apiData
      //                 );

      //                 // variables needed later confirming whether the value of the required keys are nested further with objects or not
      //                 let hasNestedArrayOfObjects =
      //                   hasArrayofObjects(apiData);
      //                 let abscissaIsNested = isNestedObject(
      //                   apiData[props.Abscissa]
      //                 );
      //                 let ordinateIsNested = isNestedObject(
      //                   apiData[props.Ordinate]
      //                 );

      //                 // MAKING IT WORK FOR UNIVERSAL API RESPONSES, below;
      //                 // not for response structures - array of arrays, object of array of objects, object of array of arrays

      //                 // checking if the reponse is an ARRAY of OBNECTS; and then creating the coordinates array xAxis, yAxis for plotting
      //                 if (
      //                   Array.isArray(apiData) &&
      //                   apiData.length > 0 &&
      //                   typeof apiData[0] === "object"
      //                 ) {
      //                   for (let objX of apiData) {
      //                     xAxis.push(
      //                       objX[!props.Abscissa ? "" : props.Abscissa]
      //                     );
      //                   }

      //                   for (let objY of apiData) {
      //                     yAxis.push(
      //                       objY[!props.Ordinate ? "" : props.Ordinate]
      //                     );
      //                   }
      //                 }

      //                 // checking if the reponse is an OBJECT but not an array; and then creating the coordinates array xAxis, yAxis for plotting
      //                 else if (
      //                   typeof apiData === "object" &&
      //                   !Array.isArray(apiData)
      //                 ) {
      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are an ARRAY also while those traits/values are not 'undefined'
      //                   if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     Array.isArray(apiData[props.Abscissa]) &&
      //                     props.Ordinate &&
      //                     Array.isArray(apiData[props.Ordinate])
      //                   ) {
      //                     // if the array contains a string amongst numbers, then don't assign it to the coordinate arrays
      //                     let xContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     xAxis = !xContainsString
      //                       ? apiData[props.Abscissa]
      //                       : [];

      //                     let yContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     yAxis = !yContainsString
      //                       ? apiData[props.Ordinate]
      //                       : [];
      //                   }

      //                   // now checking if the value of the required keys are PRIMITIVE values, neither arrays nor objects also while those traits/values are not 'undefined'
      //                   else if (
      //                     (!hasNestedArrayOfObjects &&
      //                       props.Abscissa &&
      //                       typeof apiData[props.Abscissa] !== "object") ||
      //                     (apiData[props.Abscissa] === null &&
      //                       props.Ordinate &&
      //                       typeof responseData[props.Ordinate] !==
      //                         "object") ||
      //                     responseData[props.Ordinate] === null
      //                   ) {
      //                     xAxis.push(apiData[props.Abscissa]);
      //                     yAxis.push(apiData[props.Ordinate]);
      //                   }

      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are again a NESTED OBJECT (using recursive helper function) also while those traits/values are not 'undefined'
      //                   else if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     abscissaIsNested &&
      //                     props.Ordinate &&
      //                     ordinateIsNested
      //                   ) {
      //                     console.log("inside 1");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData[props.Abscissa],
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData[props.Ordinate],
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     else if (
      //                       (xValue &&
      //                         yValue &&
      //                         !Array.isArray(xValue) &&
      //                         !Array.isArray(yValue) &&
      //                         typeof xValue !== "object") ||
      //                       (xValue === null && typeof yValue !== "object") ||
      //                       yValue === null
      //                     ) {
      //                       xAxis.push(xValue);
      //                       yAxis.push(yValue);
      //                     }
      //                   }

      //                   // now checking if the value of the response is nested also while those traits/values are not 'undefined'
      //                   else if (
      //                     props.Abscissa &&
      //                     props.Ordinate &&
      //                     hasNestedArrayOfObjects
      //                   ) {
      //                     console.log("inside 2");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     // else if (xValue && yValue && !Array.isArray(xValue) && !Array.isArray(yValue) && typeof xValue !== 'object' || xValue === null && typeof yValue !== 'object' || yValue === null && xValue !== 'NaN' && yValue !== 'NaN') {
      //                     else if (
      //                       typeof Number(xValue) === "number" &&
      //                       typeof Number(yValue) === "number"
      //                     ) {
      //                       xAxis.push(Number(xValue));
      //                       yAxis.push(Number(yValue));
      //                     }
      //                   }
      //                 }
      //               }

      //               console.log("xAxis:", xAxis, "\nyAxis:", yAxis);

      //               // const labels = [
      //               //   "January",
      //               //   "February",
      //               //   "March",
      //               //   "April",
      //               //   "May",
      //               //   "June",
      //               // ];

      //               // animations
      //               let animate;
      //               let transition;
      //               let delayed;

      //               if (props.Animation === "delay") {
      //                 animate = {
      //                   onComplete: () => {
      //                     delayed = true;
      //                   },

      //                   delay: (context) => {
      //                     let delay = 0;
      //                     if (
      //                       context.type === "data" &&
      //                       context.mode === "default" &&
      //                       !delayed
      //                     ) {
      //                       delay =
      //                         context.dataIndex * 300 +
      //                         context.datasetIndex * 100;
      //                     }
      //                     return delay;
      //                   },
      //                 };
      //               } else if (props.Animation === "hideAndShow") {
      //                 animate = {};
      //                 transition = {
      //                   show: {
      //                     animations: {
      //                       x: {
      //                         from: 0,
      //                       },
      //                       y: {
      //                         from: 0,
      //                       },
      //                     },
      //                   },

      //                   hide: {
      //                     animations: {
      //                       x: {
      //                         to: 0,
      //                       },
      //                       y: {
      //                         to: 0,
      //                       },
      //                     },
      //                   },
      //                 };
      //               }

      //               const data = {
      //                 // x-axis
      //                 labels: xAxis,
      //                 datasets: [
      //                   {
      //                     label: props.Abscissa,
      //                     // backgroundColor: ['red', 'green', 'blue'],
      //                     backgroundColor: !props.Bar_Colors
      //                       ? ["red", "green", "blue"]
      //                       : props.Bar_Colors.split(","),
      //                     borderColor: !props.Border_Color
      //                       ? "rgb(255, 99, 132)"
      //                       : props.Border_Color,
      //                     borderWidth: !props.Border_Width
      //                       ? 2
      //                       : props.Border_Width,
      //                     // y-axis
      //                     data: yAxis,
      //                     // color: ['red', 'green', 'blue'],
      //                   },
      //                 ],
      //               };

      //               const config = {
      //                 type: "bar",
      //                 data: data,
      //                 options: {
      //                   animation: animate,
      //                   transitions: transition,
      //                   stacked: false,
      //                   responsive: true,
      //                 },
      //               };

      //               const ctx = document
      //                 .getElementById("barChart")
      //                 .getContext("2d");
      //               const myChart = new Chart(ctx, config);
      //             } catch (e) {
      //               console.log(e);
      //             }
      //           }
      //         };

      //         if (typeof Chart == "undefined") {
      //           const script = document.createElement("script");
      //           script.onload = initLib;
      //           script.src = "https://cdn.jsdelivr.net/npm/chart.js";
      //           document.body.appendChild(script);
      //         } else {
      //           console.log("InitLib");
      //           initLib();
      //         }
      //       },

      //       Graph_API: "",
      //       Username: "",
      //       Password: "",
      //       API_Key: "",
      //       More_Headers: "", // input should be like: a:b,c:d,e:f

      //       Animation: "",
      //       Abscissa: "",
      //       Ordinate: "",
      //       Bar_Colors: "",
      //       Border_Width: "",
      //       Border_Color: "",
      //       // Fill: "",

      //       traits: [
      //         {
      //           type: "text",
      //           name: "Graph_API",
      //           label: "Graph API",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Username",
      //           label: "Username",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Password",
      //           label: "Password",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "API_Key",
      //           label: "API Key",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "More_Headers",
      //           label: "More Headers",
      //           changeProp: true,
      //         },
      //         {
      //           type: "select",
      //           name: "Animation",
      //           label: "Pre-defined Graph Animations",
      //           options: [
      //             { id: "delay", name: "Delay" },
      //             { id: "hideAndShow", name: "Hide And Show" },
      //           ],
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Abscissa",
      //           label: "Abscissa",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Ordinate",
      //           label: "Ordinates",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Bar_Colors",
      //           label: "Datapoint Colors",
      //           changeProp: true,
      //         },
      //         {
      //           type: "range",
      //           min: "0",
      //           max: "20",
      //           name: "Border_Width",
      //           label: "Border Width [0px to 20px]",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Border_Color",
      //           label: "Border Color",
      //           changeProp: true,
      //         },
      //         // {
      //         //     type: 'checkbox',
      //         //     name: 'Fill',
      //         //     label: 'Fill Area',
      //         //     changeProp: true,
      //         // },
      //       ],

      //       changeProp: true,

      //       "script-props": [
      //         "Graph_API",
      //         "Username",
      //         "Password",
      //         "API_Key",
      //         "More_Headers",
      //         "Animation",
      //         "Abscissa",
      //         "Ordinate",
      //         "Bar_Colors",
      //         "Border_Width",
      //         "Border_Color",
      //       ],
      //     },
      //   },
      //   isComponent: (el) => {
      //     if (
      //       el.getAttribute &&
      //       el.getAttribute("data-gjs-type") == "barChart"
      //     ) {
      //       return "barChart";
      //     }
      //   },
      //   view: defaultView.extend({
      //     init({ model }) {},
      //   }),
      // });

      // editor.DomComponents.addType("bubbleChart", {
      //   model: {
      //     defaults: {
      //       script: function (props) {
      //         const initLib = async function () {
      //           // this helper function recursively scans an object and its nests until it finds the key and returns its value
      //           function findKeyInNestedObject(response, key) {
      //             let values = [];

      //             function traverseObject(obj) {
      //               for (let prop in obj) {
      //                 if (typeof obj[prop] === "object") {
      //                   traverseObject(obj[prop]);
      //                 } else if (Array.isArray(obj[prop])) {
      //                   for (let item of obj[prop]) {
      //                     if (typeof item === "object") {
      //                       traverseObject(item);
      //                     }
      //                   }
      //                 } else if (prop === key) {
      //                   values.push(Number(obj[prop]));
      //                 }
      //               }
      //             }

      //             traverseObject(response);

      //             return values;
      //           }

      //           // this helper function checks if an object has multiple levels of nesting
      //           function isNestedObject(response) {
      //             if (typeof response !== "object") {
      //               return false; // Not an object
      //             }

      //             for (let prop in response) {
      //               if (typeof response[prop] === "object") {
      //                 return true; // Found nested object
      //               }
      //             }

      //             return false; // No nested object found
      //           }

      //           // this helper function checks if there is an array of objects in a response object
      //           function hasArrayofObjects(obj) {
      //             for (let prop in obj) {
      //               if (
      //                 Array.isArray(obj[prop]) &&
      //                 obj[prop].length > 0 &&
      //                 typeof obj[prop][0] === "object"
      //               ) {
      //                 return true; // Array of objects found
      //               }
      //             }
      //             return false; // Array of objects not found
      //           }

      //           let username = props.Username;
      //           let password = props.Password;
      //           let apiKey = props.API_key;
      //           let moreHeaders = props.More_Headers;

      //           let xAxis = [];
      //           let yAxis = [];
      //           let radius = [];

      //           console.log(
      //             "Graph props:",
      //             props,
      //             "\n\nusername:",
      //             username,
      //             "\npassword:",
      //             password,
      //             "\napiKey:",
      //             apiKey,
      //             "\nmoreHeaders:",
      //             moreHeaders
      //           );

      //           if (props.Graph_API) {
      //             try {
      //               // By Invoice URL
      //               // let response = await fetch('http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&INVOICE=AR25634D', {

      //               // By Date URL
      //               // let response = await fetch('http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&DATE=20230102', {

      //               // By Customer URL
      //               // let response = await fetch('http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&CUSTOMER=DENCO%20EQUIPMENTS%20LIMITED', {

      //               // By Coin URL
      //               // let response = await fetch('https://api.coinranking.com/v2/coins', {

      //               // By Month URL
      //               // http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&MONTH=MAR

      //               // when api, username and password is provided but not api key
      //               if (username && password && apiKey === undefined) {
      //                 let apiData;
      //                 let config = {
      //                   headers: {
      //                     Authorization:
      //                       "Basic " + btoa(username + ":" + password),
      //                   },
      //                 };
      //                 // ['a:b', "c:d"]
      //                 // extracting headers from More_Headers trait, if provided
      //                 if (moreHeaders) {
      //                   moreHeaders.split(",").forEach((header) => {
      //                     let key, val;
      //                     [key, val] = header.split(":");
      //                     console.log("[key, val]:\n", key, val);

      //                     config.headers[key] = val;
      //                   });
      //                 }

      //                 console.log("config:", config);

      //                 const response = await fetch(
      //                   `${props.Graph_API}`,
      //                   config
      //                 );

      //                 const responseData = await response.json();
      //                 apiData = responseData;
      //                 console.log(
      //                   "responseData:",
      //                   responseData,
      //                   "\napiData:",
      //                   apiData
      //                 );

      //                 // variables needed later confirming whether the value of the required keys are nested further with objects or not
      //                 let hasNestedArrayOfObjects =
      //                   hasArrayofObjects(apiData);
      //                 let abscissaIsNested = isNestedObject(
      //                   responseData[props.Abscissa]
      //                 );
      //                 let ordinateIsNested = isNestedObject(
      //                   responseData[props.Ordinate]
      //                 );
      //                 let radiusIsNested = isNestedObject(
      //                   responseData[props.Radius]
      //                 );

      //                 // MAKING IT WORK FOR UNIVERSAL API RESPONSES, below;
      //                 // not for response structures - array of arrays, object of array of objects, object of array of arrays

      //                 // checking if the reponse is an ARRAY of OBNECTS; and then creating the coordinates array xAxis, yAxis, radius for plotting
      //                 if (
      //                   Array.isArray(apiData) &&
      //                   apiData.length > 0 &&
      //                   typeof apiData[0] === "object"
      //                 ) {
      //                   for (let objX of apiData) {
      //                     xAxis.push(
      //                       objX[!props.Abscissa ? "" : props.Abscissa]
      //                     );
      //                   }

      //                   for (let objY of apiData) {
      //                     yAxis.push(
      //                       objY[!props.Ordinate ? "" : props.Ordinate]
      //                     );
      //                   }

      //                   for (let objR of apiData) {
      //                     radius.push(
      //                       objR[!props.Radius ? "" : props.Radius]
      //                     );
      //                   }
      //                 }

      //                 // checking if the reponse is an OBJECT but not an array; and then creating the coordinates array xAxis, yAxis, radius for plotting
      //                 else if (
      //                   typeof apiData === "object" &&
      //                   !Array.isArray(apiData)
      //                 ) {
      //                   // now checking if the value of the required keys - Abscissa, Ordinate, Radius (entered in the trait) are an ARRAY also while those traits/values are not 'undefined'
      //                   if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     Array.isArray(apiData[props.Abscissa]) &&
      //                     props.Ordinate &&
      //                     Array.isArray(apiData[props.Ordinate]) &&
      //                     props.Radius &&
      //                     Array.isArray(apiData[props.Radius])
      //                   ) {
      //                     // if the array contains a string amongst numbers, then don't assign it to the coordinate arrays
      //                     let xContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     xAxis = !xContainsString
      //                       ? apiData[props.Abscissa]
      //                       : [];

      //                     let yContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     yAxis = !yContainsString
      //                       ? apiData[props.Ordinate]
      //                       : [];

      //                     let rContainsString = apiData[props.Radius].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     radius = !rContainsString
      //                       ? apiData[props.Radius]
      //                       : [];
      //                   }

      //                   // now checking if the value of the required keys are PRIMITIVE values, neither arrays nor objects also while those traits/values are not 'undefined'
      //                   else if (
      //                     (!hasNestedArrayOfObjects &&
      //                       props.Abscissa &&
      //                       typeof apiData[props.Abscissa] !== "object") ||
      //                     (apiData[props.Abscissa] === null &&
      //                       props.Ordinate &&
      //                       typeof responseData[props.Ordinate] !==
      //                         "object") ||
      //                     (responseData[props.Ordinate] === null &&
      //                       props.Radius &&
      //                       typeof responseData[props.Radius] !== "object") ||
      //                     responseData[props.Radius] === null
      //                   ) {
      //                     xAxis.push(apiData[props.Abscissa]);
      //                     yAxis.push(apiData[props.Ordinate]);
      //                     radius.push(apiData[props.Radius]);
      //                   }

      //                   // now checking if the value of the required keys - Abscissa, Ordinate, Radius (entered in the trait) are again a NESTED OBJECT (using recursive helper function) also while those traits/values are not 'undefined'
      //                   else if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     abscissaIsNested &&
      //                     props.Ordinate &&
      //                     ordinateIsNested &&
      //                     props.Radius &&
      //                     radiusIsNested
      //                   ) {
      //                     console.log("inside 1");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData[props.Abscissa],
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData[props.Ordinate],
      //                       props.Ordinate
      //                     );
      //                     let rValue = findKeyInNestedObject(
      //                       apiData[props.Radius],
      //                       props.Radius
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue) &&
      //                       Array.isArray(rValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                       radius = rValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     else if (
      //                       (xValue &&
      //                         yValue &&
      //                         rValue &&
      //                         !Array.isArray(xValue) &&
      //                         !Array.isArray(yValue) &&
      //                         !Array.isArray(rValue) &&
      //                         typeof xValue !== "object") ||
      //                       (xValue === null && typeof yValue !== "object") ||
      //                       (yValue === null && typeof rValue !== "object") ||
      //                       rValue === null
      //                     ) {
      //                       xAxis.push(xValue);
      //                       yAxis.push(yValue);
      //                       radius.push(rValue);
      //                     }
      //                   }

      //                   // now checking if the value of the response is nested also while those traits/values are not 'undefined'
      //                   else if (
      //                     props.Abscissa &&
      //                     props.Ordinate &&
      //                     props.Radius &&
      //                     hasNestedArrayOfObjects
      //                   ) {
      //                     console.log("inside 2");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Ordinate
      //                     );
      //                     let rValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Radius
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                       radius = rValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     // else if (xValue && yValue && rValue && !Array.isArray(xValue) && !Array.isArray(yValue) && !Array.isArray(rValue) && typeof xValue !== 'object' || xValue === null && typeof yValue !== 'object' || yValue === null && typeof rValue !== 'object' || rValue === null && xValue !== 'NaN' && yValue !== 'NaN' && rValue !== 'NaN') {
      //                     else if (
      //                       typeof Number(xValue) === "number" &&
      //                       typeof Number(yValue) === "number" &&
      //                       typeof Number(rValue) === "number"
      //                     ) {
      //                       xAxis.push(Number(xValue));
      //                       yAxis.push(Number(yValue));
      //                       radius.push(Number(rValue));
      //                     }
      //                   }
      //                 }
      //               }

      //               // when api and api key is provided but not username and password
      //               else if (!username && !password && apiKey !== undefined) {
      //                 let apiData;
      //                 let config = {
      //                   headers: {
      //                     "X-API-KEY": `${apiKey}`,
      //                   },
      //                 };
      //                 // ['a:b', "c:d"]
      //                 // extracting headers from More_Headers trait
      //                 if (moreHeaders) {
      //                   moreHeaders.split(",").forEach((header) => {
      //                     let key, val;
      //                     [key, val] = header.split(":");
      //                     console.log("[key, val]:\n", key, val);

      //                     config.headers[key] = val;
      //                   });
      //                 }

      //                 console.log("config:", config);

      //                 let response = await fetch(
      //                   `${props.Graph_API}`,
      //                   config
      //                 );

      //                 let responseData = await response.json();
      //                 apiData = responseData;
      //                 console.log(
      //                   "responseData:",
      //                   responseData,
      //                   "\napiData:",
      //                   apiData
      //                 );

      //                 // variables needed later confirming whether the value of the required keys are nested further with objects or not
      //                 let hasNestedArrayOfObjects =
      //                   hasArrayofObjects(apiData);
      //                 let abscissaIsNested = isNestedObject(
      //                   responseData[props.Abscissa]
      //                 );
      //                 let ordinateIsNested = isNestedObject(
      //                   responseData[props.Ordinate]
      //                 );
      //                 let radiusIsNested = isNestedObject(
      //                   responseData[props.Radius]
      //                 );

      //                 // MAKING IT WORK FOR UNIVERSAL API RESPONSES, below;
      //                 // not for response structures - array of arrays, object of array of objects, object of array of arrays

      //                 // checking if the reponse is an ARRAY of OBNECTS; and then creating the coordinates array xAxis, yAxis, radius for plotting
      //                 if (
      //                   Array.isArray(apiData) &&
      //                   apiData.length > 0 &&
      //                   typeof apiData[0] === "object"
      //                 ) {
      //                   for (let objX of apiData) {
      //                     xAxis.push(
      //                       objX[!props.Abscissa ? "" : props.Abscissa]
      //                     );
      //                   }

      //                   for (let objY of apiData) {
      //                     yAxis.push(
      //                       objY[!props.Ordinate ? "" : props.Ordinate]
      //                     );
      //                   }

      //                   for (let objR of apiData) {
      //                     radius.push(
      //                       objR[!props.Radius ? "" : props.Radius]
      //                     );
      //                   }
      //                 }

      //                 // checking if the reponse is an OBJECT but not an array; and then creating the coordinates array xAxis, yAxis, radius for plotting
      //                 else if (
      //                   typeof apiData === "object" &&
      //                   !Array.isArray(apiData)
      //                 ) {
      //                   // now checking if the value of the required keys - Abscissa, Ordinate, Radius (entered in the trait) are an ARRAY also while those traits/values are not 'undefined'
      //                   if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     Array.isArray(apiData[props.Abscissa]) &&
      //                     props.Ordinate &&
      //                     Array.isArray(apiData[props.Ordinate]) &&
      //                     props.Radius &&
      //                     Array.isArray(apiData[props.Radius])
      //                   ) {
      //                     // if the array contains a string amongst numbers, then don't assign it to the coordinate arrays
      //                     let xContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     xAxis = !xContainsString
      //                       ? apiData[props.Abscissa]
      //                       : [];

      //                     let yContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     yAxis = !yContainsString
      //                       ? apiData[props.Ordinate]
      //                       : [];

      //                     let rContainsString = apiData[props.Radius].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     radius = !rContainsString
      //                       ? apiData[props.Radius]
      //                       : [];
      //                   }

      //                   // now checking if the value of the required keys are PRIMITIVE values, neither arrays nor objects also while those traits/values are not 'undefined'
      //                   else if (
      //                     (!hasNestedArrayOfObjects &&
      //                       props.Abscissa &&
      //                       typeof apiData[props.Abscissa] !== "object") ||
      //                     (apiData[props.Abscissa] === null &&
      //                       props.Ordinate &&
      //                       typeof responseData[props.Ordinate] !==
      //                         "object") ||
      //                     (responseData[props.Ordinate] === null &&
      //                       props.Radius &&
      //                       typeof responseData[props.Radius] !== "object") ||
      //                     responseData[props.Radius] === null
      //                   ) {
      //                     xAxis.push(apiData[props.Abscissa]);
      //                     yAxis.push(apiData[props.Ordinate]);
      //                     radius.push(apiData[props.Radius]);
      //                   }

      //                   // now checking if the value of the required keys - Abscissa, Ordinate, Radius (entered in the trait) are again a NESTED OBJECT (using recursive helper function) also while those traits/values are not 'undefined'
      //                   else if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     abscissaIsNested &&
      //                     props.Ordinate &&
      //                     ordinateIsNested &&
      //                     props.Radius &&
      //                     radiusIsNested
      //                   ) {
      //                     console.log("inside 1");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData[props.Abscissa],
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData[props.Ordinate],
      //                       props.Ordinate
      //                     );
      //                     let rValue = findKeyInNestedObject(
      //                       apiData[props.Radius],
      //                       props.Radius
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue) &&
      //                       Array.isArray(rValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                       radius = rValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     else if (
      //                       (xValue &&
      //                         yValue &&
      //                         rValue &&
      //                         !Array.isArray(xValue) &&
      //                         !Array.isArray(yValue) &&
      //                         !Array.isArray(rValue) &&
      //                         typeof xValue !== "object") ||
      //                       (xValue === null && typeof yValue !== "object") ||
      //                       (yValue === null && typeof rValue !== "object") ||
      //                       rValue === null
      //                     ) {
      //                       xAxis.push(xValue);
      //                       yAxis.push(yValue);
      //                       radius.push(rValue);
      //                     }
      //                   }

      //                   // now checking if the value of the response is nested also while those traits/values are not 'undefined'
      //                   else if (
      //                     props.Abscissa &&
      //                     props.Ordinate &&
      //                     props.Radius &&
      //                     hasNestedArrayOfObjects
      //                   ) {
      //                     console.log("inside 2");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Ordinate
      //                     );
      //                     let rValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Radius
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                       radius = rValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     // else if (xValue && yValue && rValue && !Array.isArray(xValue) && !Array.isArray(yValue) && !Array.isArray(rValue) && typeof xValue !== 'object' || xValue === null && typeof yValue !== 'object' || yValue === null && typeof rValue !== 'object' || rValue === null && xValue !== 'NaN' && yValue !== 'NaN' && rValue !== 'NaN') {
      //                     else if (
      //                       typeof Number(xValue) === "number" &&
      //                       typeof Number(yValue) === "number" &&
      //                       typeof Number(rValue) === "number"
      //                     ) {
      //                       xAxis.push(Number(xValue));
      //                       yAxis.push(Number(yValue));
      //                       radius.push(Number(rValue));
      //                     }
      //                   }
      //                 }
      //               }

      //               // when everything is provided
      //               else if (username && password && apiKey !== undefined) {
      //                 let apiData;
      //                 let config = {
      //                   headers: {
      //                     Authorization:
      //                       "Basic " + btoa(username + ":" + password),
      //                     "X-API-KEY": `${apiKey}`,
      //                   },
      //                 };
      //                 // ['a:b', "c:d"]
      //                 // extracting headers from More_Headers trait
      //                 if (moreHeaders) {
      //                   moreHeaders.split(",").forEach((header) => {
      //                     let key, val;
      //                     [key, val] = header.split(":");
      //                     console.log("[key, val]:\n", key, val);

      //                     config.headers[key] = val;
      //                   });
      //                 }

      //                 console.log("config:", config);

      //                 let response = await fetch(
      //                   `${props.Graph_API}`,
      //                   config
      //                 );

      //                 let responseData = await response.json();
      //                 apiData = responseData;
      //                 console.log(
      //                   "responseData:",
      //                   responseData,
      //                   "\napiData:",
      //                   apiData
      //                 );

      //                 // variables needed later confirming whether the value of the required keys are nested further with objects or not
      //                 let hasNestedArrayOfObjects =
      //                   hasArrayofObjects(apiData);
      //                 let abscissaIsNested = isNestedObject(
      //                   responseData[props.Abscissa]
      //                 );
      //                 let ordinateIsNested = isNestedObject(
      //                   responseData[props.Ordinate]
      //                 );
      //                 let radiusIsNested = isNestedObject(
      //                   responseData[props.Radius]
      //                 );

      //                 // MAKING IT WORK FOR UNIVERSAL API RESPONSES, below;
      //                 // not for response structures - array of arrays, object of array of objects, object of array of arrays

      //                 // checking if the reponse is an ARRAY of OBNECTS; and then creating the coordinates array xAxis, yAxis, radius for plotting
      //                 if (
      //                   Array.isArray(apiData) &&
      //                   apiData.length > 0 &&
      //                   typeof apiData[0] === "object"
      //                 ) {
      //                   for (let objX of apiData) {
      //                     xAxis.push(
      //                       objX[!props.Abscissa ? "" : props.Abscissa]
      //                     );
      //                   }

      //                   for (let objY of apiData) {
      //                     yAxis.push(
      //                       objY[!props.Ordinate ? "" : props.Ordinate]
      //                     );
      //                   }

      //                   for (let objR of apiData) {
      //                     radius.push(
      //                       objR[!props.Radius ? "" : props.Radius]
      //                     );
      //                   }
      //                 }

      //                 // checking if the reponse is an OBJECT but not an array; and then creating the coordinates array xAxis, yAxis, radius for plotting
      //                 else if (
      //                   typeof apiData === "object" &&
      //                   !Array.isArray(apiData)
      //                 ) {
      //                   // now checking if the value of the required keys - Abscissa, Ordinate, Radius (entered in the trait) are an ARRAY also while those traits/values are not 'undefined'
      //                   if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     Array.isArray(apiData[props.Abscissa]) &&
      //                     props.Ordinate &&
      //                     Array.isArray(apiData[props.Ordinate]) &&
      //                     props.Radius &&
      //                     Array.isArray(apiData[props.Radius])
      //                   ) {
      //                     // if the array contains a string amongst numbers, then don't assign it to the coordinate arrays
      //                     let xContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     xAxis = !xContainsString
      //                       ? apiData[props.Abscissa]
      //                       : [];

      //                     let yContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     yAxis = !yContainsString
      //                       ? apiData[props.Ordinate]
      //                       : [];

      //                     let rContainsString = apiData[props.Radius].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     radius = !rContainsString
      //                       ? apiData[props.Radius]
      //                       : [];
      //                   }

      //                   // now checking if the value of the required keys are PRIMITIVE values, neither arrays nor objects also while those traits/values are not 'undefined'
      //                   else if (
      //                     (!hasNestedArrayOfObjects &&
      //                       props.Abscissa &&
      //                       typeof apiData[props.Abscissa] !== "object") ||
      //                     (apiData[props.Abscissa] === null &&
      //                       props.Ordinate &&
      //                       typeof responseData[props.Ordinate] !==
      //                         "object") ||
      //                     (responseData[props.Ordinate] === null &&
      //                       props.Radius &&
      //                       typeof responseData[props.Radius] !== "object") ||
      //                     responseData[props.Radius] === null
      //                   ) {
      //                     xAxis.push(apiData[props.Abscissa]);
      //                     yAxis.push(apiData[props.Ordinate]);
      //                     radius.push(apiData[props.Radius]);
      //                   }

      //                   // now checking if the value of the required keys - Abscissa, Ordinate, Radius (entered in the trait) are again a NESTED OBJECT (using recursive helper function) also while those traits/values are not 'undefined'
      //                   else if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     abscissaIsNested &&
      //                     props.Ordinate &&
      //                     ordinateIsNested &&
      //                     props.Radius &&
      //                     radiusIsNested
      //                   ) {
      //                     console.log("inside 1");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData[props.Abscissa],
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData[props.Ordinate],
      //                       props.Ordinate
      //                     );
      //                     let rValue = findKeyInNestedObject(
      //                       apiData[props.Radius],
      //                       props.Radius
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue) &&
      //                       Array.isArray(rValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                       radius = rValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     else if (
      //                       (xValue &&
      //                         yValue &&
      //                         rValue &&
      //                         !Array.isArray(xValue) &&
      //                         !Array.isArray(yValue) &&
      //                         !Array.isArray(rValue) &&
      //                         typeof xValue !== "object") ||
      //                       (xValue === null && typeof yValue !== "object") ||
      //                       (yValue === null && typeof rValue !== "object") ||
      //                       rValue === null
      //                     ) {
      //                       xAxis.push(xValue);
      //                       yAxis.push(yValue);
      //                       radius.push(rValue);
      //                     }
      //                   }

      //                   // now checking if the value of the response is nested also while those traits/values are not 'undefined'
      //                   else if (
      //                     props.Abscissa &&
      //                     props.Ordinate &&
      //                     props.Radius &&
      //                     hasNestedArrayOfObjects
      //                   ) {
      //                     console.log("inside 2");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Ordinate
      //                     );
      //                     let rValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Radius
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                       radius = rValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     // else if (xValue && yValue && rValue && !Array.isArray(xValue) && !Array.isArray(yValue) && !Array.isArray(rValue) && typeof xValue !== 'object' || xValue === null && typeof yValue !== 'object' || yValue === null && typeof rValue !== 'object' || rValue === null && xValue !== 'NaN' && yValue !== 'NaN' && rValue !== 'NaN') {
      //                     else if (
      //                       typeof Number(xValue) === "number" &&
      //                       typeof Number(yValue) === "number" &&
      //                       typeof Number(rValue) === "number"
      //                     ) {
      //                       xAxis.push(Number(xValue));
      //                       yAxis.push(Number(yValue));
      //                       radius.push(Number(rValue));
      //                     }
      //                   }
      //                 }
      //               }

      //               // when api is provided but nothing else is provided
      //               else if (!username && !password && apiKey === undefined) {
      //                 let apiData;
      //                 let config = {
      //                   headers: {},
      //                 };
      //                 // ['a:b', "c:d"]
      //                 // extracting headers from More_Headers trait
      //                 if (moreHeaders) {
      //                   moreHeaders.split(",").forEach((header) => {
      //                     let key, val;
      //                     [key, val] = header.split(":");
      //                     console.log("[key, val]:\n", key, val);

      //                     config.headers[key] = val;
      //                   });
      //                 }

      //                 console.log("config:", config);

      //                 let response = await fetch(
      //                   `${props.Graph_API}`,
      //                   config
      //                 );

      //                 let responseData = await response.json();
      //                 apiData = responseData;
      //                 console.log(
      //                   "responseData:",
      //                   responseData,
      //                   "\napiData:",
      //                   apiData
      //                 );

      //                 // variables needed later confirming whether the value of the required keys are nested further with objects or not
      //                 let hasNestedArrayOfObjects =
      //                   hasArrayofObjects(apiData);
      //                 let abscissaIsNested = isNestedObject(
      //                   responseData[props.Abscissa]
      //                 );
      //                 let ordinateIsNested = isNestedObject(
      //                   responseData[props.Ordinate]
      //                 );
      //                 let radiusIsNested = isNestedObject(
      //                   responseData[props.Radius]
      //                 );

      //                 // MAKING IT WORK FOR UNIVERSAL API RESPONSES, below;
      //                 // not for response structures - array of arrays, object of array of objects, object of array of arrays

      //                 // checking if the reponse is an ARRAY of OBNECTS; and then creating the coordinates array xAxis, yAxis, radius for plotting
      //                 if (
      //                   Array.isArray(apiData) &&
      //                   apiData.length > 0 &&
      //                   typeof apiData[0] === "object"
      //                 ) {
      //                   for (let objX of apiData) {
      //                     xAxis.push(
      //                       objX[!props.Abscissa ? "" : props.Abscissa]
      //                     );
      //                   }

      //                   for (let objY of apiData) {
      //                     yAxis.push(
      //                       objY[!props.Ordinate ? "" : props.Ordinate]
      //                     );
      //                   }

      //                   for (let objR of apiData) {
      //                     radius.push(
      //                       objR[!props.Radius ? "" : props.Radius]
      //                     );
      //                   }
      //                 }

      //                 // checking if the reponse is an OBJECT but not an array; and then creating the coordinates array xAxis, yAxis, radius for plotting
      //                 else if (
      //                   typeof apiData === "object" &&
      //                   !Array.isArray(apiData)
      //                 ) {
      //                   // now checking if the value of the required keys - Abscissa, Ordinate, Radius (entered in the trait) are an ARRAY also while those traits/values are not 'undefined'
      //                   if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     Array.isArray(apiData[props.Abscissa]) &&
      //                     props.Ordinate &&
      //                     Array.isArray(apiData[props.Ordinate]) &&
      //                     props.Radius &&
      //                     Array.isArray(apiData[props.Radius])
      //                   ) {
      //                     // if the array contains a string amongst numbers, then don't assign it to the coordinate arrays
      //                     let xContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     xAxis = !xContainsString
      //                       ? apiData[props.Abscissa]
      //                       : [];

      //                     let yContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     yAxis = !yContainsString
      //                       ? apiData[props.Ordinate]
      //                       : [];

      //                     let rContainsString = apiData[props.Radius].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     radius = !rContainsString
      //                       ? apiData[props.Radius]
      //                       : [];
      //                   }

      //                   // now checking if the value of the required keys are PRIMITIVE values, neither arrays nor objects also while those traits/values are not 'undefined'
      //                   else if (
      //                     (!hasNestedArrayOfObjects &&
      //                       props.Abscissa &&
      //                       typeof apiData[props.Abscissa] !== "object") ||
      //                     (apiData[props.Abscissa] === null &&
      //                       props.Ordinate &&
      //                       typeof responseData[props.Ordinate] !==
      //                         "object") ||
      //                     (responseData[props.Ordinate] === null &&
      //                       props.Radius &&
      //                       typeof responseData[props.Radius] !== "object") ||
      //                     responseData[props.Radius] === null
      //                   ) {
      //                     xAxis.push(apiData[props.Abscissa]);
      //                     yAxis.push(apiData[props.Ordinate]);
      //                     radius.push(apiData[props.Radius]);
      //                   }

      //                   // now checking if the value of the required keys - Abscissa, Ordinate, Radius (entered in the trait) are again a NESTED OBJECT (using recursive helper function) also while those traits/values are not 'undefined'
      //                   else if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     abscissaIsNested &&
      //                     props.Ordinate &&
      //                     ordinateIsNested &&
      //                     props.Radius &&
      //                     radiusIsNested
      //                   ) {
      //                     console.log("inside 1");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData[props.Abscissa],
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData[props.Ordinate],
      //                       props.Ordinate
      //                     );
      //                     let rValue = findKeyInNestedObject(
      //                       apiData[props.Radius],
      //                       props.Radius
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue) &&
      //                       Array.isArray(rValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                       radius = rValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     else if (
      //                       (xValue &&
      //                         yValue &&
      //                         rValue &&
      //                         !Array.isArray(xValue) &&
      //                         !Array.isArray(yValue) &&
      //                         !Array.isArray(rValue) &&
      //                         typeof xValue !== "object") ||
      //                       (xValue === null && typeof yValue !== "object") ||
      //                       (yValue === null && typeof rValue !== "object") ||
      //                       rValue === null
      //                     ) {
      //                       xAxis.push(xValue);
      //                       yAxis.push(yValue);
      //                       radius.push(rValue);
      //                     }
      //                   }

      //                   // now checking if the value of the response is nested also while those traits/values are not 'undefined'
      //                   else if (
      //                     props.Abscissa &&
      //                     props.Ordinate &&
      //                     props.Radius &&
      //                     hasNestedArrayOfObjects
      //                   ) {
      //                     console.log("inside 2");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Ordinate
      //                     );
      //                     let rValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Radius
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                       radius = rValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     // else if (xValue && yValue && rValue && !Array.isArray(xValue) && !Array.isArray(yValue) && !Array.isArray(rValue) && typeof xValue !== 'object' || xValue === null && typeof yValue !== 'object' || yValue === null && typeof rValue !== 'object' || rValue === null && xValue !== 'NaN' && yValue !== 'NaN' && rValue !== 'NaN') {
      //                     else if (
      //                       typeof Number(xValue) === "number" &&
      //                       typeof Number(yValue) === "number" &&
      //                       typeof Number(rValue) === "number"
      //                     ) {
      //                       xAxis.push(Number(xValue));
      //                       yAxis.push(Number(yValue));
      //                       radius.push(Number(rValue));
      //                     }
      //                   }
      //                 }
      //               }

      //               console.log(
      //                 "xAxis:",
      //                 xAxis,
      //                 "\nyAxis:",
      //                 yAxis,
      //                 "\nradius:",
      //                 radius
      //               );

      //               // const labels = [
      //               //   "January",
      //               //   "February",
      //               //   "March",
      //               //   "April",
      //               //   "May",
      //               //   "June",
      //               // ];

      //               // animations
      //               let animate;
      //               let transition;
      //               let delayed;

      //               if (props.Animation === "delay") {
      //                 animate = {
      //                   onComplete: () => {
      //                     delayed = true;
      //                   },

      //                   delay: (context) => {
      //                     let delay = 0;
      //                     if (
      //                       context.type === "data" &&
      //                       context.mode === "default" &&
      //                       !delayed
      //                     ) {
      //                       delay =
      //                         context.dataIndex * 300 +
      //                         context.datasetIndex * 100;
      //                     }
      //                     return delay;
      //                   },
      //                 };
      //               } else if (props.Animation === "hideAndShow") {
      //                 animate = {};
      //                 transition = {
      //                   show: {
      //                     animations: {
      //                       x: {
      //                         from: 0,
      //                       },
      //                       y: {
      //                         from: 0,
      //                       },
      //                     },
      //                   },

      //                   hide: {
      //                     animations: {
      //                       x: {
      //                         to: 0,
      //                       },
      //                       y: {
      //                         to: 0,
      //                       },
      //                     },
      //                   },
      //                 };
      //               }

      //               // to populate data of dataset of bubble graph
      //               let bubbleData = [];

      //               for (let i = 0; i < xAxis.length; i++) {
      //                 xAxis.length > 0 &&
      //                   yAxis.length > 0 &&
      //                   radius.length > 0 &&
      //                   bubbleData.push({
      //                     x: xAxis[i],
      //                     y: yAxis[i],
      //                     r: radius[i],
      //                   });
      //               }

      //               console.log("bubbleData:", bubbleData);

      //               const data = {
      //                 datasets: [
      //                   {
      //                     label: !props.Label ? "Bubble Chart" : props.Label,
      //                     // backgroundColor: ['red', 'green', 'blue'],
      //                     backgroundColor: !props.Bar_Colors
      //                       ? ["red", "green", "blue"]
      //                       : props.Bar_Colors.split(","),
      //                     borderColor: !props.Border_Color
      //                       ? "rgb(255, 99, 132)"
      //                       : props.Border_Color,
      //                     borderWidth: !props.Border_Width
      //                       ? 2
      //                       : props.Border_Width,
      //                     data: bubbleData,
      //                   },
      //                 ],
      //               };

      //               const config = {
      //                 type: "bubble",
      //                 data: data,
      //                 options: {
      //                   animation: animate,
      //                   transitions: transition,
      //                 },
      //               };

      //               const ctx = document
      //                 .getElementById("bubbleChart")
      //                 .getContext("2d");
      //               const myChart = new Chart(ctx, config);
      //             } catch (e) {
      //               console.log(e);
      //             }
      //           }
      //         };

      //         if (typeof Chart == "undefined") {
      //           const script = document.createElement("script");
      //           script.onload = initLib;
      //           script.src = "https://cdn.jsdelivr.net/npm/chart.js";
      //           document.body.appendChild(script);
      //         } else {
      //           console.log("InitLib");
      //           initLib();
      //         }
      //       },

      //       Graph_API: "",
      //       Username: "",
      //       Password: "",
      //       API_Key: "",
      //       More_Headers: "", // input should be like: a:b,c:d,e:f

      //       Animation: "",
      //       Label: "",
      //       Abscissa: "",
      //       Ordinate: "",
      //       Radius: "",
      //       Bar_Colors: "",
      //       Border_Width: "",
      //       Border_Color: "",

      //       traits: [
      //         {
      //           type: "text",
      //           name: "Graph_API",
      //           label: "Graph API",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Username",
      //           label: "Username",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Password",
      //           label: "Password",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "API_Key",
      //           label: "API Key",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "More_Headers",
      //           label: "More Headers",
      //           changeProp: true,
      //         },
      //         {
      //           type: "select",
      //           name: "Animation",
      //           label: "Pre-defined Graph Animations",
      //           options: [
      //             { id: "delay", name: "Delay" },
      //             { id: "hideAndShow", name: "Hide And Show" },
      //           ],
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Abscissa",
      //           label: "Abscissa",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Ordinate",
      //           label: "Ordinates",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Radius",
      //           label: "Radius",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Bar_Colors",
      //           label: "Datapoint Colors",
      //           changeProp: true,
      //         },
      //         {
      //           type: "range",
      //           min: "0",
      //           max: "20",
      //           name: "Border_Width",
      //           label: "Border Width [0px to 20px]",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Border_Color",
      //           label: "Border Color",
      //           changeProp: true,
      //         },
      //       ],

      //       changeProp: true,

      //       "script-props": [
      //         "Graph_API",
      //         "Username",
      //         "Password",
      //         "API_Key",
      //         "More_Headers",
      //         "Animation",
      //         "Abscissa",
      //         "Ordinate",
      //         "Radius",
      //         "Bar_Colors",
      //         "Border_Width",
      //         "Border_Color",
      //       ],
      //     },
      //   },
      //   isComponent: (el) => {
      //     if (
      //       el.getAttribute &&
      //       el.getAttribute("data-gjs-type") == "bubbleChart"
      //     ) {
      //       return "bubbleChart";
      //     }
      //   },
      //   view: defaultView.extend({
      //     init({ model }) {},
      //   }),
      // });

      // editor.DomComponents.addType("doughnutChart", {
      //   model: {
      //     defaults: {
      //       script: function (props) {
      //         const initLib = async function () {
      //           // this helper function recursively scans an object and its nests until it finds the key and returns its value
      //           function findKeyInNestedObject(response, key) {
      //             let values = [];

      //             function traverseObject(obj) {
      //               for (let prop in obj) {
      //                 if (typeof obj[prop] === "object") {
      //                   traverseObject(obj[prop]);
      //                 } else if (Array.isArray(obj[prop])) {
      //                   for (let item of obj[prop]) {
      //                     if (typeof item === "object") {
      //                       traverseObject(item);
      //                     }
      //                   }
      //                 } else if (prop === key) {
      //                   values.push(Number(obj[prop]));
      //                 }
      //               }
      //             }

      //             traverseObject(response);

      //             return values;
      //           }

      //           // this helper function checks if an object has multiple levels of nesting
      //           function isNestedObject(response) {
      //             if (typeof response !== "object") {
      //               return false; // Not an object
      //             }

      //             for (let prop in response) {
      //               if (typeof response[prop] === "object") {
      //                 return true; // Found nested object
      //               }
      //             }

      //             return false; // No nested object found
      //           }

      //           // this helper function checks if there is an array of objects in a response object
      //           function hasArrayofObjects(obj) {
      //             for (let prop in obj) {
      //               if (
      //                 Array.isArray(obj[prop]) &&
      //                 obj[prop].length > 0 &&
      //                 typeof obj[prop][0] === "object"
      //               ) {
      //                 return true; // Array of objects found
      //               }
      //             }
      //             return false; // Array of objects not found
      //           }

      //           let username = props.Username;
      //           let password = props.Password;
      //           let apiKey = props.API_key;
      //           let moreHeaders = props.More_Headers;

      //           let xAxis = [];
      //           let yAxis = [];

      //           console.log(
      //             "Graph props:",
      //             props,
      //             "\n\nusername:",
      //             username,
      //             "\npassword:",
      //             password,
      //             "\napiKey:",
      //             apiKey,
      //             "\nmoreHeaders:",
      //             moreHeaders
      //           );

      //           if (props.Graph_API) {
      //             try {
      //               // By Invoice URL
      //               // let response = await fetch('http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&INVOICE=AR25634D', {

      //               // By Date URL
      //               // let response = await fetch('http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&DATE=20230102', {

      //               // By Customer URL
      //               // let response = await fetch('http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&CUSTOMER=DENCO%20EQUIPMENTS%20LIMITED', {

      //               // By Coin URL
      //               // let response = await fetch('https://api.coinranking.com/v2/coins', {

      //               // By Month URL
      //               // http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&MONTH=MAR

      //               // when api, username and password is provided but not api key
      //               if (username && password && apiKey === undefined) {
      //                 let apiData;
      //                 let config = {
      //                   headers: {
      //                     Authorization:
      //                       "Basic " + btoa(username + ":" + password),
      //                   },
      //                 };
      //                 // ['a:b', "c:d"]
      //                 // extracting headers from More_Headers trait, if provided
      //                 if (moreHeaders) {
      //                   moreHeaders.split(",").forEach((header) => {
      //                     let key, val;
      //                     [key, val] = header.split(":");
      //                     console.log("[key, val]:\n", key, val);

      //                     config.headers[key] = val;
      //                   });
      //                 }

      //                 console.log("config:", config);

      //                 const response = await fetch(
      //                   `${props.Graph_API}`,
      //                   config
      //                 );

      //                 const responseData = await response.json();
      //                 apiData = responseData;
      //                 console.log(
      //                   "responseData:",
      //                   responseData,
      //                   "\napiData:",
      //                   apiData
      //                 );

      //                 // variables needed later confirming whether the value of the required keys are nested further with objects or not
      //                 let hasNestedArrayOfObjects =
      //                   hasArrayofObjects(apiData);
      //                 let abscissaIsNested = isNestedObject(
      //                   responseData[props.Abscissa]
      //                 );
      //                 let ordinateIsNested = isNestedObject(
      //                   responseData[props.Ordinate]
      //                 );

      //                 // MAKING IT WORK FOR UNIVERSAL API RESPONSES, below;
      //                 // not for response structures - array of arrays, object of array of objects, object of array of arrays

      //                 // checking if the reponse is an ARRAY of OBNECTS; and then creating the coordinates array xAxis, yAxis for plotting
      //                 if (
      //                   Array.isArray(apiData) &&
      //                   apiData.length > 0 &&
      //                   typeof apiData[0] === "object"
      //                 ) {
      //                   for (let objX of apiData) {
      //                     xAxis.push(
      //                       objX[!props.Abscissa ? "" : props.Abscissa]
      //                     );
      //                   }

      //                   for (let objY of apiData) {
      //                     yAxis.push(
      //                       objY[!props.Ordinate ? "" : props.Ordinate]
      //                     );
      //                   }
      //                 }

      //                 // checking if the reponse is an OBJECT but not an array; and then creating the coordinates array xAxis, yAxis for plotting
      //                 else if (
      //                   typeof apiData === "object" &&
      //                   !Array.isArray(apiData)
      //                 ) {
      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are an ARRAY also while those traits/values are not 'undefined'
      //                   if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     Array.isArray(apiData[props.Abscissa]) &&
      //                     props.Ordinate &&
      //                     Array.isArray(apiData[props.Ordinate])
      //                   ) {
      //                     // if the array contains a string amongst numbers, then don't assign it to the coordinate arrays
      //                     let xContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     xAxis = !xContainsString
      //                       ? apiData[props.Abscissa]
      //                       : [];

      //                     let yContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     yAxis = !yContainsString
      //                       ? apiData[props.Ordinate]
      //                       : [];
      //                   }

      //                   // now checking if the value of the required keys are PRIMITIVE values, neither arrays nor objects also while those traits/values are not 'undefined'
      //                   else if (
      //                     (!hasNestedArrayOfObjects &&
      //                       props.Abscissa &&
      //                       typeof apiData[props.Abscissa] !== "object") ||
      //                     (apiData[props.Abscissa] === null &&
      //                       props.Ordinate &&
      //                       typeof responseData[props.Ordinate] !==
      //                         "object") ||
      //                     responseData[props.Ordinate] === null
      //                   ) {
      //                     xAxis.push(apiData[props.Abscissa]);
      //                     yAxis.push(apiData[props.Ordinate]);
      //                   }

      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are again a NESTED OBJECT (using recursive helper function) also while those traits/values are not 'undefined'
      //                   else if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     abscissaIsNested &&
      //                     props.Ordinate &&
      //                     ordinateIsNested
      //                   ) {
      //                     console.log("inside 1");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData[props.Abscissa],
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData[props.Ordinate],
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     else if (
      //                       (xValue &&
      //                         yValue &&
      //                         !Array.isArray(xValue) &&
      //                         !Array.isArray(yValue) &&
      //                         typeof xValue !== "object") ||
      //                       (xValue === null && typeof yValue !== "object") ||
      //                       yValue === null
      //                     ) {
      //                       xAxis.push(xValue);
      //                       yAxis.push(yValue);
      //                     }
      //                   }

      //                   // now checking if the value of the response is nested also while those traits/values are not 'undefined'
      //                   else if (
      //                     props.Abscissa &&
      //                     props.Ordinate &&
      //                     hasNestedArrayOfObjects
      //                   ) {
      //                     console.log("inside 2");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     // else if (xValue && yValue && !Array.isArray(xValue) && !Array.isArray(yValue) && typeof xValue !== 'object' || xValue === null && typeof yValue !== 'object' || yValue === null && xValue !== 'NaN' && yValue !== 'NaN') {
      //                     else if (
      //                       typeof Number(xValue) === "number" &&
      //                       typeof Number(yValue) === "number"
      //                     ) {
      //                       xAxis.push(Number(xValue));
      //                       yAxis.push(Number(yValue));
      //                     }
      //                   }
      //                 }
      //               }

      //               // when api and api key is provided but not username and password
      //               else if (!username && !password && apiKey !== undefined) {
      //                 let apiData;
      //                 let config = {
      //                   headers: {
      //                     "X-API-KEY": `${apiKey}`,
      //                   },
      //                 };
      //                 // ['a:b', "c:d"]
      //                 // extracting headers from More_Headers trait
      //                 if (moreHeaders) {
      //                   moreHeaders.split(",").forEach((header) => {
      //                     let key, val;
      //                     [key, val] = header.split(":");
      //                     console.log("[key, val]:\n", key, val);

      //                     config.headers[key] = val;
      //                   });
      //                 }

      //                 console.log("config:", config);

      //                 let response = await fetch(
      //                   `${props.Graph_API}`,
      //                   config
      //                 );

      //                 let responseData = await response.json();
      //                 apiData = responseData;
      //                 console.log(
      //                   "responseData:",
      //                   responseData,
      //                   "\napiData:",
      //                   apiData
      //                 );

      //                 // variables needed later confirming whether the value of the required keys are nested further with objects or not
      //                 let hasNestedArrayOfObjects =
      //                   hasArrayofObjects(apiData);
      //                 let abscissaIsNested = isNestedObject(
      //                   responseData[props.Abscissa]
      //                 );
      //                 let ordinateIsNested = isNestedObject(
      //                   responseData[props.Ordinate]
      //                 );

      //                 // MAKING IT WORK FOR UNIVERSAL API RESPONSES, below;
      //                 // not for response structures - array of arrays, object of array of objects, object of array of arrays

      //                 // checking if the reponse is an ARRAY of OBNECTS; and then creating the coordinates array xAxis, yAxis for plotting
      //                 if (
      //                   Array.isArray(apiData) &&
      //                   apiData.length > 0 &&
      //                   typeof apiData[0] === "object"
      //                 ) {
      //                   for (let objX of apiData) {
      //                     xAxis.push(
      //                       objX[!props.Abscissa ? "" : props.Abscissa]
      //                     );
      //                   }

      //                   for (let objY of apiData) {
      //                     yAxis.push(
      //                       objY[!props.Ordinate ? "" : props.Ordinate]
      //                     );
      //                   }
      //                 }

      //                 // checking if the reponse is an OBJECT but not an array; and then creating the coordinates array xAxis, yAxis for plotting
      //                 else if (
      //                   typeof apiData === "object" &&
      //                   !Array.isArray(apiData)
      //                 ) {
      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are an ARRAY also while those traits/values are not 'undefined'
      //                   if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     Array.isArray(apiData[props.Abscissa]) &&
      //                     props.Ordinate &&
      //                     Array.isArray(apiData[props.Ordinate])
      //                   ) {
      //                     // if the array contains a string amongst numbers, then don't assign it to the coordinate arrays
      //                     let xContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     xAxis = !xContainsString
      //                       ? apiData[props.Abscissa]
      //                       : [];

      //                     let yContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     yAxis = !yContainsString
      //                       ? apiData[props.Ordinate]
      //                       : [];
      //                   }

      //                   // now checking if the value of the required keys are PRIMITIVE values, neither arrays nor objects also while those traits/values are not 'undefined'
      //                   else if (
      //                     (!hasNestedArrayOfObjects &&
      //                       props.Abscissa &&
      //                       typeof apiData[props.Abscissa] !== "object") ||
      //                     (apiData[props.Abscissa] === null &&
      //                       props.Ordinate &&
      //                       typeof responseData[props.Ordinate] !==
      //                         "object") ||
      //                     responseData[props.Ordinate] === null
      //                   ) {
      //                     xAxis.push(apiData[props.Abscissa]);
      //                     yAxis.push(apiData[props.Ordinate]);
      //                   }

      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are again a NESTED OBJECT (using recursive helper function) also while those traits/values are not 'undefined'
      //                   else if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     abscissaIsNested &&
      //                     props.Ordinate &&
      //                     ordinateIsNested
      //                   ) {
      //                     console.log("inside 1");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData[props.Abscissa],
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData[props.Ordinate],
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     else if (
      //                       (xValue &&
      //                         yValue &&
      //                         !Array.isArray(xValue) &&
      //                         !Array.isArray(yValue) &&
      //                         typeof xValue !== "object") ||
      //                       (xValue === null && typeof yValue !== "object") ||
      //                       yValue === null
      //                     ) {
      //                       xAxis.push(xValue);
      //                       yAxis.push(yValue);
      //                     }
      //                   }

      //                   // now checking if the value of the response is nested also while those traits/values are not 'undefined'
      //                   else if (
      //                     props.Abscissa &&
      //                     props.Ordinate &&
      //                     hasNestedArrayOfObjects
      //                   ) {
      //                     console.log("inside 2");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     // else if (xValue && yValue && !Array.isArray(xValue) && !Array.isArray(yValue) && typeof xValue !== 'object' || xValue === null && typeof yValue !== 'object' || yValue === null && xValue !== 'NaN' && yValue !== 'NaN') {
      //                     else if (
      //                       typeof Number(xValue) === "number" &&
      //                       typeof Number(yValue) === "number"
      //                     ) {
      //                       xAxis.push(Number(xValue));
      //                       yAxis.push(Number(yValue));
      //                     }
      //                   }
      //                 }
      //               }

      //               // when everything is provided
      //               else if (username && password && apiKey !== undefined) {
      //                 let apiData;
      //                 let config = {
      //                   headers: {
      //                     Authorization:
      //                       "Basic " + btoa(username + ":" + password),
      //                     "X-API-KEY": `${apiKey}`,
      //                   },
      //                 };
      //                 // ['a:b', "c:d"]
      //                 // extracting headers from More_Headers trait
      //                 if (moreHeaders) {
      //                   moreHeaders.split(",").forEach((header) => {
      //                     let key, val;
      //                     [key, val] = header.split(":");
      //                     console.log("[key, val]:\n", key, val);

      //                     config.headers[key] = val;
      //                   });
      //                 }

      //                 console.log("config:", config);

      //                 let response = await fetch(
      //                   `${props.Graph_API}`,
      //                   config
      //                 );

      //                 let responseData = await response.json();
      //                 apiData = responseData;
      //                 console.log(
      //                   "responseData:",
      //                   responseData,
      //                   "\napiData:",
      //                   apiData
      //                 );

      //                 // variables needed later confirming whether the value of the required keys are nested further with objects or not
      //                 let hasNestedArrayOfObjects =
      //                   hasArrayofObjects(apiData);
      //                 let abscissaIsNested = isNestedObject(
      //                   responseData[props.Abscissa]
      //                 );
      //                 let ordinateIsNested = isNestedObject(
      //                   responseData[props.Ordinate]
      //                 );

      //                 // MAKING IT WORK FOR UNIVERSAL API RESPONSES, below;
      //                 // not for response structures - array of arrays, object of array of objects, object of array of arrays

      //                 // checking if the reponse is an ARRAY of OBNECTS; and then creating the coordinates array xAxis, yAxis for plotting
      //                 if (
      //                   Array.isArray(apiData) &&
      //                   apiData.length > 0 &&
      //                   typeof apiData[0] === "object"
      //                 ) {
      //                   for (let objX of apiData) {
      //                     xAxis.push(
      //                       objX[!props.Abscissa ? "" : props.Abscissa]
      //                     );
      //                   }

      //                   for (let objY of apiData) {
      //                     yAxis.push(
      //                       objY[!props.Ordinate ? "" : props.Ordinate]
      //                     );
      //                   }
      //                 }

      //                 // checking if the reponse is an OBJECT but not an array; and then creating the coordinates array xAxis, yAxis for plotting
      //                 else if (
      //                   typeof apiData === "object" &&
      //                   !Array.isArray(apiData)
      //                 ) {
      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are an ARRAY also while those traits/values are not 'undefined'
      //                   if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     Array.isArray(apiData[props.Abscissa]) &&
      //                     props.Ordinate &&
      //                     Array.isArray(apiData[props.Ordinate])
      //                   ) {
      //                     // if the array contains a string amongst numbers, then don't assign it to the coordinate arrays
      //                     let xContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     xAxis = !xContainsString
      //                       ? apiData[props.Abscissa]
      //                       : [];

      //                     let yContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     yAxis = !yContainsString
      //                       ? apiData[props.Ordinate]
      //                       : [];
      //                   }

      //                   // now checking if the value of the required keys are PRIMITIVE values, neither arrays nor objects also while those traits/values are not 'undefined'
      //                   else if (
      //                     (!hasNestedArrayOfObjects &&
      //                       props.Abscissa &&
      //                       typeof apiData[props.Abscissa] !== "object") ||
      //                     (apiData[props.Abscissa] === null &&
      //                       props.Ordinate &&
      //                       typeof responseData[props.Ordinate] !==
      //                         "object") ||
      //                     responseData[props.Ordinate] === null
      //                   ) {
      //                     xAxis.push(apiData[props.Abscissa]);
      //                     yAxis.push(apiData[props.Ordinate]);
      //                   }

      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are again a NESTED OBJECT (using recursive helper function) also while those traits/values are not 'undefined'
      //                   else if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     abscissaIsNested &&
      //                     props.Ordinate &&
      //                     ordinateIsNested
      //                   ) {
      //                     console.log("inside 1");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData[props.Abscissa],
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData[props.Ordinate],
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     else if (
      //                       (xValue &&
      //                         yValue &&
      //                         !Array.isArray(xValue) &&
      //                         !Array.isArray(yValue) &&
      //                         typeof xValue !== "object") ||
      //                       (xValue === null && typeof yValue !== "object") ||
      //                       yValue === null
      //                     ) {
      //                       xAxis.push(xValue);
      //                       yAxis.push(yValue);
      //                     }
      //                   }

      //                   // now checking if the value of the response is nested also while those traits/values are not 'undefined'
      //                   else if (
      //                     props.Abscissa &&
      //                     props.Ordinate &&
      //                     hasNestedArrayOfObjects
      //                   ) {
      //                     console.log("inside 2");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     // else if (xValue && yValue && !Array.isArray(xValue) && !Array.isArray(yValue) && typeof xValue !== 'object' || xValue === null && typeof yValue !== 'object' || yValue === null && xValue !== 'NaN' && yValue !== 'NaN') {
      //                     else if (
      //                       typeof Number(xValue) === "number" &&
      //                       typeof Number(yValue) === "number"
      //                     ) {
      //                       xAxis.push(Number(xValue));
      //                       yAxis.push(Number(yValue));
      //                     }
      //                   }
      //                 }
      //               }

      //               // when api is provided but nothing else is provided
      //               else if (!username && !password && apiKey === undefined) {
      //                 console.log(
      //                   "inside else if block - when nothing is provided except api"
      //                 );
      //                 let apiData;
      //                 // to hadd multiple header attributes
      //                 let config = {
      //                   headers: {},
      //                 };
      //                 // ['a:b', "c:d"]
      //                 // extracting headers from More_Headers trait
      //                 if (moreHeaders) {
      //                   moreHeaders.split(",").forEach((header) => {
      //                     let key, val;
      //                     [key, val] = header.split(":");
      //                     config.headers[key] = val;

      //                     console.log("[key, val]:\n", key, val);
      //                   });
      //                 }

      //                 console.log("config:", config);

      //                 let response = await fetch(
      //                   `${props.Graph_API}`,
      //                   config
      //                 );

      //                 let responseData = await response.json();
      //                 apiData = responseData;
      //                 console.log(
      //                   "responseData:",
      //                   responseData,
      //                   "\napiData:",
      //                   apiData
      //                 );

      //                 // variables needed later confirming whether the value of the required keys are nested further with objects or not
      //                 let hasNestedArrayOfObjects =
      //                   hasArrayofObjects(apiData);
      //                 let abscissaIsNested = isNestedObject(
      //                   apiData[props.Abscissa]
      //                 );
      //                 let ordinateIsNested = isNestedObject(
      //                   apiData[props.Ordinate]
      //                 );

      //                 // MAKING IT WORK FOR UNIVERSAL API RESPONSES, below;
      //                 // not for response structures - array of arrays, object of array of objects, object of array of arrays

      //                 // checking if the reponse is an ARRAY of OBNECTS; and then creating the coordinates array xAxis, yAxis for plotting
      //                 if (
      //                   Array.isArray(apiData) &&
      //                   apiData.length > 0 &&
      //                   typeof apiData[0] === "object"
      //                 ) {
      //                   for (let objX of apiData) {
      //                     xAxis.push(
      //                       objX[!props.Abscissa ? "" : props.Abscissa]
      //                     );
      //                   }

      //                   for (let objY of apiData) {
      //                     yAxis.push(
      //                       objY[!props.Ordinate ? "" : props.Ordinate]
      //                     );
      //                   }
      //                 }

      //                 // checking if the reponse is an OBJECT but not an array; and then creating the coordinates array xAxis, yAxis for plotting
      //                 else if (
      //                   typeof apiData === "object" &&
      //                   !Array.isArray(apiData)
      //                 ) {
      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are an ARRAY also while those traits/values are not 'undefined'
      //                   if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     Array.isArray(apiData[props.Abscissa]) &&
      //                     props.Ordinate &&
      //                     Array.isArray(apiData[props.Ordinate])
      //                   ) {
      //                     // if the array contains a string amongst numbers, then don't assign it to the coordinate arrays
      //                     let xContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     xAxis = !xContainsString
      //                       ? apiData[props.Abscissa]
      //                       : [];

      //                     let yContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     yAxis = !yContainsString
      //                       ? apiData[props.Ordinate]
      //                       : [];
      //                   }

      //                   // now checking if the value of the required keys are PRIMITIVE values, neither arrays nor objects also while those traits/values are not 'undefined'
      //                   else if (
      //                     (!hasNestedArrayOfObjects &&
      //                       props.Abscissa &&
      //                       typeof apiData[props.Abscissa] !== "object") ||
      //                     (apiData[props.Abscissa] === null &&
      //                       props.Ordinate &&
      //                       typeof responseData[props.Ordinate] !==
      //                         "object") ||
      //                     responseData[props.Ordinate] === null
      //                   ) {
      //                     xAxis.push(apiData[props.Abscissa]);
      //                     yAxis.push(apiData[props.Ordinate]);
      //                   }

      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are again a NESTED OBJECT (using recursive helper function) also while those traits/values are not 'undefined'
      //                   else if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     abscissaIsNested &&
      //                     props.Ordinate &&
      //                     ordinateIsNested
      //                   ) {
      //                     console.log("inside 1");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData[props.Abscissa],
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData[props.Ordinate],
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     else if (
      //                       (xValue &&
      //                         yValue &&
      //                         !Array.isArray(xValue) &&
      //                         !Array.isArray(yValue) &&
      //                         typeof xValue !== "object") ||
      //                       (xValue === null && typeof yValue !== "object") ||
      //                       yValue === null
      //                     ) {
      //                       xAxis.push(xValue);
      //                       yAxis.push(yValue);
      //                     }
      //                   }

      //                   // now checking if the value of the response is nested also while those traits/values are not 'undefined'
      //                   else if (
      //                     props.Abscissa &&
      //                     props.Ordinate &&
      //                     hasNestedArrayOfObjects
      //                   ) {
      //                     console.log("inside 2");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     // else if (xValue && yValue && !Array.isArray(xValue) && !Array.isArray(yValue) && typeof xValue !== 'object' || xValue === null && typeof yValue !== 'object' || yValue === null && xValue !== 'NaN' && yValue !== 'NaN') {
      //                     else if (
      //                       typeof Number(xValue) === "number" &&
      //                       typeof Number(yValue) === "number"
      //                     ) {
      //                       xAxis.push(Number(xValue));
      //                       yAxis.push(Number(yValue));
      //                     }
      //                   }
      //                 }
      //               }

      //               console.log("xAxis:", xAxis, "\nyAxis:", yAxis);

      //               // const labels = [
      //               //   "January",
      //               //   "February",
      //               //   "March",
      //               //   "April",
      //               //   "May",
      //               //   "June",
      //               // ];

      //               // animations
      //               let animate;
      //               let transition;
      //               let delayed;

      //               if (props.Animation === "delay") {
      //                 animate = {
      //                   onComplete: () => {
      //                     delayed = true;
      //                   },

      //                   delay: (context) => {
      //                     let delay = 0;
      //                     if (
      //                       context.type === "data" &&
      //                       context.mode === "default" &&
      //                       !delayed
      //                     ) {
      //                       delay =
      //                         context.dataIndex * 300 +
      //                         context.datasetIndex * 100;
      //                     }
      //                     return delay;
      //                   },
      //                 };
      //               } else if (props.Animation === "hideAndShow") {
      //                 animate = {};
      //                 transition = {
      //                   show: {
      //                     animations: {
      //                       x: {
      //                         from: 0,
      //                       },
      //                       y: {
      //                         from: 0,
      //                       },
      //                     },
      //                   },

      //                   hide: {
      //                     animations: {
      //                       x: {
      //                         to: 0,
      //                       },
      //                       y: {
      //                         to: 0,
      //                       },
      //                     },
      //                   },
      //                 };
      //               }

      //               const data = {
      //                 datasets: [
      //                   {
      //                     backgroundColor: !props.Bar_Colors
      //                       ? ["red", "green", "blue"]
      //                       : props.Bar_Colors.split(","),
      //                     borderColor: !props.Border_Color
      //                       ? "rgb(255, 99, 132)"
      //                       : props.Border_Color,
      //                     borderWidth: !props.Border_Width
      //                       ? 2
      //                       : props.Border_Width,
      //                     hoverOffset: 4,
      //                     // data: [10, 20, 30]
      //                     data: yAxis,
      //                   },
      //                 ],

      //                 labels: xAxis,
      //               };

      //               const config = {
      //                 type: "doughnut",
      //                 data: data,
      //                 options: {
      //                   animation: animate,
      //                   transitions: transition,
      //                   responsive: true,
      //                 },
      //               };

      //               const ctx = document
      //                 .getElementById("doughnutChart")
      //                 .getContext("2d");
      //               const myChart = new Chart(ctx, config);
      //             } catch (e) {
      //               console.log(e);
      //             }
      //           }
      //         };

      //         if (typeof Chart == "undefined") {
      //           const script = document.createElement("script");
      //           script.onload = initLib;
      //           script.src = "https://cdn.jsdelivr.net/npm/chart.js";
      //           document.body.appendChild(script);
      //         } else {
      //           console.log("InitLib");
      //           initLib();
      //         }
      //       },

      //       Graph_API: "",
      //       Username: "",
      //       Password: "",
      //       API_Key: "",
      //       More_Headers: "", // input should be like: a:b,c:d,e:f

      //       Animation: "",
      //       Abscissa: "",
      //       Ordinate: "",
      //       Bar_Colors: "",
      //       Border_Width: "",
      //       Border_Color: "",
      //       // Fill: "",

      //       traits: [
      //         {
      //           type: "text",
      //           name: "Graph_API",
      //           label: "Graph API",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Username",
      //           label: "Username",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Password",
      //           label: "Password",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "API_Key",
      //           label: "API Key",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "More_Headers",
      //           label: "More Headers",
      //           changeProp: true,
      //         },
      //         {
      //           type: "select",
      //           name: "Animation",
      //           label: "Pre-defined Graph Animations",
      //           options: [
      //             { id: "delay", name: "Delay" },
      //             { id: "hideAndShow", name: "Hide And Show" },
      //           ],
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Abscissa",
      //           label: "Abscissa",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Ordinate",
      //           label: "Ordinates",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Bar_Colors",
      //           label: "Datapoint Colors",
      //           changeProp: true,
      //         },
      //         {
      //           type: "range",
      //           min: "0",
      //           max: "20",
      //           name: "Border_Width",
      //           label: "Border Width [0px to 20px]",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Border_Color",
      //           label: "Border Color",
      //           changeProp: true,
      //         },
      //         // {
      //         //     type: 'checkbox',
      //         //     name: 'Fill',
      //         //     label: 'Fill Area',
      //         //     changeProp: true,
      //         // },
      //       ],

      //       changeProp: true,

      //       "script-props": [
      //         "Graph_API",
      //         "Username",
      //         "Password",
      //         "API_Key",
      //         "More_Headers",
      //         "Animation",
      //         "Abscissa",
      //         "Ordinate",
      //         "Bar_Colors",
      //         "Border_Width",
      //         "Border_Color",
      //       ],
      //     },
      //   },
      //   isComponent: (el) => {
      //     if (
      //       el.getAttribute &&
      //       el.getAttribute("data-gjs-type") == "doughnutChart"
      //     ) {
      //       return "doughnutChart";
      //     }
      //   },
      //   view: defaultView.extend({
      //     init({ model }) {},
      //   }),
      // });

      // editor.DomComponents.addType("pieChart", {
      //   model: {
      //     defaults: {
      //       script: function (props) {
      //         const initLib = async function () {
      //           // this helper function recursively scans an object and its nests until it finds the key and returns its value
      //           function findKeyInNestedObject(response, key) {
      //             let values = [];

      //             function traverseObject(obj) {
      //               for (let prop in obj) {
      //                 if (typeof obj[prop] === "object") {
      //                   traverseObject(obj[prop]);
      //                 } else if (Array.isArray(obj[prop])) {
      //                   for (let item of obj[prop]) {
      //                     if (typeof item === "object") {
      //                       traverseObject(item);
      //                     }
      //                   }
      //                 } else if (prop === key) {
      //                   values.push(Number(obj[prop]));
      //                 }
      //               }
      //             }

      //             traverseObject(response);

      //             return values;
      //           }

      //           // this helper function checks if an object has multiple levels of nesting
      //           function isNestedObject(response) {
      //             if (typeof response !== "object") {
      //               return false; // Not an object
      //             }

      //             for (let prop in response) {
      //               if (typeof response[prop] === "object") {
      //                 return true; // Found nested object
      //               }
      //             }

      //             return false; // No nested object found
      //           }

      //           // this helper function checks if there is an array of objects in a response object
      //           function hasArrayofObjects(obj) {
      //             for (let prop in obj) {
      //               if (
      //                 Array.isArray(obj[prop]) &&
      //                 obj[prop].length > 0 &&
      //                 typeof obj[prop][0] === "object"
      //               ) {
      //                 return true; // Array of objects found
      //               }
      //             }
      //             return false; // Array of objects not found
      //           }

      //           let username = props.Username;
      //           let password = props.Password;
      //           let apiKey = props.API_key;
      //           let moreHeaders = props.More_Headers;

      //           let xAxis = [];
      //           let yAxis = [];

      //           console.log(
      //             "Graph props:",
      //             props,
      //             "\n\nusername:",
      //             username,
      //             "\npassword:",
      //             password,
      //             "\napiKey:",
      //             apiKey,
      //             "\nmoreHeaders:",
      //             moreHeaders
      //           );

      //           if (props.Graph_API) {
      //             try {
      //               // By Invoice URL
      //               // let response = await fetch('http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&INVOICE=AR25634D', {

      //               // By Date URL
      //               // let response = await fetch('http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&DATE=20230102', {

      //               // By Customer URL
      //               // let response = await fetch('http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&CUSTOMER=DENCO%20EQUIPMENTS%20LIMITED', {

      //               // By Coin URL
      //               // let response = await fetch('https://api.coinranking.com/v2/coins', {

      //               // By Month URL
      //               // http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&MONTH=MAR

      //               // when api, username and password is provided but not api key
      //               if (username && password && apiKey === undefined) {
      //                 let apiData;
      //                 let config = {
      //                   headers: {
      //                     Authorization:
      //                       "Basic " + btoa(username + ":" + password),
      //                   },
      //                 };
      //                 // ['a:b', "c:d"]
      //                 // extracting headers from More_Headers trait, if provided
      //                 if (moreHeaders) {
      //                   moreHeaders.split(",").forEach((header) => {
      //                     let key, val;
      //                     [key, val] = header.split(":");
      //                     console.log("[key, val]:\n", key, val);

      //                     config.headers[key] = val;
      //                   });
      //                 }

      //                 console.log("config:", config);

      //                 const response = await fetch(
      //                   `${props.Graph_API}`,
      //                   config
      //                 );

      //                 const responseData = await response.json();
      //                 apiData = responseData;
      //                 console.log(
      //                   "responseData:",
      //                   responseData,
      //                   "\napiData:",
      //                   apiData
      //                 );

      //                 // variables needed later confirming whether the value of the required keys are nested further with objects or not
      //                 let hasNestedArrayOfObjects =
      //                   hasArrayofObjects(apiData);
      //                 let abscissaIsNested = isNestedObject(
      //                   responseData[props.Abscissa]
      //                 );
      //                 let ordinateIsNested = isNestedObject(
      //                   responseData[props.Ordinate]
      //                 );

      //                 // MAKING IT WORK FOR UNIVERSAL API RESPONSES, below;
      //                 // not for response structures - array of arrays, object of array of objects, object of array of arrays

      //                 // checking if the reponse is an ARRAY of OBNECTS; and then creating the coordinates array xAxis, yAxis for plotting
      //                 if (
      //                   Array.isArray(apiData) &&
      //                   apiData.length > 0 &&
      //                   typeof apiData[0] === "object"
      //                 ) {
      //                   for (let objX of apiData) {
      //                     xAxis.push(
      //                       objX[!props.Abscissa ? "" : props.Abscissa]
      //                     );
      //                   }

      //                   for (let objY of apiData) {
      //                     yAxis.push(
      //                       objY[!props.Ordinate ? "" : props.Ordinate]
      //                     );
      //                   }
      //                 }

      //                 // checking if the reponse is an OBJECT but not an array; and then creating the coordinates array xAxis, yAxis for plotting
      //                 else if (
      //                   typeof apiData === "object" &&
      //                   !Array.isArray(apiData)
      //                 ) {
      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are an ARRAY also while those traits/values are not 'undefined'
      //                   if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     Array.isArray(apiData[props.Abscissa]) &&
      //                     props.Ordinate &&
      //                     Array.isArray(apiData[props.Ordinate])
      //                   ) {
      //                     // if the array contains a string amongst numbers, then don't assign it to the coordinate arrays
      //                     let xContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     xAxis = !xContainsString
      //                       ? apiData[props.Abscissa]
      //                       : [];

      //                     let yContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     yAxis = !yContainsString
      //                       ? apiData[props.Ordinate]
      //                       : [];
      //                   }

      //                   // now checking if the value of the required keys are PRIMITIVE values, neither arrays nor objects also while those traits/values are not 'undefined'
      //                   else if (
      //                     (!hasNestedArrayOfObjects &&
      //                       props.Abscissa &&
      //                       typeof apiData[props.Abscissa] !== "object") ||
      //                     (apiData[props.Abscissa] === null &&
      //                       props.Ordinate &&
      //                       typeof responseData[props.Ordinate] !==
      //                         "object") ||
      //                     responseData[props.Ordinate] === null
      //                   ) {
      //                     xAxis.push(apiData[props.Abscissa]);
      //                     yAxis.push(apiData[props.Ordinate]);
      //                   }

      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are again a NESTED OBJECT (using recursive helper function) also while those traits/values are not 'undefined'
      //                   else if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     abscissaIsNested &&
      //                     props.Ordinate &&
      //                     ordinateIsNested
      //                   ) {
      //                     console.log("inside 1");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData[props.Abscissa],
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData[props.Ordinate],
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     else if (
      //                       (xValue &&
      //                         yValue &&
      //                         !Array.isArray(xValue) &&
      //                         !Array.isArray(yValue) &&
      //                         typeof xValue !== "object") ||
      //                       (xValue === null && typeof yValue !== "object") ||
      //                       yValue === null
      //                     ) {
      //                       xAxis.push(xValue);
      //                       yAxis.push(yValue);
      //                     }
      //                   }

      //                   // now checking if the value of the response is nested also while those traits/values are not 'undefined'
      //                   else if (
      //                     props.Abscissa &&
      //                     props.Ordinate &&
      //                     hasNestedArrayOfObjects
      //                   ) {
      //                     console.log("inside 2");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     // else if (xValue && yValue && !Array.isArray(xValue) && !Array.isArray(yValue) && typeof xValue !== 'object' || xValue === null && typeof yValue !== 'object' || yValue === null && xValue !== 'NaN' && yValue !== 'NaN') {
      //                     else if (
      //                       typeof Number(xValue) === "number" &&
      //                       typeof Number(yValue) === "number"
      //                     ) {
      //                       xAxis.push(Number(xValue));
      //                       yAxis.push(Number(yValue));
      //                     }
      //                   }
      //                 }
      //               }

      //               // when api and api key is provided but not username and password
      //               else if (!username && !password && apiKey !== undefined) {
      //                 let apiData;
      //                 let config = {
      //                   headers: {
      //                     "X-API-KEY": `${apiKey}`,
      //                   },
      //                 };
      //                 // ['a:b', "c:d"]
      //                 // extracting headers from More_Headers trait
      //                 if (moreHeaders) {
      //                   moreHeaders.split(",").forEach((header) => {
      //                     let key, val;
      //                     [key, val] = header.split(":");
      //                     console.log("[key, val]:\n", key, val);

      //                     config.headers[key] = val;
      //                   });
      //                 }

      //                 console.log("config:", config);

      //                 let response = await fetch(
      //                   `${props.Graph_API}`,
      //                   config
      //                 );

      //                 let responseData = await response.json();
      //                 apiData = responseData;
      //                 console.log(
      //                   "responseData:",
      //                   responseData,
      //                   "\napiData:",
      //                   apiData
      //                 );

      //                 // variables needed later confirming whether the value of the required keys are nested further with objects or not
      //                 let hasNestedArrayOfObjects =
      //                   hasArrayofObjects(apiData);
      //                 let abscissaIsNested = isNestedObject(
      //                   responseData[props.Abscissa]
      //                 );
      //                 let ordinateIsNested = isNestedObject(
      //                   responseData[props.Ordinate]
      //                 );

      //                 // MAKING IT WORK FOR UNIVERSAL API RESPONSES, below;
      //                 // not for response structures - array of arrays, object of array of objects, object of array of arrays

      //                 // checking if the reponse is an ARRAY of OBNECTS; and then creating the coordinates array xAxis, yAxis for plotting
      //                 if (
      //                   Array.isArray(apiData) &&
      //                   apiData.length > 0 &&
      //                   typeof apiData[0] === "object"
      //                 ) {
      //                   for (let objX of apiData) {
      //                     xAxis.push(
      //                       objX[!props.Abscissa ? "" : props.Abscissa]
      //                     );
      //                   }

      //                   for (let objY of apiData) {
      //                     yAxis.push(
      //                       objY[!props.Ordinate ? "" : props.Ordinate]
      //                     );
      //                   }
      //                 }

      //                 // checking if the reponse is an OBJECT but not an array; and then creating the coordinates array xAxis, yAxis for plotting
      //                 else if (
      //                   typeof apiData === "object" &&
      //                   !Array.isArray(apiData)
      //                 ) {
      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are an ARRAY also while those traits/values are not 'undefined'
      //                   if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     Array.isArray(apiData[props.Abscissa]) &&
      //                     props.Ordinate &&
      //                     Array.isArray(apiData[props.Ordinate])
      //                   ) {
      //                     // if the array contains a string amongst numbers, then don't assign it to the coordinate arrays
      //                     let xContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     xAxis = !xContainsString
      //                       ? apiData[props.Abscissa]
      //                       : [];

      //                     let yContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     yAxis = !yContainsString
      //                       ? apiData[props.Ordinate]
      //                       : [];
      //                   }

      //                   // now checking if the value of the required keys are PRIMITIVE values, neither arrays nor objects also while those traits/values are not 'undefined'
      //                   else if (
      //                     (!hasNestedArrayOfObjects &&
      //                       props.Abscissa &&
      //                       typeof apiData[props.Abscissa] !== "object") ||
      //                     (apiData[props.Abscissa] === null &&
      //                       props.Ordinate &&
      //                       typeof responseData[props.Ordinate] !==
      //                         "object") ||
      //                     responseData[props.Ordinate] === null
      //                   ) {
      //                     xAxis.push(apiData[props.Abscissa]);
      //                     yAxis.push(apiData[props.Ordinate]);
      //                   }

      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are again a NESTED OBJECT (using recursive helper function) also while those traits/values are not 'undefined'
      //                   else if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     abscissaIsNested &&
      //                     props.Ordinate &&
      //                     ordinateIsNested
      //                   ) {
      //                     console.log("inside 1");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData[props.Abscissa],
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData[props.Ordinate],
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     else if (
      //                       (xValue &&
      //                         yValue &&
      //                         !Array.isArray(xValue) &&
      //                         !Array.isArray(yValue) &&
      //                         typeof xValue !== "object") ||
      //                       (xValue === null && typeof yValue !== "object") ||
      //                       yValue === null
      //                     ) {
      //                       xAxis.push(xValue);
      //                       yAxis.push(yValue);
      //                     }
      //                   }

      //                   // now checking if the value of the response is nested also while those traits/values are not 'undefined'
      //                   else if (
      //                     props.Abscissa &&
      //                     props.Ordinate &&
      //                     hasNestedArrayOfObjects
      //                   ) {
      //                     console.log("inside 2");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     // else if (xValue && yValue && !Array.isArray(xValue) && !Array.isArray(yValue) && typeof xValue !== 'object' || xValue === null && typeof yValue !== 'object' || yValue === null && xValue !== 'NaN' && yValue !== 'NaN') {
      //                     else if (
      //                       typeof Number(xValue) === "number" &&
      //                       typeof Number(yValue) === "number"
      //                     ) {
      //                       xAxis.push(Number(xValue));
      //                       yAxis.push(Number(yValue));
      //                     }
      //                   }
      //                 }
      //               }

      //               // when everything is provided
      //               else if (username && password && apiKey !== undefined) {
      //                 let apiData;
      //                 let config = {
      //                   headers: {
      //                     Authorization:
      //                       "Basic " + btoa(username + ":" + password),
      //                     "X-API-KEY": `${apiKey}`,
      //                   },
      //                 };
      //                 // ['a:b', "c:d"]
      //                 // extracting headers from More_Headers trait
      //                 if (moreHeaders) {
      //                   moreHeaders.split(",").forEach((header) => {
      //                     let key, val;
      //                     [key, val] = header.split(":");
      //                     console.log("[key, val]:\n", key, val);

      //                     config.headers[key] = val;
      //                   });
      //                 }

      //                 console.log("config:", config);

      //                 let response = await fetch(
      //                   `${props.Graph_API}`,
      //                   config
      //                 );

      //                 let responseData = await response.json();
      //                 apiData = responseData;
      //                 console.log(
      //                   "responseData:",
      //                   responseData,
      //                   "\napiData:",
      //                   apiData
      //                 );

      //                 // variables needed later confirming whether the value of the required keys are nested further with objects or not
      //                 let hasNestedArrayOfObjects =
      //                   hasArrayofObjects(apiData);
      //                 let abscissaIsNested = isNestedObject(
      //                   responseData[props.Abscissa]
      //                 );
      //                 let ordinateIsNested = isNestedObject(
      //                   responseData[props.Ordinate]
      //                 );

      //                 // MAKING IT WORK FOR UNIVERSAL API RESPONSES, below;
      //                 // not for response structures - array of arrays, object of array of objects, object of array of arrays

      //                 // checking if the reponse is an ARRAY of OBNECTS; and then creating the coordinates array xAxis, yAxis for plotting
      //                 if (
      //                   Array.isArray(apiData) &&
      //                   apiData.length > 0 &&
      //                   typeof apiData[0] === "object"
      //                 ) {
      //                   for (let objX of apiData) {
      //                     xAxis.push(
      //                       objX[!props.Abscissa ? "" : props.Abscissa]
      //                     );
      //                   }

      //                   for (let objY of apiData) {
      //                     yAxis.push(
      //                       objY[!props.Ordinate ? "" : props.Ordinate]
      //                     );
      //                   }
      //                 }

      //                 // checking if the reponse is an OBJECT but not an array; and then creating the coordinates array xAxis, yAxis for plotting
      //                 else if (
      //                   typeof apiData === "object" &&
      //                   !Array.isArray(apiData)
      //                 ) {
      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are an ARRAY also while those traits/values are not 'undefined'
      //                   if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     Array.isArray(apiData[props.Abscissa]) &&
      //                     props.Ordinate &&
      //                     Array.isArray(apiData[props.Ordinate])
      //                   ) {
      //                     // if the array contains a string amongst numbers, then don't assign it to the coordinate arrays
      //                     let xContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     xAxis = !xContainsString
      //                       ? apiData[props.Abscissa]
      //                       : [];

      //                     let yContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     yAxis = !yContainsString
      //                       ? apiData[props.Ordinate]
      //                       : [];
      //                   }

      //                   // now checking if the value of the required keys are PRIMITIVE values, neither arrays nor objects also while those traits/values are not 'undefined'
      //                   else if (
      //                     (!hasNestedArrayOfObjects &&
      //                       props.Abscissa &&
      //                       typeof apiData[props.Abscissa] !== "object") ||
      //                     (apiData[props.Abscissa] === null &&
      //                       props.Ordinate &&
      //                       typeof responseData[props.Ordinate] !==
      //                         "object") ||
      //                     responseData[props.Ordinate] === null
      //                   ) {
      //                     xAxis.push(apiData[props.Abscissa]);
      //                     yAxis.push(apiData[props.Ordinate]);
      //                   }

      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are again a NESTED OBJECT (using recursive helper function) also while those traits/values are not 'undefined'
      //                   else if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     abscissaIsNested &&
      //                     props.Ordinate &&
      //                     ordinateIsNested
      //                   ) {
      //                     console.log("inside 1");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData[props.Abscissa],
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData[props.Ordinate],
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     else if (
      //                       (xValue &&
      //                         yValue &&
      //                         !Array.isArray(xValue) &&
      //                         !Array.isArray(yValue) &&
      //                         typeof xValue !== "object") ||
      //                       (xValue === null && typeof yValue !== "object") ||
      //                       yValue === null
      //                     ) {
      //                       xAxis.push(xValue);
      //                       yAxis.push(yValue);
      //                     }
      //                   }

      //                   // now checking if the value of the response is nested also while those traits/values are not 'undefined'
      //                   else if (
      //                     props.Abscissa &&
      //                     props.Ordinate &&
      //                     hasNestedArrayOfObjects
      //                   ) {
      //                     console.log("inside 2");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     // else if (xValue && yValue && !Array.isArray(xValue) && !Array.isArray(yValue) && typeof xValue !== 'object' || xValue === null && typeof yValue !== 'object' || yValue === null && xValue !== 'NaN' && yValue !== 'NaN') {
      //                     else if (
      //                       typeof Number(xValue) === "number" &&
      //                       typeof Number(yValue) === "number"
      //                     ) {
      //                       xAxis.push(Number(xValue));
      //                       yAxis.push(Number(yValue));
      //                     }
      //                   }
      //                 }
      //               }

      //               // when api is provided but nothing else is provided
      //               else if (!username && !password && apiKey === undefined) {
      //                 console.log(
      //                   "inside else if block - when nothing is provided except api"
      //                 );
      //                 let apiData;
      //                 // to hadd multiple header attributes
      //                 let config = {
      //                   headers: {},
      //                 };
      //                 // ['a:b', "c:d"]
      //                 // extracting headers from More_Headers trait
      //                 if (moreHeaders) {
      //                   moreHeaders.split(",").forEach((header) => {
      //                     let key, val;
      //                     [key, val] = header.split(":");
      //                     config.headers[key] = val;

      //                     console.log("[key, val]:\n", key, val);
      //                   });
      //                 }

      //                 console.log("config:", config);

      //                 let response = await fetch(
      //                   `${props.Graph_API}`,
      //                   config
      //                 );

      //                 let responseData = await response.json();
      //                 apiData = responseData;
      //                 console.log(
      //                   "responseData:",
      //                   responseData,
      //                   "\napiData:",
      //                   apiData
      //                 );

      //                 // variables needed later confirming whether the value of the required keys are nested further with objects or not
      //                 let hasNestedArrayOfObjects =
      //                   hasArrayofObjects(apiData);
      //                 let abscissaIsNested = isNestedObject(
      //                   apiData[props.Abscissa]
      //                 );
      //                 let ordinateIsNested = isNestedObject(
      //                   apiData[props.Ordinate]
      //                 );

      //                 // MAKING IT WORK FOR UNIVERSAL API RESPONSES, below;
      //                 // not for response structures - array of arrays, object of array of objects, object of array of arrays

      //                 // checking if the reponse is an ARRAY of OBNECTS; and then creating the coordinates array xAxis, yAxis for plotting
      //                 if (
      //                   Array.isArray(apiData) &&
      //                   apiData.length > 0 &&
      //                   typeof apiData[0] === "object"
      //                 ) {
      //                   for (let objX of apiData) {
      //                     xAxis.push(
      //                       objX[!props.Abscissa ? "" : props.Abscissa]
      //                     );
      //                   }

      //                   for (let objY of apiData) {
      //                     yAxis.push(
      //                       objY[!props.Ordinate ? "" : props.Ordinate]
      //                     );
      //                   }
      //                 }

      //                 // checking if the reponse is an OBJECT but not an array; and then creating the coordinates array xAxis, yAxis for plotting
      //                 else if (
      //                   typeof apiData === "object" &&
      //                   !Array.isArray(apiData)
      //                 ) {
      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are an ARRAY also while those traits/values are not 'undefined'
      //                   if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     Array.isArray(apiData[props.Abscissa]) &&
      //                     props.Ordinate &&
      //                     Array.isArray(apiData[props.Ordinate])
      //                   ) {
      //                     // if the array contains a string amongst numbers, then don't assign it to the coordinate arrays
      //                     let xContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     xAxis = !xContainsString
      //                       ? apiData[props.Abscissa]
      //                       : [];

      //                     let yContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     yAxis = !yContainsString
      //                       ? apiData[props.Ordinate]
      //                       : [];
      //                   }

      //                   // now checking if the value of the required keys are PRIMITIVE values, neither arrays nor objects also while those traits/values are not 'undefined'
      //                   else if (
      //                     (!hasNestedArrayOfObjects &&
      //                       props.Abscissa &&
      //                       typeof apiData[props.Abscissa] !== "object") ||
      //                     (apiData[props.Abscissa] === null &&
      //                       props.Ordinate &&
      //                       typeof responseData[props.Ordinate] !==
      //                         "object") ||
      //                     responseData[props.Ordinate] === null
      //                   ) {
      //                     xAxis.push(apiData[props.Abscissa]);
      //                     yAxis.push(apiData[props.Ordinate]);
      //                   }

      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are again a NESTED OBJECT (using recursive helper function) also while those traits/values are not 'undefined'
      //                   else if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     abscissaIsNested &&
      //                     props.Ordinate &&
      //                     ordinateIsNested
      //                   ) {
      //                     console.log("inside 1");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData[props.Abscissa],
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData[props.Ordinate],
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     else if (
      //                       (xValue &&
      //                         yValue &&
      //                         !Array.isArray(xValue) &&
      //                         !Array.isArray(yValue) &&
      //                         typeof xValue !== "object") ||
      //                       (xValue === null && typeof yValue !== "object") ||
      //                       yValue === null
      //                     ) {
      //                       xAxis.push(xValue);
      //                       yAxis.push(yValue);
      //                     }
      //                   }

      //                   // now checking if the value of the response is nested also while those traits/values are not 'undefined'
      //                   else if (
      //                     props.Abscissa &&
      //                     props.Ordinate &&
      //                     hasNestedArrayOfObjects
      //                   ) {
      //                     console.log("inside 2");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     // else if (xValue && yValue && !Array.isArray(xValue) && !Array.isArray(yValue) && typeof xValue !== 'object' || xValue === null && typeof yValue !== 'object' || yValue === null && xValue !== 'NaN' && yValue !== 'NaN') {
      //                     else if (
      //                       typeof Number(xValue) === "number" &&
      //                       typeof Number(yValue) === "number"
      //                     ) {
      //                       xAxis.push(Number(xValue));
      //                       yAxis.push(Number(yValue));
      //                     }
      //                   }
      //                 }
      //               }

      //               console.log("xAxis:", xAxis, "\nyAxis:", yAxis);

      //               // const labels = [
      //               //   "January",
      //               //   "February",
      //               //   "March",
      //               //   "April",
      //               //   "May",
      //               //   "June",
      //               // ];

      //               // animations
      //               let animate;
      //               let transition;
      //               let delayed;

      //               if (props.Animation === "delay") {
      //                 animate = {
      //                   onComplete: () => {
      //                     delayed = true;
      //                   },

      //                   delay: (context) => {
      //                     let delay = 0;
      //                     if (
      //                       context.type === "data" &&
      //                       context.mode === "default" &&
      //                       !delayed
      //                     ) {
      //                       delay =
      //                         context.dataIndex * 300 +
      //                         context.datasetIndex * 100;
      //                     }
      //                     return delay;
      //                   },
      //                 };
      //               } else if (props.Animation === "hideAndShow") {
      //                 animate = {};
      //                 transition = {
      //                   show: {
      //                     animations: {
      //                       x: {
      //                         from: 0,
      //                       },
      //                       y: {
      //                         from: 0,
      //                       },
      //                     },
      //                   },

      //                   hide: {
      //                     animations: {
      //                       x: {
      //                         to: 0,
      //                       },
      //                       y: {
      //                         to: 0,
      //                       },
      //                     },
      //                   },
      //                 };
      //               }

      //               const data = {
      //                 datasets: [
      //                   {
      //                     backgroundColor: !props.Bar_Colors
      //                       ? ["red", "green", "blue"]
      //                       : props.Bar_Colors.split(","),
      //                     borderColor: !props.Border_Color
      //                       ? "rgb(255, 99, 132)"
      //                       : props.Border_Color,
      //                     borderWidth: !props.Border_Width
      //                       ? 2
      //                       : props.Border_Width,
      //                     hoverOffset: 4,
      //                     // data: [10, 20, 30]
      //                     data: yAxis,
      //                   },
      //                 ],

      //                 labels: xAxis,
      //               };

      //               const config = {
      //                 type: "pie",
      //                 data: data,
      //                 options: {
      //                   animation: animate,
      //                   transitions: transition,
      //                   responsive: true,
      //                 },
      //               };

      //               const ctx = document
      //                 .getElementById("pieChart")
      //                 .getContext("2d");
      //               const myChart = new Chart(ctx, config);
      //             } catch (e) {
      //               console.log(e);
      //             }
      //           }
      //         };

      //         if (typeof Chart == "undefined") {
      //           const script = document.createElement("script");
      //           script.onload = initLib;
      //           script.src = "https://cdn.jsdelivr.net/npm/chart.js";
      //           document.body.appendChild(script);
      //         } else {
      //           console.log("InitLib");
      //           initLib();
      //         }
      //       },

      //       Graph_API: "",
      //       Username: "",
      //       Password: "",
      //       API_Key: "",
      //       More_Headers: "", // input should be like: a:b,c:d,e:f

      //       Animation: "",
      //       Abscissa: "",
      //       Ordinate: "",
      //       Bar_Colors: "",
      //       Border_Width: "",
      //       Border_Color: "",
      //       // Fill: "",

      //       traits: [
      //         {
      //           type: "text",
      //           name: "Graph_API",
      //           label: "Graph API",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Username",
      //           label: "Username",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Password",
      //           label: "Password",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "API_Key",
      //           label: "API Key",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "More_Headers",
      //           label: "More Headers",
      //           changeProp: true,
      //         },
      //         {
      //           type: "select",
      //           name: "Animation",
      //           label: "Pre-defined Graph Animations",
      //           options: [
      //             { id: "delay", name: "Delay" },
      //             { id: "hideAndShow", name: "Hide And Show" },
      //           ],
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Abscissa",
      //           label: "Abscissa",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Ordinate",
      //           label: "Ordinates",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Bar_Colors",
      //           label: "Datapoint Colors",
      //           changeProp: true,
      //         },
      //         {
      //           type: "range",
      //           min: "0",
      //           max: "20",
      //           name: "Border_Width",
      //           label: "Border Width [0px to 20px]",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Border_Color",
      //           label: "Border Color",
      //           changeProp: true,
      //         },
      //         // {
      //         //     type: 'checkbox',
      //         //     name: 'Fill',
      //         //     label: 'Fill Area',
      //         //     changeProp: true,
      //         // },
      //       ],

      //       changeProp: true,

      //       "script-props": [
      //         "Graph_API",
      //         "Username",
      //         "Password",
      //         "API_Key",
      //         "More_Headers",
      //         "Animation",
      //         "Abscissa",
      //         "Ordinate",
      //         "Bar_Colors",
      //         "Border_Width",
      //         "Border_Color",
      //       ],
      //     },
      //   },
      //   isComponent: (el) => {
      //     if (
      //       el.getAttribute &&
      //       el.getAttribute("data-gjs-type") == "pieChart"
      //     ) {
      //       return "pieChart";
      //     }
      //   },
      //   view: defaultView.extend({
      //     init({ model }) {},
      //   }),
      // });

      // editor.DomComponents.addType("polarAreaChart", {
      //   model: {
      //     defaults: {
      //       script: function (props) {
      //         const initLib = async function () {
      //           // this helper function recursively scans an object and its nests until it finds the key and returns its value
      //           function findKeyInNestedObject(response, key) {
      //             let values = [];

      //             function traverseObject(obj) {
      //               for (let prop in obj) {
      //                 if (typeof obj[prop] === "object") {
      //                   traverseObject(obj[prop]);
      //                 } else if (Array.isArray(obj[prop])) {
      //                   for (let item of obj[prop]) {
      //                     if (typeof item === "object") {
      //                       traverseObject(item);
      //                     }
      //                   }
      //                 } else if (prop === key) {
      //                   values.push(Number(obj[prop]));
      //                 }
      //               }
      //             }

      //             traverseObject(response);

      //             return values;
      //           }

      //           // this helper function checks if an object has multiple levels of nesting
      //           function isNestedObject(response) {
      //             if (typeof response !== "object") {
      //               return false; // Not an object
      //             }

      //             for (let prop in response) {
      //               if (typeof response[prop] === "object") {
      //                 return true; // Found nested object
      //               }
      //             }

      //             return false; // No nested object found
      //           }

      //           // this helper function checks if there is an array of objects in a response object
      //           function hasArrayofObjects(obj) {
      //             for (let prop in obj) {
      //               if (
      //                 Array.isArray(obj[prop]) &&
      //                 obj[prop].length > 0 &&
      //                 typeof obj[prop][0] === "object"
      //               ) {
      //                 return true; // Array of objects found
      //               }
      //             }
      //             return false; // Array of objects not found
      //           }

      //           let username = props.Username;
      //           let password = props.Password;
      //           let apiKey = props.API_key;
      //           let moreHeaders = props.More_Headers;

      //           let xAxis = [];
      //           let yAxis = [];

      //           console.log(
      //             "Graph props:",
      //             props,
      //             "\n\nusername:",
      //             username,
      //             "\npassword:",
      //             password,
      //             "\napiKey:",
      //             apiKey,
      //             "\nmoreHeaders:",
      //             moreHeaders
      //           );

      //           if (props.Graph_API) {
      //             try {
      //               // By Invoice URL
      //               // let response = await fetch('http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&INVOICE=AR25634D', {

      //               // By Date URL
      //               // let response = await fetch('http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&DATE=20230102', {

      //               // By Customer URL
      //               // let response = await fetch('http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&CUSTOMER=DENCO%20EQUIPMENTS%20LIMITED', {

      //               // By Coin URL
      //               // let response = await fetch('https://api.coinranking.com/v2/coins', {

      //               // By Month URL
      //               // http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&MONTH=MAR

      //               // when api, username and password is provided but not api key
      //               if (username && password && apiKey === undefined) {
      //                 let apiData;
      //                 let config = {
      //                   headers: {
      //                     Authorization:
      //                       "Basic " + btoa(username + ":" + password),
      //                   },
      //                 };
      //                 // ['a:b', "c:d"]
      //                 // extracting headers from More_Headers trait, if provided
      //                 if (moreHeaders) {
      //                   moreHeaders.split(",").forEach((header) => {
      //                     let key, val;
      //                     [key, val] = header.split(":");
      //                     console.log("[key, val]:\n", key, val);

      //                     config.headers[key] = val;
      //                   });
      //                 }

      //                 console.log("config:", config);

      //                 const response = await fetch(
      //                   `${props.Graph_API}`,
      //                   config
      //                 );

      //                 const responseData = await response.json();
      //                 apiData = responseData;
      //                 console.log(
      //                   "responseData:",
      //                   responseData,
      //                   "\napiData:",
      //                   apiData
      //                 );

      //                 // variables needed later confirming whether the value of the required keys are nested further with objects or not
      //                 let hasNestedArrayOfObjects =
      //                   hasArrayofObjects(apiData);
      //                 let abscissaIsNested = isNestedObject(
      //                   responseData[props.Abscissa]
      //                 );
      //                 let ordinateIsNested = isNestedObject(
      //                   responseData[props.Ordinate]
      //                 );

      //                 // MAKING IT WORK FOR UNIVERSAL API RESPONSES, below;
      //                 // not for response structures - array of arrays, object of array of objects, object of array of arrays

      //                 // checking if the reponse is an ARRAY of OBNECTS; and then creating the coordinates array xAxis, yAxis for plotting
      //                 if (
      //                   Array.isArray(apiData) &&
      //                   apiData.length > 0 &&
      //                   typeof apiData[0] === "object"
      //                 ) {
      //                   for (let objX of apiData) {
      //                     xAxis.push(
      //                       objX[!props.Abscissa ? "" : props.Abscissa]
      //                     );
      //                   }

      //                   for (let objY of apiData) {
      //                     yAxis.push(
      //                       objY[!props.Ordinate ? "" : props.Ordinate]
      //                     );
      //                   }
      //                 }

      //                 // checking if the reponse is an OBJECT but not an array; and then creating the coordinates array xAxis, yAxis for plotting
      //                 else if (
      //                   typeof apiData === "object" &&
      //                   !Array.isArray(apiData)
      //                 ) {
      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are an ARRAY also while those traits/values are not 'undefined'
      //                   if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     Array.isArray(apiData[props.Abscissa]) &&
      //                     props.Ordinate &&
      //                     Array.isArray(apiData[props.Ordinate])
      //                   ) {
      //                     // if the array contains a string amongst numbers, then don't assign it to the coordinate arrays
      //                     let xContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     xAxis = !xContainsString
      //                       ? apiData[props.Abscissa]
      //                       : [];

      //                     let yContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     yAxis = !yContainsString
      //                       ? apiData[props.Ordinate]
      //                       : [];
      //                   }

      //                   // now checking if the value of the required keys are PRIMITIVE values, neither arrays nor objects also while those traits/values are not 'undefined'
      //                   else if (
      //                     (!hasNestedArrayOfObjects &&
      //                       props.Abscissa &&
      //                       typeof apiData[props.Abscissa] !== "object") ||
      //                     (apiData[props.Abscissa] === null &&
      //                       props.Ordinate &&
      //                       typeof responseData[props.Ordinate] !==
      //                         "object") ||
      //                     responseData[props.Ordinate] === null
      //                   ) {
      //                     xAxis.push(apiData[props.Abscissa]);
      //                     yAxis.push(apiData[props.Ordinate]);
      //                   }

      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are again a NESTED OBJECT (using recursive helper function) also while those traits/values are not 'undefined'
      //                   else if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     abscissaIsNested &&
      //                     props.Ordinate &&
      //                     ordinateIsNested
      //                   ) {
      //                     console.log("inside 1");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData[props.Abscissa],
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData[props.Ordinate],
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     else if (
      //                       (xValue &&
      //                         yValue &&
      //                         !Array.isArray(xValue) &&
      //                         !Array.isArray(yValue) &&
      //                         typeof xValue !== "object") ||
      //                       (xValue === null && typeof yValue !== "object") ||
      //                       yValue === null
      //                     ) {
      //                       xAxis.push(xValue);
      //                       yAxis.push(yValue);
      //                     }
      //                   }

      //                   // now checking if the value of the response is nested also while those traits/values are not 'undefined'
      //                   else if (
      //                     props.Abscissa &&
      //                     props.Ordinate &&
      //                     hasNestedArrayOfObjects
      //                   ) {
      //                     console.log("inside 2");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     // else if (xValue && yValue && !Array.isArray(xValue) && !Array.isArray(yValue) && typeof xValue !== 'object' || xValue === null && typeof yValue !== 'object' || yValue === null && xValue !== 'NaN' && yValue !== 'NaN') {
      //                     else if (
      //                       typeof Number(xValue) === "number" &&
      //                       typeof Number(yValue) === "number"
      //                     ) {
      //                       xAxis.push(Number(xValue));
      //                       yAxis.push(Number(yValue));
      //                     }
      //                   }
      //                 }
      //               }

      //               // when api and api key is provided but not username and password
      //               else if (!username && !password && apiKey !== undefined) {
      //                 let apiData;
      //                 let config = {
      //                   headers: {
      //                     "X-API-KEY": `${apiKey}`,
      //                   },
      //                 };
      //                 // ['a:b', "c:d"]
      //                 // extracting headers from More_Headers trait
      //                 if (moreHeaders) {
      //                   moreHeaders.split(",").forEach((header) => {
      //                     let key, val;
      //                     [key, val] = header.split(":");
      //                     console.log("[key, val]:\n", key, val);

      //                     config.headers[key] = val;
      //                   });
      //                 }

      //                 console.log("config:", config);

      //                 let response = await fetch(
      //                   `${props.Graph_API}`,
      //                   config
      //                 );

      //                 let responseData = await response.json();
      //                 apiData = responseData;
      //                 console.log(
      //                   "responseData:",
      //                   responseData,
      //                   "\napiData:",
      //                   apiData
      //                 );

      //                 // variables needed later confirming whether the value of the required keys are nested further with objects or not
      //                 let hasNestedArrayOfObjects =
      //                   hasArrayofObjects(apiData);
      //                 let abscissaIsNested = isNestedObject(
      //                   responseData[props.Abscissa]
      //                 );
      //                 let ordinateIsNested = isNestedObject(
      //                   responseData[props.Ordinate]
      //                 );

      //                 // MAKING IT WORK FOR UNIVERSAL API RESPONSES, below;
      //                 // not for response structures - array of arrays, object of array of objects, object of array of arrays

      //                 // checking if the reponse is an ARRAY of OBNECTS; and then creating the coordinates array xAxis, yAxis for plotting
      //                 if (
      //                   Array.isArray(apiData) &&
      //                   apiData.length > 0 &&
      //                   typeof apiData[0] === "object"
      //                 ) {
      //                   for (let objX of apiData) {
      //                     xAxis.push(
      //                       objX[!props.Abscissa ? "" : props.Abscissa]
      //                     );
      //                   }

      //                   for (let objY of apiData) {
      //                     yAxis.push(
      //                       objY[!props.Ordinate ? "" : props.Ordinate]
      //                     );
      //                   }
      //                 }

      //                 // checking if the reponse is an OBJECT but not an array; and then creating the coordinates array xAxis, yAxis for plotting
      //                 else if (
      //                   typeof apiData === "object" &&
      //                   !Array.isArray(apiData)
      //                 ) {
      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are an ARRAY also while those traits/values are not 'undefined'
      //                   if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     Array.isArray(apiData[props.Abscissa]) &&
      //                     props.Ordinate &&
      //                     Array.isArray(apiData[props.Ordinate])
      //                   ) {
      //                     // if the array contains a string amongst numbers, then don't assign it to the coordinate arrays
      //                     let xContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     xAxis = !xContainsString
      //                       ? apiData[props.Abscissa]
      //                       : [];

      //                     let yContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     yAxis = !yContainsString
      //                       ? apiData[props.Ordinate]
      //                       : [];
      //                   }

      //                   // now checking if the value of the required keys are PRIMITIVE values, neither arrays nor objects also while those traits/values are not 'undefined'
      //                   else if (
      //                     (!hasNestedArrayOfObjects &&
      //                       props.Abscissa &&
      //                       typeof apiData[props.Abscissa] !== "object") ||
      //                     (apiData[props.Abscissa] === null &&
      //                       props.Ordinate &&
      //                       typeof responseData[props.Ordinate] !==
      //                         "object") ||
      //                     responseData[props.Ordinate] === null
      //                   ) {
      //                     xAxis.push(apiData[props.Abscissa]);
      //                     yAxis.push(apiData[props.Ordinate]);
      //                   }

      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are again a NESTED OBJECT (using recursive helper function) also while those traits/values are not 'undefined'
      //                   else if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     abscissaIsNested &&
      //                     props.Ordinate &&
      //                     ordinateIsNested
      //                   ) {
      //                     console.log("inside 1");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData[props.Abscissa],
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData[props.Ordinate],
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     else if (
      //                       (xValue &&
      //                         yValue &&
      //                         !Array.isArray(xValue) &&
      //                         !Array.isArray(yValue) &&
      //                         typeof xValue !== "object") ||
      //                       (xValue === null && typeof yValue !== "object") ||
      //                       yValue === null
      //                     ) {
      //                       xAxis.push(xValue);
      //                       yAxis.push(yValue);
      //                     }
      //                   }

      //                   // now checking if the value of the response is nested also while those traits/values are not 'undefined'
      //                   else if (
      //                     props.Abscissa &&
      //                     props.Ordinate &&
      //                     hasNestedArrayOfObjects
      //                   ) {
      //                     console.log("inside 2");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     // else if (xValue && yValue && !Array.isArray(xValue) && !Array.isArray(yValue) && typeof xValue !== 'object' || xValue === null && typeof yValue !== 'object' || yValue === null && xValue !== 'NaN' && yValue !== 'NaN') {
      //                     else if (
      //                       typeof Number(xValue) === "number" &&
      //                       typeof Number(yValue) === "number"
      //                     ) {
      //                       xAxis.push(Number(xValue));
      //                       yAxis.push(Number(yValue));
      //                     }
      //                   }
      //                 }
      //               }

      //               // when everything is provided
      //               else if (username && password && apiKey !== undefined) {
      //                 let apiData;
      //                 let config = {
      //                   headers: {
      //                     Authorization:
      //                       "Basic " + btoa(username + ":" + password),
      //                     "X-API-KEY": `${apiKey}`,
      //                   },
      //                 };
      //                 // ['a:b', "c:d"]
      //                 // extracting headers from More_Headers trait
      //                 if (moreHeaders) {
      //                   moreHeaders.split(",").forEach((header) => {
      //                     let key, val;
      //                     [key, val] = header.split(":");
      //                     console.log("[key, val]:\n", key, val);

      //                     config.headers[key] = val;
      //                   });
      //                 }

      //                 console.log("config:", config);

      //                 let response = await fetch(
      //                   `${props.Graph_API}`,
      //                   config
      //                 );

      //                 let responseData = await response.json();
      //                 apiData = responseData;
      //                 console.log(
      //                   "responseData:",
      //                   responseData,
      //                   "\napiData:",
      //                   apiData
      //                 );

      //                 // variables needed later confirming whether the value of the required keys are nested further with objects or not
      //                 let hasNestedArrayOfObjects =
      //                   hasArrayofObjects(apiData);
      //                 let abscissaIsNested = isNestedObject(
      //                   responseData[props.Abscissa]
      //                 );
      //                 let ordinateIsNested = isNestedObject(
      //                   responseData[props.Ordinate]
      //                 );

      //                 // MAKING IT WORK FOR UNIVERSAL API RESPONSES, below;
      //                 // not for response structures - array of arrays, object of array of objects, object of array of arrays

      //                 // checking if the reponse is an ARRAY of OBNECTS; and then creating the coordinates array xAxis, yAxis for plotting
      //                 if (
      //                   Array.isArray(apiData) &&
      //                   apiData.length > 0 &&
      //                   typeof apiData[0] === "object"
      //                 ) {
      //                   for (let objX of apiData) {
      //                     xAxis.push(
      //                       objX[!props.Abscissa ? "" : props.Abscissa]
      //                     );
      //                   }

      //                   for (let objY of apiData) {
      //                     yAxis.push(
      //                       objY[!props.Ordinate ? "" : props.Ordinate]
      //                     );
      //                   }
      //                 }

      //                 // checking if the reponse is an OBJECT but not an array; and then creating the coordinates array xAxis, yAxis for plotting
      //                 else if (
      //                   typeof apiData === "object" &&
      //                   !Array.isArray(apiData)
      //                 ) {
      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are an ARRAY also while those traits/values are not 'undefined'
      //                   if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     Array.isArray(apiData[props.Abscissa]) &&
      //                     props.Ordinate &&
      //                     Array.isArray(apiData[props.Ordinate])
      //                   ) {
      //                     // if the array contains a string amongst numbers, then don't assign it to the coordinate arrays
      //                     let xContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     xAxis = !xContainsString
      //                       ? apiData[props.Abscissa]
      //                       : [];

      //                     let yContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     yAxis = !yContainsString
      //                       ? apiData[props.Ordinate]
      //                       : [];
      //                   }

      //                   // now checking if the value of the required keys are PRIMITIVE values, neither arrays nor objects also while those traits/values are not 'undefined'
      //                   else if (
      //                     (!hasNestedArrayOfObjects &&
      //                       props.Abscissa &&
      //                       typeof apiData[props.Abscissa] !== "object") ||
      //                     (apiData[props.Abscissa] === null &&
      //                       props.Ordinate &&
      //                       typeof responseData[props.Ordinate] !==
      //                         "object") ||
      //                     responseData[props.Ordinate] === null
      //                   ) {
      //                     xAxis.push(apiData[props.Abscissa]);
      //                     yAxis.push(apiData[props.Ordinate]);
      //                   }

      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are again a NESTED OBJECT (using recursive helper function) also while those traits/values are not 'undefined'
      //                   else if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     abscissaIsNested &&
      //                     props.Ordinate &&
      //                     ordinateIsNested
      //                   ) {
      //                     console.log("inside 1");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData[props.Abscissa],
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData[props.Ordinate],
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     else if (
      //                       (xValue &&
      //                         yValue &&
      //                         !Array.isArray(xValue) &&
      //                         !Array.isArray(yValue) &&
      //                         typeof xValue !== "object") ||
      //                       (xValue === null && typeof yValue !== "object") ||
      //                       yValue === null
      //                     ) {
      //                       xAxis.push(xValue);
      //                       yAxis.push(yValue);
      //                     }
      //                   }

      //                   // now checking if the value of the response is nested also while those traits/values are not 'undefined'
      //                   else if (
      //                     props.Abscissa &&
      //                     props.Ordinate &&
      //                     hasNestedArrayOfObjects
      //                   ) {
      //                     console.log("inside 2");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     // else if (xValue && yValue && !Array.isArray(xValue) && !Array.isArray(yValue) && typeof xValue !== 'object' || xValue === null && typeof yValue !== 'object' || yValue === null && xValue !== 'NaN' && yValue !== 'NaN') {
      //                     else if (
      //                       typeof Number(xValue) === "number" &&
      //                       typeof Number(yValue) === "number"
      //                     ) {
      //                       xAxis.push(Number(xValue));
      //                       yAxis.push(Number(yValue));
      //                     }
      //                   }
      //                 }
      //               }

      //               // when api is provided but nothing else is provided
      //               else if (!username && !password && apiKey === undefined) {
      //                 let apiData;
      //                 let config = {
      //                   headers: {},
      //                 };
      //                 // ['a:b', "c:d"]
      //                 // extracting headers from More_Headers trait
      //                 if (moreHeaders) {
      //                   moreHeaders.split(",").forEach((header) => {
      //                     let key, val;
      //                     [key, val] = header.split(":");
      //                     console.log("[key, val]:\n", key, val);

      //                     config.headers[key] = val;
      //                   });
      //                 }

      //                 console.log("config:", config);

      //                 let response = await fetch(
      //                   `${props.Graph_API}`,
      //                   config
      //                 );

      //                 let responseData = await response.json();
      //                 apiData = responseData;
      //                 console.log(
      //                   "responseData:",
      //                   responseData,
      //                   "\napiData:",
      //                   apiData
      //                 );

      //                 // variables needed later confirming whether the value of the required keys are nested further with objects or not
      //                 let hasNestedArrayOfObjects =
      //                   hasArrayofObjects(apiData);
      //                 let abscissaIsNested = isNestedObject(
      //                   apiData[props.Abscissa]
      //                 );
      //                 let ordinateIsNested = isNestedObject(
      //                   apiData[props.Ordinate]
      //                 );

      //                 // MAKING IT WORK FOR UNIVERSAL API RESPONSES, below;
      //                 // not for response structures - array of arrays, object of array of objects, object of array of arrays

      //                 // checking if the reponse is an ARRAY of OBNECTS; and then creating the coordinates array xAxis, yAxis for plotting
      //                 if (
      //                   Array.isArray(apiData) &&
      //                   apiData.length > 0 &&
      //                   typeof apiData[0] === "object"
      //                 ) {
      //                   for (let objX of apiData) {
      //                     xAxis.push(
      //                       objX[!props.Abscissa ? "" : props.Abscissa]
      //                     );
      //                   }

      //                   for (let objY of apiData) {
      //                     yAxis.push(
      //                       objY[!props.Ordinate ? "" : props.Ordinate]
      //                     );
      //                   }
      //                 }

      //                 // checking if the reponse is an OBJECT but not an array; and then creating the coordinates array xAxis, yAxis for plotting
      //                 else if (
      //                   typeof apiData === "object" &&
      //                   !Array.isArray(apiData)
      //                 ) {
      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are an ARRAY also while those traits/values are not 'undefined'
      //                   if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     Array.isArray(apiData[props.Abscissa]) &&
      //                     props.Ordinate &&
      //                     Array.isArray(apiData[props.Ordinate])
      //                   ) {
      //                     // if the array contains a string amongst numbers, then don't assign it to the coordinate arrays
      //                     let xContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     xAxis = !xContainsString
      //                       ? apiData[props.Abscissa]
      //                       : [];

      //                     let yContainsString = apiData[props.Abscissa].some(
      //                       (element) => typeof element === "string"
      //                     );
      //                     yAxis = !yContainsString
      //                       ? apiData[props.Ordinate]
      //                       : [];
      //                   }

      //                   // now checking if the value of the required keys are PRIMITIVE values, neither arrays nor objects also while those traits/values are not 'undefined'
      //                   else if (
      //                     (!hasNestedArrayOfObjects &&
      //                       props.Abscissa &&
      //                       typeof apiData[props.Abscissa] !== "object") ||
      //                     (apiData[props.Abscissa] === null &&
      //                       props.Ordinate &&
      //                       typeof responseData[props.Ordinate] !==
      //                         "object") ||
      //                     responseData[props.Ordinate] === null
      //                   ) {
      //                     xAxis.push(apiData[props.Abscissa]);
      //                     yAxis.push(apiData[props.Ordinate]);
      //                   }

      //                   // now checking if the value of the required keys - Abscissa, Ordinate (entered in the trait) are again a NESTED OBJECT (using recursive helper function) also while those traits/values are not 'undefined'
      //                   else if (
      //                     !hasNestedArrayOfObjects &&
      //                     props.Abscissa &&
      //                     abscissaIsNested &&
      //                     props.Ordinate &&
      //                     ordinateIsNested
      //                   ) {
      //                     console.log("inside 1");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData[props.Abscissa],
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData[props.Ordinate],
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     else if (
      //                       (xValue &&
      //                         yValue &&
      //                         !Array.isArray(xValue) &&
      //                         !Array.isArray(yValue) &&
      //                         typeof xValue !== "object") ||
      //                       (xValue === null && typeof yValue !== "object") ||
      //                       yValue === null
      //                     ) {
      //                       xAxis.push(xValue);
      //                       yAxis.push(yValue);
      //                     }
      //                   }

      //                   // now checking if the value of the response is nested also while those traits/values are not 'undefined'
      //                   else if (
      //                     props.Abscissa &&
      //                     props.Ordinate &&
      //                     hasNestedArrayOfObjects
      //                   ) {
      //                     console.log("inside 2");
      //                     let xValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Abscissa
      //                     );
      //                     let yValue = findKeyInNestedObject(
      //                       apiData,
      //                       props.Ordinate
      //                     );

      //                     // if the value of the nested key is an ARRAY, then assign them to the coordinate array
      //                     if (
      //                       xValue &&
      //                       yValue &&
      //                       Array.isArray(xValue) &&
      //                       Array.isArray(yValue)
      //                     ) {
      //                       xAxis = xValue;
      //                       yAxis = yValue;
      //                     }

      //                     // if the value of the nested key is a PRIMITIVE value alone, then push them in the coordinate array
      //                     // else if (xValue && yValue && !Array.isArray(xValue) && !Array.isArray(yValue) && typeof xValue !== 'object' || xValue === null && typeof yValue !== 'object' || yValue === null && xValue !== 'NaN' && yValue !== 'NaN') {
      //                     else if (
      //                       typeof Number(xValue) === "number" &&
      //                       typeof Number(yValue) === "number"
      //                     ) {
      //                       xAxis.push(Number(xValue));
      //                       yAxis.push(Number(yValue));
      //                     }
      //                   }
      //                 }
      //               }

      //               console.log("xAxis:", xAxis, "\nyAxis:", yAxis);

      //               // const labels = [
      //               //   "January",
      //               //   "February",
      //               //   "March",
      //               //   "April",
      //               //   "May",
      //               //   "June",
      //               // ];

      //               // animations
      //               let animate;
      //               let transition;
      //               let delayed;

      //               if (props.Animation === "delay") {
      //                 animate = {
      //                   onComplete: () => {
      //                     delayed = true;
      //                   },

      //                   delay: (context) => {
      //                     let delay = 0;
      //                     if (
      //                       context.type === "data" &&
      //                       context.mode === "default" &&
      //                       !delayed
      //                     ) {
      //                       delay =
      //                         context.dataIndex * 300 +
      //                         context.datasetIndex * 100;
      //                     }
      //                     return delay;
      //                   },
      //                 };
      //               } else if (props.Animation === "hideAndShow") {
      //                 animate = {};
      //                 transition = {
      //                   show: {
      //                     animations: {
      //                       x: {
      //                         from: 0,
      //                       },
      //                       y: {
      //                         from: 0,
      //                       },
      //                     },
      //                   },

      //                   hide: {
      //                     animations: {
      //                       x: {
      //                         to: 0,
      //                       },
      //                       y: {
      //                         to: 0,
      //                       },
      //                     },
      //                   },
      //                 };
      //               }

      //               const data = {
      //                 // x-axis
      //                 labels: xAxis,
      //                 datasets: [
      //                   {
      //                     label: props.Abscissa,
      //                     // backgroundColor: ['red', 'green', 'blue'],
      //                     backgroundColor: !props.Bar_Colors
      //                       ? ["red", "green", "blue"]
      //                       : props.Bar_Colors.split(","),
      //                     borderColor: !props.Border_Color
      //                       ? "rgb(255, 99, 132)"
      //                       : props.Border_Color,
      //                     borderWidth: !props.Border_Width
      //                       ? 2
      //                       : props.Border_Width,
      //                     // y-axis
      //                     data: yAxis,
      //                     // color: ['red', 'green', 'blue'],
      //                   },
      //                 ],
      //               };

      //               const config = {
      //                 type: "polarArea",
      //                 data: data,
      //                 options: {
      //                   animation: animate,
      //                   transitions: transition,
      //                   stacked: false,
      //                   responsive: true,
      //                 },
      //               };

      //               const ctx = document
      //                 .getElementById("polarAreaChart")
      //                 .getContext("2d");
      //               const myChart = new Chart(ctx, config);
      //             } catch (e) {
      //               console.log(e);
      //             }
      //           }
      //         };

      //         if (typeof Chart == "undefined") {
      //           const script = document.createElement("script");
      //           script.onload = initLib;
      //           script.src = "https://cdn.jsdelivr.net/npm/chart.js";
      //           document.body.appendChild(script);
      //         } else {
      //           console.log("InitLib");
      //           initLib();
      //         }
      //       },

      //       Graph_API: "",
      //       Username: "",
      //       Password: "",
      //       API_Key: "",
      //       More_Headers: "", // input should be like: a:b,c:d,e:f

      //       Animation: "",
      //       Abscissa: "",
      //       Ordinate: "",
      //       Bar_Colors: "",
      //       Border_Width: "",
      //       Border_Color: "",
      //       // Fill: "",

      //       traits: [
      //         {
      //           type: "text",
      //           name: "Graph_API",
      //           label: "Graph API",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Username",
      //           label: "Username",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Password",
      //           label: "Password",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "API_Key",
      //           label: "API Key",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "More_Headers",
      //           label: "More Headers",
      //           changeProp: true,
      //         },
      //         {
      //           type: "select",
      //           name: "Animation",
      //           label: "Pre-defined Graph Animations",
      //           options: [
      //             { id: "delay", name: "Delay" },
      //             { id: "hideAndShow", name: "Hide And Show" },
      //           ],
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Abscissa",
      //           label: "Abscissa",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Ordinate",
      //           label: "Ordinates",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Bar_Colors",
      //           label: "Datapoint Colors",
      //           changeProp: true,
      //         },
      //         {
      //           type: "range",
      //           min: "0",
      //           max: "20",
      //           name: "Border_Width",
      //           label: "Border Width [0px to 20px]",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Border_Color",
      //           label: "Border Color",
      //           changeProp: true,
      //         },
      //         // {
      //         //     type: 'checkbox',
      //         //     name: 'Fill',
      //         //     label: 'Fill Area',
      //         //     changeProp: true,
      //         // },
      //       ],

      //       changeProp: true,

      //       "script-props": [
      //         "Graph_API",
      //         "Username",
      //         "Password",
      //         "API_Key",
      //         "More_Headers",
      //         "Animation",
      //         "Abscissa",
      //         "Ordinate",
      //         "Bar_Colors",
      //         "Border_Width",
      //         "Border_Color",
      //       ],
      //     },
      //   },
      //   isComponent: (el) => {
      //     if (
      //       el.getAttribute &&
      //       el.getAttribute("data-gjs-type") == "polarAreaChart"
      //     ) {
      //       return "polarAreaChart";
      //     }
      //   },
      //   view: defaultView.extend({
      //     init({ model }) {},
      //   }),
      // });

      // editor.DomComponents.addType("lineBarBubbleMixChart", {
      //   model: {
      //     defaults: {
      //       script: function (props) {
      //         const initLib = async function () {
      //           let username = "SLTECH1";
      //           let password = "Welcome@123";

      //           let xAxis = []; // data for X axis
      //           let yAxisLine = []; // data for Y axis line graph
      //           let yAxisBar = []; // data for Y axis bar graph
      //           let yAxisBubble = []; // data for Y axis bubble graph

      //           console.log("Graph props:", props);

      //           if (props.Graph_API) {
      //             try {
      //               // By Invoice URL
      //               // let response = await fetch('http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&INVOICE=AR25634D', {

      //               // By Date URL
      //               // let response = await fetch('http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&DATE=20230102', {

      //               // By Customer URL
      //               // let response = await fetch('http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&CUSTOMER=DENCO%20EQUIPMENTS%20LIMITED', {

      //               // By Coin URL
      //               // let response = await fetch('https://api.coinranking.com/v2/coins', {

      //               // By Month URL
      //               // http://0.0.0.0:8080/183.82.1.220:8000/wem_invoice/invoice?sap-client=800&MONTH=MAR
      //               let response = await fetch(`${props.Graph_API}`, {
      //                 headers: {
      //                   Authorization:
      //                     "Basic " + btoa(username + ":" + password),
      //                 },
      //               });
      //               let responseData = await response.json();
      //               console.log("responseData:", responseData);

      //               for (let objX of responseData) {
      //                 xAxis.push(objX[!props.Abscissa ? "" : props.Abscissa]);
      //               }

      //               for (let objYLine of responseData) {
      //                 yAxisLine.push(
      //                   objYLine[
      //                     !props.LineGraphOrdinate
      //                       ? ""
      //                       : props.LineGraphOrdinate
      //                   ]
      //                 );
      //               }

      //               for (let objYBar of responseData) {
      //                 yAxisBar.push(
      //                   objYBar[
      //                     !props.BarGraphOrdinate
      //                       ? ""
      //                       : props.BarGraphOrdinate
      //                   ]
      //                 );
      //               }

      //               for (let objYBubble of responseData) {
      //                 yAxisBubble.push(
      //                   objYBubble[
      //                     !props.BubbleGraphOrdinate
      //                       ? ""
      //                       : props.BubbleGraphOrdinate
      //                   ]
      //                 );
      //               }

      //               // const labels = [
      //               //   "January",
      //               //   "February",
      //               //   "March",
      //               //   "April",
      //               //   "May",
      //               //   "June",
      //               // ];

      //               // animations
      //               let animate;
      //               let transition;
      //               let delayed;

      //               if (props.Animation === "delay") {
      //                 animate = {
      //                   onComplete: () => {
      //                     delayed = true;
      //                   },

      //                   delay: (context) => {
      //                     let delay = 0;
      //                     if (
      //                       context.type === "data" &&
      //                       context.mode === "default" &&
      //                       !delayed
      //                     ) {
      //                       delay =
      //                         context.dataIndex * 300 +
      //                         context.datasetIndex * 100;
      //                     }
      //                     return delay;
      //                   },
      //                 };
      //               } else if (props.Animation === "hideAndShow") {
      //                 animate = {};
      //                 transition = {
      //                   show: {
      //                     animations: {
      //                       x: {
      //                         from: 0,
      //                       },
      //                       y: {
      //                         from: 0,
      //                       },
      //                     },
      //                   },

      //                   hide: {
      //                     animations: {
      //                       x: {
      //                         to: 0,
      //                       },
      //                       y: {
      //                         to: 0,
      //                       },
      //                     },
      //                   },
      //                 };
      //               }

      //               // const data = {
      //               //     // x-axis
      //               //     labels: xAxis,
      //               //     datasets: [
      //               //         {
      //               //             label: props.Abscissa,
      //               //             // backgroundColor: ['red', 'green', 'blue'],
      //               //             backgroundColor: !props.Bar_Colors ? ['red', 'green', 'blue'] : props.Bar_Colors.split(','),
      //               //             borderColor: "rgb(255, 99, 132)",
      //               //             // y-axis
      //               //             data: yAxis,
      //               //             // color: ['red', 'green', 'blue'],
      //               //         },
      //               //     ],
      //               // };

      //               const data = {
      //                 labels: xAxis,

      //                 // datasets: an array of objects [ { }, { }, ]
      //                 datasets: [
      //                   // if BarGraphOrdinate === 'true' in traits, then plot bar graph, else pass an empty array
      //                   props.MixInBarChartType
      //                     ? {
      //                         label: !props.BarLabel
      //                           ? "Bar Dataset"
      //                           : props.BarLabel,
      //                         data: yAxisBar,
      //                         backgroundColor: !props.Bar_Colors
      //                           ? ["red", "green", "blue"]
      //                           : props.Bar_Colors.split(","),
      //                         borderColor: !props.Bar_Border_Color
      //                           ? "rgb(255, 99, 132)"
      //                           : props.Bar_Border_Color,
      //                         borderWidth: !props.Bar_Border_Width
      //                           ? 2
      //                           : props.Bar_Border_Width,
      //                         // this dataset is drawn below
      //                         order: !props.BarOrder ? 2 : props.BarOrder,
      //                       }
      //                     : {},

      //                   props.MixInLineChartType
      //                     ? {
      //                         label: !props.LineLabel
      //                           ? "Line Dataset"
      //                           : props.LineLabel,
      //                         data: yAxisLine,
      //                         type: "line",
      //                         backgroundColor: !props.Line_Colors
      //                           ? ["cyan", "magenta", "yellow"]
      //                           : props.Line_Colors.split(","),
      //                         borderColor: !props.Line_Border_Color
      //                           ? "rgb(255, 99, 132)"
      //                           : props.Line_Border_Color,
      //                         borderWidth: !props.Line_Border_Width
      //                           ? 2
      //                           : props.Line_Border_Width,
      //                         // this dataset is drawn on top
      //                         order: !props.LineOrder ? 1 : props.LineOrder,
      //                       }
      //                     : {},

      //                   props.MixInBubbleChartType
      //                     ? {
      //                         label: !props.BubbleLabel
      //                           ? "Bubble Dataset"
      //                           : props.BubbleLabel,
      //                         data: yAxisBubble,
      //                         type: "bubble",
      //                         backgroundColor: !props.Bubble_Colors
      //                           ? ["black", "gray", "purple"]
      //                           : props.Bubble_Colors.split(","),
      //                         borderColor: !props.Bubble_Border_Color
      //                           ? "rgb(255, 99, 132)"
      //                           : props.Bubble_Border_Color,
      //                         borderWidth: !props.Bubble_Border_Width
      //                           ? 2
      //                           : props.Bubble_Border_Width,
      //                         // this dataset is drawn on top
      //                         order: !props.BubbleOrder
      //                           ? 1
      //                           : props.BubbleOrder,
      //                       }
      //                     : {},
      //                 ],
      //               };

      //               const config = {
      //                 type: "bar",
      //                 data: data,
      //                 options: {
      //                   animation: animate,
      //                   transitions: transition,
      //                 },
      //               };

      //               const ctx = document
      //                 .getElementById("lineBarBubbleMixChart")
      //                 .getContext("2d");
      //               const myChart = new Chart(ctx, config);
      //             } catch (e) {
      //               console.log(e);
      //             }
      //           }
      //         };

      //         if (typeof Chart == "undefined") {
      //           const script = document.createElement("script");
      //           script.onload = initLib;
      //           script.src = "https://cdn.jsdelivr.net/npm/chart.js";
      //           document.body.appendChild(script);
      //         } else {
      //           console.log("InitLib");
      //           initLib();
      //         }
      //       },

      //       Graph_API: "",

      //       MixInLineChartType: "",
      //       MixInBarChartType: "",
      //       MixInBubbleChartType: "",

      //       Animation: "",

      //       BarLabel: "",
      //       LineLabel: "",
      //       BubbleLabel: "",

      //       Abscissa: "",
      //       LineGraphOrdinate: "",
      //       BarGraphOrdinate: "",
      //       BubbleGraphOrdinate: "",

      //       Bar_Colors: "",
      //       Line_Colors: "",
      //       Bubble_Colors: "",

      //       Bar_Border_Color: "",
      //       Line_Border_Color: "",
      //       Bubble_Border_Color: "",

      //       Bar_Border_Width: "",
      //       Line_Border_Width: "",
      //       Bubble_Border_Width: "",

      //       BarOrder: "",
      //       LineOrder: "",
      //       BubbleOrder: "",

      //       traits: [
      //         {
      //           type: "text",
      //           name: "Graph_API",
      //           label: "Graph API",
      //           changeProp: true,
      //         },
      //         {
      //           type: "checkbox",
      //           name: "MixInLineChartType",
      //           label: "Mix In Line Chart",
      //           changeProp: true,
      //         },
      //         {
      //           type: "checkbox",
      //           name: "MixInBarChartType",
      //           label: "Mix In Bar Chart",
      //           changeProp: true,
      //         },
      //         {
      //           type: "checkbox",
      //           name: "MixInBubbleChartType",
      //           label: "Mix In Bubble Chart",
      //           changeProp: true,
      //         },
      //         {
      //           type: "select",
      //           name: "Animation",
      //           label: "Pre-defined Graph Animations",
      //           options: [
      //             { id: "delay", name: "Delay" },
      //             { id: "hideAndShow", name: "Hide And Show" },
      //           ],
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "BarLabel",
      //           label: "Bar Label",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "LineLabel",
      //           label: "Line Label",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "BubbleLabel",
      //           label: "Bubble Label",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Abscissa",
      //           label: "Abscissa",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "LineGraphOrdinate",
      //           label: "Line Graph Ordinate",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "BarGraphOrdinate",
      //           label: "Bar Graph Ordinate",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "BubbleGraphOrdinate",
      //           label: "Bubble Graph Ordinate",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Bar_Colors",
      //           label: "Bar Colors",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Line_Colors",
      //           label: "Line Colors",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Bubble_Colors",
      //           label: "Bubble Colors",
      //           changeProp: true,
      //         },
      //         {
      //           type: "range",
      //           min: "0",
      //           max: "20",
      //           name: "Bar_Border_Width",
      //           label: "Bar Border Width [0px to 20px]",
      //           changeProp: true,
      //         },
      //         {
      //           type: "range",
      //           min: "0",
      //           max: "20",
      //           name: "Line_Border_Width",
      //           label: "Line Border Width [0px to 20px]",
      //           changeProp: true,
      //         },
      //         {
      //           type: "range",
      //           min: "0",
      //           max: "20",
      //           name: "Bubble_Border_Width",
      //           label: "Bubble Border Width [0px to 20px]",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Bar_Border_Color",
      //           label: "Bar Border Colors",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Line_Border_Color",
      //           label: "Line Border Colors",
      //           changeProp: true,
      //         },
      //         {
      //           type: "text",
      //           name: "Bubble_Border_Color",
      //           label: "Bubble Border Colors",
      //           changeProp: true,
      //         },
      //         {
      //           type: "number",
      //           name: "LineOrder",
      //           label: "Line Chart Order",
      //           changeProp: true,
      //         },
      //         {
      //           type: "number",
      //           name: "BarOrder",
      //           label: "Bar Chart Order",
      //           changeProp: true,
      //         },
      //         {
      //           type: "number",
      //           name: "BubbleOrder",
      //           label: "Bubble Chart Order",
      //           changeProp: true,
      //         },
      //       ],

      //       changeProp: true,

      //       "script-props": [
      //         "Graph_API",
      //         "MixInLineChartType",
      //         "MixInBarChartType",
      //         "MixInBubbleChartType",
      //         "BarLabel",
      //         "LineLabel",
      //         "BubbleLabel",
      //         "Animation",
      //         "Abscissa",
      //         "LineGraphOrdinate",
      //         "BarGraphOrdinate",
      //         "BubbleGraphOrdinate",
      //         "Bar_Colors",
      //         "Line_Colors",
      //         "Bubble_Colors",
      //         "LineOrder",
      //         "BarOrder",
      //         "BubbleOrder",
      //         "Bar_Border_Width",
      //         "Line_Border_Width",
      //         "Bubble_Border_Width",
      //         "Bar_Border_Color",
      //         "Line_Border_Color",
      //         "Bubble_Border_Color",
      //       ],
      //     },
      //   },
      //   isComponent: (el) => {
      //     if (
      //       el.getAttribute &&
      //       el.getAttribute("data-gjs-type") == "lineBarBubbleMixChart"
      //     ) {
      //       return "lineBarBubbleMixChart";
      //     }
      //   },
      //   view: defaultView.extend({
      //     init({ model }) {},
      //   }),
      // });

      editor.Components.addType("radio-regular-form", {
        model: {
          defaults: {
            tagName: "div",
            attributes: { id: "radio-regular" },

            styles: `
                              #radio-regular {
                                  display: flex;
                                   column-gap: 0.5em;
                              }
                          `,

            components: [
              {
                type: "text",
                content: `<div>
                      <input contenteditable="true" type="radio" id="option" name="option" value="option1" style="margin-right: 0.1em;">
                      <label contenteditable="true" for="option">option1</label>
                      <input contenteditable="true" type="radio" id="option" name="option" value="option2" style="margin-right: 0.1em;">
                      <label contenteditable="true" for="option">option2</label>
                  </div>`,
              },
            ],

            // traits
            //TempVar: "",

            traits: [
              // {
              //   type: "select",
              //   name: "TempVar",
              //   label: "TempVar",
              //   changeProp: true,
              //   options:
              //     tempVarList && tempVarList.current !== null
              //       ? [
              //           ...tempVarList.current.map((x) => ({
              //             value: x._id,
              //             name: x.name,
              //           })),
              //           { value: "none", name: "None" },
              //         ]
              //       : "",
              // },
            ],

            //"script-props": ["TempVar"],
          },
        },

        view: {
          events: {
            // click: function (event) {
            //   var selectedFunction = this.model.get("TempVar");
            //   console.log("selectedFunction", selectedFunction);
            // },
          },
        },
      });

      editor.Components.addType("smiley-ratings-slider", {
        model: {
          defaults: {
            tagName: "div",
            //attributes: { id: "radio-regular" },

            styles: `
                    #rating-container {
                      width: 100%;
                      //margin: 20px auto;
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                  }
          
                  #rating-slider-container {
                      width: 100%;
                      position: relative;
                  }
          
                  #rating-slider {
                      width: 100%;
                  }
          
                  #smiley {
                      font-size: 24px;
                      position: absolute;
                      top: -30px;
                      left: 0;
                      transform: translateX(-50%);
                  }
          
                  #rating-scale {
                      width: 100%;
                      display: flex;
                      justify-content: space-between;
                      font-size: 18px;
                      flex-direction: row;
                  }
                          `,
            script: ratingSliderScript,
            // components: [
            //   {
            //     type: "text",
            //     content: `
            //     <div id="rating-container">
            //     <div id="rating-slider-container">
            //         <div id="smiley">😄</div>
            //         <input type="range" id="rating-slider" name="rating" min="1" max="10" value="5" contenteditable="true">
            //     </div>
            //     <div id="rating-scale">
            //         <span>1</span>
            //         <span>2</span>
            //         <span>3</span>
            //         <span>4</span>
            //         <span>5</span>
            //         <span>6</span>
            //         <span>7</span>
            //         <span>8</span>
            //         <span>9</span>
            //         <span>10</span>
            //     </div>
            //     <input type="hidden" id="hidden-rating" name="hidden-rating" value="5">
            //     </div>`,
            //   },
            // ],
            attributes: { class: "rating-container" },
            components: [
              {
                type: "text",
                content: "😄",
                tagName: "div",
                attributes: { id: "smiley" },
              },
              {
                type: "input",
                tagName: "input",
                attributes: {
                  type: "range",
                  id: "rating-slider",
                  name: "rating",
                  min: "1",
                  max: "10",
                  value: "5",
                  contenteditable: "true",
                  style: "width: -webkit-fill-available",
                },
              },
              {
                type: "text",
                tagName: "div",
                attributes: { id: "rating-scale" },
                style: {
                  width: "-webkit-fill-available",
                  display: "flex",
                  "justify-content": "space-between",
                  "font-size": "18px",
                  "flex-direction": "row",
                },
                components: [
                  { content: "1" },
                  { content: "2" },
                  { content: "3" },
                  { content: "4" },
                  { content: "5" },
                  { content: "6" },
                  { content: "7" },
                  { content: "8" },
                  { content: "9" },
                  { content: "10" },
                ],
              },
              {
                type: "input",
                tagName: "input",
                attributes: {
                  type: "hidden",
                  id: "hidden-rating",
                  name: "hidden-rating",
                  value: "5",
                },
              },
            ],

            // traits
            //TempVar: "",

            traits: [
              "name",
              "id",
              // {
              //   type: "select",
              //   name: "TempVar",
              //   label: "TempVar",
              //   changeProp: true,
              //   options:
              //     tempVarList && tempVarList.current !== null
              //       ? [
              //           ...tempVarList.current.map((x) => ({
              //             value: x._id,
              //             name: x.name,
              //           })),
              //           { value: "none", name: "None" },
              //         ]
              //       : "",
              // },
            ],
          },
        },

        view: {
          events: {
            // click: function (event) {
            //   var selectedFunction = this.model.get("TempVar");
            //   console.log("selectedFunction", selectedFunction);
            // },
          },
        },
      });

      editor.Components.addType("colorPalette-regular-form", {
        model: {
          defaults: {
            tagName: "span",
            attributes: {
              id: "colorPalette-regular-form",
              class: "colorPalette-regular-form",
            },
            content:
              '<input type="color" id="color" name="color" value="ff0000" placeholder="Type your name, here" contenteditable="true">',

            // traits
            //TempVar: "",

            traits: [
              "name",
              "placeholder",
              "id",
              "value",
              // {
              //   type: "select",
              //   name: "TempVar",
              //   label: "TempVar",
              //   changeProp: true,
              //   options:
              //     tempVarList && tempVarList.current !== null
              //       ? [
              //           ...tempVarList.current.map((x) => ({
              //             value: x._id,
              //             name: x.name,
              //           })),
              //           { value: "none", name: "None" },
              //         ]
              //       : "",
              // },
            ],

            changeProp: true,

            //"script-props": ["TempVar"],
          },
        },

        view: {
          events: {
            // click: function (event) {
            //   var selectedFunction = this.model.get("TempVar");
            //   console.log("selectedFunction", selectedFunction);
            // },
          },
        },
      });

      editor.Components.addType("week-regular-form", {
        model: {
          defaults: {
            tagName: "span",
            attributes: {
              id: "week-regular-form",
              class: "week-regular-form",
            },
            content:
              '<input type="week" id="week" name="week" contenteditable="true">',

            // traits
            //TempVar: "",

            traits: [
              "name",
              "placeholder",
              "id",
              // {
              //   type: "select",
              //   name: "TempVar",
              //   label: "TempVar",
              //   changeProp: true,
              //   options:
              //     tempVarList && tempVarList.current !== null
              //       ? [
              //           ...tempVarList.current.map((x) => ({
              //             value: x._id,
              //             name: x.name,
              //           })),
              //           { value: "none", name: "None" },
              //         ]
              //       : "",
              // },
            ],

            changeProp: true,

            //"script-props": ["TempVar"],
          },
        },

        view: {
          events: {
            // click: function (event) {
            //   var selectedFunction = this.model.get("TempVar");
            //   console.log("selectedFunction", selectedFunction);
            // },
          },
        },
      });

      editor.Components.addType("monthYear-regular-form", {
        model: {
          defaults: {
            tagName: "span",
            attributes: {
              id: "monthYear-regular-form",
              class: "monthYear-regular-form",
            },
            content:
              '<input type="month" id="month" name="month" contenteditable="true">',

            // traits
            //TempVar: "",

            traits: [
              "name",
              "placeholder",
              "id",
              // {
              //   type: "select",
              //   name: "TempVar",
              //   label: "TempVar",
              //   changeProp: true,
              //   options:
              //     tempVarList && tempVarList.current !== null
              //       ? [
              //           ...tempVarList.current.map((x) => ({
              //             value: x._id,
              //             name: x.name,
              //           })),
              //           { value: "none", name: "None" },
              //         ]
              //       : "",
              // },
            ],

            changeProp: true,

            //"script-props": ["TempVar"],
          },
        },

        view: {
          events: {
            // click: function (event) {
            //   var selectedFunction = this.model.get("TempVar");
            //   console.log("selectedFunction", selectedFunction);
            // },
          },
        },
      });

      editor.Components.addType("time-regular-form", {
        model: {
          defaults: {
            tagName: "span",
            attributes: {
              id: "time-regular-form",
              class: "time-regular-form",
            },
            content:
              '<input type="time" id="time" name="time" contenteditable="true">',

            // traits
            //TempVar: "",

            traits: [
              "name",
              "placeholder",
              "id",
              // {
              //   type: "select",
              //   name: "TempVar",
              //   label: "TempVar",
              //   changeProp: true,
              //   options:
              //     tempVarList && tempVarList.current !== null
              //       ? [
              //           ...tempVarList.current.map((x) => ({
              //             value: x._id,
              //             name: x.name,
              //           })),
              //           { value: "none", name: "None" },
              //         ]
              //       : "",
              // },
            ],

            changeProp: true,

            //"script-props": ["TempVar"],
          },
        },

        view: {
          events: {
            // click: function (event) {
            //   var selectedFunction = this.model.get("TempVar");
            //   console.log("selectedFunction", selectedFunction);
            // },
          },
        },
      });

      editor.Components.addType("file-regular-form", {
        model: {
          defaults: {
            tagName: "span",
            attributes: {
              id: "file-regular-form",
              class: "file-regular-form",
            },
            content:
              '<input type="file" id="file" name="file" contenteditable="true">',

            // traits
            //TempVar: "",

            traits: [
              "name",
              "placeholder",
              "id",
              "accept",
              "capture",
              {
                type: "checkbox",
                name: "multiple",
                label: "Multiple",
                changeProp: true,
              },
              // {
              //   type: "select",
              //   name: "TempVar",
              //   label: "TempVar",
              //   changeProp: true,
              //   options:
              //     tempVarList && tempVarList.current !== null
              //       ? [
              //           ...tempVarList.current.map((x) => ({
              //             value: x._id,
              //             name: x.name,
              //           })),
              //           { value: "none", name: "None" },
              //         ]
              //       : "",
              // },
            ],

            changeProp: true,

            //"script-props": ["TempVar"],
          },
        },

        view: {
          events: {
            // click: function (event) {
            //   var selectedFunction = this.model.get("TempVar");
            //   console.log("selectedFunction", selectedFunction);
            // },
          },
        },
      });

      editor.Components.addType("number-regular-form", {
        model: {
          defaults: {
            tagName: "span",
            attributes: {
              id: "number-regular-form",
              class: "number-regular-form",
            },
            content:
              '<input type="number" id="number" name="number" placeholder="Type your name, here" contenteditable="true">',

            // traits
            //TempVar: "",

            traits: [
              "name",
              "placeholder",
              "id",
              "min",
              "max",
              // {
              //   type: "select",
              //   name: "TempVar",
              //   label: "TempVar",
              //   changeProp: true,
              //   options:
              //     tempVarList && tempVarList.current !== null
              //       ? [
              //           ...tempVarList.current.map((x) => ({
              //             value: x._id,
              //             name: x.name,
              //           })),
              //           { value: "none", name: "None" },
              //         ]
              //       : "",
              // },
            ],

            changeProp: true,

            //"script-props": ["TempVar"],
          },
        },

        view: {
          onRender({ el, model }) {
            if (el.id === "number") {
              console.log("el before:", el);
              console.log(
                "changing the name trait of the element to:",
                this.model.get("name")
              );

              el.setAttribute("name", `${this.model.get("name")}`);

              console.log("el after:", el);
            }
          },
          events: {
            // click: function (event) {
            //   event.preventDefault();
            //   var selectedFunction = this.model.get("TempVar");
            //   console.log("selectedFunction", selectedFunction);
            // },
          },
        },
      });

      const sliderScript = function (props) {
        console.log(props);

        if (props.min) {
          this.getElementsByClassName(
            "range-regular-form-slider"
          )[0].setAttribute("min", `${props.min}`);
        }
        if (props.max) {
          this.getElementsByClassName(
            "range-regular-form-slider"
          )[0].setAttribute("max", `${props.max}`);
        }

        let slider = this.getElementsByClassName(
          "range-regular-form-slider"
        )[0];
        let sliderTooltip = this.getElementsByClassName(
          "range-regular-form-slider-tooltip"
        )[0];

        // make the tooptip appear on hovering on the slider
        slider.addEventListener("mouseover", function (event) {
          sliderTooltip.style.visibility = "visible";
          sliderTooltip.style.opacity = "1";
        });
        // make the tooptip disappear on stopping hovering on the slider
        slider.addEventListener("mouseout", function (event) {
          sliderTooltip.style.visibility = "hidden";
          sliderTooltip.style.opacity = "0";
        });

        slider.addEventListener("change", function (event) {
          sliderTooltip.innerHTML = this.value;

          // const toolTipLeft = ((this.value / 100) * 10) + 50;
          const toolTipLeft = (this.value / props.max) * 100;
          console.log("toolTipLeft", toolTipLeft);
          sliderTooltip.style.left = `${toolTipLeft}%`;
        });

        // // to keep the tooltip visible
        // if (props.show) {
        //   sliderTooltip.style.visibility = 'visible';
        //   sliderTooltip.style.opacity = '1';
        // }
        // // to hide the tooltip
        // if (!props.show) {
        //   sliderTooltip.style.visibility = 'hidden';
        //   sliderTooltip.style.opacity = '0';
        // }
      };

      editor.Components.addType("range-regular-form", {
        model: {
          defaults: {
            tagName: "span",
            type: "range-regular-form",
            script: sliderScript,
            attributes: {
              // id: "range-regular-form",
              class: "range-regular-form",
            },

            "style-default": {
              position: "relative",
              width: "40vw",
            },
            style: {
              position: "relative",
              width: "40vw",
            },

            components: [
              {
                tagName: "input",
                attributes: {
                  type: "range",
                  value: "1",
                  // id: "range-regular-form-slider",
                  class: "range-regular-form-slider",
                  name: "range-regular-form-slider",
                },
              },
              {
                tagName: "span",
                type: "range-regular-form-slider-tooltip",
                attributes: {
                  class: "range-regular-form-slider-tooltip",
                },
                content: "1",

                "style-default": {
                  visibility: "hidden",
                  width: "max-content",
                  "background-color": "transparent",
                  color: "black",
                  "text-align": "center",
                  "border-radius": "6px",
                  border: "1px solid gray",
                  padding: "0.3em",
                  "z-index": "1",
                  position: "absolute",
                  bottom: "105%",
                  left: "5px",
                  "margin-left": "-20px",
                  opacity: "0",
                  transition: "all 0.3s",
                },

                style: {
                  visibility: "hidden",
                  width: "max-content",
                  "background-color": "transparent",
                  color: "black",
                  "text-align": "center",
                  "border-radius": "6px",
                  border: "1px solid gray",
                  padding: "0.3em",
                  "z-index": "1",
                  position: "absolute",
                  bottom: "105%",
                  left: "5px",
                  "margin-left": "-20px",
                  opacity: "0",
                  transition: "all 0.3s",
                },
              },
            ],

            // traits
            min: "0",
            max: "",
            // show: false,

            traits: [
              "name",
              // "id",
              {
                type: "number",
                name: "min",
                label: "Minimum value",
                placeholder: "0",
                changeProp: true,
              },
              {
                type: "number",
                name: "max",
                label: "Maximum value",
                placeholder: "",
                changeProp: true,
              },
              // {
              //   type: 'checkbox',
              //   name: "show",
              //   label: 'Show',
              //   changeProp: true,
              // },
            ],

            "script-props": ["min", "max"],
            changeProp: true,
          },
        },
      });

      editor.Components.addType("reset-regular-form", {
        model: {
          defaults: {
            tagName: "span",
            attributes: {
              id: "reset-regular-form",
              class: "reset-regular-form",
            },
            content:
              '<input type="reset" id="reset" name="reset"contenteditable="true">',

            // traits
            //TempVar: "",

            traits: [
              "name",
              "placeholder",
              "id",
              // {
              //   type: "select",
              //   name: "TempVar",
              //   label: "TempVar",
              //   changeProp: true,
              //   options:
              //     tempVarList && tempVarList.current !== null
              //       ? [
              //           ...tempVarList.current.map((x) => ({
              //             value: x._id,
              //             name: x.name,
              //           })),
              //           { value: "none", name: "None" },
              //         ]
              //       : "",
              // },
            ],

            changeProp: true,

            //"script-props": ["TempVar"],
          },
        },

        view: {
          events: {
            // click: function (event) {
            //   var selectedFunction = this.model.get("TempVar");
            //   console.log("selectedFunction", selectedFunction);
            // },
          },
        },
      });

      // feature - if a user gives it an id or class with a hyphen it will automatically change them to underscrores
      editor.Components.addType("normal_text_area_regular_form", {
        model: {
          defaults: {
            tagName: "div",
            type: "normal_text_area_regular_form",
            attributes: {
              name: "normal_text_area_regular_form",
              class: "normal_text_area_regular_form",
            },

            components: [
              {
                tagName: "textarea",
                attributes: {
                  name: "normal_text_area",
                  class: "normal_text_area",
                  rows: 5,
                  cols: 50,
                  placeholder: "Form text area",
                },

                traits: ["id"],
              },
            ],

            // traits
            Autofocus: false,
            Cols: 50,
            Disabled: false,
            FormId: "",
            MaxLength: "",
            Name: "",
            Placeholder: "",
            ReadOnly: "",
            Required: false,
            Rows: 5,
            Wrap: "",

            traits: [
              {
                type: "checkbox",
                name: "Autofocus",
                label: "Autofocus",
                changeProp: true,
              },
              {
                type: "number",
                name: "Cols",
                label: "Cols",
                changeProp: true,
              },
              {
                type: "checkbox",
                name: "Disabled",
                label: "Disabled",
                changeProp: true,
              },
              {
                type: "text",
                name: "FormId",
                label: "FormId",
                placeholder: "ID of the form this belongs to",
                changeProp: true,
              },
              {
                type: "number",
                name: "MaxLength",
                label: "MaxLength",
                placeholder:
                  "maximum number of characters allowed in the text area",
                changeProp: true,
              },
              {
                type: "text",
                name: "Name",
                label: "Name",
                changeProp: true,
              },
              {
                type: "text",
                name: "Placeholder",
                label: "Placeholder",
                changeProp: true,
              },
              {
                type: "checkbox",
                name: "ReadOnly",
                label: "ReadOnly",
                changeProp: true,
              },
              {
                type: "checkbox",
                name: "Required",
                label: "Required",
                changeProp: true,
              },
              {
                type: "number",
                name: "Rows",
                label: "Rows",
                changeProp: true,
              },
              {
                type: "select",
                name: "Wrap",
                label: "Wrap",
                options: [
                  { id: "soft", name: "Soft" },
                  { id: "hard", name: "Hard" },
                ],
                changeProp: true,
              },
              "id",
            ],

            changeProp: true,
            "script-props": [
              "Autofocus",
              "Cols",
              "Disabled",
              "FormId",
              "MaxLength",
              "Name",
              "Placeholder",
              "ReadOnly",
              "Required",
              "Rows",
              "Wrap",
            ],
          },

          init() {
            this.on("change:Autofocus", this.handleTextAreaPropChange);
            this.on("change:Cols", this.handleTextAreaPropChange);
            this.on("change:Disabled", this.handleTextAreaPropChange);
            this.on("change:FormId", this.handleTextAreaPropChange);
            this.on("change:MaxLength", this.handleTextAreaPropChange);
            this.on("change:Name", this.handleTextAreaPropChange);
            this.on("change:Placeholder", this.handleTextAreaPropChange);
            this.on("change:ReadOnly", this.handleTextAreaPropChange);
            this.on("change:Required", this.handleTextAreaPropChange);
            this.on("change:Rows", this.handleTextAreaPropChange);
            this.on("change:Wrap", this.handleTextAreaPropChange);
          },

          handleTextAreaPropChange() {
            let {
              Autofocus,
              Cols,
              Row,
              Disabled,
              FormId,
              MaxLength,
              Name,
              Placeholder,
              ReadOnly,
              Required,
              Rows,
              Wrap,
            } = this.props();

            const container = this;
            console.log("container", container);

            const textArea = container.find("textarea.normal_text_area")[0];
            console.log("textArea", textArea);

            if (Autofocus) {
              if (textArea) {
                textArea.addAttributes({ autofocus: "autofocus" });
              }
            } else {
              if (textArea) {
                textArea.removeAttributes("autofocus");
              }
            }

            if (Cols) {
              if (textArea) {
                textArea.addAttributes({ cols: Cols });
              }
            } else {
              if (textArea) {
                textArea.removeAttributes("cols");
              }
            }

            if (Disabled) {
              if (textArea) {
                textArea.addAttributes({ disabled: "disabled" });
              }
            } else {
              if (textArea) {
                textArea.removeAttributes("disabled");
              }
            }

            if (FormId) {
              if (textArea) {
                textArea.addAttributes({ form: FormId });
              }
            } else {
              if (textArea) {
                textArea.removeAttributes("form");
              }
            }

            if (MaxLength) {
              if (textArea) {
                textArea.addAttributes({ maxlength: MaxLength });
              }
            } else {
              if (textArea) {
                textArea.removeAttributes("maxlength");
              }
            }

            if (Name) {
              if (textArea) {
                textArea.addAttributes({ name: Name });
              }
            } else {
              if (textArea) {
                textArea.removeAttributes("name");
              }
            }

            if (Placeholder) {
              if (textArea) {
                textArea.addAttributes({ placeholder: Placeholder });
              }
            } else {
              if (textArea) {
                textArea.removeAttributes("placeholder");
              }
            }

            if (ReadOnly) {
              if (textArea) {
                textArea.addAttributes({ readonly: "readonly" });
              }
            } else {
              if (textArea) {
                textArea.removeAttributes("readonly");
              }
            }

            if (Required) {
              if (textArea) {
                textArea.addAttributes({ required: "required" });
              }
            } else {
              if (textArea) {
                textArea.removeAttributes("required");
              }
            }

            if (Rows) {
              if (textArea) {
                textArea.addAttributes({ rows: Rows });
              }
            } else {
              if (textArea) {
                textArea.removeAttributes("rows");
              }
            }

            if (Wrap) {
              if (textArea) {
                textArea.addAttributes({ wrap: Wrap });
              }
            } else {
              if (textArea) {
                textArea.removeAttributes("wrap");
              }
            }
          },

          updated(property, value, prevValue) {
            console.log(
              "+++ property",
              property,
              "value",
              value,
              "prevValue",
              prevValue
            );
            console.log("this from updated()", this.getEl());

            const container = this;
            console.log("container", container);

            const textArea = container.find("textarea.normal_text_area")[0];
            console.log("textArea", textArea);

            // if the container ID includes '-', replace it with '_'
            if (
              "id" in container.getAttributes() &&
              container.getAttributes()["id"].includes("-")
            ) {
              let containerId = container
                .getAttributes()
                ["id"].replace(/-/g, "_");
              container.setAttributes({ id: containerId });
            }

            if (
              "id" in textArea.getAttributes() &&
              textArea.getAttributes()["id"].includes("-")
            ) {
              let textAreaId = textArea
                .getAttributes()
                ["id"].replace(/-/g, "_");
              textArea.setAttributes({ id: textAreaId });
            }

            console.log("container, textArea", container, textArea);
          },
        },
      });

      // to make it work in download
      const inputFileScript = function (props) {
        console.log("uploading file...", props, this);

        // For LB download manipulation
        // checking if the url or script is not running in the redsling editor, only then run the script to plot checkboxes
        let idEl = this.id;
        const urlDownload = window.top.location.href;
        const isSubstringPresent = (urlDownload.indexOf("/editor/") !== -1 && urlDownload.indexOf("?projectId=") !== -1) || urlDownload.indexOf("/large_preview/") !== -1 || urlDownload.indexOf("/tab_preview/") !== -1 || urlDownload.indexOf("/mobile_preview/") !== -1 || urlDownload.indexOf("/fragment_editor/") !== -1;
        if (!isSubstringPresent) {
          setInterval(function () {
            Object.keys(props).forEach(function (key) {
              if (window[`${key}${idEl}`]) {
                props[key] = window[`${key}${idEl}`];
              }
            });
          }, 500);
        }

        const inputFileContainer = this;
        const inputFileLabel = inputFileContainer.getElementsByClassName("input_file_label")[0];
        // const inputFile = inputFileContainer.getElementsByClassName("input_file")[0];

        // to make this script work only in download
        if (!isSubstringPresent) {
          // check if there are file name and location in session storage and set them as attributes to container if there are
          let fileUrl = sessionStorage.getItem(`data_file_url_${idEl}`);
          let fileName = sessionStorage.getItem(`data_file_name_${idEl}`);

          if (fileUrl) inputFileContainer.setAttribute(`data_file_url`, fileUrl);
          if (fileName) inputFileContainer.setAttribute(`data_file_name`, fileName);

          // attaching event listener
          inputFileLabel.addEventListener("click", async function (event) {
            let inputFile = document.createElement("input");
            inputFile.type = "file";
            inputFile.style.display = "none";

            inputFile.addEventListener("change", async function (event) {
              let file = inputFile.files[0];
              console.log("this++", this);
              console.log("file is:", file);

              let formData = new FormData();
              formData.append("file", file);

              try {
                const response = await fetch(
                  `${props.API_HOST}uploadRoutes/upload`,
                  {
                    method: "POST",
                    headers: {
                      // 'Content-Type': 'multipart/form-data',
                    },
                    body: formData,
                  }
                );

                if (response.ok) {
                  const data = await response.json();
                  console.log("File uploaded:", data);

                  // Note - Adding as element attribute, not grapesjs model attribute so that it goes away on refresh and is only temporarily added to the element
                  // Set the file URL as a custom data attribute
                  inputFileContainer.setAttribute("data_file_url", data["fileLocation"] );
                  // Set the file URL in session storage
                  sessionStorage.setItem(`data_file_url_${idEl}`, data["fileLocation"]);

                  // Set the file name as a custom data attribute
                  inputFileContainer.setAttribute("data_file_name", file.name);
                  // Set the file name in session storage
                  sessionStorage.setItem(`data_file_name_${idEl}`, file.name);
                } else {
                  console.error("Error uploading file:", response.statusText);
                }
              } catch (error) {
                console.error("Error uploading file:", error);
              } finally {
                // Remove the inputFile element after API call completes
                if (inputFile && inputFile.parentNode) {
                  inputFile.parentNode.removeChild(inputFile);
                }
              }
            });

            inputFileContainer.appendChild(inputFile);
            inputFile.click();
          });
        }        
      };

      editor.Components.addType("input_file_container", {
        model: {
          defaults: {
            tagName: "div",
            type: "input_file_container",
            script: inputFileScript,
            attributes: {
              class: "input_file_container",
            },

            API_HOST: API_HOST,

            changeProp: true,
            "script-props": ["API_HOST"],

            components: [
              {
                tagName: "label",
                type: "input_file_label",
                attributes: {
                  for: "input_file",
                  class: "input_file_label",
                },

                style: {
                  display: "inline-block",
                  padding: "1%",
                  "border-radius": "5px",
                  border: "1px solid gray",
                  cursor: "pointer",
                },

                "style-default": {
                  display: "inline-block",
                  padding: "1%",
                  "border-radius": "5px",
                  border: "1px solid gray",
                  cursor: "pointer",
                },

                components: [
                  {
                    tagName: "p",
                    type: "text",
                    editable: true,

                    "style-default": {
                      "font-size": "1.2vw",
                    },

                    style: {
                      "font-size": "1.2vw",
                    },

                    components: {
                      type: "textnode",
                      content: "Upload a file",
                    },
                  },
                ],
              },
            ],
          },

          removed() {
            // only remove keys related to this component
            for (let i = 0; i < sessionStorage.length; i++) {
              const key = sessionStorage.key(i);

              // Check if the key contains the substring '_selected_column'
              if (key && key.includes(`data_file_url_${this.getAttributes().id}`)) {
                sessionStorage.removeItem(key);
              }
            }

            // only remove keys related to this component
            for (let i = 0; i < sessionStorage.length; i++) {
              const key = sessionStorage.key(i);

              // Check if the key contains the substring '_selected_column'
              if (key && key.includes(`data_file_name_${this.getAttributes().id}`)) {
                sessionStorage.removeItem(key);
              }
            }
          },
        },

        view: {
          events: {
            "click .input_file_label": "openSystemFileDialog",
          },
          // to make it work only in the editor
          openSystemFileDialog(event) {
            // event.stopPropagation();
            console.log("clicked");

            let container = document.querySelector(".input_file_container");

            let inputFile = document.createElement("input");
            inputFile.type = "file";
            inputFile.addEventListener("change", async (event) => {
              let file = inputFile.files[0];

              console.log("file is:", file);

              let formData = new FormData();
              formData.append("file", file);

              try {
                const response = await fetch(`${API_HOST}uploadRoutes/upload`, {
                  method: "POST",
                  body: formData,
                  headers: {
                    // 'Content-Type': 'multipart/form-data',
                  },
                });

                if (response.ok) {
                  const data = await response.json();
                  console.log("File uploaded:", data);

                  // Set the file URL as a custom data attribute
                  this.model.getEl().setAttribute("data_file_url", data["fileLocation"]);
                  // Set the file URL in session storage
                  sessionStorage.setItem(`data_file_url_${this.model.getAttributes().id}`, data["fileLocation"]);
                  
                  // Set the file name as a custom data attribute
                  this.model.getEl().setAttribute("data_file_name", file.name);
                  // Set the file name in session storage
                  sessionStorage.setItem(`data_file_name_${this.model.getAttributes().id}`, file.name);
                } else {
                  console.error("Error uploading file:", response.statusText);
                }
              } catch (error) {
                console.error("Error uploading file:", error);
              } finally {
                // Remove the inputFile element after API call completes
                if (inputFile && inputFile.parentNode) {
                  inputFile.parentNode.removeChild(inputFile);
                }
              }
            });
            container?.appendChild(inputFile);
            inputFile.click();
          },

          onRender({el, model}) {
            // console.log("++ el ++", el);
            if (el.className.includes("input_file_container")) {
              // check if there are file name and location in session storage and set them as attributes to container if there are
              let fileUrl = sessionStorage.getItem(`data_file_url_${el.id}`);
              let fileName = sessionStorage.getItem(`data_file_name_${el.id}`);

              if (fileUrl) el.setAttribute(`data_file_url`, fileUrl);
              if (fileName) el.setAttribute(`data_file_name`, fileName);
            }
          },
        },
      });

      const inputFileApiScript = function (props) {
        console.log("uploading file...", props, this);

        const inputFileContainer = this;
        const inputFileLabel =
          inputFileContainer.getElementsByClassName("input_file_label")[0];
        // const inputFile = inputFileContainer.getElementsByClassName("input_file")[0];

        inputFileLabel.addEventListener("click", async function (event) {
          let inputFile = document.createElement("input");
          inputFile.type = "file";
          inputFile.style.display = "none";

          inputFile.addEventListener("change", async function (event) {
            let file = inputFile.files[0];
            console.log("this++", this);
            console.log("file is:", file);

            let formData = new FormData();
            formData.append("file", file);

            try {
              // var options = {};
              // let apiKey = props.API_key;
              // let CustomUrl = props.CustomUrl;
              // let more_HeadersTarget = props.More_Headers;

              // if (more_HeadersTarget && more_HeadersTarget.length > 0) {
              //   const additionalHeaders = more_HeadersTarget.split(",");

              //   additionalHeaders.forEach((header) => {
              //     const [headerName, headerValue] = header
              //       .split(":")
              //       .map((str) => str.trim());
              //     options.headers[headerName] = headerValue;
              //   });
              //   console.log(
              //     "options.headers only more headers",
              //     JSON.stringify(options.headers)
              //   );
              // }

              // if ((!props.BaseUrl || props.BaseUrl === 'null') && props.CustomUrl) {
              //   const response = await fetch(
              //     `${props.API_HOST}uploadRoutes/upload`,
              //     {
              //       method: "POST",
              //       headers: {
              //         // 'Content-Type': 'multipart/form-data',
              //       },
              //       body: formData,
              //     }
              //   );

              // }else if ((!props.BaseUrl || props.BaseUrl === 'null') && props.CustomUrl) {
              //   const response = await fetch(
              //     `${props.API_HOST}uploadRoutes/upload`,
              //     {
              //       method: "POST",
              //       headers: {
              //         // 'Content-Type': 'multipart/form-data',
              //       },
              //       body: formData,
              //     }
              //   );

              // }

              var options = {
                headers: {},
              };

              let apiKey = props.API_key;
              let CustomUrl = props.CustomUrl;
              let more_HeadersTarget = props.More_Headers;

              if (more_HeadersTarget && more_HeadersTarget.length > 0) {
                const additionalHeaders = more_HeadersTarget.split(",");

                additionalHeaders.forEach((header) => {
                  const [headerName, headerValue] = header
                    .split(":")
                    .map((str) => str.trim());
                  options.headers[headerName] = headerValue;
                });
                console.log(
                  "options.headers only more headers",
                  JSON.stringify(options.headers)
                );
              }
              let url, baseUrlBearerToken;
              let API;
              if (props.APIPath) {
                API = props.APIPath;
                if (props.BaseUrl === "baseUrl1") {
                  url = props.BaseVars.baseUrl1 + API;
                  baseUrlBearerToken = props.BaseVars.baseUrl1BearerToken;
                } else if (props.BaseUrl === "baseUrl2") {
                  url = props.BaseVars.baseUrl2 + API;
                  baseUrlBearerToken = props.BaseVars.baseUrl2BearerToken;
                } else if (props.BaseUrl === "baseUrl3") {
                  url = props.BaseVars.baseUrl3 + API;
                  baseUrlBearerToken = props.BaseVars.baseUrl3BearerToken;
                } else if (props.BaseUrl === "baseUrl4") {
                  url = props.BaseVars.baseUrl4 + API;
                  baseUrlBearerToken = props.BaseVars.baseUrl4BearerToken;
                } else if (props.BaseUrl === "baseUrl5") {
                  url = props.BaseVars.baseUrl5 + API;
                  baseUrlBearerToken = props.BaseVars.baseUrl5BearerToken;
                }
              }
              let apiUrl, apiBaseToken;
              if (url && baseUrlBearerToken) {
                apiUrl = url;
                apiBaseToken = baseUrlBearerToken;
              } else if (CustomUrl && apiKey) {
                apiUrl = CustomUrl;
                apiBaseToken = apiKey;
              } else if (CustomUrl) {
                apiUrl = CustomUrl;
              }

              if (apiUrl && apiBaseToken) {
                let response;
                if (options.headers && options.headers.length > 0) {
                  response = await fetch(`${apiUrl}`, {
                    method: "POST",
                    headers: {
                      //'Content-Type': `application/json`,
                      ...options.headers,
                      Authorization: `Bearer ${apiBaseToken}`,
                    },
                    body: formData,
                  });
                } else {
                  response = await fetch(`${apiUrl}`, {
                    method: "POST",
                    headers: {
                      //'Content-Type': `application/json`,
                      Authorization: `Bearer ${apiBaseToken}`,
                    },
                    body: formData,
                  });
                }

                if (response.ok) {
                  const data = await response.json();
                  console.log("File uploaded:", data);
                  inputFileContainer.setAttribute(
                    "data_file_api_url",
                    data["fileLocation"]
                  );
                } else {
                  console.error("Error uploading file:", response.statusText);
                }
              } else if (apiUrl) {
                let response;
                if (options.headers && options.headers.length > 0) {
                  response = await fetch(`${apiUrl}`, {
                    method: "POST",
                    headers: {
                      //'Content-Type': `application/json`,
                      ...options.headers,
                    },
                    body: formData,
                  });
                } else {
                  response = await fetch(`${apiUrl}`, {
                    method: "POST",
                    headers: {
                      //'Content-Type': `application/json`
                    },
                    body: formData,
                  });
                }

                if (response.ok) {
                  const data = await response.json();
                  console.log("File uploaded:", data);
                  inputFileContainer.setAttribute(
                    "data_file_api_url",
                    data["fileLocation"]
                  );
                } else {
                  console.error("Error uploading file:", response.statusText);
                }
              } else {
                console.error("API key or URL is missing");
                return;
              }
            } catch (error) {
              console.error("Error uploading file:", error);
            } finally {
              // Remove the inputFile element after API call completes
              if (inputFile && inputFile.parentNode) {
                inputFile.parentNode.removeChild(inputFile);
              }
            }
          });

          inputFileContainer.appendChild(inputFile);
          inputFile.click();
        });
      };

      editor.Components.addType("input_file_api_container", {
        model: {
          defaults: {
            tagName: "div",
            type: "input_file_api_container",
            script: inputFileApiScript,
            attributes: {
              class: "input_file_api_container",
            },
            CustomUrl: "",
            BaseUrl: "",
            APIPath: "",
            API_Key: "",
            More_Headers: "", // input should be like: a:b,c:d,e:f
            options: {
              baseUrl1,
              baseUrl2,
              baseUrl3,
              baseUrl4,
              baseUrl5,
              baseUrl1BearerToken,
              baseUrl2BearerToken,
              baseUrl3BearerToken,
              baseUrl4BearerToken,
              baseUrl5BearerToken,
            },
            BaseVars: {
              baseUrl1,
              baseUrl2,
              baseUrl3,
              baseUrl4,
              baseUrl5,
              baseUrl1BearerToken,
              baseUrl2BearerToken,
              baseUrl3BearerToken,
              baseUrl4BearerToken,
              baseUrl5BearerToken,
            },
            traits: [
              {
                type: "text",
                name: "CustomUrl",
                label: "Custom URL",
                changeProp: true,
              },
              {
                type: "select",
                name: "BaseUrl",
                label: "Base URL",
                options: [
                  { id: "baseUrl1", name: "Base URL #1" },
                  { id: "baseUrl2", name: "Base URL #2" },
                  { id: "baseUrl3", name: "Base URL #3" },
                  { id: "baseUrl4", name: "Base URL #4" },
                  { id: "baseUrl5", name: "Base URL #5" },
                  { id: "null", name: "No Base URL" },
                ],
                changeProp: true,
              },
              {
                type: "text",
                name: "APIPath",
                label: "API Path",
                placeholder: "Don't start with '/'",
                changeProp: true,
              },
              {
                type: "text",
                name: "API_Key",
                label: "API Key",
                changeProp: true,
              },
              {
                type: "text",
                name: "More_Headers",
                label: "More Headers",
                changeProp: true,
              },
            ],

            API_HOST: API_HOST,

            changeProp: true,
            "script-props": [
              "API_HOST",
              "CustomUrl",
              "BaseUrl",
              "APIPath",
              "BaseVars",
              "API_Key",
              "More_Headers",
            ],

            components: [
              {
                tagName: "label",
                type: "input_file_api_label",
                attributes: {
                  for: "input_file",
                  class: "input_file_api_label",
                },

                style: {
                  display: "inline-block",
                  padding: "1%",
                  "border-radius": "5px",
                  border: "1px solid gray",
                  cursor: "pointer",
                },

                "style-default": {
                  display: "inline-block",
                  padding: "1%",
                  "border-radius": "5px",
                  border: "1px solid gray",
                  cursor: "pointer",
                },

                components: [
                  {
                    tagName: "p",
                    type: "text",
                    editable: true,

                    "style-default": {
                      "font-size": "1.2vw",
                    },

                    style: {
                      "font-size": "1.2vw",
                    },

                    components: {
                      type: "textnode",
                      content: "Upload a file",
                    },
                  },
                ],
              },
            ],
          },
        },

        view: {
          events: {
            "click .input_file_api_label": "openSystemAPIFileDialog",
          },
          // to make it work only in the editor
          openSystemAPIFileDialog(event) {
            let {
              API_Key,
              CustomUrl,
              More_Headers,
              APIPath,
              BaseUrl,
              BaseVars,
            } = this.model.props();
            // event.stopPropagation();
            console.log("clicked");
            //console.log(props);
            console.log("APIKey" + API_Key);
            console.log("CustomUrl" + CustomUrl);

            let container = document.querySelector(".input_file_api_container");

            let inputFile = document.createElement("input");
            inputFile.type = "file";
            inputFile.addEventListener("change", async (event) => {
              let file = inputFile.files[0];

              console.log("file is:", file);

              let formData = new FormData();
              formData.append("file", file);

              try {
                // var options = {};
                // let apiKey = props.API_key;
                // let CustomUrl = props.CustomUrl;
                // let more_HeadersTarget = props.More_Headers;

                // if (more_HeadersTarget && more_HeadersTarget.length > 0) {
                //   const additionalHeaders = more_HeadersTarget.split(",");

                //   additionalHeaders.forEach((header) => {
                //     const [headerName, headerValue] = header
                //       .split(":")
                //       .map((str) => str.trim());
                //     options.headers[headerName] = headerValue;
                //   });
                //   console.log(
                //     "options.headers only more headers",
                //     JSON.stringify(options.headers)
                //   );
                // }

                // if ((!props.BaseUrl || props.BaseUrl === 'null') && props.CustomUrl) {
                //   const response = await fetch(
                //     `${props.API_HOST}uploadRoutes/upload`,
                //     {
                //       method: "POST",
                //       headers: {
                //         // 'Content-Type': 'multipart/form-data',
                //       },
                //       body: formData,
                //     }
                //   );

                // }else if ((!props.BaseUrl || props.BaseUrl === 'null') && props.CustomUrl) {
                //   const response = await fetch(
                //     `${props.API_HOST}uploadRoutes/upload`,
                //     {
                //       method: "POST",
                //       headers: {
                //         // 'Content-Type': 'multipart/form-data',
                //       },
                //       body: formData,
                //     }
                //   );

                // }

                var options = {
                  headers: {},
                };

                let apiKey = API_Key;
                //let customUrl = CustomUrl;
                let more_HeadersTarget = More_Headers;

                if (more_HeadersTarget && more_HeadersTarget.length > 0) {
                  const additionalHeaders = more_HeadersTarget.split(",");

                  additionalHeaders.forEach((header) => {
                    const [headerName, headerValue] = header
                      .split(":")
                      .map((str) => str.trim());
                    options.headers[headerName] = headerValue;
                  });
                  console.log(
                    "options.headers only more headers",
                    JSON.stringify(options.headers)
                  );
                }

                let url, baseUrlBearerToken;
                let API;
                if (APIPath && BaseUrl && BaseVars) {
                  API = APIPath;
                  if (BaseUrl === "baseUrl1") {
                    url = BaseVars.baseUrl1 + API;
                    baseUrlBearerToken = BaseVars.baseUrl1BearerToken;
                  } else if (BaseUrl === "baseUrl2") {
                    url = BaseVars.baseUrl2 + API;
                    baseUrlBearerToken = BaseVars.baseUrl2BearerToken;
                  } else if (BaseUrl === "baseUrl3") {
                    url = BaseVars.baseUrl3 + API;
                    baseUrlBearerToken = BaseVars.baseUrl3BearerToken;
                  } else if (BaseUrl === "baseUrl4") {
                    url = BaseVars.baseUrl4 + API;
                    baseUrlBearerToken = BaseVars.baseUrl4BearerToken;
                  } else if (BaseUrl === "baseUrl5") {
                    url = BaseVars.baseUrl5 + API;
                    baseUrlBearerToken = BaseVars.baseUrl5BearerToken;
                  }
                }
                let apiUrl, apiBaseToken;
                if (url && baseUrlBearerToken) {
                  apiUrl = url;
                  apiBaseToken = baseUrlBearerToken;
                } else if (CustomUrl && apiKey) {
                  apiUrl = CustomUrl;
                  apiBaseToken = apiKey;
                } else if (CustomUrl) {
                  apiUrl = CustomUrl;
                }

                if (apiUrl && apiBaseToken) {
                  let response;
                  if (options.headers && options.headers.length > 0) {
                    response = await fetch(`${apiUrl}`, {
                      method: "POST",
                      headers: {
                        //'Content-Type': `application/json`,
                        ...options.headers,
                        Authorization: `Bearer ${apiBaseToken}`,
                      },
                      body: formData,
                    });
                  } else {
                    console.log("apiUrl" + apiUrl);
                    console.log("apiBaseToken" + apiBaseToken);
                    response = await fetch(`${apiUrl}`, {
                      method: "POST",
                      headers: {
                        //'Content-Type': `application/json`,
                        Authorization: `Bearer ${apiBaseToken}`,
                      },
                      body: formData,
                    });
                  }

                  if (response.ok) {
                    const data = await response.json();
                    console.log("File uploaded:", data);
                    this.model
                      .getEl()
                      .setAttribute("data_file_api_url", data["fileLocation"]);
                  } else {
                    console.error("Error uploading file:", response.statusText);
                  }
                } else if (apiUrl) {
                  let response;
                  if (options.headers && options.headers.length > 0) {
                    response = await fetch(`${apiUrl}`, {
                      method: "POST",
                      headers: {
                        //'Content-Type': `application/json`,
                        ...options.headers,
                      },
                      body: formData,
                    });
                  } else {
                    response = await fetch(`${apiUrl}`, {
                      method: "POST",
                      headers: {
                        //'Content-Type': `application/json`
                      },
                      body: formData,
                    });
                  }

                  if (response.ok) {
                    const data = await response.json();
                    console.log("File uploaded:", data);
                    this.model
                      .getEl()
                      .setAttribute("data_file_api_url", data["fileLocation"]);
                  } else {
                    console.error("Error uploading file:", response.statusText);
                  }
                } else {
                  console.error("API key or URL is missing");
                  return;
                }
              } catch (error) {
                console.error("Error uploading file:", error);
              } finally {
                // Remove the inputFile element after API call completes
                if (inputFile && inputFile.parentNode) {
                  inputFile.parentNode.removeChild(inputFile);
                }
              }
            });
            container?.appendChild(inputFile);
            inputFile.click();
          },
        },
      });

      editor.Components.addType("submit-regular-form", {
        model: {
          defaults: {
            tagName: "span",
            attributes: {
              id: "submit-regular-form",
              class: "submit-regular-form",
            },
            content:
              '<input type="submit" id="submit" name="submit" contenteditable="true">',

            // traits
            //TempVar: "",

            traits: [
              "name",
              "placeholder",
              "id",
              // {
              //   type: "select",
              //   name: "TempVar",
              //   label: "TempVar",
              //   changeProp: true,
              //   options:
              //     tempVarList && tempVarList.current !== null
              //       ? [
              //           ...tempVarList.current.map((x) => ({
              //             value: x._id,
              //             name: x.name,
              //           })),
              //           { value: "none", name: "None" },
              //         ]
              //       : "",
              // },
            ],

            changeProp: true,

            //"script-props": ["TempVar"],
          },
        },

        // view: {
        //     events: {
        //         click: function (event) {
        //             var selectedFunction = this.model.get('TempVar');
        //             console.log("selectedFunction", selectedFunction);

        //         }
        //     },
        // },
      });

      editor.DomComponents.addType("basicButton", {
        model: {
          defaults: {
            tagName: "basicButton",
            // script,
            // Define default values for your custom properties
            // Define traits, in order to change your properties
            Page: "",
            PassToNextPage: "",
            traits: [
              {
                type: "select",
                name: "Page",
                changeProp: true,
                options: pageByProjectData
                  ? pageByProjectData?.map((x) => {
                      const arr = {
                        value: x._id,
                        name: x.name,
                      };
                      return arr;
                    })
                  : "",
              },
              {
                type: "select",
                name: "PassToNextPage",
                label: "Pass Over Next Page",
                changeProp: true,
                options: [
                  { id: "true", value: true },
                  { id: "false", value: false },
                ],
              },
            ],
            "script-props": ["Page", "PassToNextPage"],
            // Add some style, just to make the component visible
            style: {
              width: "100px",
              height: "30px",
              display: "flex",
              "justify-content": "center",
              "align-items": "center",
            },
            // content: {
            components: `
                                          <button onclick='pageRedirect'>
                                              <p style="margin: auto;">Submit</p>
                                          </button>
                                      `,
            // }
          },
        },
        view: {
          events: {
            click: function (event) {
              var link = this.model.get("Page");
              var willDataPass = this.model.get("PassToNextPage");
              console.log("willDataPass", willDataPass);

              const project_id = window.location.search.split("=")[1];

              let dataOnLocalCheck = JSON.parse(
                localStorage.getItem("dynoApi")
              );
              console.log("dataOnLocalCheck", dataOnLocalCheck);
              let valueCheck = false;

              // check if the local storage data contains empty values:
              if (link && willDataPass === true) {
                window.location.href =
                  origin +
                  `/editor/` +
                  `${link}` +
                  "?projectId=" +
                  `${project_id}`;
                // if (dataOnLocalCheck) {
                //     console.log('dataOnLocalCheck')
                //     if (Object?.keys(dataOnLocalCheck)?.length === 0) {
                //         localStorage.removeItem('dynoApi');
                //     } else {
                //         for (let key in dataOnLocalCheck) {
                //             if (dataOnLocalCheck.hasOwnProperty(key) && !dataOnLocalCheck[key]) {
                //                 localStorage.removeItem('dynoApi');
                //             }
                //         }
                //     }

                //     const components = editor.DomComponents.getComponents();
                //     console.log(components.get('components'));
                //     console.log('components', components)
                //     var editor = grapesjs.Editor;

                //     // Get the canvas element
                //     // var canvas = editor.Canvas.getCanvas();

                //     // console.log('canvas', canvas)
                //     // console.log('editor', editor)

                //     // Filter the components that have an input tag
                //     // components.filter(component => {
                //     //     console.log('component', components.component)
                //     //     console.log('component.attributes', component.attributes)
                //     //     console.log('component.attributes.type', component.attributes.type);

                //     //     if (component.attributes.attributes.type === 'div') {
                //     //         component.filter((sub_comp) => {
                //     //             if (sub_comp.attributes.attributes.id === 'email-regular-form') {
                //     //                 const emailDiv = editor.DomComponents.getWrapper().find('#email');
                //     //                 if (emailDiv) {
                //     //                     dataOnLocalCheck[`${emailDiv[0].view.el.name}`] = emailDiv[0].view.el.value;
                //     //                 }

                //     //             }

                //     //             // password input
                //     //             if (sub_comp.attributes.attributes.id === 'pwd-regular-form') {
                //     //                 const passDiv = editor.DomComponents.getWrapper().find('#password');
                //     //                 if (passDiv) {
                //     //                     dataOnLocalCheck[`${passDiv[0].view.el.name}`] = passDiv[0].view.el.value;
                //     //                 }

                //     //             }

                //     //             // number input
                //     //             if (sub_comp.attributes.attributes.id === 'tele-regular-form') {
                //     //                 const passDiv = editor.DomComponents.getWrapper().find('#phone');
                //     //                 if (passDiv) {
                //     //                     dataOnLocalCheck[`${passDiv[0].view.el.name}`] = passDiv[0].view.el.value;
                //     //                 }

                //     //             }

                //     //             // text input
                //     //             if (sub_comp.attributes.attributes.name === 'text') {
                //     //                 const el = sub_comp.view.el.childNodes[1];
                //     //                 dataOnLocalCheck[`${el.name}`] = el.value;
                //     //             }

                //     //             // checkbox input

                //     //             if (sub_comp.attributes.type === 'basicCheckBox') {
                //     //                 const elementValue = sub_comp.attributes.isChecked;
                //     //                 const elementKey = sub_comp.attributes.nameToCheck
                //     //                 if (elementValue && elementKey) {
                //     //                     dataOnLocalCheck[`${elementKey}`] = elementValue;
                //     //                 }
                //     //             }

                //     //             // calender Input

                //     //             if (sub_comp.attributes.type === 'basicDateTime') {
                //     //                 const elementKey = editor.DomComponents.getWrapper().find('#compLabel')[0].view.el.innerText;

                //     //                 const elementValue = editor.DomComponents.getWrapper().find('#compId')[0].view.el.textContent;

                //     //                 if (elementKey && elementValue) {
                //     //                     dataOnLocalCheck[`${elementKey}`] = elementValue;
                //     //                 }

                //     //             }

                //     //             // select dropdown Input
                //     //             if (sub_comp.attributes.type === 'input-select') {
                //     //                 const inputVal = sub_comp.view.el.childNodes[1].childNodes[1].value;
                //     //                 const inputKey = sub_comp.attributes.Name;

                //     //                 if (inputVal && inputKey) {
                //     //                     dataOnLocalCheck[`${inputKey}`] = inputVal;
                //     //                 }

                //     //             }

                //     //             // input type file
                //     //             if (sub_comp.attributes.attributes.id === 'upload-file') {

                //     //                 const elementKey = editor.DomComponents.getWrapper().find('#apiLabel')[0].view.el.innerHTML;
                //     //                 const elementKey1 = sub_comp.view.el.childNodes[0].childNodes[1].attributes[7].nodeValue;
                //     //                 if (elementKey && elementKey1) {
                //     //                     dataOnLocalCheck[`${elementKey}`] = elementKey1;
                //     //                 }

                //     //             }

                //     //             // number input:
                //     //             if (sub_comp.attributes.attributes.id === 'number-regular-form') {
                //     //                 const elementKey = sub_comp.view.el.childNodes[1].name;
                //     //                 const elementKey1 = sub_comp.view.el.childNodes[1].value;
                //     //                 if (elementKey && elementKey1) {
                //     //                     dataOnLocalCheck[`${elementKey}`] = elementKey1;
                //     //                 }
                //     //             }
                //     //         })
                //     //     }

                //     // });

                //     allComponents.map((component) => {
                //         // console.log(component)
                //         // console.log(component.collection.domc.componentsById);
                //         // if(component.attributes.attributes.id === "formId") {

                //         var allNestedComponents = getAllNestedComponents(components);

                //         console.log('component', component)
                //         // console.log('component.attributes.type', component.attributes.type)
                //         // console.log('#selection', editor.DomComponents.getWrapper().find('#selection'))

                //         // email input (done)
                //         if (component.attributes.attributes.type === 'email') {
                //             if (component.attributes.attributes.id && component.view.el.value) {
                //                 dataOnLocalCheck[`${component.attributes.attributes.id}`] = component.view.el.value
                //             }
                //         }

                //         // password input (done)
                //         if (component.attributes.attributes.id === 'password') {
                //             if (component.attributes.attributes.id && component.view.el.value) {
                //                 dataOnLocalCheck[`${component.attributes.attributes.id}`] = component.view.el.value
                //             }
                //         }

                //         // tele input (done)
                //         if (component.attributes.attributes.type === 'tel') {
                //             if (component.attributes.attributes.id && component.view.el.value) {
                //                 dataOnLocalCheck[`${component.attributes.attributes.id}`] = component.view.el.value
                //             }

                //         }

                //         // text input(done)
                //         if (component.attributes.attributes.id === 'text') {
                //             if (component.attributes.attributes.id && component.view.el.value) {
                //                 dataOnLocalCheck[`${component.attributes.attributes.id}`] = component.view.el.value
                //             }
                //         }

                //         // checkbox input(done)
                //         if (component.attributes.type === 'basicCheckBox') {
                //             const elementValue = component.attributes.isChecked;
                //             const elementKey = component.attributes.nameToCheck
                //             if (elementKey && elementValue) {
                //                 dataOnLocalCheck[`${elementKey}`] = elementValue;
                //             }

                //         }

                //         // calender Input(done)
                //         if (component.attributes.type === 'basicDateTime') {

                //             if (component.view.el.childNodes[0].innerText && component.view.el.childNodes[1].outerText) {
                //                 dataOnLocalCheck[`${component.view.el.childNodes[0].innerText}`] = component.view.el.childNodes[1].outerText;
                //             }

                //         }

                //         // select dropdown Input(done)
                //         if (component.attributes.type === 'input-select') {

                //             const inputVal = component.view.el.firstElementChild.value;

                //             if (inputVal && component.attributes.attributes.id) {
                //                 dataOnLocalCheck[`${component.attributes.attributes.id}`] = inputVal;
                //             }

                //         }

                //         // input type file (done)
                //         if (component.attributes.attributes.id === 'upload-file') {
                //             const elementKey = editor.DomComponents.getWrapper().find('#apiLabel')[0].view.el.innerHTML;
                //             const elementKey1 = component.view.el.childNodes[0].childNodes[1].attributes[7].nodeValue;
                //             if (elementKey && elementKey1) {
                //                 dataOnLocalCheck[`${elementKey}`] = elementKey1;
                //             }

                //         }

                //         // number input:(done)
                //         if (component.attributes.attributes.id === 'number') {
                //             if (component.attributes.attributes.id && component.view.el.value) {
                //                 dataOnLocalCheck[`${component.attributes.attributes.id}`] = component.view.el.value;
                //             }
                //         }
                //     });

                //     localStorage.removeItem('dynoApi')
                //     localStorage.setItem(`dynoApi`, JSON.stringify(dataOnLocalCheck))
                //     const origin = window.location.origin;
                //     window.location.href = origin + `/editor/` + `${link}` + '?projectId=' + `${project_id}`
                // } else {
                //     // console.log('!!!!dataOnLocalCheck')
                //     // const components = editor.DomComponents.getComponents();
                //     // let dataToBePassed = {}
                //     // // Filter the components that have an input tag
                //     // components.filter(component => {
                //     //     console.log('component', component)
                //     //     console.log('component.attributes.type', component.attributes.type)
                //     //     console.log('#selection', editor.DomComponents.getWrapper().find('#selection'))

                //     //     if (component.attributes.attributes.type === 'div') {
                //     //         component.filter((sub_comp) => {
                //     //             if (sub_comp.attributes.attributes.id === 'email-regular-form') {
                //     //                 const emailDiv = editor.DomComponents.getWrapper().find('#email');
                //     //                 if (emailDiv) {
                //     //                     dataToBePassed[`${emailDiv[0].view.el.name}`] = emailDiv[0].view.el.value;
                //     //                 }

                //     //             }

                //     //             // password input
                //     //             if (sub_comp.attributes.attributes.id === 'pwd-regular-form') {
                //     //                 const passDiv = editor.DomComponents.getWrapper().find('#password');
                //     //                 if (passDiv) {
                //     //                     dataToBePassed[`${passDiv[0].view.el.name}`] = passDiv[0].view.el.value;
                //     //                 }

                //     //             }

                //     //             // number input
                //     //             if (sub_comp.attributes.attributes.id === 'tele-regular-form') {
                //     //                 const passDiv = editor.DomComponents.getWrapper().find('#phone');
                //     //                 if (passDiv) {
                //     //                     dataToBePassed[`${passDiv[0].view.el.name}`] = passDiv[0].view.el.value;
                //     //                 }

                //     //             }

                //     //             // text input
                //     //             if (sub_comp.attributes.attributes.name === 'text') {
                //     //                 const el = sub_comp.view.el.childNodes[1];
                //     //                 dataToBePassed[`${el.name}`] = el.value;
                //     //             }

                //     //             // checkbox input

                //     //             if (sub_comp.attributes.type === 'basicCheckBox') {
                //     //                 const elementValue = sub_comp.attributes.isChecked;
                //     //                 const elementKey = sub_comp.attributes.nameToCheck
                //     //                 if (elementValue && elementKey) {
                //     //                     dataToBePassed[`${elementKey}`] = elementValue;
                //     //                 }
                //     //             }

                //     //             // calender Input

                //     //             if (sub_comp.attributes.type === 'basicDateTime') {
                //     //                 const elementKey = editor.DomComponents.getWrapper().find('#compLabel')[0].view.el.innerText;

                //     //                 const elementValue = editor.DomComponents.getWrapper().find('#compId')[0].view.el.textContent;

                //     //                 if (elementKey && elementValue) {
                //     //                     dataToBePassed[`${elementKey}`] = elementValue;
                //     //                 }

                //     //             }

                //     //             // select dropdown Input
                //     //             if (sub_comp.attributes.type === 'input-select') {
                //     //                 const inputVal = sub_comp.view.el.childNodes[1].childNodes[1].value;
                //     //                 const inputKey = sub_comp.attributes.Name;

                //     //                 if (inputVal && inputKey) {
                //     //                     dataToBePassed[`${inputKey}`] = inputVal;
                //     //                 }

                //     //             }

                //     //             // input type file
                //     //             if (sub_comp.attributes.attributes.id === 'upload-file') {

                //     //                 const elementKey = editor.DomComponents.getWrapper().find('#apiLabel')[0].view.el.innerHTML;
                //     //                 const elementKey1 = sub_comp.view.el.childNodes[0].childNodes[1].attributes[7].nodeValue;
                //     //                 if (elementKey && elementKey1) {
                //     //                     dataToBePassed[`${elementKey}`] = elementKey1;
                //     //                 }

                //     //             }

                //     //             // number input:
                //     //             if (sub_comp.attributes.attributes.id === 'number-regular-form') {
                //     //                 const elementKey = sub_comp.view.el.childNodes[1].name;
                //     //                 const elementKey1 = sub_comp.view.el.childNodes[1].value;
                //     //                 if (elementKey && elementKey1) {
                //     //                     dataToBePassed[`${elementKey}`] = elementKey1;
                //     //                 }
                //     //             }
                //     //         })
                //     //     }

                //     // });

                //     // console.log('dataToBePassed', dataToBePassed)

                //     // if (Object?.keys(dataToBePassed)?.length !== 0) {
                //     //     for (let key in dataToBePassed) {
                //     //         console.log('valueCheck11111', valueCheck)
                //     //         if (dataToBePassed.hasOwnProperty(key) && !dataToBePassed[key]) {
                //     //             valueCheck = true
                //     //         }
                //     //     }
                //     //     if (valueCheck === false) {
                //     //         localStorage.removeItem(`dynoApi`)
                //     //         localStorage.setItem(`dynoApi`, JSON.stringify(dataToBePassed));
                //     //         const origin = window.location.origin;
                //     //         window.location.href = origin + `/editor/` + `${link}` + '?projectId=' + `${project_id}`
                //     //     }
                //     // }

                //     console.log('!!!!dataOnLocalCheck')
                //     console.log('editor11111', editor)
                //     const components = editor.DomComponents.getComponents();
                //     console.log("ALLL components----- ", components)

                //     var allComponents = getAllComponents(editor);

                //     console.log("ALLL COOMPONENNNN----- ", allComponents)

                //     let dataToBePassed = {}
                //     // Filter the components that have an input tag
                //     allComponents.map((component) => {
                //         // console.log(component)
                //         // console.log(component.collection.domc.componentsById);
                //         // if(component.attributes.attributes.id === "formId") {
                //         var allNestedComponents = getAllNestedComponents(components);
                //         console.log("NESTEd components 2", allNestedComponents)

                //         console.log('component', component)
                //         // console.log('component.attributes.type', component.attributes.type)
                //         // console.log('#selection', editor.DomComponents.getWrapper().find('#selection'))

                //         // email input
                //         if (component.attributes.attributes.type === 'email') {
                //             console.log("IN EMAILLLLLL")
                //             console.log(component.view.el.value)

                //             dataToBePassed[`${component.attributes.attributes.id}`] = component.view.el.value

                //         }

                //         // password input
                //         if (component.attributes.attributes.type === 'password') {

                //             dataToBePassed[`${component.attributes.attributes.id}`] = component.view.el.value

                //         }

                //         // tele input
                //         if (component.attributes.attributes.type === 'tel') {

                //             dataToBePassed[`${component.attributes.attributes.id}`] = component.view.el.value

                //         }

                //         // text input
                //         if (component.attributes.attributes.name === 'text') {
                //             const el = component.view.el.childNodes[1];
                //             dataToBePassed[`${el.id}`] = el.value;
                //         }

                //         // checkbox input

                //         if (component.attributes.type === 'basicCheckBox') {
                //             const elementValue = component.attributes.isChecked;
                //             const elementKey = component.attributes.nameToCheck
                //             dataToBePassed[`${elementKey}`] = elementValue;
                //         }

                //         // calender Input

                //         if (component.attributes.type === 'basicDateTime') {
                //             const elementKey = editor.DomComponents.getWrapper().find('#compLabel')[0].view.el.innerText;

                //             const elementValue = editor.DomComponents.getWrapper().find('#compId')[0].view.el.textContent;

                //             if (elementKey && elementValue) {
                //                 dataToBePassed[`${elementKey}`] = elementValue;
                //             }

                //         }

                //         // select dropdown Input
                //         if (component.attributes.type === 'input-select') {
                //             const inputVal = component.view.el.childNodes[1].childNodes[1].value;
                //             const inputKey = component.attributes.Name;

                //             if (inputVal && inputKey) {
                //                 dataToBePassed[`${inputKey}`] = inputVal;
                //             }

                //         }

                //         // input type file
                //         if (component.attributes.attributes.id === 'upload-file') {

                //             const elementKey = editor.DomComponents.getWrapper().find('#apiLabel')[0].view.el.innerHTML;
                //             const elementKey1 = component.view.el.childNodes[0].childNodes[1].attributes[7].nodeValue;
                //             if (elementKey && elementKey1) {
                //                 dataToBePassed[`${elementKey}`] = elementKey1;
                //             }

                //         }

                //         // number input:
                //         if (component.attributes.attributes.id === 'number-regular-form') {
                //             const elementKey = component.view.el.childNodes[1].name;
                //             const elementKey1 = component.view.el.childNodes[1].value;
                //             if (elementKey && elementKey1) {
                //                 dataToBePassed[`${elementKey}`] = elementKey1;
                //             }
                //         }

                //         // }

                //     });

                //     console.log('dataToBePassed', dataToBePassed)

                //     if (Object?.keys(dataToBePassed)?.length !== 0) {
                //         for (let key in dataToBePassed) {
                //             console.log('valueCheck11111', valueCheck)
                //             if (dataToBePassed.hasOwnProperty(key) && !dataToBePassed[key]) {
                //                 valueCheck = true
                //             }
                //         }
                //         if (valueCheck === false) {
                //             localStorage.removeItem(`dynoApi`)
                //             localStorage.setItem(`dynoApi`, JSON.stringify(dataToBePassed));
                //             // const origin = window.location.origin;
                //             // window.location.href = origin + `/editor/` + `${link}` + '?projectId=' + `${project_id}`
                //         }
                //     }
                // }
              }
              // else if (link.length > 0 && willDataPass === false) {
              //     const origin = window.location.origin;
              //     window.location.href = origin + `/editor/` + `${link}` + '?projectId=' + `${project_id}`
              // }
            },
          },
        },
      });

      const dateTimeScript = function (props) {
        console.log("dateTimeiD", this.id);
        console.log("dateTime", this);
        const inp1 = document
          .querySelector(`#${this.id}`)
          .getElementsByTagName("ion-datetime");
        console.log("inp1", inp1);
        inp1[0].addEventListener("ionChange", (event) => {
          console.log("here");
          const selectedDate = event.detail.value;
          const newUtc = new Date(selectedDate);
          var date = new Date(newUtc),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
          const finalDate = [date.getFullYear(), mnth, day].join("-");
          console.log("finalDate", finalDate);
          const labelTag = document.querySelector(`#calenderInputId`);
          console.log("labelTag", labelTag);
          labelTag.innerText = `${finalDate}`;
          labelTag.value = `${finalDate}`;
        });
      };

      // date-time comp new
      editor.DomComponents.addType("basicDateTime", {
        model: {
          defaults: {
            script: dateTimeScript,
            tagName: "basicDateTime",
            date: "",
            traits: [
              {
                name: "date",
                type: "text",
                changeProp: true,
              },
            ],
            "script-props": ["date"],
            // Add some style, just to make the component visible
            components: `
                                  <div id="${calenderId}" >
                                  <p >
                                  <label id="${calenderLabelId}">Date<label>
                                  </p>
              
                                  <p>
                                  <ion-item id="${calenderItemId}">
                                  <ion-input
                                    id="calenderInputId"
                                    name=""
                                  ></ion-input>
                                  <ion-popover trigger="${calenderInputId}" size="contain" >
                                      <ion-datetime id="${calenderDateId}"
                                      ></ion-datetime>
                                  </ion-popover>
                                  </ion-item>
                                  </p>
              
                                  </div>
              
                                  <style>
              
                                  </style>
              
                                  `,
          },
        },
      });

      editor.DomComponents.addType("center-aligned-element-container", {
        model: {
          defaults: {
            tagName: "div",
            attributes: {
              id: "center-aligned-element-container",
              class: "center-aligned-element-container",
            },
            styles: `
                                      .center-aligned-element-container {
                                          display: flex;
                                          align-items: center;
                                          justify-content: center;
                                          padding: 0.5em;
                                          width: 100%;
                                          min-height: 3em;
                                      }
                                  `,
          },
        },
      });

      editor.DomComponents.addType("test-multiple", {
        model: {
          defaults: {
            items: ["Item 1", "Item 2", "Item 3"],
            traits: [
              {
                type: "checkbox",
                name: "items",
                changeProp: true,
                value: "Item1",
              },
              {
                type: "checkbox",
                name: "items",
                changeProp: true,
                value: "Item2",
              },
              {
                type: "checkbox",
                name: "items",
                changeProp: true,
                value: "Item3",
              },
            ],
          },
        },
        view: {
          render: function () {
            console.log("EHRJKWEHKJRHE");
            var items = this.model.get("items");
            console.log("ITEMS ---- ", items);
            var html = "<ul>";
            for (var i = 0; i < items.length; i++) {
              html += "<li>" + items[i] + "</li>";
            }
            html += "</ul>";
            this.$el.html(html);
            console.log("HTML _______ ", html);
            return this;
          },
        },
      });

      editor.DomComponents.addType("test-multiple-2", {
        model: {
          defaults: {
            count: 1,
            text: "Hello, world!",
            REST_API_URL: "",
            Response_Key_To_Be_Captured: "",
            Captured_Response_Key_Datatype: "",
            Dependent_On: "",
            Property_Name1: "",
            Property_Name2: "",
            Property_Name3: "",
            Property_Name4: "",
            Image_URL1: "",
            Image_URL2: "",
            Property_Id: "",
            traits: [
              {
                type: "number",
                name: "count",
                changeProp: true,
              },
              {
                type: "text",
                name: "text",
                changeProp: true,
              },
              {
                type: "text",
                name: "REST_API_URL",
                changeProp: true,
              },
              {
                type: "text",
                name: "Response_Key_To_Be_Captured",
                changeProp: true,
              },
              {
                type: "text",
                name: "Captured_Response_Key_Datatype",
                changeProp: true,
              },
              {
                type: "text",
                name: "Property_Name1",
                changeProp: true,
              },
              {
                type: "text",
                name: "Property_Name2",
                changeProp: true,
              },
              {
                type: "text",
                name: "Property_Name3",
                changeProp: true,
              },
              {
                type: "text",
                name: "Property_Name4",
                changeProp: true,
              },
              {
                type: "text",
                name: "Image_URL1",
                changeProp: true,
              },
              {
                type: "text",
                name: "Image_URL2",
                changeProp: true,
              },
              {
                type: "checkbox",
                name: "resizable",
                label: "Resizable",
                changeProp: true,
              },
              {
                type: "text",
                name: "Header1Key",
                changeProp: true,
                label: "Header 1 key",
              },
              {
                type: "text",
                name: "Header1Value",
                changeProp: true,
                label: "Header 1 Value",
              },
              {
                type: "text",
                name: "Header2Key",
                changeProp: true,
                label: "Header 2 Key",
              },
              {
                type: "text",
                name: "Header2Value",
                changeProp: true,
                label: "Header 2 Value",
              },
            ],
            stylable: true,
            styles: `
                      .items {
                          display: flex;
                          background-color: yellow;
                      }
                  `,
          },
        },
        view: {
          tagName: "div",
          attributes: {
            contenteditable: true, // add contenteditable attribute
          },

          editable() {
            return true;
          },

          init() {
            console.log("INITTTTT ----");
            // this.listenTo(this.model, 'change:styles.typography', this.handleTypographyChange);
            // this.listenTo(this.model, 'change:styles', this.handleTypographyChange);
            // this.listenTo(this.model.get('attributes'), 'change',  this.handleTypographyChange);
            this.listenTo(this.model, "change:count", this.render);
            this.listenTo(this.model, "change:REST_API_URL", this.render);
            this.listenTo(
              this.model,
              "change:Response_Key_To_Be_Captured",
              this.render
            );
            this.listenTo(
              this.model,
              "change:Captured_Response_Key_Datatype",
              this.render
            );
            this.listenTo(this.model, "change:Property_Name1", this.render);
            this.listenTo(this.model, "change:Property_Name2", this.render);
            this.listenTo(this.model, "change:Property_Name3", this.render);
            this.listenTo(this.model, "change:Property_Name4", this.render);
            this.listenTo(this.model, "change:Image_URL1", this.render);
            this.listenTo(this.model, "change:Image_URL2", this.render);
            this.listenTo(this.model, "change:resizable", this.handleResize);
            // this.listenTo(this.model, 'change:headers', this.render)
            this.listenTo(this.model, "change:Header1Key", this.render);
            this.listenTo(this.model, "change:Header1Value", this.render);
            this.listenTo(this.model, "change:Header2Key", this.render);
            this.listenTo(this.model, "change:Header2Value", this.render);
          },

          handleResize() {
            this.el.style.resize = this.model.get("resizable")
              ? "both"
              : "none";
          },
          //   handleTypographyChange() {
          //     console.log("DSFLKSDJGLKHDSLKGHKLDSGHLKGHKLDH__________")
          //     const typographyStyles = this.model.get('styles.style');
          //     const styleString = Object.keys(typographyStyles)
          //       .map((prop) => `${prop}: ${typographyStyles[prop]};`)
          //       .join(' ');
          //     this.el.style.cssText += ` ${styleString}`; // add typography styles to element's style attribute
          //     this.render();
          //   },
          onRender() {
            var count = this.model.get("count");

            var rest_api_url = this.model.get("REST_API_URL");
            var response_key = this.model.get("Response_Key_To_Be_Captured");
            var captured_type = this.model.get(
              "Captured_Response_Key_Datatype"
            );
            var property_name1 = this.model.get("Property_Name1");
            var property_name2 = this.model.get("Property_Name2");
            var property_name3 = this.model.get("Property_Name3");
            var property_name4 = this.model.get("Property_Name4");

            var Image_URL1 = this.model.get("Image_URL1");
            var Image_URL2 = this.model.get("Image_URL2");
            var header1Key = this.model.get("Header1Key");
            var header1Value = this.model.get("Header1Value");
            var header2Key = this.model.get("Header2Key");
            var header2Value = this.model.get("Header2Value");
            console.log(rest_api_url);
            console.log(response_key);
            console.log(captured_type);
            console.log(property_name1);

            if (
              rest_api_url &&
              response_key &&
              captured_type &&
              property_name1
            ) {
              console.log(rest_api_url);
              console.log(response_key);
              console.log(captured_type);
              console.log(property_name1);

              var html = '<div class="test-editable2">';
              var options = {
                method: "GET",
                headers: {
                  [header1Key]: header1Value,
                  [header2Key]: header2Value,
                },
              };
              // let html2 = document.querySelector('.test-editable2');
              // let html2 = document.createElement('div')
              let html2 = `<div> `;
              console.log("HENTELA-----", html2);
              let i = 0;
              fetch(`${rest_api_url}`, options).then((res) => {
                res.json().then((data) => {
                  // if(i < 4) {
                  if (response_key) {
                    // let obj2 = Object.keys(data[`${response_key}`]).find(`${property_name}`)
                    // console.log("ob2323 ", obj2)
                    if (property_name1) {
                      html2 += '<div style="display: flex">';
                      // displayObject(data[`${response_key}`], html2, property_name)
                      data[`${response_key}`].map((obj) => {
                        if (i < 4) {
                          html2 +=
                            "<div class='items' style='display: flex; flex-direction: column';>";
                          console.log("IN MAP");
                          if (obj.hasOwnProperty(`${Image_URL1}`)) {
                            console.log("HASDD PROPERTY");
                            html2 += `<img contentEditable="true" src="${
                              obj[`${Image_URL1}`]
                            }" style='width: 400px; height: 300px' />`;

                            if (obj.hasOwnProperty(`${property_name1}`)) {
                              html2 += `<h3 contentEditable="true"> ${
                                obj[`${property_name1}`]
                              } </h3>`;
                            }
                          }
                        }
                      });
                    }
                  }
                });
              });
            }
          },
        },
      });

      const rest_api_displayScript = async function (props) {
        console.log("SCCDSDS ", props);

        let API_RESPONSE;

        if (props.REST_API_URL) {
          let response = await fetch(`${props.REST_API_URL}`);

          let data = await response.json();

          // setValuesForAPI(data)

          API_RESPONSE = data;

          console.log("REST_API_URL response-1 data:", data);

          console.log("REST_API_URL response-1 data:", API_RESPONSE);
        } else if (props.Property_Name) {
          const el = this;

          el.setAttribute("name", props.Property_Name);
        }

        function setValuesForAPI(data) {
          if (props.Property_Name) {
            let htmlEl = document.querySelector(
              `[name="${props.Property_Name}"]`
            );

            htmlEl.innerHTML = data[`${props.Property_Name}`];
          }
        }
      };

      editor.DomComponents.addType("rest-api-display", {
        model: {
          defaults: {
            script: rest_api_displayScript,
            tagName: "rest-api-display",
            REST_API_URL: "",
            Number_of_items: 0,
            Name: "",

            traits: [
              {
                type: "text",
                name: "REST_API_URL",
                changeProp: true,
              },
              {
                type: "number",
                name: "Number_of_items",
                changeProp: true,
              },
              {
                type: "text",
                name: "Name",
                changeProp: true,
              },
            ],
            "script-props": ["REST_API_URL", "Number_of_items", "Name"],
            // tagName: 'div',
            attributes: { title: "Hello" },
            // Can be a string
            components: `
                                      <p>Paragraph test</p>
                                  `,
          },
        },
      });

      editor.DomComponents.addType("test-multiple", {
        model: {
          defaults: {
            items: ["Item 1", "Item 2", "Item 3"],
            traits: [
              {
                type: "checkbox",
                name: "items",
                changeProp: true,
                value: "Item1",
              },
              {
                type: "checkbox",
                name: "items",
                changeProp: true,
                value: "Item2",
              },
              {
                type: "checkbox",
                name: "items",
                changeProp: true,
                value: "Item3",
              },
            ],
          },
        },
        view: {
          render: function () {
            console.log("EHRJKWEHKJRHE");
            var items = this.model.get("items");
            console.log("ITEMS ---- ", items);
            var html = "<ul>";
            for (var i = 0; i < items.length; i++) {
              html += "<li>" + items[i] + "</li>";
            }
            html += "</ul>";
            this.$el.html(html);
            console.log("HTML _______ ", html);
            return this;
          },
        },
      });

      editor.DomComponents.addType("test-multiple-2", {
        model: {
          defaults: {
            count: 1,
            text: "Hello, world!",
            REST_API_URL: "",
            Response_Key_To_Be_Captured: "",
            Captured_Response_Key_Datatype: "",
            Dependent_On: "",
            Property_Name1: "",
            Property_Name2: "",
            Property_Name3: "",
            Property_Name4: "",
            Image_URL1: "",
            Image_URL2: "",
            Property_Id: "",
            traits: [
              {
                type: "number",
                name: "count",
                changeProp: true,
              },
              {
                type: "text",
                name: "text",
                changeProp: true,
              },
              {
                type: "text",
                name: "REST_API_URL",
                changeProp: true,
              },
              {
                type: "text",
                name: "Response_Key_To_Be_Captured",
                changeProp: true,
              },
              {
                type: "text",
                name: "Captured_Response_Key_Datatype",
                changeProp: true,
              },
              {
                type: "text",
                name: "Property_Name1",
                changeProp: true,
              },
              {
                type: "text",
                name: "Property_Name2",
                changeProp: true,
              },
              {
                type: "text",
                name: "Property_Name3",
                changeProp: true,
              },
              {
                type: "text",
                name: "Property_Name4",
                changeProp: true,
              },
              {
                type: "text",
                name: "Image_URL1",
                changeProp: true,
              },
              {
                type: "text",
                name: "Image_URL2",
                changeProp: true,
              },
              {
                type: "checkbox",
                name: "resizable",
                label: "Resizable",
                changeProp: true,
              },
              {
                type: "text",
                name: "Header1Key",
                changeProp: true,
                label: "Header 1 key",
              },
              {
                type: "text",
                name: "Header1Value",
                changeProp: true,
                label: "Header 1 Value",
              },
              {
                type: "text",
                name: "Header2Key",
                changeProp: true,
                label: "Header 2 Key",
              },
              {
                type: "text",
                name: "Header2Value",
                changeProp: true,
                label: "Header 2 Value",
              },
            ],
            stylable: true,
            styles: `
                                  .items {
                                      display: flex;
                                      background-color: yellow;
                                  }
                              `,
          },
        },
        view: {
          tagName: "div",
          attributes: {
            contenteditable: true, // add contenteditable attribute
          },

          editable() {
            return true;
          },

          init() {
            console.log("INITTTTT ----");
            // this.listenTo(this.model, 'change:styles.typography', this.handleTypographyChange);
            // this.listenTo(this.model, 'change:styles', this.handleTypographyChange);
            // this.listenTo(this.model.get('attributes'), 'change',  this.handleTypographyChange);
            this.listenTo(this.model, "change:count", this.render);
            this.listenTo(this.model, "change:REST_API_URL", this.render);
            this.listenTo(
              this.model,
              "change:Response_Key_To_Be_Captured",
              this.render
            );
            this.listenTo(
              this.model,
              "change:Captured_Response_Key_Datatype",
              this.render
            );
            this.listenTo(this.model, "change:Property_Name1", this.render);
            this.listenTo(this.model, "change:Property_Name2", this.render);
            this.listenTo(this.model, "change:Property_Name3", this.render);
            this.listenTo(this.model, "change:Property_Name4", this.render);
            this.listenTo(this.model, "change:Image_URL1", this.render);
            this.listenTo(this.model, "change:Image_URL2", this.render);
            this.listenTo(this.model, "change:resizable", this.handleResize);
            // this.listenTo(this.model, 'change:headers', this.render)
            this.listenTo(this.model, "change:Header1Key", this.render);
            this.listenTo(this.model, "change:Header1Value", this.render);
            this.listenTo(this.model, "change:Header2Key", this.render);
            this.listenTo(this.model, "change:Header2Value", this.render);
          },

          handleResize() {
            this.el.style.resize = this.model.get("resizable")
              ? "both"
              : "none";
          },
          //   handleTypographyChange() {
          //     console.log("DSFLKSDJGLKHDSLKGHKLDSGHLKGHKLDH__________")
          //     const typographyStyles = this.model.get('styles.style');
          //     const styleString = Object.keys(typographyStyles)
          //       .map((prop) => `${prop}: ${typographyStyles[prop]};`)
          //       .join(' ');
          //     this.el.style.cssText += ` ${styleString}`; // add typography styles to element's style attribute
          //     this.render();
          //   },
          onRender() {
            var count = this.model.get("count");

            var rest_api_url = this.model.get("REST_API_URL");
            var response_key = this.model.get("Response_Key_To_Be_Captured");
            var captured_type = this.model.get(
              "Captured_Response_Key_Datatype"
            );
            var property_name1 = this.model.get("Property_Name1");
            var property_name2 = this.model.get("Property_Name2");
            var property_name3 = this.model.get("Property_Name3");
            var property_name4 = this.model.get("Property_Name4");

            var Image_URL1 = this.model.get("Image_URL1");
            var Image_URL2 = this.model.get("Image_URL2");
            var header1Key = this.model.get("Header1Key");
            var header1Value = this.model.get("Header1Value");
            var header2Key = this.model.get("Header2Key");
            var header2Value = this.model.get("Header2Value");
            console.log(rest_api_url);
            console.log(response_key);
            console.log(captured_type);
            console.log(property_name1);

            if (
              rest_api_url &&
              response_key &&
              captured_type &&
              property_name1
            ) {
              console.log(rest_api_url);
              console.log(response_key);
              console.log(captured_type);
              console.log(property_name1);

              var html = '<div class="test-editable2">';
              var options = {
                method: "GET",
                headers: {
                  [header1Key]: header1Value,
                  [header2Key]: header2Value,
                },
              };
              // let html2 = document.querySelector('.test-editable2');
              // let html2 = document.createElement('div')
              let html2 = `<div> `;
              console.log("HENTELA-----", html2);
              let i = 0;
              fetch(`${rest_api_url}`, options).then((res) => {
                res.json().then((data) => {
                  // if(i < 4) {
                  if (response_key) {
                    // let obj2 = Object.keys(data[`${response_key}`]).find(`${property_name}`)
                    // console.log("ob2323 ", obj2)
                    if (property_name1) {
                      html2 += '<div style="display: flex">';
                      // displayObject(data[`${response_key}`], html2, property_name)
                      data[`${response_key}`].map((obj) => {
                        if (i < 4) {
                          html2 +=
                            "<div class='items' style='display: flex; flex-direction: column';>";
                          console.log("IN MAP");
                          if (obj.hasOwnProperty(`${Image_URL1}`)) {
                            console.log("HASDD PROPERTY");
                            html2 += `<img contentEditable="true" src="${
                              obj[`${Image_URL1}`]
                            }" style='width: 400px; height: 300px' />`;

                            if (obj.hasOwnProperty(`${property_name1}`)) {
                              html2 += `<h3 contentEditable="true"> ${
                                obj[`${property_name1}`]
                              } </h3>`;
                            }

                            if (obj.hasOwnProperty(`${property_name2}`)) {
                              html2 += `<p contentEditable="true">  ${
                                obj[`${property_name2}`]
                              } </p>`;
                            }
                          }
                          html2 += "</div>";
                          i++;
                        }
                      });
                    }
                    // i++;
                  }

                  // }
                  // displayObject(data, html2)
                  console.log("htetetete", html2);
                  html2 += "</div>";
                  this.$el.html(html2);
                  // this.el.innerHTML = html2.innerHTML;

                  return this;
                });
              });

              // html2 += "</div>"
            } else {
              // Response_Key_To_Be_Captured: "",
              // Captured_Response_Key_Datatype: "",
              // Dependent_On: "",
              // Property_Name: "",
              // Property_Id: "",
              var html = '<div class="test-editable2">';
              for (var i = 0; i < count; i++) {
                console.log("IN FOR LOOOOOPPPPP");
                html += '<p contenteditable="true">' + "Insert API" + "</p>";
              }
              html += "</div>";
              this.$el.html(html);
              // this.renderChildren();
              return this;
            }

            // var html3 = '<div class="test-editable3"> </div>'
            // html3+= "</div>"
            // this.$el.html(html3)
            // return this;
          },
        },
      });

      editor.StyleManager.addType("test-multiple-2", {
        name: "Test Multiple 2",
        properties: [
          {
            name: "Count",
            property: "count",
            type: "integer",
          },
          {
            name: "Text",
            property: "text",
          },
        ],
      });

      const listCard = async function (props) {
        console.log("props:", props);
      };

      const gTranslateScript = function () {
        const script1 = document.createElement("script");
        script1.setAttribute("type", "text/javascript");
        script1.innerHTML = `
            function googleTranslateElementInit() {
              new google.translate.TranslateElement({pageLanguage: 'en'}, 'google_translate_element');
            }
          `;
        this.append(script1);

        const script2 = document.createElement("script");
        script2.setAttribute("type", "text/javascript");
        script2.setAttribute(
          "src",
          "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        );
        this.append(script2);
      };

      editor.DomComponents.addType("g-translate-container", {
        model: {
          defaults: {
            tagName: "div",
            script: gTranslateScript,
            type: "g-translate-container",
            attributes: {
              class: "g-translate-container",
            },

            components: [
              {
                tagName: "div",
                type: "g-translate",
                attributes: {
                  id: "google_translate_element",
                  class: "google_translate_element",
                },
              },
            ],
          },
        },
      });

      // login form template 1
      function createLoginFormTemplate1() {}

      // LOGIN FORM
      editor.DomComponents.addType("login-form-temp1", {
        model: {
          defaults: {
            // script: createLoginFormTemplate1,

            tagName: "section",
            attributes: { class: "login-form-temp1" },

            styles: `
                                      .login-form-temp1 {
                                          width: 50%;
                                          padding: 0.5em;
                                      }
                                  `,

            components: [
              {
                tagName: "form",
                attributes: { class: "login-form-temp1-container" },

                styles: `
                                          .login-form-container {
                                              position: relative;
                                              max-width: 100%;
                                              background-color: white;
                                              border-radius: 20px;
                                              padding: 0.5em 0.5em 3em 0.5em;
                                              margin: auto;
                                              border: 1px solid black;
                                          }
                                      `,

                components: [
                  {
                    tagName: "h2",
                    attributes: { class: "login-form-temp1-header" },
                    content: "Login Form",

                    styles: `
                                                  .login-form-header {
                                                      text-align: center;
                                                  }
                                              `,
                  },
                  {
                    tagName: "input",
                    attributes: {
                      class: "login-form-temp1-email-input",
                      type: "email",
                      placeholder: "Enter email",
                    },

                    styles: `
                                                  .login-form-temp1-email-input {
                                                      display: block;
                                                      width: 90%;
                                                      border-radius: 25px;
                                                      boder: none;
                                                      padding: 0.5em;
                                                      background-color: #d9d6ce;
                                                      margin-right: auto;
                                                      margin-left: auto;
                                                      margin-top: 5%;
                                                      color: black;
                                                  }
                                              `,
                  },
                  {
                    tagName: "input",
                    attributes: {
                      class: "login-form-temp1-username-input",
                      type: "text",
                      placeholder: "Enter username",
                    },

                    styles: `
                                                  .login-form-temp1-username-input {
                                                      display: block;
                                                      width: 90%;
                                                      border-radius: 25px;
                                                      boder: none;
                                                      padding: 0.5em;
                                                      background-color: #d9d6ce;
                                                      margin-right: auto;
                                                      margin-left: auto;
                                                      margin-top: 5%;
                                                      color: black;
                                                  }
                                              `,
                  },
                  {
                    tagName: "input",
                    attributes: {
                      class: "login-form-temp1-password-input",
                      type: "password",
                      placeholder: "Enter password",
                    },

                    styles: `
                                                  .login-form-temp1-password-input {
                                                      display: block;
                                                      width: 90%;
                                                      border-radius: 25px;
                                                      boder: none;
                                                      padding: 0.5em;
                                                      background-color: #d9d6ce;
                                                      margin-right: auto;
                                                      margin-left: auto;
                                                      margin-top: 5%;
                                                      color: black;
                                                  }
                                              `,
                  },
                  {
                    tagName: "button",
                    attributes: {
                      class: "login-form-temp1-submit-button",
                    },
                    content: "Submit",

                    styles: `
                                                  .login-form-temp1-submit-button {
                                                      display: block;
                                                      width: 90%;
                                                      border-radius: 25px;
                                                      boder: none;
                                                      padding: 0.6em;
                                                      background-color: #2979FF;
                                                      margin-right: auto;
                                                      margin-left: auto;
                                                      margin-top: 5%;
                                                      color: white;
                                                  }
                                              `,
                  },
                  {
                    tagName: "div",
                    attributes: {
                      class: "social-media-container",
                    },

                    styles: `
                                                  .social-media-container {
                                                      display: flex;
                                                      align-items: center;
                                                      justify-content: space-between;
                                                      width: 40%;
                                                      
                                                      position: absolute;
                                                      left: 30%;
                                                      right: auto;
                                                      bottom: -5%;
                                                  }
                                              `,
                    components: [
                      {
                        tagName: "span",
                        attributes: {
                          class: "form-facebook-icon",
                        },
                        content: '<i class="fa-brands fa-facebook"></i>',

                        styles: `
                                                          .form-facebook-icon {
                                                              padding: 0.5em;
                                                              border-radius: 50%;
                                                              background-color: #2979FF,
                                                              color: white;
                                                          }
                                                      `,
                      },
                      {
                        tagName: "span",
                        attributes: {
                          class: "form-google-icon",
                        },
                        content: '<i class="fa-brands fa-google"></i>',

                        styles: `
                                                          .form-google-icon {
                                                              padding: 0.5em;
                                                              border-radius: 50%;
                                                              background-color: red,
                                                              color: white;
                                                          }
                                                      `,
                      },
                      {
                        tagName: "span",
                        attributes: {
                          class: "form-twitter-icon",
                        },
                        content: '<i class="fa-brands fa-twitter"></i>',

                        styles: `
                                                          .form-twitter-icon {
                                                              padding: 0.5em;
                                                              border-radius: 50%;
                                                              background-color: green,
                                                              color: white;
                                                          }
                                                      `,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        },
      });

      editor.DomComponents.addType("login-form-temp2", {
        model: {
          defaults: {
            // script:,
            tagName: "section",
            attributes: { class: "login-form-temp2" },

            styles: `
                                      .login-form-temp2 {
                                          width: 60%;
                                          padding: 0.5em;
                                      }
                                  `,

            components: [
              {
                tagName: "div",
                attributes: { class: "login-form-temp2-container" },

                styles: `
                                          .login-form-container {
                                              display: flex;
                                              align-items: center;
                                              max-width: 100%;
                                              height: 30em;
                                              background-color: white;
                                              border-radius: 20px;
                                              margin: auto;
                                              border: 1px solid black;
                                          }
                                      `,

                components: [
                  {
                    tagName: "div",
                    attributes: { class: "login-form-temp2-left-container" },

                    styles: `
                                                  .login-form-temp2-left-container {
                                                      width: 50%;
                                                      height: 100%;
                                                      
                                                      display: flex;
                                                      justify-content: center;
                                                      align-items: center;
                                                  }
                                              `,

                    components: [
                      {
                        tagName: "div",
                        attributes: {
                          class: "login-form-temp2-left-container-image-holder",
                        },

                        styles: `
                                                          .login-form-temp2-left-container-image-holder {
                                                              padding: 0.5em;
                                                              width: 50%;
                                                              height: 40%;
                                                              border-radius: 50%;
                                                              background-color: #f0eee9;
                                                              
                                                              display: flex;
                                                              justify-content: center;
                                                              align-items: center;
                                                          }
                                                      `,

                        components: [
                          {
                            tagName: "img",
                            attributes: {
                              class:
                                "login-form-temp2-left-container-image-holder-image",
                              src: "https://png.pngtree.com/element_our/20190602/ourmid/pngtree-cartoon-laptop-free-illustration-image_1377333.jpg",
                            },

                            styles: `
                                                              .login-form-temp2-left-container-image-holder-image {
                                                                  width: 80%;
                                                              }
                                                          `,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    tagName: "form",
                    attributes: { class: "login-form-temp2-right-container" },

                    styles: `
                                                  .login-form-temp2-right-container {
                                                      width: 50%;
                                                      height: 100%;
                                                      
                                                      display: flex;
                                                      flex-direction: column;
                                                      justify-content: center;
                                                      align-items: center;
                                                      row-gap: 2em;
                                                  }
                                              `,

                    components: [
                      {
                        tagName: "h2",
                        attributes: {
                          class: "login-form-temp2-right-container-header",
                        },
                        content: "Member Login",

                        styles: `
                                                          .login-form-temp2-right-container-header {
                                                              text-align: center;
                                                          }
                                                      `,
                      },
                      {
                        tagName: "div",
                        attributes: {
                          class:
                            "login-form-temp2-right-container-email-input-container",
                        },

                        styles: `
                                                          .login-form-temp2-right-container-email-input-container {
                                                              position: relative;
                                                              margin-left: auto;
                                                              margin-right: auto;
                                                              width: 90%;
                                                          }
                                                      `,

                        components: [
                          {
                            tagName: "i",
                            attributes: {
                              class:
                                "fa-solid fa-envelope login-form-temp2-right-container-email-input-icon",
                            },

                            styles: `
                                                                  .login-form-temp2-right-container-email-input-icon {
                                                                      color: 'gray';
                                                                      font-size: 90%;
                                                                      z-index: 1;
              
                                                                      position: absolute;
                                                                      top: 35%;
                                                                      left: 4%;
                                                                  }
                                                              `,
                          },
                          {
                            tagName: "input",
                            attributes: {
                              class:
                                "login-form-temp2-right-container-email-input",
                              type: "email",
                              placeholder: "Email",
                            },

                            styles: `
                                                                  .login-form-temp2-right-container-email-input {
                                                                      width: 100%;
                                                                      border-radius: 25px;
                                                                      boder: none;
                                                                      padding: 0.5em 0.5em 0.5em 3em;
                                                                      background-color: #d9d6ce;
                                                                      color: black;
                                                                  }
                                                              `,
                          },
                        ],
                      },
                      {
                        tagName: "div",
                        attributes: {
                          class:
                            "login-form-temp2-right-container-username-input-container",
                        },

                        styles: `
                                                          .login-form-temp2-right-container-username-input-container {
                                                              position: relative;
                                                              margin-left: auto;
                                                              margin-right: auto;
                                                              width: 90%;
                                                          }
                                                      `,

                        components: [
                          {
                            tagName: "i",
                            attributes: {
                              class:
                                "fa-solid fa-envelope login-form-temp2-right-container-username-input-icon",
                            },

                            styles: `
                                                                  .login-form-temp2-right-container-username-input-icon {
                                                                      color: 'gray';
                                                                      font-size: 90%;
                                                                      z-index: 1;
              
                                                                      position: absolute;
                                                                      top: 35%;
                                                                      left: 4%;
                                                                  }
                                                              `,
                          },
                          {
                            tagName: "input",
                            attributes: {
                              class:
                                "login-form-temp2-right-container-username-input",
                              type: "text",
                              placeholder: "Username",
                            },

                            styles: `
                                                                  .login-form-temp2-right-container-username-input {
                                                                      width: 100%;
                                                                      border-radius: 25px;
                                                                      boder: none;
                                                                      padding: 0.5em 0.5em 0.5em 3em;
                                                                      background-color: #d9d6ce;
                                                                      color: black;
                                                                  }
                                                              `,
                          },
                        ],
                      },
                      {
                        tagName: "div",
                        attributes: {
                          class:
                            "login-form-temp2-right-container-password-input-container",
                        },

                        styles: `
                                                          .login-form-temp2-right-container-password-input-container {
                                                              position: relative;
                                                              margin-left: auto;
                                                              margin-right: auto;
                                                              width: 90%;
                                                          }
                                                      `,

                        components: [
                          {
                            tagName: "i",
                            attributes: {
                              class:
                                "fa-solid fa-lock login-form-temp2-right-container-password-input-icon",
                            },

                            styles: `
                                                                  .login-form-temp2-right-container-password-input-icon {
                                                                      color: 'gray';
                                                                      font-size: 90%;
                                                                      z-index: 1;
              
                                                                      position: absolute;
                                                                      top: 35%;
                                                                      left: 4%;
                                                                  }
                                                              `,
                          },
                          {
                            tagName: "input",
                            attributes: {
                              class:
                                "login-form-temp2-right-container-password-input",
                              type: "password",
                              placeholder: "Password",
                            },

                            styles: `
                                                                  .login-form-temp2-right-container-password-input {
                                                                      width: 100%;
                                                                      border-radius: 25px;
                                                                      boder: none;
                                                                      padding: 0.5em 0.5em 0.5em 3em;
                                                                      background-color: #d9d6ce;
                                                                      color: black;
                                                                  }
                                                              `,
                          },
                        ],
                      },
                      {
                        tagName: "button",
                        attributes: {
                          class:
                            "login-form-temp2-right-container-login-button",
                        },
                        content: "LOGIN",

                        styles: `
                                                          width: 90%;
                                                          background-color: green;
                                                          border-radius: 25px;
                                                          padding: 0.8em;
                                                          cursor: pointer;
                                                          color: white;
                                                      `,
                      },
                      {
                        tagName: "p",
                        attributes: {
                          class:
                            "login-form-temp2-right-container-forget-credentials",
                        },

                        components: [
                          {
                            type: "textnode",
                            content: "Forget ",
                          },
                          {
                            tagName: "span",
                            attributes: {
                              class:
                                "login-form-temp2-right-container-forget-credentials-container",
                            },

                            components: {
                              tagName: "a",
                              attributes: {
                                class:
                                  "login-form-temp2-right-container-forget-credentials-link",
                                href: "#",
                              },
                              content: "Username/Password?",

                              styles: `
                                                                      .login-form-temp2-right-container-forget-credentials-link {
                                                                          color: green;
                                                                          text-decoration: none;
                                                                          cursor: pointer;
                                                                      }
                                                                  `,
                            },
                          },
                        ],
                      },
                      {
                        tagName: "a",
                        attributes: {
                          class:
                            "login-form-temp2-right-container-create-credentials-link",
                          href: "#",
                        },

                        styles: `
                                                          .login-form-temp2-right-container-create-credentials-link {
                                                              color: green;
                                                              text-decoration: none;
                                                              cursor: pointer;
                                                          }
                                                      `,

                        components: [
                          {
                            type: "textnode",
                            content: "Create your account ",
                          },
                          {
                            tagName: "i",
                            attributes: {
                              class:
                                "fa-solid fa-arrow-right login-form-temp2-right-container-create-credentials-arrow",
                            },

                            styles: `
                                                                  .login-form-temp2-right-container-create-credentials-arrow {
                                                                      color: green;
                                                                  }
                                                              `,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        },
      });

      function ratingSliderScript() {
        const ratingSlider = document.getElementById("rating-slider");
        const smiley = document.getElementById("smiley");
        const displayRating = document.getElementById("display-rating");
        const hiddenRatingInput = document.getElementById("hidden-rating");
        //const ratingForm = document.getElementById('rating-form');

        ratingSlider.addEventListener("input", () => {
          alert(hiddenRatingInput.value);
          const rating = ratingSlider.value;

          // Update smiley position
          const sliderWidth = ratingSlider.clientWidth;
          const smileyPosition = (rating / 10) * sliderWidth;
          smiley.style.left = `${smileyPosition}px`;

          // Update smiley icon based on rating
          if (rating >= 7) {
            smiley.textContent = "😄"; // Happy face
          } else if (rating >= 4) {
            smiley.textContent = "😐"; // Neutral face
          } else {
            smiley.textContent = "😞"; // Sad face
          }

          // Update displayed rating
          displayRating.textContent = rating;

          // Update hidden input value
          hiddenRatingInput.value = rating;
        });

        // Initialize with default value
        ratingSlider.dispatchEvent(new Event("input"));

        // // Handle form submission
        // ratingForm.addEventListener('submit', (e) => {
        //     e.preventDefault();
        //     const selectedRating = hiddenRatingInput.value;
        //     alert(`You submitted a rating of ${selectedRating}/10`);
        // });
      }

      function calendyScript({
        url,
        widgettype,
        badgetext,
        bgcolor,
        textcolor,
        buttonlink,
        hidedetail,
        branding,
      }) {
        var el = this;
        function init() {
          var btn = el.querySelector(".gpd-calendly-btn"),
            config = [
              !!hidedetail ? "hide_event_type_details=1" : "",
              "background_color=".concat(bgcolor),
              "text_color=".concat(textcolor),
              "primary_color=".concat(buttonlink),
            ]
              .filter(function (option) {
                return option;
              })
              .join("&");
          if (widgettype === "popup") {
            btn &&
              btn.addEventListener("click", function () {
                return window.Calendly.initPopupWidget({
                  url: "".concat(url, "?").concat(config),
                  branding: !!branding,
                });
              });
          } else {
            btn && (btn.style.display = "none");
            if (widgettype === "inline") {
              window.Calendly.initInlineWidget({
                url: "".concat(url, "?").concat(config),
                parentElement: el,
                branding: !!branding,
              });
            } else {
              window.Calendly.initBadgeWidget({
                url: "".concat(url, "?").concat(config),
                text: badgetext || "Schedule meeting",
                textColor: textcolor,
                color: bgcolor,
                branding: !!branding,
              });
            }
          }
        }
        if (window.Calendly) init();
        else {
          var link = document.createElement("link");
          link.href = "https://assets.calendly.com/assets/external/widget.css";
          link.rel = "stylesheet";
          document.head.appendChild(link);
          var script = document.createElement("script");
          script.src = "https://assets.calendly.com/assets/external/widget.js";
          script.onload = init;
          document.head.appendChild(script);
        }
      }

      function launchMobileNav() {
        const homeNav = document.querySelector("#home-nav");
        const homePage = document.querySelector("#home-page");
        homeNav.root = homePage;

        const radioNav = document.querySelector("#radio-nav");
        const radioPage = document.querySelector("#radio-page");
        radioNav.root = radioPage;

        const libraryNav = document.querySelector("#library-nav");
        const libraryPage = document.querySelector("#library-page");
        libraryNav.root = libraryPage;

        const searchNav = document.querySelector("#search-nav");
        const searchPage = document.querySelector("#search-page");
        searchNav.root = searchPage;
      }

      function launch3dAnimation({ url }) {
        var el = this;
        function init() {
          var btn = el.querySelector(".gpd-calendly-admin-btn");
          btn &&
            btn.addEventListener("click", function () {
              window.open(url);
            });
        }
        init();
      }

      function calendyAdminScript({
        url,
        clientid,
        clientsecret,
        redirecturi,
      }) {
        var el = this;
        //CalendyScreen(editorRef.current);
        calendyClientSecret = clientsecret;
        calendyClientID = clientid;
        var accesstokenurl = "https://auth.calendly.com/oauth/token";
        var calendyTokenUrl = accesstokenurl;
        function init() {
          var btn = el.querySelector(".gpd-calendly-admin-btn"),
            config = [
              "response_type=code&client_id=".concat(clientid),
              "redirect_uri=".concat(redirecturi),
            ]
              .filter(function (option) {
                return option;
              })
              .join("&");
          btn &&
            btn.addEventListener("click", function () {
              window.open("".concat(url, "?").concat(config));
              function fetchCode() {}
              window.setTimeout(fetchCode, 20000);

              let code = localStorage.getItem("authorizationCode");
              const credentials = `${clientid}:${clientsecret}`;
              const encodedCredentials = btoa(credentials);

              const headers = new Headers();
              headers.append("Authorization", `Basic ${encodedCredentials}`);
              headers.append("Content-Type", "application/json");

              const requestBody = {
                code: code,
                redirect_uri: redirecturi,
                grant_type: "authorization_code",
              };

              const requestOptions = {
                method: "POST",
                headers: headers,
                body: JSON.stringify(requestBody),
              };

              fetch(accesstokenurl, requestOptions)
                .then((response) => {
                  let responseCode = response.status;
                  return response.json();
                })
                .then((data) => {
                  const accessToken = data.access_token;
                  const endpoint = "https://api.calendly.com/users/me";
                  const headers = new Headers();
                  headers.append("Authorization", `Bearer ${accessToken}`);
                  headers.append("Content-Type", "application/json");

                  const requestOptions = {
                    method: "GET",
                    headers: headers,
                  };

                  fetch(endpoint, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                      let uri12 = data.resource.uri;
                      //alert(uri12);
                      console.log(uri12);
                      //alert(accessToken);
                      const endpoint =
                        "https://api.calendly.com/scheduled_events?user=" +
                        uri12;
                      const headers = new Headers();
                      headers.append("Authorization", `Bearer ${accessToken}`);
                      headers.append("Content-Type", "application/json");

                      const requestOptions = {
                        method: "GET",
                        headers: headers,
                      };
                      fetch(endpoint, requestOptions)
                        .then((response) => {
                          //alert("check2");
                          //alert(response.status);
                          return response.json();
                        })
                        .then((data) => {
                          const container = document.querySelector(
                            ".gpd-calendly-admin-btn"
                          );
                          container.innerHTML = "";
                          data.collection.forEach((item) => {
                            //alert(item.uri);
                            const card = document.createElement("div");
                            card.classList.add("card");
                            card.style.backgroundColor = "#f4f4f4";

                            const uri = document.createElement("p");
                            uri.textContent = `URI: ${item.uri}`;

                            const name = document.createElement("p");
                            name.textContent = `Name: ${item.name}`;

                            const status = document.createElement("p");
                            status.textContent = `Status: ${item.status}`;

                            const start = document.createElement("p");
                            start.textContent = `Start time: ${item.start_time}`;

                            const end = document.createElement("p");
                            end.textContent = `End Time: ${item.end_time}`;

                            card.appendChild(uri);
                            card.appendChild(name);
                            card.appendChild(status);
                            card.appendChild(start);
                            card.appendChild(end);
                            container.appendChild(card);
                          });
                        })
                        .catch((error) => {
                          alert(error);
                        });
                    })
                    .catch((error) => {
                      console.error("Error:", error);
                    });
                })
                .catch((error) => {
                  console.error("Error:", error);
                });
            });
        }
        if (window.Calendly) init();
        else {
          var link = document.createElement("link");
          link.href = "https://assets.calendly.com/assets/external/widget.css";
          link.rel = "stylesheet";
          document.head.appendChild(link);
          var script = document.createElement("script");
          script.src = "https://assets.calendly.com/assets/external/widget.js";
          script.onload = init;
          document.head.appendChild(script);
        }
      }

      editor.DomComponents.addType("calendy-admin", {
        model: {
          defaults: {
            name: "calendly-admin",
            icon: '<svg viewBox="0 0 24 24"><path d="M14 14H7v2h7m5 3H5V8h14m0-5h-1V1h-2v2H8V1H6v2H5a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2m-2 7H7v2h10v-2z"></path></svg>',
            droppable: false,
            resizable: false,
            script: calendyAdminScript,
            content: `<div style = "padding:10px 0;" data-gjs-type="calendly-admin">
        <div class= "gpd-calendly-admin-btn gpd-calendly-admin-btn-st" data-gjs-type="calendly-admin-btn">View Meetings</div>
      </div>`,
            attributes: {
              style: "font-family: Helvetica",
            },
            url: "https://calendly.com/oauth/authorize",
            widgettype: "popup",
            badgetext: "Schedule time with me",
            bgcolor: "#ffffff",
            textcolor: "#4d5055",
            buttonlink: "#00a2ff",
            branding: false,
            hidedetail: false,
            traits: [
              {
                name: "url",
                label: "Calendly Authorize URL",
                placeholder: "https://calendly.com/oauth/authorize",
                default: "https://calendly.com/oauth/authorize",
                changeProp: true,
              },
              {
                name: "clientid",
                label: "Client ID",
                placeholder: "Enter Client ID",
                default: "Enter Client ID",
                changeProp: true,
              },
              {
                name: "clientsecret",
                label: "Client Secret",
                placeholder: "Enter Client Secret",
                default: "Enter Client Secret",
                changeProp: true,
              },
              {
                name: "redirecturi",
                label: "Redirect URI",
                placeholder: "Enter Redirect URI",
                default: "Enter Redirect URI",
                changeProp: true,
              },
            ],
            "script-props": ["url", "clientid", "clientsecret", "redirecturi"],
          },
        },
      });

      editor.DomComponents.addType("3d-urdf", {
        model: {
          defaults: {
            name: "3d-urdf",
            icon: '<svg viewBox="0 0 24 24"><path d="M12 10.2308L3.08495 7.02346M12 10.2308L20.9178 7.03406M12 10.2308V20.8791M5.13498 18.5771L10.935 20.6242C11.3297 20.7635 11.527 20.8331 11.7294 20.8608C11.909 20.8853 12.091 20.8853 12.2706 20.8608C12.473 20.8331 12.6703 20.7635 13.065 20.6242L18.865 18.5771C19.6337 18.3058 20.018 18.1702 20.3018 17.9269C20.5523 17.7121 20.7459 17.4386 20.8651 17.1308C21 16.7823 21 16.3747 21 15.5595V8.44058C21 7.62542 21 7.21785 20.8651 6.86935C20.7459 6.56155 20.5523 6.28804 20.3018 6.0732C20.018 5.82996 19.6337 5.69431 18.865 5.42301L13.065 3.37595C12.6703 3.23665 12.473 3.167 12.2706 3.13936C12.091 3.11484 11.909 3.11484 11.7294 3.13936C11.527 3.167 11.3297 3.23665 10.935 3.37595L5.13498 5.42301C4.36629 5.69431 3.98195 5.82996 3.69824 6.0732C3.44766 6.28804 3.25414 6.56155 3.13495 6.86935C3 7.21785 3 7.62542 3 8.44058V15.5595C3 16.3747 3 16.7823 3.13495 17.1308C3.25414 17.4386 3.44766 17.7121 3.69824 17.9269C3.98195 18.1702 4.36629 18.3058 5.13498 18.5771Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>',
            droppable: false,
            resizable: false,
            script: launch3dAnimation,
            content: `<div style = "padding:10px 0;" data-gjs-type="calendly-admin">
        <div class= "gpd-calendly-admin-btn gpd-calendly-admin-btn-st" data-gjs-type="calendly-admin-btn">Open 3D Simulation</div>
      </div>`,
            attributes: {
              style: "font-family: Helvetica",
            },
            //url: `http://localhost:9080/js/3D/urdf/`,
            url: `${window.location.origin.split(":3000")[0]}:9080/js/3D/urdf/`,
            "script-props": ["url"],
          },
        },
      });

      editor.DomComponents.addType("calendy", {
        model: {
          defaults: {
            name: "calendly",
            icon: '<svg viewBox="0 0 24 24"><path d="M14 14H7v2h7m5 3H5V8h14m0-5h-1V1h-2v2H8V1H6v2H5a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2m-2 7H7v2h10v-2z"></path></svg>',
            droppable: false,
            resizable: false,
            script: calendyScript,
            content: `<div style = "padding:10px 0;" data-gjs-type="calendly">
        <div class= "gpd-calendly-btn gpd-calendly-btn-st" data-gjs-type="calendly-btn">Schedule Now!</div>
      </div>`,
            attributes: {
              style: "font-family: Helvetica",
            },
            url: "https://calendly.com/redslingtest",
            widgettype: "popup",
            badgetext: "Schedule time with me",
            bgcolor: "#ffffff",
            textcolor: "#4d5055",
            buttonlink: "#00a2ff",
            branding: true,
            hidedetail: false,
            traits: [
              {
                name: "url",
                label: "Calendly URL",
                placeholder: "",
                default: "",
                changeProp: true,
              },
              {
                name: "widgettype",
                label: "Type",
                type: "select",
                options: [
                  { value: "inline" },
                  { value: "popup" },
                  { value: "popup-text" },
                ],
                default: "popup",
                changeProp: true,
              },
              {
                name: "badgetext",
                label: "Badge Text",
                placeholder: "Schedule time with me",
                default: "Schedule time with me",
                changeProp: true,
              },
              {
                name: "branding",
                label: "Badge Branding",
                type: "checkbox",
                changeProp: false,
              },
              {
                name: "bgcolor",
                label: "Color background",
                type: "color",
                default: "#ffffff",
                changeProp: true,
              },
              {
                name: "textcolor",
                label: "Color text",
                type: "color",
                default: "#4d5055",
                changeProp: true,
              },
              {
                name: "buttonlink",
                label: "Button & Links",
                type: "color",
                default: "#00a2ff",
                changeProp: true,
              },
              {
                name: "hidedetail",
                label: "Hide Details",
                type: "checkbox",
                changeProp: true,
              },
            ],
            "script-props": [
              "url",
              "widgettype",
              "badgetext",
              "bgcolor",
              "textcolor",
              "buttonlink",
              "hidedetail",
              "branding",
            ],
          },
        },
      });

      editor.DomComponents.addType("calendly-btn", {
        model: {
          defaults: {
            name: "calendly",
            icons: '<i className="fa fa-link"></i>',
            droppable: false,
            highlightable: false,
            resizable: false,
            removable: false,
            copyable: false,
          },
        },
        extend: "link",
        isComponent(el) {
          if (el.tagName === "calendly-btn") return { type: "calendly-btn" };
        },
      });

      editor.DomComponents.addType("live-alert", {
        model: {
          defaults: {
            tagName: "div",
            attributes: {
              class: "alert alert-primary alert-dismissible fade show",
              role: "alert",
            },

            components: [
              {
                tagName: "p",
                type: "text",
                editable: true,

                components: {
                  type: "textnode",
                  content:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris facilisis egestas neque id viverra. Morbi et hendrerit eros.",
                },
              },
              {
                tagName: "button",
                attributes: {
                  type: "button",
                  class: "btn-close",
                  "data-bs-dismiss": "alert",
                  "aria-label": "Close",
                },
              },
            ],
          },
        },
      });

      // HELPER function, to check whether a variable is an array of objects or not
      function isArrayOfObjects(variable) {
        if (Array.isArray(variable)) {
          for (let i = 0; i < variable.length; i++) {
            if (
              typeof variable[i] !== "object" ||
              variable[i] === null ||
              Array.isArray(variable[i])
            ) {
              return false;
            }
          }

          return true;
        }

        return false;
      }

      // checkbox v1
      const individualCheckboxScript = function (props) {
        console.log("this element:", this, props);

        let container = this.getElementsByTagName("input")[0];

        if (props.Autofocus) {
          container.setAttribute("autofocus", true);
        }

        if (props.Disabled) {
          container.setAttribute("disabled", true);
        }

        if (props.Required) {
          container.setAttribute("required", true);
        }

        if (props.Checked) {
          container.setAttribute("checked", true);
        }

        if (props.Value) {
          container.setAttribute("value", props.Value);
        }

        container.addEventListener("change", function () {
          console.log(
            "wrapper:::::11111",
            this.parentNode.parentNode.parentNode
          );
          let wrapper = this.parentNode.parentNode.parentNode;
          if (wrapper) {
            // Find all input elements under the div
            const inputElements = wrapper.querySelectorAll("input");

            // Filter out the checked input elements and retrieve their value attributes
            const checkedValues = Array.from(inputElements)
              .filter((input) => input.checked)
              .map((input) => input.value);

            const dbIds = Array.from(inputElements)
              .filter((input) => input.checked)
              .map((input) => input.getAttribute("data_db_id"));

            console.log("checkedValues:::::::::", checkedValues);
            console.log("dbIds", dbIds);

            wrapper.setAttribute("value", checkedValues.join(","));
            wrapper.setAttribute("data_db_id", dbIds.join(","));
          } else {
            console.log('Div element with id "iy1ck" not found.');
          }
        });
      };

      editor.DomComponents.addType("checkbox_input", {
        model: {
          defaults: {
            tagName: "span",
            type: "checkbox_input",
            script: individualCheckboxScript,

            attributes: {
              class: "checkbox_input",
            },

            style: {
              "margin-right": "5%",
            },

            "style-default": {
              "margin-right": "5%",
            },

            components: [
              {
                tagName: "input",
                type: "default",
                attributes: {
                  type: "checkbox",
                  id: `checkbox_1_${uniqid()}`,
                  name: "checkbox_1",
                  value: "checkbox_1",
                },
              },
            ],

            Autofocus: false,
            Disabled: false,
            Required: false,
            Checked: false,

            traits: [
              "id",
              "name",
              "value",
              {
                type: "checkbox",
                name: "Autofocus",
                label: "Autofocus",
                changeProp: true,
              },
              {
                type: "checkbox",
                name: "Disabled",
                label: "Disabled",
                changeProp: true,
              },
              {
                type: "checkbox",
                name: "Required",
                label: "Required",
                changeProp: true,
              },
              {
                type: "checkbox",
                name: "Checked",
                label: "Checked",
                changeProp: true,
              },
              {
                type: "checkbox",
                name: "Checked",
                label: "Checked",
                changeProp: true,
              },
              {
                type: "checkbox",
                name: "Checked",
                label: "Checked",
                changeProp: true,
              },
              {
                type: "checkbox",
                name: "Checked",
                label: "Checked",
                changeProp: true,
              },
            ],

            changeProp: true,
            "script-props": ["Autofocus", "Disabled", "Required", "Checked"],
          },
        },
      });

      editor.DomComponents.addType("checkbox_label", {
        model: {
          defaults: {
            tagName: "label",
            type: "checkbox_label",
            attributes: {
              for: "",
            },

            components: [
              {
                tagName: "p",
                type: "text",
                editable: true,

                style: {
                  width: "max-content",
                  "word-break": "break-all",
                },

                "style-default": {
                  width: "max-content",
                  "word-break": "break-all",
                },

                components: {
                  type: "textnode",
                  content: "checkbox_1",
                },
              },
            ],

            traits: ["id", "for"],
            changeProp: true,
          },
        },
      });

      editor.DomComponents.addType("checkbox_regular_form", {
        model: {
          defaults: {
            tagName: "span",
            type: "checkbox_regular_form",
            // script: async function(props) {
            //   console.log('main element:', this, props);

            //   // helper function to check if a variable is an array-of-objects or not
            //   function isArrayofObjects(variable) {
            //     // Check if the variable is an array
            //     if (!Array.isArray(variable)) {
            //       return false;
            //     }

            //     // Check if all elements in the array are objects
            //     for (const element of variable) {
            //       if (typeof element !== 'object' || Array.isArray(element) || element === null) {
            //         return false;
            //       }
            //     }
            //     return true;
            //   }

            //   // helper function to get value of a key in nested object
            //   function findKeyValue(obj, key) {
            //     if (obj.hasOwnProperty(key)) {
            //       // If the current object has the key, return its value
            //       return obj[key];
            //     }

            //     for (var prop in obj) {
            //       if (obj.hasOwnProperty(prop) && typeof obj[prop] === 'object') {
            //         // If the current property is an object, recursively search within it
            //         var result = findKeyValue(obj[prop], key);
            //         if (result !== undefined) {
            //             return result;
            //         }
            //       }
            //     }
            //     // Key not found in the object
            //     return undefined;
            //   }

            //   const container = this;
            //   let apiData;

            //   // -------- Getting API Data
            //   // to use API to populate the dropdown list
            //   if (props.API) {
            //     // if nothing is provided for auth
            //     if (!props.BearerToken && !props.Username && !props.Password) {
            //       // add extra headers, if needed
            //       let config = {
            //         headers: {},
            //       };

            //       if (props.MoreHeaders) {
            //         // ['a:b', "c:d"]
            //         // extracting headers from More_Headers trait
            //         props.MoreHeaders.split(",").forEach((header) => {
            //           let key, val;
            //           [key, val] = header.split(":");
            //           console.log("[key, val]:\n", key, val);

            //           config.headers[key] = val;
            //         });
            //       }

            //       console.log("config:", config);

            //       // GET call to get the data
            //       let response = await fetch(`${props.API}`, config);
            //       let responseData = await response.json();
            //       apiData = responseData;
            //     }

            //     // if a bearer token is provided but not api_key or usr+pwd
            //     else if (props.BearerToken && !props.Username && !props.Password) {
            //       // add extra headers, if needed
            //       let config = {
            //         headers: {
            //           'Content-Type': 'application/json',
            //           Authorization: `Bearer ${props.BearerToken}`,
            //         },
            //       };

            //       if (props.MoreHeaders) {
            //         // ['a:b', "c:d"]
            //         // extracting headers from More_Headers trait
            //         props.MoreHeaders.split(",").forEach((header) => {
            //           let key, val;
            //           [key, val] = header.split(":");
            //           console.log("[key, val]:\n", key, val);

            //           config.headers[key] = val;
            //         });
            //       }

            //       console.log("config:", config);

            //       // GET call to get the data
            //       let response = await fetch(`${props.API}`, config);
            //       let responseData = await response.json();
            //       apiData = responseData;
            //     }

            //     // if a bearer token and api_key not provided but usr+pwd are provided
            //     else if (!props.BearerToken && props.Username && props.Password) {
            //       // add extra headers, if needed
            //       let config = {
            //         headers: {
            //           Authorization: "Basic " + btoa(props.Username + ":" + props.Password),
            //         },
            //       };

            //       if (props.MoreHeaders) {
            //         // ['a:b', "c:d"]
            //         // extracting headers from More_Headers trait
            //         props.MoreHeaders.split(",").forEach((header) => {
            //           let key, val;
            //           [key, val] = header.split(":");
            //           console.log("[key, val]:\n", key, val);

            //           config.headers[key] = val;
            //         });
            //       }

            //       console.log("config:", config);

            //       // GET call to get the data
            //       let response = await fetch(`${props.API}`, config);
            //       let responseData = await response.json();
            //       apiData = responseData;
            //     }
            //   }

            //   console.log('apiData:', apiData);

            //   // -------- Populating API Data, into the dropdown list
            //   if (apiData !== undefined) {
            //     console.log('#');
            //     // if API response is directly an array-of-objects
            //     if (isArrayofObjects(apiData)) {
            //       if (props.ArrOfObjKyename) {
            //         console.log('##');
            //         container.innerHTML = "";

            //         apiData.forEach((opt) => {
            //           let inputEle = document.createElement('input');
            //           inputEle.setAttribute('type', 'checkbox');
            //           inputEle.setAttribute('id', `${opt[`${props.ArrOfObjKyename}`]}`);
            //           inputEle.setAttribute('name', `${opt[`${props.ArrOfObjKyename}`]}`);
            //           inputEle.setAttribute('value', `${opt[`${props.ArrOfObjKyename}`]}`);

            //           let labelEle = document.createElement('label');
            //           labelEle.setAttribute('for', `${opt[`${props.ArrOfObjKyename}`]}`);
            //           labelEle.innerHTML = opt[`${props.ArrOfObjKyename}`];

            //           container.appendChild(inputEle);
            //           container.appendChild(labelEle);
            //         });
            //       }
            //     }

            //     // if API response is a plain array of single elements
            //     else if (Array.isArray(apiData) && !isArrayofObjects(apiData)) {
            //       console.log('###');
            //       container.innerHTML = "";
            //       apiData.forEach((opt) => {
            //         let inputEle = document.createElement('input');
            //           inputEle.setAttribute('type', 'checkbox');
            //           inputEle.setAttribute('id', `ch-${opt}`);
            //           inputEle.setAttribute('name', `ch-${opt}`);
            //           inputEle.setAttribute('value', `${opt}`);

            //           let labelEle = document.createElement('label');
            //           labelEle.setAttribute('for', `ch-${opt}`);
            //           labelEle.innerHTML = `${opt}`;

            //           container.appendChild(inputEle);
            //           container.appendChild(labelEle);
            //       });
            //     }

            //     // if API response is an object
            //     else if (!isArrayofObjects(apiData) && typeof apiData === "object") {
            //       // if API response to plot is a nested array-of-objects inside the main response object
            //       if (props.NestedKeyname && props.ArrOfObjKyename) {
            //         console.log('####');
            //         container.innerHTML = "";

            //         // find the array-of-objects value
            //         let data = findKeyValue(apiData, props.NestedKeyname);

            //         if (isArrayofObjects(data)) {
            //           data.forEach((opt) => {
            //             let inputEle = document.createElement('input');
            //             inputEle.setAttribute('type', 'checkbox');
            //             inputEle.setAttribute('id', `${opt[`${props.ArrOfObjKyename}`]}`);
            //             inputEle.setAttribute('name', `${opt[`${props.ArrOfObjKyename}`]}`);
            //             inputEle.setAttribute('value', `${opt[`${props.ArrOfObjKyename}`]}`);

            //             let labelEle = document.createElement('label');
            //             labelEle.setAttribute('for', `${opt[`${props.ArrOfObjKyename}`]}`);
            //             labelEle.innerHTML = opt[`${props.ArrOfObjKyename}`];

            //             container.appendChild(inputEle);
            //             container.appendChild(labelEle);
            //           });
            //         }
            //       }

            //       else if (props.NestedKeyname && !props.ArrOfObjKyename) {
            //         container.innerHTML = "";

            //         // find the array value
            //         let data = findKeyValue(apiData, props.NestedKeyname);

            //         // if API response to plot is a nested plain array of single elements inside the main response object
            //         if (Array.isArray(data) && !isArrayofObjects(data)) {
            //           console.log('#####');
            //           data.forEach((opt) => {
            //             let inputEle = document.createElement('input');
            //             inputEle.setAttribute('type', 'checkbox');
            //             inputEle.setAttribute('id', `ch-${opt}`);
            //             inputEle.setAttribute('name', `ch-${opt}`);
            //             inputEle.setAttribute('value', `${opt}`);

            //             let labelEle = document.createElement('label');
            //             labelEle.setAttribute('for', `ch-${opt}`);
            //             labelEle.innerHTML = `${opt}`;

            //             container.appendChild(inputEle);
            //             container.appendChild(labelEle);
            //           });
            //         }

            //         // if API response to plot are all key:value pairs of a plain object inside main object
            //         else if (!Array.isArray(data) && !isArrayofObjects(data) && typeof data === "object") {
            //           console.log('######');
            //           Object.keys(data).forEach((key) => {
            //             if (!Array.isArray(data[`${key}`]) && typeof data[`${key}`] !== 'object') {

            //               let inputEle = document.createElement('input');
            //               inputEle.setAttribute('type', 'checkbox');
            //               inputEle.setAttribute('id', `${data[`${key}`]}`);
            //               inputEle.setAttribute('name', `${data[`${key}`]}`);
            //               inputEle.setAttribute('value', `${data[`${key}`]}`);

            //               let labelEle = document.createElement('label');
            //               labelEle.setAttribute('for', `${data[`${key}`]}`);
            //               labelEle.innerHTML = `${key}`;

            //               container.appendChild(inputEle);
            //               container.appendChild(labelEle);
            //             }
            //           });
            //         }
            //       }

            //       // if API response to plot are all key:value pairs of the plain object
            //       else if (!props.NestedKeyname && !props.ArrOfObjKyename) {
            //         console.log('#######');
            //         container.innerHTML = "";

            //         Object.keys(apiData).forEach((key) => {
            //           if (!Array.isArray(apiData[`${key}`]) && typeof apiData[`${key}`] !== 'object') {

            //             let inputEle = document.createElement('input');
            //             inputEle.setAttribute('type', 'checkbox');
            //             inputEle.setAttribute('id', `${apiData[`${key}`]}`);
            //             inputEle.setAttribute('name', `${apiData[`${key}`]}`);
            //             inputEle.setAttribute('value', `${apiData[`${key}`]}`);

            //             let labelEle = document.createElement('label');
            //             labelEle.setAttribute('for', `${apiData[`${key}`]}`);
            //             labelEle.innerHTML = `${key}`;

            //             container.appendChild(inputEle);
            //             container.appendChild(labelEle);
            //           }
            //         });
            //       }
            //     }
            //   }
            // },

            attributes: {
              // id: "checkbox_regular_form",
              class: "checkbox_regular_form",
            },

            style: {
              display: "flex",
              "flex-wrap": "wrap",
              "align-items": "center",
              "justify-content": "flex-start",
              gap: "1%",
            },

            "style-default": {
              display: "flex",
              "flex-wrap": "wrap",
              "align-items": "center",
              "justify-content": "flex-start",
              gap: "1%",
            },

            components: [
              {
                tagName: "span",
                type: "default",
                attributes: {
                  class: "group_checkbox_label",
                },

                "style-default": {
                  display: "flex",
                  "flex-wrap": "no-wrap",
                  "justify-content": "flex-start",
                  "align-items": "flex-start",
                  width: "8%",
                },

                style: {
                  display: "flex",
                  "flex-wrap": "no-wrap",
                  "justify-content": "flex-start",
                  "align-items": "flex-start",
                  width: "8%",
                },

                components: [
                  {
                    type: "checkbox_input",
                  },
                  {
                    type: "checkbox_label",
                  },
                ],
              },
              {
                tagName: "span",
                type: "default",
                attributes: {
                  class: "group_checkbox_label",
                },

                "style-default": {
                  display: "flex",
                  "flex-wrap": "no-wrap",
                  "justify-content": "flex-start",
                  "align-items": "flex-start",
                  width: "8%",
                },

                style: {
                  display: "flex",
                  "flex-wrap": "no-wrap",
                  "justify-content": "flex-start",
                  "align-items": "flex-start",
                  width: "8%",
                },

                components: [
                  {
                    type: "checkbox_input",
                  },
                  {
                    type: "checkbox_label",
                  },
                ],
              },
            ],

            // traits
            CustomUrl: "",
            BaseUrl: "",
            APIPath: "",
            BearerToken: "",
            Username: "",
            Password: "",
            MoreHeaders: "",
            APIMethod: "GET",
            APIBody: "",
            ArrOfObjKyename: "",
            NestedKeyname: "",

            // to give width to all the divs at once
            CommonWidth: "8",

            traits: [
              "id",
              {
                type: "text",
                name: "CustomUrl",
                label: "Custom URL",
                changeProp: true,
              },
              {
                type: "select",
                name: "BaseUrl",
                label: "Base URL",
                options: [
                  { id: "baseUrl1", name: "Base URL #1" },
                  { id: "baseUrl2", name: "Base URL #2" },
                  { id: "baseUrl3", name: "Base URL #3" },
                  { id: "baseUrl4", name: "Base URL #4" },
                  { id: "baseUrl5", name: "Base URL #5" },
                  { id: "null", name: "No Base URL" },
                ],
                changeProp: true,
              },
              {
                type: "text",
                name: "APIPath",
                label: "API Path",
                placeholder: "Don't start with '/'",
                changeProp: true,
              },
              {
                type: "text",
                name: "BearerToken",
                label: "Bearer Token",
                placeholder: "No bearer token provided",
                changeProp: true,
              },
              {
                type: "text",
                name: "Username",
                label: "Username",
                changeProp: true,
              },
              {
                type: "text",
                name: "Password",
                label: "Password",
                changeProp: true,
              },
              {
                type: "text",
                name: "MoreHeaders",
                label: "More Headers",
                placeholder: "k1:v1,k2:v2,k3:v3,...",
                changeProp: true,
              },
              {
                type: "select",
                name: "APIMethod",
                label: "API Method",
                options: [
                  { id: "GET", name: "Get" },
                  { id: "POST", name: "Post" },
                ],
                // value: "GET",
                changeProp: true,
              },
              {
                type: "text",
                name: "APIBody",
                label: "API Body",
                placeholder: "Json format",
                changeProp: true,
              },
              {
                type: "text",
                name: "ArrOfObjKyename",
                label: "Keyname From Array Of Object",
                placeholder: "[ {k1:v1}, {k1:v2} ]",
                changeProp: true,
              },
              {
                type: "text",
                name: "NestedKeyname",
                label: "Keyname For Nested Structure",
                placeholder: "{ k1: [{...}, {...}] }",
                changeProp: true,
              },
              {
                type: "number",
                name: "CommonWidth",
                label: "Common Width",
                placeholder: "Unit %",
                changeProp: true,
              },
            ],

            changeProp: true,
            "script-props": [
              "CustomUrl",
              "BaseUrl",
              "APIPath",
              "BearerToken",
              "Username",
              "Password",
              "MoreHeaders",
              "APIMethod",
              "APIBody",
              "ArrOfObjKyename",
              "NestedKeyname",
              "CommonWidth",
            ],
          },

          init() {
            this.listenTo(this, "change:CustomUrl", this.handlePropChange);
            this.listenTo(this, "change:BaseUrl", this.handlePropChange);
            this.listenTo(this, "change:APIPath", this.handlePropChange);
            this.listenTo(this, "change:BearerToken", this.handlePropChange);
            this.listenTo(this, "change:Username", this.handlePropChange);
            this.listenTo(this, "change:Password", this.handlePropChange);
            this.listenTo(this, "change:MoreHeaders", this.handlePropChange);
            this.listenTo(this, "change:APIMethod", this.handlePropChange);
            this.listenTo(this, "change:APIBody", this.handlePropChange);
            this.listenTo(
              this,
              "change:ArrOfObjKyename",
              this.handlePropChange
            );
            this.listenTo(this, "change:NestedKeyname", this.handlePropChange);
            this.listenTo(this, "change:CommonWidth", this.handlePropChange);
          },

          async handlePropChange() {
            const {
              CustomUrl,
              BaseUrl,
              APIPath,
              BearerToken,
              Username,
              Password,
              MoreHeaders,
              APIMethod,
              APIBody,
              ArrOfObjKyename,
              NestedKeyname,
              CommonWidth,
            } = this.props();

            // to rename a variable because API var is getting used in many places in this block scope
            let API;
            if (APIPath) {
              API = APIPath;
            }

            let container = this;
            console.log("container:", container);

            // helper function to check if a variable is an array-of-objects or not
            function isArrayofObjects(variable) {
              // Check if the variable is an array
              if (!Array.isArray(variable)) {
                return false;
              }

              // Check if all elements in the array are objects
              for (const element of variable) {
                if (
                  typeof element !== "object" ||
                  Array.isArray(element) ||
                  element === null
                ) {
                  return false;
                }
              }
              return true;
            }

            // helper function to get value of a key in nested object
            function findKeyValue(obj, key) {
              if (obj.hasOwnProperty(key)) {
                // If the current object has the key, return its value
                return obj[key];
              }

              for (var prop in obj) {
                if (obj.hasOwnProperty(prop) && typeof obj[prop] === "object") {
                  // If the current property is an object, recursively search within it
                  var result = findKeyValue(obj[prop], key);
                  if (result !== undefined) {
                    return result;
                  }
                }
              }
              // Key not found in the object
              return undefined;
            }

            let apiData;

            // -------- Getting API Data
            // if CustomUrl is provided, use only that
            if (CustomUrl && (!BaseUrl || BaseUrl === "null")) {
              // if nothing is provided for auth
              if (!BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  headers: {},
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is provided but not api_key or usr+pwd
              else if (BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${BearerToken}`,
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token and api_key not provided but usr+pwd are provided
              else if (!BearerToken && Username && Password) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    Authorization: "Basic " + btoa(Username + ":" + Password),
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }
            }

            // if base url is the choice and provided
            else if (BaseUrl && API && !CustomUrl) {
              // concatenating base url and its remaining part
              let url, baseUrlBearerToken;
              if (BaseUrl === "baseUrl1") {
                url = baseUrl1 + API;
                baseUrlBearerToken = baseUrl1BearerToken;
              } else if (BaseUrl === "baseUrl2") {
                url = baseUrl2 + API;
                baseUrlBearerToken = baseUrl2BearerToken;
              } else if (BaseUrl === "baseUrl3") {
                url = baseUrl3 + API;
                baseUrlBearerToken = baseUrl3BearerToken;
              } else if (BaseUrl === "baseUrl4") {
                url = baseUrl4 + API;
                baseUrlBearerToken = baseUrl4BearerToken;
              } else if (BaseUrl === "baseUrl5") {
                url = baseUrl5 + API;
                baseUrlBearerToken = baseUrl5BearerToken;
              }
              console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

              // if nothing is provided for auth
              if (
                !BearerToken &&
                !baseUrlBearerToken &&
                !Username &&
                !Password
              ) {
                // add extra headers, if needed
                let config = {
                  headers: {},
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is provided but not usr+pwd
              else if (BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${BearerToken}`,
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a baseUrlBearerToken is provided but not Custom Bearer Token and usr+pwd
              else if (
                baseUrlBearerToken &&
                !BearerToken &&
                !Username &&
                !Password
              ) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${baseUrlBearerToken}`,
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config@:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is not provided but usr+pwd are provided
              else if (!BearerToken && Username && Password) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    Authorization: "Basic " + btoa(Username + ":" + Password),
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }
            }

            // POST method
            // if CustomUrl is provided, use only that
            if (
              CustomUrl &&
              (!BaseUrl || BaseUrl === "null") &&
              APIMethod === "POST"
            ) {
              // if nothing is provided for auth
              if (!BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {},
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is provided but not api_key or usr+pwd
              else if (BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${BearerToken}`,
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token and api_key not provided but usr+pwd are provided
              else if (!BearerToken && Username && Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    Authorization: "Basic " + btoa(Username + ":" + Password),
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }
            }

            // if base url is the choice and provided
            if (BaseUrl && API && !CustomUrl && APIMethod === "POST") {
              // concatenating base url and its remaining part
              let url, baseUrlBearerToken;
              if (BaseUrl === "baseUrl1") {
                url = baseUrl1 + API;
                baseUrlBearerToken = baseUrl1BearerToken;
              } else if (BaseUrl === "baseUrl2") {
                url = baseUrl2 + API;
                baseUrlBearerToken = baseUrl2BearerToken;
              } else if (BaseUrl === "baseUrl3") {
                url = baseUrl3 + API;
                baseUrlBearerToken = baseUrl3BearerToken;
              } else if (BaseUrl === "baseUrl4") {
                url = baseUrl4 + API;
                baseUrlBearerToken = baseUrl4BearerToken;
              } else if (BaseUrl === "baseUrl5") {
                url = baseUrl5 + API;
                baseUrlBearerToken = baseUrl5BearerToken;
              }
              console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

              // if nothing is provided for auth
              if (
                !BearerToken &&
                !baseUrlBearerToken &&
                !Username &&
                !Password
              ) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {},
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is provided but not usr+pwd
              else if (BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${BearerToken}`,
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a baseUrlBearerToken is provided but not Custom Bearer Token and usr+pwd
              else if (
                baseUrlBearerToken &&
                !BearerToken &&
                !Username &&
                !Password
              ) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${baseUrlBearerToken}`,
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config@:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is not provided but usr+pwd are provided
              else if (!BearerToken && Username && Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    Authorization: "Basic " + btoa(Username + ":" + Password),
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }
            }

            console.log("apiData:", apiData);

            // -------- Populating API Data, into the dropdown list
            if (apiData !== undefined) {
              console.log("#");
              // if API response is directly an array-of-objects
              if (isArrayofObjects(apiData)) {
                if (ArrOfObjKyename) {
                  console.log("##");
                  // container.getEl().innerHTML = "";
                  container.components("");

                  apiData.forEach((opt) => {
                    let groupEle = {
                      tagName: "span",
                      type: "default",
                      attributes: {
                        class: "group_checkbox_label",
                      },

                      "style-default": {
                        display: "flex",
                        "flex-wrap": "no-wrap",
                        "justify-content": "flex-start",
                        "align-items": "flex-start",
                        width: CommonWidth
                          ? CommonWidth + "%"
                          : "8% !important",
                      },

                      style: {
                        display: "flex",
                        "flex-wrap": "no-wrap",
                        "justify-content": "flex-start",
                        "align-items": "flex-start",
                        width: CommonWidth
                          ? CommonWidth + "%"
                          : "8% !important",
                      },

                      components: [
                        {
                          tagName: "span",
                          type: "checkbox_input",
                          script: individualCheckboxScript,

                          attributes: {
                            class: "checkbox_input",
                          },

                          components: [
                            {
                              tagName: "input",
                              type: "default",
                              attributes: {
                                type: "checkbox",
                                id: `checkbox_1_${uniqid()}`,
                                name: `${opt[`${ArrOfObjKyename}`]}`,
                                value: `${opt[`${ArrOfObjKyename}`]}`,
                                data_db_id: `${opt[`_id`]}`
                                  ? `${opt[`_id`]}`
                                  : `${opt[`${ArrOfObjKyename}`]}`,
                              },
                            },
                          ],

                          Autofocus: false,
                          Disabled: false,
                          Required: false,
                          Checked: false,

                          traits: [
                            "id",
                            "name",
                            "value",
                            {
                              type: "checkbox",
                              name: "Autofocus",
                              label: "Autofocus",
                              changeProp: true,
                            },
                            {
                              type: "checkbox",
                              name: "Disabled",
                              label: "Disabled",
                              changeProp: true,
                            },
                            {
                              type: "checkbox",
                              name: "Required",
                              label: "Required",
                              changeProp: true,
                            },
                            {
                              type: "checkbox",
                              name: "Checked",
                              label: "Checked",
                              changeProp: true,
                            },
                          ],

                          changeProp: true,
                          "script-props": [
                            "Autofocus",
                            "Disabled",
                            "Required",
                            "Checked",
                          ],
                        },
                        {
                          tagName: "label",
                          type: "checkbox_label",
                          attributes: {
                            for: ``,
                          },

                          components: [
                            {
                              tagName: "p",
                              type: "text",
                              editable: true,

                              style: {
                                "word-break": "break-all",
                              },

                              "style-default": {
                                "word-break": "break-all",
                              },

                              components: {
                                type: "textnode",
                                content: `${opt[`${ArrOfObjKyename}`]}`,
                              },
                            },
                          ],

                          traits: ["id", "for"],
                          changeProp: true,
                        },
                      ],
                    };

                    // let inputEle = {
                    //   tagName: "input",
                    //   type: "default", // so that the input component doesn't throw error of 'undefined'

                    //   script: individualCheckboxScript,

                    //   attributes: {
                    //     type: "checkbox",
                    //     // id: `${opt[`${ArrOfObjKyename}`]}`,
                    //     name: `${opt[`${ArrOfObjKyename}`]}`,
                    //     value: `${opt[`${ArrOfObjKyename}`]}`,
                    //   },

                    //   Autofocus: false,
                    //   Disabled: false,
                    //   Required: false,
                    //   Checked: false,

                    //   traits: [
                    //     "id",
                    //     "name",
                    //     "value",
                    //     {
                    //       type: "checkbox",
                    //       name: "Autofocus",
                    //       label: "Autofocus",
                    //       changeProp: true,
                    //     },
                    //     {
                    //       type: "checkbox",
                    //       name: "Disabled",
                    //       label: "Disabled",
                    //       changeProp: true,
                    //     },
                    //     {
                    //       type: "checkbox",
                    //       name: "Required",
                    //       label: "Required",
                    //       changeProp: true,
                    //     },
                    //     {
                    //       type: "checkbox",
                    //       name: "Checked",
                    //       label: "Checked",
                    //       changeProp: true,
                    //     },
                    //   ],

                    //   changeProp: true,
                    //   "script-props": [
                    //     "Autofocus",
                    //     "Disabled",
                    //     "Required",
                    //     "Checked",
                    //   ],
                    // };

                    // let labelEle = {
                    //   tagName: "label",
                    //   attributes: {
                    //     for: ``,
                    //   },

                    //   components: [
                    //     {
                    //       tagName: "p",
                    //       type: "text",
                    //       editable: true,

                    //       components: {
                    //         type: "textnode",
                    //         content: `${opt[`${ArrOfObjKyename}`]}`,
                    //       },
                    //     },
                    //   ],

                    //   traits: ["id", "for"],
                    // };

                    // container.append(inputEle);
                    // container.append(labelEle);
                    container.append(groupEle);
                  });
                }
              }

              // if API response is a plain array of single elements
              else if (Array.isArray(apiData) && !isArrayofObjects(apiData)) {
                console.log("###");
                container.components("");
                apiData.forEach((opt) => {
                  let groupEle = {
                    tagName: "span",
                    type: "default",
                    attributes: {
                      class: "group_checkbox_label",
                    },

                    "style-default": {
                      display: "flex",
                      "flex-wrap": "no-wrap",
                      "justify-content": "flex-start",
                      "align-items": "flex-start",
                      width: CommonWidth ? CommonWidth + "%" : "8% !important",
                    },

                    style: {
                      display: "flex",
                      "flex-wrap": "no-wrap",
                      "justify-content": "flex-start",
                      "align-items": "flex-start",
                      width: CommonWidth ? CommonWidth + "%" : "8% !important",
                    },

                    components: [
                      {
                        tagName: "span",
                        type: "checkbox_input",
                        script: individualCheckboxScript,

                        attributes: {
                          class: "checkbox_input",
                        },

                        components: [
                          {
                            tagName: "input",
                            type: "default",
                            attributes: {
                              type: "checkbox",
                              id: `checkbox_1_${uniqid()}`,
                              name: `ch-${opt}`,
                              value: `${opt}`,
                            },
                          },
                        ],

                        Autofocus: false,
                        Disabled: false,
                        Required: false,
                        Checked: false,

                        traits: [
                          "id",
                          "name",
                          "value",
                          {
                            type: "checkbox",
                            name: "Autofocus",
                            label: "Autofocus",
                            changeProp: true,
                          },
                          {
                            type: "checkbox",
                            name: "Disabled",
                            label: "Disabled",
                            changeProp: true,
                          },
                          {
                            type: "checkbox",
                            name: "Required",
                            label: "Required",
                            changeProp: true,
                          },
                          {
                            type: "checkbox",
                            name: "Checked",
                            label: "Checked",
                            changeProp: true,
                          },
                        ],

                        changeProp: true,
                        "script-props": [
                          "Autofocus",
                          "Disabled",
                          "Required",
                          "Checked",
                        ],
                      },
                      {
                        tagName: "label",
                        attributes: {
                          for: ``,
                        },

                        components: [
                          {
                            tagName: "p",
                            type: "text",
                            editable: true,

                            style: {
                              "word-break": "break-all",
                            },

                            "style-default": {
                              "word-break": "break-all",
                            },

                            components: {
                              type: "textnode",
                              content: `${opt}`,
                            },
                          },
                        ],

                        traits: ["id", "for"],
                      },
                    ],
                  };

                  // let inputEle = {
                  //   tagName: "input",
                  //   type: "default", // so that the input component doesn't throw error of 'undefined'

                  //   script: individualCheckboxScript,

                  //   attributes: {
                  //     type: "checkbox",
                  //     // id: `ch-${opt}`,
                  //     name: `ch-${opt}`,
                  //     value: `${opt}`,
                  //   },

                  //   Autofocus: false,
                  //   Disabled: false,
                  //   Required: false,
                  //   Checked: false,

                  //   traits: [
                  //     "id",
                  //     "name",
                  //     "value",
                  //     {
                  //       type: "checkbox",
                  //       name: "Autofocus",
                  //       label: "Autofocus",
                  //       changeProp: true,
                  //     },
                  //     {
                  //       type: "checkbox",
                  //       name: "Disabled",
                  //       label: "Disabled",
                  //       changeProp: true,
                  //     },
                  //     {
                  //       type: "checkbox",
                  //       name: "Required",
                  //       label: "Required",
                  //       changeProp: true,
                  //     },
                  //     {
                  //       type: "checkbox",
                  //       name: "Checked",
                  //       label: "Checked",
                  //       changeProp: true,
                  //     },
                  //   ],

                  //   changeProp: true,
                  //   "script-props": [
                  //     "Autofocus",
                  //     "Disabled",
                  //     "Required",
                  //     "Checked",
                  //   ],
                  // };

                  // let labelEle = {
                  //   tagName: "label",
                  //   attributes: {
                  //     for: ``,
                  //   },

                  //   components: [
                  //     {
                  //       tagName: "p",
                  //       type: "text",
                  //       editable: true,

                  //       components: {
                  //         type: "textnode",
                  //         content: `${opt}`,
                  //       },
                  //     },
                  //   ],

                  //   traits: ["id", "for"],
                  // };

                  // container.append(inputEle);
                  // container.append(labelEle);
                  container.append(groupEle);
                });
              }

              // if API response is an object
              else if (
                !isArrayofObjects(apiData) &&
                typeof apiData === "object"
              ) {
                // if API response to plot is a nested array-of-objects inside the main response object
                if (NestedKeyname && ArrOfObjKyename) {
                  console.log("####");
                  container.components("");

                  // find the array-of-objects value
                  let data = findKeyValue(apiData, NestedKeyname);

                  if (isArrayofObjects(data)) {
                    data.forEach((opt) => {
                      let groupEle = {
                        tagName: "span",
                        type: "default",
                        attributes: {
                          class: "group_checkbox_label",
                        },

                        "style-default": {
                          display: "flex",
                          "flex-wrap": "no-wrap",
                          "justify-content": "flex-start",
                          "align-items": "flex-start",
                          width: CommonWidth
                            ? CommonWidth + "%"
                            : "8% !important",
                        },

                        style: {
                          display: "flex",
                          "flex-wrap": "no-wrap",
                          "justify-content": "flex-start",
                          "align-items": "flex-start",
                          width: CommonWidth
                            ? CommonWidth + "%"
                            : "8% !important",
                        },

                        components: [
                          {
                            tagName: "span",
                            type: "checkbox_input",
                            script: individualCheckboxScript,

                            attributes: {
                              class: "checkbox_input",
                            },

                            components: [
                              {
                                tagName: "input",
                                type: "default",
                                attributes: {
                                  type: "checkbox",
                                  id: `checkbox_1_${uniqid()}`,
                                  name: `${opt[`${ArrOfObjKyename}`]}`,
                                  value: `${opt[`${ArrOfObjKyename}`]}`,
                                },
                              },
                            ],

                            Autofocus: false,
                            Disabled: false,
                            Required: false,
                            Checked: false,

                            traits: [
                              "id",
                              "name",
                              "value",
                              {
                                type: "checkbox",
                                name: "Autofocus",
                                label: "Autofocus",
                                changeProp: true,
                              },
                              {
                                type: "checkbox",
                                name: "Disabled",
                                label: "Disabled",
                                changeProp: true,
                              },
                              {
                                type: "checkbox",
                                name: "Required",
                                label: "Required",
                                changeProp: true,
                              },
                              {
                                type: "checkbox",
                                name: "Checked",
                                label: "Checked",
                                changeProp: true,
                              },
                            ],

                            changeProp: true,
                            "script-props": [
                              "Autofocus",
                              "Disabled",
                              "Required",
                              "Checked",
                            ],
                          },
                          {
                            tagName: "label",
                            attributes: {
                              for: ``,
                            },

                            components: [
                              {
                                tagName: "p",
                                type: "text",
                                editable: true,

                                style: {
                                  "word-break": "break-all",
                                },

                                "style-default": {
                                  "word-break": "break-all",
                                },

                                components: {
                                  type: "textnode",
                                  content: `${opt[`${ArrOfObjKyename}`]}`,
                                },
                              },
                            ],

                            traits: ["id", "for"],
                          },
                        ],
                      };

                      // let inputEle = {
                      //   tagName: "input",
                      //   type: "default", // so that the input component doesn't throw error of 'undefined'

                      //   script: individualCheckboxScript,

                      //   attributes: {
                      //     type: "checkbox",
                      //     // id: `${opt[`${ArrOfObjKyename}`]}`,
                      //     name: `${opt[`${ArrOfObjKyename}`]}`,
                      //     value: `${opt[`${ArrOfObjKyename}`]}`,
                      //   },

                      //   Autofocus: false,
                      //   Disabled: false,
                      //   Required: false,
                      //   Checked: false,

                      //   traits: [
                      //     "id",
                      //     "name",
                      //     "value",
                      //     {
                      //       type: "checkbox",
                      //       name: "Autofocus",
                      //       label: "Autofocus",
                      //       changeProp: true,
                      //     },
                      //     {
                      //       type: "checkbox",
                      //       name: "Disabled",
                      //       label: "Disabled",
                      //       changeProp: true,
                      //     },
                      //     {
                      //       type: "checkbox",
                      //       name: "Required",
                      //       label: "Required",
                      //       changeProp: true,
                      //     },
                      //     {
                      //       type: "checkbox",
                      //       name: "Checked",
                      //       label: "Checked",
                      //       changeProp: true,
                      //     },
                      //   ],

                      //   changeProp: true,
                      //   "script-props": [
                      //     "Autofocus",
                      //     "Disabled",
                      //     "Required",
                      //     "Checked",
                      //   ],
                      // };

                      // let labelEle = {
                      //   tagName: "label",
                      //   attributes: {
                      //     for: ``,
                      //   },

                      //   components: [
                      //     {
                      //       tagName: "p",
                      //       type: "text",
                      //       editable: true,

                      //       components: {
                      //         type: "textnode",
                      //         content: `${opt[`${ArrOfObjKyename}`]}`,
                      //       },
                      //     },
                      //   ],

                      //   traits: ["id", "for"],
                      // };

                      // container.append(inputEle);
                      // container.append(labelEle);
                      container.append(groupEle);
                    });
                  }
                } else if (NestedKeyname && !ArrOfObjKyename) {
                  container.components("");

                  // find the array value
                  let data = findKeyValue(apiData, NestedKeyname);

                  // if API response to plot is a nested plain array of single elements inside the main response object
                  if (Array.isArray(data) && !isArrayofObjects(data)) {
                    console.log("#####");
                    data.forEach((opt) => {
                      let groupEle = {
                        tagName: "span",
                        type: "default",
                        attributes: {
                          class: "group_checkbox_label",
                        },

                        "style-default": {
                          display: "flex",
                          "flex-wrap": "no-wrap",
                          "justify-content": "flex-start",
                          "align-items": "flex-start",
                          width: CommonWidth
                            ? CommonWidth + "%"
                            : "8% !important",
                        },

                        style: {
                          display: "flex",
                          "flex-wrap": "no-wrap",
                          "justify-content": "flex-start",
                          "align-items": "flex-start",
                          width: CommonWidth
                            ? CommonWidth + "%"
                            : "8% !important",
                        },

                        components: [
                          {
                            tagName: "span",
                            type: "checkbox_input",
                            script: individualCheckboxScript,

                            attributes: {
                              class: "checkbox_input",
                            },

                            components: [
                              {
                                tagName: "input",
                                type: "default",
                                attributes: {
                                  type: "checkbox",
                                  id: `checkbox_1_${uniqid()}`,
                                  name: `ch-${opt}`,
                                  value: `${opt}`,
                                },
                              },
                            ],

                            Autofocus: false,
                            Disabled: false,
                            Required: false,
                            Checked: false,

                            traits: [
                              "id",
                              "name",
                              "value",
                              {
                                type: "checkbox",
                                name: "Autofocus",
                                label: "Autofocus",
                                changeProp: true,
                              },
                              {
                                type: "checkbox",
                                name: "Disabled",
                                label: "Disabled",
                                changeProp: true,
                              },
                              {
                                type: "checkbox",
                                name: "Required",
                                label: "Required",
                                changeProp: true,
                              },
                              {
                                type: "checkbox",
                                name: "Checked",
                                label: "Checked",
                                changeProp: true,
                              },
                            ],

                            changeProp: true,
                            "script-props": [
                              "Autofocus",
                              "Disabled",
                              "Required",
                              "Checked",
                            ],
                          },
                          {
                            tagName: "label",
                            attributes: {
                              for: ``,
                            },

                            components: [
                              {
                                tagName: "p",
                                type: "text",
                                editable: true,

                                style: {
                                  "word-break": "break-all",
                                },

                                "style-default": {
                                  "word-break": "break-all",
                                },

                                components: {
                                  type: "textnode",
                                  content: `${opt}`,
                                },
                              },
                            ],

                            traits: ["id", "for"],
                          },
                        ],
                      };

                      // let inputEle = {
                      //   tagName: "input",
                      //   type: "default", // so that the input component doesn't throw error of 'undefined'

                      //   script: individualCheckboxScript,

                      //   attributes: {
                      //     type: "checkbox",
                      //     // id: `ch-${opt}`,
                      //     name: `ch-${opt}`,
                      //     value: `${opt}`,
                      //   },

                      //   Autofocus: false,
                      //   Disabled: false,
                      //   Required: false,
                      //   Checked: false,

                      //   traits: [
                      //     "id",
                      //     "name",
                      //     "value",
                      //     {
                      //       type: "checkbox",
                      //       name: "Autofocus",
                      //       label: "Autofocus",
                      //       changeProp: true,
                      //     },
                      //     {
                      //       type: "checkbox",
                      //       name: "Disabled",
                      //       label: "Disabled",
                      //       changeProp: true,
                      //     },
                      //     {
                      //       type: "checkbox",
                      //       name: "Required",
                      //       label: "Required",
                      //       changeProp: true,
                      //     },
                      //     {
                      //       type: "checkbox",
                      //       name: "Checked",
                      //       label: "Checked",
                      //       changeProp: true,
                      //     },
                      //   ],

                      //   changeProp: true,
                      //   "script-props": [
                      //     "Autofocus",
                      //     "Disabled",
                      //     "Required",
                      //     "Checked",
                      //   ],
                      // };

                      // let labelEle = {
                      //   tagName: "label",
                      //   attributes: {
                      //     for: ``,
                      //   },

                      //   components: [
                      //     {
                      //       tagName: "p",
                      //       type: "text",
                      //       editable: true,

                      //       components: {
                      //         type: "textnode",
                      //         content: `${opt}`,
                      //       },
                      //     },
                      //   ],

                      //   traits: ["id", "for"],
                      // };

                      // container.append(inputEle);
                      // container.append(labelEle);
                      container.append(groupEle);
                    });
                  }

                  // if API response to plot are all key:value pairs of a plain object inside main object
                  else if (
                    !Array.isArray(data) &&
                    !isArrayofObjects(data) &&
                    typeof data === "object"
                  ) {
                    console.log("######");
                    Object.keys(data).forEach((key) => {
                      if (
                        !Array.isArray(data[`${key}`]) &&
                        typeof data[`${key}`] !== "object"
                      ) {
                        let groupEle = {
                          tagName: "span",
                          type: "default",
                          attributes: {
                            class: "group_checkbox_label",
                          },

                          "style-default": {
                            display: "flex",
                            "flex-wrap": "no-wrap",
                            "justify-content": "flex-start",
                            "align-items": "flex-start",
                            width: CommonWidth
                              ? CommonWidth + "%"
                              : "8% !important",
                          },

                          style: {
                            display: "flex",
                            "flex-wrap": "no-wrap",
                            "justify-content": "flex-start",
                            "align-items": "flex-start",
                            width: CommonWidth
                              ? CommonWidth + "%"
                              : "8% !important",
                          },

                          components: [
                            {
                              tagName: "span",
                              type: "checkbox_input",
                              script: individualCheckboxScript,

                              attributes: {
                                class: "checkbox_input",
                              },

                              components: [
                                {
                                  tagName: "input",
                                  type: "default",
                                  attributes: {
                                    type: "checkbox",
                                    id: `checkbox_1_${uniqid()}`,
                                    name: `${data[`${key}`]}`,
                                    value: `${data[`${key}`]}`,
                                  },
                                },
                              ],

                              Autofocus: false,
                              Disabled: false,
                              Required: false,
                              Checked: false,

                              traits: [
                                "id",
                                "name",
                                "value",
                                {
                                  type: "checkbox",
                                  name: "Autofocus",
                                  label: "Autofocus",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Disabled",
                                  label: "Disabled",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Required",
                                  label: "Required",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Checked",
                                  label: "Checked",
                                  changeProp: true,
                                },
                              ],

                              changeProp: true,
                              "script-props": [
                                "Autofocus",
                                "Disabled",
                                "Required",
                                "Checked",
                              ],
                            },
                            {
                              tagName: "label",
                              attributes: {
                                for: ``,
                              },

                              components: [
                                {
                                  tagName: "p",
                                  type: "text",
                                  editable: true,

                                  style: {
                                    "word-break": "break-all",
                                  },

                                  "style-default": {
                                    "word-break": "break-all",
                                  },

                                  components: {
                                    type: "textnode",
                                    content: `${key}`,
                                  },
                                },
                              ],

                              traits: ["id", "for"],
                            },
                          ],
                        };

                        // let inputEle = {
                        //   tagName: "input",
                        //   type: "default", // so that the input component doesn't throw error of 'undefined'

                        //   script: individualCheckboxScript,

                        //   attributes: {
                        //     type: "checkbox",
                        //     // id: `${data[`${key}`]}`,
                        //     name: `${data[`${key}`]}`,
                        //     value: `${data[`${key}`]}`,
                        //   },

                        //   Autofocus: false,
                        //   Disabled: false,
                        //   Required: false,
                        //   Checked: false,

                        //   traits: [
                        //     "id",
                        //     "name",
                        //     "value",
                        //     {
                        //       type: "checkbox",
                        //       name: "Autofocus",
                        //       label: "Autofocus",
                        //       changeProp: true,
                        //     },
                        //     {
                        //       type: "checkbox",
                        //       name: "Disabled",
                        //       label: "Disabled",
                        //       changeProp: true,
                        //     },
                        //     {
                        //       type: "checkbox",
                        //       name: "Required",
                        //       label: "Required",
                        //       changeProp: true,
                        //     },
                        //     {
                        //       type: "checkbox",
                        //       name: "Checked",
                        //       label: "Checked",
                        //       changeProp: true,
                        //     },
                        //   ],

                        //   changeProp: true,
                        //   "script-props": [
                        //     "Autofocus",
                        //     "Disabled",
                        //     "Required",
                        //     "Checked",
                        //   ],
                        // };

                        // let labelEle = {
                        //   tagName: "label",
                        //   attributes: {
                        //     for: ``,
                        //   },

                        //   components: [
                        //     {
                        //       tagName: "p",
                        //       type: "text",
                        //       editable: true,

                        //       components: {
                        //         type: "textnode",
                        //         content: `${key}`,
                        //       },
                        //     },
                        //   ],

                        //   traits: ["id", "for"],
                        // };

                        // container.append(inputEle);
                        // container.append(labelEle);
                        container.append(groupEle);
                      }
                    });
                  }
                }

                // if API response to plot are all key:value pairs of the plain object
                else if (!NestedKeyname && !ArrOfObjKyename) {
                  console.log("#######");
                  container.components("");

                  Object.keys(apiData).forEach((key) => {
                    if (
                      !Array.isArray(apiData[`${key}`]) &&
                      typeof apiData[`${key}`] !== "object"
                    ) {
                      let groupEle = {
                        tagName: "span",
                        type: "default",
                        attributes: {
                          class: "group_checkbox_label",
                        },

                        "style-default": {
                          display: "flex",
                          "flex-wrap": "no-wrap",
                          "justify-content": "flex-start",
                          "align-items": "flex-start",
                          width: CommonWidth
                            ? CommonWidth + "%"
                            : "8% !important",
                        },

                        style: {
                          display: "flex",
                          "flex-wrap": "no-wrap",
                          "justify-content": "flex-start",
                          "align-items": "flex-start",
                          width: CommonWidth
                            ? CommonWidth + "%"
                            : "8% !important",
                        },

                        components: [
                          {
                            tagName: "span",
                            type: "checkbox_input",
                            script: individualCheckboxScript,

                            attributes: {
                              class: "checkbox_input",
                            },

                            components: [
                              {
                                tagName: "input",
                                type: "default",
                                attributes: {
                                  type: "checkbox",
                                  id: `checkbox_1_${uniqid()}`,
                                  name: `${apiData[`${key}`]}`,
                                  value: `${apiData[`${key}`]}`,
                                },
                              },
                            ],

                            Autofocus: false,
                            Disabled: false,
                            Required: false,
                            Checked: false,

                            traits: [
                              "id",
                              "name",
                              "value",
                              {
                                type: "checkbox",
                                name: "Autofocus",
                                label: "Autofocus",
                                changeProp: true,
                              },
                              {
                                type: "checkbox",
                                name: "Disabled",
                                label: "Disabled",
                                changeProp: true,
                              },
                              {
                                type: "checkbox",
                                name: "Required",
                                label: "Required",
                                changeProp: true,
                              },
                              {
                                type: "checkbox",
                                name: "Checked",
                                label: "Checked",
                                changeProp: true,
                              },
                            ],

                            changeProp: true,
                            "script-props": [
                              "Autofocus",
                              "Disabled",
                              "Required",
                              "Checked",
                            ],
                          },
                          {
                            tagName: "label",
                            attributes: {
                              for: ``,
                            },

                            components: [
                              {
                                tagName: "p",
                                type: "text",
                                editable: true,

                                style: {
                                  "word-break": "break-all",
                                },

                                "style-default": {
                                  "word-break": "break-all",
                                },

                                components: {
                                  type: "textnode",
                                  content: `${key}`,
                                },
                              },
                            ],

                            traits: ["id", "for"],
                          },
                        ],
                      };

                      // let inputEle = {
                      //   tagName: "input",
                      //   type: "default", // so that the input component doesn't throw error of 'undefined'

                      //   script: individualCheckboxScript,

                      //   attributes: {
                      //     type: "checkbox",
                      //     // id: `${apiData[`${key}`]}`,
                      //     name: `${apiData[`${key}`]}`,
                      //     value: `${apiData[`${key}`]}`,
                      //   },

                      //   Autofocus: false,
                      //   Disabled: false,
                      //   Required: false,
                      //   Checked: false,

                      //   traits: [
                      //     "id",
                      //     "name",
                      //     "value",
                      //     {
                      //       type: "checkbox",
                      //       name: "Autofocus",
                      //       label: "Autofocus",
                      //       changeProp: true,
                      //     },
                      //     {
                      //       type: "checkbox",
                      //       name: "Disabled",
                      //       label: "Disabled",
                      //       changeProp: true,
                      //     },
                      //     {
                      //       type: "checkbox",
                      //       name: "Required",
                      //       label: "Required",
                      //       changeProp: true,
                      //     },
                      //     {
                      //       type: "checkbox",
                      //       name: "Checked",
                      //       label: "Checked",
                      //       changeProp: true,
                      //     },
                      //   ],

                      //   changeProp: true,
                      //   "script-props": [
                      //     "Autofocus",
                      //     "Disabled",
                      //     "Required",
                      //     "Checked",
                      //   ],
                      // };

                      // let labelEle = {
                      //   tagName: "label",
                      //   attributes: {
                      //     for: ``,
                      //   },

                      //   components: [
                      //     {
                      //       tagName: "p",
                      //       type: "text",
                      //       editable: true,

                      //       components: {
                      //         type: "textnode",
                      //         content: `${key}`,
                      //       },
                      //     },
                      //   ],

                      //   traits: ["id", "for"],
                      // };

                      // container.append(inputEle);
                      // container.append(labelEle);
                      container.append(groupEle);
                    }
                  });
                }

                // if there is a 'message' in api response instead of data
                else if (Object.keys(apiData).includes('message')) {
                    console.log("#@@");

                    container.components("");
                    container.components().push({
                    tagName: "span",
                    type: "default",
                    attributes: {
                      class: "group_checkbox_label",
                    },

                    "style-default": {
                      display: "flex",
                      "flex-wrap": "no-wrap",
                      "justify-content": "flex-start",
                      "align-items": "flex-start",
                      width: CommonWidth ? CommonWidth + "%" : "8% !important",
                    },

                    style: {
                      display: "flex",
                      "flex-wrap": "no-wrap",
                      "justify-content": "flex-start",
                      "align-items": "flex-start",
                      width: CommonWidth ? CommonWidth + "%" : "8% !important",
                    },

                    components: [
                      {
                        tagName: "span",
                        type: "checkbox_input",

                        attributes: {
                          class: "checkbox_input",
                        },

                          style: {
                            "margin-right": "5%",
                            display: "none",
                          },

                          "style-default": {
                            "margin-right": "5%",
                            display: "none",
                          },

                        components: [
                          {
                            tagName: "input",
                            type: "default",
                            attributes: {
                              type: "checkbox",
                              id: `checkbox_1_${uniqid()}`,
                            },
                          },
                        ],
                      },
                      {
                        tagName: "label",
                        attributes: {
                          for: ``,
                        },

                        components: [
                          {
                            tagName: "p",
                            type: "text",
                            editable: true,

                            style: {
                              "word-break": "break-all",
                            },

                            "style-default": {
                              "word-break": "break-all",
                            },

                            components: {
                              type: "textnode",
                              content: `No Values.`,
                            },
                          },
                        ],

                        traits: ["id", "for"],
                      },
                    ],
                  });
                }
              }
            }
          },
        },

        view: {
          onRender({ el, model }) {
            if (el.getAttribute("data-gjs-type") === "checkbox_regular_form") {
              console.log("rendering", el);
              model.handlePropChange();
            }
          },
        },
      });

      // checkbox v2
      const individualCheckboxScriptNew = function (props) {
        console.log("this element:", this, props);

        let container = this.getElementsByTagName("input")[0];

        if (props.Autofocus) {
          container.setAttribute("autofocus", true);
        }

        if (props.Disabled) {
          container.setAttribute("disabled", true);
        }

        if (props.Required) {
          container.setAttribute("required", true);
        }

        if (props.Checked) {
          container.setAttribute("checked", true);
        }

        if (props.Value) {
          container.setAttribute("value", props.Value);
        }

        // if (props.Name) {
        //   container.setAttribute("name", props.Name);
        // }

        // if (props.Id) {
        //   container.setAttribute("id", props.Id);
        // }

        container.addEventListener("change", function () {
          console.log(
            "wrapper:::::11111",
            this.parentNode.parentNode.parentNode
          );
          let wrapper = this.parentNode.parentNode.parentNode;
          if (wrapper) {
            // Find all input elements under the div
            const inputElements = wrapper.querySelectorAll("input");

            // Filter out the checked input elements and retrieve their value attributes
            const checkedValues = Array.from(inputElements)
              .filter((input) => input.checked)
              .map((input) => input.value);

            const dbIds = Array.from(inputElements)
              .filter((input) => input.checked)
              .map((input) => input.getAttribute("data_db_id"));

            console.log("checkedValues:::::::::", checkedValues);
            console.log("dbIds", dbIds);

            wrapper.setAttribute("value", checkedValues.join("‰"));
            wrapper.setAttribute("data_db_id", dbIds.join("‰"));
          } else {
            console.log('Div element with id "iy1ck" not found.');
          }
        });
      };

      editor.DomComponents.addType("checkbox_input_new", {
        model: {
          defaults: {
            tagName: "span",
            type: "checkbox_input_new",
            script: individualCheckboxScriptNew,

            attributes: {
              class: "checkbox_input_new",
            },

            style: {
              "margin-right": "5%",
            },

            "style-default": {
              "margin-right": "5%",
            },

            components: [
              {
                tagName: "input",
                type: "default",
                attributes: {
                  type: "checkbox",
                  // id: `checkbox_1_${uniqid()}`,
                  name: `checkbox_1_${uniqid()}`,
                  value: "checkbox_1",
                },
              },
            ],

            Autofocus: false,
            Disabled: false,
            Required: false,
            Checked: false,
            Value: "",

            traits: [
              "id",
              "name",
              {
                type: "checkbox",
                name: "Autofocus",
                label: "Autofocus",
                changeProp: true,
              },
              {
                type: "checkbox",
                name: "Disabled",
                label: "Disabled",
                changeProp: true,
              },
              {
                type: "checkbox",
                name: "Required",
                label: "Required",
                changeProp: true,
              },
              {
                type: "checkbox",
                name: "Checked",
                label: "Checked",
                changeProp: true,
              },
              {
                type: "text",
                name: "Value",
                label: "Value",
                changeProp: true,
              },
            ],

            changeProp: true,
            "script-props": [
              "Autofocus",
              "Disabled",
              "Required",
              "Checked",
              "Value",
            ],
          },
        },
      });

      editor.DomComponents.addType("checkbox_label_new", {
        model: {
          defaults: {
            tagName: "label",
            type: "checkbox_label_new",
            attributes: {
              for: "",
            },

            components: [
              {
                tagName: "p",
                type: "text",
                editable: true,

                style: {
                  width: "max-content",
                  "word-break": "break-all",
                },

                "style-default": {
                  width: "max-content",
                  "word-break": "break-all",
                },

                components: {
                  type: "textnode",
                  content: "checkbox",
                },
              },
            ],

            traits: ["id", "for"],
            changeProp: true,
          },
        },
      });

      // for run-time replication
      const checkboxRegularFormNewScript = async function (props) {
        console.log(
          "checkbox_regular_form_new props",
          props,
          this,
          this.childNodes.length
        );

        // For LB download manipulation
        // checking if the url or script is not running in the redsling editor, only then run the script to plot checkboxes
        let idEl = this.id;
        const urlDownload = window.top.location.href;
        const isSubstringPresent =
          (urlDownload.indexOf("/editor/") !== -1 &&
            urlDownload.indexOf("?projectId=") !== -1) ||
          urlDownload.indexOf("/large_preview/") !== -1 ||
          urlDownload.indexOf("/tab_preview/") !== -1 ||
          urlDownload.indexOf("/mobile_preview/") !== -1 ||
          urlDownload.indexOf("/fragment_editor/") !== -1;
        if (!isSubstringPresent) {
          setInterval(function () {
            Object.keys(props).forEach(function (key) {
              if (window[`${key}${idEl}`]) {
                props[key] = window[`${key}${idEl}`];
              }
            });
          }, 500);
        }

        // base url bearer token taken directly from local storage after getting downloaded
        let baseUrlData,
          dynamicBaseUrlBearerToken,
          baseUrl1BearerToken,
          baseUrl2BearerToken,
          baseUrl3BearerToken,
          baseUrl4BearerToken,
          baseUrl5BearerToken;
        // getting baseUrl data from local storage for run-time access
        let baseUrlDataFromStorage = localStorage.getItem("baseUrlData");
        if (baseUrlDataFromStorage) {
          baseUrlData = JSON.parse(baseUrlDataFromStorage);
        }
        // extracting token values from base URLs from baseUrlData
        if (baseUrlData) {
          try {
            if (`baseUrl1_${props.projectId}` in baseUrlData) {
              baseUrl1BearerToken =
                baseUrlData[`baseUrl1_${props.projectId}`].token;
            }
            if (`baseUrl2_${props.projectId}` in baseUrlData) {
              baseUrl2BearerToken =
                baseUrlData[`baseUrl2_${props.projectId}`].token;
            }
            if (`baseUrl3_${props.projectId}` in baseUrlData) {
              baseUrl3BearerToken =
                baseUrlData[`baseUrl3_${props.projectId}`].token;
            }
            if (`baseUrl4_${props.projectId}` in baseUrlData) {
              baseUrl4BearerToken =
                baseUrlData[`baseUrl4_${props.projectId}`].token;
            }
            if (`baseUrl5_${props.projectId}` in baseUrlData) {
              baseUrl5BearerToken =
                baseUrlData[`baseUrl5_${props.projectId}`].token;
            }
          } catch (e) {
            // statements
            console.log(e);
          }
        }

        // helper function to check if a variable is an array-of-objects or not
        function isArrayofObjects(variable) {
          // Check if the variable is an array
          if (!Array.isArray(variable)) {
            return false;
          }

          // Check if all elements in the array are objects
          for (const element of variable) {
            if (
              typeof element !== "object" ||
              Array.isArray(element) ||
              element === null
            ) {
              return false;
            }
          }
          return true;
        }

        // helper function to get value of a key in nested object
        function findKeyValue(obj, key) {
          if (obj.hasOwnProperty(key)) {
            // If the current object has the key, return its value
            return obj[key];
          }

          for (var prop in obj) {
            if (obj.hasOwnProperty(prop) && typeof obj[prop] === "object") {
              // If the current property is an object, recursively search within it
              var result = findKeyValue(obj[prop], key);
              if (result !== undefined) {
                return result;
              }
            }
          }
          // Key not found in the object
          return undefined;
        }

        // helper function to create an ID string with characters randomly
        function generateRandomString(length) {
          const characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
          let result = "";
          for (let i = 0; i < length; i++) {
            result += characters.charAt(
              Math.floor(Math.random() * characters.length)
            );
          }
          return result;
        }

        let mainContainer = this;
        let apiData, API;

        if (props.APIPath) {
          API = props.APIPath;
        }

        // dynamic addition and deletion for the downloaded code
        if (!isSubstringPresent) {
          // -------- Getting API Data
          // if CustomUrl is provided, use only that
          if (
            props.CustomUrl &&
            (!props.BaseUrl || props.BaseUrl === "null") &&
            props.APIMethod !== "POST"
          ) {
            // if nothing is provided for auth
            if (!props.BearerToken && !props.Username && !props.Password) {
              // add extra headers, if needed
              let config = {
                headers: {},
              };

              if (props.MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                props.MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${props.CustomUrl}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token is provided but not api_key or usr+pwd
            else if (props.BearerToken && !props.Username && !props.Password) {
              // add extra headers, if needed
              let config = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${props.BearerToken}`,
                },
              };

              if (props.MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                props.MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${props.CustomUrl}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token and api_key not provided but usr+pwd are provided
            else if (!props.BearerToken && props.Username && props.Password) {
              // add extra headers, if needed
              let config = {
                headers: {
                  Authorization:
                    "Basic " + btoa(props.Username + ":" + props.Password),
                },
              };

              if (props.MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                props.MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${props.CustomUrl}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }
          }

          // if base url is the choice and provided
          else if (
            props.BaseUrl &&
            API &&
            !props.CustomUrl &&
            props.APIMethod !== "POST"
          ) {
            // concatenating base url and its remaining part
            let url, baseUrlBearerToken;
            let forDownloadUrl;
            if (props.BaseUrl === "baseUrl1") {
              url = props.options.baseUrl1 + API;
              forDownloadUrl = "{{API_URL1}}" + API;
              baseUrlBearerToken = props.options.baseUrl1BearerToken;
              dynamicBaseUrlBearerToken = baseUrl1BearerToken;
            } else if (props.BaseUrl === "baseUrl2") {
              url = props.options.baseUrl2 + API;
              forDownloadUrl = "{{API_URL2}}" + API;
              baseUrlBearerToken = props.options.baseUrl2BearerToken;
              dynamicBaseUrlBearerToken = baseUrl2BearerToken;
            } else if (props.BaseUrl === "baseUrl3") {
              url = props.options.baseUrl3 + API;
              forDownloadUrl = "{{API_URL3}}" + API;
              baseUrlBearerToken = props.options.baseUrl3BearerToken;
              dynamicBaseUrlBearerToken = baseUrl3BearerToken;
            } else if (props.BaseUrl === "baseUrl4") {
              url = props.options.baseUrl4 + API;
              forDownloadUrl = "{{API_URL4}}" + API;
              baseUrlBearerToken = props.options.baseUrl4BearerToken;
              dynamicBaseUrlBearerToken = baseUrl4BearerToken;
            } else if (props.BaseUrl === "baseUrl5") {
              url = props.options.baseUrl5 + API;
              forDownloadUrl = "{{API_URL5}}" + API;
              baseUrlBearerToken = props.options.baseUrl5BearerToken;
              dynamicBaseUrlBearerToken = baseUrl5BearerToken;
            }

            // running in download
            url = forDownloadUrl;

            console.log(
              "url, dynamicBaseUrlBearerToken",
              url,
              dynamicBaseUrlBearerToken
            );

            // if nothing is provided for auth
            if (
              !props.BearerToken &&
              !dynamicBaseUrlBearerToken &&
              !props.Username &&
              !props.Password
            ) {
              // add extra headers, if needed
              let config = {
                headers: {},
              };

              if (props.MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                props.MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token is provided but not usr+pwd
            else if (props.BearerToken && !props.Username && !props.Password) {
              // add extra headers, if needed
              let config = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${props.BearerToken}`,
                },
              };

              if (props.MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                props.MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a dynamicBaseUrlBearerToken is provided but not Custom Bearer Token and usr+pwd
            else if (
              dynamicBaseUrlBearerToken &&
              !props.BearerToken &&
              !props.Username &&
              !props.Password
            ) {
              // add extra headers, if needed
              let config = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${dynamicBaseUrlBearerToken}`,
                },
              };

              if (props.MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                props.MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config@:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token is not provided but usr+pwd are provided
            else if (!props.BearerToken && props.Username && props.Password) {
              // add extra headers, if needed
              let config = {
                headers: {
                  Authorization:
                    "Basic " + btoa(props.Username + ":" + props.Password),
                },
              };

              if (props.MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                props.MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }
          }

          // POST method
          // if CustomUrl is provided, use only that
          if (
            props.CustomUrl &&
            (!props.BaseUrl || props.BaseUrl === "null") &&
            props.APIMethod === "POST"
          ) {
            // if nothing is provided for auth
            if (!props.BearerToken && !props.Username && !props.Password) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {},
                body:
                  typeof props.APIBody === "object"
                    ? JSON.stringify(props.APIBody)
                    : props.APIBody,
              };

              if (props.MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                props.MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${props.CustomUrl}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token is provided but not api_key or usr+pwd
            else if (props.BearerToken && !props.Username && !props.Password) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${props.BearerToken}`,
                },
                body:
                  typeof props.APIBody === "object"
                    ? JSON.stringify(props.APIBody)
                    : props.APIBody,
              };

              if (props.MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                props.MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${props.CustomUrl}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token and api_key not provided but usr+pwd are provided
            else if (!props.BearerToken && props.Username && props.Password) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {
                  Authorization:
                    "Basic " + btoa(props.Username + ":" + props.Password),
                },
                body:
                  typeof props.APIBody === "object"
                    ? JSON.stringify(props.APIBody)
                    : props.APIBody,
              };

              if (props.MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                props.MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${props.CustomUrl}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }
          }

          // if base url is the choice and provided
          if (
            props.BaseUrl &&
            API &&
            !props.CustomUrl &&
            props.APIMethod === "POST"
          ) {
            // concatenating base url and its remaining part
            let url, baseUrlBearerToken;
            let forDownloadUrl;
            if (props.BaseUrl === "baseUrl1") {
              url = props.options.baseUrl1 + API;
              forDownloadUrl = "{{API_URL1}}" + API;
              baseUrlBearerToken = props.options.baseUrl1BearerToken;
              dynamicBaseUrlBearerToken = baseUrl1BearerToken;
            } else if (props.BaseUrl === "baseUrl2") {
              url = props.options.baseUrl2 + API;
              forDownloadUrl = "{{API_URL2}}" + API;
              baseUrlBearerToken = props.options.baseUrl2BearerToken;
              dynamicBaseUrlBearerToken = baseUrl2BearerToken;
            } else if (props.BaseUrl === "baseUrl3") {
              url = props.options.baseUrl3 + API;
              forDownloadUrl = "{{API_URL3}}" + API;
              baseUrlBearerToken = props.options.baseUrl3BearerToken;
              dynamicBaseUrlBearerToken = baseUrl3BearerToken;
            } else if (props.BaseUrl === "baseUrl4") {
              url = props.options.baseUrl4 + API;
              forDownloadUrl = "{{API_URL4}}" + API;
              baseUrlBearerToken = props.options.baseUrl4BearerToken;
              dynamicBaseUrlBearerToken = baseUrl4BearerToken;
            } else if (props.BaseUrl === "baseUrl5") {
              url = props.options.baseUrl5 + API;
              forDownloadUrl = "{{API_URL5}}" + API;
              baseUrlBearerToken = props.options.baseUrl5BearerToken;
              dynamicBaseUrlBearerToken = baseUrl5BearerToken;
            }

            // running in download
            url = forDownloadUrl;

            console.log(
              "url, dynamicBaseUrlBearerToken",
              url,
              dynamicBaseUrlBearerToken
            );

            // if nothing is provided for auth
            if (
              !props.BearerToken &&
              !dynamicBaseUrlBearerToken &&
              !props.Username &&
              !props.Password
            ) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {},
                body:
                  typeof props.APIBody === "object"
                    ? JSON.stringify(props.APIBody)
                    : props.APIBody,
              };

              if (props.MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                props.MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token is provided but not usr+pwd
            else if (props.BearerToken && !props.Username && !props.Password) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${props.BearerToken}`,
                },
                body:
                  typeof props.APIBody === "object"
                    ? JSON.stringify(props.APIBody)
                    : props.APIBody,
              };

              if (props.MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                props.MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a dynamicBaseUrlBearerToken is provided but not Custom Bearer Token and usr+pwd
            else if (
              dynamicBaseUrlBearerToken &&
              !props.BearerToken &&
              !props.Username &&
              !props.Password
            ) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${dynamicBaseUrlBearerToken}`,
                },
                body:
                  typeof props.APIBody === "object"
                    ? JSON.stringify(props.APIBody)
                    : props.APIBody,
              };

              if (props.MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                props.MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config@:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token is not provided but usr+pwd are provided
            else if (!props.BearerToken && props.Username && props.Password) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {
                  Authorization:
                    "Basic " + btoa(props.Username + ":" + props.Password),
                },
                body:
                  typeof props.APIBody === "object"
                    ? JSON.stringify(props.APIBody)
                    : props.APIBody,
              };

              if (props.MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                props.MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }
          }

          console.log("apiData (in export):", apiData);

          // checking if the url or script is not running in the redsling editor, only then run the script to plot checkboxes

          if (apiData) {
            console.log("#");

            if (isArrayofObjects(apiData)) {
              if (props.ArrOfObjKyename) {
                console.log("##");

                // dynamic add
                // to check if any '_id' data was changed in the backend object, then update the frontend labels of checkboxes also
                if (mainContainer.innerHTML !== "") {
                  // check if these are database plotted checkboxes or not
                  let isNotDefaultCheckbox = mainContainer
                    .getElementsByClassName("group_checkbox_label")[0]
                    .getElementsByClassName("checkbox_input_new")[0]
                    .getElementsByTagName("input")[0]
                    .getAttribute("data_db_id");
                  console.log("isNotDefaultCheckbox", isNotDefaultCheckbox);

                  // if they are database plotted checkboxes then scan through them using data_db_id and update if any
                  if (isNotDefaultCheckbox && isNotDefaultCheckbox !== "") {
                    let allMiniContainers =
                      mainContainer.getElementsByClassName(
                        "group_checkbox_label"
                      );
                    Array.from(allMiniContainers).forEach(
                      (groupCheckboxLabel, idx) => {
                        let checkbox = groupCheckboxLabel
                          .getElementsByClassName("checkbox_input_new")[0]
                          .getElementsByTagName("input")[0];
                        let checkbox_dataDbId = groupCheckboxLabel
                          .getElementsByClassName("checkbox_input_new")[0]
                          .getElementsByTagName("input")[0]
                          .getAttribute("data_db_id");
                        let label =
                          groupCheckboxLabel.getElementsByTagName("label")[0];

                        // get the object from apiData that has the checkbox_dataDbId
                        let currentObj = apiData.filter(
                          (obj) => obj["_id"] === checkbox_dataDbId
                        );

                        if (currentObj.length > 0) {
                          let labelInnerText =
                            label.getElementsByTagName("p")[0].innerHTML;
                          currentObj.forEach((obj) => {
                            console.log("labelInnerText", labelInnerText);

                            // check if the IDs match, if not then update the label's inner text
                            if (
                              labelInnerText !== obj[`${props.ArrOfObjKyename}`]
                            ) {
                              label.getElementsByTagName("p")[0].innerHTML =
                                obj[`${props.ArrOfObjKyename}`];
                              checkbox.setAttribute(
                                "value",
                                obj[`${props.ArrOfObjKyename}`]
                              );

                              console.log("currentObj", currentObj);
                            }
                          });
                        }
                      }
                    );

                    // if there are equal number of objects in apiData as checkboxes then plot new ones
                    if (apiData.length >= allMiniContainers.length) {
                      mainContainer.innerHTML = "";

                      apiData.forEach((opt) => {
                        let randomString = generateRandomString(7);
                        let groupEle = document.createElement("span");
                        groupEle.setAttribute("data-gjs-type", "default");
                        groupEle.setAttribute("class", "group_checkbox_label");
                        Object.assign(groupEle.style, {
                          display: "flex",
                          flexWrap: "no-wrap",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          width: props.CommonWidth
                            ? props.CommonWidth + "%"
                            : "8%",
                        });

                        let checkboxInputNew = document.createElement("span");
                        checkboxInputNew.setAttribute(
                          "data-gjs-type",
                          "checkbox_input_new"
                        );
                        checkboxInputNew.setAttribute(
                          "class",
                          "checkbox_input_new"
                        );

                        let checkboxInput = document.createElement("input");
                        checkboxInput.setAttribute("type", "checkbox");
                        checkboxInput.setAttribute("id", `${randomString}`);
                        checkboxInput.setAttribute("name", `${randomString}`);
                        checkboxInput.setAttribute(
                          "value",
                          `${opt[`${props.ArrOfObjKyename}`]}`
                        );
                        checkboxInput.setAttribute(
                          "data_db_id",
                          `${opt[`_id`]}`
                            ? `${opt[`_id`]}`
                            : `${opt[`${props.ArrOfObjKyename}`]}`
                        );

                        checkboxInputNew.appendChild(checkboxInput);

                        let label = document.createElement("label");
                        label.setAttribute("type", "checkbox_label_new");
                        label.setAttribute("for", `${randomString}`);
                        // Object.assign(label.style, {
                        //   wordBreak: "break-all",
                        // });
                        label.innerHTML = `<p id="${generateRandomString(6)}">${
                          opt[`${props.ArrOfObjKyename}`]
                        }</p>`;

                        groupEle.appendChild(checkboxInputNew);
                        groupEle.appendChild(label);

                        mainContainer.appendChild(groupEle);
                      });
                    }
                  } else {
                    mainContainer.innerHTML = "";

                    apiData.forEach((opt) => {
                      let randomString = generateRandomString(7);
                      let groupEle = document.createElement("span");
                      groupEle.setAttribute("data-gjs-type", "default");
                      groupEle.setAttribute("class", "group_checkbox_label");
                      Object.assign(groupEle.style, {
                        display: "flex",
                        flexWrap: "no-wrap",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        width: props.CommonWidth
                          ? props.CommonWidth + "%"
                          : "8%",
                      });

                      let checkboxInputNew = document.createElement("span");
                      checkboxInputNew.setAttribute(
                        "data-gjs-type",
                        "checkbox_input_new"
                      );
                      checkboxInputNew.setAttribute(
                        "class",
                        "checkbox_input_new"
                      );

                      let checkboxInput = document.createElement("input");
                      checkboxInput.setAttribute("type", "checkbox");
                      checkboxInput.setAttribute("id", `${randomString}`);
                      checkboxInput.setAttribute("name", `${randomString}`);
                      checkboxInput.setAttribute(
                        "value",
                        `${opt[`${props.ArrOfObjKyename}`]}`
                      );
                      checkboxInput.setAttribute(
                        "data_db_id",
                        `${opt[`_id`]}`
                          ? `${opt[`_id`]}`
                          : `${opt[`${props.ArrOfObjKyename}`]}`
                      );

                      checkboxInputNew.appendChild(checkboxInput);

                      let label = document.createElement("label");
                      label.setAttribute("type", "checkbox_label_new");
                      label.setAttribute("for", `${randomString}`);
                      // Object.assign(label.style, {
                      //   wordBreak: "break-all",
                      // });
                      label.innerHTML = `<p id="${generateRandomString(6)}">${
                        opt[`${props.ArrOfObjKyename}`]
                      }</p>`;

                      groupEle.appendChild(checkboxInputNew);
                      groupEle.appendChild(label);

                      mainContainer.appendChild(groupEle);
                    });
                  }
                }

                // reasons -
                // container.components().length === 0 : plot checkboxes if there are no checkboxes present in the container
                // container.components().length === 2 : plot checkboxes the first time when there are the 2 initial checkboxes
                // apiData.length > container.components().length : plot checkboxes if apiData has more objects than checkboxes in the container meaning update the checkboxes when there is an update done in the backend database, make this dynamic
                if (
                  mainContainer.childNodes.length === 0 ||
                  (mainContainer.childNodes.length === 2 && apiData.length > 2)
                ) {
                  mainContainer.innerHTML = "";

                  apiData.forEach((opt) => {
                    let randomString = generateRandomString(7);
                    let groupEle = document.createElement("span");
                    groupEle.setAttribute("data-gjs-type", "default");
                    groupEle.setAttribute("class", "group_checkbox_label");
                    Object.assign(groupEle.style, {
                      display: "flex",
                      flexWrap: "no-wrap",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      width: props.CommonWidth ? props.CommonWidth + "%" : "8%",
                    });

                    let checkboxInputNew = document.createElement("span");
                    checkboxInputNew.setAttribute(
                      "data-gjs-type",
                      "checkbox_input_new"
                    );
                    checkboxInputNew.setAttribute(
                      "class",
                      "checkbox_input_new"
                    );

                    let checkboxInput = document.createElement("input");
                    checkboxInput.setAttribute("type", "checkbox");
                    checkboxInput.setAttribute("id", `${randomString}`);
                    checkboxInput.setAttribute("name", `${randomString}`);
                    checkboxInput.setAttribute(
                      "value",
                      `${opt[`${props.ArrOfObjKyename}`]}`
                    );
                    checkboxInput.setAttribute(
                      "data_db_id",
                      `${opt[`_id`]}`
                        ? `${opt[`_id`]}`
                        : `${opt[`${props.ArrOfObjKyename}`]}`
                    );

                    checkboxInputNew.appendChild(checkboxInput);

                    let label = document.createElement("label");
                    label.setAttribute("type", "checkbox_label_new");
                    label.setAttribute("for", `${randomString}`);
                    // Object.assign(label.style, {
                    //   wordBreak: "break-all",
                    // });
                    label.innerHTML = `<p id="${generateRandomString(6)}">${
                      opt[`${props.ArrOfObjKyename}`]
                    }</p>`;

                    groupEle.appendChild(checkboxInputNew);
                    groupEle.appendChild(label);

                    mainContainer.appendChild(groupEle);
                  });
                }

                // update only when the checkboxes have already been plotted previously from apiData but not updating if the apiData had only 2 fields
                if (
                  apiData.length > mainContainer.childNodes.length &&
                  mainContainer.childNodes.length > 2
                ) {
                  // only add what's missing from api as a dynamic update

                  // get all the labels' inner content and compare it with the keyname of the apiData to be plotted and add the ones missing
                  let allLabelEles = Array.from(
                    mainContainer.getElementsByTagName("label")
                  );
                  let allLabels = allLabelEles.map(
                    (label) => label.textContent
                  );
                  console.log("allLabelEles", allLabelEles, allLabels);

                  if (allLabelEles.length > 0) {
                    // if you find a label thats not in the apiData then add it to the existing checkbox container
                    apiData.forEach((dbRow, idx) => {
                      let randomString = generateRandomString(7);
                      // if that object from apiData is not present in the checkbox container then plot it
                      if (
                        !allLabels.includes(dbRow[`${props.ArrOfObjKyename}`])
                      ) {
                        let groupEle = document.createElement("span");
                        groupEle.setAttribute("data-gjs-type", "default");
                        groupEle.setAttribute("class", "group_checkbox_label");
                        Object.assign(groupEle.style, {
                          display: "flex",
                          flexWrap: "no-wrap",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          width: props.CommonWidth
                            ? props.CommonWidth + "%"
                            : "8%",
                        });

                        let checkboxInputNew = document.createElement("span");
                        checkboxInputNew.setAttribute(
                          "data-gjs-type",
                          "checkbox_input_new"
                        );
                        checkboxInputNew.setAttribute(
                          "class",
                          "checkbox_input_new"
                        );

                        let checkboxInput = document.createElement("input");
                        checkboxInput.setAttribute("type", "checkbox");
                        checkboxInput.setAttribute("id", `${randomString}`);
                        checkboxInput.setAttribute("name", `${randomString}`);
                        checkboxInput.setAttribute(
                          "value",
                          `${dbRow[`${props.ArrOfObjKyename}`]}`
                        );
                        checkboxInput.setAttribute(
                          "data_db_id",
                          `${dbRow[`_id`]}`
                            ? `${dbRow[`_id`]}`
                            : `${dbRow[`${props.ArrOfObjKyename}`]}`
                        );

                        checkboxInputNew.appendChild(checkboxInput);

                        let label = document.createElement("label");
                        label.setAttribute("type", "checkbox_label_new");
                        label.setAttribute("for", `${randomString}`);
                        // Object.assign(label.style, {
                        //   wordBreak: "break-all",
                        // });
                        label.innerHTML = `<p id="${generateRandomString(6)}">${
                          dbRow[`${props.ArrOfObjKyename}`]
                        }</p>`;

                        groupEle.appendChild(checkboxInputNew);
                        groupEle.appendChild(label);

                        mainContainer.appendChild(groupEle);
                      }
                    });
                  }
                }

                // plot when the apiData has only around 2 entries
                else if (apiData.length <= 2) {
                  // see if the checkboxes are the default checkboxes by checking if they have this attribute 'data_db_id', if yes then clear the body and append new else append new checkboxes anyways
                  let isNotDefaultCheckbox = mainContainer
                    .getElementsByTagName("input")[0]
                    .getAttribute("data_db_id");

                  // if it is a default checkbox then empty the body before appending apiData checkboxes
                  if (!isNotDefaultCheckbox) {
                    mainContainer.innerHTML = "";
                    apiData.forEach((opt) => {
                      let randomString = generateRandomString(7);
                      let groupEle = document.createElement("span");
                      groupEle.setAttribute("data-gjs-type", "default");
                      groupEle.setAttribute("class", "group_checkbox_label");
                      Object.assign(groupEle.style, {
                        display: "flex",
                        flexWrap: "no-wrap",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        width: props.CommonWidth
                          ? props.CommonWidth + "%"
                          : "8%",
                      });

                      let checkboxInputNew = document.createElement("span");
                      checkboxInputNew.setAttribute(
                        "data-gjs-type",
                        "checkbox_input_new"
                      );
                      checkboxInputNew.setAttribute(
                        "class",
                        "checkbox_input_new"
                      );

                      let checkboxInput = document.createElement("input");
                      checkboxInput.setAttribute("type", "checkbox");
                      checkboxInput.setAttribute("id", `${randomString}`);
                      checkboxInput.setAttribute("name", `${randomString}`);
                      checkboxInput.setAttribute(
                        "value",
                        `${opt[`${props.ArrOfObjKyename}`]}`
                      );
                      checkboxInput.setAttribute(
                        "data_db_id",
                        `${opt[`_id`]}`
                          ? `${opt[`_id`]}`
                          : `${opt[`${props.ArrOfObjKyename}`]}`
                      );

                      checkboxInputNew.appendChild(checkboxInput);

                      let label = document.createElement("label");
                      label.setAttribute("type", "checkbox_label_new");
                      label.setAttribute("for", `${randomString}`);
                      // Object.assign(label.style, {
                      //   wordBreak: "break-all",
                      // });
                      label.innerHTML = `<p id="${generateRandomString(6)}">${
                        opt[`${props.ArrOfObjKyename}`]
                      }</p>`;

                      groupEle.appendChild(checkboxInputNew);
                      groupEle.appendChild(label);

                      mainContainer.appendChild(groupEle);
                    });
                  } else {
                    mainContainer.innerHTML = "";

                    apiData.forEach((opt) => {
                      let randomString = generateRandomString(7);
                      let groupEle = document.createElement("span");
                      groupEle.setAttribute("data-gjs-type", "default");
                      groupEle.setAttribute("class", "group_checkbox_label");
                      Object.assign(groupEle.style, {
                        display: "flex",
                        flexWrap: "no-wrap",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        width: props.CommonWidth
                          ? props.CommonWidth + "%"
                          : "8%",
                      });

                      let checkboxInputNew = document.createElement("span");
                      checkboxInputNew.setAttribute(
                        "data-gjs-type",
                        "checkbox_input_new"
                      );
                      checkboxInputNew.setAttribute(
                        "class",
                        "checkbox_input_new"
                      );

                      let checkboxInput = document.createElement("input");
                      checkboxInput.setAttribute("type", "checkbox");
                      checkboxInput.setAttribute("id", `${randomString}`);
                      checkboxInput.setAttribute("name", `${randomString}`);
                      checkboxInput.setAttribute(
                        "value",
                        `${opt[`${props.ArrOfObjKyename}`]}`
                      );
                      checkboxInput.setAttribute(
                        "data_db_id",
                        `${opt[`_id`]}`
                          ? `${opt[`_id`]}`
                          : `${opt[`${props.ArrOfObjKyename}`]}`
                      );

                      checkboxInputNew.appendChild(checkboxInput);

                      let label = document.createElement("label");
                      label.setAttribute("type", "checkbox_label_new");
                      label.setAttribute("for", `${randomString}`);
                      // Object.assign(label.style, {
                      //   wordBreak: "break-all",
                      // });
                      label.innerHTML = `<p id="${generateRandomString(6)}">${
                        opt[`${props.ArrOfObjKyename}`]
                      }</p>`;

                      groupEle.appendChild(checkboxInputNew);
                      groupEle.appendChild(label);

                      mainContainer.appendChild(groupEle);
                    });
                  }
                }

                // dynamic delete
                if (mainContainer.childNodes.length > apiData.length) {
                  console.log("removing extra group_checkbox_labels");

                  let groupCheckboxLabels =
                    mainContainer.getElementsByClassName(
                      "group_checkbox_label"
                    );
                  Array.from(groupCheckboxLabels).forEach(
                    (groupCheckboxLabel) => {
                      let checkbox = groupCheckboxLabel
                        .getElementsByClassName("checkbox_input_new")[0]
                        .getElementsByTagName("input")[0];
                      let checkboxDataDbId =
                        checkbox.getAttribute("data_db_id");

                      // see if checkboxDataDbId is in apiData _ids and if not there, delete this group_checkbox_label
                      let apiData_id = apiData.map((obj) => obj["_id"]);

                      if (!apiData_id.includes(checkboxDataDbId)) {
                        groupCheckboxLabel.remove();
                        console.log("removed");
                      }
                    }
                  );
                }
              }
            }

            // if API response is a plain array of single elements
            else if (Array.isArray(apiData) && !isArrayofObjects(apiData)) {
              console.log("###");

              // either no checkboxes are plotted or the default 2 checkboxes are there
              if (
                mainContainer.childNodes.length === 0 ||
                mainContainer.childNodes.length <= 2
              ) {
                mainContainer.innerHTML = "";

                apiData.forEach((opt) => {
                  let randomString = generateRandomString(7);
                  let groupEle = document.createElement("span");
                  groupEle.setAttribute("data-gjs-type", "default");
                  groupEle.setAttribute("class", "group_checkbox_label");
                  Object.assign(groupEle.style, {
                    display: "flex",
                    flexWrap: "no-wrap",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    width: props.CommonWidth ? props.CommonWidth + "%" : "8%",
                  });

                  let checkboxInputNew = document.createElement("span");
                  checkboxInputNew.setAttribute(
                    "data-gjs-type",
                    "checkbox_input_new"
                  );
                  checkboxInputNew.setAttribute("class", "checkbox_input_new");

                  let checkboxInput = document.createElement("input");
                  checkboxInput.setAttribute("type", "checkbox");
                  checkboxInput.setAttribute("id", `${randomString}`);
                  checkboxInput.setAttribute("name", `${randomString}`);
                  checkboxInput.setAttribute("value", `${opt}`);

                  checkboxInputNew.appendChild(checkboxInput);

                  let label = document.createElement("label");
                  label.setAttribute("type", "checkbox_label_new");
                  label.setAttribute("for", `${randomString}`);
                  // Object.assign(label.style, {
                  //   wordBreak: "break-all",
                  // });
                  label.innerHTML = `<p id="${generateRandomString(
                    6
                  )}">${opt}</p>`;

                  groupEle.appendChild(checkboxInputNew);
                  groupEle.appendChild(label);

                  mainContainer.appendChild(groupEle);
                });
              }

              // else if the apiData checkboxes are already plotted then check for updated values and add those here without changing the IDd of already plotted ones
              else if (
                apiData.length > mainContainer.childNodes.length &&
                mainContainer.childNodes.length > 2
              ) {
                // only add what's missing from api as a dynamic update

                // get all the labels' inner content and compare it with the keyname of the apiData to be plotted and add the ones missing
                let allLabelEles = Array.from(
                  mainContainer.getElementsByTagName("label")
                );
                let allLabels = allLabelEles.map((label) => label.textContent);
                console.log("allLabelEles", allLabelEles, allLabels);

                if (allLabelEles.length > 0) {
                  // if you find a label thats not in the apiData then add it to the existing checkbox container
                  apiData.forEach((opt, idx) => {
                    let randomString = generateRandomString(7);
                    // if that object from apiData is not present in the checkbox container then plot it
                    if (!allLabels.includes(opt)) {
                      let groupEle = document.createElement("span");
                      groupEle.setAttribute("data-gjs-type", "default");
                      groupEle.setAttribute("class", "group_checkbox_label");
                      Object.assign(groupEle.style, {
                        display: "flex",
                        flexWrap: "no-wrap",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        width: props.CommonWidth
                          ? props.CommonWidth + "%"
                          : "8%",
                      });

                      let checkboxInputNew = document.createElement("span");
                      checkboxInputNew.setAttribute(
                        "data-gjs-type",
                        "checkbox_input_new"
                      );
                      checkboxInputNew.setAttribute(
                        "class",
                        "checkbox_input_new"
                      );

                      let checkboxInput = document.createElement("input");
                      checkboxInput.setAttribute("type", "checkbox");
                      checkboxInput.setAttribute("id", `${randomString}`);
                      checkboxInput.setAttribute("name", `${randomString}`);
                      checkboxInput.setAttribute("value", `${opt}`);

                      checkboxInputNew.appendChild(checkboxInput);

                      let label = document.createElement("label");
                      label.setAttribute("type", "checkbox_label_new");
                      label.setAttribute("for", `${randomString}`);
                      // Object.assign(label.style, {
                      //   wordBreak: "break-all",
                      // });
                      label.innerHTML = `<p id="${generateRandomString(
                        6
                      )}">${opt}</p>`;

                      groupEle.appendChild(checkboxInputNew);
                      groupEle.appendChild(label);

                      mainContainer.appendChild(groupEle);
                    }
                  });
                }
              }
            }

            // if API response is an object
            else if (
              !isArrayofObjects(apiData) &&
              typeof apiData === "object"
            ) {
              // if API response to plot is a nested array-of-objects inside the main response object
              if (props.NestedKeyname && props.ArrOfObjKyename) {
                console.log("####");
                // container.components("");

                // find the array-of-objects value
                let data = findKeyValue(apiData, props.NestedKeyname);

                if (isArrayofObjects(data)) {
                  // to check if any '_id' data was changed in the backend object, then update the frontend labels of checkboxes also
                  if (mainContainer.innerHTML !== "") {
                    // check if these are database plotted checkboxes or not
                    let isNotDefaultCheckbox = mainContainer
                      .getElementsByClassName("group_checkbox_label")[0]
                      .getElementsByClassName("checkbox_input_new")[0]
                      .getElementsByTagName("input")[0]
                      .getAttribute("data_db_id");
                    console.log("isNotDefaultCheckbox", isNotDefaultCheckbox);

                    // if they are database plotted checkboxes then scan through them using data_db_id and update if any
                    if (isNotDefaultCheckbox) {
                      let allMiniContainers =
                        mainContainer.getElementsByClassName(
                          "group_checkbox_label"
                        );
                      Array.from(allMiniContainers).forEach(
                        (groupCheckboxLabel, idx) => {
                          let checkbox = groupCheckboxLabel
                            .getElementsByClassName("checkbox_input_new")[0]
                            .getElementsByTagName("input")[0];
                          let checkbox_dataDbId = groupCheckboxLabel
                            .getElementsByClassName("checkbox_input_new")[0]
                            .getElementsByTagName("input")[0]
                            .getAttribute("data_db_id");
                          let label =
                            groupCheckboxLabel.getElementsByTagName("label")[0];

                          // get the object from apiData that has the checkbox_dataDbId
                          let currentObj = data.filter(
                            (obj) => obj["_id"] === checkbox_dataDbId
                          );

                          if (currentObj.length > 0) {
                            currentObj.forEach((obj) => {
                              let labelInnerText =
                                label.getElementsByTagName("p")[0].innerHTML;
                              console.log("labelInnerText", labelInnerText);

                              // check if the IDs match, if not then update the label's inner text
                              if (
                                labelInnerText !==
                                obj[`${props.ArrOfObjKyename}`]
                              ) {
                                label.getElementsByTagName("p")[0].innerHTML =
                                  obj[`${props.ArrOfObjKyename}`];
                                checkbox.setAttribute(
                                  "value",
                                  obj[`${props.ArrOfObjKyename}`]
                                );

                                console.log("currentObj", currentObj);
                              }
                            });
                          }
                        }
                      );
                    }
                  }

                  // reasons -
                  // container.components().length === 0 : plot checkboxes if there are no checkboxes present in the container
                  // container.components().length === 2 : plot checkboxes the first time when there are the 2 initial checkboxes
                  // apiData.length > container.components().length : plot checkboxes if apiData has more objects than checkboxes in the container meaning update the checkboxes when there is an update done in the backend database, make this dynamic
                  if (
                    mainContainer.childNodes.length === 0 ||
                    (mainContainer.childNodes.length === 2 && data.length > 2)
                  ) {
                    mainContainer.innerHTML = "";

                    data.forEach((opt) => {
                      let randomString = generateRandomString(7);
                      let groupEle = document.createElement("span");
                      groupEle.setAttribute("data-gjs-type", "default");
                      groupEle.setAttribute("class", "group_checkbox_label");
                      Object.assign(groupEle.style, {
                        display: "flex",
                        flexWrap: "no-wrap",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        width: props.CommonWidth
                          ? props.CommonWidth + "%"
                          : "8%",
                      });

                      let checkboxInputNew = document.createElement("span");
                      checkboxInputNew.setAttribute(
                        "data-gjs-type",
                        "checkbox_input_new"
                      );
                      checkboxInputNew.setAttribute(
                        "class",
                        "checkbox_input_new"
                      );

                      let checkboxInput = document.createElement("input");
                      checkboxInput.setAttribute("type", "checkbox");
                      checkboxInput.setAttribute("id", `${randomString}`);
                      checkboxInput.setAttribute("name", `${randomString}`);
                      checkboxInput.setAttribute(
                        "value",
                        `${opt[`${props.ArrOfObjKyename}`]}`
                      );
                      checkboxInput.setAttribute(
                        "data_db_id",
                        `${opt[`_id`]}`
                          ? `${opt[`_id`]}`
                          : `${opt[`${props.ArrOfObjKyename}`]}`
                      );

                      checkboxInputNew.appendChild(checkboxInput);

                      let label = document.createElement("label");
                      label.setAttribute("type", "checkbox_label_new");
                      label.setAttribute("for", `${randomString}`);
                      // Object.assign(label.style, {
                      //   wordBreak: "break-all",
                      // });
                      label.innerHTML = `<p id="${generateRandomString(6)}">${
                        opt[`${props.ArrOfObjKyename}`]
                      }</p>`;

                      groupEle.appendChild(checkboxInputNew);
                      groupEle.appendChild(label);

                      mainContainer.appendChild(groupEle);
                    });
                  }

                  // update only when the checkboxes have already been plotted previously from apiData but not updating if the apiData had only 2 fields
                  if (
                    data.length > mainContainer.childNodes.length &&
                    mainContainer.childNodes.length > 2
                  ) {
                    // only add what's missing from api as a dynamic update

                    // get all the labels' inner content and compare it with the keyname of the apiData to be plotted and add the ones missing
                    let allLabelEles = Array.from(
                      mainContainer.getElementsByTagName("label")
                    );
                    let allLabels = allLabelEles.map(
                      (label) => label.textContent
                    );
                    console.log("allLabelEles", allLabelEles, allLabels);

                    if (allLabelEles.length > 0) {
                      // if you find a label thats not in the apiData then add it to the existing checkbox container
                      data.forEach((dbRow, idx) => {
                        let randomString = generateRandomString(7);
                        // if that object from apiData is not present in the checkbox container then plot it
                        if (
                          !allLabels.includes(dbRow[`${props.ArrOfObjKyename}`])
                        ) {
                          let groupEle = document.createElement("span");
                          groupEle.setAttribute("data-gjs-type", "default");
                          groupEle.setAttribute(
                            "class",
                            "group_checkbox_label"
                          );
                          Object.assign(groupEle.style, {
                            display: "flex",
                            flexWrap: "no-wrap",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            width: props.CommonWidth
                              ? props.CommonWidth + "%"
                              : "8%",
                          });

                          let checkboxInputNew = document.createElement("span");
                          checkboxInputNew.setAttribute(
                            "data-gjs-type",
                            "checkbox_input_new"
                          );
                          checkboxInputNew.setAttribute(
                            "class",
                            "checkbox_input_new"
                          );

                          let checkboxInput = document.createElement("input");
                          checkboxInput.setAttribute("type", "checkbox");
                          checkboxInput.setAttribute("id", `${randomString}`);
                          checkboxInput.setAttribute("name", `${randomString}`);
                          checkboxInput.setAttribute(
                            "value",
                            `${dbRow[`${props.ArrOfObjKyename}`]}`
                          );
                          checkboxInput.setAttribute(
                            "data_db_id",
                            `${dbRow[`_id`]}`
                              ? `${dbRow[`_id`]}`
                              : `${dbRow[`${props.ArrOfObjKyename}`]}`
                          );

                          checkboxInputNew.appendChild(checkboxInput);

                          let label = document.createElement("label");
                          label.setAttribute("type", "checkbox_label_new");
                          label.setAttribute("for", `${randomString}`);
                          // Object.assign(label.style, {
                          //   wordBreak: "break-all",
                          // });
                          label.innerHTML = `<p id="${generateRandomString(
                            6
                          )}">${dbRow[`${props.ArrOfObjKyename}`]}</p>`;

                          groupEle.appendChild(checkboxInputNew);
                          groupEle.appendChild(label);

                          mainContainer.appendChild(groupEle);
                        }
                      });
                    }
                  }

                  // plot when the apiData has only around 2 entries
                  else if (data.length <= 2) {
                    // see if the checkboxes are the default checkboxes by checking if they have this attribute 'data_db_id', if yes then clear the body and append new else append new checkboxes anyways
                    let isNotDefaultCheckbox = mainContainer
                      .getElementsByTagName("input")[0]
                      .getAttribute("data_db_id");

                    // if it is a default checkbox then empty the body before appending apiData checkboxes
                    if (!isNotDefaultCheckbox) {
                      mainContainer.innerHTML = "";
                      data.forEach((opt) => {
                        let randomString = generateRandomString(7);
                        let groupEle = document.createElement("span");
                        groupEle.setAttribute("data-gjs-type", "default");
                        groupEle.setAttribute("class", "group_checkbox_label");
                        Object.assign(groupEle.style, {
                          display: "flex",
                          flexWrap: "no-wrap",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          width: props.CommonWidth
                            ? props.CommonWidth + "%"
                            : "8%",
                        });

                        let checkboxInputNew = document.createElement("span");
                        checkboxInputNew.setAttribute(
                          "data-gjs-type",
                          "checkbox_input_new"
                        );
                        checkboxInputNew.setAttribute(
                          "class",
                          "checkbox_input_new"
                        );

                        let checkboxInput = document.createElement("input");
                        checkboxInput.setAttribute("type", "checkbox");
                        checkboxInput.setAttribute("id", `${randomString}`);
                        checkboxInput.setAttribute("name", `${randomString}`);
                        checkboxInput.setAttribute(
                          "value",
                          `${opt[`${props.ArrOfObjKyename}`]}`
                        );
                        checkboxInput.setAttribute(
                          "data_db_id",
                          `${opt[`_id`]}`
                            ? `${opt[`_id`]}`
                            : `${opt[`${props.ArrOfObjKyename}`]}`
                        );

                        checkboxInputNew.appendChild(checkboxInput);

                        let label = document.createElement("label");
                        label.setAttribute("type", "checkbox_label_new");
                        label.setAttribute("for", `${randomString}`);
                        // Object.assign(label.style, {
                        //   wordBreak: "break-all",
                        // });
                        label.innerHTML = `<p id="${generateRandomString(6)}">${
                          opt[`${props.ArrOfObjKyename}`]
                        }</p>`;

                        groupEle.appendChild(checkboxInputNew);
                        groupEle.appendChild(label);

                        mainContainer.appendChild(groupEle);
                      });
                    }
                  }

                  // dynamic delete
                  if (mainContainer.childNodes.length > data.length) {
                    console.log("removing extra group_checkbox_labels");

                    let groupCheckboxLabels =
                      mainContainer.getElementsByClassName(
                        "group_checkbox_label"
                      );
                    Array.from(groupCheckboxLabels).forEach(
                      (groupCheckboxLabel) => {
                        let checkbox = groupCheckboxLabel
                          .getElementsByClassName("checkbox_input_new")[0]
                          .getElementsByTagName("input")[0];
                        let checkboxDataDbId =
                          checkbox.getAttribute("data_db_id");

                        // see if checkboxDataDbId is in apiData _ids and if not there, delete this group_checkbox_label
                        let apiData_id = data.map((obj) => obj["_id"]);

                        if (!apiData_id.includes(checkboxDataDbId)) {
                          groupCheckboxLabel.remove();
                          console.log("removed");
                        }
                      }
                    );
                  }
                }
              } else if (props.NestedKeyname && !props.ArrOfObjKyename) {
                // container.components("");

                // find the array value
                let data = findKeyValue(apiData, props.NestedKeyname);

                // if API response to plot is a nested plain array of single elements inside the main response object
                if (Array.isArray(apiData) && !isArrayofObjects(apiData)) {
                  console.log("###");

                  // either no checkboxes are plotted or the default 2 checkboxes are there
                  if (
                    mainContainer.childNodes.length === 0 ||
                    mainContainer.childNodes.length <= 2
                  ) {
                    mainContainer.innerHTML = "";

                    data.forEach((opt) => {
                      let randomString = generateRandomString(7);
                      let groupEle = document.createElement("span");
                      groupEle.setAttribute("data-gjs-type", "default");
                      groupEle.setAttribute("class", "group_checkbox_label");
                      Object.assign(groupEle.style, {
                        display: "flex",
                        flexWrap: "no-wrap",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        width: props.CommonWidth
                          ? props.CommonWidth + "%"
                          : "8%",
                      });

                      let checkboxInputNew = document.createElement("span");
                      checkboxInputNew.setAttribute(
                        "data-gjs-type",
                        "checkbox_input_new"
                      );
                      checkboxInputNew.setAttribute(
                        "class",
                        "checkbox_input_new"
                      );

                      let checkboxInput = document.createElement("input");
                      checkboxInput.setAttribute("type", "checkbox");
                      checkboxInput.setAttribute("id", `${randomString}`);
                      checkboxInput.setAttribute("name", `${randomString}`);
                      checkboxInput.setAttribute("value", `${opt}`);

                      checkboxInputNew.appendChild(checkboxInput);

                      let label = document.createElement("label");
                      label.setAttribute("type", "checkbox_label_new");
                      label.setAttribute("for", `${randomString}`);
                      // Object.assign(label.style, {
                      //   wordBreak: "break-all",
                      // });
                      label.innerHTML = `<p id="${generateRandomString(
                        6
                      )}">${opt}</p>`;

                      groupEle.appendChild(checkboxInputNew);
                      groupEle.appendChild(label);

                      mainContainer.appendChild(groupEle);
                    });
                  }

                  // else if the apiData checkboxes are already plotted then check for updated values and add those here without changing the IDd of already plotted ones
                  else if (
                    data.length > mainContainer.childNodes.length &&
                    mainContainer.childNodes.length > 2
                  ) {
                    // only add what's missing from api as a dynamic update

                    // get all the labels' inner content and compare it with the keyname of the apiData to be plotted and add the ones missing
                    let allLabelEles = Array.from(
                      mainContainer.getElementsByTagName("label")
                    );
                    let allLabels = allLabelEles.map(
                      (label) => label.textContent
                    );
                    console.log("allLabelEles", allLabelEles, allLabels);

                    if (allLabelEles.length > 0) {
                      // if you find a label thats not in the apiData then add it to the existing checkbox container
                      data.forEach((opt, idx) => {
                        let randomString = generateRandomString(7);
                        // if that object from apiData is not present in the checkbox container then plot it
                        if (!allLabels.includes(opt)) {
                          let groupEle = document.createElement("span");
                          groupEle.setAttribute("data-gjs-type", "default");
                          groupEle.setAttribute(
                            "class",
                            "group_checkbox_label"
                          );
                          Object.assign(groupEle.style, {
                            display: "flex",
                            flexWrap: "no-wrap",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            width: props.CommonWidth
                              ? props.CommonWidth + "%"
                              : "8%",
                          });

                          let checkboxInputNew = document.createElement("span");
                          checkboxInputNew.setAttribute(
                            "data-gjs-type",
                            "checkbox_input_new"
                          );
                          checkboxInputNew.setAttribute(
                            "class",
                            "checkbox_input_new"
                          );

                          let checkboxInput = document.createElement("input");
                          checkboxInput.setAttribute("type", "checkbox");
                          checkboxInput.setAttribute("id", `${randomString}`);
                          checkboxInput.setAttribute("name", `${randomString}`);
                          checkboxInput.setAttribute("value", `${opt}`);

                          checkboxInputNew.appendChild(checkboxInput);

                          let label = document.createElement("label");
                          label.setAttribute("type", "checkbox_label_new");
                          label.setAttribute("for", `${randomString}`);
                          // Object.assign(label.style, {
                          //   wordBreak: "break-all",
                          // });
                          label.innerHTML = `<p id="${generateRandomString(
                            6
                          )}">${opt}</p>`;

                          groupEle.appendChild(checkboxInputNew);
                          groupEle.appendChild(label);

                          mainContainer.appendChild(groupEle);
                        }
                      });
                    }
                  }
                }

                // if API response to plot are all key:value pairs of a plain object inside main object
                else if (
                  !Array.isArray(data) &&
                  !isArrayofObjects(data) &&
                  typeof data === "object"
                ) {
                  console.log("######");
                  Object.keys(data).forEach((key) => {
                    if (
                      !Array.isArray(data[`${key}`]) &&
                      typeof data[`${key}`] !== "object"
                    ) {
                      let randomString = generateRandomString(7);

                      let groupEle = document.createElement("span");
                      groupEle.setAttribute("data-gjs-type", "default");
                      groupEle.setAttribute("class", "group_checkbox_label");
                      Object.assign(groupEle.style, {
                        display: "flex",
                        flexWrap: "no-wrap",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        width: props.CommonWidth
                          ? props.CommonWidth + "%"
                          : "8%",
                      });

                      let checkboxInputNew = document.createElement("span");
                      checkboxInputNew.setAttribute(
                        "data-gjs-type",
                        "checkbox_input_new"
                      );
                      checkboxInputNew.setAttribute(
                        "class",
                        "checkbox_input_new"
                      );

                      let checkboxInput = document.createElement("input");
                      checkboxInput.setAttribute("type", "checkbox");
                      checkboxInput.setAttribute("id", `${randomString}`);
                      checkboxInput.setAttribute("name", `${randomString}`);
                      checkboxInput.setAttribute("value", `${data[`${key}`]}`);

                      checkboxInputNew.appendChild(checkboxInput);

                      let label = document.createElement("label");
                      label.setAttribute("type", "checkbox_label_new");
                      label.setAttribute("for", `${randomString}`);
                      // Object.assign(label.style, {
                      //   wordBreak: "break-all",
                      // });
                      label.innerHTML = `<p id="${generateRandomString(
                        6
                      )}">${key}</p>`;

                      groupEle.appendChild(checkboxInputNew);
                      groupEle.appendChild(label);

                      mainContainer.appendChild(groupEle);
                    }
                  });
                }
              }

              // if API response to plot are all key:value pairs of the plain object
              else if (!props.NestedKeyname && !props.ArrOfObjKyename) {
                console.log("#######");
                mainContainer.innerHTML = "";

                Object.keys(apiData).forEach((key) => {
                  let randomString = generateRandomString(7);

                  if (
                    !Array.isArray(apiData[`${key}`]) &&
                    typeof apiData[`${key}`] !== "object"
                  ) {
                    let groupEle = document.createElement("span");
                    groupEle.setAttribute("data-gjs-type", "default");
                    groupEle.setAttribute("class", "group_checkbox_label");
                    Object.assign(groupEle.style, {
                      display: "flex",
                      flexWrap: "no-wrap",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      width: props.CommonWidth ? props.CommonWidth + "%" : "8%",
                    });

                    let checkboxInputNew = document.createElement("span");
                    checkboxInputNew.setAttribute(
                      "data-gjs-type",
                      "checkbox_input_new"
                    );
                    checkboxInputNew.setAttribute(
                      "class",
                      "checkbox_input_new"
                    );

                    let checkboxInput = document.createElement("input");
                    checkboxInput.setAttribute("type", "checkbox");
                    checkboxInput.setAttribute("id", `${randomString}`);
                    checkboxInput.setAttribute("name", `${randomString}`);
                    checkboxInput.setAttribute("value", `${apiData[`${key}`]}`);

                    checkboxInputNew.appendChild(checkboxInput);

                    let label = document.createElement("label");
                    label.setAttribute("type", "checkbox_label_new");
                    label.setAttribute("for", `${randomString}`);
                    // Object.assign(label.style, {
                    //   wordBreak: "break-all",
                    // });
                    label.innerHTML = `<p id="${generateRandomString(
                      6
                    )}">${key}</p>`;

                    groupEle.appendChild(checkboxInputNew);
                    groupEle.appendChild(label);

                    mainContainer.appendChild(groupEle);
                  }
                });
              }

              // if there is a 'message' in api response instead of data
              else if (Object.keys(apiData).includes('message')) {
                  console.log("#@@");

                  mainContainer.innerHTML = "";
                  let randomString = generateRandomString(7);
                  let groupEle = document.createElement("span");
                  groupEle.setAttribute("data-gjs-type", "default");
                  groupEle.setAttribute("class", "group_checkbox_label");
                  Object.assign(groupEle.style, {
                    display: "flex",
                    flexWrap: "no-wrap",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    width: props.CommonWidth ? props.CommonWidth + "%" : "8%",
                  });

                  let checkboxInputNew = document.createElement("span");
                  checkboxInputNew.setAttribute(
                    "data-gjs-type",
                    "checkbox_input_new"
                  );
                  checkboxInputNew.setAttribute("class", "checkbox_input_new");

                  let checkboxInput = document.createElement("input");
                  checkboxInput.setAttribute("type", "checkbox");
                  checkboxInput.setAttribute("id", `${randomString}`);
                  checkboxInput.setAttribute("name", `${randomString}`);
                  checkboxInput.style.display = "none";

                  checkboxInputNew.appendChild(checkboxInput);

                  let label = document.createElement("label");
                  label.setAttribute("type", "checkbox_label_new");
                  label.setAttribute("for", `${randomString}`);
                  // Object.assign(label.style, {
                  //   wordBreak: "break-all",
                  // });
                  label.innerHTML = `<p id="${generateRandomString(6 )}">No Values.</p>`;

                  groupEle.appendChild(checkboxInputNew);
                  groupEle.appendChild(label);

                  mainContainer.appendChild(groupEle);
              }
            }

            replicateStyle();
          } else {
            console.log(
              '"/editor/" & "?projectId=" substrings are present together in the browser URL.'
            );
          }
        }

        // helper function to apply styling
        function applyStyle(element, style) {
          if (!element) {
            console.error("Element is undefined:", element);
            return;
          }

          for (let key in style) {
            if (style.hasOwnProperty(key)) {
              element.style[key] = style[key];
            }
          }
        }

        // for style replication of first checkbox to the rest
        function replicateStyle() {
          if (props.ReplicateStyling) {
            // Get the first radio component
            let firstGroup = mainContainer.querySelector(
              ".group_checkbox_label"
            );

            if (firstGroup) {
              let firstGroupStyle = window.getComputedStyle(firstGroup);

              // Apply styling to all other group_radio_label components
              let groups = mainContainer.querySelectorAll(
                ".group_checkbox_label"
              );
              groups.forEach((group) => {
                if (group !== firstGroup) {
                  // Apply the style of the first group to each group
                  applyStyle(group, firstGroupStyle);

                  // Apply the style to the children of each group
                  let firstGroupChildren = firstGroup.querySelectorAll("*");
                  let groupChildren = group.querySelectorAll("*");

                  if (firstGroupChildren.length === groupChildren.length) {
                    for (let i = 0; i < firstGroupChildren.length; i++) {
                      applyStyle(
                        groupChildren[i],
                        window.getComputedStyle(firstGroupChildren[i])
                      );
                    }
                  } else {
                    console.error(
                      "Number of children elements is different between groups."
                    );
                  }
                }
              });
            } else {
              console.error("First group not found.");
            }
          }
        }

        replicateStyle();
      };

      editor.DomComponents.addType("checkbox_regular_form_new", {
        model: {
          defaults: {
            tagName: "span",
            type: "checkbox_regular_form_new",
            script: checkboxRegularFormNewScript,

            attributes: {
              // id: "checkbox_regular_form",
              class: "checkbox_regular_form_new",
            },

            style: {
              display: "flex",
              "flex-wrap": "wrap",
              "align-items": "center",
              "justify-content": "flex-start",
              gap: "1%",
            },

            "style-default": {
              display: "flex",
              "flex-wrap": "wrap",
              "align-items": "center",
              "justify-content": "flex-start",
              gap: "1%",
            },

            components: [
              {
                tagName: "span",
                type: "default",
                attributes: {
                  class: "group_checkbox_label",
                },

                "style-default": {
                  display: "flex",
                  "flex-wrap": "no-wrap",
                  "justify-content": "flex-start",
                  "align-items": "flex-start",
                  width: "8%",
                },

                style: {
                  display: "flex",
                  "flex-wrap": "no-wrap",
                  "justify-content": "flex-start",
                  "align-items": "flex-start",
                  width: "8%",
                },

                components: [
                  {
                    type: "checkbox_input_new",
                  },
                  {
                    type: "checkbox_label_new",
                  },
                ],
              },
              {
                tagName: "span",
                type: "default",
                attributes: {
                  class: "group_checkbox_label",
                },

                "style-default": {
                  display: "flex",
                  "flex-wrap": "no-wrap",
                  "justify-content": "flex-start",
                  "align-items": "flex-start",
                  width: "8%",
                },

                style: {
                  display: "flex",
                  "flex-wrap": "no-wrap",
                  "justify-content": "flex-start",
                  "align-items": "flex-start",
                  width: "8%",
                },

                components: [
                  {
                    type: "checkbox_input_new",
                  },
                  {
                    type: "checkbox_label_new",
                  },
                ],
              },
            ],

            // traits
            CustomUrl: "",
            BaseUrl: "",
            APIPath: "",
            BearerToken: "",
            Username: "",
            Password: "",
            MoreHeaders: "",
            APIMethod: "GET",
            APIBody: "",
            ArrOfObjKyename: "",
            NestedKeyname: "",
            options: {
              baseUrl1,
              baseUrl2,
              baseUrl3,
              baseUrl4,
              baseUrl5,
              baseUrl1BearerToken,
              baseUrl2BearerToken,
              baseUrl3BearerToken,
              baseUrl4BearerToken,
              baseUrl5BearerToken,
            },

            // to give width to all the divs at once
            CommonWidth: "8",

            ReplicateStyling: "",

            projectId: localStorage.getItem("project_id")
              ? localStorage.getItem("project_id")
              : "",

            traits: [
              "id",
              {
                type: "text",
                name: "CustomUrl",
                label: "Custom URL",
                changeProp: true,
              },
              {
                type: "select",
                name: "BaseUrl",
                label: "Base URL",
                options: [
                  { id: "baseUrl1", name: "Base URL #1" },
                  { id: "baseUrl2", name: "Base URL #2" },
                  { id: "baseUrl3", name: "Base URL #3" },
                  { id: "baseUrl4", name: "Base URL #4" },
                  { id: "baseUrl5", name: "Base URL #5" },
                  { id: "null", name: "No Base URL" },
                ],
                changeProp: true,
              },
              {
                type: "text",
                name: "APIPath",
                label: "API Path",
                placeholder: "Don't start with '/'",
                changeProp: true,
              },
              {
                type: "text",
                name: "BearerToken",
                label: "Bearer Token",
                placeholder: "No bearer token provided",
                changeProp: true,
              },
              {
                type: "text",
                name: "Username",
                label: "Username",
                changeProp: true,
              },
              {
                type: "text",
                name: "Password",
                label: "Password",
                changeProp: true,
              },
              {
                type: "text",
                name: "MoreHeaders",
                label: "More Headers",
                placeholder: "k1:v1,k2:v2,k3:v3,...",
                changeProp: true,
              },
              {
                type: "select",
                name: "APIMethod",
                label: "API Method",
                options: [
                  // { id: "GET", name: "Get" },
                  { id: "POST", name: "Post" },
                ],
                // value: "GET",
                changeProp: true,
              },
              {
                type: "text",
                name: "APIBody",
                label: "API Body",
                placeholder: "Json format",
                changeProp: true,
              },
              {
                type: "text",
                name: "ArrOfObjKyename",
                label: "Keyname From Array Of Object",
                placeholder: "[ {k1:v1}, {k1:v2} ]",
                changeProp: true,
              },
              {
                type: "text",
                name: "NestedKeyname",
                label: "Keyname For Nested Structure",
                placeholder: "{ k1: [{...}, {...}] }",
                changeProp: true,
              },
              {
                type: "number",
                name: "CommonWidth",
                label: "Common Width",
                placeholder: "Unit %",
                changeProp: true,
              },
              {
                type: "checkbox",
                name: "ReplicateStyling",
                label: "Replicate Styling",
                changeProp: true,
              },
            ],

            changeProp: true,
            "script-props": [
              "CustomUrl",
              "BaseUrl",
              "APIPath",
              "BearerToken",
              "Username",
              "Password",
              "MoreHeaders",
              "APIMethod",
              "APIBody",
              "ArrOfObjKyename",
              "NestedKeyname",
              "options",
              "CommonWidth",
              "ReplicateStyling",
              "projectId",
            ],
          },

          init() {
            this.listenTo(this, "change:CustomUrl", this.handlePropChange);
            this.listenTo(this, "change:BaseUrl", this.handlePropChange);
            this.listenTo(this, "change:APIPath", this.handlePropChange);
            this.listenTo(this, "change:BearerToken", this.handlePropChange);
            this.listenTo(this, "change:Username", this.handlePropChange);
            this.listenTo(this, "change:Password", this.handlePropChange);
            this.listenTo(this, "change:MoreHeaders", this.handlePropChange);
            this.listenTo(this, "change:APIMethod", this.handlePropChange);
            this.listenTo(this, "change:APIBody", this.handlePropChange);
            this.listenTo(
              this,
              "change:ArrOfObjKyename",
              this.handlePropChange
            );
            this.listenTo(this, "change:NestedKeyname", this.handlePropChange);

            this.listenTo(this, "change:CommonWidth", this.commonWidthChange);
          },

          updated(property, value, prevValue) {
            console.log(
              "+++ property",
              property,
              "value",
              value,
              "prevValue",
              prevValue
            );
            console.log("this from updated()", this.getEl());

            // for editor part, it will not be in download for which script is written for style replication
            this.replicateStyling();
          },

          // only needed during development
          commonWidthChange() {
            const { CommonWidth } = this.props();

            const mainContainer = this;
            const groupCheckboxLabels = mainContainer.find(
              ".group_checkbox_label"
            );

            if (groupCheckboxLabels.length > 0) {
              groupCheckboxLabels.forEach((groupCheckboxLabel) => {
                groupCheckboxLabel.setStyle({
                  display: "flex",
                  "flex-wrap": "no-wrap",
                  "justify-content": "flex-start",
                  "align-items": "flex-start",
                  width: CommonWidth ? CommonWidth + "%" : "8% !important",
                });
              });
            }
          },

          // needed both in development and after download
          replicateStyling() {
            console.log("replicating style");
            const { ReplicateStyling } = this.props();
            const mainContainer = this;

            if (ReplicateStyling) {
              // Get the first group_checkbox_label component
              let firstGroup = mainContainer.find(".group_checkbox_label")[0];

              if (firstGroup) {
                let firstGroupStyle = firstGroup.getStyle();

                // Apply styling to all other group_checkbox_label components
                let groups = mainContainer.find(".group_checkbox_label");
                groups.forEach((group) => {
                  if (group !== firstGroup) {
                    // Apply the style of the first group to each group
                    group.setStyle(firstGroupStyle);

                    // Apply the style to the children of each group
                    let firstGroupChildren = firstGroup.find("*");
                    let groupChildren = group.find("*");

                    for (let i = 0; i < firstGroupChildren.length; i++) {
                      let firstChildStyle = firstGroupChildren[i].getStyle();
                      groupChildren[i].setStyle(firstChildStyle);
                    }
                  }
                });
              }
              this.view.render();
              console.log("replicated");
            }
          },

          // needed both in development and after download
          async handlePropChange() {
            const {
              CustomUrl,
              BaseUrl,
              APIPath,
              BearerToken,
              Username,
              Password,
              MoreHeaders,
              APIMethod,
              APIBody,
              ArrOfObjKyename,
              NestedKeyname,
              CommonWidth,
            } = this.props();

            // to rename a variable because API var is getting used in many places in this block scope
            let API;
            if (APIPath) {
              API = APIPath;
            }

            let container = this;
            console.log("container:", container, container.components().length);

            // helper function to check if a variable is an array-of-objects or not
            function isArrayofObjects(variable) {
              // Check if the variable is an array
              if (!Array.isArray(variable)) {
                return false;
              }

              // Check if all elements in the array are objects
              for (const element of variable) {
                if (
                  typeof element !== "object" ||
                  Array.isArray(element) ||
                  element === null
                ) {
                  return false;
                }
              }
              return true;
            }

            // helper function to get value of a key in nested object
            function findKeyValue(obj, key) {
              if (obj.hasOwnProperty(key)) {
                // If the current object has the key, return its value
                return obj[key];
              }

              for (var prop in obj) {
                if (obj.hasOwnProperty(prop) && typeof obj[prop] === "object") {
                  // If the current property is an object, recursively search within it
                  var result = findKeyValue(obj[prop], key);
                  if (result !== undefined) {
                    return result;
                  }
                }
              }
              // Key not found in the object
              return undefined;
            }

            let apiData;

            // -------- Getting API Data
            // if CustomUrl is provided, use only that
            if (
              CustomUrl &&
              (!BaseUrl || (BaseUrl === "null" && APIMethod !== "POST"))
            ) {
              // if nothing is provided for auth
              if (!BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  headers: {},
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is provided but not api_key or usr+pwd
              else if (BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${BearerToken}`,
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token and api_key not provided but usr+pwd are provided
              else if (!BearerToken && Username && Password) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    Authorization: "Basic " + btoa(Username + ":" + Password),
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }
            }

            // if base url is the choice and provided
            else if (BaseUrl && API && !CustomUrl && APIMethod !== "POST") {
              // concatenating base url and its remaining part
              let url, baseUrlBearerToken;
              if (BaseUrl === "baseUrl1") {
                url = baseUrl1 + API;
                baseUrlBearerToken = baseUrl1BearerToken;
              } else if (BaseUrl === "baseUrl2") {
                url = baseUrl2 + API;
                baseUrlBearerToken = baseUrl2BearerToken;
              } else if (BaseUrl === "baseUrl3") {
                url = baseUrl3 + API;
                baseUrlBearerToken = baseUrl3BearerToken;
              } else if (BaseUrl === "baseUrl4") {
                url = baseUrl4 + API;
                baseUrlBearerToken = baseUrl4BearerToken;
              } else if (BaseUrl === "baseUrl5") {
                url = baseUrl5 + API;
                baseUrlBearerToken = baseUrl5BearerToken;
              }
              console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

              // if nothing is provided for auth
              if (
                !BearerToken &&
                !baseUrlBearerToken &&
                !Username &&
                !Password
              ) {
                // add extra headers, if needed
                let config = {
                  headers: {},
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is provided but not usr+pwd
              else if (BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${BearerToken}`,
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a baseUrlBearerToken is provided but not Custom Bearer Token and usr+pwd
              else if (
                baseUrlBearerToken &&
                !BearerToken &&
                !Username &&
                !Password
              ) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${baseUrlBearerToken}`,
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config@:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is not provided but usr+pwd are provided
              else if (!BearerToken && Username && Password) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    Authorization: "Basic " + btoa(Username + ":" + Password),
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }
            }

            // POST method
            // if CustomUrl is provided, use only that
            if (
              CustomUrl &&
              (!BaseUrl || BaseUrl === "null") &&
              APIMethod === "POST"
            ) {
              // if nothing is provided for auth
              if (!BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {},
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is provided but not api_key or usr+pwd
              else if (BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${BearerToken}`,
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token and api_key not provided but usr+pwd are provided
              else if (!BearerToken && Username && Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    Authorization: "Basic " + btoa(Username + ":" + Password),
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }
            }

            // if base url is the choice and provided
            if (BaseUrl && API && !CustomUrl && APIMethod === "POST") {
              // concatenating base url and its remaining part
              let url, baseUrlBearerToken;
              if (BaseUrl === "baseUrl1") {
                url = baseUrl1 + API;
                baseUrlBearerToken = baseUrl1BearerToken;
              } else if (BaseUrl === "baseUrl2") {
                url = baseUrl2 + API;
                baseUrlBearerToken = baseUrl2BearerToken;
              } else if (BaseUrl === "baseUrl3") {
                url = baseUrl3 + API;
                baseUrlBearerToken = baseUrl3BearerToken;
              } else if (BaseUrl === "baseUrl4") {
                url = baseUrl4 + API;
                baseUrlBearerToken = baseUrl4BearerToken;
              } else if (BaseUrl === "baseUrl5") {
                url = baseUrl5 + API;
                baseUrlBearerToken = baseUrl5BearerToken;
              }
              console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

              // if nothing is provided for auth
              if (
                !BearerToken &&
                !baseUrlBearerToken &&
                !Username &&
                !Password
              ) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {},
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is provided but not usr+pwd
              else if (BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${BearerToken}`,
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a baseUrlBearerToken is provided but not Custom Bearer Token and usr+pwd
              else if (
                baseUrlBearerToken &&
                !BearerToken &&
                !Username &&
                !Password
              ) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${baseUrlBearerToken}`,
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config@:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is not provided but usr+pwd are provided
              else if (!BearerToken && Username && Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    Authorization: "Basic " + btoa(Username + ":" + Password),
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }
            }

            // console.log("apiData:", apiData);
            let allLabels = container.find(
              'label[data-gjs-type="checkbox_label_new"]'
            );
            console.log("allLabels", allLabels);

            // -------- Populating API Data, into the dropdown list
            if (apiData !== undefined) {
              console.log("#");
              // if API response is directly an array-of-objects
              if (isArrayofObjects(apiData)) {
                if (ArrOfObjKyename) {
                  console.log("##");
                  // container.components("");

                  // dynamic add
                  // to check if any '_id' data was changed in the backend object, then update the frontend labels of checkboxes also
                  if (container.components().length > 0) {
                    // check if these are database plotted checkboxes or not
                    let isNotDefaultCheckbox =
                      "data_db_id" in
                        container
                          .find('input[type="checkbox"]')[0]
                          .getAttributes() &&
                      container
                        .find('input[type="checkbox"]')[0]
                        .getAttributes()["data_db_id"] !== "";
                    console.log("isNotDefaultCheckbox", isNotDefaultCheckbox);

                    // if they are database plotted checkboxes then scan through them using data_db_id and update if any and append it more (apiData.length > number of checkboxes)
                    if (isNotDefaultCheckbox) {
                      let allMiniContainers = container.find(
                        ".group_checkbox_label"
                      );
                      allMiniContainers.forEach((groupCheckboxLabel, idx) => {
                        let checkbox = groupCheckboxLabel.find(
                          'input[type="checkbox"]'
                        )[0];
                        let checkbox_dataDbId = groupCheckboxLabel
                          .find('input[type="checkbox"]')[0]
                          .getAttributes()["data_db_id"];
                        let label = groupCheckboxLabel.find(
                          'label[data-gjs-type="checkbox_label_new"]'
                        )[0];
                        // get the object from apiData that has the checkbox_dataDbId
                        let currentObj = apiData.filter(
                          (obj) => obj["_id"] === checkbox_dataDbId
                        );

                        if (currentObj.length > 0) {
                          let labelInnerText = label
                            .components()
                            .models[0].get("content");

                          currentObj.forEach((obj) => {
                            // check if the IDs match, if not then update the label's inner text and value of checkbox
                            if (labelInnerText !== obj[`${ArrOfObjKyename}`]) {
                              // label.getLastChild().getLastChild().set("content", obj[`${ArrOfObjKyename}`]);
                              label
                                .components()
                                .models[0].set(
                                  "content",
                                  obj[`${ArrOfObjKyename}`]
                                );
                              checkbox.addAttributes({
                                value: obj[`${ArrOfObjKyename}`],
                              });
                              // to rerender the view of the component to show that it has been updated in the editor, instantly
                              this.view.render();
                              console.log("currentObj", currentObj);
                            }
                          });
                        }
                      });

                      // if there are equal number of objects in apiData as checkboxes then plot new ones
                      if (
                        apiData.length >= allMiniContainers.length &&
                        apiData[0]["_id"] !==
                          container
                            .find('input[type="checkbox"]')[0]
                            .getAttributes()["data_db_id"]
                      ) {
                        // clear the body and then plot
                        container.components("");
                        apiData.forEach((opt) => {
                          let groupEle = {
                            tagName: "span",
                            type: "default",
                            attributes: {
                              class: "group_checkbox_label",
                            },

                            "style-default": {
                              display: "flex",
                              "flex-wrap": "no-wrap",
                              "justify-content": "flex-start",
                              "align-items": "flex-start",
                              width: CommonWidth
                                ? CommonWidth + "%"
                                : "8% !important",
                            },

                            style: {
                              display: "flex",
                              "flex-wrap": "no-wrap",
                              "justify-content": "flex-start",
                              "align-items": "flex-start",
                              width: CommonWidth
                                ? CommonWidth + "%"
                                : "8% !important",
                            },

                            components: [
                              {
                                tagName: "span",
                                type: "checkbox_input_new",
                                script: individualCheckboxScriptNew,

                                attributes: {
                                  class: "checkbox_input_new",
                                },

                                components: [
                                  {
                                    tagName: "input",
                                    type: "default",
                                    attributes: {
                                      type: "checkbox",
                                      id: `checkbox_1_${uniqid()}`,
                                      name: `checkbox_1_${uniqid()}`,
                                      value: `${opt[`${ArrOfObjKyename}`]}`,
                                      data_db_id: `${opt[`_id`]}`
                                        ? `${opt[`_id`]}`
                                        : `${opt[`${ArrOfObjKyename}`]}`,
                                    },
                                  },
                                ],

                                Autofocus: false,
                                Disabled: false,
                                Required: false,
                                Checked: false,

                                traits: [
                                  "id",
                                  "name",
                                  "value",
                                  {
                                    type: "checkbox",
                                    name: "Autofocus",
                                    label: "Autofocus",
                                    changeProp: true,
                                  },
                                  {
                                    type: "checkbox",
                                    name: "Disabled",
                                    label: "Disabled",
                                    changeProp: true,
                                  },
                                  {
                                    type: "checkbox",
                                    name: "Required",
                                    label: "Required",
                                    changeProp: true,
                                  },
                                  {
                                    type: "checkbox",
                                    name: "Checked",
                                    label: "Checked",
                                    changeProp: true,
                                  },
                                ],

                                changeProp: true,
                                "script-props": [
                                  "Autofocus",
                                  "Disabled",
                                  "Required",
                                  "Checked",
                                ],
                              },
                              {
                                tagName: "label",
                                type: "checkbox_label_new",
                                attributes: {
                                  for: ``,
                                },

                                components: [
                                  {
                                    tagName: "p",
                                    type: "text",
                                    editable: true,

                                    style: {
                                      "word-break": "break-all",
                                    },

                                    "style-default": {
                                      "word-break": "break-all",
                                    },

                                    components: {
                                      type: "textnode",
                                      content: `${opt[`${ArrOfObjKyename}`]}`,
                                    },
                                  },
                                ],

                                traits: ["id", "for"],
                                changeProp: true,
                              },
                            ],
                          };

                          container.append(groupEle);
                        });
                      }
                    } else {
                      // clear the body and then plot because the first-time plotting will happen here
                      container.components("");
                      apiData.forEach((opt) => {
                        let groupEle = {
                          tagName: "span",
                          type: "default",
                          attributes: {
                            class: "group_checkbox_label",
                          },

                          "style-default": {
                            display: "flex",
                            "flex-wrap": "no-wrap",
                            "justify-content": "flex-start",
                            "align-items": "flex-start",
                            width: CommonWidth
                              ? CommonWidth + "%"
                              : "8% !important",
                          },

                          style: {
                            display: "flex",
                            "flex-wrap": "no-wrap",
                            "justify-content": "flex-start",
                            "align-items": "flex-start",
                            width: CommonWidth
                              ? CommonWidth + "%"
                              : "8% !important",
                          },

                          components: [
                            {
                              tagName: "span",
                              type: "checkbox_input_new",
                              script: individualCheckboxScriptNew,

                              attributes: {
                                class: "checkbox_input_new",
                              },

                              components: [
                                {
                                  tagName: "input",
                                  type: "default",
                                  attributes: {
                                    type: "checkbox",
                                    id: `checkbox_1_${uniqid()}`,
                                    name: `checkbox_1_${uniqid()}`,
                                    value: `${opt[`${ArrOfObjKyename}`]}`,
                                    data_db_id: `${opt[`_id`]}`
                                      ? `${opt[`_id`]}`
                                      : `${opt[`${ArrOfObjKyename}`]}`,
                                  },
                                },
                              ],

                              Autofocus: false,
                              Disabled: false,
                              Required: false,
                              Checked: false,

                              traits: [
                                "id",
                                "name",
                                "value",
                                {
                                  type: "checkbox",
                                  name: "Autofocus",
                                  label: "Autofocus",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Disabled",
                                  label: "Disabled",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Required",
                                  label: "Required",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Checked",
                                  label: "Checked",
                                  changeProp: true,
                                },
                              ],

                              changeProp: true,
                              "script-props": [
                                "Autofocus",
                                "Disabled",
                                "Required",
                                "Checked",
                              ],
                            },
                            {
                              tagName: "label",
                              type: "checkbox_label_new",
                              attributes: {
                                for: ``,
                              },

                              components: [
                                {
                                  tagName: "p",
                                  type: "text",
                                  editable: true,

                                  style: {
                                    "word-break": "break-all",
                                  },

                                  "style-default": {
                                    "word-break": "break-all",
                                  },

                                  components: {
                                    type: "textnode",
                                    content: `${opt[`${ArrOfObjKyename}`]}`,
                                  },
                                },
                              ],

                              traits: ["id", "for"],
                              changeProp: true,
                            },
                          ],
                        };

                        container.append(groupEle);
                      });
                    }
                  }

                  // reasons -
                  // container.components().length === 0 : plot checkboxes if there are no checkboxes present in the container
                  // container.components().length === 2 : plot checkboxes the first time when there are the 2 initial checkboxes
                  // apiData.length > container.components().length : plot checkboxes if apiData has more objects than checkboxes in the container meaning update the checkboxes when there is an update done in the backend database, make this dynamic
                  if (
                    container.components().length === 0 ||
                    (container.components().length === 2 && apiData.length > 2)
                  ) {
                    // clear the body and then plot because the first-time plotting will happen here
                    container.components("");
                    apiData.forEach((opt) => {
                      let groupEle = {
                        tagName: "span",
                        type: "default",
                        attributes: {
                          class: "group_checkbox_label",
                        },

                        "style-default": {
                          display: "flex",
                          "flex-wrap": "no-wrap",
                          "justify-content": "flex-start",
                          "align-items": "flex-start",
                          width: CommonWidth
                            ? CommonWidth + "%"
                            : "8% !important",
                        },

                        style: {
                          display: "flex",
                          "flex-wrap": "no-wrap",
                          "justify-content": "flex-start",
                          "align-items": "flex-start",
                          width: CommonWidth
                            ? CommonWidth + "%"
                            : "8% !important",
                        },

                        components: [
                          {
                            tagName: "span",
                            type: "checkbox_input_new",
                            script: individualCheckboxScriptNew,

                            attributes: {
                              class: "checkbox_input_new",
                            },

                            components: [
                              {
                                tagName: "input",
                                type: "default",
                                attributes: {
                                  type: "checkbox",
                                  id: `checkbox_1_${uniqid()}`,
                                  name: `checkbox_1_${uniqid()}`,
                                  value: `${opt[`${ArrOfObjKyename}`]}`,
                                  data_db_id: `${opt[`_id`]}`
                                    ? `${opt[`_id`]}`
                                    : `${opt[`${ArrOfObjKyename}`]}`,
                                },
                              },
                            ],

                            Autofocus: false,
                            Disabled: false,
                            Required: false,
                            Checked: false,

                            traits: [
                              "id",
                              "name",
                              "value",
                              {
                                type: "checkbox",
                                name: "Autofocus",
                                label: "Autofocus",
                                changeProp: true,
                              },
                              {
                                type: "checkbox",
                                name: "Disabled",
                                label: "Disabled",
                                changeProp: true,
                              },
                              {
                                type: "checkbox",
                                name: "Required",
                                label: "Required",
                                changeProp: true,
                              },
                              {
                                type: "checkbox",
                                name: "Checked",
                                label: "Checked",
                                changeProp: true,
                              },
                            ],

                            changeProp: true,
                            "script-props": [
                              "Autofocus",
                              "Disabled",
                              "Required",
                              "Checked",
                            ],
                          },
                          {
                            tagName: "label",
                            type: "checkbox_label_new",
                            attributes: {
                              for: ``,
                            },

                            components: [
                              {
                                tagName: "p",
                                type: "text",
                                editable: true,

                                style: {
                                  "word-break": "break-all",
                                },

                                "style-default": {
                                  "word-break": "break-all",
                                },

                                components: {
                                  type: "textnode",
                                  content: `${opt[`${ArrOfObjKyename}`]}`,
                                },
                              },
                            ],

                            traits: ["id", "for"],
                            changeProp: true,
                          },
                        ],
                      };

                      // let inputEle = {
                      //   tagName: "input",
                      //   type: "default", // so that the input component doesn't throw error of 'undefined'

                      //   script: individualCheckboxScript,

                      //   attributes: {
                      //     type: "checkbox",
                      //     // id: `${opt[`${ArrOfObjKyename}`]}`,
                      //     name: `${opt[`${ArrOfObjKyename}`]}`,
                      //     value: `${opt[`${ArrOfObjKyename}`]}`,
                      //   },

                      //   Autofocus: false,
                      //   Disabled: false,
                      //   Required: false,
                      //   Checked: false,

                      //   traits: [
                      //     "id",
                      //     "name",
                      //     "value",
                      //     {
                      //       type: "checkbox",
                      //       name: "Autofocus",
                      //       label: "Autofocus",
                      //       changeProp: true,
                      //     },
                      //     {
                      //       type: "checkbox",
                      //       name: "Disabled",
                      //       label: "Disabled",
                      //       changeProp: true,
                      //     },
                      //     {
                      //       type: "checkbox",
                      //       name: "Required",
                      //       label: "Required",
                      //       changeProp: true,
                      //     },
                      //     {
                      //       type: "checkbox",
                      //       name: "Checked",
                      //       label: "Checked",
                      //       changeProp: true,
                      //     },
                      //   ],

                      //   changeProp: true,
                      //   "script-props": [
                      //     "Autofocus",
                      //     "Disabled",
                      //     "Required",
                      //     "Checked",
                      //   ],
                      // };

                      // let labelEle = {
                      //   tagName: "label",
                      //   attributes: {
                      //     for: ``,
                      //   },

                      //   components: [
                      //     {
                      //       tagName: "p",
                      //       type: "text",
                      //       editable: true,

                      //       components: {
                      //         type: "textnode",
                      //         content: `${opt[`${ArrOfObjKyename}`]}`,
                      //       },
                      //     },
                      //   ],

                      //   traits: ["id", "for"],
                      // };

                      // container.append(inputEle);
                      // container.append(labelEle);
                      container.append(groupEle);
                    });
                  }

                  // update only when the checkboxes have already been plotted previously from apiData but not updating if the apiData had only 2 fields
                  if (
                    apiData.length > container.components().length &&
                    container.components().length > 2
                  ) {
                    // only add what's missing from api as a dynamic update

                    // get all the labels' inner content and compare it with the keyname of the apiData to be plotted and add the ones missing
                    let allLabelEles = container.find(
                      'label[data-gjs-type="checkbox_label_new"]'
                    );
                    let allLabels = allLabelEles.map(
                      (label) =>
                        label.find('p[data-gjs-type="text"]')[0].getEl()
                          .innerHTML
                    );
                    console.log("allLabelEles", allLabelEles, allLabels);

                    if (allLabelEles.length > 0) {
                      // if you find a label thats not in the apiData then add it to the existing checkbox container
                      apiData.forEach((dbRow, idx) => {
                        // if that object from apiData is not present in the checkbox container then plot it
                        if (!allLabels.includes(dbRow[`${ArrOfObjKyename}`])) {
                          let groupEle = {
                            tagName: "span",
                            type: "default",
                            attributes: {
                              class: "group_checkbox_label",
                            },

                            "style-default": {
                              display: "flex",
                              "flex-wrap": "no-wrap",
                              "justify-content": "flex-start",
                              "align-items": "flex-start",
                              width: CommonWidth
                                ? CommonWidth + "%"
                                : "8% !important",
                            },

                            style: {
                              display: "flex",
                              "flex-wrap": "no-wrap",
                              "justify-content": "flex-start",
                              "align-items": "flex-start",
                              width: CommonWidth
                                ? CommonWidth + "%"
                                : "8% !important",
                            },

                            components: [
                              {
                                tagName: "span",
                                type: "checkbox_input_new",
                                script: individualCheckboxScriptNew,

                                attributes: {
                                  class: "checkbox_input_new",
                                },

                                components: [
                                  {
                                    tagName: "input",
                                    type: "default",
                                    attributes: {
                                      type: "checkbox",
                                      id: `checkbox_1_${uniqid()}`,
                                      name: `checkbox_1_${uniqid()}`,
                                      value: `${dbRow[`${ArrOfObjKyename}`]}`,
                                      data_db_id: `${dbRow[`_id`]}`
                                        ? `${dbRow[`_id`]}`
                                        : `${dbRow[`${ArrOfObjKyename}`]}`,
                                    },
                                  },
                                ],

                                Autofocus: false,
                                Disabled: false,
                                Required: false,
                                Checked: false,

                                traits: [
                                  "id",
                                  "name",
                                  "value",
                                  {
                                    type: "checkbox",
                                    name: "Autofocus",
                                    label: "Autofocus",
                                    changeProp: true,
                                  },
                                  {
                                    type: "checkbox",
                                    name: "Disabled",
                                    label: "Disabled",
                                    changeProp: true,
                                  },
                                  {
                                    type: "checkbox",
                                    name: "Required",
                                    label: "Required",
                                    changeProp: true,
                                  },
                                  {
                                    type: "checkbox",
                                    name: "Checked",
                                    label: "Checked",
                                    changeProp: true,
                                  },
                                ],

                                changeProp: true,
                                "script-props": [
                                  "Autofocus",
                                  "Disabled",
                                  "Required",
                                  "Checked",
                                ],
                              },
                              {
                                tagName: "label",
                                type: "checkbox_label_new",
                                attributes: {
                                  for: ``,
                                },

                                components: [
                                  {
                                    tagName: "p",
                                    type: "text",
                                    editable: true,

                                    style: {
                                      "word-break": "break-all",
                                    },

                                    "style-default": {
                                      "word-break": "break-all",
                                    },

                                    components: {
                                      type: "textnode",
                                      content: `${dbRow[`${ArrOfObjKyename}`]}`,
                                    },
                                  },
                                ],

                                traits: ["id", "for"],
                                changeProp: true,
                              },
                            ],
                          };

                          container.append(groupEle);
                        }
                      });
                    }
                  }

                  // plot when the apiData has only around 2 entries
                  else if (apiData.length <= 2) {
                    console.log("_");

                    // see if the checkboxes are the default checkboxes by checking if they have this attribute 'data_db_id', if yes then clear the body and append new else append new checkboxes anyways
                    let isNotDefaultCheckbox =
                      "data_db_id" in
                      container
                        .find('input[type="checkbox"]')[0]
                        .getAttributes();
                    console.log("isNotDefaultCheckbox", isNotDefaultCheckbox);

                    // if it is a default checkbox then empty the body before appending apiData checkboxes
                    if (!isNotDefaultCheckbox) {
                      container.components("");
                      apiData.forEach((opt) => {
                        let groupEle = {
                          tagName: "span",
                          type: "default",
                          attributes: {
                            class: "group_checkbox_label",
                          },

                          "style-default": {
                            display: "flex",
                            "flex-wrap": "no-wrap",
                            "justify-content": "flex-start",
                            "align-items": "flex-start",
                            width: CommonWidth
                              ? CommonWidth + "%"
                              : "8% !important",
                          },

                          style: {
                            display: "flex",
                            "flex-wrap": "no-wrap",
                            "justify-content": "flex-start",
                            "align-items": "flex-start",
                            width: CommonWidth
                              ? CommonWidth + "%"
                              : "8% !important",
                          },

                          components: [
                            {
                              tagName: "span",
                              type: "checkbox_input_new",
                              script: individualCheckboxScriptNew,

                              attributes: {
                                class: "checkbox_input_new",
                              },

                              components: [
                                {
                                  tagName: "input",
                                  type: "default",
                                  attributes: {
                                    type: "checkbox",
                                    id: `checkbox_1_${uniqid()}`,
                                    name: `checkbox_1_${uniqid()}`,
                                    value: `${opt[`${ArrOfObjKyename}`]}`,
                                    data_db_id: `${opt[`_id`]}`
                                      ? `${opt[`_id`]}`
                                      : `${opt[`${ArrOfObjKyename}`]}`,
                                  },
                                },
                              ],

                              Autofocus: false,
                              Disabled: false,
                              Required: false,
                              Checked: false,

                              traits: [
                                "id",
                                "name",
                                "value",
                                {
                                  type: "checkbox",
                                  name: "Autofocus",
                                  label: "Autofocus",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Disabled",
                                  label: "Disabled",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Required",
                                  label: "Required",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Checked",
                                  label: "Checked",
                                  changeProp: true,
                                },
                              ],

                              changeProp: true,
                              "script-props": [
                                "Autofocus",
                                "Disabled",
                                "Required",
                                "Checked",
                              ],
                            },
                            {
                              tagName: "label",
                              type: "checkbox_label_new",
                              attributes: {
                                for: ``,
                              },

                              components: [
                                {
                                  tagName: "p",
                                  type: "text",
                                  editable: true,

                                  style: {
                                    "word-break": "break-all",
                                  },

                                  "style-default": {
                                    "word-break": "break-all",
                                  },

                                  components: {
                                    type: "textnode",
                                    content: `${opt[`${ArrOfObjKyename}`]}`,
                                  },
                                },
                              ],

                              traits: ["id", "for"],
                              changeProp: true,
                            },
                          ],
                        };
                        container.append(groupEle);
                      });
                      console.log("plotted1");
                    } else {
                      // clear the body and then plot because the first-time plotting will happen here
                      container.components("");
                      apiData.forEach((opt) => {
                        let groupEle = {
                          tagName: "span",
                          type: "default",
                          attributes: {
                            class: "group_checkbox_label",
                          },

                          "style-default": {
                            display: "flex",
                            "flex-wrap": "no-wrap",
                            "justify-content": "flex-start",
                            "align-items": "flex-start",
                            width: CommonWidth
                              ? CommonWidth + "%"
                              : "8% !important",
                          },

                          style: {
                            display: "flex",
                            "flex-wrap": "no-wrap",
                            "justify-content": "flex-start",
                            "align-items": "flex-start",
                            width: CommonWidth
                              ? CommonWidth + "%"
                              : "8% !important",
                          },

                          components: [
                            {
                              tagName: "span",
                              type: "checkbox_input_new",
                              script: individualCheckboxScriptNew,

                              attributes: {
                                class: "checkbox_input_new",
                              },

                              components: [
                                {
                                  tagName: "input",
                                  type: "default",
                                  attributes: {
                                    type: "checkbox",
                                    id: `checkbox_1_${uniqid()}`,
                                    name: `checkbox_1_${uniqid()}`,
                                    value: `${opt[`${ArrOfObjKyename}`]}`,
                                    data_db_id: `${opt[`_id`]}`
                                      ? `${opt[`_id`]}`
                                      : `${opt[`${ArrOfObjKyename}`]}`,
                                  },
                                },
                              ],

                              Autofocus: false,
                              Disabled: false,
                              Required: false,
                              Checked: false,

                              traits: [
                                "id",
                                "name",
                                "value",
                                {
                                  type: "checkbox",
                                  name: "Autofocus",
                                  label: "Autofocus",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Disabled",
                                  label: "Disabled",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Required",
                                  label: "Required",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Checked",
                                  label: "Checked",
                                  changeProp: true,
                                },
                              ],

                              changeProp: true,
                              "script-props": [
                                "Autofocus",
                                "Disabled",
                                "Required",
                                "Checked",
                              ],
                            },
                            {
                              tagName: "label",
                              type: "checkbox_label_new",
                              attributes: {
                                for: ``,
                              },

                              components: [
                                {
                                  tagName: "p",
                                  type: "text",
                                  editable: true,

                                  style: {
                                    "word-break": "break-all",
                                  },

                                  "style-default": {
                                    "word-break": "break-all",
                                  },

                                  components: {
                                    type: "textnode",
                                    content: `${opt[`${ArrOfObjKyename}`]}`,
                                  },
                                },
                              ],

                              traits: ["id", "for"],
                              changeProp: true,
                            },
                          ],
                        };

                        container.append(groupEle);
                      });
                      console.log("plotted1");
                    }
                  }

                  // dynamic delete
                  if (container.components().length > apiData.length) {
                    console.log("removing extra group_checkbox_labels");

                    let groupCheckboxLabels = container.find(
                      ".group_checkbox_label"
                    );
                    groupCheckboxLabels.forEach((groupCheckboxLabel) => {
                      // get the data_db_url from checkbox and check if _id is present in apiData, if not, remove it
                      let checkbox = groupCheckboxLabel
                        .find(".checkbox_input_new")[0]
                        .find('input[type="checkbox"]')[0];
                      let checkboxDataDbId =
                        checkbox.getAttributes()["data_db_id"];

                      // see if checkboxDataDbId is in apiData _ids and if not there, delete this group_checkbox_label
                      let apiData_id = apiData.map((obj) => obj["_id"]);

                      if (!apiData_id.includes(checkboxDataDbId)) {
                        groupCheckboxLabel.remove();
                        console.log("removed");
                      }
                    });
                  }
                }
              }

              // if API response is a plain array of single elements
              else if (Array.isArray(apiData) && !isArrayofObjects(apiData)) {
                console.log("###");

                // either no checkboxes are plotted or the default 2 checkboxes are there
                if (
                  container.components().length === 0 ||
                  container.components().length <= 2
                ) {
                  container.components("");
                  apiData.forEach((opt) => {
                    let groupEle = {
                      tagName: "span",
                      type: "default",
                      attributes: {
                        class: "group_checkbox_label",
                      },

                      "style-default": {
                        display: "flex",
                        "flex-wrap": "no-wrap",
                        "justify-content": "flex-start",
                        "align-items": "flex-start",
                        width: CommonWidth
                          ? CommonWidth + "%"
                          : "8% !important",
                      },

                      style: {
                        display: "flex",
                        "flex-wrap": "no-wrap",
                        "justify-content": "flex-start",
                        "align-items": "flex-start",
                        width: CommonWidth
                          ? CommonWidth + "%"
                          : "8% !important",
                      },

                      components: [
                        {
                          tagName: "span",
                          type: "checkbox_input_new",
                          script: individualCheckboxScriptNew,

                          attributes: {
                            class: "checkbox_input_new",
                          },

                          components: [
                            {
                              tagName: "input",
                              type: "default",
                              attributes: {
                                type: "checkbox",
                                id: `checkbox_1_${uniqid()}`,
                                name: `checkbox_1_${uniqid()}`,
                                value: `${opt}`,
                              },
                            },
                          ],

                          Autofocus: false,
                          Disabled: false,
                          Required: false,
                          Checked: false,

                          traits: [
                            "id",
                            "name",
                            "value",
                            {
                              type: "checkbox",
                              name: "Autofocus",
                              label: "Autofocus",
                              changeProp: true,
                            },
                            {
                              type: "checkbox",
                              name: "Disabled",
                              label: "Disabled",
                              changeProp: true,
                            },
                            {
                              type: "checkbox",
                              name: "Required",
                              label: "Required",
                              changeProp: true,
                            },
                            {
                              type: "checkbox",
                              name: "Checked",
                              label: "Checked",
                              changeProp: true,
                            },
                          ],

                          changeProp: true,
                          "script-props": [
                            "Autofocus",
                            "Disabled",
                            "Required",
                            "Checked",
                          ],
                        },
                        {
                          tagName: "label",
                          type: "checkbox_label_new",
                          attributes: {
                            for: ``,
                          },

                          components: [
                            {
                              tagName: "p",
                              type: "text",
                              editable: true,

                              style: {
                                "word-break": "break-all",
                              },

                              "style-default": {
                                "word-break": "break-all",
                              },

                              components: {
                                type: "textnode",
                                content: `${opt}`,
                              },
                            },
                          ],

                          traits: ["id", "for"],
                        },
                      ],
                    };

                    // let inputEle = {
                    //   tagName: "input",
                    //   type: "default", // so that the input component doesn't throw error of 'undefined'

                    //   script: individualCheckboxScript,

                    //   attributes: {
                    //     type: "checkbox",
                    //     // id: `ch-${opt}`,
                    //     name: `ch-${opt}`,
                    //     value: `${opt}`,
                    //   },

                    //   Autofocus: false,
                    //   Disabled: false,
                    //   Required: false,
                    //   Checked: false,

                    //   traits: [
                    //     "id",
                    //     "name",
                    //     "value",
                    //     {
                    //       type: "checkbox",
                    //       name: "Autofocus",
                    //       label: "Autofocus",
                    //       changeProp: true,
                    //     },
                    //     {
                    //       type: "checkbox",
                    //       name: "Disabled",
                    //       label: "Disabled",
                    //       changeProp: true,
                    //     },
                    //     {
                    //       type: "checkbox",
                    //       name: "Required",
                    //       label: "Required",
                    //       changeProp: true,
                    //     },
                    //     {
                    //       type: "checkbox",
                    //       name: "Checked",
                    //       label: "Checked",
                    //       changeProp: true,
                    //     },
                    //   ],

                    //   changeProp: true,
                    //   "script-props": [
                    //     "Autofocus",
                    //     "Disabled",
                    //     "Required",
                    //     "Checked",
                    //   ],
                    // };

                    // let labelEle = {
                    //   tagName: "label",
                    //   attributes: {
                    //     for: ``,
                    //   },

                    //   components: [
                    //     {
                    //       tagName: "p",
                    //       type: "text",
                    //       editable: true,

                    //       components: {
                    //         type: "textnode",
                    //         content: `${opt}`,
                    //       },
                    //     },
                    //   ],

                    //   traits: ["id", "for"],
                    // };

                    // container.append(inputEle);
                    // container.append(labelEle);
                    container.append(groupEle);
                  });
                }

                // else if the apiData checkboxes are already plotted then check for updated values and add those here without changing the IDd of already plotted ones
                else if (
                  apiData.length > container.components().length &&
                  container.components().length > 2
                ) {
                  // only add what's missing from api as a dynamic update

                  // get all the labels' inner content and compare it with the keyname of the apiData to be plotted and add the ones missing
                  let allLabelEles = container.find(
                    'label[data-gjs-type="checkbox_label_new"]'
                  );
                  let allLabels = allLabelEles.map(
                    (label) =>
                      label.find('p[data-gjs-type="text"]')[0].getEl().innerHTML
                  );
                  console.log("allLabelEles", allLabelEles, allLabels);

                  if (allLabelEles.length > 0) {
                    // if you find a label thats not in the apiData then add it to the existing checkbox container
                    apiData.forEach((opt, idx) => {
                      // if that object from apiData is not present in the checkbox container then plot it
                      if (!allLabels.includes(opt)) {
                        let groupEle = {
                          tagName: "span",
                          type: "default",
                          attributes: {
                            class: "group_checkbox_label",
                          },

                          "style-default": {
                            display: "flex",
                            "flex-wrap": "no-wrap",
                            "justify-content": "flex-start",
                            "align-items": "flex-start",
                            width: CommonWidth
                              ? CommonWidth + "%"
                              : "8% !important",
                          },

                          style: {
                            display: "flex",
                            "flex-wrap": "no-wrap",
                            "justify-content": "flex-start",
                            "align-items": "flex-start",
                            width: CommonWidth
                              ? CommonWidth + "%"
                              : "8% !important",
                          },

                          components: [
                            {
                              tagName: "span",
                              type: "checkbox_input_new",
                              script: individualCheckboxScriptNew,

                              attributes: {
                                class: "checkbox_input_new",
                              },

                              components: [
                                {
                                  tagName: "input",
                                  type: "default",
                                  attributes: {
                                    type: "checkbox",
                                    id: `checkbox_1_${uniqid()}`,
                                    name: `checkbox_1_${uniqid()}`,
                                    value: `${opt}`,
                                  },
                                },
                              ],

                              Autofocus: false,
                              Disabled: false,
                              Required: false,
                              Checked: false,

                              traits: [
                                "id",
                                "name",
                                "value",
                                {
                                  type: "checkbox",
                                  name: "Autofocus",
                                  label: "Autofocus",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Disabled",
                                  label: "Disabled",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Required",
                                  label: "Required",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Checked",
                                  label: "Checked",
                                  changeProp: true,
                                },
                              ],

                              changeProp: true,
                              "script-props": [
                                "Autofocus",
                                "Disabled",
                                "Required",
                                "Checked",
                              ],
                            },
                            {
                              tagName: "label",
                              type: "checkbox_label_new",
                              attributes: {
                                for: ``,
                              },

                              components: [
                                {
                                  tagName: "p",
                                  type: "text",
                                  editable: true,

                                  style: {
                                    "word-break": "break-all",
                                  },

                                  "style-default": {
                                    "word-break": "break-all",
                                  },

                                  components: {
                                    type: "textnode",
                                    content: `${opt}`,
                                  },
                                },
                              ],

                              traits: ["id", "for"],
                            },
                          ],
                        };

                        container.append(groupEle);
                      }
                    });
                  }
                }
              }

              // if API response is an object
              else if (
                !isArrayofObjects(apiData) &&
                typeof apiData === "object"
              ) {
                // if API response to plot is a nested array-of-objects inside the main response object
                if (NestedKeyname && ArrOfObjKyename) {
                  console.log("####");
                  // container.components("");

                  // find the array-of-objects value
                  let data = findKeyValue(apiData, NestedKeyname);

                  if (isArrayofObjects(data)) {
                    // to check if any '_id' data was changed in the backend object, then update the frontend labels of checkboxes also
                    if (container.components().length > 0) {
                      // check if these are database plotted checkboxes or not
                      let isNotDefaultCheckbox =
                        "data_db_id" in
                        container
                          .find('input[type="checkbox"]')[0]
                          .getAttributes();
                      console.log("isNotDefaultCheckbox", isNotDefaultCheckbox);

                      // if they are database plotted checkboxes then scan through them using data_db_id and update if any
                      if (isNotDefaultCheckbox) {
                        let allMiniContainers = container.find(
                          ".group_checkbox_label"
                        );
                        allMiniContainers.forEach((groupCheckboxLabel, idx) => {
                          let checkbox_dataDbId = groupCheckboxLabel
                            .find('input[type="checkbox"]')[0]
                            .getAttributes()["data_db_id"];
                          let label = groupCheckboxLabel.find(
                            'label[data-gjs-type="checkbox_label_new"]'
                          )[0];

                          // get the object from apiData that has the checkbox_dataDbId
                          let currentObj = apiData.filter(
                            (obj) => obj["_id"] === checkbox_dataDbId
                          );

                          if (currentObj.length > 0) {
                            currentObj.forEach((obj) => {
                              let labelInnerText = label
                                .find('p[data-gjs-type="text"]')[0]
                                .getEl().innerHTML;

                              // check if the IDs match, if not then update the label's inner text
                              if (
                                labelInnerText !== obj[`${ArrOfObjKyename}`]
                              ) {
                                label
                                  .getLastChild()
                                  .getLastChild()
                                  .set("content", obj[`${ArrOfObjKyename}`]);
                                // to rerender the view of the component to show that it has been updated in the editor, instantly
                                this.view.render();
                                console.log("currentObj", currentObj);
                              }
                            });
                          }
                        });
                      }
                    }

                    // reasons -
                    // container.components().length === 0 : plot checkboxes if there are no checkboxes present in the container
                    // container.components().length === 2 : plot checkboxes the first time when there are the 2 initial checkboxes
                    // data.length > container.components().length : plot checkboxes if apiData has more objects than checkboxes in the container meaning update the checkboxes when there is an update done in the backend database, make this dynamic
                    if (
                      container.components().length === 0 ||
                      (container.components().length === 2 && data.length > 2)
                    ) {
                      container.components("");
                      data.forEach((opt) => {
                        let groupEle = {
                          tagName: "span",
                          type: "default",
                          attributes: {
                            class: "group_checkbox_label",
                          },

                          "style-default": {
                            display: "flex",
                            "flex-wrap": "no-wrap",
                            "justify-content": "flex-start",
                            "align-items": "flex-start",
                            width: CommonWidth
                              ? CommonWidth + "%"
                              : "8% !important",
                          },

                          style: {
                            display: "flex",
                            "flex-wrap": "no-wrap",
                            "justify-content": "flex-start",
                            "align-items": "flex-start",
                            width: CommonWidth
                              ? CommonWidth + "%"
                              : "8% !important",
                          },

                          components: [
                            {
                              tagName: "span",
                              type: "checkbox_input_new",
                              script: individualCheckboxScriptNew,

                              attributes: {
                                class: "checkbox_input_new",
                              },

                              components: [
                                {
                                  tagName: "input",
                                  type: "default",
                                  attributes: {
                                    type: "checkbox",
                                    id: `checkbox_1_${uniqid()}`,
                                    name: `checkbox_1_${uniqid()}`,
                                    value: `${opt[`${ArrOfObjKyename}`]}`,
                                    data_db_id: `${opt[`_id`]}`
                                      ? `${opt[`_id`]}`
                                      : `${opt[`${ArrOfObjKyename}`]}`,
                                  },
                                },
                              ],

                              Autofocus: false,
                              Disabled: false,
                              Required: false,
                              Checked: false,

                              traits: [
                                "id",
                                "name",
                                "value",
                                {
                                  type: "checkbox",
                                  name: "Autofocus",
                                  label: "Autofocus",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Disabled",
                                  label: "Disabled",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Required",
                                  label: "Required",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Checked",
                                  label: "Checked",
                                  changeProp: true,
                                },
                              ],

                              changeProp: true,
                              "script-props": [
                                "Autofocus",
                                "Disabled",
                                "Required",
                                "Checked",
                              ],
                            },
                            {
                              tagName: "label",
                              type: "checkbox_label_new",
                              attributes: {
                                for: ``,
                              },

                              components: [
                                {
                                  tagName: "p",
                                  type: "text",
                                  editable: true,

                                  style: {
                                    "word-break": "break-all",
                                  },

                                  "style-default": {
                                    "word-break": "break-all",
                                  },

                                  components: {
                                    type: "textnode",
                                    content: `${opt[`${ArrOfObjKyename}`]}`,
                                  },
                                },
                              ],

                              traits: ["id", "for"],
                            },
                          ],
                        };

                        // let inputEle = {
                        //   tagName: "input",
                        //   type: "default", // so that the input component doesn't throw error of 'undefined'

                        //   script: individualCheckboxScript,

                        //   attributes: {
                        //     type: "checkbox",
                        //     // id: `${opt[`${ArrOfObjKyename}`]}`,
                        //     name: `${opt[`${ArrOfObjKyename}`]}`,
                        //     value: `${opt[`${ArrOfObjKyename}`]}`,
                        //   },

                        //   Autofocus: false,
                        //   Disabled: false,
                        //   Required: false,
                        //   Checked: false,

                        //   traits: [
                        //     "id",
                        //     "name",
                        //     "value",
                        //     {
                        //       type: "checkbox",
                        //       name: "Autofocus",
                        //       label: "Autofocus",
                        //       changeProp: true,
                        //     },
                        //     {
                        //       type: "checkbox",
                        //       name: "Disabled",
                        //       label: "Disabled",
                        //       changeProp: true,
                        //     },
                        //     {
                        //       type: "checkbox",
                        //       name: "Required",
                        //       label: "Required",
                        //       changeProp: true,
                        //     },
                        //     {
                        //       type: "checkbox",
                        //       name: "Checked",
                        //       label: "Checked",
                        //       changeProp: true,
                        //     },
                        //   ],

                        //   changeProp: true,
                        //   "script-props": [
                        //     "Autofocus",
                        //     "Disabled",
                        //     "Required",
                        //     "Checked",
                        //   ],
                        // };

                        // let labelEle = {
                        //   tagName: "label",
                        //   attributes: {
                        //     for: ``,
                        //   },

                        //   components: [
                        //     {
                        //       tagName: "p",
                        //       type: "text",
                        //       editable: true,

                        //       components: {
                        //         type: "textnode",
                        //         content: `${opt[`${ArrOfObjKyename}`]}`,
                        //       },
                        //     },
                        //   ],

                        //   traits: ["id", "for"],
                        // };

                        // container.append(inputEle);
                        // container.append(labelEle);
                        container.append(groupEle);
                      });
                    }

                    // update only when the checkboxes have already been plotted previously from apiData but not updating if the apiData had only 2 fields
                    if (
                      data.length > container.components().length &&
                      container.components().length > 2
                    ) {
                      // only add what's missing from api as a dynamic update

                      // get all the labels' inner content and compare it with the keyname of the apiData to be plotted and add the ones missing
                      let allLabelEles = container.find(
                        'label[data-gjs-type="checkbox_label_new"]'
                      );
                      let allLabels = allLabelEles.map(
                        (label) =>
                          label.find('p[data-gjs-type="text"]')[0].getEl()
                            .innerHTML
                      );
                      console.log("allLabelEles", allLabelEles, allLabels);

                      if (allLabelEles.length > 0) {
                        // if you find a label thats not in the apiData then add it to the existing checkbox container
                        data.forEach((opt, idx) => {
                          // if that object from apiData is not present in the checkbox container then plot it
                          if (!allLabels.includes(opt[`${ArrOfObjKyename}`])) {
                            let groupEle = {
                              tagName: "span",
                              type: "default",
                              attributes: {
                                class: "group_checkbox_label",
                              },

                              "style-default": {
                                display: "flex",
                                "flex-wrap": "no-wrap",
                                "justify-content": "flex-start",
                                "align-items": "flex-start",
                                width: CommonWidth
                                  ? CommonWidth + "%"
                                  : "8% !important",
                              },

                              style: {
                                display: "flex",
                                "flex-wrap": "no-wrap",
                                "justify-content": "flex-start",
                                "align-items": "flex-start",
                                width: CommonWidth
                                  ? CommonWidth + "%"
                                  : "8% !important",
                              },

                              components: [
                                {
                                  tagName: "span",
                                  type: "checkbox_input_new",
                                  script: individualCheckboxScriptNew,

                                  attributes: {
                                    class: "checkbox_input_new",
                                  },

                                  components: [
                                    {
                                      tagName: "input",
                                      type: "default",
                                      attributes: {
                                        type: "checkbox",
                                        id: `checkbox_1_${uniqid()}`,
                                        name: `checkbox_1_${uniqid()}`,
                                        value: `${opt[`${ArrOfObjKyename}`]}`,
                                        data_db_id: `${opt[`_id`]}`
                                          ? `${opt[`_id`]}`
                                          : `${opt[`${ArrOfObjKyename}`]}`,
                                      },
                                    },
                                  ],

                                  Autofocus: false,
                                  Disabled: false,
                                  Required: false,
                                  Checked: false,

                                  traits: [
                                    "id",
                                    "name",
                                    "value",
                                    {
                                      type: "checkbox",
                                      name: "Autofocus",
                                      label: "Autofocus",
                                      changeProp: true,
                                    },
                                    {
                                      type: "checkbox",
                                      name: "Disabled",
                                      label: "Disabled",
                                      changeProp: true,
                                    },
                                    {
                                      type: "checkbox",
                                      name: "Required",
                                      label: "Required",
                                      changeProp: true,
                                    },
                                    {
                                      type: "checkbox",
                                      name: "Checked",
                                      label: "Checked",
                                      changeProp: true,
                                    },
                                  ],

                                  changeProp: true,
                                  "script-props": [
                                    "Autofocus",
                                    "Disabled",
                                    "Required",
                                    "Checked",
                                  ],
                                },
                                {
                                  tagName: "label",
                                  type: "checkbox_label_new",
                                  attributes: {
                                    for: ``,
                                  },

                                  components: [
                                    {
                                      tagName: "p",
                                      type: "text",
                                      editable: true,

                                      style: {
                                        "word-break": "break-all",
                                      },

                                      "style-default": {
                                        "word-break": "break-all",
                                      },

                                      components: {
                                        type: "textnode",
                                        content: `${opt[`${ArrOfObjKyename}`]}`,
                                      },
                                    },
                                  ],

                                  traits: ["id", "for"],
                                },
                              ],
                            };

                            container.append(groupEle);
                          }
                        });
                      }
                    }

                    // plot when the data has only around 2 entries
                    else if (data.length <= 2) {
                      console.log("_");

                      // see if the checkboxes are the default checkboxes by checking if they have this attribute 'data_db_id', if yes then clear the body and append new else append new checkboxes anyways
                      let isNotDefaultCheckbox =
                        "data_db_id" in
                        container
                          .find('input[type="checkbox"]')[0]
                          .getAttributes();
                      console.log("isNotDefaultCheckbox", isNotDefaultCheckbox);

                      // if it is a default checkbox then empty the body before appending apiData checkboxes
                      if (!isNotDefaultCheckbox) {
                        container.components("");
                        data.forEach((opt) => {
                          let groupEle = {
                            tagName: "span",
                            type: "default",
                            attributes: {
                              class: "group_checkbox_label",
                            },

                            "style-default": {
                              display: "flex",
                              "flex-wrap": "no-wrap",
                              "justify-content": "flex-start",
                              "align-items": "flex-start",
                              width: CommonWidth
                                ? CommonWidth + "%"
                                : "8% !important",
                            },

                            style: {
                              display: "flex",
                              "flex-wrap": "no-wrap",
                              "justify-content": "flex-start",
                              "align-items": "flex-start",
                              width: CommonWidth
                                ? CommonWidth + "%"
                                : "8% !important",
                            },

                            components: [
                              {
                                tagName: "span",
                                type: "checkbox_input_new",
                                script: individualCheckboxScriptNew,

                                attributes: {
                                  class: "checkbox_input_new",
                                },

                                components: [
                                  {
                                    tagName: "input",
                                    type: "default",
                                    attributes: {
                                      type: "checkbox",
                                      id: `checkbox_1_${uniqid()}`,
                                      name: `checkbox_1_${uniqid()}`,
                                      value: `${opt[`${ArrOfObjKyename}`]}`,
                                      data_db_id: `${opt[`_id`]}`
                                        ? `${opt[`_id`]}`
                                        : `${opt[`${ArrOfObjKyename}`]}`,
                                    },
                                  },
                                ],

                                Autofocus: false,
                                Disabled: false,
                                Required: false,
                                Checked: false,

                                traits: [
                                  "id",
                                  "name",
                                  "value",
                                  {
                                    type: "checkbox",
                                    name: "Autofocus",
                                    label: "Autofocus",
                                    changeProp: true,
                                  },
                                  {
                                    type: "checkbox",
                                    name: "Disabled",
                                    label: "Disabled",
                                    changeProp: true,
                                  },
                                  {
                                    type: "checkbox",
                                    name: "Required",
                                    label: "Required",
                                    changeProp: true,
                                  },
                                  {
                                    type: "checkbox",
                                    name: "Checked",
                                    label: "Checked",
                                    changeProp: true,
                                  },
                                ],

                                changeProp: true,
                                "script-props": [
                                  "Autofocus",
                                  "Disabled",
                                  "Required",
                                  "Checked",
                                ],
                              },
                              {
                                tagName: "label",
                                type: "checkbox_label_new",
                                attributes: {
                                  for: ``,
                                },

                                components: [
                                  {
                                    tagName: "p",
                                    type: "text",
                                    editable: true,

                                    style: {
                                      "word-break": "break-all",
                                    },

                                    "style-default": {
                                      "word-break": "break-all",
                                    },

                                    components: {
                                      type: "textnode",
                                      content: `${opt[`${ArrOfObjKyename}`]}`,
                                    },
                                  },
                                ],

                                traits: ["id", "for"],
                              },
                            ],
                          };

                          // let inputEle = {
                          //   tagName: "input",
                          //   type: "default", // so that the input component doesn't throw error of 'undefined'

                          //   script: individualCheckboxScript,

                          //   attributes: {
                          //     type: "checkbox",
                          //     // id: `${opt[`${ArrOfObjKyename}`]}`,
                          //     name: `${opt[`${ArrOfObjKyename}`]}`,
                          //     value: `${opt[`${ArrOfObjKyename}`]}`,
                          //   },

                          //   Autofocus: false,
                          //   Disabled: false,
                          //   Required: false,
                          //   Checked: false,

                          //   traits: [
                          //     "id",
                          //     "name",
                          //     "value",
                          //     {
                          //       type: "checkbox",
                          //       name: "Autofocus",
                          //       label: "Autofocus",
                          //       changeProp: true,
                          //     },
                          //     {
                          //       type: "checkbox",
                          //       name: "Disabled",
                          //       label: "Disabled",
                          //       changeProp: true,
                          //     },
                          //     {
                          //       type: "checkbox",
                          //       name: "Required",
                          //       label: "Required",
                          //       changeProp: true,
                          //     },
                          //     {
                          //       type: "checkbox",
                          //       name: "Checked",
                          //       label: "Checked",
                          //       changeProp: true,
                          //     },
                          //   ],

                          //   changeProp: true,
                          //   "script-props": [
                          //     "Autofocus",
                          //     "Disabled",
                          //     "Required",
                          //     "Checked",
                          //   ],
                          // };

                          // let labelEle = {
                          //   tagName: "label",
                          //   attributes: {
                          //     for: ``,
                          //   },

                          //   components: [
                          //     {
                          //       tagName: "p",
                          //       type: "text",
                          //       editable: true,

                          //       components: {
                          //         type: "textnode",
                          //         content: `${opt[`${ArrOfObjKyename}`]}`,
                          //       },
                          //     },
                          //   ],

                          //   traits: ["id", "for"],
                          // };

                          // container.append(inputEle);
                          // container.append(labelEle);
                          container.append(groupEle);
                        });
                      }
                    }

                    // dynamic delete
                    if (container.components().length > data.length) {
                      console.log("removing extra group_checkbox_labels");

                      let groupCheckboxLabels = container.find(
                        ".group_checkbox_label"
                      );
                      groupCheckboxLabels.forEach((groupCheckboxLabel) => {
                        // get the data_db_url from checkbox and check if _id is present in apiData, if not, remove it
                        let checkbox = groupCheckboxLabel
                          .find(".checkbox_input_new")[0]
                          .find('input[type="checkbox"]')[0];
                        let checkboxDataDbId =
                          checkbox.getAttributes()["data_db_id"];

                        // see if checkboxDataDbId is in apiData _ids and if not there, delete this group_checkbox_label
                        let apiData_id = data.map((obj) => obj["_id"]);

                        if (!apiData_id.includes(checkboxDataDbId)) {
                          groupCheckboxLabel.remove();
                          console.log("removed");
                        }
                      });
                    }
                  }
                } else if (NestedKeyname && !ArrOfObjKyename) {
                  // container.components("");

                  // find the array value
                  let data = findKeyValue(apiData, NestedKeyname);

                  // if API response to plot is a nested plain array of single elements inside the main response object
                  if (Array.isArray(data) && !isArrayofObjects(data)) {
                    console.log("#####");

                    // either no checkboxes are plotted or the default 2 checkboxes are there
                    if (
                      container.components().length === 0 ||
                      container.components().length <= 2
                    ) {
                      container.components("");

                      data.forEach((opt) => {
                        let groupEle = {
                          tagName: "span",
                          type: "default",
                          attributes: {
                            class: "group_checkbox_label",
                          },

                          "style-default": {
                            display: "flex",
                            "flex-wrap": "no-wrap",
                            "justify-content": "flex-start",
                            "align-items": "flex-start",
                            width: CommonWidth
                              ? CommonWidth + "%"
                              : "8% !important",
                          },

                          style: {
                            display: "flex",
                            "flex-wrap": "no-wrap",
                            "justify-content": "flex-start",
                            "align-items": "flex-start",
                            width: CommonWidth
                              ? CommonWidth + "%"
                              : "8% !important",
                          },

                          components: [
                            {
                              tagName: "span",
                              type: "checkbox_input_new",
                              script: individualCheckboxScriptNew,

                              attributes: {
                                class: "checkbox_input_new",
                              },

                              components: [
                                {
                                  tagName: "input",
                                  type: "default",
                                  attributes: {
                                    type: "checkbox",
                                    id: `checkbox_1_${uniqid()}`,
                                    name: `checkbox_1_${uniqid()}`,
                                    value: `${opt}`,
                                  },
                                },
                              ],

                              Autofocus: false,
                              Disabled: false,
                              Required: false,
                              Checked: false,

                              traits: [
                                "id",
                                "name",
                                "value",
                                {
                                  type: "checkbox",
                                  name: "Autofocus",
                                  label: "Autofocus",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Disabled",
                                  label: "Disabled",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Required",
                                  label: "Required",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Checked",
                                  label: "Checked",
                                  changeProp: true,
                                },
                              ],

                              changeProp: true,
                              "script-props": [
                                "Autofocus",
                                "Disabled",
                                "Required",
                                "Checked",
                              ],
                            },
                            {
                              tagName: "label",
                              type: "checkbox_label_new",
                              attributes: {
                                for: ``,
                              },

                              components: [
                                {
                                  tagName: "p",
                                  type: "text",
                                  editable: true,

                                  style: {
                                    "word-break": "break-all",
                                  },

                                  "style-default": {
                                    "word-break": "break-all",
                                  },

                                  components: {
                                    type: "textnode",
                                    content: `${opt}`,
                                  },
                                },
                              ],

                              traits: ["id", "for"],
                            },
                          ],
                        };

                        // let inputEle = {
                        //   tagName: "input",
                        //   type: "default", // so that the input component doesn't throw error of 'undefined'

                        //   script: individualCheckboxScript,

                        //   attributes: {
                        //     type: "checkbox",
                        //     // id: `ch-${opt}`,
                        //     name: `ch-${opt}`,
                        //     value: `${opt}`,
                        //   },

                        //   Autofocus: false,
                        //   Disabled: false,
                        //   Required: false,
                        //   Checked: false,

                        //   traits: [
                        //     "id",
                        //     "name",
                        //     "value",
                        //     {
                        //       type: "checkbox",
                        //       name: "Autofocus",
                        //       label: "Autofocus",
                        //       changeProp: true,
                        //     },
                        //     {
                        //       type: "checkbox",
                        //       name: "Disabled",
                        //       label: "Disabled",
                        //       changeProp: true,
                        //     },
                        //     {
                        //       type: "checkbox",
                        //       name: "Required",
                        //       label: "Required",
                        //       changeProp: true,
                        //     },
                        //     {
                        //       type: "checkbox",
                        //       name: "Checked",
                        //       label: "Checked",
                        //       changeProp: true,
                        //     },
                        //   ],

                        //   changeProp: true,
                        //   "script-props": [
                        //     "Autofocus",
                        //     "Disabled",
                        //     "Required",
                        //     "Checked",
                        //   ],
                        // };

                        // let labelEle = {
                        //   tagName: "label",
                        //   attributes: {
                        //     for: ``,
                        //   },

                        //   components: [
                        //     {
                        //       tagName: "p",
                        //       type: "text",
                        //       editable: true,

                        //       components: {
                        //         type: "textnode",
                        //         content: `${opt}`,
                        //       },
                        //     },
                        //   ],

                        //   traits: ["id", "for"],
                        // };

                        // container.append(inputEle);
                        // container.append(labelEle);
                        container.append(groupEle);
                      });
                    }

                    // else if the apiData checkboxes are already plotted then check for updated values and add those here without changing the IDd of already plotted ones
                    else if (
                      data.length > container.components().length &&
                      container.components().length > 2
                    ) {
                      // only add what's missing from api as a dynamic update

                      // get all the labels' inner content and compare it with the keyname of the apiData to be plotted and add the ones missing
                      let allLabelEles = container.find(
                        'label[data-gjs-type="checkbox_label_new"]'
                      );
                      let allLabels = allLabelEles.map(
                        (label) =>
                          label.find('p[data-gjs-type="text"]')[0].getEl()
                            .innerHTML
                      );
                      console.log("allLabelEles", allLabelEles, allLabels);

                      if (allLabelEles.length > 0) {
                        // if you find a label thats not in the apiData then add it to the existing checkbox container
                        data.forEach((opt, idx) => {
                          let groupEle = {
                            tagName: "span",
                            type: "default",
                            attributes: {
                              class: "group_checkbox_label",
                            },

                            "style-default": {
                              display: "flex",
                              "flex-wrap": "no-wrap",
                              "justify-content": "flex-start",
                              "align-items": "flex-start",
                              width: CommonWidth
                                ? CommonWidth + "%"
                                : "8% !important",
                            },

                            style: {
                              display: "flex",
                              "flex-wrap": "no-wrap",
                              "justify-content": "flex-start",
                              "align-items": "flex-start",
                              width: CommonWidth
                                ? CommonWidth + "%"
                                : "8% !important",
                            },

                            components: [
                              {
                                tagName: "span",
                                type: "checkbox_input_new",
                                script: individualCheckboxScriptNew,

                                attributes: {
                                  class: "checkbox_input_new",
                                },

                                components: [
                                  {
                                    tagName: "input",
                                    type: "default",
                                    attributes: {
                                      type: "checkbox",
                                      id: `checkbox_1_${uniqid()}`,
                                      name: `checkbox_1_${uniqid()}`,
                                      value: `${opt}`,
                                    },
                                  },
                                ],

                                Autofocus: false,
                                Disabled: false,
                                Required: false,
                                Checked: false,

                                traits: [
                                  "id",
                                  "name",
                                  "value",
                                  {
                                    type: "checkbox",
                                    name: "Autofocus",
                                    label: "Autofocus",
                                    changeProp: true,
                                  },
                                  {
                                    type: "checkbox",
                                    name: "Disabled",
                                    label: "Disabled",
                                    changeProp: true,
                                  },
                                  {
                                    type: "checkbox",
                                    name: "Required",
                                    label: "Required",
                                    changeProp: true,
                                  },
                                  {
                                    type: "checkbox",
                                    name: "Checked",
                                    label: "Checked",
                                    changeProp: true,
                                  },
                                ],

                                changeProp: true,
                                "script-props": [
                                  "Autofocus",
                                  "Disabled",
                                  "Required",
                                  "Checked",
                                ],
                              },
                              {
                                tagName: "label",
                                type: "checkbox_label_new",
                                attributes: {
                                  for: ``,
                                },

                                components: [
                                  {
                                    tagName: "p",
                                    type: "text",
                                    editable: true,

                                    style: {
                                      "word-break": "break-all",
                                    },

                                    "style-default": {
                                      "word-break": "break-all",
                                    },

                                    components: {
                                      type: "textnode",
                                      content: `${opt}`,
                                    },
                                  },
                                ],

                                traits: ["id", "for"],
                              },
                            ],
                          };

                          // let inputEle = {
                          //   tagName: "input",
                          //   type: "default", // so that the input component doesn't throw error of 'undefined'

                          //   script: individualCheckboxScript,

                          //   attributes: {
                          //     type: "checkbox",
                          //     // id: `ch-${opt}`,
                          //     name: `ch-${opt}`,
                          //     value: `${opt}`,
                          //   },

                          //   Autofocus: false,
                          //   Disabled: false,
                          //   Required: false,
                          //   Checked: false,

                          //   traits: [
                          //     "id",
                          //     "name",
                          //     "value",
                          //     {
                          //       type: "checkbox",
                          //       name: "Autofocus",
                          //       label: "Autofocus",
                          //       changeProp: true,
                          //     },
                          //     {
                          //       type: "checkbox",
                          //       name: "Disabled",
                          //       label: "Disabled",
                          //       changeProp: true,
                          //     },
                          //     {
                          //       type: "checkbox",
                          //       name: "Required",
                          //       label: "Required",
                          //       changeProp: true,
                          //     },
                          //     {
                          //       type: "checkbox",
                          //       name: "Checked",
                          //       label: "Checked",
                          //       changeProp: true,
                          //     },
                          //   ],

                          //   changeProp: true,
                          //   "script-props": [
                          //     "Autofocus",
                          //     "Disabled",
                          //     "Required",
                          //     "Checked",
                          //   ],
                          // };

                          // let labelEle = {
                          //   tagName: "label",
                          //   attributes: {
                          //     for: ``,
                          //   },

                          //   components: [
                          //     {
                          //       tagName: "p",
                          //       type: "text",
                          //       editable: true,

                          //       components: {
                          //         type: "textnode",
                          //         content: `${opt}`,
                          //       },
                          //     },
                          //   ],

                          //   traits: ["id", "for"],
                          // };

                          // container.append(inputEle);
                          // container.append(labelEle);
                          container.append(groupEle);
                        });
                      }
                    }
                  }

                  // if API response to plot are all key:value pairs of a plain object inside main object
                  else if (
                    !Array.isArray(data) &&
                    !isArrayofObjects(data) &&
                    typeof data === "object"
                  ) {
                    console.log("######");
                    Object.keys(data).forEach((key) => {
                      if (
                        !Array.isArray(data[`${key}`]) &&
                        typeof data[`${key}`] !== "object"
                      ) {
                        let groupEle = {
                          tagName: "span",
                          type: "default",
                          attributes: {
                            class: "group_checkbox_label",
                          },

                          "style-default": {
                            display: "flex",
                            "flex-wrap": "no-wrap",
                            "justify-content": "flex-start",
                            "align-items": "flex-start",
                            width: CommonWidth
                              ? CommonWidth + "%"
                              : "8% !important",
                          },

                          style: {
                            display: "flex",
                            "flex-wrap": "no-wrap",
                            "justify-content": "flex-start",
                            "align-items": "flex-start",
                            width: CommonWidth
                              ? CommonWidth + "%"
                              : "8% !important",
                          },

                          components: [
                            {
                              tagName: "span",
                              type: "checkbox_input_new",
                              script: individualCheckboxScriptNew,

                              attributes: {
                                class: "checkbox_input_new",
                              },

                              components: [
                                {
                                  tagName: "input",
                                  type: "default",
                                  attributes: {
                                    type: "checkbox",
                                    id: `checkbox_1_${uniqid()}`,
                                    name: `checkbox_1_${uniqid()}`,
                                    value: `${data[`${key}`]}`,
                                  },
                                },
                              ],

                              Autofocus: false,
                              Disabled: false,
                              Required: false,
                              Checked: false,

                              traits: [
                                "id",
                                "name",
                                "value",
                                {
                                  type: "checkbox",
                                  name: "Autofocus",
                                  label: "Autofocus",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Disabled",
                                  label: "Disabled",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Required",
                                  label: "Required",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Checked",
                                  label: "Checked",
                                  changeProp: true,
                                },
                              ],

                              changeProp: true,
                              "script-props": [
                                "Autofocus",
                                "Disabled",
                                "Required",
                                "Checked",
                              ],
                            },
                            {
                              tagName: "label",
                              type: "checkbox_label_new",
                              attributes: {
                                for: ``,
                              },

                              components: [
                                {
                                  tagName: "p",
                                  type: "text",
                                  editable: true,

                                  style: {
                                    "word-break": "break-all",
                                  },

                                  "style-default": {
                                    "word-break": "break-all",
                                  },

                                  components: {
                                    type: "textnode",
                                    content: `${key}`,
                                  },
                                },
                              ],

                              traits: ["id", "for"],
                            },
                          ],
                        };

                        // let inputEle = {
                        //   tagName: "input",
                        //   type: "default", // so that the input component doesn't throw error of 'undefined'

                        //   script: individualCheckboxScript,

                        //   attributes: {
                        //     type: "checkbox",
                        //     // id: `${data[`${key}`]}`,
                        //     name: `${data[`${key}`]}`,
                        //     value: `${data[`${key}`]}`,
                        //   },

                        //   Autofocus: false,
                        //   Disabled: false,
                        //   Required: false,
                        //   Checked: false,

                        //   traits: [
                        //     "id",
                        //     "name",
                        //     "value",
                        //     {
                        //       type: "checkbox",
                        //       name: "Autofocus",
                        //       label: "Autofocus",
                        //       changeProp: true,
                        //     },
                        //     {
                        //       type: "checkbox",
                        //       name: "Disabled",
                        //       label: "Disabled",
                        //       changeProp: true,
                        //     },
                        //     {
                        //       type: "checkbox",
                        //       name: "Required",
                        //       label: "Required",
                        //       changeProp: true,
                        //     },
                        //     {
                        //       type: "checkbox",
                        //       name: "Checked",
                        //       label: "Checked",
                        //       changeProp: true,
                        //     },
                        //   ],

                        //   changeProp: true,
                        //   "script-props": [
                        //     "Autofocus",
                        //     "Disabled",
                        //     "Required",
                        //     "Checked",
                        //   ],
                        // };

                        // let labelEle = {
                        //   tagName: "label",
                        //   attributes: {
                        //     for: ``,
                        //   },

                        //   components: [
                        //     {
                        //       tagName: "p",
                        //       type: "text",
                        //       editable: true,

                        //       components: {
                        //         type: "textnode",
                        //         content: `${key}`,
                        //       },
                        //     },
                        //   ],

                        //   traits: ["id", "for"],
                        // };

                        // container.append(inputEle);
                        // container.append(labelEle);
                        container.append(groupEle);
                      }
                    });
                  }
                }

                // if API response to plot are all key:value pairs of the plain object
                else if (!NestedKeyname && !ArrOfObjKyename) {
                  console.log("#######");
                  container.components("");

                  Object.keys(apiData).forEach((key) => {
                    if (
                      !Array.isArray(apiData[`${key}`]) &&
                      typeof apiData[`${key}`] !== "object"
                    ) {
                      let groupEle = {
                        tagName: "span",
                        type: "default",
                        attributes: {
                          class: "group_checkbox_label",
                        },

                        "style-default": {
                          display: "flex",
                          "flex-wrap": "no-wrap",
                          "justify-content": "flex-start",
                          "align-items": "flex-start",
                          width: CommonWidth
                            ? CommonWidth + "%"
                            : "8% !important",
                        },

                        style: {
                          display: "flex",
                          "flex-wrap": "no-wrap",
                          "justify-content": "flex-start",
                          "align-items": "flex-start",
                          width: CommonWidth
                            ? CommonWidth + "%"
                            : "8% !important",
                        },

                        components: [
                          {
                            tagName: "span",
                            type: "checkbox_input_new",
                            script: individualCheckboxScriptNew,

                            attributes: {
                              class: "checkbox_input_new",
                            },

                            components: [
                              {
                                tagName: "input",
                                type: "default",
                                attributes: {
                                  type: "checkbox",
                                  id: `checkbox_1_${uniqid()}`,
                                  name: `checkbox_1_${uniqid()}`,
                                  value: `${apiData[`${key}`]}`,
                                },
                              },
                            ],

                            Autofocus: false,
                            Disabled: false,
                            Required: false,
                            Checked: false,

                            traits: [
                              "id",
                              "name",
                              "value",
                              {
                                type: "checkbox",
                                name: "Autofocus",
                                label: "Autofocus",
                                changeProp: true,
                              },
                              {
                                type: "checkbox",
                                name: "Disabled",
                                label: "Disabled",
                                changeProp: true,
                              },
                              {
                                type: "checkbox",
                                name: "Required",
                                label: "Required",
                                changeProp: true,
                              },
                              {
                                type: "checkbox",
                                name: "Checked",
                                label: "Checked",
                                changeProp: true,
                              },
                            ],

                            changeProp: true,
                            "script-props": [
                              "Autofocus",
                              "Disabled",
                              "Required",
                              "Checked",
                            ],
                          },
                          {
                            tagName: "label",
                            type: "checkbox_label_new",
                            attributes: {
                              for: ``,
                            },

                            components: [
                              {
                                tagName: "p",
                                type: "text",
                                editable: true,

                                style: {
                                  "word-break": "break-all",
                                },

                                "style-default": {
                                  "word-break": "break-all",
                                },

                                components: {
                                  type: "textnode",
                                  content: `${key}`,
                                },
                              },
                            ],

                            traits: ["id", "for"],
                          },
                        ],
                      };

                      // let inputEle = {
                      //   tagName: "input",
                      //   type: "default", // so that the input component doesn't throw error of 'undefined'

                      //   script: individualCheckboxScript,

                      //   attributes: {
                      //     type: "checkbox",
                      //     // id: `${apiData[`${key}`]}`,
                      //     name: `${apiData[`${key}`]}`,
                      //     value: `${apiData[`${key}`]}`,
                      //   },

                      //   Autofocus: false,
                      //   Disabled: false,
                      //   Required: false,
                      //   Checked: false,

                      //   traits: [
                      //     "id",
                      //     "name",
                      //     "value",
                      //     {
                      //       type: "checkbox",
                      //       name: "Autofocus",
                      //       label: "Autofocus",
                      //       changeProp: true,
                      //     },
                      //     {
                      //       type: "checkbox",
                      //       name: "Disabled",
                      //       label: "Disabled",
                      //       changeProp: true,
                      //     },
                      //     {
                      //       type: "checkbox",
                      //       name: "Required",
                      //       label: "Required",
                      //       changeProp: true,
                      //     },
                      //     {
                      //       type: "checkbox",
                      //       name: "Checked",
                      //       label: "Checked",
                      //       changeProp: true,
                      //     },
                      //   ],

                      //   changeProp: true,
                      //   "script-props": [
                      //     "Autofocus",
                      //     "Disabled",
                      //     "Required",
                      //     "Checked",
                      //   ],
                      // };

                      // let labelEle = {
                      //   tagName: "label",
                      //   attributes: {
                      //     for: ``,
                      //   },

                      //   components: [
                      //     {
                      //       tagName: "p",
                      //       type: "text",
                      //       editable: true,

                      //       components: {
                      //         type: "textnode",
                      //         content: `${key}`,
                      //       },
                      //     },
                      //   ],

                      //   traits: ["id", "for"],
                      // };

                      // container.append(inputEle);
                      // container.append(labelEle);
                      container.append(groupEle);
                    }
                  });
                }

                // if there is a 'message' in api response instead of data
                else if (Object.keys(apiData).includes('message')) {
                    console.log("#@@");

                    container.components("");
                    container.components().push({
                      tagName: "span",
                      type: "default",
                      attributes: {
                        class: "group_checkbox_label",
                      },

                      "style-default": {
                        display: "flex",
                        "flex-wrap": "no-wrap",
                        "justify-content": "flex-start",
                        "align-items": "flex-start",
                        width: "8%",
                      },

                      style: {
                        display: "flex",
                        "flex-wrap": "no-wrap",
                        "justify-content": "flex-start",
                        "align-items": "flex-start",
                        width: "8%",
                      },

                      components: [
                        {
                          tagName: "label",
                          type: "checkbox_label_new",
                          attributes: {
                            for: "",
                          },

                          components: [
                            {
                              tagName: "p",
                              type: "text",
                              editable: true,

                              style: {
                                width: "max-content",
                                "word-break": "break-all",
                              },

                              "style-default": {
                                width: "max-content",
                                "word-break": "break-all",
                              },

                              components: {
                                type: "textnode",
                                content: "No Values.",
                              },
                            },
                          ],

                          traits: ["id", "for"],
                          changeProp: true,
                        },
                        {
                          tagName: "span",
                          type: "checkbox_input_new",

                          attributes: {
                            class: "checkbox_input_new",
                          },

                          style: {
                            "margin-right": "5%",
                            display: "none",
                          },

                          "style-default": {
                            "margin-right": "5%",
                            display: "none",
                          },

                          components: [
                            {
                              tagName: "input",
                              type: "default",
                              attributes: {
                                type: "checkbox",
                                // id: `checkbox_1_${uniqid()}`,
                                name: `checkbox_1_${uniqid()}`,
                                value: "checkbox_1",
                              },
                            },
                          ],
                        },
                      ],
                    });
                }
              }
            }
          },
        },

        view: {
          onRender({ el, model }) {
            if (
              el.getAttribute("data-gjs-type") === "checkbox_regular_form_new"
            ) {
              console.log("rendering", el);
              model.handlePropChange();
            }
          },
        },
      });

      const checkboxRegularFormSingleScript = async function (props) {
        let container = this;

        let idEl = this.id;
        const urlDownload = window.top.location.href;
        const isSubstringPresent =
          (urlDownload.indexOf("/editor/") !== -1 &&
            urlDownload.indexOf("?projectId=") !== -1) ||
            urlDownload.indexOf("/large_preview/") !== -1 ||
            urlDownload.indexOf("/tab_preview/") !== -1 ||
            urlDownload.indexOf("/mobile_preview/") !== -1 ||
            urlDownload.indexOf("/fragment_editor/") !== -1;
        
        // to update table props as soon as window is updated and then trigger rendering
        if (!isSubstringPresent) {
          setInterval(function () {
            Object.keys(props).forEach(function (key) {
              if (window[`${key}${idEl}`]) {
                if(props[key] === window[`${key}${idEl}`]) {
                  // console.log('');
                }else{
                  props[key] = window[`${key}${idEl}`];
                  initLib(props);
                }
              }
            });
          }, 500);
        }

        function initLib(props) {
          let checkboxEle = container.getElementsByClassName("checkbox")[0];

          if (checkboxEle) {
            checkboxEle.setAttribute('name', props["name"]);
            checkboxEle.setAttribute('value', props["value"]);
            checkboxEle.setAttribute('title', props["title"]);

            if (props.checked) checkboxEle.setAttribute('checked', 'checked');
            else checkboxEle.removeAttribute("checked");

            if (props.required) checkboxEle.setAttribute('required', 'required');
            else checkboxEle.removeAttribute("required");
          }
        }
      };

      editor.DomComponents.addType("checkbox_regular_form_single", {
        model: {
          defaults: {
            tagName: "div",
            script: checkboxRegularFormSingleScript,
            attributes: {
              class: "checkbox_regular_form_single",
            },

            name: "checkbox",
            value: "checkbox",
            checked: false,
            required: false,

            style: {
              width: "max-content",
            },

            "style-default": {
              width: "max-content",
            },

            components: [
              {
                tagName: "input",
                type: "checkbox",
                attributes: {
                  type: "checkbox",
                  class: "checkbox",
                  name: "checkbox",
                  value: "checkbox",
                },

                traits: ["id", "name", "value"],
              }, {
                tagName: "label",
                attributes: {
                  class: "label",
                  for: "",
                },

                components: [
                  {
                    tagName: "p",
                    type: "text",
                    editable: true,
    
                    style: {
                      width: "max-content",
                      "word-break": "break-all",
                    },
    
                    "style-default": {
                      width: "max-content",
                      "word-break": "break-all",
                    },
    
                    components: {
                      type: "textnode",
                      content: "checkbox",
                    },
                  },
                ],
                traits: ["for"],
              },
            ],

            traits: [
              "id",
              "title",
              {
                type: "text",
                name: "name",
                label: "Name",
                changeProp: true,
              },
              {
                type: "text",
                name: "value",
                label: "value",
                changeProp: true,
              },
              {
                type: "checkbox",
                name: "checked",
                label: "checked",
                changeProp: true,
              },
              {
                type: "checkbox",
                name: "required",
                label: "required",
                changeProp: true,
              },
            ],
            
            "script-props": ["id", "title", "name", "value", "checked", "required"],
            
            changeProp: true,
          },

          init() {
            // Assign a persistent ID to the input child component
            const inputComponent = this.get("components").at(0); // First child is the input
            const labelComponent = this.get("components").at(1); // Second child is the label

            if (inputComponent && inputComponent.get("type") === "checkbox") {
              const currentId = inputComponent.getAttributes().id;
              let persistentId = currentId;

              // If the ID is not already set, generate a deterministic one
              if (!currentId) {
                persistentId = `checkbox_${this.cid}`; // Generate a unique ID
                inputComponent.addAttributes({ id: persistentId });
              }

              // Update the label's "for" attribute with the same ID
              if (labelComponent) {
                const labelAttributes = labelComponent.getAttributes();
                const currentFor = labelAttributes.for;
                if (!currentFor || currentFor !== persistentId) {
                  labelComponent.addAttributes({ for: persistentId });
                }
              }
            }

            this.on("change:name", this.nameChangeHandler);
            this.on("change:value", this.valueChangeHandler);
            this.on("change:checked", this.checkedChangeHandler);
            this.on("change:required", this.requiredChangeHandler);
          },

          nameChangeHandler() {
            let {name} = this.props();
            let checkboxComp = this.find("input[type='checkbox']")[0];
            
            if (checkboxComp) {
              checkboxComp.addAttributes({
                name,
              });
            }
          },

          valueChangeHandler() {
            let {value} = this.props();
            let checkboxComp = this.find("input[type='checkbox']")[0];
            
            if (checkboxComp) {
              checkboxComp.addAttributes({
                value,
              });
            }
          },

          checkedChangeHandler() {
            let {checked} = this.props();
            let checkboxComp = this.find("input[type='checkbox']")[0];
            
            if (checkboxComp) {
              if (checked) {
                checkboxComp.addAttributes({
                  checked: "checked",
                });
              } else {
                checkboxComp.removeAttributes('checked');
              }
            }
          },

          requiredChangeHandler() {
            let {required} = this.props();
            let checkboxComp = this.find("input[type='checkbox']")[0];
            
            if (checkboxComp) {
              if (required) {
                checkboxComp.addAttributes({
                  required: "required",
                });
              } else {
                checkboxComp.removeAttributes('required');
              }
            }
          },
        },
      });

      const individualRadioScript = function (props) {
        console.log("this element:", this, props);

        let container = this.getElementsByTagName("input")[0];

        if (props.Autofocus) {
          container.setAttribute("autofocus", true);
        }

        if (props.Disabled) {
          container.setAttribute("disabled", true);
        }

        if (props.Required) {
          container.setAttribute("required", true);
        }

        if (props.Checked) {
          container.setAttribute("checked", true);
        }

        if (props.Value) {
          container.setAttribute("value", props.Value);
        }

        if (props.Name) {
            console.log('radio', container);
          container.setAttribute("name", props.Name);
        }

        let wrapperNew = this.parentNode.parentNode;
        let inputs = wrapperNew.querySelectorAll("input");

        function generateRandomString(length) {
          const characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
          let result = "";
          for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            result += characters.charAt(randomIndex);
          }
          return result;
        }

        // Example usage
        // const randomString = generateRandomString(6); // Generates a random string of length 10

        // Iterate over each input element and change its name attribute to 'radio'
        // inputs.forEach((input) => {
        //   input.setAttribute("name", `${randomString}`);
        // });

        container.addEventListener("change", function () {
          console.log("wrapper:::::", this.parentNode.parentNode);
          let wrapper = this.parentNode.parentNode;
          let mainDiv = this.parentNode.parentNode.parentNode;
          console.log("mainDiv", mainDiv);

          console.log("datadbid::::", container.getAttribute("data_db_id"));

          if (container.checked) {
            wrapper.setAttribute("value", container.value);
            wrapper.setAttribute(
              "data_db_id",
              container.getAttribute("data_db_id")
            );

            mainDiv.setAttribute("value", container.value);
            mainDiv.setAttribute(
              "data_db_id",
              container.getAttribute("data_db_id")
            );
          }
        });
      };

      editor.DomComponents.addType("radio_input", {
        model: {
          defaults: {
            tagName: "span",
            type: "radio_input",
            script: individualRadioScript,

            attributes: {
              class: "radio_input",
            },

            style: {
              "margin-right": "5%",
            },

            "style-default": {
              "margin-right": "5%",
            },

            components: [
              {
                tagName: "input",
                type: "default",
                attributes: {
                  type: "radio",
                  id: `radio_1_${uniqid()}`,
                  name: "radio_1",
                  value: "radio_1",
                },
              },
            ],

            Autofocus: false,
            Disabled: false,
            Required: false,
            Checked: false,
            Name: "radio_1",

            traits: [
              {
                type: "checkbox",
                name: "Autofocus",
                label: "Autofocus",
                changeProp: true,
              },
              {
                type: "checkbox",
                name: "Disabled",
                label: "Disabled",
                changeProp: true,
              },
              {
                type: "checkbox",
                name: "Required",
                label: "Required",
                changeProp: true,
              },
              {
                type: "checkbox",
                name: "Checked",
                label: "Checked",
                changeProp: true,
              },
              {
                type: "text",
                name: "Value",
                label: "Value",
                changeProp: true,
              },
              {
                type: "text",
                name: "Name",
                label: "Name",
                changeProp: true,
              },
              "id",
            ],

            changeProp: true,
            "script-props": [
              "Value",
              "Autofocus",
              "Disabled",
              "Required",
              "Checked",
              "Name",
            ],
          },
        },
      });

      editor.DomComponents.addType("radio_label", {
        model: {
          defaults: {
            tagName: "label",
            type: "radio_label",
            attributes: {
              for: "",
            },

            components: [
              {
                tagName: "p",
                type: "text",
                editable: true,

                style: {
                  width: "max-content",
                  "word-break": "break-all",
                },

                "style-default": {
                  width: "max-content",
                  "word-break": "break-all",
                },

                components: {
                  type: "textnode",
                  content: "radio_1",
                },
              },
            ],

            traits: ["id", "for"],
            changeProp: true,
          },
        },
      });

      // for run-time replication
      const radioRegularFormScript = async function (props) {
        console.log(
          "radio_regular_form_new props",
          props,
          this,
          this.id,
          this.childNodes.length
        );

        // For LB download manipulation
        // checking if the url or script is not running in the redsling editor, only then run the script to plot checkboxes
        let idEl = this.id;
        const urlDownload = window.top.location.href;
        const isSubstringPresent =
          (urlDownload.indexOf("/editor/") !== -1 &&
            urlDownload.indexOf("?projectId=") !== -1) ||
          urlDownload.indexOf("/large_preview/") !== -1 ||
          urlDownload.indexOf("/tab_preview/") !== -1 ||
          urlDownload.indexOf("/mobile_preview/") !== -1 ||
          urlDownload.indexOf("/fragment_editor/") !== -1;
        if (!isSubstringPresent) {
          setInterval(function () {
            Object.keys(props).forEach(function (key) {
              if (window[`${key}${idEl}`]) {
                props[key] = window[`${key}${idEl}`];
              }
            });
          }, 500);
        }

        // base url bearer token taken directly from local storage after getting downloaded
        let baseUrlData,
          dynamicBaseUrlBearerToken,
          baseUrl1BearerToken,
          baseUrl2BearerToken,
          baseUrl3BearerToken,
          baseUrl4BearerToken,
          baseUrl5BearerToken;
        // getting baseUrl data from local storage for run-time access
        let baseUrlDataFromStorage = localStorage.getItem("baseUrlData");
        if (baseUrlDataFromStorage) {
          baseUrlData = JSON.parse(baseUrlDataFromStorage);
        }
        // extracting token values from base URLs from baseUrlData
        if (baseUrlData) {
          try {
            if (`baseUrl1_${props.projectId}` in baseUrlData) {
              baseUrl1BearerToken =
                baseUrlData[`baseUrl1_${props.projectId}`].token;
            }
            if (`baseUrl2_${props.projectId}` in baseUrlData) {
              baseUrl2BearerToken =
                baseUrlData[`baseUrl2_${props.projectId}`].token;
            }
            if (`baseUrl3_${props.projectId}` in baseUrlData) {
              baseUrl3BearerToken =
                baseUrlData[`baseUrl3_${props.projectId}`].token;
            }
            if (`baseUrl4_${props.projectId}` in baseUrlData) {
              baseUrl4BearerToken =
                baseUrlData[`baseUrl4_${props.projectId}`].token;
            }
            if (`baseUrl5_${props.projectId}` in baseUrlData) {
              baseUrl5BearerToken =
                baseUrlData[`baseUrl5_${props.projectId}`].token;
            }
          } catch (e) {
            // statements
            console.log(e);
          }
        }

        // helper function to check if a variable is an array-of-objects or not
        function isArrayofObjects(variable) {
          // Check if the variable is an array
          if (!Array.isArray(variable)) {
            return false;
          }

          // Check if all elements in the array are objects
          for (const element of variable) {
            if (
              typeof element !== "object" ||
              Array.isArray(element) ||
              element === null
            ) {
              return false;
            }
          }
          return true;
        }

        // helper function to get value of a key in nested object
        function findKeyValue(obj, key) {
          if (obj.hasOwnProperty(key)) {
            // If the current object has the key, return its value
            return obj[key];
          }

          for (var prop in obj) {
            if (obj.hasOwnProperty(prop) && typeof obj[prop] === "object") {
              // If the current property is an object, recursively search within it
              var result = findKeyValue(obj[prop], key);
              if (result !== undefined) {
                return result;
              }
            }
          }
          // Key not found in the object
          return undefined;
        }

        // helper function to create an ID string with characters randomly
        function generateRandomString(length) {
          const characters =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
          let result = "";
          for (let i = 0; i < length; i++) {
            result += characters.charAt(
              Math.floor(Math.random() * characters.length)
            );
          }
          return result;
        }

        let mainContainer = this;
        let apiData, API;

        if (props.APIPath) {
          API = props.APIPath;
        }

        // dynamic addition and deletion for the downloaded code
        if (!isSubstringPresent) {
          // -------- Getting API Data
          // if CustomUrl is provided, use only that
          if (
            props.CustomUrl &&
            (!props.BaseUrl || props.BaseUrl === "null") &&
            props.APIMethod !== "POST"
          ) {
            // if nothing is provided for auth
            if (!props.BearerToken && !props.Username && !props.Password) {
              // add extra headers, if needed
              let config = {
                headers: {},
              };

              if (props.MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                props.MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${props.CustomUrl}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token is provided but not api_key or usr+pwd
            else if (props.BearerToken && !props.Username && !props.Password) {
              // add extra headers, if needed
              let config = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${props.BearerToken}`,
                },
              };

              if (props.MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                props.MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${props.CustomUrl}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token and api_key not provided but usr+pwd are provided
            else if (!props.BearerToken && props.Username && props.Password) {
              // add extra headers, if needed
              let config = {
                headers: {
                  Authorization:
                    "Basic " + btoa(props.Username + ":" + props.Password),
                },
              };

              if (props.MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                props.MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${props.CustomUrl}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }
          }

          // if base url is the choice and provided
          else if (
            props.BaseUrl &&
            API &&
            !props.CustomUrl &&
            props.APIMethod !== "POST"
          ) {
            // concatenating base url and its remaining part
            let url, baseUrlBearerToken;
            let forDownloadUrl;
            if (props.BaseUrl === "baseUrl1") {
              url = props.options.baseUrl1 + API;
              forDownloadUrl = "{{API_URL1}}" + API;
              baseUrlBearerToken = props.options.baseUrl1BearerToken;
              dynamicBaseUrlBearerToken = baseUrl1BearerToken;
            } else if (props.BaseUrl === "baseUrl2") {
              url = props.options.baseUrl2 + API;
              forDownloadUrl = "{{API_URL2}}" + API;
              baseUrlBearerToken = props.options.baseUrl2BearerToken;
              dynamicBaseUrlBearerToken = baseUrl2BearerToken;
            } else if (props.BaseUrl === "baseUrl3") {
              url = props.options.baseUrl3 + API;
              forDownloadUrl = "{{API_URL3}}" + API;
              baseUrlBearerToken = props.options.baseUrl3BearerToken;
              dynamicBaseUrlBearerToken = baseUrl3BearerToken;
            } else if (props.BaseUrl === "baseUrl4") {
              url = props.options.baseUrl4 + API;
              forDownloadUrl = "{{API_URL4}}" + API;
              baseUrlBearerToken = props.options.baseUrl4BearerToken;
              dynamicBaseUrlBearerToken = baseUrl4BearerToken;
            } else if (props.BaseUrl === "baseUrl5") {
              url = props.options.baseUrl5 + API;
              forDownloadUrl = "{{API_URL5}}" + API;
              baseUrlBearerToken = props.options.baseUrl5BearerToken;
              dynamicBaseUrlBearerToken = baseUrl5BearerToken;
            }

            // running in download
            url = forDownloadUrl;

            console.log(
              "url, dynamicBaseUrlBearerToken",
              url,
              dynamicBaseUrlBearerToken
            );

            // if nothing is provided for auth
            if (
              !props.BearerToken &&
              !dynamicBaseUrlBearerToken &&
              !props.Username &&
              !props.Password
            ) {
              // add extra headers, if needed
              let config = {
                headers: {},
              };

              if (props.MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                props.MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token is provided but not usr+pwd
            else if (props.BearerToken && !props.Username && !props.Password) {
              // add extra headers, if needed
              let config = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${props.BearerToken}`,
                },
              };

              if (props.MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                props.MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a dynamicBaseUrlBearerToken is provided but not Custom Bearer Token and usr+pwd
            else if (
              dynamicBaseUrlBearerToken &&
              !props.BearerToken &&
              !props.Username &&
              !props.Password
            ) {
              // add extra headers, if needed
              let config = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${dynamicBaseUrlBearerToken}`,
                },
              };

              if (props.MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                props.MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config@:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token is not provided but usr+pwd are provided
            else if (!props.BearerToken && props.Username && props.Password) {
              // add extra headers, if needed
              let config = {
                headers: {
                  Authorization:
                    "Basic " + btoa(props.Username + ":" + props.Password),
                },
              };

              if (props.MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                props.MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }
          }

          // POST method
          // if CustomUrl is provided, use only that
          if (
            props.CustomUrl &&
            (!props.BaseUrl || props.BaseUrl === "null") &&
            props.APIMethod === "POST"
          ) {
            // if nothing is provided for auth
            if (!props.BearerToken && !props.Username && !props.Password) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {},
                body:
                  typeof props.APIBody === "object"
                    ? JSON.stringify(props.APIBody)
                    : props.APIBody,
              };

              if (props.MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                props.MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${props.CustomUrl}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token is provided but not api_key or usr+pwd
            else if (props.BearerToken && !props.Username && !props.Password) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${props.BearerToken}`,
                },
                body:
                  typeof props.APIBody === "object"
                    ? JSON.stringify(props.APIBody)
                    : props.APIBody,
              };

              if (props.MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                props.MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${props.CustomUrl}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token and api_key not provided but usr+pwd are provided
            else if (!props.BearerToken && props.Username && props.Password) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {
                  Authorization:
                    "Basic " + btoa(props.Username + ":" + props.Password),
                },
                body:
                  typeof props.APIBody === "object"
                    ? JSON.stringify(props.APIBody)
                    : props.APIBody,
              };

              if (props.MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                props.MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${props.CustomUrl}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }
          }

          // if base url is the choice and provided
          if (
            props.BaseUrl &&
            API &&
            !props.CustomUrl &&
            props.APIMethod === "POST"
          ) {
            // concatenating base url and its remaining part
            let url, baseUrlBearerToken;
            let forDownloadUrl;
            if (props.BaseUrl === "baseUrl1") {
              url = props.options.baseUrl1 + API;
              forDownloadUrl = "{{API_URL1}}" + API;
              baseUrlBearerToken = props.options.baseUrl1BearerToken;
              dynamicBaseUrlBearerToken = baseUrl1BearerToken;
            } else if (props.BaseUrl === "baseUrl2") {
              url = props.options.baseUrl2 + API;
              forDownloadUrl = "{{API_URL2}}" + API;
              baseUrlBearerToken = props.options.baseUrl2BearerToken;
              dynamicBaseUrlBearerToken = baseUrl2BearerToken;
            } else if (props.BaseUrl === "baseUrl3") {
              url = props.options.baseUrl3 + API;
              forDownloadUrl = "{{API_URL3}}" + API;
              baseUrlBearerToken = props.options.baseUrl3BearerToken;
              dynamicBaseUrlBearerToken = baseUrl3BearerToken;
            } else if (props.BaseUrl === "baseUrl4") {
              url = props.options.baseUrl4 + API;
              forDownloadUrl = "{{API_URL4}}" + API;
              baseUrlBearerToken = props.options.baseUrl4BearerToken;
              dynamicBaseUrlBearerToken = baseUrl4BearerToken;
            } else if (props.BaseUrl === "baseUrl5") {
              url = props.options.baseUrl5 + API;
              forDownloadUrl = "{{API_URL5}}" + API;
              baseUrlBearerToken = props.options.baseUrl5BearerToken;
              dynamicBaseUrlBearerToken = baseUrl5BearerToken;
            }

            // running in download
            url = forDownloadUrl;

            console.log(
              "url, dynamicBaseUrlBearerToken",
              url,
              dynamicBaseUrlBearerToken
            );

            // if nothing is provided for auth
            if (
              !props.BearerToken &&
              !dynamicBaseUrlBearerToken &&
              !props.Username &&
              !props.Password
            ) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {},
                body:
                  typeof props.APIBody === "object"
                    ? JSON.stringify(props.APIBody)
                    : props.APIBody,
              };

              if (props.MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                props.MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token is provided but not usr+pwd
            else if (props.BearerToken && !props.Username && !props.Password) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${props.BearerToken}`,
                },
                body:
                  typeof props.APIBody === "object"
                    ? JSON.stringify(props.APIBody)
                    : props.APIBody,
              };

              if (props.MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                props.MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a dynamicBaseUrlBearerToken is provided but not Custom Bearer Token and usr+pwd
            else if (
              dynamicBaseUrlBearerToken &&
              !props.BearerToken &&
              !props.Username &&
              !props.Password
            ) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${dynamicBaseUrlBearerToken}`,
                },
                body:
                  typeof props.APIBody === "object"
                    ? JSON.stringify(props.APIBody)
                    : props.APIBody,
              };

              if (props.MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                props.MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config@:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token is not provided but usr+pwd are provided
            else if (!props.BearerToken && props.Username && props.Password) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {
                  Authorization:
                    "Basic " + btoa(props.Username + ":" + props.Password),
                },
                body:
                  typeof props.APIBody === "object"
                    ? JSON.stringify(props.APIBody)
                    : props.APIBody,
              };

              if (props.MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                props.MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }
          }

          console.log("apiData (in export):", apiData);

          // checking if the url or script is not running in the redsling editor, only then run the script to plot radio

          if (apiData) {
            console.log("#");

            if (isArrayofObjects(apiData)) {
              if (props.ArrOfObjKyename) {
                console.log("##");

                // dynamic add
                // to check if any '_id' data was changed in the backend object, then update the frontend labels of radio also
                if (mainContainer.innerHTML !== "") {
                  // check if these are database plotted radio or not
                  let isNotDefaultRadio = mainContainer
                    .getElementsByClassName("group_radio_label")[0]
                    .getElementsByClassName("radio_input")[0]
                    .getElementsByTagName("input")[0]
                    .getAttribute("data_db_id");
                  console.log("isNotDefaultRadio", isNotDefaultRadio);

                  // if they are database plotted radio then scan through them using data_db_id and update if any
                  if (isNotDefaultRadio && isNotDefaultRadio !== "") {
                    let allMiniContainers =
                      mainContainer.getElementsByClassName("group_radio_label");
                    Array.from(allMiniContainers).forEach(
                      (groupRadioLabel, idx) => {
                        let radio = groupRadioLabel
                          .getElementsByClassName("radio_input")[0]
                          .getElementsByTagName("input")[0];
                        let radio_dataDbId = groupRadioLabel
                          .getElementsByClassName("radio_input")[0]
                          .getElementsByTagName("input")[0]
                          .getAttribute("data_db_id");
                        let label =
                          groupRadioLabel.getElementsByTagName("label")[0];

                        // get the object from apiData that has the radio
                        let currentObj = apiData.filter(
                          (obj) => obj["_id"] === radio_dataDbId
                        );

                        if (currentObj.length > 0) {
                          let labelInnerText =
                            label.getElementsByTagName("p")[0].innerHTML;
                          console.log("labelInnerText", labelInnerText);

                          currentObj.forEach((obj) => {
                            // check if the IDs match, if not then update the label's inner text
                            if (
                              labelInnerText !== obj[`${props.ArrOfObjKyename}`]
                            ) {
                              label.getElementsByTagName("p")[0].innerHTML =
                                obj[`${props.ArrOfObjKyename}`];
                              radio.setAttribute(
                                "value",
                                obj[`${props.ArrOfObjKyename}`]
                              );

                              console.log("currentObj", currentObj);
                            }
                          });
                        }
                      }
                    );

                    // if there are equal number of objects in apiData as checkboxes then plot new ones
                    if (apiData.length >= allMiniContainers.length) {
                      mainContainer.innerHTML = "";

                      apiData.forEach((opt) => {
                        let randomString = generateRandomString(7);
                        let groupEle = document.createElement("span");
                        groupEle.setAttribute("data-gjs-type", "default");
                        groupEle.setAttribute("class", "group_radio_label");
                        Object.assign(groupEle.style, {
                          display: "flex",
                          flexWrap: "no-wrap",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          width: props.CommonWidth
                            ? props.CommonWidth + "%"
                            : "8%",
                        });

                        let radioInputNew = document.createElement("span");
                        radioInputNew.setAttribute(
                          "data-gjs-type",
                          "radio_input"
                        );
                        radioInputNew.setAttribute("class", "radio_input");

                        let radioInput = document.createElement("input");
                        radioInput.setAttribute("type", "radio");
                        radioInput.setAttribute("id", `${randomString}`);
                        radioInput.setAttribute(
                          "name",
                          `radio_${mainContainer.id}`
                        );
                        radioInput.setAttribute(
                          "value",
                          `${opt[`${props.ArrOfObjKyename}`]}`
                        );
                        radioInput.setAttribute(
                          "data_db_id",
                          `${opt[`_id`]}`
                            ? `${opt[`_id`]}`
                            : `${opt[`${props.ArrOfObjKyename}`]}`
                        );

                        radioInputNew.appendChild(radioInput);

                        let label = document.createElement("label");
                        label.setAttribute("type", "radio_label");
                        label.setAttribute("for", `${randomString}`);
                        // Object.assign(label.style, {
                        //   wordBreak: "break-all",
                        // });
                        label.innerHTML = `<p id="${generateRandomString(6)}">${
                          opt[`${props.ArrOfObjKyename}`]
                        }</p>`;

                        groupEle.appendChild(label);
                        groupEle.appendChild(radioInputNew);

                        mainContainer.appendChild(groupEle);
                      });
                    }
                  } else {
                    mainContainer.innerHTML = "";

                    apiData.forEach((opt) => {
                      let randomString = generateRandomString(7);
                      let groupEle = document.createElement("span");
                      groupEle.setAttribute("data-gjs-type", "default");
                      groupEle.setAttribute("class", "group_radio_label");
                      Object.assign(groupEle.style, {
                        display: "flex",
                        flexWrap: "no-wrap",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        width: props.CommonWidth
                          ? props.CommonWidth + "%"
                          : "8%",
                      });

                      let radioInputNew = document.createElement("span");
                      radioInputNew.setAttribute(
                        "data-gjs-type",
                        "radio_input"
                      );
                      radioInputNew.setAttribute("class", "radio_input");

                      let radioInput = document.createElement("input");
                      radioInput.setAttribute("type", "radio");
                      radioInput.setAttribute("id", `${randomString}`);
                      radioInput.setAttribute(
                        "name",
                        `radio_${mainContainer.id}`
                      );
                      radioInput.setAttribute(
                        "value",
                        `${opt[`${props.ArrOfObjKyename}`]}`
                      );
                      radioInput.setAttribute(
                        "data_db_id",
                        `${opt[`_id`]}`
                          ? `${opt[`_id`]}`
                          : `${opt[`${props.ArrOfObjKyename}`]}`
                      );

                      radioInputNew.appendChild(radioInput);

                      let label = document.createElement("label");
                      label.setAttribute("type", "radio_label");
                      label.setAttribute("for", `${randomString}`);
                      // Object.assign(label.style, {
                      //   wordBreak: "break-all",
                      // });
                      label.innerHTML = `<p id="${generateRandomString(6)}">${
                        opt[`${props.ArrOfObjKyename}`]
                      }</p>`;

                      groupEle.appendChild(label);
                      groupEle.appendChild(radioInputNew);

                      mainContainer.appendChild(groupEle);
                    });
                  }
                }

                // reasons -
                // container.components().length === 0 : plot radio if there are no radio present in the container
                // container.components().length === 2 : plot radio the first time when there are the 2 initial radio
                // apiData.length > container.components().length : plot radio if apiData has more objects than radio in the container meaning update the radio when there is an update done in the backend database, make this dynamic
                if (
                  mainContainer.childNodes.length === 0 ||
                  (mainContainer.childNodes.length === 2 && apiData.length > 2)
                ) {
                  mainContainer.innerHTML = "";

                  apiData.forEach((opt) => {
                    let randomString = generateRandomString(7);
                    let groupEle = document.createElement("span");
                    groupEle.setAttribute("data-gjs-type", "default");
                    groupEle.setAttribute("class", "group_radio_label");
                    Object.assign(groupEle.style, {
                      display: "flex",
                      flexWrap: "no-wrap",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      width: props.CommonWidth ? props.CommonWidth + "%" : "8%",
                    });

                    let radioInputNew = document.createElement("span");
                    radioInputNew.setAttribute("data-gjs-type", "radio_input");
                    radioInputNew.setAttribute("class", "radio_input");

                    let radioInput = document.createElement("input");
                    radioInput.setAttribute("type", "radio");
                    radioInput.setAttribute("id", `${randomString}`);
                    radioInput.setAttribute(
                      "name",
                      `radio_${mainContainer.id}`
                    );
                    radioInput.setAttribute(
                      "value",
                      `${opt[`${props.ArrOfObjKyename}`]}`
                    );
                    radioInput.setAttribute(
                      "data_db_id",
                      `${opt[`_id`]}`
                        ? `${opt[`_id`]}`
                        : `${opt[`${props.ArrOfObjKyename}`]}`
                    );

                    radioInputNew.appendChild(radioInput);

                    let label = document.createElement("label");
                    label.setAttribute("type", "radio_label");
                    label.setAttribute("for", `${randomString}`);
                    // Object.assign(label.style, {
                    //   wordBreak: "break-all",
                    // });
                    label.innerHTML = `<p id="${generateRandomString(6)}">${
                      opt[`${props.ArrOfObjKyename}`]
                    }</p>`;

                    groupEle.appendChild(label);
                    groupEle.appendChild(radioInputNew);

                    mainContainer.appendChild(groupEle);
                  });
                }

                // update only when the radio have already been plotted previously from apiData but not updating if the apiData had only 2 fields
                if (
                  apiData.length > mainContainer.childNodes.length &&
                  mainContainer.childNodes.length > 2
                ) {
                  // only add what's missing from api as a dynamic update

                  // get all the labels' inner content and compare it with the keyname of the apiData to be plotted and add the ones missing
                  let allLabelEles = Array.from(
                    mainContainer.getElementsByTagName("label")
                  );
                  let allLabels = allLabelEles.map(
                    (label) => label.textContent
                  );
                  console.log("allLabelEles", allLabelEles, allLabels);

                  if (allLabelEles.length > 0) {
                    // if you find a label thats not in the apiData then add it to the existing radio container
                    apiData.forEach((dbRow, idx) => {
                      let randomString = generateRandomString(7);
                      // if that object from apiData is not present in the radio container then plot it
                      if (
                        !allLabels.includes(dbRow[`${props.ArrOfObjKyename}`])
                      ) {
                        let groupEle = document.createElement("span");
                        groupEle.setAttribute("data-gjs-type", "default");
                        groupEle.setAttribute("class", "group_radio_label");
                        Object.assign(groupEle.style, {
                          display: "flex",
                          flexWrap: "no-wrap",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          width: props.CommonWidth
                            ? props.CommonWidth + "%"
                            : "8%",
                        });

                        let radioInputNew = document.createElement("span");
                        radioInputNew.setAttribute(
                          "data-gjs-type",
                          "radio_input"
                        );
                        radioInputNew.setAttribute("class", "radio_input");

                        let radioInput = document.createElement("input");
                        radioInput.setAttribute("type", "radio");
                        radioInput.setAttribute("id", `${randomString}`);
                        radioInput.setAttribute(
                          "name",
                          `radio_${mainContainer.id}`
                        );
                        radioInput.setAttribute(
                          "value",
                          `${dbRow[`${props.ArrOfObjKyename}`]}`
                        );
                        radioInput.setAttribute(
                          "data_db_id",
                          `${dbRow[`_id`]}`
                            ? `${dbRow[`_id`]}`
                            : `${dbRow[`${props.ArrOfObjKyename}`]}`
                        );

                        radioInputNew.appendChild(radioInput);

                        let label = document.createElement("label");
                        // label.setAttribute('type', 'radio_label');
                        label.setAttribute("for", `${randomString}`);
                        // Object.assign(label.style, {
                        //   wordBreak: "break-all",
                        // });
                        label.innerHTML = `<p id="${generateRandomString(6)}">${
                          dbRow[`${props.ArrOfObjKyename}`]
                        }</p>`;

                        groupEle.appendChild(radioInputNew);

                        groupEle.appendChild(label);

                        mainContainer.appendChild(groupEle);
                      }
                    });
                  }
                }

                // plot when the apiData has only around 2 entries
                else if (apiData.length <= 2) {
                  // see if the radio are the default radio by checking if they have this attribute 'data_db_id', if yes then clear the body and append new else append new checkboxes anyways
                  let isNotDefaultRadio = mainContainer
                    .getElementsByTagName("input")[0]
                    .getAttribute("data_db_id");

                  // if it is a default radio then empty the body before appending apiData radio
                  if (!isNotDefaultRadio) {
                    mainContainer.innerHTML = "";
                    apiData.forEach((opt) => {
                      let randomString = generateRandomString(7);
                      let groupEle = document.createElement("span");
                      groupEle.setAttribute("data-gjs-type", "default");
                      groupEle.setAttribute("class", "group_radio_label");
                      Object.assign(groupEle.style, {
                        display: "flex",
                        flexWrap: "no-wrap",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        width: props.CommonWidth
                          ? props.CommonWidth + "%"
                          : "8%",
                      });

                      let radioInputNew = document.createElement("span");
                      radioInputNew.setAttribute(
                        "data-gjs-type",
                        "radio_input"
                      );
                      radioInputNew.setAttribute("class", "cradio_input");

                      let radioInput = document.createElement("input");
                      radioInput.setAttribute("type", "radio");
                      radioInput.setAttribute("id", `${randomString}`);
                      radioInput.setAttribute(
                        "name",
                        `radio_${mainContainer.id}`
                      );
                      radioInput.setAttribute(
                        "value",
                        `${opt[`${props.ArrOfObjKyename}`]}`
                      );
                      radioInput.setAttribute(
                        "data_db_id",
                        `${opt[`_id`]}`
                          ? `${opt[`_id`]}`
                          : `${opt[`${props.ArrOfObjKyename}`]}`
                      );

                      radioInputNew.appendChild(radioInput);

                      let label = document.createElement("label");
                      label.setAttribute("type", "radio_label");
                      label.setAttribute("for", `${randomString}`);
                      // Object.assign(label.style, {
                      //   wordBreak: "break-all",
                      // });
                      label.innerHTML = `<p id="${generateRandomString(6)}">${
                        opt[`${props.ArrOfObjKyename}`]
                      }</p>`;

                      groupEle.appendChild(radioInputNew);
                      groupEle.appendChild(label);

                      mainContainer.appendChild(groupEle);
                    });
                  } else {
                    mainContainer.innerHTML = "";

                    apiData.forEach((opt) => {
                      let randomString = generateRandomString(7);
                      let groupEle = document.createElement("span");
                      groupEle.setAttribute("data-gjs-type", "default");
                      groupEle.setAttribute("class", "group_radio_label");
                      Object.assign(groupEle.style, {
                        display: "flex",
                        flexWrap: "no-wrap",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        width: props.CommonWidth
                          ? props.CommonWidth + "%"
                          : "8%",
                      });

                      let radioInputNew = document.createElement("span");
                      radioInputNew.setAttribute(
                        "data-gjs-type",
                        "radio_input"
                      );
                      radioInputNew.setAttribute("class", "radio_input");

                      let radioInput = document.createElement("input");
                      radioInput.setAttribute("type", "radio");
                      radioInput.setAttribute("id", `${randomString}`);
                      radioInput.setAttribute(
                        "name",
                        `radio_${mainContainer.id}`
                      );
                      radioInput.setAttribute(
                        "value",
                        `${opt[`${props.ArrOfObjKyename}`]}`
                      );
                      radioInput.setAttribute(
                        "data_db_id",
                        `${opt[`_id`]}`
                          ? `${opt[`_id`]}`
                          : `${opt[`${props.ArrOfObjKyename}`]}`
                      );

                      radioInputNew.appendChild(radioInput);

                      let label = document.createElement("label");
                      label.setAttribute("type", "radio_label");
                      label.setAttribute("for", `${randomString}`);
                      // Object.assign(label.style, {
                      //   wordBreak: "break-all",
                      // });
                      label.innerHTML = `<p id="${generateRandomString(6)}">${
                        opt[`${props.ArrOfObjKyename}`]
                      }</p>`;

                      groupEle.appendChild(label);
                      groupEle.appendChild(radioInputNew);

                      mainContainer.appendChild(groupEle);
                    });
                  }
                }

                // dynamic delete
                if (mainContainer.childNodes.length > apiData.length) {
                  console.log("removing extra group_radio_labels");

                  let groupRadioLabels =
                    mainContainer.getElementsByClassName("group_radio_label");
                  Array.from(groupRadioLabels).forEach((groupRadioLabel) => {
                    let radio = groupRadioLabel
                      .getElementsByClassName("radio_input")[0]
                      .getElementsByTagName("input")[0];
                    let radioDataDbId = radio.getAttribute("data_db_id");

                    // see if radioDataDbId is in apiData _ids and if not there, delete this group_radio_label
                    let apiData_id = apiData.map((obj) => obj["_id"]);

                    if (!apiData_id.includes(radioDataDbId)) {
                      groupRadioLabel.remove();
                      console.log("removed");
                    }
                  });
                }
              }
            }

            // if API response is a plain array of single elements
            else if (Array.isArray(apiData) && !isArrayofObjects(apiData)) {
              console.log("###");

              // either no radio are plotted or the default 2 radio are there
              if (
                mainContainer.childNodes.length === 0 ||
                mainContainer.childNodes.length <= 2
              ) {
                mainContainer.innerHTML = "";

                apiData.forEach((opt) => {
                  let randomString = generateRandomString(7);
                  let groupEle = document.createElement("span");
                  groupEle.setAttribute("data-gjs-type", "default");
                  groupEle.setAttribute("class", "group_radio_label");
                  Object.assign(groupEle.style, {
                    display: "flex",
                    flexWrap: "no-wrap",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    width: props.CommonWidth ? props.CommonWidth + "%" : "8%",
                  });

                  let radioInputNew = document.createElement("span");
                  radioInputNew.setAttribute("data-gjs-type", "radio_input");
                  radioInputNew.setAttribute("class", "radio_input");

                  let radioInput = document.createElement("input");
                  radioInput.setAttribute("type", "radio");
                  radioInput.setAttribute("id", `${randomString}`);
                  radioInput.setAttribute("name", `radio_${mainContainer.id}`);
                  radioInput.setAttribute("value", `${opt}`);

                  radioInputNew.appendChild(radioInput);

                  let label = document.createElement("label");
                  label.setAttribute("type", "radio_label");
                  label.setAttribute("for", `${randomString}`);
                  // Object.assign(label.style, {
                  //   wordBreak: "break-all",
                  // });
                  label.innerHTML = `<p id="${generateRandomString(
                    6
                  )}">${opt}</p>`;

                  groupEle.appendChild(radioInputNew);
                  groupEle.appendChild(label);

                  mainContainer.appendChild(groupEle);
                });
              }

              // else if the apiData radio are already plotted then check for updated values and add those here without changing the IDd of already plotted ones
              else if (
                apiData.length > mainContainer.childNodes.length &&
                mainContainer.childNodes.length > 2
              ) {
                // only add what's missing from api as a dynamic update

                // get all the labels' inner content and compare it with the keyname of the apiData to be plotted and add the ones missing
                let allLabelEles = Array.from(
                  mainContainer.getElementsByTagName("label")
                );
                let allLabels = allLabelEles.map((label) => label.textContent);
                console.log("allLabelEles", allLabelEles, allLabels);

                if (allLabelEles.length > 0) {
                  // if you find a label thats not in the apiData then add it to the existing radio container
                  apiData.forEach((opt, idx) => {
                    let randomString = generateRandomString(7);
                    // if that object from apiData is not present in the radio container then plot it
                    if (!allLabels.includes(opt)) {
                      let groupEle = document.createElement("span");
                      groupEle.setAttribute("data-gjs-type", "default");
                      groupEle.setAttribute("class", "group_radio_label");
                      Object.assign(groupEle.style, {
                        display: "flex",
                        flexWrap: "no-wrap",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        width: props.CommonWidth
                          ? props.CommonWidth + "%"
                          : "8%",
                      });

                      let radioInputNew = document.createElement("span");
                      radioInputNew.setAttribute(
                        "data-gjs-type",
                        "radio_input"
                      );
                      radioInputNew.setAttribute("class", "radio_input");

                      let radioInput = document.createElement("input");
                      radioInput.setAttribute("type", "radio");
                      radioInput.setAttribute("id", `${randomString}`);
                      radioInput.setAttribute(
                        "name",
                        `radio_${mainContainer.id}`
                      );
                      radioInput.setAttribute("value", `${opt}`);

                      radioInputNew.appendChild(radioInput);

                      let label = document.createElement("label");
                      label.setAttribute("type", "radio_label");
                      label.setAttribute("for", `${randomString}`);
                      // Object.assign(label.style, {
                      //   wordBreak: "break-all",
                      // });
                      label.innerHTML = `<p id="${generateRandomString(
                        6
                      )}">${opt}</p>`;

                      groupEle.appendChild(radioInputNew);
                      groupEle.appendChild(label);

                      mainContainer.appendChild(groupEle);
                    }
                  });
                }
              }
            }

            // if API response is an object
            else if (
              !isArrayofObjects(apiData) &&
              typeof apiData === "object"
            ) {
              // if API response to plot is a nested array-of-objects inside the main response object
              if (props.NestedKeyname && props.ArrOfObjKyename) {
                console.log("####");
                // container.components("");

                // find the array-of-objects value
                let data = findKeyValue(apiData, props.NestedKeyname);

                if (isArrayofObjects(data)) {
                  // to check if any '_id' data was changed in the backend object, then update the frontend labels of radio also
                  if (mainContainer.innerHTML !== "") {
                    // check if these are database plotted radio or not
                    let isNotDefaultRadio = mainContainer
                      .getElementsByClassName("group_radio_label")[0]
                      .getElementsByClassName("radio_input")[0]
                      .getElementsByTagName("input")[0]
                      .getAttribute("data_db_id");
                    console.log("isNotDefaultRadio", isNotDefaultRadio);

                    // if they are database plotted radio then scan through them using data_db_id and update if any
                    if (isNotDefaultRadio) {
                      let allMiniContainers =
                        mainContainer.getElementsByClassName(
                          "group_radio_label"
                        );
                      Array.from(allMiniContainers).forEach(
                        (groupRadioLabel, idx) => {
                          let radio = groupRadioLabel
                            .getElementsByClassName("radio_input")[0]
                            .getElementsByTagName("input")[0];
                          let radio_dataDbId = groupRadioLabel
                            .getElementsByClassName("radio_input")[0]
                            .getElementsByTagName("input")[0]
                            .getAttribute("data_db_id");
                          let label =
                            groupRadioLabel.getElementsByTagName("label")[0];

                          // get the object from apiData that has the radio
                          let currentObj = data.filter(
                            (obj) => obj["_id"] === radio_dataDbId
                          );

                          if (currentObj.length > 0) {
                            currentObj.forEach((obj) => {
                              let labelInnerText =
                                label.getElementsByTagName("p")[0].innerHTML;
                              console.log("labelInnerText", labelInnerText);

                              // check if the IDs match, if not then update the label's inner text
                              if (
                                labelInnerText !==
                                obj[`${props.ArrOfObjKyename}`]
                              ) {
                                label.getElementsByTagName("p")[0].innerHTML =
                                  obj[`${props.ArrOfObjKyename}`];
                                radio.setAttribute(
                                  "value",
                                  obj[`${props.ArrOfObjKyename}`]
                                );

                                console.log("currentObj", currentObj);
                              }
                            });
                          }
                        }
                      );
                    }
                  }

                  // reasons -
                  // container.components().length === 0 : plot radio if there are no radio present in the container
                  // container.components().length === 2 : plot radio the first time when there are the 2 initial radio
                  // apiData.length > container.components().length : plot radio if apiData has more objects than radio in the container meaning update the radio when there is an update done in the backend database, make this dynamic
                  if (
                    mainContainer.childNodes.length === 0 ||
                    (mainContainer.childNodes.length === 2 && data.length > 2)
                  ) {
                    mainContainer.innerHTML = "";

                    data.forEach((opt) => {
                      let randomString = generateRandomString(7);
                      let groupEle = document.createElement("span");
                      groupEle.setAttribute("data-gjs-type", "default");
                      groupEle.setAttribute("class", "group_radio_label");
                      Object.assign(groupEle.style, {
                        display: "flex",
                        flexWrap: "no-wrap",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        width: props.CommonWidth
                          ? props.CommonWidth + "%"
                          : "8%",
                      });

                      let radioInputNew = document.createElement("span");
                      radioInputNew.setAttribute(
                        "data-gjs-type",
                        "radio_input"
                      );
                      radioInputNew.setAttribute("class", "radio_input");

                      let radioInput = document.createElement("input");
                      radioInput.setAttribute("type", "radio");
                      radioInput.setAttribute("id", `${randomString}`);
                      radioInput.setAttribute(
                        "name",
                        `radio_${mainContainer.id}`
                      );
                      radioInput.setAttribute(
                        "value",
                        `${opt[`${props.ArrOfObjKyename}`]}`
                      );
                      radioInput.setAttribute(
                        "data_db_id",
                        `${opt[`_id`]}`
                          ? `${opt[`_id`]}`
                          : `${opt[`${props.ArrOfObjKyename}`]}`
                      );

                      radioInputNew.appendChild(radioInput);

                      let label = document.createElement("label");
                      label.setAttribute("type", "radio_label");
                      label.setAttribute("for", `${randomString}`);
                      // Object.assign(label.style, {
                      //   wordBreak: "break-all",
                      // });
                      label.innerHTML = `<p id="${generateRandomString(6)}">${
                        opt[`${props.ArrOfObjKyename}`]
                      }</p>`;

                      groupEle.appendChild(radioInputNew);
                      groupEle.appendChild(label);

                      mainContainer.appendChild(groupEle);
                    });
                  }

                  // update only when the radio have already been plotted previously from apiData but not updating if the apiData had only 2 fields
                  if (
                    data.length > mainContainer.childNodes.length &&
                    mainContainer.childNodes.length > 2
                  ) {
                    // only add what's missing from api as a dynamic update

                    // get all the labels' inner content and compare it with the keyname of the apiData to be plotted and add the ones missing
                    let allLabelEles = Array.from(
                      mainContainer.getElementsByTagName("label")
                    );
                    let allLabels = allLabelEles.map(
                      (label) => label.textContent
                    );
                    console.log("allLabelEles", allLabelEles, allLabels);

                    if (allLabelEles.length > 0) {
                      // if you find a label thats not in the apiData then add it to the existing radio container
                      data.forEach((dbRow, idx) => {
                        let randomString = generateRandomString(7);
                        // if that object from apiData is not present in the radio container then plot it
                        if (
                          !allLabels.includes(dbRow[`${props.ArrOfObjKyename}`])
                        ) {
                          let groupEle = document.createElement("span");
                          groupEle.setAttribute("data-gjs-type", "default");
                          groupEle.setAttribute("class", "group_radio_label");
                          Object.assign(groupEle.style, {
                            display: "flex",
                            flexWrap: "no-wrap",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            width: props.CommonWidth
                              ? props.CommonWidth + "%"
                              : "8%",
                          });

                          let radioInputNew = document.createElement("span");
                          radioInputNew.setAttribute(
                            "data-gjs-type",
                            "radio_input"
                          );
                          radioInputNew.setAttribute("class", "radio_input");

                          let radioInput = document.createElement("input");
                          radioInput.setAttribute("type", "radio");
                          radioInput.setAttribute("id", `${randomString}`);
                          radioInput.setAttribute(
                            "name",
                            `radio_${mainContainer.id}`
                          );
                          radioInput.setAttribute(
                            "value",
                            `${dbRow[`${props.ArrOfObjKyename}`]}`
                          );
                          radioInput.setAttribute(
                            "data_db_id",
                            `${dbRow[`_id`]}`
                              ? `${dbRow[`_id`]}`
                              : `${dbRow[`${props.ArrOfObjKyename}`]}`
                          );

                          radioInputNew.appendChild(radioInput);

                          let label = document.createElement("label");
                          label.setAttribute("type", "radio_label");
                          label.setAttribute("for", `${randomString}`);
                          // Object.assign(label.style, {
                          //   wordBreak: "break-all",
                          // });
                          label.innerHTML = `<p id="${generateRandomString(
                            6
                          )}">${dbRow[`${props.ArrOfObjKyename}`]}</p>`;

                          groupEle.appendChild(radioInputNew);
                          groupEle.appendChild(label);

                          mainContainer.appendChild(groupEle);
                        }
                      });
                    }
                  }

                  // plot when the apiData has only around 2 entries
                  else if (data.length <= 2) {
                    // see if the radioes are the default radioes by checking if they have this attribute 'data_db_id', if yes then clear the body and append new else append new radioes anyways
                    let isNotDefaultradio = mainContainer
                      .getElementsByTagName("input")[0]
                      .getAttribute("data_db_id");

                    // if it is a default radio then empty the body before appending apiData radioes
                    if (!isNotDefaultradio) {
                      mainContainer.innerHTML = "";
                      data.forEach((opt) => {
                        let randomString = generateRandomString(7);
                        let groupEle = document.createElement("span");
                        groupEle.setAttribute("data-gjs-type", "default");
                        groupEle.setAttribute("class", "group_radio_label");
                        Object.assign(groupEle.style, {
                          display: "flex",
                          flexWrap: "no-wrap",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          width: props.CommonWidth
                            ? props.CommonWidth + "%"
                            : "8%",
                        });

                        let radioInputNew = document.createElement("span");
                        radioInputNew.setAttribute(
                          "data-gjs-type",
                          "radio_input"
                        );
                        radioInputNew.setAttribute("class", "radio_input");

                        let radioInput = document.createElement("input");
                        radioInput.setAttribute("type", "radio");
                        radioInput.setAttribute("id", `${randomString}`);
                        radioInput.setAttribute(
                          "name",
                          `radio_${mainContainer.id}`
                        );
                        radioInput.setAttribute(
                          "value",
                          `${opt[`${props.ArrOfObjKyename}`]}`
                        );
                        radioInput.setAttribute(
                          "data_db_id",
                          `${opt[`_id`]}`
                            ? `${opt[`_id`]}`
                            : `${opt[`${props.ArrOfObjKyename}`]}`
                        );

                        radioInputNew.appendChild(radioInput);

                        let label = document.createElement("label");
                        label.setAttribute("type", "radio_label");
                        label.setAttribute("for", `${randomString}`);
                        // Object.assign(label.style, {
                        //   wordBreak: "break-all",
                        // });
                        label.innerHTML = `<p id="${generateRandomString(6)}">${
                          opt[`${props.ArrOfObjKyename}`]
                        }</p>`;

                        groupEle.appendChild(radioInputNew);
                        groupEle.appendChild(label);

                        mainContainer.appendChild(groupEle);
                      });
                    }
                  }

                  // dynamic delete
                  if (mainContainer.childNodes.length > data.length) {
                    console.log("removing extra group_radio_labels");

                    let groupradioLabels =
                      mainContainer.getElementsByClassName("group_radio_label");
                    Array.from(groupradioLabels).forEach((groupradioLabel) => {
                      let radio = groupradioLabel
                        .getElementsByClassName("radio_input")[0]
                        .getElementsByTagName("input")[0];
                      let radioDataDbId = radio.getAttribute("data_db_id");

                      // see if radioDataDbId is in apiData _ids and if not there, delete this group_radio_label
                      let apiData_id = data.map((obj) => obj["_id"]);

                      if (!apiData_id.includes(radioDataDbId)) {
                        groupradioLabel.remove();
                        console.log("removed");
                      }
                    });
                  }
                }
              } else if (props.NestedKeyname && !props.ArrOfObjKyename) {
                // container.components("");

                // find the array value
                let data = findKeyValue(apiData, props.NestedKeyname);

                // if API response to plot is a nested plain array of single elements inside the main response object
                if (Array.isArray(apiData) && !isArrayofObjects(apiData)) {
                  console.log("###");

                  // either no radioes are plotted or the default 2 radioes are there
                  if (
                    mainContainer.childNodes.length === 0 ||
                    mainContainer.childNodes.length <= 2
                  ) {
                    mainContainer.innerHTML = "";

                    data.forEach((opt) => {
                      let randomString = generateRandomString(7);
                      let groupEle = document.createElement("span");
                      groupEle.setAttribute("data-gjs-type", "default");
                      groupEle.setAttribute("class", "group_radio_label");
                      Object.assign(groupEle.style, {
                        display: "flex",
                        flexWrap: "no-wrap",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        width: props.CommonWidth
                          ? props.CommonWidth + "%"
                          : "8%",
                      });

                      let radioInputNew = document.createElement("span");
                      radioInputNew.setAttribute(
                        "data-gjs-type",
                        "radio_input"
                      );
                      radioInputNew.setAttribute("class", "radio_input");

                      let radioInput = document.createElement("input");
                      radioInput.setAttribute("type", "radio");
                      radioInput.setAttribute("id", `${randomString}`);
                      radioInput.setAttribute(
                        "name",
                        `radio_${mainContainer.id}`
                      );
                      radioInput.setAttribute("value", `${opt}`);

                      radioInputNew.appendChild(radioInput);

                      let label = document.createElement("label");
                      label.setAttribute("type", "radio_label");
                      label.setAttribute("for", `${randomString}`);
                      // Object.assign(label.style, {
                      //   wordBreak: "break-all",
                      // });
                      label.innerHTML = `<p id="${generateRandomString(
                        6
                      )}">${opt}</p>`;

                      groupEle.appendChild(radioInputNew);
                      groupEle.appendChild(label);

                      mainContainer.appendChild(groupEle);
                    });
                  }

                  // else if the apiData radioes are already plotted then check for updated values and add those here without changing the IDd of already plotted ones
                  else if (
                    data.length > mainContainer.childNodes.length &&
                    mainContainer.childNodes.length > 2
                  ) {
                    // only add what's missing from api as a dynamic update

                    // get all the labels' inner content and compare it with the keyname of the apiData to be plotted and add the ones missing
                    let allLabelEles = Array.from(
                      mainContainer.getElementsByTagName("label")
                    );
                    let allLabels = allLabelEles.map(
                      (label) => label.textContent
                    );
                    console.log("allLabelEles", allLabelEles, allLabels);

                    if (allLabelEles.length > 0) {
                      // if you find a label thats not in the apiData then add it to the existing radio container
                      data.forEach((opt, idx) => {
                        let randomString = generateRandomString(7);
                        // if that object from apiData is not present in the radio container then plot it
                        if (!allLabels.includes(opt)) {
                          let groupEle = document.createElement("span");
                          groupEle.setAttribute("data-gjs-type", "default");
                          groupEle.setAttribute("class", "group_radio_label");
                          Object.assign(groupEle.style, {
                            display: "flex",
                            flexWrap: "no-wrap",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            width: props.CommonWidth
                              ? props.CommonWidth + "%"
                              : "8%",
                          });

                          let radioInputNew = document.createElement("span");
                          radioInputNew.setAttribute(
                            "data-gjs-type",
                            "radio_input"
                          );
                          radioInputNew.setAttribute("class", "radio_input");

                          let radioInput = document.createElement("input");
                          radioInput.setAttribute("type", "radio");
                          radioInput.setAttribute("id", `${randomString}`);
                          radioInput.setAttribute(
                            "name",
                            `radio_${mainContainer.id}`
                          );
                          radioInput.setAttribute("value", `${opt}`);

                          radioInputNew.appendChild(radioInput);

                          let label = document.createElement("label");
                          label.setAttribute("type", "radio_label");
                          label.setAttribute("for", `${randomString}`);
                          // Object.assign(label.style, {
                          //   wordBreak: "break-all",
                          // });
                          label.innerHTML = `<p id="${generateRandomString(
                            6
                          )}">${opt}</p>`;

                          groupEle.appendChild(radioInputNew);
                          groupEle.appendChild(label);

                          mainContainer.appendChild(groupEle);
                        }
                      });
                    }
                  }
                }

                // if API response to plot are all key:value pairs of a plain object inside main object
                else if (
                  !Array.isArray(data) &&
                  !isArrayofObjects(data) &&
                  typeof data === "object"
                ) {
                  console.log("######");
                  Object.keys(data).forEach((key) => {
                    if (
                      !Array.isArray(data[`${key}`]) &&
                      typeof data[`${key}`] !== "object"
                    ) {
                      let randomString = generateRandomString(7);

                      let groupEle = document.createElement("span");
                      groupEle.setAttribute("data-gjs-type", "default");
                      groupEle.setAttribute("class", "group_radio_label");
                      Object.assign(groupEle.style, {
                        display: "flex",
                        flexWrap: "no-wrap",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        width: props.CommonWidth
                          ? props.CommonWidth + "%"
                          : "8%",
                      });

                      let radioInputNew = document.createElement("span");
                      radioInputNew.setAttribute(
                        "data-gjs-type",
                        "radio_input"
                      );
                      radioInputNew.setAttribute("class", "radio_input");

                      let radioInput = document.createElement("input");
                      radioInput.setAttribute("type", "radio");
                      radioInput.setAttribute("id", `${randomString}`);
                      radioInput.setAttribute("name", `${randomString}`);
                      radioInput.setAttribute("value", `${data[`${key}`]}`);

                      radioInputNew.appendChild(radioInput);

                      let label = document.createElement("label");
                      label.setAttribute("type", "radio_label");
                      label.setAttribute("for", `${randomString}`);
                      // Object.assign(label.style, {
                      //   wordBreak: "break-all",
                      // });
                      label.innerHTML = `<p id="${generateRandomString(
                        6
                      )}">${key}</p>`;

                      groupEle.appendChild(radioInputNew);
                      groupEle.appendChild(label);

                      mainContainer.appendChild(groupEle);
                    }
                  });
                }
              }

              // if API response to plot are all key:value pairs of the plain object
              else if (!props.NestedKeyname && !props.ArrOfObjKyename) {
                console.log("#######");
                mainContainer.innerHTML = "";

                Object.keys(apiData).forEach((key) => {
                  let randomString = generateRandomString(7);

                  if (
                    !Array.isArray(apiData[`${key}`]) &&
                    typeof apiData[`${key}`] !== "object"
                  ) {
                    let groupEle = document.createElement("span");
                    groupEle.setAttribute("data-gjs-type", "default");
                    groupEle.setAttribute("class", "group_radio_label");
                    Object.assign(groupEle.style, {
                      display: "flex",
                      flexWrap: "no-wrap",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      width: props.CommonWidth ? props.CommonWidth + "%" : "8%",
                    });

                    let radioInputNew = document.createElement("span");
                    radioInputNew.setAttribute("data-gjs-type", "radio_input");
                    radioInputNew.setAttribute("class", "radio_input");

                    let radioInput = document.createElement("input");
                    radioInput.setAttribute("type", "radio");
                    radioInput.setAttribute("id", `${randomString}`);
                    radioInput.setAttribute(
                      "name",
                      `radio_${mainContainer.id}`
                    );
                    radioInput.setAttribute("value", `${apiData[`${key}`]}`);

                    radioInputNew.appendChild(radioInput);

                    let label = document.createElement("label");
                    label.setAttribute("type", "radio_label");
                    label.setAttribute("for", `${randomString}`);
                    // Object.assign(label.style, {
                    //   wordBreak: "break-all",
                    // });
                    label.innerHTML = `<p id="${generateRandomString(
                      6
                    )}">${key}</p>`;

                    groupEle.appendChild(radioInputNew);
                    groupEle.appendChild(label);

                    mainContainer.appendChild(groupEle);
                  }
                });
              }

                // if there is a 'message' in api response instead of data
                else if (Object.keys(apiData).includes('message')) {
                    console.log("#@@");

                    mainContainer.innerHTML = "";
                    let randomString = generateRandomString(7);
                      let groupEle = document.createElement("span");
                      groupEle.setAttribute("data-gjs-type", "default");
                      groupEle.setAttribute("class", "group_radio_label");
                      Object.assign(groupEle.style, {
                        display: "flex",
                        flexWrap: "no-wrap",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        width: props.CommonWidth ? props.CommonWidth + "%" : "8%",
                      });

                      let radioInputNew = document.createElement("span");
                      radioInputNew.setAttribute("data-gjs-type", "radio_input");
                      radioInputNew.setAttribute("class", "radio_input");

                      let radioInput = document.createElement("input");
                      radioInput.setAttribute("type", "radio");
                      radioInput.setAttribute("id", `${randomString}`);
                      radioInput.setAttribute("name", `radio_${mainContainer.id}`);
                      radioInput.style.display = "none";

                      radioInputNew.appendChild(radioInput);

                      let label = document.createElement("label");
                      label.setAttribute("type", "radio_label");
                      label.setAttribute("for", `${randomString}`);
                      // Object.assign(label.style, {
                      //   wordBreak: "break-all",
                      // });
                      label.innerHTML = `<p id="${generateRandomString(6 )}">No Values.</p>`;

                      groupEle.appendChild(radioInputNew);
                      groupEle.appendChild(label);

                      mainContainer.appendChild(groupEle);
                }
            }

            replicateStyle();
          } else {
            console.log(
              '"/editor/" & "?projectId=" substrings are present together in the browser URL.'
            );
          }
        }

        // helper function to apply styling
        function applyStyle(element, style) {
          if (!element) {
            console.error("Element is undefined:", element);
            return;
          }

          for (let key in style) {
            if (style.hasOwnProperty(key)) {
              element.style[key] = style[key];
            }
          }
        }

        // for style replication of first radio to the rest
        function replicateStyle() {
          if (props.ReplicateStyling) {
            // Get the first radio component
            let firstGroup = mainContainer.querySelector(".group_radio_label");

            if (firstGroup) {
              let firstGroupStyle = window.getComputedStyle(firstGroup);

              // Apply styling to all other group_radio_label components
              let groups = mainContainer.querySelectorAll(".group_radio_label");
              groups.forEach((group) => {
                if (group !== firstGroup) {
                  // Apply the style of the first group to each group
                  applyStyle(group, firstGroupStyle);

                  // Apply the style to the children of each group
                  let firstGroupChildren = firstGroup.querySelectorAll("*");
                  let groupChildren = group.querySelectorAll("*");

                  if (firstGroupChildren.length === groupChildren.length) {
                    for (let i = 0; i < firstGroupChildren.length; i++) {
                      // Apply styles to individual children
                      let firstChildStyle = window.getComputedStyle(
                        firstGroupChildren[i]
                      );
                      applyStyle(groupChildren[i], firstChildStyle);
                    }
                  } else {
                    console.error(
                      "Number of children elements is different between groups."
                    );
                  }
                }
              });
            } else {
              console.error("First group not found.");
            }
          }
        }

        replicateStyle();
      };

      editor.DomComponents.addType("radio_regular_form", {
        model: {
          defaults: {
            tagName: "span",
            // script: radioGroupScript,
            type: "radio_regular_form",
            script: radioRegularFormScript,
            attributes: {
              // id: "radio_regular_form",
              class: "radio_regular_form",
              value: "",
            },

            style: {
              display: "flex",
              "flex-wrap": "wrap",
              "align-items": "center",
              "justify-content": "flex-start",
              gap: "1%",
            },

            "style-default": {
              display: "flex",
              "flex-wrap": "wrap",
              "align-items": "center",
              "justify-content": "flex-start",
              gap: "1%",
            },

            components: [
              {
                tagName: "span",
                type: "default",
                attributes: {
                  class: "group_radio_label",
                },

                "style-default": {
                  display: "flex",
                  "flex-wrap": "no-wrap",
                  "justify-content": "flex-start",
                  "align-items": "flex-start",
                },

                style: {
                  display: "flex",
                  "flex-wrap": "no-wrap",
                  "justify-content": "flex-start",
                  "align-items": "flex-start",
                },

                components: [
                  {
                    type: "radio_input",
                  },
                  {
                    type: "radio_label",
                  },
                ],
              },
              {
                tagName: "span",
                type: "default",
                attributes: {
                  class: "group_radio_label",
                },

                "style-default": {
                  display: "flex",
                  "flex-wrap": "no-wrap",
                  "justify-content": "flex-start",
                  "align-items": "flex-start",
                },

                style: {
                  display: "flex",
                  "flex-wrap": "no-wrap",
                  "justify-content": "flex-start",
                  "align-items": "flex-start",
                },

                components: [
                  {
                    type: "radio_input",
                  },
                  {
                    type: "radio_label",
                  },
                ],
              },
            ],

            // traits
            CustomUrl: "",
            BaseUrl: "",
            APIPath: "",
            BearerToken: "",
            Username: "",
            Password: "",
            MoreHeaders: "",
            APIMethod: "GET",
            APIBody: "",
            ArrOfObjKyename: "",
            NestedKeyname: "",
            options: {
              baseUrl1,
              baseUrl2,
              baseUrl3,
              baseUrl4,
              baseUrl5,
              baseUrl1BearerToken,
              baseUrl2BearerToken,
              baseUrl3BearerToken,
              baseUrl4BearerToken,
              baseUrl5BearerToken,
            },

            // to give width to all the divs at once
            CommonWidth: "8",

            ReplicateStyling: "",

            projectId: localStorage.getItem("project_id")
              ? localStorage.getItem("project_id")
              : "",

            traits: [
              "id",
              {
                type: "text",
                name: "CustomUrl",
                label: "Custom URL",
                changeProp: true,
              },
              {
                type: "select",
                name: "BaseUrl",
                label: "Base URL",
                options: [
                  { id: "baseUrl1", name: "Base URL #1" },
                  { id: "baseUrl2", name: "Base URL #2" },
                  { id: "baseUrl3", name: "Base URL #3" },
                  { id: "baseUrl4", name: "Base URL #4" },
                  { id: "baseUrl5", name: "Base URL #5" },
                  { id: "null", name: "No Base URL" },
                ],
                changeProp: true,
              },
              {
                type: "text",
                name: "APIPath",
                label: "API Path",
                placeholder: "Don't start with '/'",
                changeProp: true,
              },
              {
                type: "text",
                name: "BearerToken",
                label: "Bearer Token",
                placeholder: "No bearer token provided",
                changeProp: true,
              },
              {
                type: "text",
                name: "Username",
                label: "Username",
                changeProp: true,
              },
              {
                type: "text",
                name: "Password",
                label: "Password",
                changeProp: true,
              },
              {
                type: "text",
                name: "MoreHeaders",
                label: "More Headers",
                placeholder: "k1:v1,k2:v2,k3:v3,...",
                changeProp: true,
              },
              {
                type: "select",
                name: "APIMethod",
                label: "API Method",
                options: [
                  { id: "GET", name: "Get" },
                  { id: "POST", name: "Post" },
                ],
                // value: "GET",
                changeProp: true,
              },
              {
                type: "text",
                name: "APIBody",
                label: "API Body",
                placeholder: "Json format",
                changeProp: true,
              },
              {
                type: "text",
                name: "ArrOfObjKyename",
                label: "Keyname From Array Of Object",
                placeholder: "[ {k1:v1}, {k1:v2} ]",
                changeProp: true,
              },
              {
                type: "text",
                name: "NestedKeyname",
                label: "Keyname For Nested Structure",
                placeholder: "{ k1: [{...}, {...}] }",
                changeProp: true,
              },
              {
                type: "number",
                name: "CommonWidth",
                label: "Common Width",
                placeholder: "Unit %",
                changeProp: true,
              },
              {
                type: "checkbox",
                name: "ReplicateStyling",
                label: "Replicate Styling",
                changeProp: true,
              },
            ],

            changeProp: true,
            "script-props": [
              "CustomUrl",
              "BaseUrl",
              "APIPath",
              "BearerToken",
              "Username",
              "Password",
              "MoreHeaders",
              "ArrOfObjKyename",
              "NestedKeyname",
              "options",
              "CommonWidth",
              "ReplicateStyling",
              "projectId",
            ],
          },

          init() {
            this.listenTo(this, "change:CustomUrl", this.handlePropChange);
            this.listenTo(this, "change:BaseUrl", this.handlePropChange);
            this.listenTo(this, "change:APIPath", this.handlePropChange);
            this.listenTo(this, "change:BearerToken", this.handlePropChange);
            this.listenTo(this, "change:Username", this.handlePropChange);
            this.listenTo(this, "change:Password", this.handlePropChange);
            this.listenTo(this, "change:MoreHeaders", this.handlePropChange);
            this.listenTo(this, "change:APIMethod", this.handlePropChange);
            this.listenTo(this, "change:APIBody", this.handlePropChange);
            this.listenTo(
              this,
              "change:ArrOfObjKyename",
              this.handlePropChange
            );
            this.listenTo(this, "change:NestedKeyname", this.handlePropChange);
            this.listenTo(this, "change:CommonWidth", this.commonWidthChange);
          },

          updated(property, value, prevValue) {
            console.log(
              "+++ property",
              property,
              "value",
              value,
              "prevValue",
              prevValue
            );
            console.log("this from updated()", this.getEl());

            // for editor part, it will not be in download for which script is written for style replication
            this.replicateStyling();
          },

          // only needed during development
          commonWidthChange() {
            const { CommonWidth } = this.props();

            const mainContainer = this;
            const groupRadioLabels = mainContainer.find(".group_radio_label");

            if (groupRadioLabels.length > 0) {
              groupRadioLabels.forEach((groupRadioLabel) => {
                groupRadioLabel.setStyle({
                  display: "flex",
                  "flex-wrap": "no-wrap",
                  "justify-content": "flex-start",
                  "align-items": "flex-start",
                  width: CommonWidth ? CommonWidth + "%" : "8% !important",
                });
              });
            }
          },

          // needed both in development and after download
          replicateStyling() {
            console.log("replicating style");
            const { ReplicateStyling } = this.props();
            const mainContainer = this;

            if (ReplicateStyling) {
              // Get the first group_radio_label component
              let firstGroup = mainContainer.find(".group_radio_label")[0];

              if (firstGroup) {
                let firstGroupStyle = firstGroup.getStyle();

                // Apply styling to all other group_checkbox_label components
                let groups = mainContainer.find(".group_radio_label");
                groups.forEach((group) => {
                  if (group !== firstGroup) {
                    // Apply the style of the first group to each group
                    group.setStyle(firstGroupStyle);

                    // Apply the style to the children of each group
                    let firstGroupChildren = firstGroup.find("*");
                    let groupChildren = group.find("*");

                    for (let i = 0; i < firstGroupChildren.length; i++) {
                      let firstChildStyle = firstGroupChildren[i].getStyle();
                      groupChildren[i].setStyle(firstChildStyle);
                    }
                  }
                });
              }
              this.view.render();
              console.log("replicated");
            }
          },

          async handlePropChange() {
            const {
              CustomUrl,
              BaseUrl,
              APIPath,
              BearerToken,
              Username,
              Password,
              MoreHeaders,
              APIMethod,
              APIBody,
              ArrOfObjKyename,
              NestedKeyname,
              CommonWidth,
            } = this.props();

            // to rename a variable because API var is getting used in many places in this block scope
            let API;
            if (APIPath) {
              API = APIPath;
            }

            let container = this;
            let containerId = this.getAttributes()["id"];
            console.log("container:", container, containerId);

            // helper function to check if a variable is an array-of-objects or not
            function isArrayofObjects(variable) {
              // Check if the variable is an array
              if (!Array.isArray(variable)) {
                return false;
              }

              // Check if all elements in the array are objects
              for (const element of variable) {
                if (
                  typeof element !== "object" ||
                  Array.isArray(element) ||
                  element === null
                ) {
                  return false;
                }
              }
              return true;
            }

            // helper function to get value of a key in nested object
            function findKeyValue(obj, key) {
              if (obj.hasOwnProperty(key)) {
                // If the current object has the key, return its value
                return obj[key];
              }

              for (var prop in obj) {
                if (obj.hasOwnProperty(prop) && typeof obj[prop] === "object") {
                  // If the current property is an object, recursively search within it
                  var result = findKeyValue(obj[prop], key);
                  if (result !== undefined) {
                    return result;
                  }
                }
              }
              // Key not found in the object
              return undefined;
            }

            let apiData;

            // -------- Getting API Data
            // to use API to populate the dropdown list
            // if CustomUrl is provided, use only that
            if (CustomUrl && (!BaseUrl || BaseUrl === "null")) {
              // if nothing is provided for auth
              if (!BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  headers: {},
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is provided but not api_key or usr+pwd
              else if (BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${BearerToken}`,
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token and api_key not provided but usr+pwd are provided
              else if (!BearerToken && Username && Password) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    Authorization: "Basic " + btoa(Username + ":" + Password),
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }
            }

            // if base url is the choice and provided
            else if (BaseUrl && API && !CustomUrl) {
              // concatenating base url and its remaining part
              let url, baseUrlBearerToken;
              if (BaseUrl === "baseUrl1") {
                url = baseUrl1 + API;
                baseUrlBearerToken = baseUrl1BearerToken;
              } else if (BaseUrl === "baseUrl2") {
                url = baseUrl2 + API;
                baseUrlBearerToken = baseUrl2BearerToken;
              } else if (BaseUrl === "baseUrl3") {
                url = baseUrl3 + API;
                baseUrlBearerToken = baseUrl3BearerToken;
              } else if (BaseUrl === "baseUrl4") {
                url = baseUrl4 + API;
                baseUrlBearerToken = baseUrl4BearerToken;
              } else if (BaseUrl === "baseUrl5") {
                url = baseUrl5 + API;
                baseUrlBearerToken = baseUrl5BearerToken;
              }
              console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

              // if nothing is provided for auth
              if (
                !BearerToken &&
                !baseUrlBearerToken &&
                !Username &&
                !Password
              ) {
                // add extra headers, if needed
                let config = {
                  headers: {},
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is provided but not usr+pwd
              else if (BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${BearerToken}`,
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a baseUrlBearerToken is provided but not Custom Bearer Token and usr+pwd
              else if (
                baseUrlBearerToken &&
                !BearerToken &&
                !Username &&
                !Password
              ) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${baseUrlBearerToken}`,
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config@:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is not provided but usr+pwd are provided
              else if (!BearerToken && Username && Password) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    Authorization: "Basic " + btoa(Username + ":" + Password),
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }
            }

            // POST method
            // if CustomUrl is provided, use only that
            if (
              CustomUrl &&
              (!BaseUrl || BaseUrl === "null") &&
              APIMethod === "POST"
            ) {
              // if nothing is provided for auth
              if (!BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {},
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is provided but not api_key or usr+pwd
              else if (BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${BearerToken}`,
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token and api_key not provided but usr+pwd are provided
              else if (!BearerToken && Username && Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    Authorization: "Basic " + btoa(Username + ":" + Password),
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }
            }

            // if base url is the choice and provided
            if (BaseUrl && API && !CustomUrl && APIMethod === "POST") {
              // concatenating base url and its remaining part
              let url, baseUrlBearerToken;
              if (BaseUrl === "baseUrl1") {
                url = baseUrl1 + API;
                baseUrlBearerToken = baseUrl1BearerToken;
              } else if (BaseUrl === "baseUrl2") {
                url = baseUrl2 + API;
                baseUrlBearerToken = baseUrl2BearerToken;
              } else if (BaseUrl === "baseUrl3") {
                url = baseUrl3 + API;
                baseUrlBearerToken = baseUrl3BearerToken;
              } else if (BaseUrl === "baseUrl4") {
                url = baseUrl4 + API;
                baseUrlBearerToken = baseUrl4BearerToken;
              } else if (BaseUrl === "baseUrl5") {
                url = baseUrl5 + API;
                baseUrlBearerToken = baseUrl5BearerToken;
              }
              console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

              // if nothing is provided for auth
              if (
                !BearerToken &&
                !baseUrlBearerToken &&
                !Username &&
                !Password
              ) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {},
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is provided but not usr+pwd
              else if (BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${BearerToken}`,
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a baseUrlBearerToken is provided but not Custom Bearer Token and usr+pwd
              else if (
                baseUrlBearerToken &&
                !BearerToken &&
                !Username &&
                !Password
              ) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${baseUrlBearerToken}`,
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config@:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is not provided but usr+pwd are provided
              else if (!BearerToken && Username && Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    Authorization: "Basic " + btoa(Username + ":" + Password),
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }
            }

            console.log("apiData:", apiData);

            // -------- Populating API Data, into the dropdown list
            if (apiData !== undefined) {
              console.log("#");
              // if API response is directly an array-of-objects
              if (isArrayofObjects(apiData)) {
                if (ArrOfObjKyename) {
                  console.log("##");
                  // container.components("");

                  // dynamic add
                  // to check if any '_id' data was changed in the backend object, then update the frontend labels of radio also
                  if (container.components().length > 0) {
                    // check if these are database plotted radio or not
                    let isNotDefaultRadio = "data_db_id" in container .find('input[type="radio"]')[0] .getAttributes() && container.find('input[type="radio"]')[0].getAttributes()["data_db_id" ] !== "";
                    console.log("isNotDefaultRadio", isNotDefaultRadio);

                    // if they are database plotted radio then scan through them using data_db_id and update if any
                    if (isNotDefaultRadio) {
                      let allMiniContainers = container.find(".group_radio_label");
                      allMiniContainers.forEach((groupRadioLabel, idx) => {
                        let radio = groupRadioLabel.find('input[type="radio"]' )[0];
                        let radio_dataDbId = groupRadioLabel .find('input[type="radio"]')[0] .getAttributes()["data_db_id"];
                        let label = groupRadioLabel.find("label")[0];

                        // get the object from apiData that has the radio
                        let currentObj = apiData.filter((obj) => obj["_id"] === radio_dataDbId );

                        if (currentObj.length > 0) {
                          let labelInnerText = label
                            .components()
                            .models[0].components()
                            .models[0].get("content");
                          // console.log('*+*+*+ labelInnerText 1', label.components().models[0].components().models[0].get('content'), '2', label.getLastChild().getLastChild().get('content'));

                          currentObj.forEach((obj) => {
                            // check if the IDs match, if not then update the label's inner text and value of radio
                            if (labelInnerText !== obj[`${ArrOfObjKyename}`]) {
                              // console.log('*+*+*+', labelInnerText, '------', obj[`${ArrOfObjKyename}`]);
                              label
                                .components()
                                .models[0].components()
                                .models[0].set(
                                  "content",
                                  obj[`${ArrOfObjKyename}`]
                                );
                              radio.addAttributes({
                                value: obj[`${ArrOfObjKyename}`],
                              });
                              // to rerender the view of the component to show that it has been updated in the editor, instantly
                              this.view.render();
                              console.log("currentObj", currentObj);
                            }
                          });
                        }
                      });

                      // if there are equal number of objects in apiData as checkboxes then plot new ones
                      if (apiData.length >= allMiniContainers.length) {
                        container.components("");
                        apiData.forEach((opt) => {
                          let groupEle = {
                            tagName: "span",
                            type: "default",
                            attributes: {
                              class: "group_radio_label",
                            },

                            "style-default": {
                              display: "flex",
                              "flex-wrap": "no-wrap",
                              "justify-content": "flex-start",
                              "align-items": "flex-start",
                              width: CommonWidth
                                ? CommonWidth + "%"
                                : "8% !important",
                            },

                            style: {
                              display: "flex",
                              "flex-wrap": "no-wrap",
                              "justify-content": "flex-start",
                              "align-items": "flex-start",
                              width: CommonWidth
                                ? CommonWidth + "%"
                                : "8% !important",
                            },

                            components: [
                              {
                                tagName: "span",
                                type: "radio_input",
                                script: individualRadioScript,

                                attributes: {
                                  class: "radio_input",
                                },

                                components: [
                                  {
                                    tagName: "input",
                                    type: "default",
                                    attributes: {
                                      type: "radio",
                                      id: `radio_1_${uniqid()}`,
                                      name: `radio_${containerId}`,
                                      value: `${opt[`${ArrOfObjKyename}`]}`,
                                      data_db_id: `${opt[`_id`]}`
                                        ? `${opt[`_id`]}`
                                        : `${opt[`${ArrOfObjKyename}`]}`,
                                    },
                                  },
                                ],

                                Autofocus: false,
                                Disabled: false,
                                Required: false,
                                Checked: false,

                                traits: [
                                  "id",
                                  "name",
                                  "value",
                                  {
                                    type: "checkbox",
                                    name: "Autofocus",
                                    label: "Autofocus",
                                    changeProp: true,
                                  },
                                  {
                                    type: "checkbox",
                                    name: "Disabled",
                                    label: "Disabled",
                                    changeProp: true,
                                  },
                                  {
                                    type: "checkbox",
                                    name: "Required",
                                    label: "Required",
                                    changeProp: true,
                                  },
                                  {
                                    type: "checkbox",
                                    name: "Checked",
                                    label: "Checked",
                                    changeProp: true,
                                  },
                                ],

                                changeProp: true,
                                "script-props": [
                                  "Autofocus",
                                  "Disabled",
                                  "Required",
                                  "Checked",
                                ],
                              },
                              {
                                tagName: "label",
                                attributes: {
                                  for: ``,
                                },

                                components: [
                                  {
                                    tagName: "p",
                                    type: "text",
                                    editable: true,

                                    style: {
                                      "word-break": "break-all",
                                    },

                                    "style-default": {
                                      "word-break": "break-all",
                                    },

                                    components: {
                                      type: "textnode",
                                      content: `${opt[`${ArrOfObjKyename}`]}`,
                                    },
                                  },
                                ],

                                traits: ["id", "for"],
                                changeProp: true,
                              },
                            ],
                          };

                          container.append(groupEle);
                        });
                      }
                    } else {
                      container.components("");
                      apiData.forEach((opt) => {
                        let groupEle = {
                          tagName: "span",
                          type: "default",
                          attributes: {
                            class: "group_radio_label",
                          },

                          "style-default": {
                            display: "flex",
                            "flex-wrap": "no-wrap",
                            "justify-content": "flex-start",
                            "align-items": "flex-start",
                            width: CommonWidth
                              ? CommonWidth + "%"
                              : "8% !important",
                          },

                          style: {
                            display: "flex",
                            "flex-wrap": "no-wrap",
                            "justify-content": "flex-start",
                            "align-items": "flex-start",
                            width: CommonWidth
                              ? CommonWidth + "%"
                              : "8% !important",
                          },

                          components: [
                            {
                              tagName: "span",
                              type: "radio_input",
                              script: individualRadioScript,

                              attributes: {
                                class: "radio_input",
                              },

                              components: [
                                {
                                  tagName: "input",
                                  type: "default",
                                  attributes: {
                                    type: "radio",
                                    id: `radio_1_${uniqid()}`,
                                    name: `radio_${containerId}`,
                                    value: `${opt[`${ArrOfObjKyename}`]}`,
                                    data_db_id: `${opt[`_id`]}`
                                      ? `${opt[`_id`]}`
                                      : `${opt[`${ArrOfObjKyename}`]}`,
                                  },
                                },
                              ],

                              Autofocus: false,
                              Disabled: false,
                              Required: false,
                              Checked: false,

                              traits: [
                                "id",
                                "name",
                                "value",
                                {
                                  type: "checkbox",
                                  name: "Autofocus",
                                  label: "Autofocus",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Disabled",
                                  label: "Disabled",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Required",
                                  label: "Required",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Checked",
                                  label: "Checked",
                                  changeProp: true,
                                },
                              ],

                              changeProp: true,
                              "script-props": [
                                "Autofocus",
                                "Disabled",
                                "Required",
                                "Checked",
                              ],
                            },
                            {
                              tagName: "label",
                              attributes: {
                                for: ``,
                              },

                              components: [
                                {
                                  tagName: "p",
                                  type: "text",
                                  editable: true,

                                  style: {
                                    "word-break": "break-all",
                                  },

                                  "style-default": {
                                    "word-break": "break-all",
                                  },

                                  components: {
                                    type: "textnode",
                                    content: `${opt[`${ArrOfObjKyename}`]}`,
                                  },
                                },
                              ],

                              traits: ["id", "for"],
                              changeProp: true,
                            },
                          ],
                        };

                        container.append(groupEle);
                      });
                    }
                  }

                  // reasons -
                  // container.components().length === 0 : plot radio if there are no radio present in the container
                  // container.components().length === 2 : plot radio the first time when there are the 2 initial radio
                  // apiData.length > container.components().length : plot radio if apiData has more objects than radio in the container meaning update the radio when there is an update done in the backend database, make this dynamic
                  if (
                    container.components().length === 0 ||
                    (container.components().length === 2 && apiData.length > 2)
                  ) {
                    // clear the body and then plot because the first-time plotting will happen here
                    console.log("#*");
                    container.components("");
                    apiData.forEach((opt) => {
                      let groupEle = {
                        tagName: "span",
                        type: "default",
                        attributes: {
                          class: "group_radio_label",
                        },

                        "style-default": {
                          display: "flex",
                          "flex-wrap": "no-wrap",
                          "justify-content": "flex-start",
                          "align-items": "flex-start",
                          width: CommonWidth
                            ? CommonWidth + "%"
                            : "8% !important",
                        },

                        style: {
                          display: "flex",
                          "flex-wrap": "no-wrap",
                          "justify-content": "flex-start",
                          "align-items": "flex-start",
                          width: CommonWidth
                            ? CommonWidth + "%"
                            : "8% !important",
                        },

                        components: [
                          {
                            tagName: "span",
                            type: "radio_input",
                            script: individualRadioScript,

                            attributes: {
                              class: "radio_input",
                            },

                            components: [
                              {
                                tagName: "input",
                                type: "default",
                                attributes: {
                                  type: "radio",
                                  id: `radio_1_${uniqid()}`,
                                  name: `radio_${containerId}`,
                                  value: `${opt[`${ArrOfObjKyename}`]}`,
                                  data_db_id: `${opt[`_id`]}`
                                    ? `${opt[`_id`]}`
                                    : `${opt[`${ArrOfObjKyename}`]}`,
                                },
                              },
                            ],

                            Autofocus: false,
                            Disabled: false,
                            Required: false,
                            Checked: false,

                            traits: [
                              "id",
                              "name",
                              "value",
                              {
                                type: "checkbox",
                                name: "Autofocus",
                                label: "Autofocus",
                                changeProp: true,
                              },
                              {
                                type: "checkbox",
                                name: "Disabled",
                                label: "Disabled",
                                changeProp: true,
                              },
                              {
                                type: "checkbox",
                                name: "Required",
                                label: "Required",
                                changeProp: true,
                              },
                              {
                                type: "checkbox",
                                name: "Checked",
                                label: "Checked",
                                changeProp: true,
                              },
                            ],

                            changeProp: true,
                            "script-props": [
                              "Autofocus",
                              "Disabled",
                              "Required",
                              "Checked",
                            ],
                          },
                          {
                            tagName: "label",
                            attributes: {
                              for: ``,
                            },

                            components: [
                              {
                                tagName: "p",
                                type: "text",
                                editable: true,

                                style: {
                                  "word-break": "break-all",
                                },

                                "style-default": {
                                  "word-break": "break-all",
                                },

                                components: {
                                  type: "textnode",
                                  content: `${opt[`${ArrOfObjKyename}`]}`,
                                },
                              },
                            ],

                            traits: ["id", "for"],
                            changeProp: true,
                          },
                        ],
                      };

                      // let inputEle = {
                      //   tagName: "input",
                      //   type: "default", // so that the input component doesn't throw error of 'undefined'

                      //   script: individualCheckboxScript,

                      //   attributes: {
                      //     type: "checkbox",
                      //     // id: `${opt[`${ArrOfObjKyename}`]}`,
                      //     name: `${opt[`${ArrOfObjKyename}`]}`,
                      //     value: `${opt[`${ArrOfObjKyename}`]}`,
                      //   },

                      //   Autofocus: false,
                      //   Disabled: false,
                      //   Required: false,
                      //   Checked: false,

                      //   traits: [
                      //     "id",
                      //     "name",
                      //     "value",
                      //     {
                      //       type: "checkbox",
                      //       name: "Autofocus",
                      //       label: "Autofocus",
                      //       changeProp: true,
                      //     },
                      //     {
                      //       type: "checkbox",
                      //       name: "Disabled",
                      //       label: "Disabled",
                      //       changeProp: true,
                      //     },
                      //     {
                      //       type: "checkbox",
                      //       name: "Required",
                      //       label: "Required",
                      //       changeProp: true,
                      //     },
                      //     {
                      //       type: "checkbox",
                      //       name: "Checked",
                      //       label: "Checked",
                      //       changeProp: true,
                      //     },
                      //   ],

                      //   changeProp: true,
                      //   "script-props": [
                      //     "Autofocus",
                      //     "Disabled",
                      //     "Required",
                      //     "Checked",
                      //   ],
                      // };

                      // let labelEle = {
                      //   tagName: "label",
                      //   attributes: {
                      //     for: ``,
                      //   },

                      //   components: [
                      //     {
                      //       tagName: "p",
                      //       type: "text",
                      //       editable: true,

                      //       components: {
                      //         type: "textnode",
                      //         content: `${opt[`${ArrOfObjKyename}`]}`,
                      //       },
                      //     },
                      //   ],

                      //   traits: ["id", "for"],
                      // };

                      // container.append(inputEle);
                      // container.append(labelEle);
                      container.append(groupEle);
                    });
                  }

                  // update only when the radio have already been plotted previously from apiData but not updating if the apiData had only 2 fields
                  if (
                    apiData.length > container.components().length &&
                    container.components().length > 2
                  ) {
                    // only add what's missing from api as a dynamic update
                    console.log("#**");
                    // get all the labels' inner content and compare it with the keyname of the apiData to be plotted and add the ones missing
                    let allLabelEles = container.find("label");
                    let allLabels = allLabelEles.map(
                      (label) =>
                        label.find('p[data-gjs-type="text"]')[0].getEl()
                          .innerHTML
                    );
                    console.log("allLabelEles", allLabelEles, allLabels);

                    if (allLabelEles.length > 0) {
                      // if you find a label thats not in the apiData then add it to the existing radio container
                      apiData.forEach((dbRow, idx) => {
                        // if that object from apiData is not present in the radio container then plot it
                        if (!allLabels.includes(dbRow[`${ArrOfObjKyename}`])) {
                          let groupEle = {
                            tagName: "span",
                            type: "default",
                            attributes: {
                              class: "group_radio_label",
                            },

                            "style-default": {
                              display: "flex",
                              "flex-wrap": "no-wrap",
                              "justify-content": "flex-start",
                              "align-items": "flex-start",
                              width: CommonWidth
                                ? CommonWidth + "%"
                                : "8% !important",
                            },

                            style: {
                              display: "flex",
                              "flex-wrap": "no-wrap",
                              "justify-content": "flex-start",
                              "align-items": "flex-start",
                              width: CommonWidth
                                ? CommonWidth + "%"
                                : "8% !important",
                            },

                            components: [
                              {
                                tagName: "span",
                                type: "radio_input",
                                script: individualRadioScript,

                                attributes: {
                                  class: "radio_input",
                                },

                                components: [
                                  {
                                    tagName: "input",
                                    type: "default",
                                    attributes: {
                                      type: "radio",
                                      id: `radio_1_${uniqid()}`,
                                      name: `radio_${containerId}`,
                                      value: `${dbRow[`${ArrOfObjKyename}`]}`,
                                      data_db_id: `${dbRow[`_id`]}`
                                        ? `${dbRow[`_id`]}`
                                        : `${dbRow[`${ArrOfObjKyename}`]}`,
                                    },
                                  },
                                ],

                                Autofocus: false,
                                Disabled: false,
                                Required: false,
                                Checked: false,

                                traits: [
                                  "id",
                                  "name",
                                  "value",
                                  {
                                    type: "checkbox",
                                    name: "Autofocus",
                                    label: "Autofocus",
                                    changeProp: true,
                                  },
                                  {
                                    type: "checkbox",
                                    name: "Disabled",
                                    label: "Disabled",
                                    changeProp: true,
                                  },
                                  {
                                    type: "checkbox",
                                    name: "Required",
                                    label: "Required",
                                    changeProp: true,
                                  },
                                  {
                                    type: "checkbox",
                                    name: "Checked",
                                    label: "Checked",
                                    changeProp: true,
                                  },
                                ],

                                changeProp: true,
                                "script-props": [
                                  "Autofocus",
                                  "Disabled",
                                  "Required",
                                  "Checked",
                                ],
                              },
                              {
                                tagName: "label",
                                attributes: {
                                  for: ``,
                                },

                                components: [
                                  {
                                    tagName: "p",
                                    type: "text",
                                    editable: true,

                                    style: {
                                      "word-break": "break-all",
                                    },

                                    "style-default": {
                                      "word-break": "break-all",
                                    },

                                    components: {
                                      type: "textnode",
                                      content: `${dbRow[`${ArrOfObjKyename}`]}`,
                                    },
                                  },
                                ],

                                traits: ["id", "for"],
                                changeProp: true,
                              },
                            ],
                          };

                          container.append(groupEle);
                        }
                      });
                    }
                  }

                  // plot when the apiData has only around 2 entries
                  else if (apiData.length <= 2) {
                    console.log("_");

                    // see if the radio are the default radio by checking if they have this attribute 'data_db_id', if yes then clear the body and append new else append new radio anyways
                    let isNotDefaultCheckbox =
                      "data_db_id" in
                      container.find('input[type="radio"]')[0].getAttributes();
                    console.log("isNotDefaultCheckbox", isNotDefaultCheckbox);

                    // if it is a default radio then empty the body before appending apiData radio
                    if (!isNotDefaultCheckbox) {
                      container.components("");
                      apiData.forEach((opt) => {
                        let groupEle = {
                          tagName: "span",
                          type: "default",
                          attributes: {
                            class: "group_radio_label",
                          },

                          "style-default": {
                            display: "flex",
                            "flex-wrap": "no-wrap",
                            "justify-content": "flex-start",
                            "align-items": "flex-start",
                            width: CommonWidth
                              ? CommonWidth + "%"
                              : "8% !important",
                          },

                          style: {
                            display: "flex",
                            "flex-wrap": "no-wrap",
                            "justify-content": "flex-start",
                            "align-items": "flex-start",
                            width: CommonWidth
                              ? CommonWidth + "%"
                              : "8% !important",
                          },

                          components: [
                            {
                              tagName: "span",
                              type: "radio_input",
                              script: individualRadioScript,

                              attributes: {
                                class: "radio_input",
                              },

                              components: [
                                {
                                  tagName: "input",
                                  type: "default",
                                  attributes: {
                                    type: "radio",
                                    id: `radio_1_${uniqid()}`,
                                    name: `radio_${containerId}`,
                                    value: `${opt[`${ArrOfObjKyename}`]}`,
                                    data_db_id: `${opt[`_id`]}`
                                      ? `${opt[`_id`]}`
                                      : `${opt[`${ArrOfObjKyename}`]}`,
                                  },
                                },
                              ],

                              Autofocus: false,
                              Disabled: false,
                              Required: false,
                              Checked: false,

                              traits: [
                                "id",
                                "name",
                                "value",
                                {
                                  type: "checkbox",
                                  name: "Autofocus",
                                  label: "Autofocus",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Disabled",
                                  label: "Disabled",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Required",
                                  label: "Required",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Checked",
                                  label: "Checked",
                                  changeProp: true,
                                },
                              ],

                              changeProp: true,
                              "script-props": [
                                "Autofocus",
                                "Disabled",
                                "Required",
                                "Checked",
                              ],
                            },
                            {
                              tagName: "label",
                              attributes: {
                                for: ``,
                              },

                              components: [
                                {
                                  tagName: "p",
                                  type: "text",
                                  editable: true,

                                  style: {
                                    "word-break": "break-all",
                                  },

                                  "style-default": {
                                    "word-break": "break-all",
                                  },

                                  components: {
                                    type: "textnode",
                                    content: `${opt[`${ArrOfObjKyename}`]}`,
                                  },
                                },
                              ],

                              traits: ["id", "for"],
                              changeProp: true,
                            },
                          ],
                        };
                        container.append(groupEle);
                        console.log("appended", opt);
                      });
                    } else {
                      container.components("");
                      apiData.forEach((opt) => {
                        let groupEle = {
                          tagName: "span",
                          type: "default",
                          attributes: {
                            class: "group_radio_label",
                          },

                          "style-default": {
                            display: "flex",
                            "flex-wrap": "no-wrap",
                            "justify-content": "flex-start",
                            "align-items": "flex-start",
                            width: CommonWidth
                              ? CommonWidth + "%"
                              : "8% !important",
                          },

                          style: {
                            display: "flex",
                            "flex-wrap": "no-wrap",
                            "justify-content": "flex-start",
                            "align-items": "flex-start",
                            width: CommonWidth
                              ? CommonWidth + "%"
                              : "8% !important",
                          },

                          components: [
                            {
                              tagName: "span",
                              type: "radio_input",
                              script: individualRadioScript,

                              attributes: {
                                class: "radio_input",
                              },

                              components: [
                                {
                                  tagName: "input",
                                  type: "default",
                                  attributes: {
                                    type: "radio",
                                    id: `radio_1_${uniqid()}`,
                                    name: `radio_${containerId}`,
                                    value: `${opt[`${ArrOfObjKyename}`]}`,
                                    data_db_id: `${opt[`_id`]}`
                                      ? `${opt[`_id`]}`
                                      : `${opt[`${ArrOfObjKyename}`]}`,
                                  },
                                },
                              ],

                              Autofocus: false,
                              Disabled: false,
                              Required: false,
                              Checked: false,

                              traits: [
                                "id",
                                "name",
                                "value",
                                {
                                  type: "checkbox",
                                  name: "Autofocus",
                                  label: "Autofocus",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Disabled",
                                  label: "Disabled",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Required",
                                  label: "Required",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Checked",
                                  label: "Checked",
                                  changeProp: true,
                                },
                              ],

                              changeProp: true,
                              "script-props": [
                                "Autofocus",
                                "Disabled",
                                "Required",
                                "Checked",
                              ],
                            },
                            {
                              tagName: "label",
                              attributes: {
                                for: ``,
                              },

                              components: [
                                {
                                  tagName: "p",
                                  type: "text",
                                  editable: true,

                                  style: {
                                    "word-break": "break-all",
                                  },

                                  "style-default": {
                                    "word-break": "break-all",
                                  },

                                  components: {
                                    type: "textnode",
                                    content: `${opt[`${ArrOfObjKyename}`]}`,
                                  },
                                },
                              ],

                              traits: ["id", "for"],
                              changeProp: true,
                            },
                          ],
                        };

                        container.append(groupEle);
                      });
                    }
                  }

                  // dynamic delete
                  if (container.components().length > apiData.length) {
                    console.log("removing extra group_radio_labels");

                    let groupRadioLabels = container.find(".group_radio_label");
                    groupRadioLabels.forEach((groupRadioLabel) => {
                      // get the data_db_url from radio and check if _id is present in apiData, if not, remove it
                      let radio = groupRadioLabel
                        .find(".radio_input")[0]
                        .find('input[type="radio"]')[0];
                      let radioDataDbId = radio.getAttributes()["data_db_id"];

                      // see if radioDataDbId is in apiData _ids and if not there, delete this group_radio_label
                      let apiData_id = apiData.map((obj) => obj["_id"]);

                      if (!apiData_id.includes(radioDataDbId)) {
                        groupRadioLabel.remove();
                        console.log("removed");
                      }
                    });
                  }
                }
              }

              // if API response is a plain array of single elements
              else if (Array.isArray(apiData) && !isArrayofObjects(apiData)) {
                console.log("###");

                // either no radio are plotted or the default 2 radio are there
                if (
                  container.components().length === 0 ||
                  container.components().length <= 2
                ) {
                  container.components("");
                  apiData.forEach((opt) => {
                    let groupEle = {
                      tagName: "span",
                      type: "default",
                      attributes: {
                        class: "group_radio_label",
                      },

                      "style-default": {
                        display: "flex",
                        "flex-wrap": "no-wrap",
                        "justify-content": "flex-start",
                        "align-items": "flex-start",
                        width: CommonWidth
                          ? CommonWidth + "%"
                          : "8% !important",
                      },

                      style: {
                        display: "flex",
                        "flex-wrap": "no-wrap",
                        "justify-content": "flex-start",
                        "align-items": "flex-start",
                        width: CommonWidth
                          ? CommonWidth + "%"
                          : "8% !important",
                      },

                      components: [
                        {
                          tagName: "span",
                          type: "radio_input",
                          script: individualRadioScript,

                          attributes: {
                            class: "radio_input",
                          },

                          components: [
                            {
                              tagName: "input",
                              type: "default",
                              attributes: {
                                type: "radio",
                                id: `radio_1_${uniqid()}`,
                                name: `radio_${containerId}`,
                                value: `${opt}`,
                              },
                            },
                          ],

                          Autofocus: false,
                          Disabled: false,
                          Required: false,
                          Checked: false,

                          traits: [
                            "id",
                            "name",
                            "value",
                            {
                              type: "checkbox",
                              name: "Autofocus",
                              label: "Autofocus",
                              changeProp: true,
                            },
                            {
                              type: "checkbox",
                              name: "Disabled",
                              label: "Disabled",
                              changeProp: true,
                            },
                            {
                              type: "checkbox",
                              name: "Required",
                              label: "Required",
                              changeProp: true,
                            },
                            {
                              type: "checkbox",
                              name: "Checked",
                              label: "Checked",
                              changeProp: true,
                            },
                          ],

                          changeProp: true,
                          "script-props": [
                            "Autofocus",
                            "Disabled",
                            "Required",
                            "Checked",
                          ],
                        },
                        {
                          tagName: "label",
                          attributes: {
                            for: ``,
                          },

                          components: [
                            {
                              tagName: "p",
                              type: "text",
                              editable: true,

                              style: {
                                "word-break": "break-all",
                              },

                              "style-default": {
                                "word-break": "break-all",
                              },

                              components: {
                                type: "textnode",
                                content: `${opt}`,
                              },
                            },
                          ],

                          traits: ["id", "for"],
                        },
                      ],
                    };

                    // let inputEle = {
                    //   tagName: "input",
                    //   type: "default", // so that the input component doesn't throw error of 'undefined'

                    //   script: individualCheckboxScript,

                    //   attributes: {
                    //     type: "checkbox",
                    //     // id: `ch-${opt}`,
                    //     name: `ch-${opt}`,
                    //     value: `${opt}`,
                    //   },

                    //   Autofocus: false,
                    //   Disabled: false,
                    //   Required: false,
                    //   Checked: false,

                    //   traits: [
                    //     "id",
                    //     "name",
                    //     "value",
                    //     {
                    //       type: "checkbox",
                    //       name: "Autofocus",
                    //       label: "Autofocus",
                    //       changeProp: true,
                    //     },
                    //     {
                    //       type: "checkbox",
                    //       name: "Disabled",
                    //       label: "Disabled",
                    //       changeProp: true,
                    //     },
                    //     {
                    //       type: "checkbox",
                    //       name: "Required",
                    //       label: "Required",
                    //       changeProp: true,
                    //     },
                    //     {
                    //       type: "checkbox",
                    //       name: "Checked",
                    //       label: "Checked",
                    //       changeProp: true,
                    //     },
                    //   ],

                    //   changeProp: true,
                    //   "script-props": [
                    //     "Autofocus",
                    //     "Disabled",
                    //     "Required",
                    //     "Checked",
                    //   ],
                    // };

                    // let labelEle = {
                    //   tagName: "label",
                    //   attributes: {
                    //     for: ``,
                    //   },

                    //   components: [
                    //     {
                    //       tagName: "p",
                    //       type: "text",
                    //       editable: true,

                    //       components: {
                    //         type: "textnode",
                    //         content: `${opt}`,
                    //       },
                    //     },
                    //   ],

                    //   traits: ["id", "for"],
                    // };

                    // container.append(inputEle);
                    // container.append(labelEle);
                    container.append(groupEle);
                  });
                }

                // else if the apiData radio are already plotted then check for updated values and add those here without changing the IDd of already plotted ones
                else if (
                  apiData.length > container.components().length &&
                  container.components().length > 2
                ) {
                  // only add what's missing from api as a dynamic update

                  // get all the labels' inner content and compare it with the keyname of the apiData to be plotted and add the ones missing
                  let allLabelEles = container.find("label");
                  let allLabels = allLabelEles.map(
                    (label) =>
                      label.find('p[data-gjs-type="text"]')[0].getEl().innerHTML
                  );
                  console.log("allLabelEles", allLabelEles, allLabels);

                  if (allLabelEles.length > 0) {
                    // if you find a label thats not in the apiData then add it to the existing radio container
                    apiData.forEach((opt, idx) => {
                      // if that object from apiData is not present in the radio container then plot it
                      if (!allLabels.includes(opt)) {
                        let groupEle = {
                          tagName: "span",
                          type: "default",
                          attributes: {
                            class: "group_radio_label",
                          },

                          "style-default": {
                            display: "flex",
                            "flex-wrap": "no-wrap",
                            "justify-content": "flex-start",
                            "align-items": "flex-start",
                            width: CommonWidth
                              ? CommonWidth + "%"
                              : "8% !important",
                          },

                          style: {
                            display: "flex",
                            "flex-wrap": "no-wrap",
                            "justify-content": "flex-start",
                            "align-items": "flex-start",
                            width: CommonWidth
                              ? CommonWidth + "%"
                              : "8% !important",
                          },

                          components: [
                            {
                              tagName: "span",
                              type: "radio_input",
                              script: individualRadioScript,

                              attributes: {
                                class: "radio_input",
                              },

                              components: [
                                {
                                  tagName: "input",
                                  type: "default",
                                  attributes: {
                                    type: "radio",
                                    id: `radio_1_${uniqid()}`,
                                    name: `radio_${containerId}`,
                                    value: `${opt}`,
                                  },
                                },
                              ],

                              Autofocus: false,
                              Disabled: false,
                              Required: false,
                              Checked: false,

                              traits: [
                                "id",
                                "name",
                                "value",
                                {
                                  type: "checkbox",
                                  name: "Autofocus",
                                  label: "Autofocus",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Disabled",
                                  label: "Disabled",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Required",
                                  label: "Required",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Checked",
                                  label: "Checked",
                                  changeProp: true,
                                },
                              ],

                              changeProp: true,
                              "script-props": [
                                "Autofocus",
                                "Disabled",
                                "Required",
                                "Checked",
                              ],
                            },
                            {
                              tagName: "label",
                              attributes: {
                                for: ``,
                              },

                              components: [
                                {
                                  tagName: "p",
                                  type: "text",
                                  editable: true,

                                  style: {
                                    "word-break": "break-all",
                                  },

                                  "style-default": {
                                    "word-break": "break-all",
                                  },

                                  components: {
                                    type: "textnode",
                                    content: `${opt}`,
                                  },
                                },
                              ],

                              traits: ["id", "for"],
                            },
                          ],
                        };

                        container.append(groupEle);
                      }
                    });
                  }
                }
              }

              // if API response is an object
              else if (!isArrayofObjects(apiData) && typeof apiData === "object" ) {
                // if API response to plot is a nested array-of-objects inside the main response object
                if (NestedKeyname && ArrOfObjKyename) {
                  console.log("####");
                  // container.components("");

                  // find the array-of-objects value
                  let data = findKeyValue(apiData, NestedKeyname);

                  if (isArrayofObjects(data)) {
                    // to check if any '_id' data was changed in the backend object, then update the frontend labels of radio also
                    if (container.components().length > 0) {
                      // check if these are database plotted radio or not
                      let isNotDefaultRadio =
                        "data_db_id" in
                        container
                          .find('input[type="radio"]')[0]
                          .getAttributes();
                      console.log("isNotDefaultRadio", isNotDefaultRadio);

                      // if they are database plotted radio then scan through them using data_db_id and update if any
                      if (isNotDefaultRadio) {
                        let allMiniContainers =
                          container.find(".group_radio_label");
                        allMiniContainers.forEach((groupRadioLabel, idx) => {
                          let radio_dataDbId = groupRadioLabel
                            .find('input[type="radio"]')[0]
                            .getAttributes()["data_db_id"];
                          let label = groupRadioLabel.find("label")[0];

                          // get the object from apiData that has the radio
                          let currentObj = apiData.filter(
                            (obj) => obj["_id"] === radio_dataDbId
                          );

                          if (currentObj.length > 0) {
                            currentObj.forEach((obj) => {
                              let labelInnerText = label
                                .find('p[data-gjs-type="text"]')[0]
                                .getEl().innerHTML;

                              // check if the IDs match, if not then update the label's inner text
                              if (
                                labelInnerText !== obj[`${ArrOfObjKyename}`]
                              ) {
                                label
                                  .getLastChild()
                                  .getLastChild()
                                  .set("content", obj[`${ArrOfObjKyename}`]);
                                // to rerender the view of the component to show that it has been updated in the editor, instantly
                                this.view.render();
                                console.log("currentObj", currentObj);
                              }
                            });
                          }
                        });
                      }
                    }

                    // reasons -
                    // container.components().length === 0 : plot radio if there are no radio present in the container
                    // container.components().length === 2 : plot radio the first time when there are the 2 initial radio
                    // data.length > container.components().length : plot radio if apiData has more objects than radio in the container meaning update the radio when there is an update done in the backend database, make this dynamic
                    if (
                      container.components().length === 0 ||
                      (container.components().length === 2 && data.length > 2)
                    ) {
                      container.components("");
                      data.forEach((opt) => {
                        let groupEle = {
                          tagName: "span",
                          type: "default",
                          attributes: {
                            class: "group_radio_label",
                          },

                          "style-default": {
                            display: "flex",
                            "flex-wrap": "no-wrap",
                            "justify-content": "flex-start",
                            "align-items": "flex-start",
                            width: CommonWidth
                              ? CommonWidth + "%"
                              : "8% !important",
                          },

                          style: {
                            display: "flex",
                            "flex-wrap": "no-wrap",
                            "justify-content": "flex-start",
                            "align-items": "flex-start",
                            width: CommonWidth
                              ? CommonWidth + "%"
                              : "8% !important",
                          },

                          components: [
                            {
                              tagName: "span",
                              type: "radio_input",
                              script: individualRadioScript,

                              attributes: {
                                class: "radio_input",
                              },

                              components: [
                                {
                                  tagName: "input",
                                  type: "default",
                                  attributes: {
                                    type: "radio",
                                    id: `radio_1_${uniqid()}`,
                                    name: `radio_${containerId}`,
                                    value: `${opt[`${ArrOfObjKyename}`]}`,
                                    data_db_id: `${opt[`_id`]}`
                                      ? `${opt[`_id`]}`
                                      : `${opt[`${ArrOfObjKyename}`]}`,
                                  },
                                },
                              ],

                              Autofocus: false,
                              Disabled: false,
                              Required: false,
                              Checked: false,

                              traits: [
                                "id",
                                "name",
                                "value",
                                {
                                  type: "checkbox",
                                  name: "Autofocus",
                                  label: "Autofocus",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Disabled",
                                  label: "Disabled",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Required",
                                  label: "Required",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Checked",
                                  label: "Checked",
                                  changeProp: true,
                                },
                              ],

                              changeProp: true,
                              "script-props": [
                                "Autofocus",
                                "Disabled",
                                "Required",
                                "Checked",
                              ],
                            },
                            {
                              tagName: "label",
                              attributes: {
                                for: ``,
                              },

                              components: [
                                {
                                  tagName: "p",
                                  type: "text",
                                  editable: true,

                                  style: {
                                    "word-break": "break-all",
                                  },

                                  "style-default": {
                                    "word-break": "break-all",
                                  },

                                  components: {
                                    type: "textnode",
                                    content: `${opt[`${ArrOfObjKyename}`]}`,
                                  },
                                },
                              ],

                              traits: ["id", "for"],
                            },
                          ],
                        };

                        // let inputEle = {
                        //   tagName: "input",
                        //   type: "default", // so that the input component doesn't throw error of 'undefined'

                        //   script: individualCheckboxScript,

                        //   attributes: {
                        //     type: "checkbox",
                        //     // id: `${opt[`${ArrOfObjKyename}`]}`,
                        //     name: `${opt[`${ArrOfObjKyename}`]}`,
                        //     value: `${opt[`${ArrOfObjKyename}`]}`,
                        //   },

                        //   Autofocus: false,
                        //   Disabled: false,
                        //   Required: false,
                        //   Checked: false,

                        //   traits: [
                        //     "id",
                        //     "name",
                        //     "value",
                        //     {
                        //       type: "checkbox",
                        //       name: "Autofocus",
                        //       label: "Autofocus",
                        //       changeProp: true,
                        //     },
                        //     {
                        //       type: "checkbox",
                        //       name: "Disabled",
                        //       label: "Disabled",
                        //       changeProp: true,
                        //     },
                        //     {
                        //       type: "checkbox",
                        //       name: "Required",
                        //       label: "Required",
                        //       changeProp: true,
                        //     },
                        //     {
                        //       type: "checkbox",
                        //       name: "Checked",
                        //       label: "Checked",
                        //       changeProp: true,
                        //     },
                        //   ],

                        //   changeProp: true,
                        //   "script-props": [
                        //     "Autofocus",
                        //     "Disabled",
                        //     "Required",
                        //     "Checked",
                        //   ],
                        // };

                        // let labelEle = {
                        //   tagName: "label",
                        //   attributes: {
                        //     for: ``,
                        //   },

                        //   components: [
                        //     {
                        //       tagName: "p",
                        //       type: "text",
                        //       editable: true,

                        //       components: {
                        //         type: "textnode",
                        //         content: `${opt[`${ArrOfObjKyename}`]}`,
                        //       },
                        //     },
                        //   ],

                        //   traits: ["id", "for"],
                        // };

                        // container.append(inputEle);
                        // container.append(labelEle);
                        container.append(groupEle);
                      });
                    }

                    // update only when the radio have already been plotted previously from apiData but not updating if the apiData had only 2 fields
                    if (
                      data.length > container.components().length &&
                      container.components().length > 2
                    ) {
                      // only add what's missing from api as a dynamic update

                      // get all the labels' inner content and compare it with the keyname of the apiData to be plotted and add the ones missing
                      let allLabelEles = container.find("label");
                      let allLabels = allLabelEles.map(
                        (label) =>
                          label.find('p[data-gjs-type="text"]')[0].getEl()
                            .innerHTML
                      );
                      console.log("allLabelEles", allLabelEles, allLabels);

                      if (allLabelEles.length > 0) {
                        // if you find a label thats not in the apiData then add it to the existing radio container
                        data.forEach((opt, idx) => {
                          // if that object from apiData is not present in the radio container then plot it
                          if (!allLabels.includes(opt[`${ArrOfObjKyename}`])) {
                            let groupEle = {
                              tagName: "span",
                              type: "default",
                              attributes: {
                                class: "group_radio_label",
                              },

                              "style-default": {
                                display: "flex",
                                "flex-wrap": "no-wrap",
                                "justify-content": "flex-start",
                                "align-items": "flex-start",
                                width: CommonWidth
                                  ? CommonWidth + "%"
                                  : "8% !important",
                              },

                              style: {
                                display: "flex",
                                "flex-wrap": "no-wrap",
                                "justify-content": "flex-start",
                                "align-items": "flex-start",
                                width: CommonWidth
                                  ? CommonWidth + "%"
                                  : "8% !important",
                              },

                              components: [
                                {
                                  tagName: "span",
                                  type: "radio_input",
                                  script: individualRadioScript,

                                  attributes: {
                                    class: "radio_input",
                                  },

                                  components: [
                                    {
                                      tagName: "input",
                                      type: "default",
                                      attributes: {
                                        type: "radio",
                                        id: `radio_1_${uniqid()}`,
                                        name: `radio_${containerId}`,
                                        value: `${opt[`${ArrOfObjKyename}`]}`,
                                        data_db_id: `${opt[`_id`]}`
                                          ? `${opt[`_id`]}`
                                          : `${opt[`${ArrOfObjKyename}`]}`,
                                      },
                                    },
                                  ],

                                  Autofocus: false,
                                  Disabled: false,
                                  Required: false,
                                  Checked: false,

                                  traits: [
                                    "id",
                                    "name",
                                    "value",
                                    {
                                      type: "checkbox",
                                      name: "Autofocus",
                                      label: "Autofocus",
                                      changeProp: true,
                                    },
                                    {
                                      type: "checkbox",
                                      name: "Disabled",
                                      label: "Disabled",
                                      changeProp: true,
                                    },
                                    {
                                      type: "checkbox",
                                      name: "Required",
                                      label: "Required",
                                      changeProp: true,
                                    },
                                    {
                                      type: "checkbox",
                                      name: "Checked",
                                      label: "Checked",
                                      changeProp: true,
                                    },
                                  ],

                                  changeProp: true,
                                  "script-props": [
                                    "Autofocus",
                                    "Disabled",
                                    "Required",
                                    "Checked",
                                  ],
                                },
                                {
                                  tagName: "label",
                                  attributes: {
                                    for: ``,
                                  },

                                  components: [
                                    {
                                      tagName: "p",
                                      type: "text",
                                      editable: true,

                                      style: {
                                        "word-break": "break-all",
                                      },

                                      "style-default": {
                                        "word-break": "break-all",
                                      },

                                      components: {
                                        type: "textnode",
                                        content: `${opt[`${ArrOfObjKyename}`]}`,
                                      },
                                    },
                                  ],

                                  traits: ["id", "for"],
                                },
                              ],
                            };

                            container.append(groupEle);
                          }
                        });
                      }
                    }

                    // plot when the data has only around 2 entries
                    else if (data.length <= 2) {
                      console.log("_");

                      // see if the radio are the default radio by checking if they have this attribute 'data_db_id', if yes then clear the body and append new else append new checkboxes anyways
                      let isNotDefaultRadio =
                        "data_db_id" in
                        container
                          .find('input[type="radio"]')[0]
                          .getAttributes();
                      console.log("isNotDefaultRadio", isNotDefaultRadio);

                      // if it is a default radio then empty the body before appending apiData radio
                      if (!isNotDefaultRadio) {
                        container.components("");
                        data.forEach((opt) => {
                          let groupEle = {
                            tagName: "span",
                            type: "default",
                            attributes: {
                              class: "group_radio_label",
                            },

                            "style-default": {
                              display: "flex",
                              "flex-wrap": "no-wrap",
                              "justify-content": "flex-start",
                              "align-items": "flex-start",
                              width: CommonWidth
                                ? CommonWidth + "%"
                                : "8% !important",
                            },

                            style: {
                              display: "flex",
                              "flex-wrap": "no-wrap",
                              "justify-content": "flex-start",
                              "align-items": "flex-start",
                              width: CommonWidth
                                ? CommonWidth + "%"
                                : "8% !important",
                            },

                            components: [
                              {
                                tagName: "span",
                                type: "radio_input",
                                script: individualRadioScript,

                                attributes: {
                                  class: "radio_input",
                                },

                                components: [
                                  {
                                    tagName: "input",
                                    type: "default",
                                    attributes: {
                                      type: "radio",
                                      id: `radio_1_${uniqid()}`,
                                      name: `radio_${containerId}`,
                                      value: `${opt[`${ArrOfObjKyename}`]}`,
                                      data_db_id: `${opt[`_id`]}`
                                        ? `${opt[`_id`]}`
                                        : `${opt[`${ArrOfObjKyename}`]}`,
                                    },
                                  },
                                ],

                                Autofocus: false,
                                Disabled: false,
                                Required: false,
                                Checked: false,

                                traits: [
                                  "id",
                                  "name",
                                  "value",
                                  {
                                    type: "checkbox",
                                    name: "Autofocus",
                                    label: "Autofocus",
                                    changeProp: true,
                                  },
                                  {
                                    type: "checkbox",
                                    name: "Disabled",
                                    label: "Disabled",
                                    changeProp: true,
                                  },
                                  {
                                    type: "checkbox",
                                    name: "Required",
                                    label: "Required",
                                    changeProp: true,
                                  },
                                  {
                                    type: "checkbox",
                                    name: "Checked",
                                    label: "Checked",
                                    changeProp: true,
                                  },
                                ],

                                changeProp: true,
                                "script-props": [
                                  "Autofocus",
                                  "Disabled",
                                  "Required",
                                  "Checked",
                                ],
                              },
                              {
                                tagName: "label",
                                attributes: {
                                  for: ``,
                                },

                                components: [
                                  {
                                    tagName: "p",
                                    type: "text",
                                    editable: true,

                                    style: {
                                      "word-break": "break-all",
                                    },

                                    "style-default": {
                                      "word-break": "break-all",
                                    },

                                    components: {
                                      type: "textnode",
                                      content: `${opt[`${ArrOfObjKyename}`]}`,
                                    },
                                  },
                                ],

                                traits: ["id", "for"],
                              },
                            ],
                          };

                          // let inputEle = {
                          //   tagName: "input",
                          //   type: "default", // so that the input component doesn't throw error of 'undefined'

                          //   script: individualCheckboxScript,

                          //   attributes: {
                          //     type: "checkbox",
                          //     // id: `${opt[`${ArrOfObjKyename}`]}`,
                          //     name: `${opt[`${ArrOfObjKyename}`]}`,
                          //     value: `${opt[`${ArrOfObjKyename}`]}`,
                          //   },

                          //   Autofocus: false,
                          //   Disabled: false,
                          //   Required: false,
                          //   Checked: false,

                          //   traits: [
                          //     "id",
                          //     "name",
                          //     "value",
                          //     {
                          //       type: "checkbox",
                          //       name: "Autofocus",
                          //       label: "Autofocus",
                          //       changeProp: true,
                          //     },
                          //     {
                          //       type: "checkbox",
                          //       name: "Disabled",
                          //       label: "Disabled",
                          //       changeProp: true,
                          //     },
                          //     {
                          //       type: "checkbox",
                          //       name: "Required",
                          //       label: "Required",
                          //       changeProp: true,
                          //     },
                          //     {
                          //       type: "checkbox",
                          //       name: "Checked",
                          //       label: "Checked",
                          //       changeProp: true,
                          //     },
                          //   ],

                          //   changeProp: true,
                          //   "script-props": [
                          //     "Autofocus",
                          //     "Disabled",
                          //     "Required",
                          //     "Checked",
                          //   ],
                          // };

                          // let labelEle = {
                          //   tagName: "label",
                          //   attributes: {
                          //     for: ``,
                          //   },

                          //   components: [
                          //     {
                          //       tagName: "p",
                          //       type: "text",
                          //       editable: true,

                          //       components: {
                          //         type: "textnode",
                          //         content: `${opt[`${ArrOfObjKyename}`]}`,
                          //       },
                          //     },
                          //   ],

                          //   traits: ["id", "for"],
                          // };

                          // container.append(inputEle);
                          // container.append(labelEle);
                          container.append(groupEle);
                        });
                      }
                    }

                    // dynamic delete
                    if (container.components().length > data.length) {
                      console.log("removing extra group_radio_labels");

                      let groupRadioLabels =
                        container.find(".group_radio_label");
                      groupRadioLabels.forEach((groupRadioLabel) => {
                        // get the data_db_url from radio and check if _id is present in apiData, if not, remove it
                        let radio = groupRadioLabel
                          .find(".radio_input")[0]
                          .find('input[type="radio"]')[0];
                        let radioDataDbId = radio.getAttributes()["data_db_id"];

                        // see if radioDataDbId is in apiData _ids and if not there, delete this group_radio_label
                        let apiData_id = data.map((obj) => obj["_id"]);

                        if (!apiData_id.includes(radioDataDbId)) {
                          groupRadioLabel.remove();
                          console.log("removed");
                        }
                      });
                    }
                  }
                } else if (NestedKeyname && !ArrOfObjKyename) {
                  // container.components("");

                  // find the array value
                  let data = findKeyValue(apiData, NestedKeyname);

                  // if API response to plot is a nested plain array of single elements inside the main response object
                  if (Array.isArray(data) && !isArrayofObjects(data)) {
                    console.log("#####");

                    // either no radio are plotted or the default 2 radio are there
                    if (
                      container.components().length === 0 ||
                      container.components().length <= 2
                    ) {
                      container.components("");

                      data.forEach((opt) => {
                        let groupEle = {
                          tagName: "span",
                          type: "default",
                          attributes: {
                            class: "group_radio_label",
                          },

                          "style-default": {
                            display: "flex",
                            "flex-wrap": "no-wrap",
                            "justify-content": "flex-start",
                            "align-items": "flex-start",
                            width: CommonWidth
                              ? CommonWidth + "%"
                              : "8% !important",
                          },

                          style: {
                            display: "flex",
                            "flex-wrap": "no-wrap",
                            "justify-content": "flex-start",
                            "align-items": "flex-start",
                            width: CommonWidth
                              ? CommonWidth + "%"
                              : "8% !important",
                          },

                          components: [
                            {
                              tagName: "span",
                              type: "radio_input",
                              script: individualRadioScript,

                              attributes: {
                                class: "radio_input",
                              },

                              components: [
                                {
                                  tagName: "input",
                                  type: "default",
                                  attributes: {
                                    type: "radio",
                                    id: `radio_1_${uniqid()}`,
                                    name: `radio_${containerId}`,
                                    value: `${opt}`,
                                  },
                                },
                              ],

                              Autofocus: false,
                              Disabled: false,
                              Required: false,
                              Checked: false,

                              traits: [
                                "id",
                                "name",
                                "value",
                                {
                                  type: "checkbox",
                                  name: "Autofocus",
                                  label: "Autofocus",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Disabled",
                                  label: "Disabled",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Required",
                                  label: "Required",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Checked",
                                  label: "Checked",
                                  changeProp: true,
                                },
                              ],

                              changeProp: true,
                              "script-props": [
                                "Autofocus",
                                "Disabled",
                                "Required",
                                "Checked",
                              ],
                            },
                            {
                              tagName: "label",
                              attributes: {
                                for: ``,
                              },

                              components: [
                                {
                                  tagName: "p",
                                  type: "text",
                                  editable: true,

                                  style: {
                                    "word-break": "break-all",
                                  },

                                  "style-default": {
                                    "word-break": "break-all",
                                  },

                                  components: {
                                    type: "textnode",
                                    content: `${opt}`,
                                  },
                                },
                              ],

                              traits: ["id", "for"],
                            },
                          ],
                        };

                        // let inputEle = {
                        //   tagName: "input",
                        //   type: "default", // so that the input component doesn't throw error of 'undefined'

                        //   script: individualCheckboxScript,

                        //   attributes: {
                        //     type: "checkbox",
                        //     // id: `ch-${opt}`,
                        //     name: `ch-${opt}`,
                        //     value: `${opt}`,
                        //   },

                        //   Autofocus: false,
                        //   Disabled: false,
                        //   Required: false,
                        //   Checked: false,

                        //   traits: [
                        //     "id",
                        //     "name",
                        //     "value",
                        //     {
                        //       type: "checkbox",
                        //       name: "Autofocus",
                        //       label: "Autofocus",
                        //       changeProp: true,
                        //     },
                        //     {
                        //       type: "checkbox",
                        //       name: "Disabled",
                        //       label: "Disabled",
                        //       changeProp: true,
                        //     },
                        //     {
                        //       type: "checkbox",
                        //       name: "Required",
                        //       label: "Required",
                        //       changeProp: true,
                        //     },
                        //     {
                        //       type: "checkbox",
                        //       name: "Checked",
                        //       label: "Checked",
                        //       changeProp: true,
                        //     },
                        //   ],

                        //   changeProp: true,
                        //   "script-props": [
                        //     "Autofocus",
                        //     "Disabled",
                        //     "Required",
                        //     "Checked",
                        //   ],
                        // };

                        // let labelEle = {
                        //   tagName: "label",
                        //   attributes: {
                        //     for: ``,
                        //   },

                        //   components: [
                        //     {
                        //       tagName: "p",
                        //       type: "text",
                        //       editable: true,

                        //       components: {
                        //         type: "textnode",
                        //         content: `${opt}`,
                        //       },
                        //     },
                        //   ],

                        //   traits: ["id", "for"],
                        // };

                        // container.append(inputEle);
                        // container.append(labelEle);
                        container.append(groupEle);
                      });
                    }

                    // else if the apiData radio are already plotted then check for updated values and add those here without changing the IDd of already plotted ones
                    else if (
                      data.length > container.components().length &&
                      container.components().length > 2
                    ) {
                      // only add what's missing from api as a dynamic update

                      // get all the labels' inner content and compare it with the keyname of the apiData to be plotted and add the ones missing
                      let allLabelEles = container.find("label");
                      let allLabels = allLabelEles.map(
                        (label) =>
                          label.find('p[data-gjs-type="text"]')[0].getEl()
                            .innerHTML
                      );
                      console.log("allLabelEles", allLabelEles, allLabels);

                      if (allLabelEles.length > 0) {
                        // if you find a label thats not in the apiData then add it to the existing radio container
                        data.forEach((opt, idx) => {
                          let groupEle = {
                            tagName: "span",
                            type: "default",
                            attributes: {
                              class: "group_radio_label",
                            },

                            "style-default": {
                              display: "flex",
                              "flex-wrap": "no-wrap",
                              "justify-content": "flex-start",
                              "align-items": "flex-start",
                              width: CommonWidth
                                ? CommonWidth + "%"
                                : "8% !important",
                            },

                            style: {
                              display: "flex",
                              "flex-wrap": "no-wrap",
                              "justify-content": "flex-start",
                              "align-items": "flex-start",
                              width: CommonWidth
                                ? CommonWidth + "%"
                                : "8% !important",
                            },

                            components: [
                              {
                                tagName: "span",
                                type: "radio_input",
                                script: individualRadioScript,

                                attributes: {
                                  class: "radio_input",
                                },

                                components: [
                                  {
                                    tagName: "input",
                                    type: "default",
                                    attributes: {
                                      type: "radio",
                                      id: `radio_1_${uniqid()}`,
                                      name: `radio_${containerId}`,
                                      value: `${opt}`,
                                    },
                                  },
                                ],

                                Autofocus: false,
                                Disabled: false,
                                Required: false,
                                Checked: false,

                                traits: [
                                  "id",
                                  "name",
                                  "value",
                                  {
                                    type: "checkbox",
                                    name: "Autofocus",
                                    label: "Autofocus",
                                    changeProp: true,
                                  },
                                  {
                                    type: "checkbox",
                                    name: "Disabled",
                                    label: "Disabled",
                                    changeProp: true,
                                  },
                                  {
                                    type: "checkbox",
                                    name: "Required",
                                    label: "Required",
                                    changeProp: true,
                                  },
                                  {
                                    type: "checkbox",
                                    name: "Checked",
                                    label: "Checked",
                                    changeProp: true,
                                  },
                                ],

                                changeProp: true,
                                "script-props": [
                                  "Autofocus",
                                  "Disabled",
                                  "Required",
                                  "Checked",
                                ],
                              },
                              {
                                tagName: "label",
                                attributes: {
                                  for: ``,
                                },

                                components: [
                                  {
                                    tagName: "p",
                                    type: "text",
                                    editable: true,

                                    style: {
                                      "word-break": "break-all",
                                    },

                                    "style-default": {
                                      "word-break": "break-all",
                                    },

                                    components: {
                                      type: "textnode",
                                      content: `${opt}`,
                                    },
                                  },
                                ],

                                traits: ["id", "for"],
                              },
                            ],
                          };

                          // let inputEle = {
                          //   tagName: "input",
                          //   type: "default", // so that the input component doesn't throw error of 'undefined'

                          //   script: individualCheckboxScript,

                          //   attributes: {
                          //     type: "checkbox",
                          //     // id: `ch-${opt}`,
                          //     name: `ch-${opt}`,
                          //     value: `${opt}`,
                          //   },

                          //   Autofocus: false,
                          //   Disabled: false,
                          //   Required: false,
                          //   Checked: false,

                          //   traits: [
                          //     "id",
                          //     "name",
                          //     "value",
                          //     {
                          //       type: "checkbox",
                          //       name: "Autofocus",
                          //       label: "Autofocus",
                          //       changeProp: true,
                          //     },
                          //     {
                          //       type: "checkbox",
                          //       name: "Disabled",
                          //       label: "Disabled",
                          //       changeProp: true,
                          //     },
                          //     {
                          //       type: "checkbox",
                          //       name: "Required",
                          //       label: "Required",
                          //       changeProp: true,
                          //     },
                          //     {
                          //       type: "checkbox",
                          //       name: "Checked",
                          //       label: "Checked",
                          //       changeProp: true,
                          //     },
                          //   ],

                          //   changeProp: true,
                          //   "script-props": [
                          //     "Autofocus",
                          //     "Disabled",
                          //     "Required",
                          //     "Checked",
                          //   ],
                          // };

                          // let labelEle = {
                          //   tagName: "label",
                          //   attributes: {
                          //     for: ``,
                          //   },

                          //   components: [
                          //     {
                          //       tagName: "p",
                          //       type: "text",
                          //       editable: true,

                          //       components: {
                          //         type: "textnode",
                          //         content: `${opt}`,
                          //       },
                          //     },
                          //   ],

                          //   traits: ["id", "for"],
                          // };

                          // container.append(inputEle);
                          // container.append(labelEle);
                          container.append(groupEle);
                        });
                      }
                    }
                  }

                  // if API response to plot are all key:value pairs of a plain object inside main object
                  else if (
                    !Array.isArray(data) &&
                    !isArrayofObjects(data) &&
                    typeof data === "object"
                  ) {
                    console.log("######");
                    Object.keys(data).forEach((key) => {
                      if (
                        !Array.isArray(data[`${key}`]) &&
                        typeof data[`${key}`] !== "object"
                      ) {
                        let groupEle = {
                          tagName: "span",
                          type: "default",
                          attributes: {
                            class: "group_radio_label",
                          },

                          "style-default": {
                            display: "flex",
                            "flex-wrap": "no-wrap",
                            "justify-content": "flex-start",
                            "align-items": "flex-start",
                            width: CommonWidth
                              ? CommonWidth + "%"
                              : "8% !important",
                          },

                          style: {
                            display: "flex",
                            "flex-wrap": "no-wrap",
                            "justify-content": "flex-start",
                            "align-items": "flex-start",
                            width: CommonWidth
                              ? CommonWidth + "%"
                              : "8% !important",
                          },

                          components: [
                            {
                              tagName: "span",
                              type: "radio_input",
                              script: individualRadioScript,

                              attributes: {
                                class: "radio_input",
                              },

                              components: [
                                {
                                  tagName: "input",
                                  type: "default",
                                  attributes: {
                                    type: "radio",
                                    id: `radio_1_${uniqid()}`,
                                    name: `radio_${containerId}`,
                                    value: `${data[`${key}`]}`,
                                  },
                                },
                              ],

                              Autofocus: false,
                              Disabled: false,
                              Required: false,
                              Checked: false,

                              traits: [
                                "id",
                                "name",
                                "value",
                                {
                                  type: "checkbox",
                                  name: "Autofocus",
                                  label: "Autofocus",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Disabled",
                                  label: "Disabled",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Required",
                                  label: "Required",
                                  changeProp: true,
                                },
                                {
                                  type: "checkbox",
                                  name: "Checked",
                                  label: "Checked",
                                  changeProp: true,
                                },
                              ],

                              changeProp: true,
                              "script-props": [
                                "Autofocus",
                                "Disabled",
                                "Required",
                                "Checked",
                              ],
                            },
                            {
                              tagName: "label",
                              attributes: {
                                for: ``,
                              },

                              components: [
                                {
                                  tagName: "p",
                                  type: "text",
                                  editable: true,

                                  style: {
                                    "word-break": "break-all",
                                  },

                                  "style-default": {
                                    "word-break": "break-all",
                                  },

                                  components: {
                                    type: "textnode",
                                    content: `${key}`,
                                  },
                                },
                              ],

                              traits: ["id", "for"],
                            },
                          ],
                        };

                        // let inputEle = {
                        //   tagName: "input",
                        //   type: "default", // so that the input component doesn't throw error of 'undefined'

                        //   script: individualCheckboxScript,

                        //   attributes: {
                        //     type: "checkbox",
                        //     // id: `${data[`${key}`]}`,
                        //     name: `${data[`${key}`]}`,
                        //     value: `${data[`${key}`]}`,
                        //   },

                        //   Autofocus: false,
                        //   Disabled: false,
                        //   Required: false,
                        //   Checked: false,

                        //   traits: [
                        //     "id",
                        //     "name",
                        //     "value",
                        //     {
                        //       type: "checkbox",
                        //       name: "Autofocus",
                        //       label: "Autofocus",
                        //       changeProp: true,
                        //     },
                        //     {
                        //       type: "checkbox",
                        //       name: "Disabled",
                        //       label: "Disabled",
                        //       changeProp: true,
                        //     },
                        //     {
                        //       type: "checkbox",
                        //       name: "Required",
                        //       label: "Required",
                        //       changeProp: true,
                        //     },
                        //     {
                        //       type: "checkbox",
                        //       name: "Checked",
                        //       label: "Checked",
                        //       changeProp: true,
                        //     },
                        //   ],

                        //   changeProp: true,
                        //   "script-props": [
                        //     "Autofocus",
                        //     "Disabled",
                        //     "Required",
                        //     "Checked",
                        //   ],
                        // };

                        // let labelEle = {
                        //   tagName: "label",
                        //   attributes: {
                        //     for: ``,
                        //   },

                        //   components: [
                        //     {
                        //       tagName: "p",
                        //       type: "text",
                        //       editable: true,

                        //       components: {
                        //         type: "textnode",
                        //         content: `${key}`,
                        //       },
                        //     },
                        //   ],

                        //   traits: ["id", "for"],
                        // };

                        // container.append(inputEle);
                        // container.append(labelEle);
                        container.append(groupEle);
                      }
                    });
                  }
                }

                // if API response to plot are all key:value pairs of the plain object
                else if (!NestedKeyname && !ArrOfObjKyename) {
                  console.log("#######");
                  container.components("");

                  Object.keys(apiData).forEach((key) => {
                    if (
                      !Array.isArray(apiData[`${key}`]) &&
                      typeof apiData[`${key}`] !== "object"
                    ) {
                      let groupEle = {
                        tagName: "span",
                        type: "default",
                        attributes: {
                          class: "group_radio_label",
                        },

                        "style-default": {
                          display: "flex",
                          "flex-wrap": "no-wrap",
                          "justify-content": "flex-start",
                          "align-items": "flex-start",
                          width: CommonWidth
                            ? CommonWidth + "%"
                            : "8% !important",
                        },

                        style: {
                          display: "flex",
                          "flex-wrap": "no-wrap",
                          "justify-content": "flex-start",
                          "align-items": "flex-start",
                          width: CommonWidth
                            ? CommonWidth + "%"
                            : "8% !important",
                        },

                        components: [
                          {
                            tagName: "span",
                            type: "radio_input",
                            script: individualRadioScript,

                            attributes: {
                              class: "radio_input",
                            },

                            components: [
                              {
                                tagName: "input",
                                type: "default",
                                attributes: {
                                  type: "radio",
                                  id: `radio_1_${uniqid()}`,
                                  name: `radio_${containerId}`,
                                  value: `${apiData[`${key}`]}`,
                                },
                              },
                            ],

                            Autofocus: false,
                            Disabled: false,
                            Required: false,
                            Checked: false,

                            traits: [
                              "id",
                              "name",
                              "value",
                              {
                                type: "checkbox",
                                name: "Autofocus",
                                label: "Autofocus",
                                changeProp: true,
                              },
                              {
                                type: "checkbox",
                                name: "Disabled",
                                label: "Disabled",
                                changeProp: true,
                              },
                              {
                                type: "checkbox",
                                name: "Required",
                                label: "Required",
                                changeProp: true,
                              },
                              {
                                type: "checkbox",
                                name: "Checked",
                                label: "Checked",
                                changeProp: true,
                              },
                            ],

                            changeProp: true,
                            "script-props": [
                              "Autofocus",
                              "Disabled",
                              "Required",
                              "Checked",
                            ],
                          },
                          {
                            tagName: "label",
                            attributes: {
                              for: ``,
                            },

                            components: [
                              {
                                tagName: "p",
                                type: "text",
                                editable: true,

                                style: {
                                  "word-break": "break-all",
                                },

                                "style-default": {
                                  "word-break": "break-all",
                                },

                                components: {
                                  type: "textnode",
                                  content: `${key}`,
                                },
                              },
                            ],

                            traits: ["id", "for"],
                          },
                        ],
                      };

                      // let inputEle = {
                      //   tagName: "input",
                      //   type: "default", // so that the input component doesn't throw error of 'undefined'

                      //   script: individualCheckboxScript,

                      //   attributes: {
                      //     type: "checkbox",
                      //     // id: `${apiData[`${key}`]}`,
                      //     name: `${apiData[`${key}`]}`,
                      //     value: `${apiData[`${key}`]}`,
                      //   },

                      //   Autofocus: false,
                      //   Disabled: false,
                      //   Required: false,
                      //   Checked: false,

                      //   traits: [
                      //     "id",
                      //     "name",
                      //     "value",
                      //     {
                      //       type: "checkbox",
                      //       name: "Autofocus",
                      //       label: "Autofocus",
                      //       changeProp: true,
                      //     },
                      //     {
                      //       type: "checkbox",
                      //       name: "Disabled",
                      //       label: "Disabled",
                      //       changeProp: true,
                      //     },
                      //     {
                      //       type: "checkbox",
                      //       name: "Required",
                      //       label: "Required",
                      //       changeProp: true,
                      //     },
                      //     {
                      //       type: "checkbox",
                      //       name: "Checked",
                      //       label: "Checked",
                      //       changeProp: true,
                      //     },
                      //   ],

                      //   changeProp: true,
                      //   "script-props": [
                      //     "Autofocus",
                      //     "Disabled",
                      //     "Required",
                      //     "Checked",
                      //   ],
                      // };

                      // let labelEle = {
                      //   tagName: "label",
                      //   attributes: {
                      //     for: ``,
                      //   },

                      //   components: [
                      //     {
                      //       tagName: "p",
                      //       type: "text",
                      //       editable: true,

                      //       components: {
                      //         type: "textnode",
                      //         content: `${key}`,
                      //       },
                      //     },
                      //   ],

                      //   traits: ["id", "for"],
                      // };

                      // container.append(inputEle);
                      // container.append(labelEle);
                      container.append(groupEle);
                    }
                  });
                }

                // if there is a 'message' in api response instead of data
                else if (Object.keys(apiData).includes('message')) {
                    console.log("#@@");

                    container.components("");
                    container.components().push({
                        tagName: "span",
                        type: "default",
                        attributes: {
                          class: "group_radio_label",
                        },

                        "style-default": {
                          display: "flex",
                          "flex-wrap": "no-wrap",
                          "justify-content": "flex-start",
                          "align-items": "flex-start",
                        },

                        style: {
                          display: "flex",
                          "flex-wrap": "no-wrap",
                          "justify-content": "flex-start",
                          "align-items": "flex-start",
                        },

                        components: [
                          {
                            tagName: "span",
                            type: "radio_input",

                            attributes: {
                              class: "radio_input",
                            },

                            style: {
                              "margin-right": "5%",
                              display: "none",
                            },

                            "style-default": {
                              "margin-right": "5%",
                              display: "none",
                            },

                            components: [
                              {
                                tagName: "input",
                                type: "default",
                                attributes: {
                                  type: "radio",
                                  id: `radio_1_${uniqid()}`,
                                  name: "radio_1",
                                  value: "radio_1",
                                },
                              },
                            ],
                          },
                          {
                            tagName: "label",
                            type: "radio_label",
                            attributes: {
                              for: "",
                            },

                            components: [
                              {
                                tagName: "p",
                                type: "text",
                                editable: true,

                                style: {
                                  width: "max-content",
                                  "word-break": "break-all",
                                },

                                "style-default": {
                                  width: "max-content",
                                  "word-break": "break-all",
                                },

                                components: {
                                  type: "textnode",
                                  content: "No Values.",
                                },
                              },
                            ],

                            traits: ["id", "for"],
                            changeProp: true,
                          },
                        ],
                    });
                }
              }
            }
          },
        },

        view: {
          onRender({ el, model }) {
            if (el.getAttribute("data-gjs-type") === "radio_regular_form") {
              console.log("rendering", el);
              model.handlePropChange();
            }
          },
        },
      });

      // script for input-select
      const dropdownListScript = async function (props) {
        console.log("this element:", this, props);

        // helper function to check if a variable is an array-of-objects or not
        function isArrayofObjects(variable) {
          // Check if the variable is an array
          if (!Array.isArray(variable)) {
            return false;
          }

          // Check if all elements in the array are objects
          for (const element of variable) {
            if (
              typeof element !== "object" ||
              Array.isArray(element) ||
              element === null
            ) {
              return false;
            }
          }
          return true;
        }

        // helper function to get value of a key in nested object
        function findKeyValue(obj, key) {
          if (obj.hasOwnProperty(key)) {
            // If the current object has the key, return its value
            return obj[key];
          }

          for (var prop in obj) {
            if (obj.hasOwnProperty(prop) && typeof obj[prop] === "object") {
              // If the current property is an object, recursively search within it
              var result = findKeyValue(obj[prop], key);
              if (result !== undefined) {
                return result;
              }
            }
          }
          // Key not found in the object
          return undefined;
        }

        const container = this;
        let apiData;

        // to manually populate the dropdown list
        if (props.SetOptionsManually) {
          let options = props.SetOptionsManually.split(",");

          container.innerHTML = "";

          options.forEach((opt) => {
            let option = document.createElement("option");
            option.setAttribute("value", `${opt}`);
            option.innerHTML = opt;

            container.appendChild(option);
          });
        }

        if (props.Autofocus) {
          container.setAttribute("autofocus", true);
        }

        if (props.Multiple) {
          container.setAttribute("multiple", true);
        }

        if (props.Disabled) {
          container.setAttribute("disabled", true);
        }

        if (props.Required) {
          container.setAttribute("required", true);
        }

        if (props.Size) {
          container.setAttribute("size", `${props.Size}`);
        }

        // -------- Getting API Data
        // to use API to populate the dropdown list
        if (
          (props.CustomUrl && !props.BaseUrl) ||
          (props.CustomUrl && props.BaseUrl === "null")
        ) {
          console.log("props.CustomUrl:", props.CustomUrl);
        } else if (props.BaseUrl && !props.CustomUrl) {
          console.log("props.BaseUrl:", props.BaseUrl);
        }

        if (props.API) {
          // if nothing is provided for auth
          if (!props.BearerToken && !props.Username && !props.Password) {
            // add extra headers, if needed
            let config = {
              headers: {},
            };

            if (props.MoreHeaders) {
              // ['a:b', "c:d"]
              // extracting headers from More_Headers trait
              props.MoreHeaders.split(",").forEach((header) => {
                let key, val;
                [key, val] = header.split(":");
                console.log("[key, val]:\n", key, val);

                config.headers[key] = val;
              });
            }

            console.log("config:", config);

            // GET call to get the data
            let response = await fetch(`${props.API}`, config);
            let responseData = await response.json();
            apiData = responseData;
          }

          // if a bearer token is provided but not api_key or usr+pwd
          else if (props.BearerToken && !props.Username && !props.Password) {
            // add extra headers, if needed
            let config = {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${props.BearerToken}`,
              },
            };

            if (props.MoreHeaders) {
              // ['a:b', "c:d"]
              // extracting headers from More_Headers trait
              props.MoreHeaders.split(",").forEach((header) => {
                let key, val;
                [key, val] = header.split(":");
                console.log("[key, val]:\n", key, val);

                config.headers[key] = val;
              });
            }

            console.log("config:", config);

            // GET call to get the data
            let response = await fetch(`${props.API}`, config);
            let responseData = await response.json();
            apiData = responseData;
          }

          // if a bearer token and api_key not provided but usr+pwd are provided
          else if (!props.BearerToken && props.Username && props.Password) {
            // add extra headers, if needed
            let config = {
              headers: {
                Authorization:
                  "Basic " + btoa(props.Username + ":" + props.Password),
              },
            };

            if (props.MoreHeaders) {
              // ['a:b', "c:d"]
              // extracting headers from More_Headers trait
              props.MoreHeaders.split(",").forEach((header) => {
                let key, val;
                [key, val] = header.split(":");
                console.log("[key, val]:\n", key, val);

                config.headers[key] = val;
              });
            }

            console.log("config:", config);

            // GET call to get the data
            let response = await fetch(`${props.API}`, config);
            let responseData = await response.json();
            apiData = responseData;
          }
        }

        console.log("apiData:", apiData);

        // -------- Populating API Data, into the dropdown list
        if (apiData !== undefined) {
          console.log("#");
          // if API response is directly an array-of-objects
          if (isArrayofObjects(apiData)) {
            if (props.ArrOfObjKyename) {
              console.log("##");
              container.innerHTML = "";

              apiData.forEach((opt) => {
                let option = document.createElement("option");
                option.setAttribute(
                  "value",
                  `${opt[`${props.ArrOfObjKyename}`]}`
                );
                option.innerHTML = opt[`${props.ArrOfObjKyename}`];

                container.appendChild(option);
              });
            }
          }

          // if API response is a plain array of single elements
          else if (Array.isArray(apiData) && !isArrayofObjects(apiData)) {
            console.log("###");
            container.innerHTML = "";
            apiData.forEach((opt) => {
              let option = document.createElement("option");
              option.setAttribute("value", `${opt}`);
              option.innerHTML = opt;

              container.appendChild(option);
            });
          }

          // if API response is an object
          else if (!isArrayofObjects(apiData) && typeof apiData === "object") {
            // if API response to plot is a nested array-of-objects inside the main response object
            if (props.NestedKeyname && props.ArrOfObjKyename) {
              console.log("####");
              container.innerHTML = "";

              // find the array-of-objects value
              let data = findKeyValue(apiData, props.NestedKeyname);

              if (isArrayofObjects(data)) {
                data.forEach((opt) => {
                  let option = document.createElement("option");
                  option.setAttribute(
                    "value",
                    `${opt[`${props.ArrOfObjKyename}`]}`
                  );
                  option.innerHTML = opt[`${props.ArrOfObjKyename}`];

                  container.appendChild(option);
                });
              }
            } else if (props.NestedKeyname && !props.ArrOfObjKyename) {
              container.innerHTML = "";

              // find the array value
              let data = findKeyValue(apiData, props.NestedKeyname);

              // if API response to plot is a nested plain array of single elements inside the main response object
              if (Array.isArray(data) && !isArrayofObjects(data)) {
                console.log("#####");
                data.forEach((opt) => {
                  let option = document.createElement("option");
                  option.setAttribute("value", `${opt}`);
                  option.innerHTML = opt;

                  container.appendChild(option);
                });
              }

              // if API response to plot are all key:value pairs of a plain object inside main object
              else if (
                !Array.isArray(data) &&
                !isArrayofObjects(data) &&
                typeof data === "object"
              ) {
                console.log("######");
                Object.keys(data).forEach((key) => {
                  if (
                    !Array.isArray(data[`${key}`]) &&
                    typeof data[`${key}`] !== "object"
                  ) {
                    let option = document.createElement("option");
                    option.setAttribute("value", `${data[`${key}`]}`);
                    option.innerHTML = key;

                    container.appendChild(option);
                  }
                });
              }
            }

            // if API response to plot are all key:value pairs of the plain object
            else if (!props.NestedKeyname && !props.ArrOfObjKyename) {
              console.log("#######");
              container.innerHTML = "";

              Object.keys(apiData).forEach((key) => {
                if (
                  !Array.isArray(apiData[`${key}`]) &&
                  typeof apiData[`${key}`] !== "object"
                ) {
                  let option = document.createElement("option");
                  option.setAttribute("value", `${apiData[`${key}`]}`);
                  option.innerHTML = key;

                  container.appendChild(option);
                }
              });
            }
          }
        }
      };

      const inputSelectScript = async function (props) {
        console.log("input_select props", props, this);

        // For LB download manipulation
        // checking if the url or script is not running in the redsling editor, only then run the script to plot checkboxes
        let idEl = this.id;
        const urlDownload = window.top.location.href;
        const isSubstringPresent =
          (urlDownload.indexOf("/editor/") !== -1 &&
            urlDownload.indexOf("?projectId=") !== -1) ||
          urlDownload.indexOf("/large_preview/") !== -1 ||
          urlDownload.indexOf("/tab_preview/") !== -1 ||
          urlDownload.indexOf("/mobile_preview/") !== -1 ||
          urlDownload.indexOf("/fragment_editor/") !== -1;
        if (!isSubstringPresent) {
          setInterval(function () {
            Object.keys(props).forEach(function (key) {
              if (window[`${key}${idEl}`]) {
                props[key] = window[`${key}${idEl}`];
              }
            });
          }, 500);
        }

        // helper function to check if a variable is an array-of-objects or not
        function isArrayofObjects(variable) {
          // Check if the variable is an array
          if (!Array.isArray(variable)) {
            return false;
          }

          // Check if all elements in the array are objects
          for (const element of variable) {
            if (
              typeof element !== "object" ||
              Array.isArray(element) ||
              element === null
            ) {
              return false;
            }
          }
          return true;
        }

        // helper function to get value of a key in nested object
        function findKeyValue(obj, key) {
          if (obj.hasOwnProperty(key)) {
            // If the current object has the key, return its value
            return obj[key];
          }

          for (var prop in obj) {
            if (obj.hasOwnProperty(prop) && typeof obj[prop] === "object") {
              // If the current property is an object, recursively search within it
              var result = findKeyValue(obj[prop], key);
              if (result !== undefined) {
                return result;
              }
            }
          }
          // Key not found in the object
          return undefined;
        }

        // base url bearer token taken directly from local storage after getting downloaded
        let baseUrlData,
          dynamicBaseUrlBearerToken,
          baseUrl1BearerToken,
          baseUrl2BearerToken,
          baseUrl3BearerToken,
          baseUrl4BearerToken,
          baseUrl5BearerToken;
        // getting baseUrl data from local storage for run-time access
        let baseUrlDataFromStorage = localStorage.getItem("baseUrlData");
        if (baseUrlDataFromStorage) {
          baseUrlData = JSON.parse(baseUrlDataFromStorage);
        }
        // extracting token values from base URLs from baseUrlData
        if (baseUrlData) {
          try {
            if (`baseUrl1_${props.projectId}` in baseUrlData) {
              baseUrl1BearerToken =
                baseUrlData[`baseUrl1_${props.projectId}`].token;
            }
            if (`baseUrl2_${props.projectId}` in baseUrlData) {
              baseUrl2BearerToken =
                baseUrlData[`baseUrl2_${props.projectId}`].token;
            }
            if (`baseUrl3_${props.projectId}` in baseUrlData) {
              baseUrl3BearerToken =
                baseUrlData[`baseUrl3_${props.projectId}`].token;
            }
            if (`baseUrl4_${props.projectId}` in baseUrlData) {
              baseUrl4BearerToken =
                baseUrlData[`baseUrl4_${props.projectId}`].token;
            }
            if (`baseUrl5_${props.projectId}` in baseUrlData) {
              baseUrl5BearerToken =
                baseUrlData[`baseUrl5_${props.projectId}`].token;
            }
          } catch (e) {
            // statements
            console.log(e);
          }
        }

        let apiData;
        const {
          CustomUrl,
          BaseUrl,
          APIPath,
          BearerToken,
          Username,
          Password,
          MoreHeaders,
          ArrOfObjKyename,
          NestedKeyname,
        } = props;
        console.log(
          "CustomUrl, BaseUrl, APIPath, BearerToken, Username, Password, MoreHeaders, ArrOfObjKyename, NestedKeyname,",
          CustomUrl,
          BaseUrl,
          APIPath,
          BearerToken,
          Username,
          Password,
          MoreHeaders,
          ArrOfObjKyename,
          NestedKeyname
        );

        // to rename a variable because API var is getting used in many places in this block scope
        let API;
        if (APIPath) {
          API = APIPath;
        }

        const container = this;
        const dropdown = container.getElementsByClassName("input_select")[0];

        // checking if the url or script is not running in the redsling editor, only then run the script to plot checkboxes

        if (!isSubstringPresent) {
          // -------- Getting API Data
          // if CustomUrl is provided, use only that
          if (CustomUrl && (!BaseUrl || BaseUrl === "null")) {
            // if nothing is provided for auth
            if (!BearerToken && !Username && !Password) {
              // add extra headers, if needed
              let config = {
                headers: {},
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${CustomUrl}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token is provided but not api_key or usr+pwd
            else if (BearerToken && !Username && !Password) {
              // add extra headers, if needed
              let config = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${BearerToken}`,
                },
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${CustomUrl}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token and api_key not provided but usr+pwd are provided
            else if (!BearerToken && Username && Password) {
              // add extra headers, if needed
              let config = {
                headers: {
                  Authorization: "Basic " + btoa(Username + ":" + Password),
                },
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${CustomUrl}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }
          }

          // if base url is the choice and provided
          else if (BaseUrl && API && !CustomUrl) {
            // concatenating base url and its remaining part
            let url, baseUrlBearerToken;
            if (BaseUrl === "baseUrl1") {
              url = props.options.baseUrl1 + API;
              baseUrlBearerToken = baseUrl1BearerToken;
            } else if (BaseUrl === "baseUrl2") {
              url = props.options.baseUrl2 + API;
              baseUrlBearerToken = baseUrl2BearerToken;
            } else if (BaseUrl === "baseUrl3") {
              url = props.options.baseUrl3 + API;
              baseUrlBearerToken = baseUrl3BearerToken;
            } else if (BaseUrl === "baseUrl4") {
              url = props.options.baseUrl4 + API;
              baseUrlBearerToken = baseUrl4BearerToken;
            } else if (BaseUrl === "baseUrl5") {
              url = props.options.baseUrl5 + API;
              baseUrlBearerToken = baseUrl5BearerToken;
            }
            console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

            // if nothing is provided for auth
            if (!BearerToken && !baseUrlBearerToken && !Username && !Password) {
              // add extra headers, if needed
              let config = {
                headers: {},
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token is provided but not usr+pwd
            else if (BearerToken && !Username && !Password) {
              // add extra headers, if needed
              let config = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${BearerToken}`,
                },
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a baseUrlBearerToken is provided but not Custom Bearer Token and usr+pwd
            else if (
              baseUrlBearerToken &&
              !BearerToken &&
              !Username &&
              !Password
            ) {
              // add extra headers, if needed
              let config = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${baseUrlBearerToken}`,
                },
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config@:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token is not provided but usr+pwd are provided
            else if (!BearerToken && Username && Password) {
              // add extra headers, if needed
              let config = {
                headers: {
                  Authorization: "Basic " + btoa(Username + ":" + Password),
                },
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }
          }

          console.log("apiData:", apiData);

          // -------- Populating API Data, into the dropdown list
          if (apiData !== undefined) {
            console.log("#");
            // if API response is directly an array-of-objects
            if (isArrayofObjects(apiData)) {
              if (ArrOfObjKyename) {
                console.log("##");
                dropdown.innerHTML = "";

                apiData.forEach((opt) => {
                  let optionEle = document.createElement("option");
                  optionEle.setAttribute(
                    "value",
                    `${opt[`${ArrOfObjKyename}`]}`
                  );
                  optionEle.setAttribute(
                    "data_db_id",
                    `${opt[`_id`]}`
                      ? `${opt[`_id`]}`
                      : `${opt[`${ArrOfObjKyename}`]}`
                  );
                  optionEle.innerHTML = `${opt[`${ArrOfObjKyename}`]}`;

                  dropdown.appendChild(optionEle);
                });
              }
            }

            // if API response is a plain array of single elements
            else if (Array.isArray(apiData) && !isArrayofObjects(apiData)) {
              console.log("###");
              dropdown.innerHTML = "";

              apiData.forEach((opt) => {
                let optionEle = document.createElement("option");
                optionEle.setAttribute("value", `${opt}`);
                optionEle.innerHTML = `${opt}`;

                dropdown.appendChild(optionEle);
              });
            }

            // if API response is an object
            else if (
              !isArrayofObjects(apiData) &&
              typeof apiData === "object"
            ) {
              // if API response to plot is a nested array-of-objects inside the main response object
              if (NestedKeyname && ArrOfObjKyename) {
                console.log("####");
                dropdown.innerHTML = "";

                // find the array-of-objects value
                let data = findKeyValue(apiData, NestedKeyname);

                if (isArrayofObjects(data)) {
                  data.forEach((opt) => {
                    let optionEle = document.createElement("option");
                    optionEle.setAttribute(
                      "value",
                      `${opt[`${ArrOfObjKyename}`]}`
                    );
                    optionEle.innerHTML = `${opt[`${ArrOfObjKyename}`]}`;

                    dropdown.appendChild(optionEle);
                  });
                }
              } else if (NestedKeyname && !ArrOfObjKyename) {
                dropdown.components("");

                // find the array value
                let data = findKeyValue(apiData, NestedKeyname);

                // if API response to plot is a nested plain array of single elements inside the main response object
                if (Array.isArray(data) && !isArrayofObjects(data)) {
                  console.log("#####");
                  data.forEach((opt) => {
                    let optionEle = document.createElement("option");
                    optionEle.setAttribute("value", `${opt}`);
                    optionEle.innerHTML = `${opt}`;

                    dropdown.appendChild(optionEle);
                  });
                }

                // if API response to plot are all key:value pairs of a plain object inside main object
                else if (
                  !Array.isArray(data) &&
                  !isArrayofObjects(data) &&
                  typeof data === "object"
                ) {
                  console.log("######");
                  Object.keys(data).forEach((key) => {
                    if (
                      !Array.isArray(data[`${key}`]) &&
                      typeof data[`${key}`] !== "object"
                    ) {
                      let optionEle = document.createElement("option");
                      optionEle.setAttribute("value", `${data[`${key}`]}`);
                      optionEle.innerHTML = `${key}`;

                      dropdown.appendChild(optionEle);
                    }
                  });
                }
              }

              // if API response to plot are all key:value pairs of the plain object
              else if (!NestedKeyname && !ArrOfObjKyename) {
                console.log("#######");
                dropdown.components("");

                Object.keys(apiData).forEach((key) => {
                  if (
                    !Array.isArray(apiData[`${key}`]) &&
                    typeof apiData[`${key}`] !== "object"
                  ) {
                    let optionEle = document.createElement("option");
                    optionEle.setAttribute("value", `${apiData[`${key}`]}`);
                    optionEle.innerHTML = `${key}`;

                    dropdown.appendChild(optionEle);
                  }
                });
              }

              // if there is a 'message' in api response instead of data
              else if (Object.keys(apiData).includes('message')) {
                console.log("#@@");
                dropdown.innerHTML = "";

                let disabledOptionEle = document.createElement("option");
                disabledOptionEle.setAttribute("disabled", true);
                disabledOptionEle.setAttribute("selected", true);
                disabledOptionEle.innerHTML = "";

                dropdown.appendChild(disabledOptionEle);
              }
            }
          }
        }
      };

      editor.DomComponents.addType("input_select", {
        model: {
          defaults: {
            tagName: "span",
            attributes: {
              // id: "input_select_container",
              name: "input_select_container",
              class: "input_select_container",
            },
            script: inputSelectScript,

            components: [
              {
                tagName: "label",
                type: "default",
                attributes: {
                  for: "",
                },

                components: [
                  {
                    tagName: "p",
                    type: "text",
                    editable: true,
                    content: "Select an option:",
                  },
                ],

                traits: ["for"],
              },
              {
                tagName: "select",
                type: "default",
                // script: dropdownListScript,
                attributes: {
                  // id: "input_select",
                  name: "input_select",
                  class: "input_select",
                },

                components: [
                  {
                    tagName: "option",
                    type: "default",
                    attributes: {
                      value: "option1",
                    },

                    components: [
                      {
                        tagName: "p",
                        type: "text",
                        editable: true,

                        components: {
                          type: "textnode",
                          content: "Option 1",
                        },
                      },
                    ],
                  },
                  {
                    tagName: "option",
                    type: "default",
                    attributes: {
                      value: "option2",
                    },

                    components: [
                      {
                        tagName: "p",
                        type: "text",
                        editable: true,

                        components: {
                          type: "textnode",
                          content: "Option 2",
                        },
                      },
                    ],
                  },
                  {
                    tagName: "option",
                    type: "default",
                    attributes: {
                      value: "option3",
                    },

                    components: [
                      {
                        tagName: "p",
                        type: "text",
                        editable: true,

                        components: {
                          type: "textnode",
                          content: "Option 3",
                        },
                      },
                    ],
                  },
                ],
              },
            ],

            // traits
            SetOptionsManually: "",

            CustomUrl: "",
            BaseUrl: "",
            APIPath: "",
            BearerToken: "",
            Username: "",
            Password: "",
            MoreHeaders: "",
            ArrOfObjKyename: "",
            NestedKeyname: "",

            Autofocus: false,
            Multiple: false,
            Disabled: false,
            Required: false,
            Size: "",

            options: {
              baseUrl1,
              baseUrl2,
              baseUrl3,
              baseUrl4,
              baseUrl5,
            },

            projectId: localStorage.getItem("project_id")
              ? localStorage.getItem("project_id")
              : "",

            traits: [
              // "id",
              // "name",
              // "form",
              {
                type: "text",
                name: "SetOptionsManually",
                label: "Set Options Manually",
                placeholder: "Option 1,Option 2,Option 3",
                changeProp: true,
              },
              {
                type: "text",
                name: "CustomUrl",
                label: "Custom URL",
                changeProp: true,
              },
              {
                type: "select",
                name: "BaseUrl",
                label: "Base URL",
                options: [
                  { id: "baseUrl1", name: "Base URL #1" },
                  { id: "baseUrl2", name: "Base URL #2" },
                  { id: "baseUrl3", name: "Base URL #3" },
                  { id: "baseUrl4", name: "Base URL #4" },
                  { id: "baseUrl5", name: "Base URL #5" },
                  { id: "null", name: "No Base URL" },
                ],
                changeProp: true,
              },
              {
                type: "text",
                name: "APIPath",
                label: "API Path",
                placeholder: "Don't start with '/'",
                changeProp: true,
              },
              {
                type: "text",
                name: "BearerToken",
                label: "Bearer Token",
                placeholder: "No bearer token provided",
                changeProp: true,
              },
              {
                type: "text",
                name: "Username",
                label: "Username",
                changeProp: true,
              },
              {
                type: "text",
                name: "Password",
                label: "Password",
                changeProp: true,
              },
              {
                type: "text",
                name: "MoreHeaders",
                label: "More Headers",
                placeholder: "k1:v1,k2:v2,k3:v3,...",
                changeProp: true,
              },
              {
                type: "text",
                name: "ArrOfObjKyename",
                label: "Keyname From Array Of Object",
                placeholder: "[ {k1:v1}, {k1:v2} ]",
                changeProp: true,
              },
              {
                type: "text",
                name: "NestedKeyname",
                label: "Keyname For Nested Structure",
                placeholder: "{ k1: [{...}, {...}] }",
                changeProp: true,
              },
              {
                type: "checkbox",
                name: "Autofocus",
                label: "Autofocus",
                changeProp: true,
              },
              {
                type: "checkbox",
                name: "Multiple",
                label: "Multiple",
                changeProp: true,
              },
              {
                type: "checkbox",
                name: "Disabled",
                label: "Disabled",
                changeProp: true,
              },
              {
                type: "checkbox",
                name: "Required",
                label: "Required",
                changeProp: true,
              },
              {
                type: "number",
                name: "Size",
                label: "Size",
                min: "1",
                changeProp: true,
              },
            ],

            changeProp: true,

            "script-props": [
              "SetOptionsManually",
              "CustomUrl",
              "BaseUrl",
              "APIPath",
              "BearerToken",
              "Username",
              "Password",
              "MoreHeaders",
              "ArrOfObjKyename",
              "NestedKeyname",
              "Multiple",
              "Autofocus",
              "Disabled",
              "Required",
              "Size",
              "projectId",
              "options",
            ],
          },

          init() {
            this.on("change:SetOptionsManually", this.handlePropChange);
            this.on("change:CustomUrl", this.handlePropChange);
            this.on("change:BaseUrl", this.handlePropChange);
            this.on("change:APIPath", this.handlePropChange);
            this.on("change:BearerToken", this.handlePropChange);
            this.on("change:Username", this.handlePropChange);
            this.on("change:Password", this.handlePropChange);
            this.on("change:MoreHeaders", this.handlePropChange);
            this.on("change:ArrOfObjKyename", this.handlePropChange);
            this.on("change:NestedKeyname", this.handlePropChange);
            this.on("change:Multiple", this.handlePropChange);
            this.on("change:Autofocus", this.handlePropChange);
            this.on("change:Disabled", this.handlePropChange);
            this.on("change:Required", this.handlePropChange);
            this.on("change:Size", this.handlePropChange);
          },

          async handlePropChange() {
            const {
              SetOptionsManually,
              CustomUrl,
              BaseUrl,
              APIPath,
              BearerToken,
              Username,
              Password,
              MoreHeaders,
              ArrOfObjKyename,
              NestedKeyname,
              Multiple,
              Autofocus,
              Disabled,
              Required,
              Size,
            } = this.props();
            console.log(
              "this, SetOptionsManually, CustomUrl, BaseUrl, APIPath, BearerToken, Username, Password, MoreHeaders, ArrOfObjKyename, NestedKeyname, Multiple, Autofocus, Disabled, Required, Size",
              this,
              SetOptionsManually,
              APIPath,
              BearerToken,
              Username,
              Password,
              MoreHeaders,
              ArrOfObjKyename,
              NestedKeyname,
              Multiple,
              Autofocus,
              Disabled,
              Required,
              Size
            );
            console.log("baseUrlData", baseUrlData);

            // to rename a variable because API var is getting used in many places in this block scope
            let API;
            if (APIPath) {
              API = APIPath;
            }

            // helper function to check if a variable is an array-of-objects or not
            function isArrayofObjects(variable) {
              // Check if the variable is an array
              if (!Array.isArray(variable)) {
                return false;
              }

              // Check if all elements in the array are objects
              for (const element of variable) {
                if (
                  typeof element !== "object" ||
                  Array.isArray(element) ||
                  element === null
                ) {
                  return false;
                }
              }
              return true;
            }

            // helper function to get value of a key in nested object
            function findKeyValue(obj, key) {
              if (obj.hasOwnProperty(key)) {
                // If the current object has the key, return its value
                return obj[key];
              }

              for (var prop in obj) {
                if (obj.hasOwnProperty(prop) && typeof obj[prop] === "object") {
                  // If the current property is an object, recursively search within it
                  var result = findKeyValue(obj[prop], key);
                  if (result !== undefined) {
                    return result;
                  }
                }
              }
              // Key not found in the object
              return undefined;
            }

            const container = this;
            let apiData;
            console.log("container", container);
            const dropdown = container.find("select.input_select")[0];
            console.log("dropdown", dropdown);

            if (SetOptionsManually) {
              let options = SetOptionsManually.split(",");

              dropdown.components("");

              options.forEach((opt) => {
                let optionEle = {
                  tagName: "option",
                  attributes: {
                    value: `${opt}`,
                  },

                  components: [
                    {
                      tagName: "p",
                      type: "text",
                      editable: true,

                      components: {
                        type: "textnode",
                        content: `${opt}`,
                      },
                    },
                  ],
                };

                dropdown.append(optionEle);
              });
            }
            if (Autofocus) {
              if (dropdown) {
                dropdown.addAttributes({ autofocus: true });
              }
            } else {
              if (dropdown) {
                dropdown.removeAttributes("autofocus");
              }
            }
            if (Multiple) {
              if (dropdown) {
                dropdown.addAttributes({ multiple: true });
              }
            } else {
              if (dropdown) {
                dropdown.removeAttributes("multiple");
              }
            }
            if (Disabled) {
              if (dropdown) {
                dropdown.addAttributes({ disabled: true });
              }
            } else {
              if (dropdown) {
                dropdown.removeAttributes("disabled");
              }
            }
            if (Required) {
              if (dropdown) {
                dropdown.addAttributes({ required: true });
              }
            } else {
              if (dropdown) {
                dropdown.removeAttributes("required");
              }
            }
            if (Size) {
              if (dropdown) {
                dropdown.addAttributes({ size: `${Size}` });
              }
            } else {
              if (dropdown) {
                dropdown.removeAttributes("size");
              }
            }

            // -------- Getting API Data

            // if CustomUrl is provided, use only that
            if (CustomUrl && (!BaseUrl || BaseUrl === "null")) {
              console.log("CustomUrl", CustomUrl);
              // if nothing is provided for auth
              if (!BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  headers: {},
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is provided but not api_key or usr+pwd
              else if (BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${BearerToken}`,
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token and api_key not provided but usr+pwd are provided
              else if (!BearerToken && Username && Password) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    Authorization: "Basic " + btoa(Username + ":" + Password),
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }
            }

            // if base url is the choice and provided
            else if (BaseUrl && API && !CustomUrl) {
              // concatenating base url and its remaining part
              let url, baseUrlBearerToken;
              if (BaseUrl === "baseUrl1") {
                url = baseUrl1 + API;
                baseUrlBearerToken = baseUrl1BearerToken;
              } else if (BaseUrl === "baseUrl2") {
                url = baseUrl2 + API;
                baseUrlBearerToken = baseUrl2BearerToken;
              } else if (BaseUrl === "baseUrl3") {
                url = baseUrl3 + API;
                baseUrlBearerToken = baseUrl3BearerToken;
              } else if (BaseUrl === "baseUrl4") {
                url = baseUrl4 + API;
                baseUrlBearerToken = baseUrl4BearerToken;
              } else if (BaseUrl === "baseUrl5") {
                url = baseUrl5 + API;
                baseUrlBearerToken = baseUrl5BearerToken;
              }
              console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

              // if nothing is provided for auth
              if (
                !BearerToken &&
                !baseUrlBearerToken &&
                !Username &&
                !Password
              ) {
                // add extra headers, if needed
                let config = {
                  headers: {},
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is provided but not usr+pwd
              else if (BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${BearerToken}`,
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a baseUrlBearerToken is provided but not Custom Bearer Token and usr+pwd
              else if (
                baseUrlBearerToken &&
                !BearerToken &&
                !Username &&
                !Password
              ) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${baseUrlBearerToken}`,
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config@:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is not provided but usr+pwd are provided
              else if (!BearerToken && Username && Password) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    Authorization: "Basic " + btoa(Username + ":" + Password),
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }
            }

            console.log("apiData:", apiData);

            // -------- Populating API Data, into the dropdown list
            if (apiData !== undefined) {
              console.log("#");
              // if API response is directly an array-of-objects
              if (isArrayofObjects(apiData)) {
                if (ArrOfObjKyename) {
                  console.log("##");
                  dropdown.components("");

                  apiData.forEach((opt) => {
                    let optionEle = {
                      tagName: "option",
                      type: "default",
                      attributes: {
                        value: `${opt[`${ArrOfObjKyename}`]}`,
                        data_db_id: `${opt[`_id`]}`
                          ? `${opt[`_id`]}`
                          : `${opt[`${ArrOfObjKyename}`]}`,
                      },

                      components: [
                        {
                          tagName: "p",
                          type: "text",
                          editable: true,

                          components: {
                            type: "textnode",
                            content: `${opt[`${ArrOfObjKyename}`]}`,
                          },
                        },
                      ],
                    };

                    dropdown.append(optionEle);
                  });
                }
              }

              // if API response is a plain array of single elements
              else if (Array.isArray(apiData) && !isArrayofObjects(apiData)) {
                console.log("###");
                dropdown.components("");

                apiData.forEach((opt) => {
                  let optionEle = {
                    tagName: "option",
                    type: "default",
                    attributes: {
                      value: `${opt}`,
                    },

                    components: [
                      {
                        tagName: "p",
                        type: "text",
                        editable: true,

                        components: {
                          type: "textnode",
                          content: `${opt}`,
                        },
                      },
                    ],
                  };

                  dropdown.append(optionEle);
                });
              }

              // if API response is an object
              else if (
                !isArrayofObjects(apiData) &&
                typeof apiData === "object"
              ) {
                // if API response to plot is a nested array-of-objects inside the main response object
                if (NestedKeyname && ArrOfObjKyename) {
                  console.log("####");
                  dropdown.components("");

                  // find the array-of-objects value
                  let data = findKeyValue(apiData, NestedKeyname);

                  if (isArrayofObjects(data)) {
                    data.forEach((opt) => {
                      let optionEle = {
                        tagName: "option",
                        type: "default",
                        attributes: {
                          value: `${opt[`${ArrOfObjKyename}`]}`,
                        },

                        components: [
                          {
                            tagName: "p",
                            type: "text",
                            editable: true,

                            components: {
                              type: "textnode",
                              content: `${opt[`${ArrOfObjKyename}`]}`,
                            },
                          },
                        ],
                      };

                      dropdown.append(optionEle);
                    });
                  }
                } else if (NestedKeyname && !ArrOfObjKyename) {
                  dropdown.components("");

                  // find the array value
                  let data = findKeyValue(apiData, NestedKeyname);

                  // if API response to plot is a nested plain array of single elements inside the main response object
                  if (Array.isArray(data) && !isArrayofObjects(data)) {
                    console.log("#####");
                    data.forEach((opt) => {
                      let optionEle = {
                        tagName: "option",
                        type: "default",
                        attributes: {
                          value: `${opt}`,
                        },

                        components: [
                          {
                            tagName: "p",
                            type: "text",
                            editable: true,

                            components: {
                              type: "textnode",
                              content: `${opt}`,
                            },
                          },
                        ],
                      };

                      dropdown.append(optionEle);
                    });
                  }

                  // if API response to plot are all key:value pairs of a plain object inside main object
                  else if (
                    !Array.isArray(data) &&
                    !isArrayofObjects(data) &&
                    typeof data === "object"
                  ) {
                    console.log("######");
                    Object.keys(data).forEach((key) => {
                      if (
                        !Array.isArray(data[`${key}`]) &&
                        typeof data[`${key}`] !== "object"
                      ) {
                        let optionEle = {
                          tagName: "option",
                          type: "default",
                          attributes: {
                            value: `${data[`${key}`]}`,
                          },

                          components: [
                            {
                              tagName: "p",
                              type: "text",
                              editable: true,

                              components: {
                                type: "textnode",
                                content: `${key}`,
                              },
                            },
                          ],
                        };

                        dropdown.append(optionEle);
                      }
                    });
                  }
                }

                // if API response to plot are all key:value pairs of the plain object
                else if (!NestedKeyname && !ArrOfObjKyename) {
                  console.log("#######");
                  dropdown.components("");

                  Object.keys(apiData).forEach((key) => {
                    if (
                      !Array.isArray(apiData[`${key}`]) &&
                      typeof apiData[`${key}`] !== "object"
                    ) {
                      let optionEle = {
                        tagName: "option",
                        type: "default",
                        attributes: {
                          value: `${apiData[`${key}`]}`,
                        },

                        components: [
                          {
                            tagName: "p",
                            type: "text",
                            editable: true,

                            components: {
                              type: "textnode",
                              content: `${key}`,
                            },
                          },
                        ],
                      };

                      dropdown.append(optionEle);
                    }
                  });
                }

                // if there is a 'message' in api response instead of data
                else if (Object.keys(apiData).includes('message')) {
                    console.log("#@@");

                     dropdown.components("");

                    // initializing with a disabled first option
                    let disabledOptionEle = {
                        tagName: "option",
                        attributes: {
                          disabled: true,
                          selected: true,
                        },

                        components: [
                          {
                            tagName: "p",
                            type: "text",
                            editable: true,

                            components: {
                              type: "textnode",
                              content: "",
                              // content: `${apiData['message']}`,
                            },
                          },
                        ],
                    };

                    dropdown.append(disabledOptionEle);
                }
              }
            }
          },
        },

        view: {
          onRender({ el, model }) {
            console.log("el", el, el.tagName);
            if (el.getAttribute("data-gjs-type") === "input_select" && (el.tagName === "DIV" || el.tagName === "SPAN" || el.tagName === "FORM") ) {
              console.log("rendering", el);
              model.handlePropChange();
            }
          },
        },
      });

      const inputSelectV2Script = async function (props) {
        console.log("input_select_v2 props", props, this);

        // For LB download manipulation
        // checking if the url or script is not running in the redsling editor, only then run the script to plot checkboxes
        let idEl = this.id;
        const urlDownload = window.top.location.href;
        const isSubstringPresent =
          (urlDownload.indexOf("/editor/") !== -1 &&
            urlDownload.indexOf("?projectId=") !== -1) ||
          urlDownload.indexOf("/large_preview/") !== -1 ||
          urlDownload.indexOf("/tab_preview/") !== -1 ||
          urlDownload.indexOf("/mobile_preview/") !== -1 ||
          urlDownload.indexOf("/fragment_editor/") !== -1;
        if (!isSubstringPresent) {
          setInterval(function () {
            Object.keys(props).forEach(function (key) {
              if (window[`${key}${idEl}`]) {
                props[key] = window[`${key}${idEl}`];
              }
            });
          }, 500);
        }

        // helper function to check if a variable is an array-of-objects or not
        function isArrayofObjects(variable) {
          // Check if the variable is an array
          if (!Array.isArray(variable)) {
            return false;
          }

          // Check if all elements in the array are objects
          for (const element of variable) {
            if (
              typeof element !== "object" ||
              Array.isArray(element) ||
              element === null
            ) {
              return false;
            }
          }
          return true;
        }

        // helper function to get value of a key in nested object
        function findKeyValue(obj, key) {
          if (obj.hasOwnProperty(key)) {
            // If the current object has the key, return its value
            return obj[key];
          }

          for (var prop in obj) {
            if (obj.hasOwnProperty(prop) && typeof obj[prop] === "object") {
              // If the current property is an object, recursively search within it
              var result = findKeyValue(obj[prop], key);
              if (result !== undefined) {
                return result;
              }
            }
          }
          // Key not found in the object
          return undefined;
        }

        // base url bearer token taken directly from local storage after getting downloaded
        let baseUrlData,
          dynamicBaseUrlBearerToken,
          baseUrl1BearerToken,
          baseUrl2BearerToken,
          baseUrl3BearerToken,
          baseUrl4BearerToken,
          baseUrl5BearerToken;
        // getting baseUrl data from local storage for run-time access
        let baseUrlDataFromStorage = localStorage.getItem("baseUrlData");
        if (baseUrlDataFromStorage) {
          baseUrlData = JSON.parse(baseUrlDataFromStorage);
        }
        // extracting token values from base URLs from baseUrlData
        if (baseUrlData) {
          try {
            if (`baseUrl1_${props.projectId}` in baseUrlData) {
              baseUrl1BearerToken =
                baseUrlData[`baseUrl1_${props.projectId}`].token;
            }
            if (`baseUrl2_${props.projectId}` in baseUrlData) {
              baseUrl2BearerToken =
                baseUrlData[`baseUrl2_${props.projectId}`].token;
            }
            if (`baseUrl3_${props.projectId}` in baseUrlData) {
              baseUrl3BearerToken =
                baseUrlData[`baseUrl3_${props.projectId}`].token;
            }
            if (`baseUrl4_${props.projectId}` in baseUrlData) {
              baseUrl4BearerToken =
                baseUrlData[`baseUrl4_${props.projectId}`].token;
            }
            if (`baseUrl5_${props.projectId}` in baseUrlData) {
              baseUrl5BearerToken =
                baseUrlData[`baseUrl5_${props.projectId}`].token;
            }
          } catch (e) {
            // statements
            console.log(e);
          }
        }

        let apiData;
        const {
          CustomUrl,
          BaseUrl,
          APIPath,
          BearerToken,
          Username,
          Password,
          MoreHeaders,
          APIMethod,
          APIBody,
          ArrOfObjKyename,
          NestedKeyname,
        } = props;
        // console.log('CustomUrl, BaseUrl, APIPath, BearerToken, Username, Password, MoreHeaders, ArrOfObjKyename, NestedKeyname,',CustomUrl, BaseUrl, APIPath, BearerToken, Username, Password, MoreHeaders, ArrOfObjKyename, NestedKeyname);

        // to rename a variable because API var is getting used in many places in this block scope
        let API;
        if (APIPath) {
          API = APIPath;
        }

        const container = this;
        const dropdown = container.getElementsByClassName("input_select_v2")[0];

        // checking if the url or script is not running in the redsling editor, only then run the script to plot checkboxes

        if (!isSubstringPresent) {
          // -------- Getting API Data
          // if CustomUrl is provided, use only that
          if (CustomUrl && (!BaseUrl || BaseUrl === "null")) {
            // if nothing is provided for auth
            if (!BearerToken && !Username && !Password) {
              // add extra headers, if needed
              let config = {
                headers: {},
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${CustomUrl}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token is provided but not api_key or usr+pwd
            else if (BearerToken && !Username && !Password) {
              // add extra headers, if needed
              let config = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${BearerToken}`,
                },
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${CustomUrl}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token and api_key not provided but usr+pwd are provided
            else if (!BearerToken && Username && Password) {
              // add extra headers, if needed
              let config = {
                headers: {
                  Authorization: "Basic " + btoa(Username + ":" + Password),
                },
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${CustomUrl}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }
          }

          // if base url is the choice and provided
          else if (BaseUrl && API && !CustomUrl) {
            // concatenating base url and its remaining part
            let url, baseUrlBearerToken;
            if (BaseUrl === "baseUrl1") {
              url = props.options.baseUrl1 + API;
              baseUrlBearerToken = baseUrl1BearerToken;
            } else if (BaseUrl === "baseUrl2") {
              url = props.options.baseUrl2 + API;
              baseUrlBearerToken = baseUrl2BearerToken;
            } else if (BaseUrl === "baseUrl3") {
              url = props.options.baseUrl3 + API;
              baseUrlBearerToken = baseUrl3BearerToken;
            } else if (BaseUrl === "baseUrl4") {
              url = props.options.baseUrl4 + API;
              baseUrlBearerToken = baseUrl4BearerToken;
            } else if (BaseUrl === "baseUrl5") {
              url = props.options.baseUrl5 + API;
              baseUrlBearerToken = baseUrl5BearerToken;
            }
            console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

            // if nothing is provided for auth
            if (!BearerToken && !baseUrlBearerToken && !Username && !Password) {
              // add extra headers, if needed
              let config = {
                headers: {},
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token is provided but not usr+pwd
            else if (BearerToken && !Username && !Password) {
              // add extra headers, if needed
              let config = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${BearerToken}`,
                },
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a baseUrlBearerToken is provided but not Custom Bearer Token and usr+pwd
            else if (
              baseUrlBearerToken &&
              !BearerToken &&
              !Username &&
              !Password
            ) {
              // add extra headers, if needed
              let config = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${baseUrlBearerToken}`,
                },
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config@:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token is not provided but usr+pwd are provided
            else if (!BearerToken && Username && Password) {
              // add extra headers, if needed
              let config = {
                headers: {
                  Authorization: "Basic " + btoa(Username + ":" + Password),
                },
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }
          }

          // -------- Getting API Data (POST method)

          // if CustomUrl is provided, use only that
          else if (
            CustomUrl &&
            (!BaseUrl || BaseUrl === "null") &&
            APIMethod === "POST"
          ) {
            console.log("CustomUrl", CustomUrl);
            // if nothing is provided for auth
            if (!BearerToken && !Username && !Password) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {},
                body:
                  typeof APIBody === "object"
                    ? JSON.stringify(APIBody)
                    : APIBody,
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${CustomUrl}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token is provided but not api_key or usr+pwd
            else if (BearerToken && !Username && !Password) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${BearerToken}`,
                },
                body:
                  typeof APIBody === "object"
                    ? JSON.stringify(APIBody)
                    : APIBody,
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${CustomUrl}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token and api_key not provided but usr+pwd are provided
            else if (!BearerToken && Username && Password) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {
                  Authorization: "Basic " + btoa(Username + ":" + Password),
                },
                body:
                  typeof APIBody === "object"
                    ? JSON.stringify(APIBody)
                    : APIBody,
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${CustomUrl}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }
          }

          // if base url is the choice and provided
          else if (BaseUrl && API && !CustomUrl && APIMethod === "POST") {
            // concatenating base url and its remaining part
            let url, baseUrlBearerToken;
            if (BaseUrl === "baseUrl1") {
              url = props.options.baseUrl1 + API;
              baseUrlBearerToken = baseUrl1BearerToken;
            } else if (BaseUrl === "baseUrl2") {
              url = props.options.baseUrl2 + API;
              baseUrlBearerToken = baseUrl2BearerToken;
            } else if (BaseUrl === "baseUrl3") {
              url = props.options.baseUrl3 + API;
              baseUrlBearerToken = baseUrl3BearerToken;
            } else if (BaseUrl === "baseUrl4") {
              url = props.options.baseUrl4 + API;
              baseUrlBearerToken = baseUrl4BearerToken;
            } else if (BaseUrl === "baseUrl5") {
              url = props.options.baseUrl5 + API;
              baseUrlBearerToken = baseUrl5BearerToken;
            }
            console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

            // if nothing is provided for auth
            if (!BearerToken && !baseUrlBearerToken && !Username && !Password) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {},
                body:
                  typeof APIBody === "object"
                    ? JSON.stringify(APIBody)
                    : APIBody,
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token is provided but not usr+pwd
            else if (BearerToken && !Username && !Password) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${BearerToken}`,
                },
                body:
                  typeof APIBody === "object"
                    ? JSON.stringify(APIBody)
                    : APIBody,
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a baseUrlBearerToken is provided but not Custom Bearer Token and usr+pwd
            else if (
              baseUrlBearerToken &&
              !BearerToken &&
              !Username &&
              !Password
            ) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${baseUrlBearerToken}`,
                },
                body:
                  typeof APIBody === "object"
                    ? JSON.stringify(APIBody)
                    : APIBody,
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config@:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }

            // if a bearer token is not provided but usr+pwd are provided
            else if (!BearerToken && Username && Password) {
              // add extra headers, if needed
              let config = {
                method: "POST",
                headers: {
                  Authorization: "Basic " + btoa(Username + ":" + Password),
                },
                body:
                  typeof APIBody === "object"
                    ? JSON.stringify(APIBody)
                    : APIBody,
              };

              if (MoreHeaders) {
                // ['a:b', "c:d"]
                // extracting headers from More_Headers trait
                MoreHeaders.split(",").forEach((header) => {
                  let key, val;
                  [key, val] = header.split(":");
                  console.log("[key, val]:\n", key, val);

                  config.headers[key] = val;
                });
              }

              console.log("config:", config);

              try {
                // GET call to get the data
                let response = await fetch(`${url}`, config);
                let responseData = await response.json();
                apiData = responseData;
              } catch (e) {
                // statements
                console.log(e);
              }
            }
          }

          console.log("apiData:", apiData);

          // -------- Populating API Data, into the dropdown list
          if (apiData !== undefined) {
            console.log("#");
            // if API response is directly an array-of-objects
            if (isArrayofObjects(apiData)) {
              if (ArrOfObjKyename) {
                console.log("##");
                dropdown.innerHTML = "";

                apiData.forEach((opt) => {
                  let optionEle = document.createElement("option");
                  optionEle.setAttribute(
                    "value",
                    `${opt[`${ArrOfObjKyename}`]}`
                  );
                  optionEle.setAttribute(
                    "data_db_id",
                    `${opt[`_id`]}`
                      ? `${opt[`_id`]}`
                      : `${opt[`${ArrOfObjKyename}`]}`
                  );
                  optionEle.innerHTML = `${opt[`${ArrOfObjKyename}`]}`;

                  dropdown.appendChild(optionEle);
                });
              }
            }

            // if API response is a plain array of single elements
            else if (Array.isArray(apiData) && !isArrayofObjects(apiData)) {
              console.log("###");
              dropdown.innerHTML = "";

              apiData.forEach((opt) => {
                let optionEle = document.createElement("option");
                optionEle.setAttribute("value", `${opt}`);
                optionEle.innerHTML = `${opt}`;

                dropdown.appendChild(optionEle);
              });
            }

            // if API response is an object
            else if (
              !isArrayofObjects(apiData) &&
              typeof apiData === "object"
            ) {
              // if API response to plot is a nested array-of-objects inside the main response object
              if (NestedKeyname && ArrOfObjKyename) {
                console.log("####");
                dropdown.innerHTML = "";

                // find the array-of-objects value
                let data = findKeyValue(apiData, NestedKeyname);

                if (isArrayofObjects(data)) {
                  data.forEach((opt) => {
                    let optionEle = document.createElement("option");
                    optionEle.setAttribute(
                      "value",
                      `${opt[`${ArrOfObjKyename}`]}`
                    );
                    optionEle.innerHTML = `${opt[`${ArrOfObjKyename}`]}`;

                    dropdown.appendChild(optionEle);
                  });
                }
              } else if (NestedKeyname && !ArrOfObjKyename) {
                dropdown.components("");

                // find the array value
                let data = findKeyValue(apiData, NestedKeyname);

                // if API response to plot is a nested plain array of single elements inside the main response object
                if (Array.isArray(data) && !isArrayofObjects(data)) {
                  console.log("#####");
                  data.forEach((opt) => {
                    let optionEle = document.createElement("option");
                    optionEle.setAttribute("value", `${opt}`);
                    optionEle.innerHTML = `${opt}`;

                    dropdown.appendChild(optionEle);
                  });
                }

                // if API response to plot are all key:value pairs of a plain object inside main object
                else if (
                  !Array.isArray(data) &&
                  !isArrayofObjects(data) &&
                  typeof data === "object"
                ) {
                  console.log("######");
                  Object.keys(data).forEach((key) => {
                    if (
                      !Array.isArray(data[`${key}`]) &&
                      typeof data[`${key}`] !== "object"
                    ) {
                      let optionEle = document.createElement("option");
                      optionEle.setAttribute("value", `${data[`${key}`]}`);
                      optionEle.innerHTML = `${key}`;

                      dropdown.appendChild(optionEle);
                    }
                  });
                }
              }

              // if API response to plot are all key:value pairs of the plain object
              else if (!NestedKeyname && !ArrOfObjKyename) {
                console.log("#######");
                dropdown.components("");

                Object.keys(apiData).forEach((key) => {
                  if (
                    !Array.isArray(apiData[`${key}`]) &&
                    typeof apiData[`${key}`] !== "object"
                  ) {
                    let optionEle = document.createElement("option");
                    optionEle.setAttribute("value", `${apiData[`${key}`]}`);
                    optionEle.innerHTML = `${key}`;

                    dropdown.appendChild(optionEle);
                  }
                });
              }

              // if there is a 'message' in api response instead of data
              else if (Object.keys(apiData).includes('message')) {
                console.log("#@@");
                dropdown.innerHTML = "";

                let disabledOptionEle = document.createElement("option");
                disabledOptionEle.setAttribute("disabled", true);
                disabledOptionEle.setAttribute("selected", true);
                disabledOptionEle.innerHTML = "";

                dropdown.appendChild(disabledOptionEle);
              }
            }
          }
        }
      };

      editor.DomComponents.addType("input_select_v2", {
        model: {
          defaults: {
            tagName: "span",
            attributes: {
              name: "input_select_v2_container",
              class: "input_select_v2_container",
            },
            script: inputSelectV2Script,

            components: [
              {
                tagName: "label",
                type: "default",
                attributes: {
                  for: "",
                },

                components: [
                  {
                    tagName: "p",
                    type: "text",
                    editable: true,
                    content: "Select an option:",
                  },
                ],

                traits: ["for"],
              },
              {
                tagName: "select",
                type: "default",
                // script: dropdownListScript,
                attributes: {
                  // id: "input_select",
                  name: "input_select_v2",
                  class: "input_select_v2",
                },

                components: [
                  {
                    tagName: "option",
                    type: "default",
                    attributes: {
                      value: "option1",
                    },

                    components: [
                      {
                        tagName: "p",
                        type: "text",
                        editable: true,

                        components: {
                          type: "textnode",
                          content: "Option 1",
                        },
                      },
                    ],
                  },
                  {
                    tagName: "option",
                    type: "default",
                    attributes: {
                      value: "option2",
                    },

                    components: [
                      {
                        tagName: "p",
                        type: "text",
                        editable: true,

                        components: {
                          type: "textnode",
                          content: "Option 2",
                        },
                      },
                    ],
                  },
                  {
                    tagName: "option",
                    type: "default",
                    attributes: {
                      value: "option3",
                    },

                    components: [
                      {
                        tagName: "p",
                        type: "text",
                        editable: true,

                        components: {
                          type: "textnode",
                          content: "Option 3",
                        },
                      },
                    ],
                  },
                ],
              },
            ],

            // traits
            SetOptionsManually: "",

            CustomUrl: "",
            BaseUrl: "",
            APIPath: "",
            BearerToken: "",
            Username: "",
            Password: "",
            MoreHeaders: "",
            APIMethod: "GET",
            APIBody: "",
            ArrOfObjKyename: "",
            NestedKeyname: "",

            Autofocus: false,
            Multiple: false,
            Disabled: false,
            Required: false,
            Size: "",

            options: {
              baseUrl1,
              baseUrl2,
              baseUrl3,
              baseUrl4,
              baseUrl5,
            },

            projectId: localStorage.getItem("project_id")
              ? localStorage.getItem("project_id")
              : "",

            traits: [
              // "id",
              // "name",
              // "form",
              {
                type: "text",
                name: "SetOptionsManually",
                label: "Set Options Manually",
                placeholder: "Option 1,Option 2,Option 3",
                changeProp: true,
              },
              {
                type: "text",
                name: "CustomUrl",
                label: "Custom URL",
                changeProp: true,
              },
              {
                type: "select",
                name: "BaseUrl",
                label: "Base URL",
                options: [
                  { id: "baseUrl1", name: "Base URL #1" },
                  { id: "baseUrl2", name: "Base URL #2" },
                  { id: "baseUrl3", name: "Base URL #3" },
                  { id: "baseUrl4", name: "Base URL #4" },
                  { id: "baseUrl5", name: "Base URL #5" },
                  { id: "null", name: "No Base URL" },
                ],
                changeProp: true,
              },
              {
                type: "text",
                name: "APIPath",
                label: "API Path",
                placeholder: "Don't start with '/'",
                changeProp: true,
              },
              {
                type: "text",
                name: "BearerToken",
                label: "Bearer Token",
                placeholder: "No bearer token provided",
                changeProp: true,
              },
              {
                type: "text",
                name: "Username",
                label: "Username",
                changeProp: true,
              },
              {
                type: "text",
                name: "Password",
                label: "Password",
                changeProp: true,
              },
              {
                type: "text",
                name: "MoreHeaders",
                label: "More Headers",
                placeholder: "k1:v1,k2:v2,k3:v3,...",
                changeProp: true,
              },
              {
                type: "select",
                name: "APIMethod",
                label: "API Method",
                options: [
                  { id: "GET", name: "Get" },
                  { id: "POST", name: "Post" },
                ],
                changeProp: true,
              },
              {
                type: "text",
                name: "APIBody",
                label: "API Body",
                placeholder: "Json format",
                changeProp: true,
              },
              {
                type: "text",
                name: "ArrOfObjKyename",
                label: "Keyname From Array Of Object",
                placeholder: "[ {k1:v1}, {k1:v2} ]",
                changeProp: true,
              },
              {
                type: "text",
                name: "NestedKeyname",
                label: "Keyname For Nested Structure",
                placeholder: "{ k1: [{...}, {...}] }",
                changeProp: true,
              },
              {
                type: "checkbox",
                name: "Autofocus",
                label: "Autofocus",
                changeProp: true,
              },
              {
                type: "checkbox",
                name: "Multiple",
                label: "Multiple",
                changeProp: true,
              },
              {
                type: "checkbox",
                name: "Disabled",
                label: "Disabled",
                changeProp: true,
              },
              {
                type: "checkbox",
                name: "Required",
                label: "Required",
                changeProp: true,
              },
              {
                type: "number",
                name: "Size",
                label: "Size",
                min: "1",
                changeProp: true,
              },
            ],

            changeProp: true,

            "script-props": [
              "SetOptionsManually",
              "CustomUrl",
              "BaseUrl",
              "APIPath",
              "BearerToken",
              "Username",
              "Password",
              "MoreHeaders",
              "APIMethod",
              "APIBody",
              "ArrOfObjKyename",
              "NestedKeyname",
              "Multiple",
              "Autofocus",
              "Disabled",
              "Required",
              "Size",
              "options",
              "projectId",
            ],
          },

          init() {
            this.on("change:SetOptionsManually", this.handlePropChange);
            this.on("change:CustomUrl", this.handlePropChange);
            this.on("change:BaseUrl", this.handlePropChange);
            this.on("change:APIPath", this.handlePropChange);
            this.on("change:BearerToken", this.handlePropChange);
            this.on("change:Username", this.handlePropChange);
            this.on("change:Password", this.handlePropChange);
            this.on("change:MoreHeaders", this.handlePropChange);
            this.on("change:APIMethod", this.handlePropChange);
            this.on("change:APIBody", this.handlePropChange);
            this.on("change:ArrOfObjKyename", this.handlePropChange);
            this.on("change:NestedKeyname", this.handlePropChange);
            this.on("change:Multiple", this.handlePropChange);
            this.on("change:Autofocus", this.handlePropChange);
            this.on("change:Disabled", this.handlePropChange);
            this.on("change:Required", this.handlePropChange);
            this.on("change:Size", this.handlePropChange);
          },

          async handlePropChange() {
            const {
              SetOptionsManually,
              CustomUrl,
              BaseUrl,
              APIPath,
              BearerToken,
              Username,
              Password,
              MoreHeaders,
              APIMethod,
              APIBody,
              ArrOfObjKyename,
              NestedKeyname,
              Multiple,
              Autofocus,
              Disabled,
              Required,
              Size,
            } = this.props();
            console.log(
              "this, SetOptionsManually, CustomUrl, BaseUrl, APIPath, BearerToken, Username, Password, MoreHeaders, APIMethod, APIBody, ArrOfObjKyename, NestedKeyname, Multiple, Autofocus, Disabled, Required, Size",
              this,
              SetOptionsManually,
              APIPath,
              BearerToken,
              Username,
              Password,
              MoreHeaders,
              APIMethod,
              APIBody,
              ArrOfObjKyename,
              NestedKeyname,
              Multiple,
              Autofocus,
              Disabled,
              Required,
              Size
            );
            console.log("baseUrlData", baseUrlData);

            // to rename a variable because API var is getting used in many places in this block scope
            let API;
            if (APIPath) {
              API = APIPath;
            }

            // helper function to check if a variable is an array-of-objects or not
            function isArrayofObjects(variable) {
              // Check if the variable is an array
              if (!Array.isArray(variable)) {
                return false;
              }

              // Check if all elements in the array are objects
              for (const element of variable) {
                if (
                  typeof element !== "object" ||
                  Array.isArray(element) ||
                  element === null
                ) {
                  return false;
                }
              }
              return true;
            }

            // helper function to get value of a key in nested object
            function findKeyValue(obj, key) {
              if (obj.hasOwnProperty(key)) {
                // If the current object has the key, return its value
                return obj[key];
              }

              for (var prop in obj) {
                if (obj.hasOwnProperty(prop) && typeof obj[prop] === "object") {
                  // If the current property is an object, recursively search within it
                  var result = findKeyValue(obj[prop], key);
                  if (result !== undefined) {
                    return result;
                  }
                }
              }
              // Key not found in the object
              return undefined;
            }

            const container = this;
            let apiData;
            console.log("container", container);
            const dropdown = container.find("select.input_select_v2")[0];
            console.log("dropdown", dropdown);

            if (SetOptionsManually) {
              let options = SetOptionsManually.split(",");

              dropdown.components("");

              options.forEach((opt) => {
                let optionEle = {
                  tagName: "option",
                  attributes: {
                    value: `${opt}`,
                  },

                  components: [
                    {
                      tagName: "p",
                      type: "text",
                      editable: true,

                      components: {
                        type: "textnode",
                        content: `${opt}`,
                      },
                    },
                  ],
                };

                dropdown.append(optionEle);
              });
            }
            if (Autofocus) {
              if (dropdown) {
                dropdown.addAttributes({ autofocus: true });
              }
            } else {
              if (dropdown) {
                dropdown.removeAttributes("autofocus");
              }
            }
            if (Multiple) {
              if (dropdown) {
                dropdown.addAttributes({ multiple: true });
              }
            } else {
              if (dropdown) {
                dropdown.removeAttributes("multiple");
              }
            }
            if (Disabled) {
              if (dropdown) {
                dropdown.addAttributes({ disabled: true });
              }
            } else {
              if (dropdown) {
                dropdown.removeAttributes("disabled");
              }
            }
            if (Required) {
              if (dropdown) {
                dropdown.addAttributes({ required: true });
              }
            } else {
              if (dropdown) {
                dropdown.removeAttributes("required");
              }
            }
            if (Size) {
              if (dropdown) {
                dropdown.addAttributes({ size: `${Size}` });
              }
            } else {
              if (dropdown) {
                dropdown.removeAttributes("size");
              }
            }

            // -------- Getting API Data (GET method)

            // if CustomUrl is provided, use only that
            if (CustomUrl && (!BaseUrl || BaseUrl === "null") && APIMethod === "GET" ) {
              console.log("CustomUrl", CustomUrl);
              // if nothing is provided for auth
              if (!BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  headers: {},
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is provided but not api_key or usr+pwd
              else if (BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${BearerToken}`,
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token and api_key not provided but usr+pwd are provided
              else if (!BearerToken && Username && Password) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    Authorization: "Basic " + btoa(Username + ":" + Password),
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }
            }

            // if base url is the choice and provided
            else if (BaseUrl && API && !CustomUrl && APIMethod === "GET") {
              // concatenating base url and its remaining part
              let url, baseUrlBearerToken;
              if (BaseUrl === "baseUrl1") {
                url = baseUrl1 + API;
                baseUrlBearerToken = baseUrl1BearerToken;
              } else if (BaseUrl === "baseUrl2") {
                url = baseUrl2 + API;
                baseUrlBearerToken = baseUrl2BearerToken;
              } else if (BaseUrl === "baseUrl3") {
                url = baseUrl3 + API;
                baseUrlBearerToken = baseUrl3BearerToken;
              } else if (BaseUrl === "baseUrl4") {
                url = baseUrl4 + API;
                baseUrlBearerToken = baseUrl4BearerToken;
              } else if (BaseUrl === "baseUrl5") {
                url = baseUrl5 + API;
                baseUrlBearerToken = baseUrl5BearerToken;
              }
              console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

              // if nothing is provided for auth
              if (
                !BearerToken &&
                !baseUrlBearerToken &&
                !Username &&
                !Password
              ) {
                // add extra headers, if needed
                let config = {
                  headers: {},
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is provided but not usr+pwd
              else if (BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${BearerToken}`,
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a baseUrlBearerToken is provided but not Custom Bearer Token and usr+pwd
              else if (
                baseUrlBearerToken &&
                !BearerToken &&
                !Username &&
                !Password
              ) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${baseUrlBearerToken}`,
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config@:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is not provided but usr+pwd are provided
              else if (!BearerToken && Username && Password) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    Authorization: "Basic " + btoa(Username + ":" + Password),
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }
            }

            // -------- Getting API Data (POST method)

            // if CustomUrl is provided, use only that
            else if (CustomUrl && (!BaseUrl || BaseUrl === "null") && APIMethod === "POST" ) {
              console.log("CustomUrl", CustomUrl);
              // if nothing is provided for auth
              if (!BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {},
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is provided but not api_key or usr+pwd
              else if (BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${BearerToken}`,
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token and api_key not provided but usr+pwd are provided
              else if (!BearerToken && Username && Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    Authorization: "Basic " + btoa(Username + ":" + Password),
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }
            }

            // if base url is the choice and provided
            else if (BaseUrl && API && !CustomUrl && APIMethod === "POST") {
              // concatenating base url and its remaining part
              let url, baseUrlBearerToken;
              if (BaseUrl === "baseUrl1") {
                url = baseUrl1 + API;
                baseUrlBearerToken = baseUrl1BearerToken;
              } else if (BaseUrl === "baseUrl2") {
                url = baseUrl2 + API;
                baseUrlBearerToken = baseUrl2BearerToken;
              } else if (BaseUrl === "baseUrl3") {
                url = baseUrl3 + API;
                baseUrlBearerToken = baseUrl3BearerToken;
              } else if (BaseUrl === "baseUrl4") {
                url = baseUrl4 + API;
                baseUrlBearerToken = baseUrl4BearerToken;
              } else if (BaseUrl === "baseUrl5") {
                url = baseUrl5 + API;
                baseUrlBearerToken = baseUrl5BearerToken;
              }
              console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

              // if nothing is provided for auth
              if (
                !BearerToken &&
                !baseUrlBearerToken &&
                !Username &&
                !Password
              ) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {},
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is provided but not usr+pwd
              else if (BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${BearerToken}`,
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a baseUrlBearerToken is provided but not Custom Bearer Token and usr+pwd
              else if (
                baseUrlBearerToken &&
                !BearerToken &&
                !Username &&
                !Password
              ) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${baseUrlBearerToken}`,
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config@:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is not provided but usr+pwd are provided
              else if (!BearerToken && Username && Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    Authorization: "Basic " + btoa(Username + ":" + Password),
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }
            }

            console.log("apiData:", apiData);

            // -------- Populating API Data, into the dropdown list
            if (apiData !== undefined) {
              console.log("#");
              // if API response is directly an array-of-objects
              if (isArrayofObjects(apiData)) {
                if (ArrOfObjKyename) {
                  console.log("##");
                  dropdown.components("");

                  apiData.forEach((opt) => {
                    let optionEle = {
                      tagName: "option",
                      type: "default",
                      attributes: {
                        value: `${opt[`${ArrOfObjKyename}`]}`,
                        data_db_id: `${opt[`_id`]}`
                          ? `${opt[`_id`]}`
                          : `${opt[`${ArrOfObjKyename}`]}`,
                      },

                      components: [
                        {
                          tagName: "p",
                          type: "text",
                          editable: true,

                          components: {
                            type: "textnode",
                            content: `${opt[`${ArrOfObjKyename}`]}`,
                          },
                        },
                      ],
                    };

                    dropdown.append(optionEle);
                  });
                }
              }

              // if API response is a plain array of single elements
              else if (Array.isArray(apiData) && !isArrayofObjects(apiData)) {
                console.log("###");
                dropdown.components("");

                apiData.forEach((opt) => {
                  let optionEle = {
                    tagName: "option",
                    type: "default",
                    attributes: {
                      value: `${opt}`,
                    },

                    components: [
                      {
                        tagName: "p",
                        type: "text",
                        editable: true,

                        components: {
                          type: "textnode",
                          content: `${opt}`,
                        },
                      },
                    ],
                  };

                  dropdown.append(optionEle);
                });
              }

              // if API response is an object
              else if (!isArrayofObjects(apiData) && typeof apiData === "object" ) {
                // if API response to plot is a nested array-of-objects inside the main response object
                if (NestedKeyname && ArrOfObjKyename) {
                  console.log("####");
                  dropdown.components("");

                  // find the array-of-objects value
                  let data = findKeyValue(apiData, NestedKeyname);

                  if (isArrayofObjects(data)) {
                    data.forEach((opt) => {
                      let optionEle = {
                        tagName: "option",
                        type: "default",
                        attributes: {
                          value: `${opt[`${ArrOfObjKyename}`]}`,
                        },

                        components: [
                          {
                            tagName: "p",
                            type: "text",
                            editable: true,

                            components: {
                              type: "textnode",
                              content: `${opt[`${ArrOfObjKyename}`]}`,
                            },
                          },
                        ],
                      };

                      dropdown.append(optionEle);
                    });
                  }
                } else if (NestedKeyname && !ArrOfObjKyename) {
                  dropdown.components("");

                  // find the array value
                  let data = findKeyValue(apiData, NestedKeyname);

                  // if API response to plot is a nested plain array of single elements inside the main response object
                  if (Array.isArray(data) && !isArrayofObjects(data)) {
                    console.log("#####");
                    data.forEach((opt) => {
                      let optionEle = {
                        tagName: "option",
                        type: "default",
                        attributes: {
                          value: `${opt}`,
                        },

                        components: [
                          {
                            tagName: "p",
                            type: "text",
                            editable: true,

                            components: {
                              type: "textnode",
                              content: `${opt}`,
                            },
                          },
                        ],
                      };

                      dropdown.append(optionEle);
                    });
                  }

                  // if API response to plot are all key:value pairs of a plain object inside main object
                  else if (
                    !Array.isArray(data) &&
                    !isArrayofObjects(data) &&
                    typeof data === "object"
                  ) {
                    console.log("######");
                    Object.keys(data).forEach((key) => {
                      if (
                        !Array.isArray(data[`${key}`]) &&
                        typeof data[`${key}`] !== "object"
                      ) {
                        let optionEle = {
                          tagName: "option",
                          type: "default",
                          attributes: {
                            value: `${data[`${key}`]}`,
                          },

                          components: [
                            {
                              tagName: "p",
                              type: "text",
                              editable: true,

                              components: {
                                type: "textnode",
                                content: `${key}`,
                              },
                            },
                          ],
                        };

                        dropdown.append(optionEle);
                      }
                    });
                  }
                }

                // if API response to plot are all key:value pairs of the plain object
                else if (!NestedKeyname && !ArrOfObjKyename) {
                  console.log("#######");
                  dropdown.components("");

                  Object.keys(apiData).forEach((key) => {
                    if (
                      !Array.isArray(apiData[`${key}`]) &&
                      typeof apiData[`${key}`] !== "object"
                    ) {
                      let optionEle = {
                        tagName: "option",
                        type: "default",
                        attributes: {
                          value: `${apiData[`${key}`]}`,
                        },

                        components: [
                          {
                            tagName: "p",
                            type: "text",
                            editable: true,

                            components: {
                              type: "textnode",
                              content: `${key}`,
                            },
                          },
                        ],
                      };

                      dropdown.append(optionEle);
                    }
                  });
                }

                // if there is a 'message' in api response instead of data
                else if (Object.keys(apiData).includes('message')) {
                    console.log("#@@");

                     dropdown.components("");

                    // initializing with a disabled first option
                    let disabledOptionEle = {
                        tagName: "option",
                        attributes: {
                          disabled: true,
                          selected: true,
                        },

                        components: [
                          {
                            tagName: "p",
                            type: "text",
                            editable: true,

                            components: {
                              type: "textnode",
                              content: "",
                              // content: `${apiData['message']}`,
                            },
                          },
                        ],
                    };

                    dropdown.append(disabledOptionEle);
                }
              }
            }
          },
        },

        view: {
          onRender({ el, model }) {
            console.log("el", el, el.tagName);
            if (el.getAttribute("data-gjs-type") === "input_select_v2" && (el.tagName === "DIV" || el.tagName === "SPAN" || el.tagName === "FORM") ) {
              console.log("rendering", el);
              model.handlePropChange();
            }
          },
        },
      });

      const inputSelectV2DOT1Script = function (props) {
        console.log("input_select_v2 props", props, this);

        // For LB download manipulation
        // checking if the url or script is not running in the redsling editor, only then run the script to plot checkboxes
        let idEl = this.id;
        const urlDownload = window.top.location.href;
        const isSubstringPresent =
          (urlDownload.indexOf("/editor/") !== -1 &&
          urlDownload.indexOf("?projectId=") !== -1) ||
          urlDownload.indexOf("/large_preview/") !== -1 ||
          urlDownload.indexOf("/tab_preview/") !== -1 ||
          urlDownload.indexOf("/mobile_preview/") !== -1 ||
          urlDownload.indexOf("/fragment_editor/") !== -1;

        // helper function to check if a variable is an array-of-objects or not
        function isArrayofObjects(variable) {
          // Check if the variable is an array
          if (!Array.isArray(variable)) {
            return false;
          }

          // Check if all elements in the array are objects
          for (const element of variable) {
            if (
              typeof element !== "object" ||
              Array.isArray(element) ||
              element === null
            ) {
              return false;
            }
          }
          return true;
        }

        // helper function to get value of a key in nested object
        function findKeyValue(obj, key) {
          if (obj.hasOwnProperty(key)) {
            // If the current object has the key, return its value
            return obj[key];
          }

          for (var prop in obj) {
            if (obj.hasOwnProperty(prop) && typeof obj[prop] === "object") {
              // If the current property is an object, recursively search within it
              var result = findKeyValue(obj[prop], key);
              if (result !== undefined) {
                return result;
              }
            }
          }
          // Key not found in the object
          return undefined;
        }

        // base url bearer token taken directly from local storage after getting downloaded
        let baseUrlData,
          dynamicBaseUrlBearerToken,
          baseUrl1BearerToken,
          baseUrl2BearerToken,
          baseUrl3BearerToken,
          baseUrl4BearerToken,
          baseUrl5BearerToken;
        // getting baseUrl data from local storage for run-time access
        let baseUrlDataFromStorage = localStorage.getItem("baseUrlData");
        if (baseUrlDataFromStorage) {
          baseUrlData = JSON.parse(baseUrlDataFromStorage);
        }
        // extracting token values from base URLs from baseUrlData
        if (baseUrlData) {
          try {
            if (`baseUrl1_${props.projectId}` in baseUrlData) {
              baseUrl1BearerToken =
                baseUrlData[`baseUrl1_${props.projectId}`].token;
            }
            if (`baseUrl2_${props.projectId}` in baseUrlData) {
              baseUrl2BearerToken =
                baseUrlData[`baseUrl2_${props.projectId}`].token;
            }
            if (`baseUrl3_${props.projectId}` in baseUrlData) {
              baseUrl3BearerToken =
                baseUrlData[`baseUrl3_${props.projectId}`].token;
            }
            if (`baseUrl4_${props.projectId}` in baseUrlData) {
              baseUrl4BearerToken =
                baseUrlData[`baseUrl4_${props.projectId}`].token;
            }
            if (`baseUrl5_${props.projectId}` in baseUrlData) {
              baseUrl5BearerToken =
                baseUrlData[`baseUrl5_${props.projectId}`].token;
            }
          } catch (e) {
            // statements
            console.log(e);
          }
        }

        let apiData;

        const container = this;
        const dropdown = container.getElementsByClassName("input_select_v2DOT1")[0];

        // checking if the url or script is not running in the redsling editor, only then run the script to plot checkboxes
        async function initLib() {
          const {
            DisabledOption,
            CustomUrl,
            BaseUrl,
            APIPath,
            BearerToken,
            Username,
            Password,
            MoreHeaders,
            APIMethod,
            APIBody,
            ArrOfObjKyename,
            NestedKeyname,
          } = props;
          // console.log('CustomUrl, BaseUrl, APIPath, BearerToken, Username, Password, MoreHeaders, ArrOfObjKyename, NestedKeyname,',CustomUrl, BaseUrl, APIPath, BearerToken, Username, Password, MoreHeaders, ArrOfObjKyename, NestedKeyname);

          // to rename a variable because API var is getting used in many places in this block scope
          let API;
          if (APIPath) {
            API = APIPath;
          }

          if (!isSubstringPresent) {
            // -------- Getting API Data
            // if CustomUrl is provided, use only that
            if (CustomUrl && (!BaseUrl || BaseUrl === "null") && APIMethod === "GET" ) {
              console.log("CustomUrl", CustomUrl);
              // if nothing is provided for auth
              if (!BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  headers: {},
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is provided but not api_key or usr+pwd
              else if (BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${BearerToken}`,
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token and api_key not provided but usr+pwd are provided
              else if (!BearerToken && Username && Password) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    Authorization: "Basic " + btoa(Username + ":" + Password),
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }
            }

            // if base url is the choice and provided
            else if (BaseUrl && API && !CustomUrl && APIMethod === "GET") {
              // concatenating base url and its remaining part
              let url, baseUrlBearerToken;
              let forDownloadUrl;
              if (BaseUrl === "baseUrl1") {
                url = props.options.baseUrl1 + API;
                forDownloadUrl = "{{API_URL1}}" + API;
                baseUrlBearerToken = baseUrl1BearerToken;
              } else if (BaseUrl === "baseUrl2") {
                url = props.options.baseUrl2 + API;
                forDownloadUrl = "{{API_URL2}}" + API;
                baseUrlBearerToken = baseUrl2BearerToken;
              } else if (BaseUrl === "baseUrl3") {
                url = props.options.baseUrl3 + API;
                forDownloadUrl = "{{API_URL3}}" + API;
                baseUrlBearerToken = baseUrl3BearerToken;
              } else if (BaseUrl === "baseUrl4") {
                url = props.options.baseUrl4 + API;
                forDownloadUrl = "{{API_URL4}}" + API;
                baseUrlBearerToken = baseUrl4BearerToken;
              } else if (BaseUrl === "baseUrl5") {
                url = props.options.baseUrl5 + API;
                forDownloadUrl = "{{API_URL5}}" + API;
                baseUrlBearerToken = baseUrl5BearerToken;
              }

              // running in download
              url = forDownloadUrl;

              console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

              // if nothing is provided for auth
              if (!BearerToken && !baseUrlBearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  headers: {},
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is provided but not usr+pwd
              else if (BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${BearerToken}`,
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a baseUrlBearerToken is provided but not Custom Bearer Token and usr+pwd
              else if (
                baseUrlBearerToken &&
                !BearerToken &&
                !Username &&
                !Password
              ) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${baseUrlBearerToken}`,
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config@:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is not provided but usr+pwd are provided
              else if (!BearerToken && Username && Password) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    Authorization: "Basic " + btoa(Username + ":" + Password),
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }
            }

            // -------- Getting API Data (POST method)

            // if CustomUrl is provided, use only that
            else if (
              CustomUrl &&
              (!BaseUrl || BaseUrl === "null") &&
              APIMethod === "POST"
            ) {
              console.log("CustomUrl", CustomUrl);
              // if nothing is provided for auth
              if (!BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {},
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is provided but not api_key or usr+pwd
              else if (BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${BearerToken}`,
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token and api_key not provided but usr+pwd are provided
              else if (!BearerToken && Username && Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    Authorization: "Basic " + btoa(Username + ":" + Password),
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }
            }

            // if base url is the choice and provided
            else if (BaseUrl && API && !CustomUrl && APIMethod === "POST") {
              // concatenating base url and its remaining part
              let url, baseUrlBearerToken;
              let forDownloadUrl;
              if (BaseUrl === "baseUrl1") {
                url = props.options.baseUrl1 + API;
                forDownloadUrl = "{{API_URL1}}" + API;
                baseUrlBearerToken = baseUrl1BearerToken;
              } else if (BaseUrl === "baseUrl2") {
                url = props.options.baseUrl2 + API;
                forDownloadUrl = "{{API_URL2}}" + API;
                baseUrlBearerToken = baseUrl2BearerToken;
              } else if (BaseUrl === "baseUrl3") {
                url = props.options.baseUrl3 + API;
                forDownloadUrl = "{{API_URL3}}" + API;
                baseUrlBearerToken = baseUrl3BearerToken;
              } else if (BaseUrl === "baseUrl4") {
                url = props.options.baseUrl4 + API;
                forDownloadUrl = "{{API_URL4}}" + API;
                baseUrlBearerToken = baseUrl4BearerToken;
              } else if (BaseUrl === "baseUrl5") {
                url = props.options.baseUrl5 + API;
                forDownloadUrl = "{{API_URL5}}" + API;
                baseUrlBearerToken = baseUrl5BearerToken;
              }

              // running in download
              url = forDownloadUrl;

              console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

              // if nothing is provided for auth
              if (!BearerToken && !baseUrlBearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {},
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is provided but not usr+pwd
              else if (BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${BearerToken}`,
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a baseUrlBearerToken is provided but not Custom Bearer Token and usr+pwd
              else if (
                baseUrlBearerToken &&
                !BearerToken &&
                !Username &&
                !Password
              ) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${baseUrlBearerToken}`,
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config@:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is not provided but usr+pwd are provided
              else if (!BearerToken && Username && Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    Authorization: "Basic " + btoa(Username + ":" + Password),
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }
            }

            console.log("apiData:", apiData);

            // -------- Populating API Data, into the dropdown list
            if (apiData !== undefined) {
              console.log("#");
              // if API response is directly an array-of-objects
              if (isArrayofObjects(apiData)) {
                if (ArrOfObjKyename) {
                  console.log("##");
                  dropdown.innerHTML = "";

                  let disabledOptionEle = document.createElement("option");
                  disabledOptionEle.setAttribute("disabled", true);
                  disabledOptionEle.setAttribute("selected", true);
                  disabledOptionEle.innerHTML = `${DisabledOption}`;

                  dropdown.appendChild(disabledOptionEle);

                  apiData.forEach((opt) => {
                    let optionEle;

                    if (ArrOfObjKyename.includes(":")) {
                      let x = ArrOfObjKyename.split(":").map(str => str.trim());
                      let key = x[0];
                      let keyVal = x[1];

                      optionEle = document.createElement("option");
                      optionEle.setAttribute("value", `${opt?.[`${key}`]?.[`${keyVal}`] || ''}`);
                      // optionEle.setAttribute("data_db_id", `${opt[`_id`]}` ? `${opt[`_id`]}` : `${opt?.[`${key}`]?.[`${keyVal}`] || ''}`);
                      optionEle.setAttribute("data_db_id", `${opt?.[`${key}`]?.[`_id`] || ''}`);
                      optionEle.innerHTML = `${opt?.[`${key}`]?.[`${keyVal}`] || ''}`;
                    } else {
                      optionEle = document.createElement("option");
                      optionEle.setAttribute("value", `${opt[`${ArrOfObjKyename}`]}`);
                      optionEle.setAttribute("data_db_id", `${opt[`_id`]}` ? `${opt[`_id`]}` : `${opt[`${ArrOfObjKyename}`]}`);
                      optionEle.innerHTML = `${opt[`${ArrOfObjKyename}`]}`;
                    }

                    dropdown.appendChild(optionEle);
                  });
                }
              }

              // if API response is a plain array of single elements
              else if (Array.isArray(apiData) && !isArrayofObjects(apiData)) {
                console.log("###");
                dropdown.innerHTML = "";

                let disabledOptionEle = document.createElement("option");
                disabledOptionEle.setAttribute("disabled", true);
                disabledOptionEle.setAttribute("selected", true);
                disabledOptionEle.innerHTML = `${DisabledOption}`;

                dropdown.appendChild(disabledOptionEle);

                apiData.forEach((opt) => {
                  let optionEle = document.createElement("option");
                  optionEle.setAttribute("value", `${opt}`);
                  optionEle.innerHTML = `${opt}`;

                  dropdown.appendChild(optionEle);
                });
              }

              // if API response is an object
              else if (!isArrayofObjects(apiData) && typeof apiData === "object" ) {
                // if API response to plot is a nested array-of-objects inside the main response object
                if (NestedKeyname && ArrOfObjKyename) {
                  console.log("####");
                  dropdown.innerHTML = "";

                  let disabledOptionEle = document.createElement("option");
                  disabledOptionEle.setAttribute("disabled", true);
                  disabledOptionEle.setAttribute("selected", true);
                  disabledOptionEle.innerHTML = `${DisabledOption}`;

                  dropdown.appendChild(disabledOptionEle);

                  // find the array-of-objects value
                  let data = findKeyValue(apiData, NestedKeyname);

                  if (isArrayofObjects(data)) {
                    data.forEach((opt) => {
                      let optionEle;

                      if (ArrOfObjKyename.includes(":")) {
                        let x = ArrOfObjKyename.split(":").map(str => str.trim());
                        let key = x[0];
                        let keyVal = x[1];

                        optionEle = document.createElement("option");
                        optionEle.setAttribute("value", `${opt?.[`${key}`]?.[`${keyVal}`] || ''}`);
                        optionEle.innerHTML = `${opt?.[`${key}`]?.[`${keyVal}`] || ''}`;
                      } else {
                        optionEle = document.createElement("option");
                        optionEle.setAttribute("value", `${opt[`${ArrOfObjKyename}`]}` );
                        optionEle.innerHTML = `${opt[`${ArrOfObjKyename}`]}`;
                      }

                      dropdown.appendChild(optionEle);
                    });
                  }
                } else if (NestedKeyname && !ArrOfObjKyename) {
                  dropdown.components("");

                  // find the array value
                  let data = findKeyValue(apiData, NestedKeyname);

                  // if API response to plot is a nested plain array of single elements inside the main response object
                  if (Array.isArray(data) && !isArrayofObjects(data)) {
                    console.log("#####");
                    data.forEach((opt) => {
                      let optionEle = document.createElement("option");
                      optionEle.setAttribute("value", `${opt}`);
                      optionEle.innerHTML = `${opt}`;

                      dropdown.appendChild(optionEle);
                    });
                  }

                  // if API response to plot are all key:value pairs of a plain object inside main object
                  else if (
                    !Array.isArray(data) &&
                    !isArrayofObjects(data) &&
                    typeof data === "object"
                  ) {
                    console.log("######");
                    Object.keys(data).forEach((key) => {
                      if (
                        !Array.isArray(data[`${key}`]) &&
                        typeof data[`${key}`] !== "object"
                      ) {
                        let optionEle = document.createElement("option");
                        optionEle.setAttribute("value", `${data[`${key}`]}`);
                        optionEle.innerHTML = `${key}`;

                        dropdown.appendChild(optionEle);
                      }
                    });
                  }
                }

                // if API response to plot are all key:value pairs of the plain object
                else if (!NestedKeyname && !ArrOfObjKyename) {
                  console.log("#######");
                  dropdown.components("");

                  Object.keys(apiData).forEach((key) => {
                    if (
                      !Array.isArray(apiData[`${key}`]) &&
                      typeof apiData[`${key}`] !== "object"
                    ) {
                      let optionEle = document.createElement("option");
                      optionEle.setAttribute("value", `${apiData[`${key}`]}`);
                      optionEle.innerHTML = `${key}`;

                      dropdown.appendChild(optionEle);
                    }
                  });
                }

                // if there is a 'message' in api response instead of data
                else if (Object.keys(apiData).includes('message')) {
                  console.log("#@@");
                  dropdown.innerHTML = "";

                  let disabledOptionEle = document.createElement("option");
                  disabledOptionEle.setAttribute("disabled", true);
                  disabledOptionEle.setAttribute("selected", true);
                  disabledOptionEle.innerHTML = `${DisabledOption}`;

                  dropdown.appendChild(disabledOptionEle);
                }
              }
            }
          }
        }

        // Call the initLib function
        initLib();

        // to update table props as soon as window is updated and then trigger rendering
        if (!isSubstringPresent) {
          setInterval(function () {
            Object.keys(props).forEach(function (key) {
              if (window[`${key}${idEl}`]) {
                if(props[key] === window[`${key}${idEl}`]){
                  // console.log('');
                }else{
                  props[key] = window[`${key}${idEl}`];
                  initLib();
                }
              }
            });
          }, 500);
        }
      };

      editor.DomComponents.addType("input_select_v2DOT1", {
        model: {
          defaults: {
            tagName: "span",
            attributes: {
              name: "input_select_v2DOT1_container",
              class: "input_select_v2DOT1_container",
            },
            script: inputSelectV2DOT1Script,

            components: [
              {
                tagName: "label",
                type: "default",
                attributes: {
                  for: "",
                },

                components: [
                  {
                    tagName: "p",
                    type: "text",
                    editable: true,
                    content: "Select an option:",
                  },
                ],

                traits: ["for"],
              },
              {
                tagName: "select",
                type: "default",
                // script: dropdownListScript,
                attributes: {
                  // id: "input_select",
                  name: "input_select_v2DOT1",
                  class: "input_select_v2DOT1",
                },

                components: [
                  {
                    tagName: "option",
                    type: "default",
                    attributes: {
                      disabled: true,
                    },

                    components: [
                      {
                        tagName: "p",
                        type: "text",
                        editable: true,

                        components: {
                          type: "textnode",
                          content: "--select--",
                        },
                      },
                    ],
                  },
                  {
                    tagName: "option",
                    type: "default",
                    attributes: {
                      value: "option1",
                    },

                    components: [
                      {
                        tagName: "p",
                        type: "text",
                        editable: true,

                        components: {
                          type: "textnode",
                          content: "Option 1",
                        },
                      },
                    ],
                  },
                  {
                    tagName: "option",
                    type: "default",
                    attributes: {
                      value: "option2",
                    },

                    components: [
                      {
                        tagName: "p",
                        type: "text",
                        editable: true,

                        components: {
                          type: "textnode",
                          content: "Option 2",
                        },
                      },
                    ],
                  },
                  {
                    tagName: "option",
                    type: "default",
                    attributes: {
                      value: "option3",
                    },

                    components: [
                      {
                        tagName: "p",
                        type: "text",
                        editable: true,

                        components: {
                          type: "textnode",
                          content: "Option 3",
                        },
                      },
                    ],
                  },
                ],
              },
            ],

            // traits
            DisabledOption: "--select--",
            SetOptionsManually: "",

            CustomUrl: "",
            BaseUrl: "",
            APIPath: "",
            BearerToken: "",
            Username: "",
            Password: "",
            MoreHeaders: "",
            APIMethod: "GET",
            APIBody: "",
            ArrOfObjKyename: "",
            NestedKeyname: "",

            Autofocus: false,
            Multiple: false,
            Disabled: false,
            Required: false,
            Size: "",

            options: {
              baseUrl1,
              baseUrl2,
              baseUrl3,
              baseUrl4,
              baseUrl5,
            },

            projectId: localStorage.getItem("project_id")
              ? localStorage.getItem("project_id")
              : "",

            traits: [
              // "id",
              // "name",
              // "form",
              {
                type: "text",
                name: "DisabledOption",
                label: "Disabled Option Text",
                placeholder: "--select--",
                changeProp: true,
              },
              {
                type: "text",
                name: "SetOptionsManually",
                label: "Set Options Manually",
                placeholder: "Option 1,Option 2,Option 3",
                changeProp: true,
              },
              {
                type: "text",
                name: "CustomUrl",
                label: "Custom URL",
                changeProp: true,
              },
              {
                type: "select",
                name: "BaseUrl",
                label: "Base URL",
                options: [
                  { id: "baseUrl1", name: "Base URL #1" },
                  { id: "baseUrl2", name: "Base URL #2" },
                  { id: "baseUrl3", name: "Base URL #3" },
                  { id: "baseUrl4", name: "Base URL #4" },
                  { id: "baseUrl5", name: "Base URL #5" },
                  { id: "null", name: "No Base URL" },
                ],
                changeProp: true,
              },
              {
                type: "text",
                name: "APIPath",
                label: "API Path",
                placeholder: "Don't start with '/'",
                changeProp: true,
              },
              {
                type: "text",
                name: "BearerToken",
                label: "Bearer Token",
                placeholder: "No bearer token provided",
                changeProp: true,
              },
              {
                type: "text",
                name: "Username",
                label: "Username",
                changeProp: true,
              },
              {
                type: "text",
                name: "Password",
                label: "Password",
                changeProp: true,
              },
              {
                type: "text",
                name: "MoreHeaders",
                label: "More Headers",
                placeholder: "k1:v1,k2:v2,k3:v3,...",
                changeProp: true,
              },
              {
                type: "select",
                name: "APIMethod",
                label: "API Method",
                options: [
                  { id: "GET", name: "Get" },
                  { id: "POST", name: "Post" },
                ],
                changeProp: true,
              },
              {
                type: "text",
                name: "APIBody",
                label: "API Body",
                placeholder: "Json format",
                changeProp: true,
              },
              {
                type: "text",
                name: "ArrOfObjKyename",
                label: "Keyname From Array Of Object",
                placeholder: "[ {k1:v1}, {k1:v2} ]",
                changeProp: true,
              },
              {
                type: "text",
                name: "NestedKeyname",
                label: "Keyname For Nested Structure",
                placeholder: "{ k1: [{...}, {...}] }",
                changeProp: true,
              },
              {
                type: "checkbox",
                name: "Autofocus",
                label: "Autofocus",
                changeProp: true,
              },
              {
                type: "checkbox",
                name: "Multiple",
                label: "Multiple",
                changeProp: true,
              },
              {
                type: "checkbox",
                name: "Disabled",
                label: "Disabled",
                changeProp: true,
              },
              {
                type: "checkbox",
                name: "Required",
                label: "Required",
                changeProp: true,
              },
              {
                type: "number",
                name: "Size",
                label: "Size",
                min: "1",
                changeProp: true,
              },
            ],

            changeProp: true,

            "script-props": [
              "DisabledOption",
              "SetOptionsManually",
              "CustomUrl",
              "BaseUrl",
              "APIPath",
              "BearerToken",
              "Username",
              "Password",
              "MoreHeaders",
              "APIMethod",
              "APIBody",
              "ArrOfObjKyename",
              "NestedKeyname",
              "Multiple",
              "Autofocus",
              "Disabled",
              "Required",
              "Size",
              "options",
              "projectId",
            ],
          },

          init() {
            this.on("change:DisabledOption", this.handlePropChange);
            this.on("change:SetOptionsManually", this.handlePropChange);
            this.on("change:CustomUrl", this.handlePropChange);
            this.on("change:BaseUrl", this.handlePropChange);
            this.on("change:APIPath", this.handlePropChange);
            this.on("change:BearerToken", this.handlePropChange);
            this.on("change:Username", this.handlePropChange);
            this.on("change:Password", this.handlePropChange);
            this.on("change:MoreHeaders", this.handlePropChange);
            this.on("change:APIMethod", this.handlePropChange);
            this.on("change:APIBody", this.handlePropChange);
            this.on("change:ArrOfObjKyename", this.handlePropChange);
            this.on("change:NestedKeyname", this.handlePropChange);
            this.on("change:Multiple", this.handlePropChange);
            this.on("change:Autofocus", this.handlePropChange);
            this.on("change:Disabled", this.handlePropChange);
            this.on("change:Required", this.handlePropChange);
            this.on("change:Size", this.handlePropChange);
          },

          async handlePropChange() {
            const {
              DisabledOption,
              SetOptionsManually,
              CustomUrl,
              BaseUrl,
              APIPath,
              BearerToken,
              Username,
              Password,
              MoreHeaders,
              APIMethod,
              APIBody,
              ArrOfObjKyename,
              NestedKeyname,
              Multiple,
              Autofocus,
              Disabled,
              Required,
              Size,
            } = this.props();
            // console.log(
            //   "this, DisabledOption, SetOptionsManually, CustomUrl, BaseUrl, APIPath, BearerToken, Username, Password, MoreHeaders, APIMethod, APIBody, ArrOfObjKyename, NestedKeyname, Multiple, Autofocus, Disabled, Required, Size",
            //   this,
            //   DisabledOption,
            //   SetOptionsManually,
            //   APIPath,
            //   BearerToken,
            //   Username,
            //   Password,
            //   MoreHeaders,
            //   APIMethod,
            //   APIBody,
            //   ArrOfObjKyename,
            //   NestedKeyname,
            //   Multiple,
            //   Autofocus,
            //   Disabled,
            //   Required,
            //   Size
            // );
            // console.log("baseUrlData", baseUrlData);

            // to rename a variable because API var is getting used in many places in this block scope
            let API;
            if (APIPath) {
              API = APIPath;
            }

            // helper function to check if a variable is an array-of-objects or not
            function isArrayofObjects(variable) {
              // Check if the variable is an array
              if (!Array.isArray(variable)) {
                return false;
              }

              // Check if all elements in the array are objects
              for (const element of variable) {
                if (
                  typeof element !== "object" ||
                  Array.isArray(element) ||
                  element === null
                ) {
                  return false;
                }
              }
              return true;
            }

            // helper function to get value of a key in nested object
            function findKeyValue(obj, key) {
              if (obj.hasOwnProperty(key)) {
                // If the current object has the key, return its value
                return obj[key];
              }

              for (var prop in obj) {
                if (obj.hasOwnProperty(prop) && typeof obj[prop] === "object") {
                  // If the current property is an object, recursively search within it
                  var result = findKeyValue(obj[prop], key);
                  if (result !== undefined) {
                    return result;
                  }
                }
              }
              // Key not found in the object
              return undefined;
            }

            const container = this;
            let apiData;
            console.log("container", container);
            const dropdown = container.find("select.input_select_v2DOT1")[0];
            console.log("dropdown", dropdown);

            if (SetOptionsManually) {
              let options = SetOptionsManually.split(",");

              dropdown.components("");

              // initializing with a disabled first option
              let disabledOptionEle = {
                tagName: "option",
                attributes: {
                  disabled: true,
                  selected: true,
                },

                components: [
                  {
                    tagName: "p",
                    type: "text",
                    editable: true,

                    components: {
                      type: "textnode",
                      content: `${DisabledOption}`,
                    },
                  },
                ],
              };

              dropdown.append(disabledOptionEle);

              options.forEach((opt) => {
                let optionEle = {
                  tagName: "option",
                  attributes: {
                    value: `${opt}`,
                  },

                  components: [
                    {
                      tagName: "p",
                      type: "text",
                      editable: true,

                      components: {
                        type: "textnode",
                        content: `${opt}`,
                      },
                    },
                  ],
                };

                dropdown.append(optionEle);
              });
            }
            if (Autofocus) {
              if (dropdown) {
                dropdown.addAttributes({ autofocus: true });
              }
            } else {
              if (dropdown) {
                dropdown.removeAttributes("autofocus");
              }
            }
            if (Multiple) {
              if (dropdown) {
                dropdown.addAttributes({ multiple: true });
              }
            } else {
              if (dropdown) {
                dropdown.removeAttributes("multiple");
              }
            }
            if (Disabled) {
              if (dropdown) {
                dropdown.addAttributes({ disabled: true });
              }
            } else {
              if (dropdown) {
                dropdown.removeAttributes("disabled");
              }
            }
            if (Required) {
              if (dropdown) {
                dropdown.addAttributes({ required: true });
              }
            } else {
              if (dropdown) {
                dropdown.removeAttributes("required");
              }
            }
            if (Size) {
              if (dropdown) {
                dropdown.addAttributes({ size: `${Size}` });
              }
            } else {
              if (dropdown) {
                dropdown.removeAttributes("size");
              }
            }

            // -------- Getting API Data (GET method)

            // if CustomUrl is provided, use only that
            if (CustomUrl && (!BaseUrl || BaseUrl === "null") && APIMethod === "GET" ) {
              console.log("CustomUrl", CustomUrl);
              // if nothing is provided for auth
              if (!BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  headers: {},
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is provided but not api_key or usr+pwd
              else if (BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${BearerToken}`,
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token and api_key not provided but usr+pwd are provided
              else if (!BearerToken && Username && Password) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    Authorization: "Basic " + btoa(Username + ":" + Password),
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }
            }

            // if base url is the choice and provided
            else if (BaseUrl && API && !CustomUrl && APIMethod === "GET") {
              // concatenating base url and its remaining part
              let url, baseUrlBearerToken;
              if (BaseUrl === "baseUrl1") {
                url = baseUrl1 + API;
                baseUrlBearerToken = baseUrl1BearerToken;
              } else if (BaseUrl === "baseUrl2") {
                url = baseUrl2 + API;
                baseUrlBearerToken = baseUrl2BearerToken;
              } else if (BaseUrl === "baseUrl3") {
                url = baseUrl3 + API;
                baseUrlBearerToken = baseUrl3BearerToken;
              } else if (BaseUrl === "baseUrl4") {
                url = baseUrl4 + API;
                baseUrlBearerToken = baseUrl4BearerToken;
              } else if (BaseUrl === "baseUrl5") {
                url = baseUrl5 + API;
                baseUrlBearerToken = baseUrl5BearerToken;
              }
              console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

              // if nothing is provided for auth
              if (
                !BearerToken &&
                !baseUrlBearerToken &&
                !Username &&
                !Password
              ) {
                // add extra headers, if needed
                let config = {
                  headers: {},
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is provided but not usr+pwd
              else if (BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${BearerToken}`,
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a baseUrlBearerToken is provided but not Custom Bearer Token and usr+pwd
              else if (
                baseUrlBearerToken &&
                !BearerToken &&
                !Username &&
                !Password
              ) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    Authorization: `Bearer ${baseUrlBearerToken}`,
                    "Content-Type": "application/json",
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config@:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is not provided but usr+pwd are provided
              else if (!BearerToken && Username && Password) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    Authorization: "Basic " + btoa(Username + ":" + Password),
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }
            }

            // -------- Getting API Data (POST method)

            // if CustomUrl is provided, use only that
            else if (CustomUrl && (!BaseUrl || BaseUrl === "null") && APIMethod === "POST" ) {
              console.log("CustomUrl", CustomUrl);
              // if nothing is provided for auth
              if (!BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {},
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is provided but not api_key or usr+pwd
              else if (BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${BearerToken}`,
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token and api_key not provided but usr+pwd are provided
              else if (!BearerToken && Username && Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    Authorization: "Basic " + btoa(Username + ":" + Password),
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }
            }

            // if base url is the choice and provided
            else if (BaseUrl && API && !CustomUrl && APIMethod === "POST") {
              // concatenating base url and its remaining part
              let url, baseUrlBearerToken;
              if (BaseUrl === "baseUrl1") {
                url = baseUrl1 + API;
                baseUrlBearerToken = baseUrl1BearerToken;
              } else if (BaseUrl === "baseUrl2") {
                url = baseUrl2 + API;
                baseUrlBearerToken = baseUrl2BearerToken;
              } else if (BaseUrl === "baseUrl3") {
                url = baseUrl3 + API;
                baseUrlBearerToken = baseUrl3BearerToken;
              } else if (BaseUrl === "baseUrl4") {
                url = baseUrl4 + API;
                baseUrlBearerToken = baseUrl4BearerToken;
              } else if (BaseUrl === "baseUrl5") {
                url = baseUrl5 + API;
                baseUrlBearerToken = baseUrl5BearerToken;
              }
              console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

              // if nothing is provided for auth
              if (
                !BearerToken &&
                !baseUrlBearerToken &&
                !Username &&
                !Password
              ) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {},
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is provided but not usr+pwd
              else if (BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${BearerToken}`,
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a baseUrlBearerToken is provided but not Custom Bearer Token and usr+pwd
              else if (
                baseUrlBearerToken &&
                !BearerToken &&
                !Username &&
                !Password
              ) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${baseUrlBearerToken}`,
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config@:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is not provided but usr+pwd are provided
              else if (!BearerToken && Username && Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    Authorization: "Basic " + btoa(Username + ":" + Password),
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }
            }

            console.log("apiData:", apiData);

            // -------- Populating API Data, into the dropdown list
            if (apiData !== undefined) {
              console.log("#");
              // if API response is directly an array-of-objects
              if (isArrayofObjects(apiData)) {
                if (ArrOfObjKyename) {
                  console.log("##");
                  dropdown.components("");

                  // initializing with a disabled first option
                  let disabledOptionEle = {
                    tagName: "option",
                    attributes: {
                      disabled: true,
                      selected: true,
                    },

                    components: [
                      {
                        tagName: "p",
                        type: "text",
                        editable: true,

                        components: {
                          type: "textnode",
                          content: `${DisabledOption}`,
                        },
                      },
                    ],
                  };

                  dropdown.append(disabledOptionEle);

                  apiData.forEach((opt) => {
                    let optionEle;

                    if (ArrOfObjKyename.includes(":")) {
                      let x = ArrOfObjKyename.split(":").map(str => str.trim());
                      let key = x[0];
                      let keyVal = x[1];
                      // console.log("opt, key, keyVal", opt, key, keyVal);

                      optionEle = {
                        tagName: "option",
                        type: "default",
                        attributes: {
                          value: `${opt?.[`${key}`]?.[`${keyVal}`] || ''}`,
                          // data_db_id: `${opt[`_id`]}` ? `${opt[`_id`]}` : `${opt?.[`${key}`]?.[`${keyVal}`] || ''}`,
                          data_db_id: `${opt?.[`${key}`]?.[`_id`] || ''}`,
                        },

                        components: [
                          {
                            tagName: "p",
                            type: "text",
                            editable: true,

                            components: {
                              type: "textnode",
                              content: `${opt?.[`${key}`]?.[`${keyVal}`] || ''}`,
                            },
                          },
                        ],
                      };
                    } else {
                      optionEle = {
                        tagName: "option",
                        type: "default",
                        attributes: {
                          value: `${opt[`${ArrOfObjKyename}`]}`,
                          data_db_id: `${opt[`_id`]}`
                            ? `${opt[`_id`]}`
                            : `${opt[`${ArrOfObjKyename}`]}`,
                        },

                        components: [
                          {
                            tagName: "p",
                            type: "text",
                            editable: true,

                            components: {
                              type: "textnode",
                              content: `${opt[`${ArrOfObjKyename}`]}`,
                            },
                          },
                        ],
                      };
                    }                    

                    dropdown.append(optionEle);
                  });
                }
              }

              // if API response is a plain array of single elements
              else if (Array.isArray(apiData) && !isArrayofObjects(apiData)) {
                console.log("###");
                dropdown.components("");

                // initializing with a disabled first option
                let disabledOptionEle = {
                  tagName: "option",
                  attributes: {
                    disabled: true,
                    selected: true,
                  },

                  components: [
                    {
                      tagName: "p",
                      type: "text",
                      editable: true,

                      components: {
                        type: "textnode",
                        content: `${DisabledOption}`,
                      },
                    },
                  ],
                };

                dropdown.append(disabledOptionEle);

                apiData.forEach((opt) => {
                  let optionEle = {
                    tagName: "option",
                    type: "default",
                    attributes: {
                      value: `${opt}`,
                    },

                    components: [
                      {
                        tagName: "p",
                        type: "text",
                        editable: true,

                        components: {
                          type: "textnode",
                          content: `${opt}`,
                        },
                      },
                    ],
                  };

                  dropdown.append(optionEle);
                });
              }

              // if API response is an object
              else if (!isArrayofObjects(apiData) && typeof apiData === "object" ) {
                console.log('#@');
                // if API response to plot is a nested array-of-objects inside the main response object
                if (NestedKeyname && ArrOfObjKyename) {
                  console.log("####");
                  dropdown.components("");

                  // initializing with a disabled first option
                  let disabledOptionEle = {
                    tagName: "option",
                    attributes: {
                      disabled: true,
                      selected: true,
                    },

                    components: [
                      {
                        tagName: "p",
                        type: "text",
                        editable: true,

                        components: {
                          type: "textnode",
                          content: `${DisabledOption}`,
                        },
                      },
                    ],
                  };

                  dropdown.append(disabledOptionEle);

                  // find the array-of-objects value
                  let data = findKeyValue(apiData, NestedKeyname);

                  if (isArrayofObjects(data)) {
                    data.forEach((opt) => {
                      let optionEle;

                      if (ArrOfObjKyename.includes(":")) {
                        let x = ArrOfObjKyename.split(":").map(str => str.trim());
                        let key = x[0];
                        let keyVal = x[1];
                        // console.log("opt, key, keyVal", opt, key, keyVal);

                        optionEle = {
                          tagName: "option",
                          type: "default",
                          attributes: {
                            value: `${opt?.[`${key}`]?.[`${keyVal}`] || ''}`,
                          },

                          components: [
                            {
                              tagName: "p",
                              type: "text",
                              editable: true,

                              components: {
                                type: "textnode",
                                content: `${opt?.[`${key}`]?.[`${keyVal}`] || ''}`,
                              },
                            },
                          ],
                        };
                      } else {
                        optionEle = {
                          tagName: "option",
                          type: "default",
                          attributes: {
                            value: `${opt[`${ArrOfObjKyename}`]}`,
                          },

                          components: [
                            {
                              tagName: "p",
                              type: "text",
                              editable: true,

                              components: {
                                type: "textnode",
                                content: `${opt[`${ArrOfObjKyename}`]}`,
                              },
                            },
                          ],
                        };
                      }

                      dropdown.append(optionEle);
                    });
                  }
                } else if (NestedKeyname && !ArrOfObjKyename) {
                    console.log("####@");
                  dropdown.components("");

                  // find the array value
                  let data = findKeyValue(apiData, NestedKeyname);

                  // if API response to plot is a nested plain array of single elements inside the main response object
                  if (Array.isArray(data) && !isArrayofObjects(data)) {
                    console.log("#####");
                    data.forEach((opt) => {
                      let optionEle = {
                        tagName: "option",
                        type: "default",
                        attributes: {
                          value: `${opt}`,
                        },

                        components: [
                          {
                            tagName: "p",
                            type: "text",
                            editable: true,

                            components: {
                              type: "textnode",
                              content: `${opt}`,
                            },
                          },
                        ],
                      };

                      dropdown.append(optionEle);
                    });
                  }

                  // if API response to plot are all key:value pairs of a plain object inside main object
                  else if (
                    !Array.isArray(data) &&
                    !isArrayofObjects(data) &&
                    typeof data === "object"
                  ) {
                    console.log("######");
                    Object.keys(data).forEach((key) => {
                      if (
                        !Array.isArray(data[`${key}`]) &&
                        typeof data[`${key}`] !== "object"
                      ) {
                        let optionEle = {
                          tagName: "option",
                          type: "default",
                          attributes: {
                            value: `${data[`${key}`]}`,
                          },

                          components: [
                            {
                              tagName: "p",
                              type: "text",
                              editable: true,

                              components: {
                                type: "textnode",
                                content: `${key}`,
                              },
                            },
                          ],
                        };

                        dropdown.append(optionEle);
                      }
                    });
                  }
                }

                // if API response to plot are all key:value pairs of the plain object
                else if (!NestedKeyname && !ArrOfObjKyename) {
                  console.log("#######@");
                  dropdown.components("");

                  Object.keys(apiData).forEach((key) => {
                    if (
                      !Array.isArray(apiData[`${key}`]) &&
                      typeof apiData[`${key}`] !== "object"
                    ) {
                      let optionEle = {
                        tagName: "option",
                        type: "default",
                        attributes: {
                          value: `${apiData[`${key}`]}`,
                        },

                        components: [
                          {
                            tagName: "p",
                            type: "text",
                            editable: true,

                            components: {
                              type: "textnode",
                              content: `${key}`,
                            },
                          },
                        ],
                      };

                      dropdown.append(optionEle);
                    }
                  });
                }

                // if there is a 'message' in api response instead of data
                else if (Object.keys(apiData).includes('message')) {
                    console.log("#@@");

                     dropdown.components("");

                    // initializing with a disabled first option
                    let disabledOptionEle = {
                        tagName: "option",
                        attributes: {
                          disabled: true,
                          selected: true,
                        },

                        components: [
                          {
                            tagName: "p",
                            type: "text",
                            editable: true,

                            components: {
                              type: "textnode",
                              content: `${DisabledOption}`,
                              // content: `${apiData['message']}`,
                            },
                          },
                        ],
                    };

                    dropdown.append(disabledOptionEle);
                }
              }
            }
          },
        },

        view: {
          onRender({ el, model }) {
            console.log("el", el, el.tagName);
            if (el.getAttribute("data-gjs-type") === "input_select_v2DOT1" && (el.tagName === "DIV" || el.tagName === "SPAN" || el.tagName === "FORM") ) {
              console.log("rendering", el);
              model.handlePropChange();
            }
          },
        },
      });

      editor.DomComponents.addType("dateTimeInputNew", {
        model: {
          defaults: {
            tagName: "div",
            // Add some style, just to make the component visible
            components: [
              {
                tagName: "input",
                type: "default",
                attributes: {
                  type: "date",
                  id: `dateTime${uniqid()}`,
                },
                styles: {
                  width: "100%",
                  padding: "0.5rem",
                },
              },
            ],
          },
        },
      });

      editor.DomComponents.addType("custom-date-regular-form", {
        model: {
          defaults: {
            tagName: "div",
            type: "custom-date-regular-form",
            attributes: {
              class: "custom-date-regular-form",
            },
            // Add some style, just to make the component visible

            addPhantomDisplay: true,

            traits: [
              {
                type: "checkbox",
                name: "addPhantomDisplay",
                label: "Add Phantom Display",
                changeProp: true,
              },
            ],

            changeProp: true,
            "script-props": ["addPhantomDisplay"],

            components: [
              {
                tagName: "input",
                type: "default",
                attributes: {
                  type: "date",
                  id: `customDateTime${uniqid()}`,
                },

                "style-default": {
                  // display: "none",
                  width: "100%",
                  padding: "0.5rem",
                },

                style: {
                  // display: "none",
                  width: "100%",
                  padding: "0.5rem",
                },
              }, {
                tagName: "div", // This will display the formatted date
                type: "custom-date-regular-form-phantom-display",
                attributes: {
                  class: "custom-date-regular-form-phantom-display",
                  // id: `formattedDate${uniqid()}`,
                },

                "style-default": {
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "space-between",
                  width: "50%",
                  padding: "0.5rem",
                  cursor: "pointer",
                },

                style: {
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "space-between",
                  width: "50%",
                  padding: "0.5rem",
                  cursor: "pointer",
                },

                content: `
                  <p style="margin: auto 0;">dd-mm-yyyy</p>

                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-calendar" viewBox="0 0 16 16">
                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
                  </svg>
                `,
              }
            ],
          },

          init() {
            this.on("change:addPhantomDisplay", this.addPhantomDisplayToWidget);
          },

          addPhantomDisplayToWidget() {
            let {addPhantomDisplay} = this.props();
            let dateContainer = this;

            let inputDateEle;
            if (dateContainer)
              inputDateEle = dateContainer.find("input[type='date']")[0];

            let phantomDisplayEle;
            if (dateContainer)
              phantomDisplayEle = dateContainer.find(".custom-date-regular-form-phantom-display")[0];

            if (addPhantomDisplay) {
              if (inputDateEle && phantomDisplayEle) {
                inputDateEle.setStyle({
                  display: "none",
                  width: "100%",
                  padding: "0.5rem",
                });

                phantomDisplayEle.setStyle({
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "space-between",
                  width: "50%",
                  padding: "0.5rem",
                  cursor: "pointer",
                });
              }
            } else {
              if (inputDateEle && phantomDisplayEle) {
                inputDateEle.setStyle({
                  display: "block",
                  width: "100%",
                  padding: "0.5rem",
                });

                phantomDisplayEle.setStyle({
                  display: "none",
                  "align-items": "center",
                  "justify-content": "space-between",
                  width: "50%",
                  padding: "0.5rem",
                  cursor: "pointer",
                });
              }
            }
          },
        },

        view: {
          events: {
            'click .custom-date-regular-form-phantom-display': 'openCalendarWidget',
          },

          openCalendarWidget() {
            let dateContainer = this.model;

            let inputDateEle;
            if (dateContainer) {
              inputDateEle = dateContainer.find("input[type='date']")[0];
              inputDateEle.getEl().focus();
            }
          },
        },
      });

      editor.DomComponents.addType("rest-api-display", {
        model: {
          defaults: {
            script: rest_api_displayScript,

            tagName: "rest-api-display",

            REST_API_URL: "",

            Number_of_items: 0,

            Name: "",

            traits: [
              {
                type: "text",

                name: "REST_API_URL",

                changeProp: true,
              },

              {
                type: "number",

                name: "Number_of_items",

                changeProp: true,
              },

              {
                type: "text",

                name: "Name",

                changeProp: true,
              },
            ],

            "script-props": ["REST_API_URL", "Number_of_items", "Name"],

            // tagName: 'div',

            attributes: { title: "Hello" },

            // Can be a string

            components: `

                      <p>Paragraph test</p>

                  `,
          },
        },
      });

      editor.DomComponents.addType("rest-api-label", {
        isComponent: (el) =>
          el.tagName === "SECTION" && el.classList.contains("row"),

        extend: "default",

        // extendFn: ['init', 'view'], // array of model functions to extend from `parent-type`

        model: {
          defaults: Object.assign(
            {},
            editor.DomComponents.getType("default").model.prototype.defaults,
            {
              customProp: "custom-value",

              tagName: "section",

              draggable: ".row",

              droppable: true,

              // Add your own custom properties here

              LabelName: "",

              REST_API_URL: "",

              script: rest_api_displayScript,

              Property_Name: "",

              traits: [
                {
                  type: "text",

                  name: "LabelName",

                  changeProp: true,
                },

                {
                  type: "text",

                  name: "Property_Name",

                  changeProp: true,
                },

                {
                  type: "text",

                  name: "REST_API_URL",

                  changeProp: true,
                },
              ],

              "script-props": ["LabelName", "Property_Name", "REST_API_URL"],

              components: `<div class="row"><div class="col"></div></div>`,
            }
          ),
        },

        view: editor.DomComponents.getType("default").view.extend({
          init() {
            // Set the initial style of the component view

            console.log("HEREHRER");

            this.listenTo(this.model, "change:style", this.updateStyle);
          },

          updateStyle() {
            console.log("UPDSTTTTIIIIII....");

            // Update the style of the component view

            this.el.setAttribute("style", this.model.get("style"));
          },
        }),

        // init() {

        // // do something

        // },

        // },

        // model: {

        //     defaults: {

        //         tagName: 'rest-api-label',

        //         Property_Name: '',

        //         traits: [

        //             {

        //                 type: "text",

        //                 name: "Property_Name",

        //                 changeProp: true

        //             }

        //         ],

        //         'script-props': ["Property_Name"],

        //         components: `<div class="rest-api-label" style={{max-width: "100%", min-height: "10%", min-width: "10%"}}> </div>`

        //     }

        // }
      });

      editor.DomComponents.addType("test-multiple", {
        model: {
          defaults: {
            items: ["Item 1", "Item 2", "Item 3"],

            traits: [
              {
                type: "checkbox",

                name: "items",

                changeProp: true,

                value: "Item1",
              },

              {
                type: "checkbox",

                name: "items",

                changeProp: true,

                value: "Item2",
              },

              {
                type: "checkbox",

                name: "items",

                changeProp: true,

                value: "Item3",
              },
            ],
          },
        },

        view: {
          render: function () {
            console.log("EHRJKWEHKJRHE");

            var items = this.model.get("items");

            console.log("ITEMS ---- ", items);

            var html = "<ul>";

            for (var i = 0; i < items.length; i++) {
              html += "<li>" + items[i] + "</li>";
            }

            html += "</ul>";

            this.$el.html(html);

            console.log("HTML _______ ", html);

            return this;
          },
        },
      });

      editor.StyleManager.addType("test-multiple-2", {
        name: "Test Multiple 2",

        properties: [
          {
            name: "Count",

            property: "count",

            type: "integer",
          },
          {
            name: "Text",

            property: "text",
          },
        ],
      });

      // editor component

      editor.DomComponents.addType("helper-editor", {
        model: {
          defaults: {
            tagName: "div",

            traits: [
              // Add traits for configuration if needed
            ],
          },
        },

        view: {
          init() {
            const model = this.model;

            const props = model.getAttributes();

            ReactDOM.render(<PageInModal {...props} />, this.el);
          },

          update() {
            const model = this.model;

            const props = model.getAttributes();

            ReactDOM.render(<PageInModal {...props} />, this.el);
          },

          onRemove() {
            ReactDOM.unmountComponentAtNode(this.el);
          },
        },
      });

      // Text box with auto search and api
      editor.DomComponents.addType("text-box-auto-search", {
        model: {
          defaults: {
            tagName: "div",

            attributes: {
              id: "text-box-auto-search",
            },

            styles: `
              
                                      #text-box-auto-search-container {
              
                                          width: 100%;
              
                                          padding: 0.5em;
              
                                      }
              
                                  `,

            components: [
              {
                tagName: "input",

                attributes: {
                  type: "text",

                  placeholder: "Search",

                  name: "text-box-auto-search-input",

                  id: "text-box-auto-search-input",

                  class: "text-box-auto-search-input",
                },

                styles: `
              
                                              .text-box-auto-search-input {
              
                                                  width: 100%;
              
                                              }
              
                                          `,

                // traits

                API: "",

                Username: "",

                Password: "",

                API_Key: "",

                Subject_Key: "",

                Nested_Array_Of_Objects: "",

                Nested_Subject_Key: "",

                traits: [
                  {
                    type: "text",

                    name: "API",

                    label: "API",

                    changeProp: true,
                  },
                  {
                    type: "text",

                    name: "Username",

                    label: "Username",

                    changeProp: true,
                  },
                  {
                    type: "text",

                    name: "Password",

                    label: "Password",

                    changeProp: true,
                  },
                  {
                    type: "text",

                    name: "API_Key",

                    label: "API Key",

                    changeProp: true,
                  },
                  {
                    type: "text",

                    name: "Subject_Key", // the seach/filtering will be performed on the value (as array of element) of this key

                    label: "Subject Key",

                    changeProp: true,
                  },
                  {
                    type: "select",

                    name: "Nested_Array_Of_Objects",

                    label: "Nested Array Of Objects",

                    options: [
                      { id: "true", name: "True" },

                      { id: "false", name: "False" },
                    ],

                    changeProp: true,
                  },
                  {
                    type: "text",

                    name: "Nested_Subject_Key",

                    label: "Nested Subject Key",

                    changeProp: true,
                  },
                ],

                "script-props": [
                  "API",
                  "Username",
                  "Password",
                  "API_Key",
                  "Subject_Key",
                  "Nested_Array_Of_Objects",
                  "Nested_Subject_Key",
                ],
              },
            ],
          },
        },

        view: {
          async onRender({ el, model }) {
            let api = model.get("API");

            let username = model.get("Username");

            let password = model.get("Password");

            let apiKey = model.get("API_Key");

            // the seach/filtering will be performed on the value (as array of element) of this key

            let subjectKey = model.get("Subject_Key");

            let nestedSubjectKey = model.get("Nested_Subject_Key");

            let nestedArrayOfObjects = model.get("Nested_Array_Of_Objects");

            console.log(
              "log 1",
              "\nel:",
              el,
              "\napi:",
              api,
              "\nusername:",
              username,
              "\npassword:",
              password,
              "\napiKey:",
              apiKey,
              "\nsubjectKey:",
              subjectKey,
              "\nnestedSubjectKey:",
              nestedSubjectKey,
              "\nnestedArrayOfObjects:",
              nestedArrayOfObjects
            );

            // because onRender will run as many times as there are components in this definition. It will run for every render.

            if (el.id === "text-box-auto-search-input") {
              console.log("inside text-box-auto-search-input");

              // getting the parent component to attact the suggestion box to it

              let parentComponent = this.model.parent().getEl();

              console.log("parentComponent:", parentComponent);

              let apiData;

              // making a get call

              // test - https://datausa.io/api/data?drilldowns=Nation&measures=Population (nested key: data, search: United)

              // test - https://swapi.dev/api/planets/ (nested key: results)

              // test - https://swapi.dev/api/starships/ (nested key: results)

              // when api is provided but nothing else is provided

              if (
                (api !== undefined || api !== "") &&
                (username === undefined || username === "") &&
                (password === undefined || password === "") &&
                (apiKey === undefined || apiKey === "")
              ) {
                let response = await fetch(`${api}`);

                let responseData = await response.json();

                apiData = responseData;

                console.log("responseData:", responseData);
              }

              // when api, username and password is provided but not api key
              else if (
                (api !== undefined || api !== "") &&
                (username !== undefined || username !== "") &&
                (password !== undefined || password !== "") &&
                (apiKey === undefined || apiKey === "")
              ) {
                let token = authenticateUser(username, password);

                console.log("token:", token);

                let config = {
                  Authorization: `${token}`,
                };

                let response = await fetch(`${api}`, {
                  method: "GET",

                  headers: config,
                });

                let responseData = await response.json();

                apiData = responseData;

                console.log("responseData:", responseData);
              }

              // when api and api key is provided but not username and password
              else if (
                (api !== undefined || api !== "") &&
                (username === undefined || username === "") &&
                (password === undefined || password === "") &&
                (apiKey !== undefined || apiKey !== "")
              ) {
                console.log("apiKey:", apiKey);

                let config = {
                  "X-API-KEY": `${apiKey}`,
                };

                let response = await fetch(`${api}`, {
                  method: "GET",

                  headers: config,
                });

                let responseData = await response.json();

                apiData = responseData;

                console.log("responseData:", responseData);
              }

              // when everything is provided
              else if (
                (api !== undefined || api !== "") &&
                (username !== undefined || username !== "") &&
                (password !== undefined || password !== "") &&
                (apiKey !== undefined || apiKey !== "")
              ) {
                let token = authenticateUser(username, password);

                console.log("token:", token, "\napiKey:", apiKey);

                let config = {
                  "X-API-KEY": `${apiKey}`,

                  Authorization: `${token}`,
                };

                let response = await fetch(`${api}`, {
                  method: "GET",

                  headers: config,
                });

                let responseData = await response.json();

                apiData = responseData;

                console.log("responseData:", responseData);
              }

              console.log("apiData:", apiData);

              // creating suggestion box

              let suggestionBox = document.createElement("ul");

              suggestionBox.style.display = "flex";

              suggestionBox.style.flexDirection = "column";

              suggestionBox.style.alignItems = "flex-start";

              suggestionBox.style.justifyContent = "center";

              suggestionBox.style.rowGap = "1em";

              suggestionBox.style.backgroundColor = "white";

              suggestionBox.style.width = "max-content";

              suggestionBox.style.padding = "1em";

              suggestionBox.style.borderRadius = "10px";

              // making a search filter

              el.addEventListener("change", function (event) {
                console.log(
                  "attaching event listener to text-box-auto-search-input"
                );

                let matchValue = this.value;

                console.log("searchBar value:", matchValue);

                // searching for keys

                // let searchedKeys = Object.keys(apiData).filter((key) => key.includes(matchValue));

                // console.log('searchedKeys:', searchedKeys);

                let searchResult;

                // proceed the filtering/searching only if the apiData/response and the subject key is provided

                if (
                  (apiData !== undefined &&
                    (subjectKey !== undefined || subjectKey !== "") &&
                    nestedArrayOfObjects === undefined) ||
                  nestedArrayOfObjects === false
                ) {
                  // first check if the key's value is an array or not at level 1; it should be an array to proceed

                  if (Array.isArray(apiData[subjectKey])) {
                    console.log("it is an array");

                    searchResult =
                      Number(matchValue) === NaN
                        ? apiData[subjectKey].filter((value) =>
                            value.includes(matchValue)
                          )
                        : apiData[subjectKey].filter(
                            (value) => value === matchValue
                          );

                    console.log("searchResult:", searchResult);
                  } else {
                    toast.error(
                      `The value of the key '${subjectKey}' is not an array. Please try another key.`,
                      successTheme
                    );
                  }
                }

                let nestedSearchResult;

                // proceed the filtering/searching only if the apiData/response and the subject key is provided and the response contains a key that is an array of objects

                if (apiData !== undefined && nestedArrayOfObjects) {
                  // check if the parent key and the nested key is provided or not

                  if (nestedSubjectKey !== undefined) {
                    console.log("it is in a nested array of objects");

                    // loop over the nested objects in the array, then, first get the values of each object 1 by 1 and find the match value, if found, push it to the search result

                    apiData[nestedSubjectKey].forEach((element) => {
                      let valuesArray = Object.values(element);

                      console.log("valuesArray:", valuesArray);

                      let matchedResults = valuesArray.filter((val) => {
                        if (typeof val === "string")
                          if (val.includes(matchValue)) return true;
                          else if (typeof val === "number")
                            if (val === matchValue) return true;
                            else return false;
                      });

                      console.log("matchedResults:", matchedResults);

                      nestedSearchResult = matchedResults;
                    });
                  }
                }

                console.log("searchResult:", searchResult);

                console.log("nestedSearchResult:", nestedSearchResult);

                // make that suggestion box only if the search results are present

                if (searchResult !== undefined) {
                  // temporary demo

                  // for (let item = 0; item <= 3; item++) {

                  //     let listItem = document.createElement('li');

                  //     listItem.style.cursor = 'pointer';

                  //     listItem.innerHTML = `${item} abcdef ghi`;

                  //     listItem.addEventListener('click', function() {

                  //         // replace the value with the clicked item

                  //         el.value = item;

                  //     });

                  //     suggestionBox.appendChild(listItem);

                  // }

                  searchResult.forEach((ele) => {
                    let listItem = document.createElement("li");

                    listItem.style.cursor = "pointer";

                    listItem.innerHTML = `${ele}`;

                    listItem.addEventListener("click", function () {
                      // replace the value with the clicked ele

                      el.value = ele;

                      suggestionBox.remove();
                    });

                    suggestionBox.appendChild(listItem);
                  });
                }

                // make a suggestion box only if the nested search results are present

                if (nestedSearchResult !== undefined) {
                  // temporary demo

                  // for (let item = 0; item <= 3; item++) {

                  //     let listItem = document.createElement('li');

                  //     listItem.style.cursor = 'pointer';

                  //     listItem.innerHTML = `${item} abcdef ghi`;

                  //     listItem.addEventListener('click', function() {

                  //         // replace the value with the clicked item

                  //         el.value = item;

                  //     });

                  //     suggestionBox2.appendChild(listItem);

                  // }

                  nestedSearchResult.forEach((ele) => {
                    let listItem = document.createElement("li");

                    listItem.style.cursor = "pointer";

                    listItem.innerHTML = `${ele}`;

                    listItem.addEventListener("click", function () {
                      // replace the value with the clicked ele

                      el.value = ele;

                      suggestionBox.remove();
                    });

                    suggestionBox.appendChild(listItem);
                  });
                }

                // append the suggestionBox it only when the user inputs into the search bar

                parentComponent.appendChild(suggestionBox);
              });
            }
          },
        },
      });

      // editor.getSelected().append({ type: 'dataGrid' })

      editor.DomComponents.addType("progress-bar-height1px", {
        model: {
          defaults: {
            tagName: "div",
            attributes: {
              class: "progress",
              style: "height: 1px;",
            },

            components: [
              {
                tagName: "div",
                attributes: {
                  class: "progress-bar",
                  role: "progressbar",
                  style: "width: 25%;",
                  "aria-label": "1px High",
                  "aria-valuenow": "25",
                  "aria-valuemin": "0",
                  "aria-valuemax": "100",
                },

                // traits
                traits: [
                  {
                    type: "text",
                    name: "aria-valuenow",
                    label: "Aria Value Now",
                    changeProp: true,
                  },
                  {
                    type: "text",
                    name: "aria-valuemin",
                    label: "Aria Value Min",
                    changeProp: true,
                  },
                  {
                    type: "text",
                    name: "aria-valuemax",
                    label: "Aria Value Max",
                    changeProp: true,
                  },
                ],

                changeProp: true,

                "script-props": [
                  "aria-valuenow",
                  "aria-valuemin",
                  "aria-valuemax",
                ],
              },
            ],
          },
        },
      });

      editor.DomComponents.addType("ion-toggle-menu", {
        model: {
          defaults: {
            tagName: "div",
            attributes: {
              class: "ion-toggle-menu",
              id: "ion-toggle-menu",
            },

            components: [
              {
                tagName: "ion-menu",
                attributes: {
                  "content-id": "main-content",
                },

                components: [
                  {
                    tagName: "ion-header",

                    components: [
                      {
                        tagName: "ion-toolbar",

                        components: [
                          {
                            tagName: "ion-title",
                            content: "Menu Content",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    tagName: "ion-content",
                    attributes: {
                      class: "ion-padding",
                    },

                    components: [
                      {
                        tagName: "ion-menu-toggle",

                        components: [
                          {
                            tagName: "ion-button",
                            content: "toggle off",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                tagName: "div",
                attributes: {
                  class: "ion-page",
                  id: "main-content",
                },

                components: [
                  {
                    tagName: "ion-header",

                    components: [
                      {
                        tagName: "ion-toolbar",

                        components: [
                          {
                            tagName: "ion-title",
                            content: "menu",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    tagName: "ion-content",
                    attributes: {
                      class: "ion-padding",
                    },

                    components: [
                      {
                        tagName: "ion-menu-toggle",

                        components: [
                          {
                            tagName: "ion-button",
                            content: "Toggle Open",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        },
      });

      const offConvasMenuBtnScript = function (props) {
        if (props.Data_Bs_Toggle) {
          this.getElementsByClassName("offcanvas-menu-btn")[0].setAttribute(
            "data-bs-toggle",
            `${props.Data_Bs_Toggle}`
          );
        }

        if (props.Data_Bs_Target) {
          this.getElementsByClassName("offcanvas-menu-btn")[0].setAttribute(
            "data-bs-target",
            `${props.Data_Bs_Target}`
          );
        }

        if (props.Aria_Controls) {
          this.getElementsByClassName("offcanvas-menu-btn")[0].setAttribute(
            "aria-controls",
            `${props.Aria_Controls}`
          );
        }
      };

      // dependent on type: ion-toggle-menu
      editor.DomComponents.addType("offcanvas-menu-btn-container", {
        model: {
          defaults: {
            tagName: "span",
            type: "offcanvas-menu-btn-container",
            script: offConvasMenuBtnScript,
            attributes: {
              class: "offcanvas-menu-btn-container",
            },

            components: [
              {
                tagName: "button",
                type: "offcanvas-menu-btn",
                attributes: {
                  // type: "button",
                  class: "offcanvas-menu-btn",
                  "data-bs-toggle": "offcanvas",
                  "data-bs-target": "#offcanvasExample",
                  "aria-controls": "offcanvasExample",
                },

                styles: `
                  .offcanvas-menu-btn {
                    padding: 0.5em;
                    width: max-content;
                    border-radius: 10px;
                  }
                `,

                components: [
                  {
                    tagName: "p",
                    type: "text",
                    editable: true,

                    components: {
                      type: "textnode",
                      content: "Toggle Menu",
                    },
                  },
                ],
              },
            ],

            // traits
            Data_Bs_Toggle: "offcanvas",
            Data_Bs_Target: "#offcanvasExample",
            Aria_Controls: "offcanvasExample",

            traits: [
              {
                type: "text",
                name: "Data_Bs_Toggle",
                label: "data-bs-toggle",
                changeProp: true,
              },
              {
                type: "text",
                name: "Data_Bs_Target",
                label: "data-bs-target",
                changeProp: true,
              },
              {
                type: "text",
                name: "Aria_Controls",
                label: "aria-controls",
                changeProp: true,
              },
            ],

            changeProp: true,

            "script-props": [
              "Data_Bs_Toggle",
              "Data_Bs_Target",
              "Aria_Controls",
            ],
          },
        },

        // view: {
        //   tagName: "button",

        //   init({ model }) {
        //     // to attach event listener on initialization
        //     this.listenTo(
        //       model,
        //       "change:Data_Bs_Toggle",
        //       this.handleDataBsToggleTrait
        //     );
        //     this.listenTo(
        //       model,
        //       "change:Data_Bs_Target",
        //       this.handleDataBsTargetTrait
        //     );
        //     this.listenTo(
        //       model,
        //       "change:Aria_Controls",
        //       this.handleAriaControlsTrait
        //     );
        //   },

        //   handleDataBsToggleTrait() {
        //     const dataBsToggle = this.model.get("Data_Bs_Toggle");
        //     console.log("dataBsToggle:", dataBsToggle);

        //     // let parentComponent = this.model.parent().getEl();
        //     let button = this.model;

        //     button.setAttributes({ "data-bs-toggle": dataBsToggle });

        //     console.log("button:", button);
        //   },

        //   handleDataBsTargetTrait() {
        //     const dataBsTarget = this.model.get("Data_Bs_Target");
        //     console.log("dataBsTarget:", dataBsTarget);

        //     // let parentComponent = this.model.parent().getEl();
        //     let button = this.model;

        //     button.setAttributes({ "data-bs-target": dataBsTarget });

        //     console.log("button:", button);
        //   },

        //   handleAriaControlsTrait() {
        //     const ariaControls = this.model.get("Aria_Controls");
        //     console.log("ariaControls:", ariaControls);

        //     // let parentComponent = this.model.parent().getEl();
        //     let button = this.model;

        //     button.setAttributes({ "aria-controls": ariaControls });

        //     console.log("button:", button);
        //   },
        // },
      });

      const offConvasMenuScript = function (props) {
        if (props.Offcanvas_Direction) {
          this.setAttribute("class", `offcanvas ${props.Offcanvas_Direction}`);
        }
      };

      editor.DomComponents.addType("offcanvas-menu", {
        model: {
          defaults: {
            tagName: "div",
            type: "offcanvas-menu",
            script: offConvasMenuScript,
            attributes: {
              class: "offcanvas offcanvas-start",
              tabindex: "-1",
              id: "offcanvasExample",
              "aria-labelledby": "offcanvasExampleLabel",
            },

            // traits
            Offcanvas_Direction: "",

            traits: [
              "id",
              "aria-labelledby",
              {
                type: "select",
                name: "Offcanvas_Direction",
                label: "Offcanvas Direction",
                options: [
                  { id: "offcanvas-start", name: "Start" },
                  { id: "offcanvas-end", name: "End" },
                ],
                changeProp: true,
              },
            ],

            changeProp: true,

            "script-props": ["Offcanvas_Direction"],

            components: [
              {
                tagName: "div",
                type: "offcanvas-header",
                attributes: {
                  class: "offcanvas-header",
                },

                components: [
                  {
                    tagName: "h5",
                    type: "offcanvas-title",
                    attributes: {
                      class: "offcanvas-title",
                      id: "offcanvasExampleLabel",
                    },

                    components: {
                      tagName: "p",
                      type: "text",
                      editable: true,

                      components: {
                        type: "textnode",
                        content: "Menu Title",
                      },
                    },
                  },
                  {
                    tagName: "button",
                    type: "offcanvas-close-btn",
                    attributes: {
                      class: "btn-close",
                      "data-bs-dismiss": "offcanvas",
                      "aria-label": "Close",
                    },
                  },
                ],
              },
              {
                tagName: "div",
                type: "offcanvas-body",
                attributes: {
                  class: "offcanvas-body",
                },

                components: [
                  {
                    tagName: "div",

                    components: {
                      tagName: "p",
                      type: "text",
                      editable: true,

                      components: {
                        type: "textnode",
                        content:
                          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec nisl est, volutpat non elit ac, viverra laoreet mauris. In cursus a eros vel pulvinar.",
                      },
                    },
                  },
                ],
              },
            ],
          },
        },

        // view: {
        //   init({ model }) {
        //     // to attach event listener on initialization
        //     this.listenTo(
        //       model,
        //       "change:Offcanvas_Direction",
        //       this.handleOffcanvasDirectionTrait
        //     );
        //   },

        //   handleOffcanvasDirectionTrait() {
        //     const offcanvasDirection = this.model.get("Offcanvas_Direction");
        //     console.log("offcanvasDirection:", offcanvasDirection);

        //     // let parentComponent = this.model.parent().getEl();
        //     let menuDiv = this.model;

        //     menuDiv.setAttributes({
        //       class: "offcanvas" + " " + offcanvasDirection,
        //     });

        //     console.log("menuDiv:", menuDiv);
        //   },
        // },
      });

      // Bootstrap Tabs
      editor.DomComponents.addType("button-tabs-btn-container", {
        model: {
          defaults: {
            tagName: "div",
            type: "button-tabs-btn-container",
            attributes: {
              class: "button-tabs-btn-container",
            },

            "style-default": {
              padding: "0.5em",
            },

            style: {
              padding: "0.5em",
            },

            components: [
              {
                tagName: "button",
                type: "button-tabs-btn",
                attributes: {
                  class: "nav-link",
                  "data-bs-toggle": "pill",
                  "data-bs-target": "#pills-home",
                  "aria-controls": "#pills-home",
                  role: "tab",
                },

                components: {
                  tagName: "p",
                  type: "text",
                  editable: true,

                  components: {
                    type: "textnode",
                    content: "Tab Button",
                  },
                },
              },
            ],

            // traits
            Data_Bs_Target: "#pills-home",
            Aria_Controls: "#pills-home",

            traits: [
              {
                type: "text",
                name: "Data_Bs_Target",
                label: "(Target's ID) data-bs-target",
                changeProp: true,
              },
              {
                type: "text",
                name: "Aria_Controls",
                label: "(Control's ID) aria-controls",
                changeProp: true,
              },
            ],

            changeProp: true,
            "script-props": ["Data_Bs_Target", "Aria_Controls"],
          },

          init() {
            this.on("change:Data_Bs_Target", this.handlePropChange);
            this.on("change:Aria_Controls", this.handlePropChange);
          },

          handlePropChange() {
            let { Data_Bs_Target, Aria_Controls } = this.props();
            console.log(
              "Data_Bs_Target, Aria_Controls",
              Data_Bs_Target,
              Aria_Controls
            );

            let btn = this.find("button.nav-link")[0];

            if (Data_Bs_Target) {
              btn.attributes.attributes["data-bs-target"] = Data_Bs_Target;
            }

            if (Data_Bs_Target) {
              btn.attributes.attributes["aria-controls"] = Aria_Controls;
            }

            console.log("btn", btn);
          },
        },
      });

      editor.DomComponents.addType("button-tabs-tab-content-panel", {
        model: {
          defaults: {
            tagName: "div",
            type: "button-tabs-tab-content-panel",
            attributes: {
              id: "pills-home",
              class: "tab-pane fade",
              role: "tabpanel",
              "aria-labelledby": "pills-home-tab",
              tabindex: "0",
            },

            "style-default": {
              padding: "0.5em",
            },

            style: {
              padding: "0.5em",
            },

            content:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent pretium mi ut lacinia vehicula. In faucibus rutrum lorem, eu sagittis nibh dignissim non. Sed convallis tempus urna eu elementum. Quisque congue tempor tellus, hendrerit tempor nibh rhoncus non. Phasellus tincidunt orci a arcu porta maximus. Pellentesque lacinia lacus at euismod molestie. Aenean ut nulla bibendum, viverra tortor lobortis, gravida arcu.",

            traits: ["id"],
            changeProp: true,
          },
        },
      });

      editor.DomComponents.addType("button-tabs", {
        model: {
          defaults: {
            tagName: "div",
            type: "button-tabs",
            attributes: {
              class: "button-tabs",
            },

            components: [
              {
                tagName: "ul",
                type: "button-tabs-ul",
                attributes: {
                  class: "nav nav-pills mb-3",
                  // id: "pills-tab",
                  role: "tablist",
                },

                components: [
                  {
                    tagName: "li",
                    type: "button-tabs-ul-li",
                    attributes: {
                      class: "nav-item",
                      role: "presentation",
                    },

                    components: [
                      {
                        type: "button-tabs-btn-container",
                      },
                    ],
                  },
                ],
              },
              {
                tagName: "div",
                type: "button-tabs-tab-content",
                attributes: {
                  class: "tab-content",
                  // id: "pills-tabContent",
                },

                components: [
                  {
                    type: "button-tabs-tab-content-panel",
                  },
                ],
              },
            ],

            // traits
            AddTabs: 0,

            traits: [
              {
                type: "number",
                name: "AddTabs",
                label: "Add tabs",
                placeholder: "how many more",
                min: "0",
                changeProp: true,
              },
            ],

            changeProp: true,
            "script-props": ["AddTabs"],
          },

          init() {
            this.on("change:AddTabs", this.handlePropChange);
          },

          handlePropChange() {
            let { AddTabs } = this.props();
            console.log("AddTabs", AddTabs);

            let navItem = this.find(".nav-item")[0];
            let tabContent = this.find(".tab-content")[0];

            // adding more tabs and panels as required
            for (let i = 0; i < AddTabs; i++) {
              navItem.append({
                type: "button-tabs-btn-container",
              });

              tabContent.append({
                type: "button-tabs-tab-content-panel",
              });
            }
          },
        },
      });

      editor.DomComponents.addType("anchor", {
        model: {
          defaults: {
            tagName: "a",

            attributes: {
              href: "#",
            },

            // traits
            href: "#",

            traits: [
              {
                name: "href",
                label: "href",
                placeholder: "Hyperlink reference",
                changeProp: true,
              },
            ],

            changeProp: true,

            components: {
              tagName: "p",
              type: "text",
              editable: true,

              components: {
                type: "textnode",
                content: "Link",
              },
            },
          },
        },

        view: {
          init({ model }) {
            // to attach event listener on initialization
            this.listenTo(model, "change:href", this.handleHrefTrait);
          },

          handleHrefTrait() {
            const href = this.model.get("href");
            console.log("href:", href);

            // let parentComponent = this.model.parent().getEl();
            let anchor = this.model;

            anchor.setAttributes({
              href: href,
            });

            console.log("anchor:", anchor);
          },
        },
      });

      // NAV-BAR
      editor.DomComponents.addType("nav-bar", {
        model: {
          defaults: {
            tagName: "nav",
            attributes: {
              class: "nav-bar",
              id: "nav-bar",
            },

            components: [
              {
                type: "anchor",
              },
              {
                tagName: "a",
                attributes: {
                  href: "#",
                },

                // traits
                href: "#",

                traits: [
                  {
                    name: "href",
                    label: "href",
                    placeholder: "Hyperlink reference",
                    changeProp: true,
                  },
                ],

                changeProp: true,

                components: {
                  tagName: "p",
                  type: "text",
                  editable: true,

                  components: {
                    type: "textnode",
                    content: "Contact us",
                  },
                },
              },
            ],
          },
        },
      });

      const dropdownItemScript = function (props) {
        console.log("this, props", this, props);
        let project_id = props.projectId;

        if (
          props.Navigate &&
          props.InternalTarget &&
          (!props.ExternalTarget || props.ExternalTarget === "#")
        ) {
          this.onclick = function (event) {
            console.log("props.InternalTarget", props.InternalTarget);

            const origin = window.location.origin;

            if (window.location.href.includes("/large_preview")) {
              window.top.location.href =
                origin + "/large_preview/" + `${props.InternalTarget}`;
            } else if (window.top.location.href.includes("/editor")) {
              window.top.location.href =
                origin +
                `/editor/` +
                `${props.InternalTarget}` +
                "?projectId=" +
                `${project_id}`;
            } else {
              window.top.location.href = `{{HOST_URL}}/${props.InternalTarget}.html`;
            }
          };
        } else if (
          props.Navigate &&
          props.ExternalTarget &&
          !props.InternalTarget
        ) {
          this.onclick = function (event) {
            console.log("props.ExternalTarget", props.ExternalTarget);

            let origin = "https://";
            let modifiedUrl =
              props.ExternalTarget.includes("http://") ||
              props.ExternalTarget.includes("https://")
                ? props.ExternalTarget
                : origin + `${props.ExternalTarget}`;
            console.log("modifiedUrl:", modifiedUrl);
            let win = window.open(modifiedUrl, "_blank");
            if (win) {
              win.focus();
            }
          };
        }
      };

      editor.DomComponents.addType("dropdown-item", {
        model: {
          defaults: {
            tagName: "a",
            type: "dropdown-item",
            script: dropdownItemScript,
            type: "link",
            attributes: {
              class: "dropdown-item",
            },

            "style-default": {
              padding: "0.5em",
              cursor: "pointer",
            },

            style: {
              padding: "0.5em",
              cursor: "pointer",
            },

            components: {
              tagName: "p",
              type: "text",
              editable: true,

              components: {
                type: "textnode",
                content: "Menu Item",
              },
            },

            Navigate: false,
            ExternalTarget: "#",
            InternalTarget: "",
            // pageByProjectData: pageByProjectData ? pageByProjectData : "",
            projectId: localStorage.getItem("project_id")
              ? localStorage.getItem("project_id")
              : "",

            traits: [
              {
                type: "select",
                name: "Navigate",
                label: "Navigate",
                options: [
                  { id: "true", value: true, name: "True" },
                  { id: "false", value: false, name: "False" },
                ],
                changeProp: true,
              },
              {
                type: "text",
                name: "ExternalTarget",
                label: "External Target",
                placeholder: "http(s)://www.example.com/",
                changeProp: true,
              },
              {
                type: "select",
                name: "InternalTarget",
                label: "Internal Target",
                changeProp: true,
                options: pageByProjectData
                  ? pageByProjectData?.map((x) => {
                      const arr = {
                        value: x._id,
                        name: x.name,
                      };
                      return arr;
                    })
                  : "",
              },
            ],

            "script-props": [
              "Navigate",
              "ExternalTarget",
              "InternalTarget",
              "projectId",
            ],
          },
        },
      });

      const dropdownDDScript = function () {
        console.log("this", this);

        let button = this.getElementsByClassName("dropdown-dd-btn")[0];
        let itemContainer = this.getElementsByClassName(
          "dropdown-item-container"
        )[0];

        // on click, the dropdown-item-container should appear and again on click, it should disappear
        // button.addEventListener("click", function (event) {
        //   // itemContainer.classList.toggle('toggle-dropdown-item-container');
        //   itemContainer.style.display =
        //     itemContainer.style.display === "none" ? "inline" : "none";
        // });

        button.onclick = function (event) {
          // itemContainer.classList.toggle('toggle-dropdown-item-container');
          itemContainer.style.display =
            itemContainer.style.display === "none" ? "inline" : "none";
        };

        // if (props.Expand) {
        //   itemContainer.style.display = 'block';
        // }

        // if (!props.Expand) {
        //   itemContainer.style.display = 'none';
        // }

        // if (props.Direction === 'top') {
        //   itemContainer.style.top = 'auto';
        //   itemContainer.style.bottom = '105%';
        //   itemContainer.style.left = '0';
        //   itemContainer.style.right = 'auto';
        //   itemContainer.style.position = 'absolute';
        // }

        // if (props.Direction === 'right') {
        //   itemContainer.style.top = '0';
        //   itemContainer.style.bottom = 'auto';
        //   itemContainer.style.left = '105%';
        //   itemContainer.style.right = 'auto';
        //   itemContainer.style.position = 'absolute';
        // }

        // if (props.Direction === 'bottom') {
        //   itemContainer.style.top = '105%';
        //   itemContainer.style.bottom = 'auto';
        //   itemContainer.style.left = '0';
        //   itemContainer.style.right = 'auto';
        //   itemContainer.style.position = 'absolute';
        // }

        // if (props.Direction === 'left') {
        //   itemContainer.style.top = '0';
        //   itemContainer.style.bottom = 'auto';
        //   itemContainer.style.left = 'auto';
        //   itemContainer.style.right = '105%';
        //   itemContainer.style.position = 'absolute';
        // }
      };

      editor.DomComponents.addType("directional-dd", {
        model: {
          defaults: {
            tagName: "div",
            type: "directional-dd",
            script: dropdownDDScript,
            attributes: {
              class: "dropdown-dd",
            },

            "style-default": {
              width: "max-content",
              padding: "0.5em",
              position: "relative",
            },

            style: {
              width: "max-content",
              padding: "0.5em",
              position: "relative",
            },

            components: [
              {
                tagName: "button",
                type: "dropdown-dd-btn",
                attributes: {
                  class: "dropdown-dd-btn",
                },

                "style-default": {
                  width: "15vw",
                  padding: "0.5em",
                  cursor: "pointer",
                },

                style: {
                  width: "15vw",
                  padding: "0.5em",
                  cursor: "pointer",
                },

                components: {
                  tagName: "p",
                  type: "text",
                  editable: true,

                  components: {
                    type: "textnode",
                    content: "Menu Button",
                  },
                },
              },
              {
                tagName: "div",
                type: "dropdown-item-container",
                attributes: {
                  class: "dropdown-item-container",
                },

                "style-default": {
                  display: "none",
                  padding: "0.5em",
                  width: "15vw",
                  position: "absolute !important",
                  top: "105%",
                  left: "0",
                  "border-radius": "10px",
                  "background-color": "white !important",
                  border: "1px solid gray",
                },

                style: {
                  display: "none",
                  padding: "0.5em",
                  width: "15vw",
                  position: "absolute !important",
                  top: "105%",
                  left: "0",
                  "border-radius": "10px",
                  "background-color": "white !important",
                  border: "1px solid gray",
                },

                components: [
                  {
                    type: "dropdown-item",
                  },
                  {
                    type: "dropdown-item",
                  },
                  {
                    type: "dropdown-item",
                  },
                ],
              },
            ],

            // traits
            // Expand: "",
            // Direction: "",

            // traits: [
            //   {
            //     type: "select",
            //     name: "Expand",
            //     label: "Expand",
            //     options: [
            //       { id: "true", value: true, name: "True" },
            //       { id: "false", value: false, name: "False" },
            //     ],
            //     changeProp: true,
            //   },
            //   {
            //     type: "select",
            //     name: "Direction",
            //     label: "Direction",
            //     options: [
            //       { id: "top", value: "top", name: "Top" },
            //       { id: "right", value: "right", name: "Right" },
            //       { id: "bottom", value: "bottom", name: "Bottom" },
            //       { id: "left", value: "left", name: "Left" },
            //     ],
            //     changeProp: true,
            //   },
            // ],

            // "script-props": ["Expand", "Direction"],
            changeProp: true,
          },
        },
      });

      const stellarScript = function (props) {
        let project_id = window.location.search.split("=")[1];
        let parentComponent = this.parentElement.nodeName;
        console.log("this, parentComponent:", this, parentComponent);
        console.log("props.pageByProjectData", props.pageByProjectData);

        if (props.Navigate && props.InternalTarget && !props.ExternalTarget) {
          this.getElementsByTagName("button")[0].addEventListener(
            "click",
            function (event) {
              console.log("props.InternalTarget", props.InternalTarget);

              const origin = window.location.origin;
              if (window.location.href.includes("/large_preview")) {
                window.top.location.href =
                  origin + "/large_preview/" + `${props.InternalTarget}`;
              } else if (window.top.location.href.includes("/editor")) {
                window.top.location.href =
                  origin +
                  `/editor/` +
                  `${props.InternalTarget}` +
                  "?projectId=" +
                  `${project_id}`;
              } else {
                let pageName = props.pageByProjectData.filter(
                  (page) => page["_id"] === props.InternalTarget
                )[0].name;
                // page name (remove space from page name and keep the first letter in uppercase), instead of pageId
                let words = pageName.split(" ");
                let capitalizeWords = words.map(
                  (word) => word.charAt(0).toUpperCase() + word.slice(1)
                );
                let formattedPageName = capitalizeWords.join("");

                window.location.href =
                  origin + "/" + formattedPageName
                    ? formattedPageName
                    : `${props.InternalTarget}`;
              }
            }
          );
        } else if (
          props.Navigate &&
          props.ExternalTarget &&
          !props.InternalTarget
        ) {
          this.getElementsByTagName("button")[0].addEventListener(
            "click",
            function (event) {
              console.log("props.ExternalTarget", props.ExternalTarget);

              let origin = "https://";
              let modifiedUrl =
                props.ExternalTarget.includes("http://") ||
                props.ExternalTarget.includes("https://")
                  ? props.ExternalTarget
                  : origin + `${props.ExternalTarget}`;
              console.log("modifiedUrl:", modifiedUrl);
              let win = window.open(modifiedUrl, "_blank");
              if (win) {
                win.focus();
              }
            }
          );
        }

        // if the parent component of this button is not a form, then use button's API traits
        if (parentComponent !== "FORM") {
          console.log("this button is NOT inside a FORM; using API traits");

          // GET API call
          if (props.Method === "GET") {
            console.log("stellar-button GET request");

            // creating a token using this method from creatorFunctions.js file

            // let token = authenticateUser(username, password);

            // console.log('stellar-button GET request token:', token);

            // let config = {

            //     "Authorization": `${token}`,

            // };

            // if there is no api key, username or password
            if (
              props.Username === "" &&
              props.Password === "" &&
              props.API_Key === ""
            ) {
              console.log(
                "No username, password & api key for stellar button POST request"
              );

              // making the fetch API GET request

              fetch(`${props.API_URL}`, {
                method: props.Method,
                // headers: config,
              })
                .then((response) => response.json())
                .then((data) => {
                  // can display the data here in a table format
                  if (props.Response_Status_200) {
                    this.getElementsByTagName("button")[0].addEventListener(
                      "click",
                      function (e) {
                        const origin = window.location.origin;
                        window.top.location.href =
                          origin +
                          `/editor/` +
                          `${props.Response_Status_200}` +
                          "?projectId=" +
                          `${project_id}`;
                      }
                    );
                  }
                  console.log("stellar-button GET request Data:", data);
                })
                .catch((error) => {
                  console.log("stellar-button GET request error:", error);
                });
            }

            // if there is an api key, but no username or password

            if (props.API_Key !== "") {
              console.log("Api key provided for stellar button GET request");

              // making the fetch API GET request

              fetch(`${props.API_URL}`, {
                method: props.Method,

                headers: {
                  "Content-Type": `${props.Content_Type}`,

                  Authorization: `Bearer ${props.API_Key}`,
                },
              })
                .then((response) => response.json())

                .then((data) => {
                  // can display the data here in a table format
                  if (props.Response_Status_200) {
                    this.getElementsByTagName("button")[0].addEventListener(
                      "click",
                      function (e) {
                        const origin = window.location.origin;
                        window.top.location.href =
                          origin +
                          `/editor/` +
                          `${props.Response_Status_200}` +
                          "?projectId=" +
                          `${project_id}`;
                      }
                    );
                  }
                  console.log("stellar-button GET request Data:", data);
                })
                .catch((error) => {
                  console.log("stellar-button GET request error:", error);
                });
            }

            // if there is no api key, but username or password or token

            if (props.Username !== "" && props.Password !== "") {
              console.log(
                "Username & Password provided for stellar button GET request"
              );

              // creating a token using this method from creatorFunctions.js file

              let token = authenticateUser(props.Username, props.Password);

              console.log("stellar-button GET request token:", token);

              // making the fetch API GET request

              fetch(`${props.API_URL}`, {
                method: props.Method,

                headers: {
                  "Content-Type": `${props.Content_Type}`,

                  Authorization: `${token}`,
                },
              })
                .then((response) => response.json())

                .then((data) => {
                  // can display the data here in a table format
                  if (props.Response_Status_200) {
                    this.getElementsByTagName("button")[0].addEventListener(
                      "click",
                      function (e) {
                        const origin = window.location.origin;
                        window.top.location.href =
                          origin +
                          `/editor/` +
                          `${props.Response_Status_200}` +
                          "?projectId=" +
                          `${project_id}`;
                      }
                    );
                  }
                  console.log("stellar-button GET request Data:", data);
                })

                .catch((error) => {
                  console.log("stellar-button GET request error:", error);
                });
            }
          }

          // GET API call
          else if (props.Method === "POST") {
            console.log("stellar-button POST request");

            // if there is no api key, username or password

            if (
              props.Username === "" &&
              props.Password === "" &&
              props.API_Key === ""
            ) {
              console.log(
                "No username, password & api key for stellar button POST request"
              );

              // making the fetch API POST request

              fetch(`${props.API_URL}`, {
                method: props.Method,

                headers: {
                  "Content-Type": `${props.Content_Type}`,
                },

                body: props.Body,
              })
                .then((response) => response.json())

                .then((data) => {
                  if (props.Response_Status_200) {
                    this.getElementsByTagName("button")[0].addEventListener(
                      "click",
                      function (e) {
                        const origin = window.location.origin;
                        window.top.location.href =
                          origin +
                          `/editor/` +
                          `${props.Response_Status_200}` +
                          "?projectId=" +
                          `${project_id}`;
                      }
                    );
                  }
                  // can display the data here in a table format

                  console.log("stellar-button POST request Data:", data);
                })

                .catch((error) => {
                  console.log("stellar-button POST request error:", error);
                });
            }

            // if there is an api key, but no username or password

            if (props.API_Key !== "") {
              console.log("Api key provided for stellar button POST request");

              // making the fetch API POST request

              fetch(`${props.API_URL}`, {
                method: props.Method,

                headers: {
                  "Content-Type": `${props.Content_Type}`,

                  Authorization: `Bearer ${props.API_Key}`,
                },

                body: props.Body,
              })
                .then((response) => response.json())

                .then((data) => {
                  // can display the data here in a table format

                  console.log("stellar-button POST request Data:", data);
                  if (props.Response_Status_200) {
                    this.getElementsByTagName("button")[0].addEventListener(
                      "click",
                      function (e) {
                        const origin = window.location.origin;
                        window.top.location.href =
                          origin +
                          `/editor/` +
                          `${props.Response_Status_200}` +
                          "?projectId=" +
                          `${project_id}`;
                      }
                    );
                  }
                })

                .catch((error) => {
                  console.log("stellar-button POST request error:", error);
                });
            }

            // if there is no api key, but username or password or token

            if (props.Username !== "" && props.Password !== "") {
              console.log(
                "Username & Password provided for stellar button POST request"
              );

              // creating a token using this method from creatorFunctions.js file

              let token = authenticateUser(props.Username, props.Password);

              console.log("stellar-button POST request token:", token);

              // making the fetch API POST request

              fetch(`${props.API_URL}`, {
                method: props.Method,

                headers: {
                  "Content-Type": `${props.Content_Type}`,

                  Authorization: `${token}`,
                },

                body: props.Body,
              })
                .then((response) => response.json())

                .then((data) => {
                  if (props.Response_Status_200) {
                    this.getElementsByTagName("button")[0].addEventListener(
                      "click",
                      function (e) {
                        const origin = window.location.origin;
                        window.top.location.href =
                          origin +
                          `/editor/` +
                          `${props.Response_Status_200}` +
                          "?projectId=" +
                          `${project_id}`;
                      }
                    );
                  }
                  // can display the data here in a table format

                  console.log("stellar-button POST request Data:", data);
                })

                .catch((error) => {
                  console.log("stellar-button POST request error:", error);
                });
            }
          }
        }

        // if the parent component of this button is a form, then use the data from the form
        // if (parentComponent === "FORM") {
        //   var formApiUrl = this.model.get("Form_API_URL");

        //   var formMethod = this.model.get("Form_Method");

        //   var formContentType = this.model.get("Form_Content_Type");

        //   let formEmail;

        //   let formEmailTraitName; // using this variable to store the 'Name' trait value of its input field and passing it in form 'body' fetch API request

        //   let formUsername;

        //   let formUsernameTraitName; // using this variable to store the 'Name' trait value of its input field and passing it in form 'body' fetch API request

        //   let formPassword;

        //   let formPasswordTraitName; // using this variable to store the 'Name' trait value of its input field and passing it in form 'body' fetch API request

        //   let formBody = {}; // creating payload for 'Body' key

        //   // the form should have only 1 username and password field

        //   // find all the inputs in the form container and see their field types and do something based on them

        //   parentComponent.find("input").forEach(
        //     (child) => {
        //       // if (child.attributes.tagName === 'input') {

        //       console.log(
        //         "child:",
        //         child,
        //         "\ninput value:",
        //         child.view.el.value
        //       );

        //       // CONFIGURED only for inputs-types: email, text, text-node, password

        //       // checking if element is email or username or password and then extracting their value and trait Name

        //       if (child.attributes.attributes.type === "email") {
        //         formEmail = child.view.el.value;

        //         formEmailTraitName = child.attributes.attributes.name;
        //       }

        //       if (child.attributes.attributes.type === "text") {
        //         formUsername = child.view.el.value;

        //         formUsernameTraitName = child.attributes.attributes.name;
        //       }

        //       if (child.attributes.attributes.type === "password") {
        //         formPassword = child.view.el.value;

        //         formPasswordTraitName = child.attributes.attributes.name;
        //       }

        //       // preparing formBody (payload) depending of whether the Name traits are provided or not

        //       if (formEmailTraitName)
        //         formBody[formEmailTraitName] = formEmail;

        //       if (formUsernameTraitName)
        //         formBody[formUsernameTraitName] = formUsername;

        //       if (formPasswordTraitName)
        //         formBody[formPasswordTraitName] = formPassword;
        //     }

        //     // }
        //   );

        //   // let allInputs = parentComponent.find('input');

        //   // console.log('allInputs:', allInputs);

        //   console.log(
        //     "formApiUrl:",
        //     formApiUrl,
        //     "\nformMethod:",
        //     formMethod,
        //     "\nformContentType:",
        //     formContentType,
        //     "\nformEmail:",
        //     formEmail,
        //     "\nformUsername:",
        //     formUsername,
        //     "\nformPassword:",
        //     formPassword,
        //     "\nformBody (payload):",
        //     formBody
        //   );

        //   // making a GET request

        //   if (formMethod === "GET") {
        //     // if there is no api key, but formUsername or formPassword or token

        //     if (formUsername !== "" && formPassword !== "") {
        //       console.log(
        //         "Username & Password provided for stellar button GET request"
        //       );

        //       // creating a token using this method from creatorFunctions.js file

        //       let token = authenticateUser(formUsername, formPassword);

        //       console.log(
        //         "stellar-button inside form GET request token:",
        //         token
        //       );

        //       // making the fetch API GET request

        //       fetch(`${formApiUrl}`, {
        //         method: formMethod,

        //         headers: {
        //           "Content-Type": `${formContentType}`,

        //           Authorization: `${token}`,
        //         },
        //       })
        //         .then((response) => response.json())

        //         .then((data) => {
        //           if (responseStatus200) {
        //             const origin = window.location.origin;

        //             window.location.href =
        //               origin +
        //               `/editor/` +
        //               `${responseStatus200}` +
        //               "?projectId=" +
        //               `${project_id}`;
        //           }
        //           // can display the data here in a table format

        //           console.log(
        //             "stellar-button form GET request Data:",
        //             data
        //           );

        //           toast.info(
        //             "Data is received successfully in the console.",
        //             successTheme
        //           );
        //         })

        //         .catch((error) => {
        //           console.log(
        //             "stellar-button form GET request error:",
        //             error
        //           );
        //         });
        //     }
        //   }

        //   // making a POST request

        //   if (formMethod === "POST") {
        //     // if there is no api key, but formUsername or formPassword or token

        //     if (
        //       formUsername !== undefined &&
        //       formPassword !== undefined
        //     ) {
        //       console.log(
        //         "Username & Password provided for stellar button POST request"
        //       );

        //       // creating a token using this method from creatorFunctions.js file

        //       let token = authenticateUser(formUsername, formPassword);

        //       console.log(
        //         "stellar-button inside form, POST request token:",
        //         token
        //       );

        //       // making the fetch API POST request

        //       fetch(`${formApiUrl}`, {
        //         method: formMethod,

        //         headers: {
        //           "Content-Type": `${formContentType}`,

        //           Authorization: `${token}`,
        //         },

        //         body:
        //           formContentType === "application/json"
        //             ? JSON.stringify(formBody)
        //             : formBody,
        //       })
        //         .then((response) => response.json())

        //         .then((data) => {
        //           // can display the data here in a table format
        //           if (responseStatus200) {
        //             const origin = window.location.origin;

        //             window.location.href =
        //               origin +
        //               `/editor/` +
        //               `${responseStatus200}` +
        //               "?projectId=" +
        //               `${project_id}`;
        //           }
        //           console.log(
        //             "stellar-button form POST request Data:",
        //             data
        //           );

        //           toast.info(
        //             "Data is received successfully in the console.",
        //             successTheme
        //           );
        //         })

        //         .catch((error) => {
        //           console.log(
        //             "stellar-button form POST request error:",
        //             error
        //           );
        //         });
        //     }

        //     // if there is no api key, but formEmail or formPassword or token

        //     if (formEmail !== undefined && formPassword !== undefined) {
        //       console.log(
        //         "Username & Password provided for stellar button POST request"
        //       );

        //       // creating a token using this method from creatorFunctions.js file

        //       let token = authenticateUser(formEmail, formPassword);

        //       console.log(
        //         "stellar-button inside form, POST request token:",
        //         token
        //       );

        //       // making the fetch API POST request

        //       fetch(`${formApiUrl}`, {
        //         method: formMethod,

        //         headers: {
        //           "Content-Type": `${formContentType}`,

        //           Authorization: `${token}`,
        //         },

        //         body:
        //           formContentType === "application/json"
        //             ? JSON.stringify(formBody)
        //             : formBody,
        //       })
        //         .then((response) => response.json())

        //         .then((data) => {
        //           if (responseStatus200) {
        //             const origin = window.location.origin;

        //             window.location.href =
        //               origin +
        //               `/editor/` +
        //               `${responseStatus200}` +
        //               "?projectId=" +
        //               `${project_id}`;
        //           }

        //           console.log(
        //             "stellar-button form POST request Data:",
        //             data
        //           );

        //           toast.info(
        //             "Data is received successfully in the console.",
        //             successTheme
        //           );
        //         })

        //         .catch((error) => {
        //           console.log(
        //             "stellar-button form POST request error:",
        //             error
        //           );
        //         });
        //     }
        //   }
        // }
      };

      editor.DomComponents.addType("stellar-button", {
        model: {
          defaults: {
            tagName: "div",
            script: stellarScript,
            attributes: {
              class: "stellar-button-container",
            },

            components: [
              {
                tagName: "button",
                attributes: { class: "stellar-btn" },
                editable: true,

                // EXPLANATION - why do we need to define css in both 'style-default' and style keys along with the same css in geditor_utils.js file?

                // using 'style-default', we make it so it doesn't delete other componenets' css when this button is deleted but if there is no other component already present

                // in the canvas and this is the first to be dropped then its css won't initialize with it and that's why we define css in 'style' key also.

                // But if we define css only in 'style' key then the user won't be able to customize this component even from the styles manager and if we define css

                // using 'styles' key then if the user deletes this component then other components will lose their css also thats why both 'style-default' and 'style' key are needed for the css definition.

                "style-default": {
                  width: "max-content",

                  padding: "0.5em",

                  "border-radius": "10px",

                  "background-color": "blue",

                  border: "none",

                  color: "white",

                  cursor: "pointer",

                  "pointer-events": "auto",
                },

                style: {
                  width: "max-content",

                  padding: "0.5em",

                  "border-radius": "10px",

                  "background-color": "blue",

                  border: "none",

                  color: "white",

                  cursor: "pointer",

                  "pointer-events": "auto",
                },

                components: {
                  tagName: "p",

                  type: "text",

                  editable: true,

                  components: {
                    type: "textnode",

                    content: "Click",
                  },

                  "style-default": {
                    margin: "auto",

                    "text-align": "center",
                  },

                  style: {
                    margin: "auto",

                    "text-align": "center",
                  },
                },

                // styles: `

                //     .stellar-btn {

                //         width: max-content;

                //         padding: 0.5em;

                //         border-radius: 10px;

                //         background-color: blue;

                //         border: none;

                //         color: white;

                //         cursor: pointer;

                //     }

                //     .stellar-btn > p {

                //         margin: auto;

                //         text-align: center;

                //     }

                // `,
              },
            ],

            // traits
            Navigate: false,
            InternalTarget: "",
            ExternalTarget: "",
            pageByProjectData: pageByProjectData ? pageByProjectData : "",

            // Independent Button API traits
            API_URL: "",
            Method: "",
            Body: "",
            Content_Type: "",
            Username: "",
            Password: "",
            API_Key: "",
            Response_Status_200: "",
            Response_Status_201: "",
            Response_Status_204: "",
            Response_Status_400: "",
            Response_Status_404: "",
            Response_Status_500: "",

            // Within Form API traits
            Form_API_URL: "",
            Form_Method: "",
            Form_Content_Type: "",

            traits: [
              // {

              //     type: 'text',

              //     name: 'Button_label',

              //     label: 'Button label',

              //     changeProp: true,

              // },

              {
                type: "select",
                name: "Navigate",
                label: "Navigate",
                changeProp: true,
                options: [
                  { id: "true", value: true, name: "True" },
                  { id: "false", value: false, name: "False" },
                ],
              },
              {
                type: "select",
                name: "InternalTarget",
                label: "Internal Target",
                changeProp: true,
                options: pageByProjectData
                  ? pageByProjectData?.map((x) => {
                      const arr = {
                        value: x._id,
                        name: x.name,
                      };
                      return arr;
                    })
                  : "",
              },
              {
                type: "url",
                name: "ExternalTarget",
                label: "External Target",
                changeProp: true,
              },
              {
                type: "url",
                name: "API_URL",
                label: "API URL",
                changeProp: true,
              },
              {
                type: "select",
                name: "Method",
                label: "Method",
                changeProp: true,
                options: [
                  { id: "GET", name: "GET" },
                  { id: "POST", name: "POST" },
                ],
              },
              {
                type: "text",
                name: "Body",
                label: "Body",
                changeProp: true,
              },
              {
                type: "select",
                name: "Content_Type",
                label: "Content-Type",
                changeProp: true,
                options: [
                  {
                    id: "application/java-archive",
                    name: "application/java-archive",
                  },
                  { id: "application/json", name: "application/json" },
                  { id: "application/xml", name: "application/xml" },
                  { id: "application/xml", name: "application/xml" },
                  { id: "text/css", name: "text/css" },
                  { id: "text/csv", name: "text/csv" },
                  { id: "text/html", name: "text/html" },
                  { id: "text/plain", name: "text/plain" },
                ],
              },
              {
                type: "text",
                name: "Username",
                label: "Username",
                changeProp: true,
              },
              {
                type: "text",
                name: "Password",
                label: "Password",
                changeProp: true,
              },
              {
                type: "text",
                name: "API_Key",
                label: "Bearer Token",
                placeholder: "Bearer",
                changeProp: true,
              },
              {
                type: "select",
                name: "Response_Status_200",
                label: "Response Status 200 Target",
                changeProp: true,
                options: pageByProjectData
                  ? pageByProjectData?.map((x) => {
                      const arr = {
                        value: x._id,
                        name: x.name,
                      };
                      return arr;
                    })
                  : "",
              },
              {
                type: "select",
                name: "Response_Status_201",
                label: "Response Status 201 Target",
                changeProp: true,
                options: pageByProjectData
                  ? pageByProjectData?.map((x) => {
                      const arr = {
                        value: x._id,
                        name: x.name,
                      };
                      return arr;
                    })
                  : "",
              },
              {
                type: "select",
                name: "Response_Status_204",
                label: "Response Status 204 Target",
                changeProp: true,
                options: pageByProjectData
                  ? pageByProjectData?.map((x) => {
                      const arr = {
                        value: x._id,
                        name: x.name,
                      };
                      return arr;
                    })
                  : "",
              },
              {
                type: "select",
                name: "Response_Status_400",
                label: "Response Status 400 Target",
                changeProp: true,
                options: pageByProjectData
                  ? pageByProjectData?.map((x) => {
                      const arr = {
                        value: x._id,
                        name: x.name,
                      };
                      return arr;
                    })
                  : "",
              },
              {
                type: "select",
                name: "Response_Status_404",
                label: "Response Status 404 Target",
                changeProp: true,
                options: pageByProjectData
                  ? pageByProjectData?.map((x) => {
                      const arr = {
                        value: x._id,
                        name: x.name,
                      };
                      return arr;
                    })
                  : "",
              },
              {
                type: "select",
                name: "Response_Status_500",
                label: "Response Status 500 Target",
                changeProp: true,
                options: pageByProjectData
                  ? pageByProjectData?.map((x) => {
                      const arr = {
                        value: x._id,
                        name: x.name,
                      };
                      return arr;
                    })
                  : "",
              },
              {
                type: "url",
                name: "Form_API_URL",
                label: "Form API URL",
                changeProp: true,
              },
              {
                type: "select",
                name: "Form_Method",
                label: "Form Method",
                changeProp: true,
                options: [
                  { id: "GET", name: "GET" },
                  { id: "POST", name: "POST" },
                ],
              },
              {
                type: "select",
                name: "Form_Content_Type",
                label: "Form Content-Type",
                changeProp: true,
                options: [
                  {
                    id: "application/java-archive",
                    name: "application/java-archive",
                  },
                  { id: "application/json", name: "application/json" },
                  { id: "application/xml", name: "application/xml" },
                  { id: "application/xml", name: "application/xml" },
                  { id: "text/css", name: "text/css" },
                  { id: "text/csv", name: "text/csv" },
                  { id: "text/html", name: "text/html" },
                  { id: "text/plain", name: "text/plain" },
                ],
              },
            ],

            changeProp: true,
            "script-props": [
              "Navigate",
              "InternalTarget",
              "ExternalTarget",
              "pageByProjectData",
              "API_URL",
              "Method",
              "Body",
              "Content_Type",
              "Username",
              "Password",
              "API_Key",
              "Response_Status_200",
              "Response_Status_201",
              "Response_Status_204",
              "Response_Status_400",
              "Response_Status_404",
              "Response_Status_500",
              "Form_API_URL",
              "Form_Method",
              "Form_Content_Type",
            ],
          },

          init() {
            // this.on('change:Navigate', this.handlePropChange);
            // this.on('change:InternalTarget', this.handlePropChange);
            // this.on('change:ExternalTarget', this.handlePropChange);
            // this.on('change:API_URL', this.handlePropChange);
            // this.on('change:Method', this.handlePropChange);
            // this.on('change:Body', this.handlePropChange);
            // this.on('change:Content_Type', this.handlePropChange);
            // this.on('change:Username', this.handlePropChange);
            // this.on('change:Password', this.handlePropChange);
            // this.on('change:API_Key', this.handlePropChange);
            // this.on('change:Response_Status_200', this.handlePropChange);
            // this.on('change:Response_Status_201', this.handlePropChange);
            // this.on('change:Response_Status_204', this.handlePropChange);
            // this.on('change:Response_Status_400', this.handlePropChange);
            // this.on('change:Response_Status_404', this.handlePropChange);
            // this.on('change:Response_Status_500', this.handlePropChange);
            // this.on('change:Form_API_URL', this.handlePropChange);
            // this.on('change:Form_Method', this.handlePropChange);
            // this.on('change:Form_Content_Type', this.handlePropChange);
            // console.log('this', this);
            // if (this.attributes.tagName === 'div' && this.attributes.type === 'stellar-button') {
            //   this.setAttributes({tagName: "button"});
            // }
            // console.log('this', this);
          },

          async handlePropChange() {
            const {
              Navigate,
              InternalTarget,
              ExternalTarget,
              API_URL,
              Method,
              Body,
              Content_Type,
              Username,
              Password,
              API_Key,
              Response_Status_200,
              Response_Status_201,
              Response_Status_204,
              Response_Status_400,
              Response_Status_404,
              Response_Status_500,
              Form_API_URL,
              Form_Method,
              Form_Content_Type,
            } = this.props();
            let project_id = window.location.search.split("=")[1];
            // let parentComponent = this.model.parent();

            console.log(
              "this, project_id, Navigate, InternalTarget, ExternalTarget, API_URL, Method, Body, Content_Type, Username, Password, API_Key, Response_Status_200, Response_Status_201, Response_Status_204, Response_Status_400, Response_Status_404, Response_Status_500, Form_API_URL, Form_Method, Form_Content_Type",
              this,
              this.getEl(),
              project_id,
              Navigate,
              InternalTarget,
              ExternalTarget,
              API_URL,
              Method,
              Body,
              Content_Type,
              Username,
              Password,
              API_Key,
              Response_Status_200,
              Response_Status_201,
              Response_Status_204,
              Response_Status_400,
              Response_Status_404,
              Response_Status_500,
              Form_API_URL,
              Form_Method,
              Form_Content_Type
            );

            if (Navigate && InternalTarget && !ExternalTarget) {
              this.getEl().addEventListener("click", function (event) {
                console.log("InternalTarget", InternalTarget);

                const origin = window.location.origin;
                window.location.href =
                  origin +
                  `/editor/` +
                  `${InternalTarget}` +
                  "?projectId=" +
                  `${project_id}`;
              });
            } else if (Navigate && ExternalTarget && !InternalTarget) {
              this.getEl().addEventListener("click", function (event) {
                console.log("ExternalTarget", ExternalTarget);

                let origin = "https://";
                let modifiedUrl =
                  ExternalTarget.includes("http://") ||
                  ExternalTarget.includes("https://")
                    ? ExternalTarget
                    : origin + `${ExternalTarget}`;
                console.log("modifiedUrl:", modifiedUrl);
                let win = window.open(modifiedUrl, "_blank");
                if (win) {
                  win.focus();
                }
              });
            }

            // if (parentComponent.attributes.tagName !== "form") {
            //   console.log("this button is NOT inside a FORM; using API traits" );

            //   // GET API call
            //   if (Method === "GET") {
            //     console.log("stellar-button GET request");

            //     // if there is no api key, username or password
            //     if (Username === "" && Password === "" && API_Key === "") {
            //       console.log("No username, password & api key for stellar button POST request" );

            //       // making the fetch API GET request
            //       fetch(`${API_URL}`, {
            //         method: Method,
            //         // headers: config,
            //       })
            //       .then((response) => response.json())
            //       .then((data) => {
            //         // can display the data here in a table format
            //         if (Response_Status_200) {
            //           this.getEl().addEventListener('click', function(event) {
            //             const origin = window.location.origin;
            //             window.location.href = origin + `/editor/` + `${Response_Status_200}` + "?projectId=" + `${project_id}`;
            //           });
            //         }
            //         console.log("stellar-button GET request Data:", data);
            //         toast.info("Data is received successfully in the console.", successTheme );
            //       })
            //       .catch((error) => {
            //         console.log("stellar-button GET request error:", error);
            //       });
            //     }

            //     // if there is an api key, but no username or password
            //     else if (API_Key !== "") {
            //       console.log("Api key provided for stellar button GET request" );

            //       // making the fetch API GET request
            //       fetch(`${API_URL}`, {
            //         method: Method,
            //         headers: {
            //           "Content-Type": `${Content_Type}`,
            //           Authorization: `Bearer ${API_Key}`,
            //         },
            //       })
            //       .then((response) => response.json())
            //       .then((data) => {
            //         // can display the data here in a table format
            //         if (Response_Status_200) {
            //           this.getEl().addEventListener('click', function(event) {
            //             const origin = window.location.origin;
            //             window.location.href = origin + `/editor/` + `${Response_Status_200}` + "?projectId=" + `${project_id}`;
            //           });
            //         }
            //         console.log("stellar-button GET request Data:", data);
            //         toast.info("Data is received successfully in the console.", successTheme );
            //       })
            //       .catch((error) => {
            //         console.log("stellar-button GET request error:", error);
            //       });
            //     }

            //     // if there is no api key, but username or password or token
            //     if (Username !== "" && Password !== "") {
            //       console.log(
            //         "Username & Password provided for stellar button GET request"
            //       );

            //       // creating a token using this method from creatorFunctions.js file

            //       let token = authenticateUser(Username, Password);

            //       console.log("stellar-button GET request token:", token);

            //       // making the fetch API GET request
            //       fetch(`${API_URL}`, {
            //         method: Method,

            //         headers: {
            //           "Content-Type": `${Content_Type}`,
            //           Authorization: `${token}`,
            //         },
            //       })
            //       .then((response) => response.json())
            //       .then((data) => {
            //         // can display the data here in a table format
            //         if (Response_Status_200) {
            //           this.getEl().addEventListener('click', function(event) {
            //             const origin = window.location.origin;
            //             window.location.href = origin + `/editor/` + `${Response_Status_200}` + "?projectId=" + `${project_id}`;
            //           });
            //         }
            //         console.log("stellar-button GET request Data:", data);

            //         toast.info("Data is received successfully in the console.", successTheme );
            //       })
            //       .catch((error) => {
            //         console.log("stellar-button GET request error:", error);
            //       });
            //     }
            //   }

            //   // POST API call
            //   else if (Method === "POST") {
            //     console.log("stellar-button POST request");

            //     // if there is no api key, username or password
            //     if (Username === "" && Password === "" && API_Key === "") {
            //       console.log("No username, password & api key for stellar button POST request" );

            //       // making the fetch API POST request
            //       fetch(`${API_URL}`, {
            //         method: Method,
            //         headers: {
            //           "Content-Type": `${Content_Type}`,
            //         },
            //         body: Body,
            //       })
            //       .then((response) => response.json())
            //       .then((data) => {
            //         if (Response_Status_200) {
            //           this.getEl().addEventListener('click', function(event) {
            //             const origin = window.location.origin;
            //             window.location.href = origin + `/editor/` + `${Response_Status_200}` + "?projectId=" + `${project_id}`;
            //           });
            //         }
            //         // can display the data here in a table format
            //         console.log("stellar-button POST request Data:", data);

            //         toast.info("Data is received successfully in the console.", successTheme );
            //       })
            //       .catch((error) => {
            //         console.log("stellar-button POST request error:", error );
            //       });
            //     }

            //     // if there is an api key, but no username or password
            //     else if (API_Key !== "") {
            //       console.log("Api key provided for stellar button POST request" );

            //       // making the fetch API POST request
            //       fetch(`${API_URL}`, {
            //         method: Method,
            //         headers: {
            //           "Content-Type": `${Content_Type}`,
            //           Authorization: `Bearer ${API_Key}`,
            //         },
            //         body: Body,
            //       })
            //       .then((response) => response.json())
            //       .then((data) => {
            //         // can display the data here in a table format

            //         console.log("stellar-button POST request Data:", data);
            //         if (Response_Status_200) {
            //           this.getEl().addEventListener('click', function(event) {
            //             const origin = window.location.origin;
            //             window.location.href = origin + `/editor/` + `${Response_Status_200}` + "?projectId=" + `${project_id}`;
            //           });
            //         }

            //         toast.info("Data is received successfully in the console.", successTheme );
            //       })
            //       .catch((error) => {
            //         console.log("stellar-button POST request error:", error );
            //       });
            //     }

            //     // if there is no api key, but username or password or token
            //     else if (Username !== "" && Password !== "") {
            //       console.log("Username & Password provided for stellar button POST request" );

            //       // creating a token using this method from creatorFunctions.js file
            //       let token = authenticateUser(Username, Password);

            //       console.log("stellar-button POST request token:", token);

            //       // making the fetch API POST request
            //       fetch(`${API_URL}`, {
            //         method: Method,
            //         headers: {
            //           "Content-Type": `${Content_Type}`,
            //           Authorization: `${token}`,
            //         },
            //         body: Body,
            //       })
            //       .then((response) => response.json())
            //       .then((data) => {
            //         if (Response_Status_200) {
            //           this.getEl().addEventListener('click', function(event) {
            //             const origin = window.location.origin;
            //             window.location.href = origin + `/editor/` + `${Response_Status_200}` + "?projectId=" + `${project_id}`;
            //           });
            //         }
            //         // can display the data here in a table format

            //         console.log("stellar-button POST request Data:", data);

            //         toast.info("Data is received successfully in the console.", successTheme );
            //       })
            //       .catch((error) => {
            //         console.log("stellar-button POST request error:", error );
            //       });
            //     }
            //   }
            // }

            // if the parent component of this button is a form, then use the data from the form
            // if (parentComponent.attributes.tagName === "form") {
            //   // the form should have only 1 username and password field
            //   // find all the inputs in the form container and see their field types and do something based on them
            //   parentComponent.find("input").forEach((child) => {
            //       // if (child.attributes.tagName === 'input') {

            //       console.log("child:", child, "\ninput value:", child.view.el.value );

            //       // CONFIGURED only for inputs-types: email, text, text-node, password
            //       // checking if element is email or username or password and then extracting their value and trait Name

            //       if (child.attributes.attributes.type === "email") {
            //         formEmail = child.view.el.value;

            //         formEmailTraitName = child.attributes.attributes.name;
            //       }

            //       if (child.attributes.attributes.type === "text") {
            //         formUsername = child.view.el.value;

            //         formUsernameTraitName = child.attributes.attributes.name;
            //       }

            //       if (child.attributes.attributes.type === "password") {
            //         formPassword = child.view.el.value;

            //         formPasswordTraitName = child.attributes.attributes.name;
            //       }

            //       // preparing formBody (payload) depending of whether the Name traits are provided or not

            //       if (formEmailTraitName)
            //         formBody[formEmailTraitName] = formEmail;

            //       if (formUsernameTraitName)
            //         formBody[formUsernameTraitName] = formUsername;

            //       if (formPasswordTraitName)
            //         formBody[formPasswordTraitName] = formPassword;
            //     }

            //     // }
            //   );
            // }
          },
        },

        view: {
          // init({ model }) {

          //     // giving unique class to every stellar-button so that they don't affect others on getting styled

          //     const uniqueId = uniqid('stellar-button-');

          //     console.log('stellar-button ID for class:', uniqueId);

          //     // to give unique class so that one button does not affect the other

          //     // let modelAttributes = this.model.getAttributes();

          //     // console.log('modelAttributes:', modelAttributes);

          //     // modelAttributes.class += ` ${uniqueId}`;

          //     this.model.setAttributes({ class: `stellar-btn ${uniqueId}` });

          //     // console.log('modelAttributes:', modelAttributes);

          // this.listenTo(model, 'change:style', this.handlePropChange);

          // },

          onRender({ el, model }) {
            // el.classList.add(`${uniqueId}`);
            // let customStyle = this.model.get('styles');
            // console.log('customStyle:', customStyle);
            // if (customStyle) {
            //     el.setAttribute('styles', customStyle);
            // }
            // getting the current value of this trait
            // let buttonLabel = this.model.get('Button_label');
            // console.log('buttonLabel:', buttonLabel, 'el:', el);
            // // to let the button inner text be edited without opening the modal because the button is connected to the modal, so you cannot click ont he button to edit the inner text of it
            // if (buttonLabel) {
            //     el.innerHTML = buttonLabel;
            // }
            // // Listen for changes to the button style
            // el.addEventListener('change', () => {
            //     const updatedStyle = el.getAttribute('styles');
            //     console.log('updatedStyle:', updatedStyle);
            //     // Store the updated style in the model based on the unique ID
            //     this.model.set('styles', updatedStyle);
            // });
            // el.style.width = 'max-content';
            // el.style.padding = '0.5em';
            // el.style.borderRadius = '10px';
            // el.style.backgroundColor = 'blue';
            // el.style.border = 'none';
            // el.style.color = 'white';
            // el.style.cursor = 'pointer';
            // let innerPTag = el.querySelector('p');
            // innerPTag.style.margin = 'auto';
            // innerPTag.style.textAlign = 'center';
            // console.log('model', model);
            // if (model.attributes.type === 'stellar-button') {
            //   model.attributes.tagName = 'button';
            //   console.log('el, model', el, model);
            // }
          },

          // events: {
          //   click: function (event) {
          //     var internalTarget = this.model.get("InternalTarget");

          //     var navigate = this.model.get("Navigate");

          //     var externalTarget = this.model.get("ExternalTarget");

          //     console.log(
          //       "Navigating:",
          //       navigate,
          //       "\nInternal Target:",
          //       internalTarget,
          //       "\nExternal Target:",
          //       externalTarget
          //     );

          //     const project_id = window.location.search.split("=")[1];

          //     if (navigate && internalTarget && !externalTarget) {
          //       console.log("internalTarget", internalTarget);

          //       const origin = window.location.origin;

          //       window.location.href =
          //         origin +
          //         `/editor/` +
          //         `${internalTarget}` +
          //         "?projectId=" +
          //         `${project_id}`;
          //     }

          //     if (navigate && externalTarget && !internalTarget) {
          //       console.log("externalTarget", externalTarget);

          //       let origin = "https://";

          //       let modifiedUrl =
          //         externalTarget.includes("http://") ||
          //         externalTarget.includes("https://")
          //           ? externalTarget
          //           : origin + `${externalTarget}`;

          //       console.log("modifiedUrl:", modifiedUrl);

          //       let win = window.open(modifiedUrl, "_blank");

          //       win.focus();
          //     }

          //     // Button API functionality

          //     let parentComponent = this.model.parent();

          //     console.log("parent of stellar-button:", parentComponent);

          //     // if the parent component of this button is not a form, then use button's API traits

          //     if (parentComponent.attributes.tagName !== "form") {
          //       console.log(
          //         "this button is NOT inside a FORM; using API traits"
          //       );

          //       var apiUrl = this.model.get("API_URL");

          //       var method = this.model.get("Method");

          //       var body = this.model.get("Body");

          //       var contentType = this.model.get("Content_Type");

          //       var username = this.model.get("Username");

          //       var password = this.model.get("Password");

          //       var apiKey = this.model.get("API_Key");

          //       var responseStatus200 = this.model.get("Response_Status_200");

          //       var responseStatus201 = this.model.get("Response_Status_201");

          //       var responseStatus204 = this.model.get("Response_Status_204");

          //       var responseStatus400 = this.model.get("Response_Status_400");

          //       var responseStatus404 = this.model.get("Response_Status_404");

          //       var responseStatus500 = this.model.get("Response_Status_500");

          //       console.log("apiUrl:", apiUrl, "\nmethod:", method, "\nbody:", body, "\ncontentType:", contentType, "\nUsername:", username, "\nPassword:", password, "\napiKey:", apiKey, "\nresponseStatus200:", responseStatus200, "\nresponseStatus201:", responseStatus201, "\nresponseStatus204:", responseStatus204, "\nresponseStatus400:", responseStatus400, "\nresponseStatus404:", responseStatus404, "\nresponseStatus500:", responseStatus500 );

          //       // GET API call

          //       if (method === "GET") {
          //         console.log("stellar-button GET request");

          //         // creating a token using this method from creatorFunctions.js file

          //         // let token = authenticateUser(username, password);

          //         // console.log('stellar-button GET request token:', token);

          //         // let config = {

          //         //     "Authorization": `${token}`,

          //         // };

          //         // if there is no api key, username or password

          //         if (username === "" && password === "" && apiKey === "") {
          //           console.log(
          //             "No username, password & api key for stellar button POST request"
          //           );

          //           // making the fetch API GET request

          //           fetch(`${apiUrl}`, {
          //             method: method,

          //             // headers: config,
          //           })
          //             .then((response) => response.json())

          //             .then((data) => {
          //               // can display the data here in a table format
          //               if (responseStatus200) {
          //                 const origin = window.location.origin;

          //                 window.location.href =
          //                   origin +
          //                   `/editor/` +
          //                   `${responseStatus200}` +
          //                   "?projectId=" +
          //                   `${project_id}`;
          //               }
          //               console.log("stellar-button GET request Data:", data);

          //               toast.info(
          //                 "Data is received successfully in the console.",
          //                 successTheme
          //               );
          //             })

          //             .catch((error) => {
          //               console.log("stellar-button GET request error:", error);
          //             });
          //         }

          //         // if there is an api key, but no username or password

          //         if (apiKey !== "") {
          //           console.log(
          //             "Api key provided for stellar button GET request"
          //           );

          //           // making the fetch API GET request

          //           fetch(`${apiUrl}`, {
          //             method: method,

          //             headers: {
          //               "Content-Type": `${contentType}`,

          //               Authorization: `Bearer ${apiKey}`,
          //             },
          //           })
          //             .then((response) => response.json())

          //             .then((data) => {
          //               // can display the data here in a table format
          //               if (responseStatus200) {
          //                 const origin = window.location.origin;

          //                 window.location.href =
          //                   origin +
          //                   `/editor/` +
          //                   `${responseStatus200}` +
          //                   "?projectId=" +
          //                   `${project_id}`;
          //               }
          //               console.log("stellar-button GET request Data:", data);

          //               toast.info(
          //                 "Data is received successfully in the console.",
          //                 successTheme
          //               );
          //             })

          //             .catch((error) => {
          //               console.log("stellar-button GET request error:", error);
          //             });
          //         }

          //         // if there is no api key, but username or password or token

          //         if (username !== "" && password !== "") {
          //           console.log(
          //             "Username & Password provided for stellar button GET request"
          //           );

          //           // creating a token using this method from creatorFunctions.js file

          //           let token = authenticateUser(username, password);

          //           console.log("stellar-button GET request token:", token);

          //           // making the fetch API GET request

          //           fetch(`${apiUrl}`, {
          //             method: method,

          //             headers: {
          //               "Content-Type": `${contentType}`,

          //               Authorization: `${token}`,
          //             },
          //           })
          //             .then((response) => response.json())

          //             .then((data) => {
          //               // can display the data here in a table format
          //               if (responseStatus200) {
          //                 const origin = window.location.origin;

          //                 window.location.href =
          //                   origin +
          //                   `/editor/` +
          //                   `${responseStatus200}` +
          //                   "?projectId=" +
          //                   `${project_id}`;
          //               }
          //               console.log("stellar-button GET request Data:", data);

          //               toast.info(
          //                 "Data is received successfully in the console.",
          //                 successTheme
          //               );
          //             })

          //             .catch((error) => {
          //               console.log("stellar-button GET request error:", error);
          //             });
          //         }
          //       }

          //       // GET API call
          //       else if (method === "POST") {
          //         console.log("stellar-button POST request");

          //         // if there is no api key, username or password

          //         if (username === "" && password === "" && apiKey === "") {
          //           console.log(
          //             "No username, password & api key for stellar button POST request"
          //           );

          //           // making the fetch API POST request

          //           fetch(`${apiUrl}`, {
          //             method: method,

          //             headers: {
          //               "Content-Type": `${contentType}`,
          //             },

          //             body: body,
          //           })
          //             .then((response) => response.json())

          //             .then((data) => {
          //               if (responseStatus200) {
          //                 const origin = window.location.origin;

          //                 window.location.href =
          //                   origin +
          //                   `/editor/` +
          //                   `${responseStatus200}` +
          //                   "?projectId=" +
          //                   `${project_id}`;
          //               }
          //               // can display the data here in a table format

          //               console.log("stellar-button POST request Data:", data);

          //               toast.info(
          //                 "Data is received successfully in the console.",
          //                 successTheme
          //               );
          //             })

          //             .catch((error) => {
          //               console.log(
          //                 "stellar-button POST request error:",
          //                 error
          //               );
          //             });
          //         }

          //         // if there is an api key, but no username or password

          //         if (apiKey !== "") {
          //           console.log(
          //             "Api key provided for stellar button POST request"
          //           );

          //           // making the fetch API POST request

          //           fetch(`${apiUrl}`, {
          //             method: method,

          //             headers: {
          //               "Content-Type": `${contentType}`,

          //               Authorization: `Bearer ${apiKey}`,
          //             },

          //             body: body,
          //           })
          //             .then((response) => response.json())

          //             .then((data) => {
          //               // can display the data here in a table format

          //               console.log("stellar-button POST request Data:", data);
          //               if (responseStatus200) {
          //                 const origin = window.location.origin;

          //                 window.location.href =
          //                   origin +
          //                   `/editor/` +
          //                   `${responseStatus200}` +
          //                   "?projectId=" +
          //                   `${project_id}`;
          //               }

          //               toast.info(
          //                 "Data is received successfully in the console.",
          //                 successTheme
          //               );
          //             })

          //             .catch((error) => {
          //               console.log(
          //                 "stellar-button POST request error:",
          //                 error
          //               );
          //             });
          //         }

          //         // if there is no api key, but username or password or token

          //         if (username !== "" && password !== "") {
          //           console.log(
          //             "Username & Password provided for stellar button POST request"
          //           );

          //           // creating a token using this method from creatorFunctions.js file

          //           let token = authenticateUser(username, password);

          //           console.log("stellar-button POST request token:", token);

          //           // making the fetch API POST request

          //           fetch(`${apiUrl}`, {
          //             method: method,

          //             headers: {
          //               "Content-Type": `${contentType}`,

          //               Authorization: `${token}`,
          //             },

          //             body: body,
          //           })
          //             .then((response) => response.json())

          //             .then((data) => {
          //               if (responseStatus200) {
          //                 const origin = window.location.origin;

          //                 window.location.href =
          //                   origin +
          //                   `/editor/` +
          //                   `${responseStatus200}` +
          //                   "?projectId=" +
          //                   `${project_id}`;
          //               }
          //               // can display the data here in a table format

          //               console.log("stellar-button POST request Data:", data);

          //               toast.info(
          //                 "Data is received successfully in the console.",
          //                 successTheme
          //               );
          //             })

          //             .catch((error) => {
          //               console.log(
          //                 "stellar-button POST request error:",
          //                 error
          //               );
          //             });
          //         }
          //       }
          //     }

          //     // if the parent component of this button is a form, then use the data from the form

          //     if (parentComponent.attributes.tagName === "form") {
          //       var formApiUrl = this.model.get("Form_API_URL");

          //       var formMethod = this.model.get("Form_Method");

          //       var formContentType = this.model.get("Form_Content_Type");

          //       let formEmail;

          //       let formEmailTraitName; // using this variable to store the 'Name' trait value of its input field and passing it in form 'body' fetch API request

          //       let formUsername;

          //       let formUsernameTraitName; // using this variable to store the 'Name' trait value of its input field and passing it in form 'body' fetch API request

          //       let formPassword;

          //       let formPasswordTraitName; // using this variable to store the 'Name' trait value of its input field and passing it in form 'body' fetch API request

          //       let formBody = {}; // creating payload for 'Body' key

          //       // the form should have only 1 username and password field

          //       // find all the inputs in the form container and see their field types and do something based on them

          //       parentComponent.find("input").forEach(
          //         (child) => {
          //           // if (child.attributes.tagName === 'input') {

          //           console.log(
          //             "child:",
          //             child,
          //             "\ninput value:",
          //             child.view.el.value
          //           );

          //           // CONFIGURED only for inputs-types: email, text, text-node, password

          //           // checking if element is email or username or password and then extracting their value and trait Name

          //           if (child.attributes.attributes.type === "email") {
          //             formEmail = child.view.el.value;

          //             formEmailTraitName = child.attributes.attributes.name;
          //           }

          //           if (child.attributes.attributes.type === "text") {
          //             formUsername = child.view.el.value;

          //             formUsernameTraitName = child.attributes.attributes.name;
          //           }

          //           if (child.attributes.attributes.type === "password") {
          //             formPassword = child.view.el.value;

          //             formPasswordTraitName = child.attributes.attributes.name;
          //           }

          //           // preparing formBody (payload) depending of whether the Name traits are provided or not

          //           if (formEmailTraitName)
          //             formBody[formEmailTraitName] = formEmail;

          //           if (formUsernameTraitName)
          //             formBody[formUsernameTraitName] = formUsername;

          //           if (formPasswordTraitName)
          //             formBody[formPasswordTraitName] = formPassword;
          //         }

          //         // }
          //       );

          //       // let allInputs = parentComponent.find('input');

          //       // console.log('allInputs:', allInputs);

          //       console.log(
          //         "formApiUrl:",
          //         formApiUrl,
          //         "\nformMethod:",
          //         formMethod,
          //         "\nformContentType:",
          //         formContentType,
          //         "\nformEmail:",
          //         formEmail,
          //         "\nformUsername:",
          //         formUsername,
          //         "\nformPassword:",
          //         formPassword,
          //         "\nformBody (payload):",
          //         formBody
          //       );

          //       // making a GET request

          //       if (formMethod === "GET") {
          //         // if there is no api key, but formUsername or formPassword or token

          //         if (formUsername !== "" && formPassword !== "") {
          //           console.log(
          //             "Username & Password provided for stellar button GET request"
          //           );

          //           // creating a token using this method from creatorFunctions.js file

          //           let token = authenticateUser(formUsername, formPassword);

          //           console.log(
          //             "stellar-button inside form GET request token:",
          //             token
          //           );

          //           // making the fetch API GET request

          //           fetch(`${formApiUrl}`, {
          //             method: formMethod,

          //             headers: {
          //               "Content-Type": `${formContentType}`,

          //               Authorization: `${token}`,
          //             },
          //           })
          //             .then((response) => response.json())

          //             .then((data) => {
          //               if (responseStatus200) {
          //                 const origin = window.location.origin;

          //                 window.location.href =
          //                   origin +
          //                   `/editor/` +
          //                   `${responseStatus200}` +
          //                   "?projectId=" +
          //                   `${project_id}`;
          //               }
          //               // can display the data here in a table format

          //               console.log(
          //                 "stellar-button form GET request Data:",
          //                 data
          //               );

          //               toast.info(
          //                 "Data is received successfully in the console.",
          //                 successTheme
          //               );
          //             })

          //             .catch((error) => {
          //               console.log(
          //                 "stellar-button form GET request error:",
          //                 error
          //               );
          //             });
          //         }
          //       }

          //       // making a POST request

          //       if (formMethod === "POST") {
          //         // if there is no api key, but formUsername or formPassword or token

          //         if (
          //           formUsername !== undefined &&
          //           formPassword !== undefined
          //         ) {
          //           console.log(
          //             "Username & Password provided for stellar button POST request"
          //           );

          //           // creating a token using this method from creatorFunctions.js file

          //           let token = authenticateUser(formUsername, formPassword);

          //           console.log(
          //             "stellar-button inside form, POST request token:",
          //             token
          //           );

          //           // making the fetch API POST request

          //           fetch(`${formApiUrl}`, {
          //             method: formMethod,

          //             headers: {
          //               "Content-Type": `${formContentType}`,

          //               Authorization: `${token}`,
          //             },

          //             body:
          //               formContentType === "application/json"
          //                 ? JSON.stringify(formBody)
          //                 : formBody,
          //           })
          //             .then((response) => response.json())

          //             .then((data) => {
          //               // can display the data here in a table format
          //               if (responseStatus200) {
          //                 const origin = window.location.origin;

          //                 window.location.href =
          //                   origin +
          //                   `/editor/` +
          //                   `${responseStatus200}` +
          //                   "?projectId=" +
          //                   `${project_id}`;
          //               }
          //               console.log(
          //                 "stellar-button form POST request Data:",
          //                 data
          //               );

          //               toast.info(
          //                 "Data is received successfully in the console.",
          //                 successTheme
          //               );
          //             })

          //             .catch((error) => {
          //               console.log(
          //                 "stellar-button form POST request error:",
          //                 error
          //               );
          //             });
          //         }

          //         // if there is no api key, but formEmail or formPassword or token

          //         if (formEmail !== undefined && formPassword !== undefined) {
          //           console.log(
          //             "Username & Password provided for stellar button POST request"
          //           );

          //           // creating a token using this method from creatorFunctions.js file

          //           let token = authenticateUser(formEmail, formPassword);

          //           console.log(
          //             "stellar-button inside form, POST request token:",
          //             token
          //           );

          //           // making the fetch API POST request

          //           fetch(`${formApiUrl}`, {
          //             method: formMethod,

          //             headers: {
          //               "Content-Type": `${formContentType}`,

          //               Authorization: `${token}`,
          //             },

          //             body:
          //               formContentType === "application/json"
          //                 ? JSON.stringify(formBody)
          //                 : formBody,
          //           })
          //             .then((response) => response.json())

          //             .then((data) => {
          //               if (responseStatus200) {
          //                 const origin = window.location.origin;

          //                 window.location.href =
          //                   origin +
          //                   `/editor/` +
          //                   `${responseStatus200}` +
          //                   "?projectId=" +
          //                   `${project_id}`;
          //               }

          //               console.log(
          //                 "stellar-button form POST request Data:",
          //                 data
          //               );

          //               toast.info(
          //                 "Data is received successfully in the console.",
          //                 successTheme
          //               );
          //             })

          //             .catch((error) => {
          //               console.log(
          //                 "stellar-button form POST request error:",
          //                 error
          //               );
          //             });
          //         }
          //       }
          //     }
          //   },
          // },
        },
      });

      editor.DomComponents.addType("rich_text_area", {
        model: {
          defaults: {
            tagName: "div",
            type: "rich_text_area",
            script: function () {
              console.log("this", this);

              console.log("this id:::::::", this.id);

              const initLib = async function (id) {
                try {
                  const container = document.getElementById(id);
                  const quill = new Quill(container, {
                    modules: {
                      toolbar: [
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        ["bold", "italic", "underline", "strike"],
                        [{ list: "bullet" }, { list: "ordered" }],
                        ["link"],
                        [{ align: [] }],
                        [{ color: [] }, { background: [] }],
                      ],
                    },
                    placeholder: "",
                    theme: "snow", // or 'bubble'
                  });

                  quill.on("text-change", () => {
                    const delta = quill.getContents();

                    localStorage.setItem(
                      `redslingRichText_${id}`,
                      JSON.stringify(delta)
                    );
                  });

                  let richTextContent = localStorage.getItem(
                    `redslingRichText_${id}`
                  );
                  if (richTextContent) {
                    quill.setContents(JSON.parse(richTextContent));
                  }
                } catch (e) {
                  console.error(e);
                }
              };

              if (typeof Quill == "undefined") {
                console.log("this undefined case::::::::", this);
                const script = document.createElement("script");
                script.onload = initLib;
                script.src =
                  "https://cdn.jsdelivr.net/npm/quill@2.0.0-rc.2/dist/quill.js";
                document.body.appendChild(script);
              } else {
                console.log("InitLib");
                initLib(this.id);
              }
            },
            attributes: {
              // id: "radio_regular_form",
              class: "rich_text_area",
            },

            content: `<div id="rich_text_area_child"></div>`,

            traits: [],
          },

          // removed() {

          // },
        },
      });

      editor.DomComponents.addType("rich_text_area_container", {
        model: {
          defaults: {
            tagName: "div",
            type: "rich_text_area_container",

            attributes: {
              // id: "radio_regular_form",
              class: "rich_text_area_container",
            },

            script: function (props) {
              // For LB download manipulation
              // checking if the url or script is not running in the redsling editor, only then run the script to plot checkboxes
              let idEl = this.id;
              const urlDownload = window.top.location.href;
              const isSubstringPresent =
                (urlDownload.indexOf("/editor/") !== -1 &&
                  urlDownload.indexOf("?projectId=") !== -1) ||
                urlDownload.indexOf("/large_preview/") !== -1 ||
                urlDownload.indexOf("/tab_preview/") !== -1 ||
                urlDownload.indexOf("/mobile_preview/") !== -1 ||
                urlDownload.indexOf("/fragment_editor/") !== -1;
              if (!isSubstringPresent) {
                setInterval(function () {
                  Object.keys(props).forEach(function (key) {
                    if (window[`${key}${idEl}`]) {
                      props[key] = window[`${key}${idEl}`];
                    }
                  });
                }, 500);
              } 

              const Hide_ToolbarRef = props.Hide_Toolbar;
              const readOnly = props.read_only;

              console.log("readOnly:::::::::", readOnly);

              const container = this;

              console.log("container", container);

              let initialToolbar =
                container.getElementsByClassName("ql-toolbar")[0];
              console.log("initialToolbar.......", initialToolbar);

              let qlEditor = container.querySelector(".ql-editor");

              console.log("qlEditor:::::::", qlEditor);

              if (initialToolbar) {
                console.log("here123");
                if (Hide_ToolbarRef === "trueHideQuill") {
                  console.log("here234");
                  initialToolbar.style.display = "none";
                } else {
                  initialToolbar.style.display = "block";
                }
              }

              if (qlEditor) {
                if (readOnly === "true" || readOnly === true) {
                  qlEditor.style.pointerEvents = "none";
                } else {
                  qlEditor.style.pointerEvents = "auto";
                }
              }
            },

            components: [
              {
                type: "rich_text_area",
                tagName: "div",
              },
            ],

            traits: [],
          },

          // removed() {

          // },
        },
      });

      editor.DomComponents.addType("autocomplete_custom", {
        model: {
          defaults: {
            tagName: "span",
            attributes: {
              name: "autocomplete_custom",
              class: "autocomplete_custom",
            },

            components: [
              {
                tagName: "input",
                type: "default",
                // script: dropdownListScript,
                attributes: {
                  // id: "input_select",
                  name: "autocomplete_custom_el",
                  class: "autocomplete_custom_el",
                  placeholder: "Enter a text",
                },
                style: {
                  width: "100%",
                  padding: "0.5rem",
                  "border-radius": "10px",
                },
              },
              {
                tagName: "div",
                type: "default",
                // script: dropdownListScript,
                attributes: {
                  // id: "input_select",
                  name: "autocomplete_custom_div",
                  class: "autocomplete_custom_div",
                },
                style: {
                  width: "100%",
                  padding: "0.5rem",
                  "border-radius": "10px",
                  "margin-top": "1rem",
                  "box-shadow": "-6px 9px 23px -9px rgba(0,0,0,0.75)",
                },
              },
            ],

            CustomUrl: "",
            BaseUrl: "",
            APIPath: "",
            BearerToken: "",
            Username: "",
            Password: "",
            MoreHeaders: "",
            APIMethod: "GET",
            APIBody: "",
            ArrOfObjKyename: "",
            NestedKeyname: "",

            traits: [
              {
                type: "text",
                name: "CustomUrl",
                label: "Custom URL",
                changeProp: true,
              },
              {
                type: "select",
                name: "BaseUrl",
                label: "Base URL",
                options: [
                  { id: "baseUrl1", name: "Base URL #1" },
                  { id: "baseUrl2", name: "Base URL #2" },
                  { id: "baseUrl3", name: "Base URL #3" },
                  { id: "baseUrl4", name: "Base URL #4" },
                  { id: "baseUrl5", name: "Base URL #5" },
                  { id: "null", name: "No Base URL" },
                ],
                changeProp: true,
              },
              {
                type: "text",
                name: "APIPath",
                label: "API Path",
                placeholder: "Don't start with '/'",
                changeProp: true,
              },
              {
                type: "text",
                name: "BearerToken",
                label: "Bearer Token",
                placeholder: "No bearer token provided",
                changeProp: true,
              },
              {
                type: "text",
                name: "Username",
                label: "Username",
                changeProp: true,
              },
              {
                type: "text",
                name: "Password",
                label: "Password",
                changeProp: true,
              },
              {
                type: "text",
                name: "MoreHeaders",
                label: "More Headers",
                placeholder: "k1:v1,k2:v2,k3:v3,...",
                changeProp: true,
              },
              {
                type: "select",
                name: "APIMethod",
                label: "API Method",
                options: [
                  { id: "GET", name: "Get" },
                  { id: "POST", name: "Post" },
                ],
                changeProp: true,
              },
              {
                type: "text",
                name: "APIBody",
                label: "API Body",
                placeholder: "Json format",
                changeProp: true,
              },
              {
                type: "text",
                name: "ArrOfObjKyename",
                label: "Keyname From Array Of Object",
                placeholder: "[ {k1:v1}, {k1:v2} ]",
                changeProp: true,
              },
              {
                type: "text",
                name: "NestedKeyname",
                label: "Keyname For Nested Structure",
                placeholder: "{ k1: [{...}, {...}] }",
                changeProp: true,
              },
            ],

            changeProp: true,

            "script-props": [
              "CustomUrl",
              "BaseUrl",
              "APIPath",
              "BearerToken",
              "Username",
              "Password",
              "MoreHeaders",
              "APIMethod",
              "APIBody",
              "ArrOfObjKyename",
              "NestedKeyname",
            ],
          },

          init() {
            this.on("change:CustomUrl", this.handlePropChange);
            this.on("change:BaseUrl", this.handlePropChange);
            this.on("change:APIPath", this.handlePropChange);
            this.on("change:BearerToken", this.handlePropChange);
            this.on("change:Username", this.handlePropChange);
            this.on("change:Password", this.handlePropChange);
            this.on("change:MoreHeaders", this.handlePropChange);
            this.on("change:APIMethod", this.handlePropChange);
            this.on("change:APIBody", this.handlePropChange);
            this.on("change:ArrOfObjKyename", this.handlePropChange);
            this.on("change:NestedKeyname", this.handlePropChange);
          },

          async handlePropChange() {
            const {
              CustomUrl,
              BaseUrl,
              APIPath,
              BearerToken,
              Username,
              Password,
              MoreHeaders,
              APIMethod,
              APIBody,
              ArrOfObjKyename,
              NestedKeyname,
            } = this.props();
            console.log(
              "this, CustomUrl, BaseUrl, APIPath, BearerToken, Username, Password, MoreHeaders, APIMethod, APIBody, ArrOfObjKyename, NestedKeyname",
              this,
              APIPath,
              BearerToken,
              Username,
              Password,
              MoreHeaders,
              APIMethod,
              APIBody,
              ArrOfObjKyename,
              NestedKeyname
            );
            console.log("baseUrlData", baseUrlData);

            // to rename a variable because API var is getting used in many places in this block scope
            let API;
            if (APIPath) {
              API = APIPath;
            }

            // helper function to check if a variable is an array-of-objects or not
            function isArrayofObjects(variable) {
              // Check if the variable is an array
              if (!Array.isArray(variable)) {
                return false;
              }

              // Check if all elements in the array are objects
              for (const element of variable) {
                if (
                  typeof element !== "object" ||
                  Array.isArray(element) ||
                  element === null
                ) {
                  return false;
                }
              }
              return true;
            }

            // helper function to get value of a key in nested object
            function findKeyValue(obj, key) {
              if (obj.hasOwnProperty(key)) {
                // If the current object has the key, return its value
                return obj[key];
              }

              for (var prop in obj) {
                if (obj.hasOwnProperty(prop) && typeof obj[prop] === "object") {
                  // If the current property is an object, recursively search within it
                  var result = findKeyValue(obj[prop], key);
                  if (result !== undefined) {
                    return result;
                  }
                }
              }
              // Key not found in the object
              return undefined;
            }

            const container = this;
            let apiData;
            console.log("container", container);
            const dropdown = container.find("input.autocomplete_custom_el")[0];
            const optionDiv = container.find("div.autocomplete_custom_div")[0];
            let optionWrapperEl = optionDiv.getEl();
            console.log("optionDiv", optionDiv);

            let inputEl = dropdown.getEl();
            console.log("inputEl", inputEl);

            let currentValFromInput;

            inputEl.addEventListener("change", function (event) {
              currentValFromInput = event.target.value;
              if (apiData && currentValFromInput) {
                console.log("apiData1234::::", apiData);
                if (isArrayofObjects(apiData)) {
                  if (ArrOfObjKyename) {
                    console.log("ArrOfObjKyename1234::::", ArrOfObjKyename);
                    optionDiv.components("");
                    const searchTerm = currentValFromInput.toLowerCase();
                    const optionsStartingWithSearchTerm = [];
                    optionWrapperEl.style.display = "block";

                    apiData.forEach((opt) => {
                      console.log("searchTerm::::::::::", searchTerm);
                      console.log("opt1234:::::::::", opt);
                      console.log("opt.ArrOfObjKyename", opt.ArrOfObjKyename);
                      if (
                        opt[`${ArrOfObjKyename}`] &&
                        opt[`${ArrOfObjKyename}`]
                          .toLowerCase()
                          .startsWith(searchTerm)
                      ) {
                        optionsStartingWithSearchTerm.push(opt);
                      }
                    });

                    console.log(
                      "optionsStartingWithSearchTerm:::::::::",
                      optionsStartingWithSearchTerm
                    );

                    if (optionsStartingWithSearchTerm.length) {
                      optionsStartingWithSearchTerm.sort((a, b) =>
                        a[`${ArrOfObjKyename}`].localeCompare(
                          b[`${ArrOfObjKyename}`],
                          undefined,
                          {
                            sensitivity: "base",
                          }
                        )
                      );

                      optionsStartingWithSearchTerm.forEach((opt) => {
                        let optionEle = {
                          tagName: "p",
                          type: "default",
                          attributes: {
                            value: `${opt[`${ArrOfObjKyename}`]}`,
                          },
                          content: `${opt[`${ArrOfObjKyename}`]}`,
                          style: {
                            width: "100%",
                            padding: "0.5rem",
                            "border-bottom": "2px solid gray",
                          },
                        };

                        optionDiv.append(optionEle);
                      });

                      let allPTags = optionWrapperEl.querySelectorAll("p");
                      if (allPTags.length) {
                        allPTags.forEach((pTag) => {
                          console.log("pTag::::::", pTag);
                          pTag.addEventListener("click", function (e) {
                            console.log(
                              "e from pTag::::::::",
                              e.target.textContent
                            );
                            inputEl.setAttribute(
                              "data_value",
                              e.target.textContent
                            );
                            inputEl.setAttribute("keyname", ArrOfObjKyename);
                            inputEl.value = e.target.textContent;
                            optionWrapperEl.style.display = "none";
                          });
                        });
                      }
                    } else {
                      let optionEle = {
                        tagName: "p",
                        type: "default",
                        attributes: {
                          value: `No Results Found!!!`,
                        },
                        content: `No Results Found!!!`,
                      };

                      optionDiv.append(optionEle);
                      inputEl.removeAttribute("data_value");
                      inputEl.removeAttribute("keyname");
                    }
                  }
                }
              }
            });
            // -------- Getting API Data (GET method)

            // if CustomUrl is provided, use only that
            if (
              CustomUrl &&
              (!BaseUrl || BaseUrl === "null") &&
              APIMethod === "GET"
            ) {
              console.log("CustomUrl", CustomUrl);
              // if nothing is provided for auth
              if (!BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  headers: {},
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is provided but not api_key or usr+pwd
              else if (BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${BearerToken}`,
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token and api_key not provided but usr+pwd are provided
              else if (!BearerToken && Username && Password) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    Authorization: "Basic " + btoa(Username + ":" + Password),
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }
            }

            // if base url is the choice and provided
            else if (BaseUrl && API && !CustomUrl && APIMethod === "GET") {
              // concatenating base url and its remaining part
              let url, baseUrlBearerToken;
              if (BaseUrl === "baseUrl1") {
                url = baseUrl1 + API;
                baseUrlBearerToken = baseUrl1BearerToken;
              } else if (BaseUrl === "baseUrl2") {
                url = baseUrl2 + API;
                baseUrlBearerToken = baseUrl2BearerToken;
              } else if (BaseUrl === "baseUrl3") {
                url = baseUrl3 + API;
                baseUrlBearerToken = baseUrl3BearerToken;
              } else if (BaseUrl === "baseUrl4") {
                url = baseUrl4 + API;
                baseUrlBearerToken = baseUrl4BearerToken;
              } else if (BaseUrl === "baseUrl5") {
                url = baseUrl5 + API;
                baseUrlBearerToken = baseUrl5BearerToken;
              }
              console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

              // if nothing is provided for auth
              if (
                !BearerToken &&
                !baseUrlBearerToken &&
                !Username &&
                !Password
              ) {
                // add extra headers, if needed
                let config = {
                  headers: {},
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is provided but not usr+pwd
              else if (BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${BearerToken}`,
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a baseUrlBearerToken is provided but not Custom Bearer Token and usr+pwd
              else if (
                baseUrlBearerToken &&
                !BearerToken &&
                !Username &&
                !Password
              ) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${baseUrlBearerToken}`,
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config@:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is not provided but usr+pwd are provided
              else if (!BearerToken && Username && Password) {
                // add extra headers, if needed
                let config = {
                  headers: {
                    Authorization: "Basic " + btoa(Username + ":" + Password),
                  },
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }
            }

            // -------- Getting API Data (POST method)

            // if CustomUrl is provided, use only that
            else if (
              CustomUrl &&
              (!BaseUrl || BaseUrl === "null") &&
              APIMethod === "POST"
            ) {
              console.log("CustomUrl", CustomUrl);
              // if nothing is provided for auth
              if (!BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {},
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is provided but not api_key or usr+pwd
              else if (BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${BearerToken}`,
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token and api_key not provided but usr+pwd are provided
              else if (!BearerToken && Username && Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    Authorization: "Basic " + btoa(Username + ":" + Password),
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${CustomUrl}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }
            }

            // if base url is the choice and provided
            else if (BaseUrl && API && !CustomUrl && APIMethod === "POST") {
              // concatenating base url and its remaining part
              let url, baseUrlBearerToken;
              if (BaseUrl === "baseUrl1") {
                url = baseUrl1 + API;
                baseUrlBearerToken = baseUrl1BearerToken;
              } else if (BaseUrl === "baseUrl2") {
                url = baseUrl2 + API;
                baseUrlBearerToken = baseUrl2BearerToken;
              } else if (BaseUrl === "baseUrl3") {
                url = baseUrl3 + API;
                baseUrlBearerToken = baseUrl3BearerToken;
              } else if (BaseUrl === "baseUrl4") {
                url = baseUrl4 + API;
                baseUrlBearerToken = baseUrl4BearerToken;
              } else if (BaseUrl === "baseUrl5") {
                url = baseUrl5 + API;
                baseUrlBearerToken = baseUrl5BearerToken;
              }
              console.log("url, baseUrlBearerToken", url, baseUrlBearerToken);

              // if nothing is provided for auth
              if (
                !BearerToken &&
                !baseUrlBearerToken &&
                !Username &&
                !Password
              ) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {},
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is provided but not usr+pwd
              else if (BearerToken && !Username && !Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${BearerToken}`,
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a baseUrlBearerToken is provided but not Custom Bearer Token and usr+pwd
              else if (
                baseUrlBearerToken &&
                !BearerToken &&
                !Username &&
                !Password
              ) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${baseUrlBearerToken}`,
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config@:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }

              // if a bearer token is not provided but usr+pwd are provided
              else if (!BearerToken && Username && Password) {
                // add extra headers, if needed
                let config = {
                  method: "POST",
                  headers: {
                    Authorization: "Basic " + btoa(Username + ":" + Password),
                  },
                  body:
                    typeof APIBody === "object"
                      ? JSON.stringify(APIBody)
                      : APIBody,
                };

                if (MoreHeaders) {
                  // ['a:b', "c:d"]
                  // extracting headers from More_Headers trait
                  MoreHeaders.split(",").forEach((header) => {
                    let key, val;
                    [key, val] = header.split(":");
                    console.log("[key, val]:\n", key, val);

                    config.headers[key] = val;
                  });
                }

                console.log("config:", config);

                try {
                  // GET call to get the data
                  let response = await fetch(`${url}`, config);
                  let responseData = await response.json();
                  apiData = responseData;
                } catch (e) {
                  // statements
                  console.log(e);
                }
              }
            }
          },
        },

        view: {
          onRender({ el, model }) {
            console.log("el", el, el.tagName);
            if (
              el.getAttribute("data-gjs-type") === "autocomplete_custom" &&
              (el.tagName === "DIV" || el.tagName === "SPAN")
            ) {
              console.log("rendering", el);
              model.handlePropChange();
            }
          },
        },
      });

      const getAllComponents = (model, result = []) => {
        result.push(model);
        model.components().each((mod) => getAllComponents(mod, result));
        return result;
      };
    } catch (e) {
      console.log(e);
    }
  }, [previewDimsUnits]);

  return (
    <>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100vw",
          boxShadow: 6,
          padding: {
            md: "1vh 2vw",
            xs: "1vh 4vw",
            position: "fixed",
            top: "0",
            zIndex: 30,
            backgroundColor: "white",
          },
        }}>
        <Typography
          sx={{ cursor: "pointer", borderRight: "1px solid #27272a" }}>
          <img style={{ width: "5vw" }} src={redSlingLogo} />
        </Typography>

        <Typography
          sx={{
            color: "black",
            fontSize: "1rem",
            fontWeight: 400,
            marginTop: "1.2rem",
            marginLeft: "0.5rem",
          }}>
          Preview: {previewDimsUnits.name}
        </Typography>

        {/*<ButtonGroup variant="contained" aria-label="outlined button group"
          sx={{
            alignSelf: "flex-end",
            marginLeft: "auto",
            background: "transparent"
          }}>
          <Tooltip title="Laptop - w:100%, h:100%" arrow>
            <Button onClick={() => resizePreview("100%", "100%")} sx={{backgroundColor: "gray", paddingLeft: "2em", paddingRight: "2em"}}>Default</Button>
          </Tooltip>

          <Tooltip title="Laptop - w:1024px, h:100%" arrow>
            <Button onClick={() => resizePreview("1024px", "100%")} sx={{backgroundColor: "gray", paddingLeft: "2em", paddingRight: "2em"}}>L-1024px</Button>
          </Tooltip>

          <Tooltip title="Laptop - w1440px, h:100%" arrow>
            <Button onClick={() => resizePreview("1440px", "100%")} sx={{backgroundColor: "gray", paddingLeft: "2em", paddingRight: "2em"}}>L-1440px</Button>
          </Tooltip>

          <Tooltip title="Laptop - w:2560px, h:100%" arrow>
            <Button onClick={() => resizePreview("2560px", "100%")} sx={{backgroundColor: "gray", paddingLeft: "2em", paddingRight: "2em"}}>L-2560px</Button>
          </Tooltip>
        </ButtonGroup>*/}

        <div
          style={{
            alignSelf: "flex-end",
            marginLeft: "auto",
            background: "transparent",
          }}>
          {/* disable the button when editor is loading so that the user cannot change screens during editor initialization as it will give errors otherwise */}
          {!disableScreensBtn && (
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) => setAnchorEl(event.currentTarget)}>
              Screens
            </Button>
          )}

          {disableScreensBtn && (
            <Button
              disabled
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) => setAnchorEl(event.currentTarget)}>
              Screens
            </Button>
          )}

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}>
            <MenuItem
              onClick={() => setAnchorEl(null)}
              sx={{ paddingRight: "2em" }}>
              <Tooltip title="Default Browser Dimensions" arrow>
                <LaptopMacIcon
                  sx={{
                    color: "black",
                    marginRight: "5%",
                    "&:hover": { color: "orange" },
                  }}
                />
                <Button
                  onClick={() => resizePreview("100%", "100%", "Default")}
                  sx={{
                    backgroundColor: "transparent",
                    width: "max-content",
                    padding: "0",
                  }}>
                  Default
                </Button>
              </Tooltip>
            </MenuItem>

            <MenuItem
              onClick={() => setAnchorEl(null)}
              sx={{ paddingRight: "2em" }}>
              <Tooltip title="Laptop - w:1024px, h:100%" arrow>
                <LaptopMacIcon
                  sx={{
                    color: "black",
                    marginRight: "5%",
                    "&:hover": { color: "orange" },
                  }}
                />
                <Button
                  onClick={() =>
                    resizePreview("1024px", "100%", "Laptop1024px")
                  }
                  sx={{
                    backgroundColor: "transparent",
                    width: "max-content",
                    padding: "0",
                  }}>
                  Laptop - 1024px
                </Button>
              </Tooltip>
            </MenuItem>

            <MenuItem
              onClick={() => setAnchorEl(null)}
              sx={{ paddingRight: "2em" }}>
              <Tooltip title="MacBook Air - w:1280px, h:100%" arrow>
                <LaptopMacIcon
                  sx={{
                    color: "black",
                    marginRight: "5%",
                    "&:hover": { color: "orange" },
                  }}
                />
                <Button
                  onClick={() => resizePreview("1280px", "100%", "MacBook Air")}
                  sx={{
                    backgroundColor: "transparent",
                    width: "max-content",
                    padding: "0",
                  }}>
                  MacBook Air - 1280px
                </Button>
              </Tooltip>
            </MenuItem>

            <MenuItem
              onClick={() => setAnchorEl(null)}
              sx={{ paddingRight: "2em" }}>
              <Tooltip title="Laptop - w:1440px, h:100%" arrow>
                <LaptopMacIcon
                  sx={{
                    color: "black",
                    marginRight: "5%",
                    "&:hover": { color: "orange" },
                  }}
                />
                <Button
                  onClick={() =>
                    resizePreview("1440px", "100%", "Laptop1440px")
                  }
                  sx={{
                    backgroundColor: "transparent",
                    width: "max-content",
                    padding: "0",
                  }}>
                  Laptop - 1440px
                </Button>
              </Tooltip>
            </MenuItem>

            <MenuItem
              onClick={() => setAnchorEl(null)}
              sx={{ paddingRight: "2em" }}>
              <Tooltip title="MacBook Pro 14 - w:1512px, h:100%" arrow>
                <LaptopMacIcon
                  sx={{
                    color: "black",
                    marginRight: "5%",
                    "&:hover": { color: "orange" },
                  }}
                />
                <Button
                  onClick={() =>
                    resizePreview("1512px", "100%", "MacBook Pro 14")
                  }
                  sx={{
                    backgroundColor: "transparent",
                    width: "max-content",
                    padding: "0",
                  }}>
                  MacBook Pro 14 - 1512px
                </Button>
              </Tooltip>
            </MenuItem>

            <MenuItem
              onClick={() => setAnchorEl(null)}
              sx={{ paddingRight: "2em" }}>
              <Tooltip title="MacBook Pro 16 - w:1728px, h:100%" arrow>
                <LaptopMacIcon
                  sx={{
                    color: "black",
                    marginRight: "5%",
                    "&:hover": { color: "orange" },
                  }}
                />
                <Button
                  onClick={() =>
                    resizePreview("1728px", "100%", "MacBook Pro 16")
                  }
                  sx={{
                    backgroundColor: "transparent",
                    width: "max-content",
                    padding: "0",
                  }}>
                  MacBook Pro 16 - 1728px
                </Button>
              </Tooltip>
            </MenuItem>

            <MenuItem
              onClick={() => setAnchorEl(null)}
              sx={{ paddingRight: "2em" }}>
              <Tooltip title="Desktop - w:2560px, h:100%" arrow>
                <LaptopMacIcon
                  sx={{
                    color: "black",
                    marginRight: "5%",
                    "&:hover": { color: "orange" },
                  }}
                />
                <Button
                  onClick={() =>
                    resizePreview("2560px", "100%", "Desktop2560px")
                  }
                  sx={{
                    backgroundColor: "transparent",
                    width: "max-content",
                    padding: "0",
                  }}>
                  Desktop - 2560px
                </Button>
              </Tooltip>
            </MenuItem>

            <Divider />

            <MenuItem
              onClick={() => setAnchorEl(null)}
              sx={{ paddingRight: "2em" }}>
              <Tooltip title="Mobile - w:480px, h:667px" arrow>
                <PhoneAndroidIcon
                  sx={{
                    color: "black",
                    marginRight: "5%",
                    "&:hover": { color: "orange" },
                  }}
                />
                <Button
                  onClick={() => resizePreview("480px", "667px", "Mobile")}
                  sx={{
                    backgroundColor: "transparent",
                    width: "max-content",
                    padding: "0",
                  }}>
                  Mobile - 480px
                </Button>
              </Tooltip>
            </MenuItem>

            <MenuItem
              onClick={() => setAnchorEl(null)}
              sx={{ paddingRight: "2em" }}>
              <Tooltip title="Android - w:360px, h:667px" arrow>
                <PhoneAndroidIcon
                  sx={{
                    color: "black",
                    marginRight: "5%",
                    "&:hover": { color: "orange" },
                  }}
                />
                <Button
                  onClick={() => resizePreview("360px", "667px", "Android")}
                  sx={{
                    backgroundColor: "transparent",
                    width: "max-content",
                    padding: "0",
                  }}>
                  Android - 360px
                </Button>
              </Tooltip>
            </MenuItem>

            <MenuItem
              onClick={() => setAnchorEl(null)}
              sx={{ paddingRight: "2em" }}>
              <Tooltip title="iPhone SE - w:375px, h:667px" arrow>
                <PhoneAndroidIcon
                  sx={{
                    color: "black",
                    marginRight: "5%",
                    "&:hover": { color: "orange" },
                  }}
                />
                <Button
                  onClick={() => resizePreview("375px", "667px", "iPhoneSE")}
                  sx={{
                    backgroundColor: "transparent",
                    width: "max-content",
                    padding: "0",
                  }}>
                  iPhone SE - 375px, 667px
                </Button>
              </Tooltip>
            </MenuItem>

            <MenuItem
              onClick={() => setAnchorEl(null)}
              sx={{ paddingRight: "2em" }}>
              <Tooltip title="iPhone XR - w:414px, h:896px" arrow>
                <PhoneAndroidIcon
                  sx={{
                    color: "black",
                    marginRight: "5%",
                    "&:hover": { color: "orange" },
                  }}
                />
                <Button
                  onClick={() => resizePreview("414px", "896px", "iPhoneXR")}
                  sx={{
                    backgroundColor: "transparent",
                    width: "max-content",
                    padding: "0",
                  }}>
                  iPhone XR - 414px, 896px
                </Button>
              </Tooltip>
            </MenuItem>

            <MenuItem
              onClick={() => setAnchorEl(null)}
              sx={{ paddingRight: "2em" }}>
              <Tooltip title="iPhone 12 Pro - w:390px, h:844px" arrow>
                <PhoneAndroidIcon
                  sx={{
                    color: "black",
                    marginRight: "5%",
                    "&:hover": { color: "orange" },
                  }}
                />
                <Button
                  onClick={() =>
                    resizePreview("390px", "844px", "iPhone 12 Pro")
                  }
                  sx={{
                    backgroundColor: "transparent",
                    width: "max-content",
                    padding: "0",
                  }}>
                  iPhone 12 Pro - 390px, 844px
                </Button>
              </Tooltip>
            </MenuItem>

            <MenuItem
              onClick={() => setAnchorEl(null)}
              sx={{ paddingRight: "2em" }}>
              <Tooltip title="iPhone 13 Mini - w:375px, h:812px" arrow>
                <PhoneAndroidIcon
                  sx={{
                    color: "black",
                    marginRight: "5%",
                    "&:hover": { color: "orange" },
                  }}
                />
                <Button
                  onClick={() =>
                    resizePreview("375px", "812px", "iPhone 13 Mini")
                  }
                  sx={{
                    backgroundColor: "transparent",
                    width: "max-content",
                    padding: "0",
                  }}>
                  iPhone 13 Mini - 375px, 844px
                </Button>
              </Tooltip>
            </MenuItem>

            <MenuItem
              onClick={() => setAnchorEl(null)}
              sx={{ paddingRight: "2em" }}>
              <Tooltip title="iPhone 14 Plus - w:428px, h:926px" arrow>
                <PhoneAndroidIcon
                  sx={{
                    color: "black",
                    marginRight: "5%",
                    "&:hover": { color: "orange" },
                  }}
                />
                <Button
                  onClick={() =>
                    resizePreview("428px", "926px", "iPhone 14 Plus")
                  }
                  sx={{
                    backgroundColor: "transparent",
                    width: "max-content",
                    padding: "0",
                  }}>
                  iPhone 14 Plus - 428px, 926px
                </Button>
              </Tooltip>
            </MenuItem>

            <MenuItem
              onClick={() => setAnchorEl(null)}
              sx={{ paddingRight: "2em" }}>
              <Tooltip title="iPhone 14 Pro Max - w:430px, h:932px" arrow>
                <PhoneAndroidIcon
                  sx={{
                    color: "black",
                    marginRight: "5%",
                    "&:hover": { color: "orange" },
                  }}
                />
                <Button
                  onClick={() =>
                    resizePreview("430px", "932px", "iPhone 14 Pro Max")
                  }
                  sx={{
                    backgroundColor: "transparent",
                    width: "max-content",
                    padding: "0",
                  }}>
                  iPhone 14 Pro Max - 430px, 932px
                </Button>
              </Tooltip>
            </MenuItem>

            <MenuItem
              onClick={() => setAnchorEl(null)}
              sx={{ paddingRight: "2em" }}>
              <Tooltip title="Pixel 7 - w:412px, h:915px" arrow>
                <PhoneAndroidIcon
                  sx={{
                    color: "black",
                    marginRight: "5%",
                    "&:hover": { color: "orange" },
                  }}
                />
                <Button
                  onClick={() => resizePreview("412px", "915px", "Pixel 7")}
                  sx={{
                    backgroundColor: "transparent",
                    width: "max-content",
                    padding: "0",
                  }}>
                  Pixel 7 - 412px, 915px
                </Button>
              </Tooltip>
            </MenuItem>

            <MenuItem
              onClick={() => setAnchorEl(null)}
              sx={{ paddingRight: "2em" }}>
              <Tooltip title="Samsung Galaxy S8+ - w:360px, h:740px" arrow>
                <PhoneAndroidIcon
                  sx={{
                    color: "black",
                    marginRight: "5%",
                    "&:hover": { color: "orange" },
                  }}
                />
                <Button
                  onClick={() => resizePreview("360px", "740px", "Galaxy S8")}
                  sx={{
                    backgroundColor: "transparent",
                    width: "max-content",
                    padding: "0",
                  }}>
                  Samsung Galaxy S8+ - 360px, 740px
                </Button>
              </Tooltip>
            </MenuItem>

            <MenuItem
              onClick={() => setAnchorEl(null)}
              sx={{ paddingRight: "2em" }}>
              <Tooltip
                title="Samsung Galaxy S20 Ultra - w:412px, h:915px"
                arrow>
                <PhoneAndroidIcon
                  sx={{
                    color: "black",
                    marginRight: "5%",
                    "&:hover": { color: "orange" },
                  }}
                />
                <Button
                  onClick={() =>
                    resizePreview("412px", "915px", "Galaxy S20 Ultra")
                  }
                  sx={{
                    backgroundColor: "transparent",
                    width: "max-content",
                    padding: "0",
                  }}>
                  Samsung Galaxy S20 Ultra - 412px, 915px
                </Button>
              </Tooltip>
            </MenuItem>

            <MenuItem
              onClick={() => setAnchorEl(null)}
              sx={{ paddingRight: "2em" }}>
              <Tooltip title="Galaxy Fold - w:280px, h:653px" arrow>
                <PhoneAndroidIcon
                  sx={{
                    color: "black",
                    marginRight: "5%",
                    "&:hover": { color: "orange" },
                  }}
                />
                <Button
                  onClick={() => resizePreview("280px", "653px", "Galaxy Fold")}
                  sx={{
                    backgroundColor: "transparent",
                    width: "max-content",
                    padding: "0",
                  }}>
                  Galaxy Fold - 280px, 653px
                </Button>
              </Tooltip>
            </MenuItem>

            <MenuItem
              onClick={() => setAnchorEl(null)}
              sx={{ paddingRight: "2em" }}>
              <Tooltip title="Samsung Galaxy A51/71 - w:412px, h:914px" arrow>
                <PhoneAndroidIcon
                  sx={{
                    color: "black",
                    marginRight: "5%",
                    "&:hover": { color: "orange" },
                  }}
                />
                <Button
                  onClick={() =>
                    resizePreview("412px", "914px", "Galaxy A51/71")
                  }
                  sx={{
                    backgroundColor: "transparent",
                    width: "max-content",
                    padding: "0",
                  }}>
                  Samsung Galaxy A51/71 - 412px, 914px
                </Button>
              </Tooltip>
            </MenuItem>
          </Menu>
        </div>
      </Grid>

      <br />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "2rem",
          backgroundColor: "#fef2f2",
          position: "relative",
          width: "100vw",
          // height: "100vh",
          height: "94vh",
          // overflow property is to solve an error due to exploding screens
          // overflow: "auto",
        }}>
        {/* if the preview pixels are greater than 500px, then its a laptop preview and we need to load a laptop frame */}
        {/*{(previewDimsUnits.name && (previewDimsUnits.width.split("px")[0] >= 500 || previewDimsUnits.width.includes("100%"))) ? <Box sx={{ width:'100%', height: '100%',position:'absolute',top:'0',zIndex: 1,display:'flex',justifyContent:'center',alignItems:'start'}}>*/}
        {/*<img src={laptopFrameVectorNew} style={{ width: '90vw', height: '85vh', objectFit: 'fill',  backgroundColor: 'transparent' }} alt="Laptop Mock" />*/}
        {/*{(previewDimsUnits.name) ? <img src={laptopFrameVectorNew} style={{ width: `${previewDimsUnits.width}`, height: '92.5vh', margin: "0.2% auto auto auto", objectFit: 'fill',  backgroundColor: 'transparent' }} alt="Laptop Mock" /> : ''}*/}
        {/*</Box> : ''}*/}

        {/* if the preview pixels are less than 500px, then its a mobile preview and we need to load a laptop frame */}
        {/*{(previewDimsUnits.name && (previewDimsUnits.width.split("px")[0] <= 500)) ? <Box sx={{ width:'100%', height: '100%',position:'absolute',top:'0',zIndex: 1,display:'flex',justifyContent:'center',alignItems:'start'}}> */}
        {/*<img src={laptopFrameVectorNew} style={{ width: '90vw', height: '85vh', objectFit: 'fill',  backgroundColor: 'transparent' }} alt="Laptop Mock" />*/}
        {/*{(previewDimsUnits.name && (previewDimsUnits.width.split("px")[0] <= 500)) ? <img src={phoneFrameVectorNew} style={{ width: `${previewDimsUnits.width}`, height: `${previewDimsUnits.height}`, margin: "auto", objectFit: 'fill',  backgroundColor: 'transparent' }} alt="Laptop Mock" /> : ''}*/}
        {/*</Box> : ''}*/}

        {/*<Box sx={{ position: 'absolute',top:"-2%", width: '100%', objectFit: 'contain', borderRadius: '12px', zIndex: 10, overflowY: 'auto',height:'100%',display:'flex',justifyContent:'center',alignItems:'start',backgroundColor:'transparent'}}>*/}
        {/*<div style={{scale:'85%'}} ref={editorRef} />*/}
        {/*</Box>*/}

        <div ref={editorRef}></div>
      </div>
    </>
  );
}

export default Laptop;
